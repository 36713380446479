import React, { useState, useEffect } from "react";
import * as styles from "./bengkel.module.css";
import { Spin, Button, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { store, setBengkel } from "../../../lib";

interface DataType {
  _id: string;
  key: string;
  name: string;
  address: string;
  city: string;
  company: string;
}

export const BengkelStep = ({ onNextBengkel }) => {
  const [data, setData] = useState<Array<DataType>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const onPilih = (record) => {
    store.dispatch(setBengkel(record));
    onNextBengkel();
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      key: "action",
      render: (record) => (
        <Button onClick={() => onPilih(record)} type="primary">
          Pilih Bengkel
        </Button>
      ),
      width: 100,
    },
    {
      title: "Nama Bengkel",
      dataIndex: "",
      key: "name",
      render: (record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={`${styles.name}`}>{record.name.toUpperCase()}</div>
          <div className={`${styles.address}`}>{record.address}</div>
          <div className={`${styles.city}`}>{record.kota}</div>
          <div className={`${styles.company}`}>perusahaan : {record.name}</div>
        </div>
      ),
    },
    {
      title: "Alamat",
      dataIndex: "",
      key: "address",
      responsive: ["lg"],
      render: (record) => <div>{record.address}</div>,
    },
    {
      title: "Kota",
      dataIndex: "",
      key: "city",
      render: (record) => <div>{record.city}</div>,
      responsive: ["lg"],
    },
    {
      title: "Perusahaan",
      dataIndex: "company",
      key: "company",
      responsive: ["lg"],
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    let url = "/api/webuser/bengkel/get";
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ active: true, filter: { status: "verified" } }),
    });
    const jsonData = await rawResponse.json();
    if (jsonData.result == 200) {
      console.log(jsonData.data);
      for (let i = 0; i < jsonData.data.length; i++) {
        jsonData.data[i].key = jsonData.data[i]._id;
      }
      setData(jsonData.data);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className={`${styles.center}`}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <div className={`${styles.title}`}>Daftar Bengkel Pelaksana Konversi</div>
      <div>Cari bengkel terdekat dengan lokasi anda</div>
      <div className={`${styles.garisbawah}`}></div>

      <div>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

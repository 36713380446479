[
  {
    "id": "110101",
    "regency_id": "1101",
    "label": "Bakongan",
    "value": "Bakongan"
  },
  {
    "id": "110102",
    "regency_id": "1101",
    "label": "Kluet Utara",
    "value": "Kluet Utara"
  },
  {
    "id": "110103",
    "regency_id": "1101",
    "label": "Kluet Selatan",
    "value": "Kluet Selatan"
  },
  {
    "id": "110104",
    "regency_id": "1101",
    "label": "Labuhan Haji",
    "value": "Labuhan Haji"
  },
  {
    "id": "110105",
    "regency_id": "1101",
    "label": "Meukek",
    "value": "Meukek"
  },
  {
    "id": "110106",
    "regency_id": "1101",
    "label": "Samadua",
    "value": "Samadua"
  },
  {
    "id": "110107",
    "regency_id": "1101",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "110108",
    "regency_id": "1101",
    "label": "Tapaktuan",
    "value": "Tapaktuan"
  },
  {
    "id": "110109",
    "regency_id": "1101",
    "label": "Trumon",
    "value": "Trumon"
  },
  {
    "id": "110110",
    "regency_id": "1101",
    "label": "Pasi Raja",
    "value": "Pasi Raja"
  },
  {
    "id": "110111",
    "regency_id": "1101",
    "label": "Labuhan Haji Timur",
    "value": "Labuhan Haji Timur"
  },
  {
    "id": "110112",
    "regency_id": "1101",
    "label": "Labuhan Haji Barat",
    "value": "Labuhan Haji Barat"
  },
  {
    "id": "110113",
    "regency_id": "1101",
    "label": "Kluet Tengah",
    "value": "Kluet Tengah"
  },
  {
    "id": "110114",
    "regency_id": "1101",
    "label": "Kluet Timur",
    "value": "Kluet Timur"
  },
  {
    "id": "110115",
    "regency_id": "1101",
    "label": "Bakongan Timur",
    "value": "Bakongan Timur"
  },
  {
    "id": "110116",
    "regency_id": "1101",
    "label": "Trumon Timur",
    "value": "Trumon Timur"
  },
  {
    "id": "110117",
    "regency_id": "1101",
    "label": "Kota Bahagia",
    "value": "Kota Bahagia"
  },
  {
    "id": "110118",
    "regency_id": "1101",
    "label": "Trumon Tengah",
    "value": "Trumon Tengah"
  },
  {
    "id": "110201",
    "regency_id": "1102",
    "label": "Lawe Alas",
    "value": "Lawe Alas"
  },
  {
    "id": "110202",
    "regency_id": "1102",
    "label": "Lawe Sigala-Gala",
    "value": "Lawe Sigala-Gala"
  },
  {
    "id": "110203",
    "regency_id": "1102",
    "label": "Bambel",
    "value": "Bambel"
  },
  {
    "id": "110204",
    "regency_id": "1102",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  { "id": "110205", "regency_id": "1102", "label": "Badar", "value": "Badar" },
  {
    "id": "110206",
    "regency_id": "1102",
    "label": "Babul Makmur",
    "value": "Babul Makmur"
  },
  {
    "id": "110207",
    "regency_id": "1102",
    "label": "Darul Hasanah",
    "value": "Darul Hasanah"
  },
  {
    "id": "110208",
    "regency_id": "1102",
    "label": "Lawe Bulan",
    "value": "Lawe Bulan"
  },
  {
    "id": "110209",
    "regency_id": "1102",
    "label": "Bukit Tusam",
    "value": "Bukit Tusam"
  },
  {
    "id": "110210",
    "regency_id": "1102",
    "label": "Semadam",
    "value": "Semadam"
  },
  {
    "id": "110211",
    "regency_id": "1102",
    "label": "Babul Rahmah",
    "value": "Babul Rahmah"
  },
  {
    "id": "110212",
    "regency_id": "1102",
    "label": "Ketambe",
    "value": "Ketambe"
  },
  {
    "id": "110213",
    "regency_id": "1102",
    "label": "Deleng Pokhkisen",
    "value": "Deleng Pokhkisen"
  },
  {
    "id": "110214",
    "regency_id": "1102",
    "label": "Lawe Sumur",
    "value": "Lawe Sumur"
  },
  {
    "id": "110215",
    "regency_id": "1102",
    "label": "Tanoh Alas",
    "value": "Tanoh Alas"
  },
  {
    "id": "110216",
    "regency_id": "1102",
    "label": "Leuser",
    "value": "Leuser"
  },
  {
    "id": "110301",
    "regency_id": "1103",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  { "id": "110302", "regency_id": "1103", "label": "Julok", "value": "Julok" },
  {
    "id": "110303",
    "regency_id": "1103",
    "label": "Idi Rayeuk",
    "value": "Idi Rayeuk"
  },
  {
    "id": "110304",
    "regency_id": "1103",
    "label": "Birem Bayeun",
    "value": "Birem Bayeun"
  },
  {
    "id": "110305",
    "regency_id": "1103",
    "label": "Serbajadi",
    "value": "Serbajadi"
  },
  {
    "id": "110306",
    "regency_id": "1103",
    "label": "Nurussalam",
    "value": "Nurussalam"
  },
  {
    "id": "110307",
    "regency_id": "1103",
    "label": "Peureulak",
    "value": "Peureulak"
  },
  {
    "id": "110308",
    "regency_id": "1103",
    "label": "Rantau Selamat",
    "value": "Rantau Selamat"
  },
  {
    "id": "110309",
    "regency_id": "1103",
    "label": "Simpang Ulim",
    "value": "Simpang Ulim"
  },
  {
    "id": "110310",
    "regency_id": "1103",
    "label": "Rantau Peureulak",
    "value": "Rantau Peureulak"
  },
  {
    "id": "110311",
    "regency_id": "1103",
    "label": "Pante Bidari",
    "value": "Pante Bidari"
  },
  { "id": "110312", "regency_id": "1103", "label": "Madat", "value": "Madat" },
  {
    "id": "110313",
    "regency_id": "1103",
    "label": "Indra Makmu",
    "value": "Indra Makmu"
  },
  {
    "id": "110314",
    "regency_id": "1103",
    "label": "Idi Tunong",
    "value": "Idi Tunong"
  },
  {
    "id": "110315",
    "regency_id": "1103",
    "label": "Banda Alam",
    "value": "Banda Alam"
  },
  {
    "id": "110316",
    "regency_id": "1103",
    "label": "Peudawa",
    "value": "Peudawa"
  },
  {
    "id": "110317",
    "regency_id": "1103",
    "label": "Peureulak Timur",
    "value": "Peureulak Timur"
  },
  {
    "id": "110318",
    "regency_id": "1103",
    "label": "Peureulak Barat",
    "value": "Peureulak Barat"
  },
  {
    "id": "110319",
    "regency_id": "1103",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "110320",
    "regency_id": "1103",
    "label": "Simpang Jernih",
    "value": "Simpang Jernih"
  },
  {
    "id": "110321",
    "regency_id": "1103",
    "label": "Darul Ihsan",
    "value": "Darul Ihsan"
  },
  {
    "id": "110322",
    "regency_id": "1103",
    "label": "Darul Falah",
    "value": "Darul Falah"
  },
  {
    "id": "110323",
    "regency_id": "1103",
    "label": "Idi Timur",
    "value": "Idi Timur"
  },
  {
    "id": "110324",
    "regency_id": "1103",
    "label": "Peunaron",
    "value": "Peunaron"
  },
  { "id": "110401", "regency_id": "1104", "label": "Linge", "value": "Linge" },
  {
    "id": "110402",
    "regency_id": "1104",
    "label": "Silih Nara",
    "value": "Silih Nara"
  },
  {
    "id": "110403",
    "regency_id": "1104",
    "label": "Bebesen",
    "value": "Bebesen"
  },
  {
    "id": "110407",
    "regency_id": "1104",
    "label": "Pegasing",
    "value": "Pegasing"
  },
  {
    "id": "110408",
    "regency_id": "1104",
    "label": "Bintang",
    "value": "Bintang"
  },
  { "id": "110410", "regency_id": "1104", "label": "Ketol", "value": "Ketol" },
  {
    "id": "110411",
    "regency_id": "1104",
    "label": "Kebayakan",
    "value": "Kebayakan"
  },
  {
    "id": "110412",
    "regency_id": "1104",
    "label": "Kute Panang",
    "value": "Kute Panang"
  },
  {
    "id": "110413",
    "regency_id": "1104",
    "label": "Celala",
    "value": "Celala"
  },
  {
    "id": "110417",
    "regency_id": "1104",
    "label": "Laut Tawar",
    "value": "Laut Tawar"
  },
  {
    "id": "110418",
    "regency_id": "1104",
    "label": "Atu Lintang",
    "value": "Atu Lintang"
  },
  {
    "id": "110419",
    "regency_id": "1104",
    "label": "Jagong Jeget",
    "value": "Jagong Jeget"
  },
  { "id": "110420", "regency_id": "1104", "label": "Bies", "value": "Bies" },
  {
    "id": "110421",
    "regency_id": "1104",
    "label": "Rusip Antara",
    "value": "Rusip Antara"
  },
  {
    "id": "110501",
    "regency_id": "1105",
    "label": "Johan Pahwalan",
    "value": "Johan Pahwalan"
  },
  {
    "id": "110502",
    "regency_id": "1105",
    "label": "Kaway XVI",
    "value": "Kaway XVI"
  },
  {
    "id": "110503",
    "regency_id": "1105",
    "label": "Sungai Mas",
    "value": "Sungai Mas"
  },
  { "id": "110504", "regency_id": "1105", "label": "Woyla", "value": "Woyla" },
  {
    "id": "110505",
    "regency_id": "1105",
    "label": "Samatiga",
    "value": "Samatiga"
  },
  { "id": "110506", "regency_id": "1105", "label": "Bubon", "value": "Bubon" },
  {
    "id": "110507",
    "regency_id": "1105",
    "label": "Arongan Lambalek",
    "value": "Arongan Lambalek"
  },
  {
    "id": "110508",
    "regency_id": "1105",
    "label": "Pante Ceureumen",
    "value": "Pante Ceureumen"
  },
  {
    "id": "110509",
    "regency_id": "1105",
    "label": "Meureubo",
    "value": "Meureubo"
  },
  {
    "id": "110510",
    "regency_id": "1105",
    "label": "Woyla Barat",
    "value": "Woyla Barat"
  },
  {
    "id": "110511",
    "regency_id": "1105",
    "label": "Woyla Timur",
    "value": "Woyla Timur"
  },
  {
    "id": "110512",
    "regency_id": "1105",
    "label": "Panton Reu",
    "value": "Panton Reu"
  },
  {
    "id": "110601",
    "regency_id": "1106",
    "label": "Lhoong",
    "value": "Lhoong"
  },
  {
    "id": "110602",
    "regency_id": "1106",
    "label": "Lhoknga",
    "value": "Lhoknga"
  },
  {
    "id": "110603",
    "regency_id": "1106",
    "label": "Indrapuri",
    "value": "Indrapuri"
  },
  {
    "id": "110604",
    "regency_id": "1106",
    "label": "Seulimeum",
    "value": "Seulimeum"
  },
  {
    "id": "110605",
    "regency_id": "1106",
    "label": "Montasik",
    "value": "Montasik"
  },
  {
    "id": "110606",
    "regency_id": "1106",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "110607",
    "regency_id": "1106",
    "label": "Darul Imarah",
    "value": "Darul Imarah"
  },
  {
    "id": "110608",
    "regency_id": "1106",
    "label": "Peukan Bada",
    "value": "Peukan Bada"
  },
  {
    "id": "110609",
    "regency_id": "1106",
    "label": "Mesjid Raya",
    "value": "Mesjid Raya"
  },
  {
    "id": "110610",
    "regency_id": "1106",
    "label": "Ingin Jaya",
    "value": "Ingin Jaya"
  },
  {
    "id": "110611",
    "regency_id": "1106",
    "label": "Kuta Baro",
    "value": "Kuta Baro"
  },
  {
    "id": "110612",
    "regency_id": "1106",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "110613",
    "regency_id": "1106",
    "label": "Pulo Aceh",
    "value": "Pulo Aceh"
  },
  {
    "id": "110614",
    "regency_id": "1106",
    "label": "Lembah Seulawah",
    "value": "Lembah Seulawah"
  },
  {
    "id": "110615",
    "regency_id": "1106",
    "label": "Kota Jantho",
    "value": "Kota Jantho"
  },
  {
    "id": "110616",
    "regency_id": "1106",
    "label": "Kota Cot Glie",
    "value": "Kota Cot Glie"
  },
  {
    "id": "110617",
    "regency_id": "1106",
    "label": "Kuta Malaka",
    "value": "Kuta Malaka"
  },
  {
    "id": "110618",
    "regency_id": "1106",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "110619",
    "regency_id": "1106",
    "label": "Darul Kamal",
    "value": "Darul Kamal"
  },
  {
    "id": "110620",
    "regency_id": "1106",
    "label": "Baitussalam",
    "value": "Baitussalam"
  },
  {
    "id": "110621",
    "regency_id": "1106",
    "label": "Krueng Barona Jaya",
    "value": "Krueng Barona Jaya"
  },
  {
    "id": "110622",
    "regency_id": "1106",
    "label": "Leupung",
    "value": "Leupung"
  },
  {
    "id": "110623",
    "regency_id": "1106",
    "label": "Blang Bintang",
    "value": "Blang Bintang"
  },
  { "id": "110703", "regency_id": "1107", "label": "Batee", "value": "Batee" },
  {
    "id": "110704",
    "regency_id": "1107",
    "label": "Delima",
    "value": "Delima"
  },
  {
    "id": "110705",
    "regency_id": "1107",
    "label": "Geumpang",
    "value": "Geumpang"
  },
  {
    "id": "110706",
    "regency_id": "1107",
    "label": "Geulumpang Tiga",
    "value": "Geulumpang Tiga"
  },
  {
    "id": "110707",
    "regency_id": "1107",
    "label": "Indra Jaya",
    "value": "Indra Jaya"
  },
  {
    "id": "110708",
    "regency_id": "1107",
    "label": "Kembang Tanjong",
    "value": "Kembang Tanjong"
  },
  {
    "id": "110709",
    "regency_id": "1107",
    "label": "Kota Sigli",
    "value": "Kota Sigli"
  },
  { "id": "110711", "regency_id": "1107", "label": "Mila", "value": "Mila" },
  {
    "id": "110712",
    "regency_id": "1107",
    "label": "Muara Tiga",
    "value": "Muara Tiga"
  },
  {
    "id": "110713",
    "regency_id": "1107",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "110714",
    "regency_id": "1107",
    "label": "Padang Tiji",
    "value": "Padang Tiji"
  },
  {
    "id": "110715",
    "regency_id": "1107",
    "label": "Peukan Baro",
    "value": "Peukan Baro"
  },
  { "id": "110716", "regency_id": "1107", "label": "Pidie", "value": "Pidie" },
  { "id": "110717", "regency_id": "1107", "label": "Sakti", "value": "Sakti" },
  {
    "id": "110718",
    "regency_id": "1107",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "110719",
    "regency_id": "1107",
    "label": "Tangse",
    "value": "Tangse"
  },
  {
    "id": "110721",
    "regency_id": "1107",
    "label": "Tiro/Truseb",
    "value": "Tiro/Truseb"
  },
  {
    "id": "110722",
    "regency_id": "1107",
    "label": "Keumala",
    "value": "Keumala"
  },
  {
    "id": "110724",
    "regency_id": "1107",
    "label": "Mutiara Timur",
    "value": "Mutiara Timur"
  },
  {
    "id": "110725",
    "regency_id": "1107",
    "label": "Grong-grong",
    "value": "Grong-grong"
  },
  { "id": "110727", "regency_id": "1107", "label": "Mane", "value": "Mane" },
  {
    "id": "110729",
    "regency_id": "1107",
    "label": "Glumpang Baro",
    "value": "Glumpang Baro"
  },
  {
    "id": "110731",
    "regency_id": "1107",
    "label": "Titeue",
    "value": "Titeue"
  },
  {
    "id": "110801",
    "regency_id": "1108",
    "label": "Baktiya",
    "value": "Baktiya"
  },
  {
    "id": "110802",
    "regency_id": "1108",
    "label": "Dewantara",
    "value": "Dewantara"
  },
  {
    "id": "110803",
    "regency_id": "1108",
    "label": "Kuta Makmur",
    "value": "Kuta Makmur"
  },
  {
    "id": "110804",
    "regency_id": "1108",
    "label": "Lhoksukon",
    "value": "Lhoksukon"
  },
  {
    "id": "110805",
    "regency_id": "1108",
    "label": "Matangkuli",
    "value": "Matangkuli"
  },
  {
    "id": "110806",
    "regency_id": "1108",
    "label": "Muara Batu",
    "value": "Muara Batu"
  },
  {
    "id": "110807",
    "regency_id": "1108",
    "label": "Meurah Mulia",
    "value": "Meurah Mulia"
  },
  {
    "id": "110808",
    "regency_id": "1108",
    "label": "Samudera",
    "value": "Samudera"
  },
  {
    "id": "110809",
    "regency_id": "1108",
    "label": "Seunuddon",
    "value": "Seunuddon"
  },
  {
    "id": "110810",
    "regency_id": "1108",
    "label": "Syamtalira Aron",
    "value": "Syamtalira Aron"
  },
  {
    "id": "110811",
    "regency_id": "1108",
    "label": "Syamtalira Bayu",
    "value": "Syamtalira Bayu"
  },
  {
    "id": "110812",
    "regency_id": "1108",
    "label": "Tanah Luas",
    "value": "Tanah Luas"
  },
  {
    "id": "110813",
    "regency_id": "1108",
    "label": "Tanah Pasir",
    "value": "Tanah Pasir"
  },
  {
    "id": "110814",
    "regency_id": "1108",
    "label": "T. Jambo Aye",
    "value": "T. Jambo Aye"
  },
  {
    "id": "110815",
    "regency_id": "1108",
    "label": "Sawang",
    "value": "Sawang"
  },
  { "id": "110816", "regency_id": "1108", "label": "Nisam", "value": "Nisam" },
  {
    "id": "110817",
    "regency_id": "1108",
    "label": "Cot Girek",
    "value": "Cot Girek"
  },
  {
    "id": "110818",
    "regency_id": "1108",
    "label": "Langkahan",
    "value": "Langkahan"
  },
  {
    "id": "110819",
    "regency_id": "1108",
    "label": "Baktiya Barat",
    "value": "Baktiya Barat"
  },
  {
    "id": "110820",
    "regency_id": "1108",
    "label": "Paya Bakong",
    "value": "Paya Bakong"
  },
  {
    "id": "110821",
    "regency_id": "1108",
    "label": "Nibong",
    "value": "Nibong"
  },
  {
    "id": "110822",
    "regency_id": "1108",
    "label": "Simpang Kramat",
    "value": "Simpang Kramat"
  },
  {
    "id": "110823",
    "regency_id": "1108",
    "label": "Lapang",
    "value": "Lapang"
  },
  {
    "id": "110824",
    "regency_id": "1108",
    "label": "Pirak Timur",
    "value": "Pirak Timur"
  },
  {
    "id": "110825",
    "regency_id": "1108",
    "label": "Geuredong Pase",
    "value": "Geuredong Pase"
  },
  {
    "id": "110826",
    "regency_id": "1108",
    "label": "Banda Baro",
    "value": "Banda Baro"
  },
  {
    "id": "110827",
    "regency_id": "1108",
    "label": "Nisam Antara",
    "value": "Nisam Antara"
  },
  {
    "id": "110901",
    "regency_id": "1109",
    "label": "Simeulue Tengah",
    "value": "Simeulue Tengah"
  },
  {
    "id": "110902",
    "regency_id": "1109",
    "label": "Salang",
    "value": "Salang"
  },
  {
    "id": "110903",
    "regency_id": "1109",
    "label": "Teupah Barat",
    "value": "Teupah Barat"
  },
  {
    "id": "110904",
    "regency_id": "1109",
    "label": "Simeulue Timur",
    "value": "Simeulue Timur"
  },
  {
    "id": "110905",
    "regency_id": "1109",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "110906",
    "regency_id": "1109",
    "label": "Simeulue Barat",
    "value": "Simeulue Barat"
  },
  {
    "id": "110907",
    "regency_id": "1109",
    "label": "Teupah Selatan",
    "value": "Teupah Selatan"
  },
  {
    "id": "110908",
    "regency_id": "1109",
    "label": "Alapan",
    "value": "Alapan"
  },
  {
    "id": "110909",
    "regency_id": "1109",
    "label": "Teupah Tengah",
    "value": "Teupah Tengah"
  },
  {
    "id": "110910",
    "regency_id": "1109",
    "label": "Simeulue Cut",
    "value": "Simeulue Cut"
  },
  {
    "id": "111001",
    "regency_id": "1110",
    "label": "Pulau Banyak",
    "value": "Pulau Banyak"
  },
  {
    "id": "111002",
    "regency_id": "1110",
    "label": "Simpang Kanan",
    "value": "Simpang Kanan"
  },
  {
    "id": "111004",
    "regency_id": "1110",
    "label": "Singkil",
    "value": "Singkil"
  },
  {
    "id": "111006",
    "regency_id": "1110",
    "label": "Gunung Meriah",
    "value": "Gunung Meriah"
  },
  {
    "id": "111009",
    "regency_id": "1110",
    "label": "Kota Baharu",
    "value": "Kota Baharu"
  },
  {
    "id": "111010",
    "regency_id": "1110",
    "label": "Singkil Utara",
    "value": "Singkil Utara"
  },
  {
    "id": "111011",
    "regency_id": "1110",
    "label": "Danau Paris",
    "value": "Danau Paris"
  },
  {
    "id": "111012",
    "regency_id": "1110",
    "label": "Suro Makmur",
    "value": "Suro Makmur"
  },
  {
    "id": "111013",
    "regency_id": "1110",
    "label": "Singkohor",
    "value": "Singkohor"
  },
  {
    "id": "111014",
    "regency_id": "1110",
    "label": "Kuala Baru",
    "value": "Kuala Baru"
  },
  {
    "id": "111016",
    "regency_id": "1110",
    "label": "Pulau Banyak Barat",
    "value": "Pulau Banyak Barat"
  },
  {
    "id": "111101",
    "regency_id": "1111",
    "label": "Samalanga",
    "value": "Samalanga"
  },
  {
    "id": "111102",
    "regency_id": "1111",
    "label": "Jeunieb",
    "value": "Jeunieb"
  },
  {
    "id": "111103",
    "regency_id": "1111",
    "label": "Peudada",
    "value": "Peudada"
  },
  {
    "id": "111104",
    "regency_id": "1111",
    "label": "Jeumpa",
    "value": "Jeumpa"
  },
  {
    "id": "111105",
    "regency_id": "1111",
    "label": "Peusangan",
    "value": "Peusangan"
  },
  {
    "id": "111106",
    "regency_id": "1111",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "111107",
    "regency_id": "1111",
    "label": "Gandapura",
    "value": "Gandapura"
  },
  {
    "id": "111108",
    "regency_id": "1111",
    "label": "Pandrah",
    "value": "Pandrah"
  },
  { "id": "111109", "regency_id": "1111", "label": "Juli", "value": "Juli" },
  {
    "id": "111110",
    "regency_id": "1111",
    "label": "Jangka",
    "value": "Jangka"
  },
  {
    "id": "111111",
    "regency_id": "1111",
    "label": "Simpang Mamplam",
    "value": "Simpang Mamplam"
  },
  {
    "id": "111112",
    "regency_id": "1111",
    "label": "Peulimbang",
    "value": "Peulimbang"
  },
  {
    "id": "111113",
    "regency_id": "1111",
    "label": "Kota Juang",
    "value": "Kota Juang"
  },
  { "id": "111114", "regency_id": "1111", "label": "Kuala", "value": "Kuala" },
  {
    "id": "111115",
    "regency_id": "1111",
    "label": "Peusangan Siblah Krueng",
    "value": "Peusangan Siblah Krueng"
  },
  {
    "id": "111116",
    "regency_id": "1111",
    "label": "Peusangan Selatan",
    "value": "Peusangan Selatan"
  },
  {
    "id": "111117",
    "regency_id": "1111",
    "label": "Kuta Blang",
    "value": "Kuta Blang"
  },
  {
    "id": "111201",
    "regency_id": "1112",
    "label": "Blang Pidie",
    "value": "Blang Pidie"
  },
  {
    "id": "111202",
    "regency_id": "1112",
    "label": "Tangan-Tangan",
    "value": "Tangan-Tangan"
  },
  {
    "id": "111203",
    "regency_id": "1112",
    "label": "Manggeng",
    "value": "Manggeng"
  },
  { "id": "111204", "regency_id": "1112", "label": "Susoh", "value": "Susoh" },
  {
    "id": "111205",
    "regency_id": "1112",
    "label": "Kuala Batee",
    "value": "Kuala Batee"
  },
  {
    "id": "111206",
    "regency_id": "1112",
    "label": "Babah Rot",
    "value": "Babah Rot"
  },
  { "id": "111207", "regency_id": "1112", "label": "Setia", "value": "Setia" },
  {
    "id": "111208",
    "regency_id": "1112",
    "label": "Jeumpa",
    "value": "Jeumpa"
  },
  {
    "id": "111209",
    "regency_id": "1112",
    "label": "Lembah Sabil",
    "value": "Lembah Sabil"
  },
  {
    "id": "111301",
    "regency_id": "1113",
    "label": "Blangkejeren",
    "value": "Blangkejeren"
  },
  {
    "id": "111302",
    "regency_id": "1113",
    "label": "Kutapanjang",
    "value": "Kutapanjang"
  },
  {
    "id": "111303",
    "regency_id": "1113",
    "label": "Rikit Gaib",
    "value": "Rikit Gaib"
  },
  {
    "id": "111304",
    "regency_id": "1113",
    "label": "Terangun",
    "value": "Terangun"
  },
  {
    "id": "111305",
    "regency_id": "1113",
    "label": "Pining",
    "value": "Pining"
  },
  {
    "id": "111306",
    "regency_id": "1113",
    "label": "Blangpegayon",
    "value": "Blangpegayon"
  },
  {
    "id": "111307",
    "regency_id": "1113",
    "label": "Puteri Betung",
    "value": "Puteri Betung"
  },
  {
    "id": "111308",
    "regency_id": "1113",
    "label": "Dabun Gelang",
    "value": "Dabun Gelang"
  },
  {
    "id": "111309",
    "regency_id": "1113",
    "label": "Blangjerango",
    "value": "Blangjerango"
  },
  {
    "id": "111310",
    "regency_id": "1113",
    "label": "Teripe Jaya",
    "value": "Teripe Jaya"
  },
  {
    "id": "111311",
    "regency_id": "1113",
    "label": "Pantan Cuaca",
    "value": "Pantan Cuaca"
  },
  {
    "id": "111401",
    "regency_id": "1114",
    "label": "Teunom",
    "value": "Teunom"
  },
  {
    "id": "111402",
    "regency_id": "1114",
    "label": "Krueng Sabee",
    "value": "Krueng Sabee"
  },
  {
    "id": "111403",
    "regency_id": "1114",
    "label": "Setia Bhakti",
    "value": "Setia Bhakti"
  },
  {
    "id": "111404",
    "regency_id": "1114",
    "label": "Sampoiniet",
    "value": "Sampoiniet"
  },
  { "id": "111405", "regency_id": "1114", "label": "Jaya", "value": "Jaya" },
  { "id": "111406", "regency_id": "1114", "label": "Panga", "value": "Panga" },
  {
    "id": "111407",
    "regency_id": "1114",
    "label": "Indra Jaya",
    "value": "Indra Jaya"
  },
  {
    "id": "111408",
    "regency_id": "1114",
    "label": "Darul Hikmah",
    "value": "Darul Hikmah"
  },
  {
    "id": "111409",
    "regency_id": "1114",
    "label": "Pasie Raya",
    "value": "Pasie Raya"
  },
  { "id": "111501", "regency_id": "1115", "label": "Kuala", "value": "Kuala" },
  {
    "id": "111502",
    "regency_id": "1115",
    "label": "Seunagan",
    "value": "Seunagan"
  },
  {
    "id": "111503",
    "regency_id": "1115",
    "label": "Seunagan Timur",
    "value": "Seunagan Timur"
  },
  {
    "id": "111504",
    "regency_id": "1115",
    "label": "Beutong",
    "value": "Beutong"
  },
  {
    "id": "111505",
    "regency_id": "1115",
    "label": "Darul Makmur",
    "value": "Darul Makmur"
  },
  {
    "id": "111506",
    "regency_id": "1115",
    "label": "Suka Makmue",
    "value": "Suka Makmue"
  },
  {
    "id": "111507",
    "regency_id": "1115",
    "label": "Kuala Pesisir",
    "value": "Kuala Pesisir"
  },
  {
    "id": "111508",
    "regency_id": "1115",
    "label": "Tadu Raya",
    "value": "Tadu Raya"
  },
  {
    "id": "111509",
    "regency_id": "1115",
    "label": "Tripa Makmur",
    "value": "Tripa Makmur"
  },
  {
    "id": "111510",
    "regency_id": "1115",
    "label": "Beutong Ateuh Banggalang",
    "value": "Beutong Ateuh Banggalang"
  },
  {
    "id": "111601",
    "regency_id": "1116",
    "label": "Manyak Payed",
    "value": "Manyak Payed"
  },
  {
    "id": "111602",
    "regency_id": "1116",
    "label": "Bendahara",
    "value": "Bendahara"
  },
  {
    "id": "111603",
    "regency_id": "1116",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "111604",
    "regency_id": "1116",
    "label": "Seruway",
    "value": "Seruway"
  },
  {
    "id": "111605",
    "regency_id": "1116",
    "label": "Kota Kualasinpang",
    "value": "Kota Kualasinpang"
  },
  {
    "id": "111606",
    "regency_id": "1116",
    "label": "Kejuruan Muda",
    "value": "Kejuruan Muda"
  },
  {
    "id": "111607",
    "regency_id": "1116",
    "label": "Tamiang Hulu",
    "value": "Tamiang Hulu"
  },
  {
    "id": "111608",
    "regency_id": "1116",
    "label": "Rantau",
    "value": "Rantau"
  },
  {
    "id": "111609",
    "regency_id": "1116",
    "label": "Banda Mulia",
    "value": "Banda Mulia"
  },
  {
    "id": "111610",
    "regency_id": "1116",
    "label": "Bandar Pusaka",
    "value": "Bandar Pusaka"
  },
  {
    "id": "111611",
    "regency_id": "1116",
    "label": "Tenggulun",
    "value": "Tenggulun"
  },
  {
    "id": "111612",
    "regency_id": "1116",
    "label": "Sekerak",
    "value": "Sekerak"
  },
  {
    "id": "111701",
    "regency_id": "1117",
    "label": "Pintu Rime Gayo",
    "value": "Pintu Rime Gayo"
  },
  {
    "id": "111702",
    "regency_id": "1117",
    "label": "Permata",
    "value": "Permata"
  },
  {
    "id": "111703",
    "regency_id": "1117",
    "label": "Syiah Utama",
    "value": "Syiah Utama"
  },
  {
    "id": "111704",
    "regency_id": "1117",
    "label": "Bandar",
    "value": "Bandar"
  },
  { "id": "111705", "regency_id": "1117", "label": "Bukit", "value": "Bukit" },
  {
    "id": "111706",
    "regency_id": "1117",
    "label": "Wih Pesam",
    "value": "Wih Pesam"
  },
  {
    "id": "111707",
    "regency_id": "1117",
    "label": "Timang gajah",
    "value": "Timang gajah"
  },
  {
    "id": "111708",
    "regency_id": "1117",
    "label": "Bener Kelipah",
    "value": "Bener Kelipah"
  },
  {
    "id": "111709",
    "regency_id": "1117",
    "label": "Mesidah",
    "value": "Mesidah"
  },
  {
    "id": "111710",
    "regency_id": "1117",
    "label": "Gajah Putih",
    "value": "Gajah Putih"
  },
  {
    "id": "111801",
    "regency_id": "1118",
    "label": "Meureudu",
    "value": "Meureudu"
  },
  { "id": "111802", "regency_id": "1118", "label": "Ulim", "value": "Ulim" },
  {
    "id": "111803",
    "regency_id": "1118",
    "label": "Jangka Buaya",
    "value": "Jangka Buaya"
  },
  {
    "id": "111804",
    "regency_id": "1118",
    "label": "Bandar Dua",
    "value": "Bandar Dua"
  },
  {
    "id": "111805",
    "regency_id": "1118",
    "label": "Meurah Dua",
    "value": "Meurah Dua"
  },
  {
    "id": "111806",
    "regency_id": "1118",
    "label": "Bandar Baru",
    "value": "Bandar Baru"
  },
  {
    "id": "111807",
    "regency_id": "1118",
    "label": "Panteraja",
    "value": "Panteraja"
  },
  {
    "id": "111808",
    "regency_id": "1118",
    "label": "Trienggadeng",
    "value": "Trienggadeng"
  },
  {
    "id": "117101",
    "regency_id": "1171",
    "label": "Baiturrahman",
    "value": "Baiturrahman"
  },
  {
    "id": "117102",
    "regency_id": "1171",
    "label": "Kuta Alam",
    "value": "Kuta Alam"
  },
  {
    "id": "117103",
    "regency_id": "1171",
    "label": "Meuraxa",
    "value": "Meuraxa"
  },
  {
    "id": "117104",
    "regency_id": "1171",
    "label": "Syiah Kuala",
    "value": "Syiah Kuala"
  },
  {
    "id": "117105",
    "regency_id": "1171",
    "label": "Lueng Bata",
    "value": "Lueng Bata"
  },
  {
    "id": "117106",
    "regency_id": "1171",
    "label": "Kuta Raja",
    "value": "Kuta Raja"
  },
  {
    "id": "117107",
    "regency_id": "1171",
    "label": "Banda Raya",
    "value": "Banda Raya"
  },
  {
    "id": "117108",
    "regency_id": "1171",
    "label": "Jaya Baru",
    "value": "Jaya Baru"
  },
  {
    "id": "117109",
    "regency_id": "1171",
    "label": "Ulee Kareng",
    "value": "Ulee Kareng"
  },
  {
    "id": "117201",
    "regency_id": "1172",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "117202",
    "regency_id": "1172",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "117301",
    "regency_id": "1173",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "117302",
    "regency_id": "1173",
    "label": "Banda Sakti",
    "value": "Banda Sakti"
  },
  {
    "id": "117303",
    "regency_id": "1173",
    "label": "Blang Mangat",
    "value": "Blang Mangat"
  },
  {
    "id": "117304",
    "regency_id": "1173",
    "label": "Muara Satu",
    "value": "Muara Satu"
  },
  {
    "id": "117401",
    "regency_id": "1174",
    "label": "Langsa Timur",
    "value": "Langsa Timur"
  },
  {
    "id": "117402",
    "regency_id": "1174",
    "label": "Langsa Barat",
    "value": "Langsa Barat"
  },
  {
    "id": "117403",
    "regency_id": "1174",
    "label": "Langsa Kota",
    "value": "Langsa Kota"
  },
  {
    "id": "117404",
    "regency_id": "1174",
    "label": "Langsa Lama",
    "value": "Langsa Lama"
  },
  {
    "id": "117405",
    "regency_id": "1174",
    "label": "Langsa Baro",
    "value": "Langsa Baro"
  },
  {
    "id": "117501",
    "regency_id": "1175",
    "label": "Simpang Kiri",
    "value": "Simpang Kiri"
  },
  {
    "id": "117502",
    "regency_id": "1175",
    "label": "Penanggalan",
    "value": "Penanggalan"
  },
  {
    "id": "117503",
    "regency_id": "1175",
    "label": "Rundeng",
    "value": "Rundeng"
  },
  {
    "id": "117504",
    "regency_id": "1175",
    "label": "Sultan Daulat",
    "value": "Sultan Daulat"
  },
  {
    "id": "117505",
    "regency_id": "1175",
    "label": "Longkib",
    "value": "Longkib"
  },
  { "id": "120101", "regency_id": "1201", "label": "Barus", "value": "Barus" },
  {
    "id": "120102",
    "regency_id": "1201",
    "label": "Sorkam",
    "value": "Sorkam"
  },
  {
    "id": "120103",
    "regency_id": "1201",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "120104",
    "regency_id": "1201",
    "label": "Pinangsori",
    "value": "Pinangsori"
  },
  {
    "id": "120105",
    "regency_id": "1201",
    "label": "Manduamas",
    "value": "Manduamas"
  },
  {
    "id": "120106",
    "regency_id": "1201",
    "label": "Kolang",
    "value": "Kolang"
  },
  {
    "id": "120107",
    "regency_id": "1201",
    "label": "Tapian Nauli",
    "value": "Tapian Nauli"
  },
  {
    "id": "120108",
    "regency_id": "1201",
    "label": "Sibabangun",
    "value": "Sibabangun"
  },
  {
    "id": "120109",
    "regency_id": "1201",
    "label": "Sosorgadong",
    "value": "Sosorgadong"
  },
  {
    "id": "120110",
    "regency_id": "1201",
    "label": "Sorkam Barat",
    "value": "Sorkam Barat"
  },
  {
    "id": "120111",
    "regency_id": "1201",
    "label": "Sirandorung",
    "value": "Sirandorung"
  },
  {
    "id": "120112",
    "regency_id": "1201",
    "label": "Andam Dewi",
    "value": "Andam Dewi"
  },
  {
    "id": "120113",
    "regency_id": "1201",
    "label": "Sitahuis",
    "value": "Sitahuis"
  },
  { "id": "120114", "regency_id": "1201", "label": "Tukka", "value": "Tukka" },
  {
    "id": "120115",
    "regency_id": "1201",
    "label": "Badiri",
    "value": "Badiri"
  },
  {
    "id": "120116",
    "regency_id": "1201",
    "label": "Pasaribu Tobing",
    "value": "Pasaribu Tobing"
  },
  {
    "id": "120117",
    "regency_id": "1201",
    "label": "Barus Utara",
    "value": "Barus Utara"
  },
  {
    "id": "120118",
    "regency_id": "1201",
    "label": "Suka Bangun",
    "value": "Suka Bangun"
  },
  { "id": "120119", "regency_id": "1201", "label": "Lumut", "value": "Lumut" },
  {
    "id": "120120",
    "regency_id": "1201",
    "label": "Sarudik",
    "value": "Sarudik"
  },
  {
    "id": "120201",
    "regency_id": "1202",
    "label": "Tarutung",
    "value": "Tarutung"
  },
  {
    "id": "120202",
    "regency_id": "1202",
    "label": "Siatas Barita",
    "value": "Siatas Barita"
  },
  {
    "id": "120203",
    "regency_id": "1202",
    "label": "Adian Koting",
    "value": "Adian Koting"
  },
  {
    "id": "120204",
    "regency_id": "1202",
    "label": "Sipoholon",
    "value": "Sipoholon"
  },
  {
    "id": "120205",
    "regency_id": "1202",
    "label": "Pahae Julu",
    "value": "Pahae Julu"
  },
  {
    "id": "120206",
    "regency_id": "1202",
    "label": "Pahae Jae",
    "value": "Pahae Jae"
  },
  {
    "id": "120207",
    "regency_id": "1202",
    "label": "Simangumban",
    "value": "Simangumban"
  },
  {
    "id": "120208",
    "regency_id": "1202",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "120209",
    "regency_id": "1202",
    "label": "Siborong-Borong",
    "value": "Siborong-Borong"
  },
  {
    "id": "120210",
    "regency_id": "1202",
    "label": "Pagaran",
    "value": "Pagaran"
  },
  {
    "id": "120211",
    "regency_id": "1202",
    "label": "Parmonangan",
    "value": "Parmonangan"
  },
  {
    "id": "120212",
    "regency_id": "1202",
    "label": "Sipahutar",
    "value": "Sipahutar"
  },
  {
    "id": "120213",
    "regency_id": "1202",
    "label": "Pangaribuan",
    "value": "Pangaribuan"
  },
  {
    "id": "120214",
    "regency_id": "1202",
    "label": "Garoga",
    "value": "Garoga"
  },
  { "id": "120215", "regency_id": "1202", "label": "Muara", "value": "Muara" },
  {
    "id": "120301",
    "regency_id": "1203",
    "label": "Angkola Barat",
    "value": "Angkola Barat"
  },
  {
    "id": "120302",
    "regency_id": "1203",
    "label": "Batang Toru",
    "value": "Batang Toru"
  },
  {
    "id": "120303",
    "regency_id": "1203",
    "label": "Angkola Timur",
    "value": "Angkola Timur"
  },
  {
    "id": "120304",
    "regency_id": "1203",
    "label": "Sipirok",
    "value": "Sipirok"
  },
  {
    "id": "120305",
    "regency_id": "1203",
    "label": "Saipar Dolok Hole",
    "value": "Saipar Dolok Hole"
  },
  {
    "id": "120306",
    "regency_id": "1203",
    "label": "Angkola Selatan",
    "value": "Angkola Selatan"
  },
  {
    "id": "120307",
    "regency_id": "1203",
    "label": "Batang Angkola",
    "value": "Batang Angkola"
  },
  { "id": "120314", "regency_id": "1203", "label": "Arse", "value": "Arse" },
  {
    "id": "120320",
    "regency_id": "1203",
    "label": "Marancar",
    "value": "Marancar"
  },
  {
    "id": "120321",
    "regency_id": "1203",
    "label": "Sayur Matinggi",
    "value": "Sayur Matinggi"
  },
  {
    "id": "120322",
    "regency_id": "1203",
    "label": "Aek Bilah",
    "value": "Aek Bilah"
  },
  {
    "id": "120329",
    "regency_id": "1203",
    "label": "Muara Batang Toru",
    "value": "Muara Batang Toru"
  },
  {
    "id": "120330",
    "regency_id": "1203",
    "label": "Tano Tombangan Angkola",
    "value": "Tano Tombangan Angkola"
  },
  {
    "id": "120331",
    "regency_id": "1203",
    "label": "Angkola Sangkunur",
    "value": "Angkola Sangkunur"
  },
  {
    "id": "120332",
    "regency_id": "1203",
    "label": "Angkola Muara Tais",
    "value": "Angkola Muara Tais"
  },
  {
    "id": "120405",
    "regency_id": "1204",
    "label": "Hiliduho",
    "value": "Hiliduho"
  },
  { "id": "120406", "regency_id": "1204", "label": "Gido", "value": "Gido" },
  {
    "id": "120410",
    "regency_id": "1204",
    "label": "Idanogawo",
    "value": "Idanogawo"
  },
  {
    "id": "120411",
    "regency_id": "1204",
    "label": "Bawolato",
    "value": "Bawolato"
  },
  {
    "id": "120420",
    "regency_id": "1204",
    "label": "Hiliserangkai",
    "value": "Hiliserangkai"
  },
  {
    "id": "120421",
    "regency_id": "1204",
    "label": "Botomuzoi",
    "value": "Botomuzoi"
  },
  {
    "id": "120427",
    "regency_id": "1204",
    "label": "Ulugawo",
    "value": "Ulugawo"
  },
  { "id": "120428", "regency_id": "1204", "label": "Ma'u", "value": "Ma'u" },
  {
    "id": "120429",
    "regency_id": "1204",
    "label": "Somolo-molo",
    "value": "Somolo-molo"
  },
  {
    "id": "120435",
    "regency_id": "1204",
    "label": "Sogae'adu",
    "value": "Sogae'adu"
  },
  {
    "id": "120501",
    "regency_id": "1205",
    "label": "Bahorok",
    "value": "Bahorok"
  },
  {
    "id": "120502",
    "regency_id": "1205",
    "label": "Salapian",
    "value": "Salapian"
  },
  { "id": "120503", "regency_id": "1205", "label": "Kuala", "value": "Kuala" },
  {
    "id": "120504",
    "regency_id": "1205",
    "label": "Sei Bingai",
    "value": "Sei Bingai"
  },
  {
    "id": "120505",
    "regency_id": "1205",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "120506",
    "regency_id": "1205",
    "label": "Selesai",
    "value": "Selesai"
  },
  {
    "id": "120507",
    "regency_id": "1205",
    "label": "Stabat",
    "value": "Stabat"
  },
  { "id": "120508", "regency_id": "1205", "label": "Wampu", "value": "Wampu" },
  {
    "id": "120509",
    "regency_id": "1205",
    "label": "Secanggang",
    "value": "Secanggang"
  },
  { "id": "120510", "regency_id": "1205", "label": "Hinai", "value": "Hinai" },
  {
    "id": "120511",
    "regency_id": "1205",
    "label": "Tanjung Pura",
    "value": "Tanjung Pura"
  },
  {
    "id": "120512",
    "regency_id": "1205",
    "label": "Padang Tualang",
    "value": "Padang Tualang"
  },
  {
    "id": "120513",
    "regency_id": "1205",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "120514",
    "regency_id": "1205",
    "label": "Babalan",
    "value": "Babalan"
  },
  {
    "id": "120515",
    "regency_id": "1205",
    "label": "Pangkalan Susu",
    "value": "Pangkalan Susu"
  },
  {
    "id": "120516",
    "regency_id": "1205",
    "label": "Besitang",
    "value": "Besitang"
  },
  {
    "id": "120517",
    "regency_id": "1205",
    "label": "Sei Lepan",
    "value": "Sei Lepan"
  },
  {
    "id": "120518",
    "regency_id": "1205",
    "label": "Berandan Barat",
    "value": "Berandan Barat"
  },
  {
    "id": "120519",
    "regency_id": "1205",
    "label": "Batang Serangan",
    "value": "Batang Serangan"
  },
  {
    "id": "120520",
    "regency_id": "1205",
    "label": "Sawit Seberang",
    "value": "Sawit Seberang"
  },
  {
    "id": "120521",
    "regency_id": "1205",
    "label": "Sirapit",
    "value": "Sirapit"
  },
  {
    "id": "120522",
    "regency_id": "1205",
    "label": "Kutambaru",
    "value": "Kutambaru"
  },
  {
    "id": "120523",
    "regency_id": "1205",
    "label": "Pematang Jaya",
    "value": "Pematang Jaya"
  },
  {
    "id": "120601",
    "regency_id": "1206",
    "label": "Kabanjahe",
    "value": "Kabanjahe"
  },
  {
    "id": "120602",
    "regency_id": "1206",
    "label": "Berastagi",
    "value": "Berastagi"
  },
  {
    "id": "120603",
    "regency_id": "1206",
    "label": "Barusjahe",
    "value": "Barusjahe"
  },
  {
    "id": "120604",
    "regency_id": "1206",
    "label": "Tigapanah",
    "value": "Tigapanah"
  },
  { "id": "120605", "regency_id": "1206", "label": "Merek", "value": "Merek" },
  { "id": "120606", "regency_id": "1206", "label": "Munte", "value": "Munte" },
  { "id": "120607", "regency_id": "1206", "label": "Juhar", "value": "Juhar" },
  {
    "id": "120608",
    "regency_id": "1206",
    "label": "Tigabinanga",
    "value": "Tigabinanga"
  },
  {
    "id": "120609",
    "regency_id": "1206",
    "label": "Laubaleng",
    "value": "Laubaleng"
  },
  {
    "id": "120610",
    "regency_id": "1206",
    "label": "Mardingding",
    "value": "Mardingding"
  },
  {
    "id": "120611",
    "regency_id": "1206",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "120612",
    "regency_id": "1206",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "120613",
    "regency_id": "1206",
    "label": "Kutabuluh",
    "value": "Kutabuluh"
  },
  {
    "id": "120614",
    "regency_id": "1206",
    "label": "Dolat Rayat",
    "value": "Dolat Rayat"
  },
  {
    "id": "120615",
    "regency_id": "1206",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "120616",
    "regency_id": "1206",
    "label": "Naman Teran",
    "value": "Naman Teran"
  },
  {
    "id": "120617",
    "regency_id": "1206",
    "label": "Tiganderket",
    "value": "Tiganderket"
  },
  {
    "id": "120701",
    "regency_id": "1207",
    "label": "Gunung Meriah",
    "value": "Gunung Meriah"
  },
  {
    "id": "120702",
    "regency_id": "1207",
    "label": "Tanjung Morawa",
    "value": "Tanjung Morawa"
  },
  {
    "id": "120703",
    "regency_id": "1207",
    "label": "Sibolangit",
    "value": "Sibolangit"
  },
  {
    "id": "120704",
    "regency_id": "1207",
    "label": "Kutalimbaru",
    "value": "Kutalimbaru"
  },
  {
    "id": "120705",
    "regency_id": "1207",
    "label": "Pancur Batu",
    "value": "Pancur Batu"
  },
  {
    "id": "120706",
    "regency_id": "1207",
    "label": "Namorambe",
    "value": "Namorambe"
  },
  {
    "id": "120707",
    "regency_id": "1207",
    "label": "Sibiru-biru",
    "value": "Sibiru-biru"
  },
  {
    "id": "120708",
    "regency_id": "1207",
    "label": "STM Hilir",
    "value": "STM Hilir"
  },
  {
    "id": "120709",
    "regency_id": "1207",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "120719",
    "regency_id": "1207",
    "label": "Galang",
    "value": "Galang"
  },
  {
    "id": "120720",
    "regency_id": "1207",
    "label": "STM Hulu",
    "value": "STM Hulu"
  },
  {
    "id": "120721",
    "regency_id": "1207",
    "label": "Patumbak",
    "value": "Patumbak"
  },
  {
    "id": "120722",
    "regency_id": "1207",
    "label": "Deli Tua",
    "value": "Deli Tua"
  },
  {
    "id": "120723",
    "regency_id": "1207",
    "label": "Sunggal",
    "value": "Sunggal"
  },
  {
    "id": "120724",
    "regency_id": "1207",
    "label": "Hamparan Perak",
    "value": "Hamparan Perak"
  },
  {
    "id": "120725",
    "regency_id": "1207",
    "label": "Labuhan Deli",
    "value": "Labuhan Deli"
  },
  {
    "id": "120726",
    "regency_id": "1207",
    "label": "Percut Sei Tuan",
    "value": "Percut Sei Tuan"
  },
  {
    "id": "120727",
    "regency_id": "1207",
    "label": "Batang Kuis",
    "value": "Batang Kuis"
  },
  {
    "id": "120728",
    "regency_id": "1207",
    "label": "Lubuk Pakam",
    "value": "Lubuk Pakam"
  },
  {
    "id": "120731",
    "regency_id": "1207",
    "label": "Pagar Merbau",
    "value": "Pagar Merbau"
  },
  {
    "id": "120732",
    "regency_id": "1207",
    "label": "Pantai Labu",
    "value": "Pantai Labu"
  },
  {
    "id": "120733",
    "regency_id": "1207",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "120801",
    "regency_id": "1208",
    "label": "Siantar",
    "value": "Siantar"
  },
  {
    "id": "120802",
    "regency_id": "1208",
    "label": "Gunung Malela",
    "value": "Gunung Malela"
  },
  {
    "id": "120803",
    "regency_id": "1208",
    "label": "Gunung Maligas",
    "value": "Gunung Maligas"
  },
  { "id": "120804", "regency_id": "1208", "label": "Panei", "value": "Panei" },
  {
    "id": "120805",
    "regency_id": "1208",
    "label": "Panombeian Pane",
    "value": "Panombeian Pane"
  },
  {
    "id": "120806",
    "regency_id": "1208",
    "label": "Jorlang Hataran",
    "value": "Jorlang Hataran"
  },
  {
    "id": "120807",
    "regency_id": "1208",
    "label": "Raya Kahean",
    "value": "Raya Kahean"
  },
  {
    "id": "120808",
    "regency_id": "1208",
    "label": "Bosar Maligas",
    "value": "Bosar Maligas"
  },
  {
    "id": "120809",
    "regency_id": "1208",
    "label": "Sidamanik",
    "value": "Sidamanik"
  },
  {
    "id": "120810",
    "regency_id": "1208",
    "label": "Pematang Sidamanik",
    "value": "Pematang Sidamanik"
  },
  {
    "id": "120811",
    "regency_id": "1208",
    "label": "Tanah Jawa",
    "value": "Tanah Jawa"
  },
  {
    "id": "120812",
    "regency_id": "1208",
    "label": "Hatonduhan",
    "value": "Hatonduhan"
  },
  {
    "id": "120813",
    "regency_id": "1208",
    "label": "Dolok Panribuan",
    "value": "Dolok Panribuan"
  },
  { "id": "120814", "regency_id": "1208", "label": "Purba", "value": "Purba" },
  {
    "id": "120815",
    "regency_id": "1208",
    "label": "Haranggaol Horison",
    "value": "Haranggaol Horison"
  },
  {
    "id": "120816",
    "regency_id": "1208",
    "label": "Girsang Sipangan Bolon",
    "value": "Girsang Sipangan Bolon"
  },
  {
    "id": "120817",
    "regency_id": "1208",
    "label": "Dolok Batu Nanggar",
    "value": "Dolok Batu Nanggar"
  },
  {
    "id": "120818",
    "regency_id": "1208",
    "label": "Huta Bayu Raja",
    "value": "Huta Bayu Raja"
  },
  {
    "id": "120819",
    "regency_id": "1208",
    "label": "Jawa Maraja Bah Jambi",
    "value": "Jawa Maraja Bah Jambi"
  },
  {
    "id": "120820",
    "regency_id": "1208",
    "label": "Dolok Pardamean",
    "value": "Dolok Pardamean"
  },
  {
    "id": "120821",
    "regency_id": "1208",
    "label": "Pematang Bandar",
    "value": "Pematang Bandar"
  },
  {
    "id": "120822",
    "regency_id": "1208",
    "label": "Bandar Huluan",
    "value": "Bandar Huluan"
  },
  {
    "id": "120823",
    "regency_id": "1208",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "120824",
    "regency_id": "1208",
    "label": "Bandar Masilam",
    "value": "Bandar Masilam"
  },
  {
    "id": "120825",
    "regency_id": "1208",
    "label": "Silimakuta",
    "value": "Silimakuta"
  },
  {
    "id": "120826",
    "regency_id": "1208",
    "label": "Dolok Silau",
    "value": "Dolok Silau"
  },
  {
    "id": "120827",
    "regency_id": "1208",
    "label": "Silou Kahean",
    "value": "Silou Kahean"
  },
  {
    "id": "120828",
    "regency_id": "1208",
    "label": "Tapian Dolok",
    "value": "Tapian Dolok"
  },
  { "id": "120829", "regency_id": "1208", "label": "Raya", "value": "Raya" },
  {
    "id": "120830",
    "regency_id": "1208",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "120831",
    "regency_id": "1208",
    "label": "Pamatang Silima Huta",
    "value": "Pamatang Silima Huta"
  },
  {
    "id": "120832",
    "regency_id": "1208",
    "label": "Dolog Masagal",
    "value": "Dolog Masagal"
  },
  {
    "id": "120908",
    "regency_id": "1209",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "120909",
    "regency_id": "1209",
    "label": "Air Joman",
    "value": "Air Joman"
  },
  {
    "id": "120910",
    "regency_id": "1209",
    "label": "Tanjung Balai",
    "value": "Tanjung Balai"
  },
  {
    "id": "120911",
    "regency_id": "1209",
    "label": "Sei Kepayang",
    "value": "Sei Kepayang"
  },
  {
    "id": "120912",
    "regency_id": "1209",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "120913",
    "regency_id": "1209",
    "label": "Air Batu",
    "value": "Air Batu"
  },
  {
    "id": "120914",
    "regency_id": "1209",
    "label": "Pulau Rakyat",
    "value": "Pulau Rakyat"
  },
  {
    "id": "120915",
    "regency_id": "1209",
    "label": "Bandar Pulau",
    "value": "Bandar Pulau"
  },
  {
    "id": "120916",
    "regency_id": "1209",
    "label": "Buntu Pane",
    "value": "Buntu Pane"
  },
  {
    "id": "120917",
    "regency_id": "1209",
    "label": "Bandar Pasir Mandoge",
    "value": "Bandar Pasir Mandoge"
  },
  {
    "id": "120918",
    "regency_id": "1209",
    "label": "Aek Kuasan",
    "value": "Aek Kuasan"
  },
  {
    "id": "120919",
    "regency_id": "1209",
    "label": "Kota Kisaran Barat",
    "value": "Kota Kisaran Barat"
  },
  {
    "id": "120920",
    "regency_id": "1209",
    "label": "Kota Kisaran Timur",
    "value": "Kota Kisaran Timur"
  },
  {
    "id": "120921",
    "regency_id": "1209",
    "label": "Aek Songsongan",
    "value": "Aek Songsongan"
  },
  {
    "id": "120922",
    "regency_id": "1209",
    "label": "Rahunig",
    "value": "Rahunig"
  },
  {
    "id": "120923",
    "regency_id": "1209",
    "label": "Sei Dadap",
    "value": "Sei Dadap"
  },
  {
    "id": "120924",
    "regency_id": "1209",
    "label": "Sei Kepayang Barat",
    "value": "Sei Kepayang Barat"
  },
  {
    "id": "120925",
    "regency_id": "1209",
    "label": "Sei Kepayang Timur",
    "value": "Sei Kepayang Timur"
  },
  {
    "id": "120926",
    "regency_id": "1209",
    "label": "Tinggi Raja",
    "value": "Tinggi Raja"
  },
  {
    "id": "120927",
    "regency_id": "1209",
    "label": "Setia Janji",
    "value": "Setia Janji"
  },
  {
    "id": "120928",
    "regency_id": "1209",
    "label": "Silau Laut",
    "value": "Silau Laut"
  },
  {
    "id": "120929",
    "regency_id": "1209",
    "label": "Rawang Panca Arga",
    "value": "Rawang Panca Arga"
  },
  {
    "id": "120930",
    "regency_id": "1209",
    "label": "Pulo Bandring",
    "value": "Pulo Bandring"
  },
  {
    "id": "120931",
    "regency_id": "1209",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "120932",
    "regency_id": "1209",
    "label": "Aek Ledong",
    "value": "Aek Ledong"
  },
  {
    "id": "121001",
    "regency_id": "1210",
    "label": "Rantau Utara",
    "value": "Rantau Utara"
  },
  {
    "id": "121002",
    "regency_id": "1210",
    "label": "Rantau Selatan",
    "value": "Rantau Selatan"
  },
  {
    "id": "121007",
    "regency_id": "1210",
    "label": "Bilah Barat",
    "value": "Bilah Barat"
  },
  {
    "id": "121008",
    "regency_id": "1210",
    "label": "Bilah Hilir",
    "value": "Bilah Hilir"
  },
  {
    "id": "121009",
    "regency_id": "1210",
    "label": "Bilah Hulu",
    "value": "Bilah Hulu"
  },
  {
    "id": "121014",
    "regency_id": "1210",
    "label": "Pangkatan",
    "value": "Pangkatan"
  },
  {
    "id": "121018",
    "regency_id": "1210",
    "label": "Panai Tengah",
    "value": "Panai Tengah"
  },
  {
    "id": "121019",
    "regency_id": "1210",
    "label": "Panai Hilir",
    "value": "Panai Hilir"
  },
  {
    "id": "121020",
    "regency_id": "1210",
    "label": "Panai Hulu",
    "value": "Panai Hulu"
  },
  {
    "id": "121101",
    "regency_id": "1211",
    "label": "Sidikalang",
    "value": "Sidikalang"
  },
  {
    "id": "121102",
    "regency_id": "1211",
    "label": "Sumbul",
    "value": "Sumbul"
  },
  {
    "id": "121103",
    "regency_id": "1211",
    "label": "Tigalingga",
    "value": "Tigalingga"
  },
  {
    "id": "121104",
    "regency_id": "1211",
    "label": "Siempat Nempu",
    "value": "Siempat Nempu"
  },
  {
    "id": "121105",
    "regency_id": "1211",
    "label": "Silima Pungga Pungga",
    "value": "Silima Pungga Pungga"
  },
  {
    "id": "121106",
    "regency_id": "1211",
    "label": "Tanah Pinem",
    "value": "Tanah Pinem"
  },
  {
    "id": "121107",
    "regency_id": "1211",
    "label": "Siempat Nempu Hulu",
    "value": "Siempat Nempu Hulu"
  },
  {
    "id": "121108",
    "regency_id": "1211",
    "label": "Siempat Nempu Hilir",
    "value": "Siempat Nempu Hilir"
  },
  {
    "id": "121109",
    "regency_id": "1211",
    "label": "Pegagan Hilir",
    "value": "Pegagan Hilir"
  },
  {
    "id": "121110",
    "regency_id": "1211",
    "label": "Parbuluan",
    "value": "Parbuluan"
  },
  {
    "id": "121111",
    "regency_id": "1211",
    "label": "Lae Parira",
    "value": "Lae Parira"
  },
  {
    "id": "121112",
    "regency_id": "1211",
    "label": "Gunung Sitember",
    "value": "Gunung Sitember"
  },
  {
    "id": "121113",
    "regency_id": "1211",
    "label": "Berampu",
    "value": "Berampu"
  },
  {
    "id": "121114",
    "regency_id": "1211",
    "label": "Silahisabungan",
    "value": "Silahisabungan"
  },
  {
    "id": "121115",
    "regency_id": "1211",
    "label": "Sitinjo",
    "value": "Sitinjo"
  },
  {
    "id": "121201",
    "regency_id": "1212",
    "label": "Balige",
    "value": "Balige"
  },
  {
    "id": "121202",
    "regency_id": "1212",
    "label": "Laguboti",
    "value": "Laguboti"
  },
  {
    "id": "121203",
    "regency_id": "1212",
    "label": "Silaen",
    "value": "Silaen"
  },
  {
    "id": "121204",
    "regency_id": "1212",
    "label": "Habinsaran",
    "value": "Habinsaran"
  },
  {
    "id": "121205",
    "regency_id": "1212",
    "label": "Pintu Pohan Meranti",
    "value": "Pintu Pohan Meranti"
  },
  {
    "id": "121206",
    "regency_id": "1212",
    "label": "Borbor",
    "value": "Borbor"
  },
  {
    "id": "121207",
    "regency_id": "1212",
    "label": "Porsea",
    "value": "Porsea"
  },
  {
    "id": "121208",
    "regency_id": "1212",
    "label": "Ajibata",
    "value": "Ajibata"
  },
  {
    "id": "121209",
    "regency_id": "1212",
    "label": "Lumban Julu",
    "value": "Lumban Julu"
  },
  { "id": "121210", "regency_id": "1212", "label": "Uluan", "value": "Uluan" },
  {
    "id": "121219",
    "regency_id": "1212",
    "label": "Sigumpar",
    "value": "Sigumpar"
  },
  {
    "id": "121220",
    "regency_id": "1212",
    "label": "Siantar Narumonda",
    "value": "Siantar Narumonda"
  },
  {
    "id": "121221",
    "regency_id": "1212",
    "label": "Nassau",
    "value": "Nassau"
  },
  {
    "id": "121222",
    "regency_id": "1212",
    "label": "Tampahan",
    "value": "Tampahan"
  },
  {
    "id": "121223",
    "regency_id": "1212",
    "label": "Bonatua Lunasi",
    "value": "Bonatua Lunasi"
  },
  {
    "id": "121224",
    "regency_id": "1212",
    "label": "Parmaksian",
    "value": "Parmaksian"
  },
  {
    "id": "121301",
    "regency_id": "1213",
    "label": "Panyabungan",
    "value": "Panyabungan"
  },
  {
    "id": "121302",
    "regency_id": "1213",
    "label": "Panyabungan Utara",
    "value": "Panyabungan Utara"
  },
  {
    "id": "121303",
    "regency_id": "1213",
    "label": "Panyabungan Timur",
    "value": "Panyabungan Timur"
  },
  {
    "id": "121304",
    "regency_id": "1213",
    "label": "Panyabungan Selatan",
    "value": "Panyabungan Selatan"
  },
  {
    "id": "121305",
    "regency_id": "1213",
    "label": "Panyabungan Barat",
    "value": "Panyabungan Barat"
  },
  { "id": "121306", "regency_id": "1213", "label": "Siabu", "value": "Siabu" },
  {
    "id": "121307",
    "regency_id": "1213",
    "label": "Bukit Malintang",
    "value": "Bukit Malintang"
  },
  {
    "id": "121308",
    "regency_id": "1213",
    "label": "Kotanopan",
    "value": "Kotanopan"
  },
  {
    "id": "121309",
    "regency_id": "1213",
    "label": "Lembah Sorik Marapi",
    "value": "Lembah Sorik Marapi"
  },
  {
    "id": "121310",
    "regency_id": "1213",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "121311",
    "regency_id": "1213",
    "label": "Ulu Pungkut",
    "value": "Ulu Pungkut"
  },
  {
    "id": "121312",
    "regency_id": "1213",
    "label": "Muara Sipongi",
    "value": "Muara Sipongi"
  },
  {
    "id": "121313",
    "regency_id": "1213",
    "label": "Batang Natal",
    "value": "Batang Natal"
  },
  {
    "id": "121314",
    "regency_id": "1213",
    "label": "Lingga Bayu",
    "value": "Lingga Bayu"
  },
  {
    "id": "121315",
    "regency_id": "1213",
    "label": "Batahan",
    "value": "Batahan"
  },
  { "id": "121316", "regency_id": "1213", "label": "Natal", "value": "Natal" },
  {
    "id": "121317",
    "regency_id": "1213",
    "label": "Muara Batang Gadis",
    "value": "Muara Batang Gadis"
  },
  {
    "id": "121318",
    "regency_id": "1213",
    "label": "Ranto Baek",
    "value": "Ranto Baek"
  },
  {
    "id": "121319",
    "regency_id": "1213",
    "label": "Huta Bargot",
    "value": "Huta Bargot"
  },
  {
    "id": "121320",
    "regency_id": "1213",
    "label": "Puncak Sorik Marapi",
    "value": "Puncak Sorik Marapi"
  },
  {
    "id": "121321",
    "regency_id": "1213",
    "label": "Pakantan",
    "value": "Pakantan"
  },
  {
    "id": "121322",
    "regency_id": "1213",
    "label": "Sinunukan",
    "value": "Sinunukan"
  },
  {
    "id": "121323",
    "regency_id": "1213",
    "label": "Naga Juang",
    "value": "Naga Juang"
  },
  {
    "id": "121401",
    "regency_id": "1214",
    "label": "Lolomatua",
    "value": "Lolomatua"
  },
  { "id": "121402", "regency_id": "1214", "label": "Gomo", "value": "Gomo" },
  {
    "id": "121403",
    "regency_id": "1214",
    "label": "Lahusa",
    "value": "Lahusa"
  },
  {
    "id": "121404",
    "regency_id": "1214",
    "label": "Hibala",
    "value": "Hibala"
  },
  {
    "id": "121405",
    "regency_id": "1214",
    "label": "Pulau-Pulau Batu",
    "value": "Pulau-Pulau Batu"
  },
  {
    "id": "121406",
    "regency_id": "1214",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "121407",
    "regency_id": "1214",
    "label": "Amandraya",
    "value": "Amandraya"
  },
  {
    "id": "121408",
    "regency_id": "1214",
    "label": "Lolowau",
    "value": "Lolowau"
  },
  { "id": "121409", "regency_id": "1214", "label": "Susua", "value": "Susua" },
  {
    "id": "121410",
    "regency_id": "1214",
    "label": "Maniamolo",
    "value": "Maniamolo"
  },
  {
    "id": "121411",
    "regency_id": "1214",
    "label": "Hilimegai",
    "value": "Hilimegai"
  },
  { "id": "121412", "regency_id": "1214", "label": "Toma", "value": "Toma" },
  {
    "id": "121413",
    "regency_id": "1214",
    "label": "Mazino",
    "value": "Mazino"
  },
  {
    "id": "121414",
    "regency_id": "1214",
    "label": "Umbunasi",
    "value": "Umbunasi"
  },
  { "id": "121415", "regency_id": "1214", "label": "Aramo", "value": "Aramo" },
  {
    "id": "121416",
    "regency_id": "1214",
    "label": "Pulau-Pulau Batu Timur",
    "value": "Pulau-Pulau Batu Timur"
  },
  { "id": "121417", "regency_id": "1214", "label": "Mazo", "value": "Mazo" },
  {
    "id": "121418",
    "regency_id": "1214",
    "label": "Fanayama",
    "value": "Fanayama"
  },
  {
    "id": "121419",
    "regency_id": "1214",
    "label": "Ulunoyo",
    "value": "Ulunoyo"
  },
  {
    "id": "121420",
    "regency_id": "1214",
    "label": "Huruna",
    "value": "Huruna"
  },
  { "id": "121421", "regency_id": "1214", "label": "O'o'u", "value": "O'o'u" },
  {
    "id": "121422",
    "regency_id": "1214",
    "label": "Onohazumba",
    "value": "Onohazumba"
  },
  {
    "id": "121423",
    "regency_id": "1214",
    "label": "Hilisalawa'ahe",
    "value": "Hilisalawa'ahe"
  },
  {
    "id": "121424",
    "regency_id": "1214",
    "label": "Ulususua",
    "value": "Ulususua"
  },
  {
    "id": "121425",
    "regency_id": "1214",
    "label": "Sidua'ori",
    "value": "Sidua'ori"
  },
  {
    "id": "121426",
    "regency_id": "1214",
    "label": "Somambawa",
    "value": "Somambawa"
  },
  {
    "id": "121427",
    "regency_id": "1214",
    "label": "Boronadu",
    "value": "Boronadu"
  },
  { "id": "121428", "regency_id": "1214", "label": "Simuk", "value": "Simuk" },
  {
    "id": "121429",
    "regency_id": "1214",
    "label": "Pulau-Pulau Batu Barat",
    "value": "Pulau-Pulau Batu Barat"
  },
  {
    "id": "121430",
    "regency_id": "1214",
    "label": "Pulau-Pulau Batu Utara",
    "value": "Pulau-Pulau Batu Utara"
  },
  {
    "id": "121431",
    "regency_id": "1214",
    "label": "Tanah Masa",
    "value": "Tanah Masa"
  },
  {
    "id": "121432",
    "regency_id": "1214",
    "label": "Luahagundre Maniamolo",
    "value": "Luahagundre Maniamolo"
  },
  {
    "id": "121433",
    "regency_id": "1214",
    "label": "Onolalu",
    "value": "Onolalu"
  },
  {
    "id": "121434",
    "regency_id": "1214",
    "label": "Ulu Idanotae",
    "value": "Ulu Idanotae"
  },
  {
    "id": "121435",
    "regency_id": "1214",
    "label": "Idanotae",
    "value": "Idanotae"
  },
  {
    "id": "121501",
    "regency_id": "1215",
    "label": "Sitelu Tali Urang Jehe",
    "value": "Sitelu Tali Urang Jehe"
  },
  {
    "id": "121502",
    "regency_id": "1215",
    "label": "Kerajaan",
    "value": "Kerajaan"
  },
  { "id": "121503", "regency_id": "1215", "label": "Salak", "value": "Salak" },
  {
    "id": "121504",
    "regency_id": "1215",
    "label": "Sitelu Tali Urang Julu",
    "value": "Sitelu Tali Urang Julu"
  },
  {
    "id": "121505",
    "regency_id": "1215",
    "label": "Pergetteng Getteng Sengkut",
    "value": "Pergetteng Getteng Sengkut"
  },
  {
    "id": "121506",
    "regency_id": "1215",
    "label": "Pagindar",
    "value": "Pagindar"
  },
  {
    "id": "121507",
    "regency_id": "1215",
    "label": "Tinada",
    "value": "Tinada"
  },
  {
    "id": "121508",
    "regency_id": "1215",
    "label": "Siempat Rube",
    "value": "Siempat Rube"
  },
  {
    "id": "121601",
    "regency_id": "1216",
    "label": "Parlilitan",
    "value": "Parlilitan"
  },
  {
    "id": "121602",
    "regency_id": "1216",
    "label": "Pollung",
    "value": "Pollung"
  },
  {
    "id": "121603",
    "regency_id": "1216",
    "label": "Baktiraja",
    "value": "Baktiraja"
  },
  {
    "id": "121604",
    "regency_id": "1216",
    "label": "Paranginan",
    "value": "Paranginan"
  },
  {
    "id": "121605",
    "regency_id": "1216",
    "label": "Lintong Nihuta",
    "value": "Lintong Nihuta"
  },
  {
    "id": "121606",
    "regency_id": "1216",
    "label": "Dolok Sanggul",
    "value": "Dolok Sanggul"
  },
  {
    "id": "121607",
    "regency_id": "1216",
    "label": "Sijamapolang",
    "value": "Sijamapolang"
  },
  {
    "id": "121608",
    "regency_id": "1216",
    "label": "Onan Ganjang",
    "value": "Onan Ganjang"
  },
  {
    "id": "121609",
    "regency_id": "1216",
    "label": "Pakkat",
    "value": "Pakkat"
  },
  {
    "id": "121610",
    "regency_id": "1216",
    "label": "Tarabintang",
    "value": "Tarabintang"
  },
  {
    "id": "121701",
    "regency_id": "1217",
    "label": "Simanindo",
    "value": "Simanindo"
  },
  {
    "id": "121702",
    "regency_id": "1217",
    "label": "Onan Runggu",
    "value": "Onan Runggu"
  },
  {
    "id": "121703",
    "regency_id": "1217",
    "label": "Nainggolan",
    "value": "Nainggolan"
  },
  {
    "id": "121704",
    "regency_id": "1217",
    "label": "Palipi",
    "value": "Palipi"
  },
  {
    "id": "121705",
    "regency_id": "1217",
    "label": "Harian",
    "value": "Harian"
  },
  {
    "id": "121706",
    "regency_id": "1217",
    "label": "Sianjar Mula Mula",
    "value": "Sianjar Mula Mula"
  },
  {
    "id": "121707",
    "regency_id": "1217",
    "label": "Ronggur Nihuta",
    "value": "Ronggur Nihuta"
  },
  {
    "id": "121708",
    "regency_id": "1217",
    "label": "Pangururan",
    "value": "Pangururan"
  },
  {
    "id": "121709",
    "regency_id": "1217",
    "label": "Sitio-tio",
    "value": "Sitio-tio"
  },
  {
    "id": "121801",
    "regency_id": "1218",
    "label": "Pantai Cermin",
    "value": "Pantai Cermin"
  },
  {
    "id": "121802",
    "regency_id": "1218",
    "label": "Perbaungan",
    "value": "Perbaungan"
  },
  {
    "id": "121803",
    "regency_id": "1218",
    "label": "Teluk Mengkudu",
    "value": "Teluk Mengkudu"
  },
  {
    "id": "121804",
    "regency_id": "1218",
    "label": "Sei Rampah",
    "value": "Sei Rampah"
  },
  {
    "id": "121805",
    "regency_id": "1218",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "121806",
    "regency_id": "1218",
    "label": "Bandar Khalipah",
    "value": "Bandar Khalipah"
  },
  {
    "id": "121807",
    "regency_id": "1218",
    "label": "Dolok Merawan",
    "value": "Dolok Merawan"
  },
  {
    "id": "121808",
    "regency_id": "1218",
    "label": "Sipispis",
    "value": "Sipispis"
  },
  {
    "id": "121809",
    "regency_id": "1218",
    "label": "Dolok Masihul",
    "value": "Dolok Masihul"
  },
  {
    "id": "121810",
    "regency_id": "1218",
    "label": "Kotarih",
    "value": "Kotarih"
  },
  {
    "id": "121811",
    "regency_id": "1218",
    "label": "Silinda",
    "value": "Silinda"
  },
  {
    "id": "121812",
    "regency_id": "1218",
    "label": "Serba Jadi",
    "value": "Serba Jadi"
  },
  {
    "id": "121813",
    "regency_id": "1218",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "121814",
    "regency_id": "1218",
    "label": "Pegajahan",
    "value": "Pegajahan"
  },
  {
    "id": "121815",
    "regency_id": "1218",
    "label": "Sei Bamban",
    "value": "Sei Bamban"
  },
  {
    "id": "121816",
    "regency_id": "1218",
    "label": "Tebing Syahbandar",
    "value": "Tebing Syahbandar"
  },
  {
    "id": "121817",
    "regency_id": "1218",
    "label": "Bintang Bayu",
    "value": "Bintang Bayu"
  },
  {
    "id": "121901",
    "regency_id": "1219",
    "label": "Medang Deras",
    "value": "Medang Deras"
  },
  {
    "id": "121902",
    "regency_id": "1219",
    "label": "Sei Suka",
    "value": "Sei Suka"
  },
  {
    "id": "121903",
    "regency_id": "1219",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "121904",
    "regency_id": "1219",
    "label": "Lima Puluh",
    "value": "Lima Puluh"
  },
  {
    "id": "121905",
    "regency_id": "1219",
    "label": "Talawi",
    "value": "Talawi"
  },
  {
    "id": "121906",
    "regency_id": "1219",
    "label": "Tanjung Tiram",
    "value": "Tanjung Tiram"
  },
  {
    "id": "121907",
    "regency_id": "1219",
    "label": "Sei Balai",
    "value": "Sei Balai"
  },
  {
    "id": "121908",
    "regency_id": "1219",
    "label": "Laut Tador",
    "value": "Laut Tador"
  },
  {
    "id": "121909",
    "regency_id": "1219",
    "label": "Lima Puluh Pesisir",
    "value": "Lima Puluh Pesisir"
  },
  {
    "id": "121910",
    "regency_id": "1219",
    "label": "Datuk Lima Puluh",
    "value": "Datuk Lima Puluh"
  },
  {
    "id": "121911",
    "regency_id": "1219",
    "label": "Datuk Tanah Datar",
    "value": "Datuk Tanah Datar"
  },
  {
    "id": "121912",
    "regency_id": "1219",
    "label": "Nibung Hangus",
    "value": "Nibung Hangus"
  },
  {
    "id": "122001",
    "regency_id": "1220",
    "label": "Dolok Sigompulon",
    "value": "Dolok Sigompulon"
  },
  { "id": "122002", "regency_id": "1220", "label": "Dolok", "value": "Dolok" },
  {
    "id": "122003",
    "regency_id": "1220",
    "label": "Halongonan",
    "value": "Halongonan"
  },
  {
    "id": "122004",
    "regency_id": "1220",
    "label": "Padang Bolak",
    "value": "Padang Bolak"
  },
  {
    "id": "122005",
    "regency_id": "1220",
    "label": "Padang Bolak Julu",
    "value": "Padang Bolak Julu"
  },
  {
    "id": "122006",
    "regency_id": "1220",
    "label": "Portibi",
    "value": "Portibi"
  },
  {
    "id": "122007",
    "regency_id": "1220",
    "label": "Batang Onang",
    "value": "Batang Onang"
  },
  {
    "id": "122008",
    "regency_id": "1220",
    "label": "Simangambat",
    "value": "Simangambat"
  },
  {
    "id": "122009",
    "regency_id": "1220",
    "label": "Hulu Sihapas",
    "value": "Hulu Sihapas"
  },
  {
    "id": "122010",
    "regency_id": "1220",
    "label": "Padang Bolak Tenggara",
    "value": "Padang Bolak Tenggara"
  },
  {
    "id": "122011",
    "regency_id": "1220",
    "label": "Halongonan Timur",
    "value": "Halongonan Timur"
  },
  {
    "id": "122012",
    "regency_id": "1220",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "122101",
    "regency_id": "1221",
    "label": "Sosopan",
    "value": "Sosopan"
  },
  {
    "id": "122102",
    "regency_id": "1221",
    "label": "Barumun Tengah",
    "value": "Barumun Tengah"
  },
  {
    "id": "122103",
    "regency_id": "1221",
    "label": "Huristak",
    "value": "Huristak"
  },
  {
    "id": "122104",
    "regency_id": "1221",
    "label": "Lubuk Barumun",
    "value": "Lubuk Barumun"
  },
  {
    "id": "122105",
    "regency_id": "1221",
    "label": "Huta Raja Tinggi",
    "value": "Huta Raja Tinggi"
  },
  {
    "id": "122106",
    "regency_id": "1221",
    "label": "Ulu Barumun",
    "value": "Ulu Barumun"
  },
  {
    "id": "122107",
    "regency_id": "1221",
    "label": "Barumun",
    "value": "Barumun"
  },
  { "id": "122108", "regency_id": "1221", "label": "Sosa", "value": "Sosa" },
  {
    "id": "122109",
    "regency_id": "1221",
    "label": "Batang Lubu Sutam",
    "value": "Batang Lubu Sutam"
  },
  {
    "id": "122110",
    "regency_id": "1221",
    "label": "Barumun Selatan",
    "value": "Barumun Selatan"
  },
  {
    "id": "122111",
    "regency_id": "1221",
    "label": "Aek Nabara Barumun",
    "value": "Aek Nabara Barumun"
  },
  {
    "id": "122112",
    "regency_id": "1221",
    "label": "Sihapas Barumun",
    "value": "Sihapas Barumun"
  },
  {
    "id": "122201",
    "regency_id": "1222",
    "label": "Kotapinang",
    "value": "Kotapinang"
  },
  {
    "id": "122202",
    "regency_id": "1222",
    "label": "Kampung Rakyat",
    "value": "Kampung Rakyat"
  },
  {
    "id": "122203",
    "regency_id": "1222",
    "label": "Torgamba",
    "value": "Torgamba"
  },
  {
    "id": "122204",
    "regency_id": "1222",
    "label": "Sungai Kanan",
    "value": "Sungai Kanan"
  },
  {
    "id": "122205",
    "regency_id": "1222",
    "label": "Silangkitang",
    "value": "Silangkitang"
  },
  {
    "id": "122301",
    "regency_id": "1223",
    "label": "Kualuh Hulu",
    "value": "Kualuh Hulu"
  },
  {
    "id": "122302",
    "regency_id": "1223",
    "label": "Kualuh Leidong",
    "value": "Kualuh Leidong"
  },
  {
    "id": "122303",
    "regency_id": "1223",
    "label": "Kualuh Hilir",
    "value": "Kualuh Hilir"
  },
  {
    "id": "122304",
    "regency_id": "1223",
    "label": "Aek Kuo",
    "value": "Aek Kuo"
  },
  {
    "id": "122305",
    "regency_id": "1223",
    "label": "Marbau",
    "value": "Marbau"
  },
  {
    "id": "122306",
    "regency_id": "1223",
    "label": "Na IX - X",
    "value": "Na IX - X"
  },
  {
    "id": "122307",
    "regency_id": "1223",
    "label": "Aek Natas",
    "value": "Aek Natas"
  },
  {
    "id": "122308",
    "regency_id": "1223",
    "label": "Kualuh Selatan",
    "value": "Kualuh Selatan"
  },
  { "id": "122401", "regency_id": "1224", "label": "Lotu", "value": "Lotu" },
  { "id": "122402", "regency_id": "1224", "label": "Sawo", "value": "Sawo" },
  {
    "id": "122403",
    "regency_id": "1224",
    "label": "Tuhemberua",
    "value": "Tuhemberua"
  },
  {
    "id": "122404",
    "regency_id": "1224",
    "label": "Sitolu Ori",
    "value": "Sitolu Ori"
  },
  {
    "id": "122405",
    "regency_id": "1224",
    "label": "Namohalu Esiwa",
    "value": "Namohalu Esiwa"
  },
  {
    "id": "122406",
    "regency_id": "1224",
    "label": "Alasa Talumuzoi",
    "value": "Alasa Talumuzoi"
  },
  { "id": "122407", "regency_id": "1224", "label": "Alasa", "value": "Alasa" },
  {
    "id": "122408",
    "regency_id": "1224",
    "label": "Tugala Oyo",
    "value": "Tugala Oyo"
  },
  { "id": "122409", "regency_id": "1224", "label": "Afulu", "value": "Afulu" },
  {
    "id": "122410",
    "regency_id": "1224",
    "label": "Lahewa",
    "value": "Lahewa"
  },
  {
    "id": "122411",
    "regency_id": "1224",
    "label": "Lahewa Timur",
    "value": "Lahewa Timur"
  },
  {
    "id": "122501",
    "regency_id": "1225",
    "label": "Lahomi",
    "value": "Lahomi"
  },
  {
    "id": "122502",
    "regency_id": "1225",
    "label": "Sirombu",
    "value": "Sirombu"
  },
  {
    "id": "122503",
    "regency_id": "1225",
    "label": "Mandrehe Barat",
    "value": "Mandrehe Barat"
  },
  {
    "id": "122504",
    "regency_id": "1225",
    "label": "Moro'o",
    "value": "Moro'o"
  },
  {
    "id": "122505",
    "regency_id": "1225",
    "label": "Mandrehe",
    "value": "Mandrehe"
  },
  {
    "id": "122506",
    "regency_id": "1225",
    "label": "Mandrehe Utara",
    "value": "Mandrehe Utara"
  },
  {
    "id": "122507",
    "regency_id": "1225",
    "label": "Lolofitu Moi",
    "value": "Lolofitu Moi"
  },
  {
    "id": "122508",
    "regency_id": "1225",
    "label": "Ulu Moro'o",
    "value": "Ulu Moro'o"
  },
  {
    "id": "127101",
    "regency_id": "1271",
    "label": "Medan Kota",
    "value": "Medan Kota"
  },
  {
    "id": "127102",
    "regency_id": "1271",
    "label": "Medan Sunggal",
    "value": "Medan Sunggal"
  },
  {
    "id": "127103",
    "regency_id": "1271",
    "label": "Medan Helvetia",
    "value": "Medan Helvetia"
  },
  {
    "id": "127104",
    "regency_id": "1271",
    "label": "Medan Denai",
    "value": "Medan Denai"
  },
  {
    "id": "127105",
    "regency_id": "1271",
    "label": "Medan Barat",
    "value": "Medan Barat"
  },
  {
    "id": "127106",
    "regency_id": "1271",
    "label": "Medan Deli",
    "value": "Medan Deli"
  },
  {
    "id": "127107",
    "regency_id": "1271",
    "label": "Medan Tuntungan",
    "value": "Medan Tuntungan"
  },
  {
    "id": "127108",
    "regency_id": "1271",
    "label": "Medan Belawan",
    "value": "Medan Belawan"
  },
  {
    "id": "127109",
    "regency_id": "1271",
    "label": "Medan Amplas",
    "value": "Medan Amplas"
  },
  {
    "id": "127110",
    "regency_id": "1271",
    "label": "Medan Area",
    "value": "Medan Area"
  },
  {
    "id": "127111",
    "regency_id": "1271",
    "label": "Medan Johor",
    "value": "Medan Johor"
  },
  {
    "id": "127112",
    "regency_id": "1271",
    "label": "Medan Marelan",
    "value": "Medan Marelan"
  },
  {
    "id": "127113",
    "regency_id": "1271",
    "label": "Medan Labuhan",
    "value": "Medan Labuhan"
  },
  {
    "id": "127114",
    "regency_id": "1271",
    "label": "Medan Tembung",
    "value": "Medan Tembung"
  },
  {
    "id": "127115",
    "regency_id": "1271",
    "label": "Medan Maimun",
    "value": "Medan Maimun"
  },
  {
    "id": "127116",
    "regency_id": "1271",
    "label": "Medan Polonia",
    "value": "Medan Polonia"
  },
  {
    "id": "127117",
    "regency_id": "1271",
    "label": "Medan Baru",
    "value": "Medan Baru"
  },
  {
    "id": "127118",
    "regency_id": "1271",
    "label": "Medan Perjuangan",
    "value": "Medan Perjuangan"
  },
  {
    "id": "127119",
    "regency_id": "1271",
    "label": "Medan Petisah",
    "value": "Medan Petisah"
  },
  {
    "id": "127120",
    "regency_id": "1271",
    "label": "Medan Timur",
    "value": "Medan Timur"
  },
  {
    "id": "127121",
    "regency_id": "1271",
    "label": "Medan Selayang",
    "value": "Medan Selayang"
  },
  {
    "id": "127201",
    "regency_id": "1272",
    "label": "Siantar Timur",
    "value": "Siantar Timur"
  },
  {
    "id": "127202",
    "regency_id": "1272",
    "label": "Siantar Barat",
    "value": "Siantar Barat"
  },
  {
    "id": "127203",
    "regency_id": "1272",
    "label": "Siantar Utara",
    "value": "Siantar Utara"
  },
  {
    "id": "127204",
    "regency_id": "1272",
    "label": "Siantar Selatan",
    "value": "Siantar Selatan"
  },
  {
    "id": "127205",
    "regency_id": "1272",
    "label": "Siantar Marihat",
    "value": "Siantar Marihat"
  },
  {
    "id": "127206",
    "regency_id": "1272",
    "label": "Siantar Martoba",
    "value": "Siantar Martoba"
  },
  {
    "id": "127207",
    "regency_id": "1272",
    "label": "Siantar Sitalasari",
    "value": "Siantar Sitalasari"
  },
  {
    "id": "127208",
    "regency_id": "1272",
    "label": "Siantar Marimbun",
    "value": "Siantar Marimbun"
  },
  {
    "id": "127301",
    "regency_id": "1273",
    "label": "Sibolga Utara",
    "value": "Sibolga Utara"
  },
  {
    "id": "127302",
    "regency_id": "1273",
    "label": "Sibolga Kota",
    "value": "Sibolga Kota"
  },
  {
    "id": "127303",
    "regency_id": "1273",
    "label": "Sibolga Selatan",
    "value": "Sibolga Selatan"
  },
  {
    "id": "127304",
    "regency_id": "1273",
    "label": "Sibolga Sambas",
    "value": "Sibolga Sambas"
  },
  {
    "id": "127401",
    "regency_id": "1274",
    "label": "Tanjung Balai Selatan",
    "value": "Tanjung Balai Selatan"
  },
  {
    "id": "127402",
    "regency_id": "1274",
    "label": "Tanjung Balai Utara",
    "value": "Tanjung Balai Utara"
  },
  {
    "id": "127403",
    "regency_id": "1274",
    "label": "Sei Tualang Raso",
    "value": "Sei Tualang Raso"
  },
  {
    "id": "127404",
    "regency_id": "1274",
    "label": "Teluk Nibung",
    "value": "Teluk Nibung"
  },
  {
    "id": "127405",
    "regency_id": "1274",
    "label": "Datuk Bandar",
    "value": "Datuk Bandar"
  },
  {
    "id": "127406",
    "regency_id": "1274",
    "label": "Datuk Bandar Timur",
    "value": "Datuk Bandar Timur"
  },
  {
    "id": "127501",
    "regency_id": "1275",
    "label": "Binjai Utara",
    "value": "Binjai Utara"
  },
  {
    "id": "127502",
    "regency_id": "1275",
    "label": "Binjai Kota",
    "value": "Binjai Kota"
  },
  {
    "id": "127503",
    "regency_id": "1275",
    "label": "Binjai Barat",
    "value": "Binjai Barat"
  },
  {
    "id": "127504",
    "regency_id": "1275",
    "label": "Binjai Timur",
    "value": "Binjai Timur"
  },
  {
    "id": "127505",
    "regency_id": "1275",
    "label": "Binjai Selatan",
    "value": "Binjai Selatan"
  },
  {
    "id": "127601",
    "regency_id": "1276",
    "label": "Padang Hulu",
    "value": "Padang Hulu"
  },
  {
    "id": "127602",
    "regency_id": "1276",
    "label": "Rambutan",
    "value": "Rambutan"
  },
  {
    "id": "127603",
    "regency_id": "1276",
    "label": "Padang Hilir",
    "value": "Padang Hilir"
  },
  {
    "id": "127604",
    "regency_id": "1276",
    "label": "Bajenis",
    "value": "Bajenis"
  },
  {
    "id": "127605",
    "regency_id": "1276",
    "label": "Tebing Tinggi Kota",
    "value": "Tebing Tinggi Kota"
  },
  {
    "id": "127701",
    "regency_id": "1277",
    "label": "Padangsidimpuan Utara",
    "value": "Padangsidimpuan Utara"
  },
  {
    "id": "127702",
    "regency_id": "1277",
    "label": "Padangsidimpuan Selatan",
    "value": "Padangsidimpuan Selatan"
  },
  {
    "id": "127703",
    "regency_id": "1277",
    "label": "Padangsidimpuan Batunadu",
    "value": "Padangsidimpuan Batunadu"
  },
  {
    "id": "127704",
    "regency_id": "1277",
    "label": "Padangsidimpuan Hutaimbar",
    "value": "Padangsidimpuan Hutaimbar"
  },
  {
    "id": "127705",
    "regency_id": "1277",
    "label": "Padangsidimpuan Tenggara",
    "value": "Padangsidimpuan Tenggara"
  },
  {
    "id": "127706",
    "regency_id": "1277",
    "label": "Padangsidimpuan Angkola Julu",
    "value": "Padangsidimpuan Angkola Julu"
  },
  {
    "id": "127801",
    "regency_id": "1278",
    "label": "Gunungsitoli",
    "value": "Gunungsitoli"
  },
  {
    "id": "127802",
    "regency_id": "1278",
    "label": "Gunungsitoli Selatan",
    "value": "Gunungsitoli Selatan"
  },
  {
    "id": "127803",
    "regency_id": "1278",
    "label": "Gunungsitoli Utara",
    "value": "Gunungsitoli Utara"
  },
  {
    "id": "127804",
    "regency_id": "1278",
    "label": "Gunungsitoli Idanoi",
    "value": "Gunungsitoli Idanoi"
  },
  {
    "id": "127805",
    "regency_id": "1278",
    "label": "Gunungsitoli Alo'oa",
    "value": "Gunungsitoli Alo'oa"
  },
  {
    "id": "127806",
    "regency_id": "1278",
    "label": "Gunungsitoli Barat",
    "value": "Gunungsitoli Barat"
  },
  {
    "id": "130101",
    "regency_id": "1301",
    "label": "Pancung Soal",
    "value": "Pancung Soal"
  },
  {
    "id": "130102",
    "regency_id": "1301",
    "label": "Ranah Pesisir",
    "value": "Ranah Pesisir"
  },
  {
    "id": "130103",
    "regency_id": "1301",
    "label": "Lengayang",
    "value": "Lengayang"
  },
  {
    "id": "130104",
    "regency_id": "1301",
    "label": "Batang Kapas",
    "value": "Batang Kapas"
  },
  {
    "id": "130105",
    "regency_id": "1301",
    "label": "IV Jurai",
    "value": "IV Jurai"
  },
  {
    "id": "130106",
    "regency_id": "1301",
    "label": "Bayang",
    "value": "Bayang"
  },
  {
    "id": "130107",
    "regency_id": "1301",
    "label": "Koto XI Tarusan",
    "value": "Koto XI Tarusan"
  },
  {
    "id": "130108",
    "regency_id": "1301",
    "label": "Sutera",
    "value": "Sutera"
  },
  {
    "id": "130109",
    "regency_id": "1301",
    "label": "Linggo Sari Baganti",
    "value": "Linggo Sari Baganti"
  },
  {
    "id": "130110",
    "regency_id": "1301",
    "label": "Lunang",
    "value": "Lunang"
  },
  {
    "id": "130111",
    "regency_id": "1301",
    "label": "Basa Ampek Balai Tapan",
    "value": "Basa Ampek Balai Tapan"
  },
  {
    "id": "130112",
    "regency_id": "1301",
    "label": "IV Nagari Bayang Utara",
    "value": "IV Nagari Bayang Utara"
  },
  {
    "id": "130113",
    "regency_id": "1301",
    "label": "Airpura",
    "value": "Airpura"
  },
  {
    "id": "130114",
    "regency_id": "1301",
    "label": "Ranah Ampek Hulu Tapan",
    "value": "Ranah Ampek Hulu Tapan"
  },
  {
    "id": "130115",
    "regency_id": "1301",
    "label": "Silaut",
    "value": "Silaut"
  },
  {
    "id": "130203",
    "regency_id": "1302",
    "label": "Pantai Cermin",
    "value": "Pantai Cermin"
  },
  {
    "id": "130204",
    "regency_id": "1302",
    "label": "Lembah Gumanti",
    "value": "Lembah Gumanti"
  },
  {
    "id": "130205",
    "regency_id": "1302",
    "label": "Payung Sekaki",
    "value": "Payung Sekaki"
  },
  {
    "id": "130206",
    "regency_id": "1302",
    "label": "Lembang Jaya",
    "value": "Lembang Jaya"
  },
  {
    "id": "130207",
    "regency_id": "1302",
    "label": "Gunung Talang",
    "value": "Gunung Talang"
  },
  {
    "id": "130208",
    "regency_id": "1302",
    "label": "Bukit Sundi",
    "value": "Bukit Sundi"
  },
  {
    "id": "130209",
    "regency_id": "1302",
    "label": "IX Koto Sungai Lasi",
    "value": "IX Koto Sungai Lasi"
  },
  {
    "id": "130210",
    "regency_id": "1302",
    "label": "Kubung",
    "value": "Kubung"
  },
  {
    "id": "130211",
    "regency_id": "1302",
    "label": "X Koto Singkarak",
    "value": "X Koto Singkarak"
  },
  {
    "id": "130212",
    "regency_id": "1302",
    "label": "X Koto Diatas",
    "value": "X Koto Diatas"
  },
  {
    "id": "130213",
    "regency_id": "1302",
    "label": "Junjung Sirih",
    "value": "Junjung Sirih"
  },
  {
    "id": "130217",
    "regency_id": "1302",
    "label": "Hiliran Gumanti",
    "value": "Hiliran Gumanti"
  },
  {
    "id": "130218",
    "regency_id": "1302",
    "label": "Tigo Lurah",
    "value": "Tigo Lurah"
  },
  {
    "id": "130219",
    "regency_id": "1302",
    "label": "Danau Kembar",
    "value": "Danau Kembar"
  },
  {
    "id": "130303",
    "regency_id": "1303",
    "label": "Tanjung Gadang",
    "value": "Tanjung Gadang"
  },
  {
    "id": "130304",
    "regency_id": "1303",
    "label": "Sijunjung",
    "value": "Sijunjung"
  },
  {
    "id": "130305",
    "regency_id": "1303",
    "label": "IV Nagari",
    "value": "IV Nagari"
  },
  {
    "id": "130306",
    "regency_id": "1303",
    "label": "Kamang Baru",
    "value": "Kamang Baru"
  },
  {
    "id": "130307",
    "regency_id": "1303",
    "label": "Lubuak Tarok",
    "value": "Lubuak Tarok"
  },
  {
    "id": "130308",
    "regency_id": "1303",
    "label": "Koto VII",
    "value": "Koto VII"
  },
  {
    "id": "130309",
    "regency_id": "1303",
    "label": "Sumpur Kudus",
    "value": "Sumpur Kudus"
  },
  {
    "id": "130310",
    "regency_id": "1303",
    "label": "Kupitan",
    "value": "Kupitan"
  },
  {
    "id": "130401",
    "regency_id": "1304",
    "label": "X Koto",
    "value": "X Koto"
  },
  {
    "id": "130402",
    "regency_id": "1304",
    "label": "Batipuh",
    "value": "Batipuh"
  },
  {
    "id": "130403",
    "regency_id": "1304",
    "label": "Rambatan",
    "value": "Rambatan"
  },
  {
    "id": "130404",
    "regency_id": "1304",
    "label": "Lima Kaum",
    "value": "Lima Kaum"
  },
  {
    "id": "130405",
    "regency_id": "1304",
    "label": "Tanjung Emas",
    "value": "Tanjung Emas"
  },
  {
    "id": "130406",
    "regency_id": "1304",
    "label": "Lintau Buo",
    "value": "Lintau Buo"
  },
  {
    "id": "130407",
    "regency_id": "1304",
    "label": "Sungayang",
    "value": "Sungayang"
  },
  {
    "id": "130408",
    "regency_id": "1304",
    "label": "Sungai Tarab",
    "value": "Sungai Tarab"
  },
  {
    "id": "130409",
    "regency_id": "1304",
    "label": "Pariangan",
    "value": "Pariangan"
  },
  {
    "id": "130410",
    "regency_id": "1304",
    "label": "Salimpaung",
    "value": "Salimpaung"
  },
  {
    "id": "130411",
    "regency_id": "1304",
    "label": "Padang Ganting",
    "value": "Padang Ganting"
  },
  {
    "id": "130412",
    "regency_id": "1304",
    "label": "Tanjuang Baru",
    "value": "Tanjuang Baru"
  },
  {
    "id": "130413",
    "regency_id": "1304",
    "label": "Lintau Buo Utara",
    "value": "Lintau Buo Utara"
  },
  {
    "id": "130414",
    "regency_id": "1304",
    "label": "Batipuah Selatan",
    "value": "Batipuah Selatan"
  },
  {
    "id": "130501",
    "regency_id": "1305",
    "label": "Lubuk Alung",
    "value": "Lubuk Alung"
  },
  {
    "id": "130502",
    "regency_id": "1305",
    "label": "Batang Anai",
    "value": "Batang Anai"
  },
  {
    "id": "130503",
    "regency_id": "1305",
    "label": "Nan Sabaris",
    "value": "Nan Sabaris"
  },
  {
    "id": "130504",
    "regency_id": "1305",
    "label": "2 x 11 Enam Lingkuang",
    "value": "2 x 11 Enam Lingkuang"
  },
  {
    "id": "130505",
    "regency_id": "1305",
    "label": "VII Koto Sungai Sarik",
    "value": "VII Koto Sungai Sarik"
  },
  {
    "id": "130506",
    "regency_id": "1305",
    "label": "V Koto Kampung Dalam",
    "value": "V Koto Kampung Dalam"
  },
  {
    "id": "130507",
    "regency_id": "1305",
    "label": "Sungai Garingging",
    "value": "Sungai Garingging"
  },
  {
    "id": "130508",
    "regency_id": "1305",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "130509",
    "regency_id": "1305",
    "label": "IV Koto Aur Malintang",
    "value": "IV Koto Aur Malintang"
  },
  {
    "id": "130510",
    "regency_id": "1305",
    "label": "Ulakan Tapakih",
    "value": "Ulakan Tapakih"
  },
  {
    "id": "130511",
    "regency_id": "1305",
    "label": "Sintuak Toboh Gadang",
    "value": "Sintuak Toboh Gadang"
  },
  {
    "id": "130512",
    "regency_id": "1305",
    "label": "Padang Sago",
    "value": "Padang Sago"
  },
  {
    "id": "130513",
    "regency_id": "1305",
    "label": "Batang Gasan",
    "value": "Batang Gasan"
  },
  {
    "id": "130514",
    "regency_id": "1305",
    "label": "V Koto Timur",
    "value": "V Koto Timur"
  },
  {
    "id": "130515",
    "regency_id": "1305",
    "label": "2 x 11 Kayu Tanam",
    "value": "2 x 11 Kayu Tanam"
  },
  {
    "id": "130516",
    "regency_id": "1305",
    "label": "Patamuan",
    "value": "Patamuan"
  },
  {
    "id": "130517",
    "regency_id": "1305",
    "label": "Enam Lingkung",
    "value": "Enam Lingkung"
  },
  {
    "id": "130601",
    "regency_id": "1306",
    "label": "Tanjung Mutiara",
    "value": "Tanjung Mutiara"
  },
  {
    "id": "130602",
    "regency_id": "1306",
    "label": "Lubuk Basung",
    "value": "Lubuk Basung"
  },
  {
    "id": "130603",
    "regency_id": "1306",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  { "id": "130604", "regency_id": "1306", "label": "Matur", "value": "Matur" },
  {
    "id": "130605",
    "regency_id": "1306",
    "label": "IV Koto",
    "value": "IV Koto"
  },
  {
    "id": "130606",
    "regency_id": "1306",
    "label": "Banuhampu",
    "value": "Banuhampu"
  },
  {
    "id": "130607",
    "regency_id": "1306",
    "label": "Ampek Angkek",
    "value": "Ampek Angkek"
  },
  { "id": "130608", "regency_id": "1306", "label": "Baso", "value": "Baso" },
  {
    "id": "130609",
    "regency_id": "1306",
    "label": "Tilatang Kamang",
    "value": "Tilatang Kamang"
  },
  {
    "id": "130610",
    "regency_id": "1306",
    "label": "Palupuh",
    "value": "Palupuh"
  },
  {
    "id": "130611",
    "regency_id": "1306",
    "label": "Pelembayan",
    "value": "Pelembayan"
  },
  {
    "id": "130612",
    "regency_id": "1306",
    "label": "Sungai Pua",
    "value": "Sungai Pua"
  },
  {
    "id": "130613",
    "regency_id": "1306",
    "label": "Ampek Nagari",
    "value": "Ampek Nagari"
  },
  {
    "id": "130614",
    "regency_id": "1306",
    "label": "Candung",
    "value": "Candung"
  },
  {
    "id": "130615",
    "regency_id": "1306",
    "label": "Kamang Magek",
    "value": "Kamang Magek"
  },
  {
    "id": "130616",
    "regency_id": "1306",
    "label": "Malalak",
    "value": "Malalak"
  },
  {
    "id": "130701",
    "regency_id": "1307",
    "label": "Suliki",
    "value": "Suliki"
  },
  {
    "id": "130702",
    "regency_id": "1307",
    "label": "Guguak",
    "value": "Guguak"
  },
  {
    "id": "130703",
    "regency_id": "1307",
    "label": "Payakumbuh",
    "value": "Payakumbuh"
  },
  { "id": "130704", "regency_id": "1307", "label": "Luak", "value": "Luak" },
  { "id": "130705", "regency_id": "1307", "label": "Harau", "value": "Harau" },
  {
    "id": "130706",
    "regency_id": "1307",
    "label": "Pangkalan Koto Baru",
    "value": "Pangkalan Koto Baru"
  },
  {
    "id": "130707",
    "regency_id": "1307",
    "label": "Kapur IX",
    "value": "Kapur IX"
  },
  {
    "id": "130708",
    "regency_id": "1307",
    "label": "Gunuang Omeh",
    "value": "Gunuang Omeh"
  },
  {
    "id": "130709",
    "regency_id": "1307",
    "label": "Lareh Sago Halaban",
    "value": "Lareh Sago Halaban"
  },
  {
    "id": "130710",
    "regency_id": "1307",
    "label": "Situjuah Limo Nagari",
    "value": "Situjuah Limo Nagari"
  },
  {
    "id": "130711",
    "regency_id": "1307",
    "label": "Mungka",
    "value": "Mungka"
  },
  {
    "id": "130712",
    "regency_id": "1307",
    "label": "Bukik Barisan",
    "value": "Bukik Barisan"
  },
  {
    "id": "130713",
    "regency_id": "1307",
    "label": "Akabiluru",
    "value": "Akabiluru"
  },
  {
    "id": "130804",
    "regency_id": "1308",
    "label": "Bonjol",
    "value": "Bonjol"
  },
  {
    "id": "130805",
    "regency_id": "1308",
    "label": "Lubuk Sikaping",
    "value": "Lubuk Sikaping"
  },
  { "id": "130807", "regency_id": "1308", "label": "Panti", "value": "Panti" },
  {
    "id": "130808",
    "regency_id": "1308",
    "label": "Mapat Tunggul",
    "value": "Mapat Tunggul"
  },
  {
    "id": "130812",
    "regency_id": "1308",
    "label": "Duo Koto",
    "value": "Duo Koto"
  },
  {
    "id": "130813",
    "regency_id": "1308",
    "label": "Tigo Nagari",
    "value": "Tigo Nagari"
  },
  { "id": "130814", "regency_id": "1308", "label": "Rao", "value": "Rao" },
  {
    "id": "130815",
    "regency_id": "1308",
    "label": "Mapat Tunggul Selatan",
    "value": "Mapat Tunggul Selatan"
  },
  {
    "id": "130816",
    "regency_id": "1308",
    "label": "Simpang Alahan Mati",
    "value": "Simpang Alahan Mati"
  },
  {
    "id": "130817",
    "regency_id": "1308",
    "label": "Padang Gelugur",
    "value": "Padang Gelugur"
  },
  {
    "id": "130818",
    "regency_id": "1308",
    "label": "Rao Utara",
    "value": "Rao Utara"
  },
  {
    "id": "130819",
    "regency_id": "1308",
    "label": "Rao Selatan",
    "value": "Rao Selatan"
  },
  {
    "id": "130901",
    "regency_id": "1309",
    "label": "Pagai Utara",
    "value": "Pagai Utara"
  },
  {
    "id": "130902",
    "regency_id": "1309",
    "label": "Sipora Selatan",
    "value": "Sipora Selatan"
  },
  {
    "id": "130903",
    "regency_id": "1309",
    "label": "Siberut Selatan",
    "value": "Siberut Selatan"
  },
  {
    "id": "130904",
    "regency_id": "1309",
    "label": "Siberut Utara",
    "value": "Siberut Utara"
  },
  {
    "id": "130905",
    "regency_id": "1309",
    "label": "Siberut Barat",
    "value": "Siberut Barat"
  },
  {
    "id": "130906",
    "regency_id": "1309",
    "label": "Siberut Barat Daya",
    "value": "Siberut Barat Daya"
  },
  {
    "id": "130907",
    "regency_id": "1309",
    "label": "Siberut Tengah",
    "value": "Siberut Tengah"
  },
  {
    "id": "130908",
    "regency_id": "1309",
    "label": "Sipora Utara",
    "value": "Sipora Utara"
  },
  {
    "id": "130909",
    "regency_id": "1309",
    "label": "Sikakap",
    "value": "Sikakap"
  },
  {
    "id": "130910",
    "regency_id": "1309",
    "label": "Pagai Selatan",
    "value": "Pagai Selatan"
  },
  {
    "id": "131001",
    "regency_id": "1310",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "131002",
    "regency_id": "1310",
    "label": "Pulau Punjung",
    "value": "Pulau Punjung"
  },
  {
    "id": "131003",
    "regency_id": "1310",
    "label": "Sungai Rumbai",
    "value": "Sungai Rumbai"
  },
  {
    "id": "131004",
    "regency_id": "1310",
    "label": "Sitiung",
    "value": "Sitiung"
  },
  {
    "id": "131005",
    "regency_id": "1310",
    "label": "Sembilan Koto",
    "value": "Sembilan Koto"
  },
  {
    "id": "131006",
    "regency_id": "1310",
    "label": "Timpeh",
    "value": "Timpeh"
  },
  {
    "id": "131007",
    "regency_id": "1310",
    "label": "Koto Salak",
    "value": "Koto Salak"
  },
  {
    "id": "131008",
    "regency_id": "1310",
    "label": "Tiumang",
    "value": "Tiumang"
  },
  {
    "id": "131009",
    "regency_id": "1310",
    "label": "Padang Laweh",
    "value": "Padang Laweh"
  },
  {
    "id": "131010",
    "regency_id": "1310",
    "label": "Asam Jujuhan",
    "value": "Asam Jujuhan"
  },
  {
    "id": "131011",
    "regency_id": "1310",
    "label": "Koto Besar",
    "value": "Koto Besar"
  },
  {
    "id": "131101",
    "regency_id": "1311",
    "label": "Sangir",
    "value": "Sangir"
  },
  {
    "id": "131102",
    "regency_id": "1311",
    "label": "Sungai Pagu",
    "value": "Sungai Pagu"
  },
  {
    "id": "131103",
    "regency_id": "1311",
    "label": "Koto Parik Gadang Diateh",
    "value": "Koto Parik Gadang Diateh"
  },
  {
    "id": "131104",
    "regency_id": "1311",
    "label": "Sangir Jujuan",
    "value": "Sangir Jujuan"
  },
  {
    "id": "131105",
    "regency_id": "1311",
    "label": "Sangir Batang Hari",
    "value": "Sangir Batang Hari"
  },
  {
    "id": "131106",
    "regency_id": "1311",
    "label": "Pauh Duo",
    "value": "Pauh Duo"
  },
  {
    "id": "131107",
    "regency_id": "1311",
    "label": "Sangir Balai Janggo",
    "value": "Sangir Balai Janggo"
  },
  {
    "id": "131201",
    "regency_id": "1312",
    "label": "Sungaiberemas",
    "value": "Sungaiberemas"
  },
  {
    "id": "131202",
    "regency_id": "1312",
    "label": "Lembah Melintang",
    "value": "Lembah Melintang"
  },
  {
    "id": "131203",
    "regency_id": "1312",
    "label": "Pasaman",
    "value": "Pasaman"
  },
  {
    "id": "131204",
    "regency_id": "1312",
    "label": "Talamau",
    "value": "Talamau"
  },
  {
    "id": "131205",
    "regency_id": "1312",
    "label": "Kinali",
    "value": "Kinali"
  },
  {
    "id": "131206",
    "regency_id": "1312",
    "label": "Gunungtuleh",
    "value": "Gunungtuleh"
  },
  {
    "id": "131207",
    "regency_id": "1312",
    "label": "Ranah Batahan",
    "value": "Ranah Batahan"
  },
  {
    "id": "131208",
    "regency_id": "1312",
    "label": "Koto Balingka",
    "value": "Koto Balingka"
  },
  {
    "id": "131209",
    "regency_id": "1312",
    "label": "Sungaiaur",
    "value": "Sungaiaur"
  },
  {
    "id": "131210",
    "regency_id": "1312",
    "label": "Luhak Nan Duo",
    "value": "Luhak Nan Duo"
  },
  {
    "id": "131211",
    "regency_id": "1312",
    "label": "Sasak Ranah Pesisir",
    "value": "Sasak Ranah Pesisir"
  },
  {
    "id": "137101",
    "regency_id": "1371",
    "label": "Padang Selatan",
    "value": "Padang Selatan"
  },
  {
    "id": "137102",
    "regency_id": "1371",
    "label": "Padang Timur",
    "value": "Padang Timur"
  },
  {
    "id": "137103",
    "regency_id": "1371",
    "label": "Padang Barat",
    "value": "Padang Barat"
  },
  {
    "id": "137104",
    "regency_id": "1371",
    "label": "Padang Utara",
    "value": "Padang Utara"
  },
  {
    "id": "137105",
    "regency_id": "1371",
    "label": "Bungus Teluk Kabung",
    "value": "Bungus Teluk Kabung"
  },
  {
    "id": "137106",
    "regency_id": "1371",
    "label": "Lubuk Begalung",
    "value": "Lubuk Begalung"
  },
  {
    "id": "137107",
    "regency_id": "1371",
    "label": "Lubuk Kilangan",
    "value": "Lubuk Kilangan"
  },
  { "id": "137108", "regency_id": "1371", "label": "Pauh", "value": "Pauh" },
  {
    "id": "137109",
    "regency_id": "1371",
    "label": "Kuranji",
    "value": "Kuranji"
  },
  {
    "id": "137110",
    "regency_id": "1371",
    "label": "Nanggalo",
    "value": "Nanggalo"
  },
  {
    "id": "137111",
    "regency_id": "1371",
    "label": "Koto Tangah",
    "value": "Koto Tangah"
  },
  {
    "id": "137201",
    "regency_id": "1372",
    "label": "Lubuk Sikarah",
    "value": "Lubuk Sikarah"
  },
  {
    "id": "137202",
    "regency_id": "1372",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "137301",
    "regency_id": "1373",
    "label": "Lembah Segar",
    "value": "Lembah Segar"
  },
  {
    "id": "137302",
    "regency_id": "1373",
    "label": "Barangin",
    "value": "Barangin"
  },
  {
    "id": "137303",
    "regency_id": "1373",
    "label": "Silungkang",
    "value": "Silungkang"
  },
  {
    "id": "137304",
    "regency_id": "1373",
    "label": "Talawi",
    "value": "Talawi"
  },
  {
    "id": "137401",
    "regency_id": "1374",
    "label": "Padang Panjang Timur",
    "value": "Padang Panjang Timur"
  },
  {
    "id": "137402",
    "regency_id": "1374",
    "label": "Padang Panjang Barat",
    "value": "Padang Panjang Barat"
  },
  {
    "id": "137501",
    "regency_id": "1375",
    "label": "Guguak Panjang",
    "value": "Guguak Panjang"
  },
  {
    "id": "137502",
    "regency_id": "1375",
    "label": "Mandiangin K. Selayan",
    "value": "Mandiangin K. Selayan"
  },
  {
    "id": "137503",
    "regency_id": "1375",
    "label": "Aur Birugo Tigo Baleh",
    "value": "Aur Birugo Tigo Baleh"
  },
  {
    "id": "137601",
    "regency_id": "1376",
    "label": "Payakumbuh Barat",
    "value": "Payakumbuh Barat"
  },
  {
    "id": "137602",
    "regency_id": "1376",
    "label": "Payakumbuh Utara",
    "value": "Payakumbuh Utara"
  },
  {
    "id": "137603",
    "regency_id": "1376",
    "label": "Payakumbuh Timur",
    "value": "Payakumbuh Timur"
  },
  {
    "id": "137604",
    "regency_id": "1376",
    "label": "Lamposi Tigo Nagori",
    "value": "Lamposi Tigo Nagori"
  },
  {
    "id": "137605",
    "regency_id": "1376",
    "label": "Payakumbuh Selatan",
    "value": "Payakumbuh Selatan"
  },
  {
    "id": "137701",
    "regency_id": "1377",
    "label": "Pariaman Tengah",
    "value": "Pariaman Tengah"
  },
  {
    "id": "137702",
    "regency_id": "1377",
    "label": "Pariaman Utara",
    "value": "Pariaman Utara"
  },
  {
    "id": "137703",
    "regency_id": "1377",
    "label": "Pariaman Selatan",
    "value": "Pariaman Selatan"
  },
  {
    "id": "137704",
    "regency_id": "1377",
    "label": "Pariaman Timur",
    "value": "Pariaman Timur"
  },
  {
    "id": "140101",
    "regency_id": "1401",
    "label": "Bangkinang Kota",
    "value": "Bangkinang Kota"
  },
  {
    "id": "140102",
    "regency_id": "1401",
    "label": "Kampar",
    "value": "Kampar"
  },
  {
    "id": "140103",
    "regency_id": "1401",
    "label": "Tambang",
    "value": "Tambang"
  },
  {
    "id": "140104",
    "regency_id": "1401",
    "label": "XIII Koto Kampar",
    "value": "XIII Koto Kampar"
  },
  { "id": "140105", "regency_id": "1401", "label": "Kuok", "value": "Kuok" },
  {
    "id": "140106",
    "regency_id": "1401",
    "label": "Siak Hulu",
    "value": "Siak Hulu"
  },
  {
    "id": "140107",
    "regency_id": "1401",
    "label": "Kampar Kiri",
    "value": "Kampar Kiri"
  },
  {
    "id": "140108",
    "regency_id": "1401",
    "label": "Kampar Kiri Hilir",
    "value": "Kampar Kiri Hilir"
  },
  {
    "id": "140109",
    "regency_id": "1401",
    "label": "Kampar Kiri Hulu",
    "value": "Kampar Kiri Hulu"
  },
  {
    "id": "140110",
    "regency_id": "1401",
    "label": "Tapung",
    "value": "Tapung"
  },
  {
    "id": "140111",
    "regency_id": "1401",
    "label": "Tapung Hilir",
    "value": "Tapung Hilir"
  },
  {
    "id": "140112",
    "regency_id": "1401",
    "label": "Tapung Hulu",
    "value": "Tapung Hulu"
  },
  { "id": "140113", "regency_id": "1401", "label": "Salo", "value": "Salo" },
  {
    "id": "140114",
    "regency_id": "1401",
    "label": "Rumbio Jaya",
    "value": "Rumbio Jaya"
  },
  {
    "id": "140115",
    "regency_id": "1401",
    "label": "Bangkinang",
    "value": "Bangkinang"
  },
  {
    "id": "140116",
    "regency_id": "1401",
    "label": "Perhentian Raja",
    "value": "Perhentian Raja"
  },
  { "id": "140117", "regency_id": "1401", "label": "Kampa", "value": "Kampa" },
  {
    "id": "140118",
    "regency_id": "1401",
    "label": "Kampar Utara",
    "value": "Kampar Utara"
  },
  {
    "id": "140119",
    "regency_id": "1401",
    "label": "Kampar Kiri Tengah",
    "value": "Kampar Kiri Tengah"
  },
  {
    "id": "140120",
    "regency_id": "1401",
    "label": "Gunung Sahilan",
    "value": "Gunung Sahilan"
  },
  {
    "id": "140121",
    "regency_id": "1401",
    "label": "Koto Kampar Hulu",
    "value": "Koto Kampar Hulu"
  },
  {
    "id": "140201",
    "regency_id": "1402",
    "label": "Rengat",
    "value": "Rengat"
  },
  {
    "id": "140202",
    "regency_id": "1402",
    "label": "Rengat Barat",
    "value": "Rengat Barat"
  },
  {
    "id": "140203",
    "regency_id": "1402",
    "label": "Kelayang",
    "value": "Kelayang"
  },
  {
    "id": "140204",
    "regency_id": "1402",
    "label": "Pasir Penyu",
    "value": "Pasir Penyu"
  },
  {
    "id": "140205",
    "regency_id": "1402",
    "label": "Peranap",
    "value": "Peranap"
  },
  {
    "id": "140206",
    "regency_id": "1402",
    "label": "Siberida",
    "value": "Siberida"
  },
  {
    "id": "140207",
    "regency_id": "1402",
    "label": "Batang Cenaku",
    "value": "Batang Cenaku"
  },
  {
    "id": "140208",
    "regency_id": "1402",
    "label": "Batang Gangsal",
    "value": "Batang Gangsal"
  },
  { "id": "140209", "regency_id": "1402", "label": "Lirik", "value": "Lirik" },
  {
    "id": "140210",
    "regency_id": "1402",
    "label": "Kuala Cenaku",
    "value": "Kuala Cenaku"
  },
  {
    "id": "140211",
    "regency_id": "1402",
    "label": "Sungai Lala",
    "value": "Sungai Lala"
  },
  {
    "id": "140212",
    "regency_id": "1402",
    "label": "Lubuk Batu Jaya",
    "value": "Lubuk Batu Jaya"
  },
  {
    "id": "140213",
    "regency_id": "1402",
    "label": "Rakit Kulim",
    "value": "Rakit Kulim"
  },
  {
    "id": "140214",
    "regency_id": "1402",
    "label": "Batang Peranap",
    "value": "Batang Peranap"
  },
  {
    "id": "140301",
    "regency_id": "1403",
    "label": "Bengkalis",
    "value": "Bengkalis"
  },
  {
    "id": "140302",
    "regency_id": "1403",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "140303",
    "regency_id": "1403",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "140309",
    "regency_id": "1403",
    "label": "Mandau",
    "value": "Mandau"
  },
  { "id": "140310", "regency_id": "1403", "label": "Rupat", "value": "Rupat" },
  {
    "id": "140311",
    "regency_id": "1403",
    "label": "Rupat Utara",
    "value": "Rupat Utara"
  },
  {
    "id": "140312",
    "regency_id": "1403",
    "label": "Siak Kecil",
    "value": "Siak Kecil"
  },
  {
    "id": "140313",
    "regency_id": "1403",
    "label": "Pinggir",
    "value": "Pinggir"
  },
  {
    "id": "140314",
    "regency_id": "1403",
    "label": "Bandar Laksamana",
    "value": "Bandar Laksamana"
  },
  {
    "id": "140315",
    "regency_id": "1403",
    "label": "Talang Muandau",
    "value": "Talang Muandau"
  },
  {
    "id": "140316",
    "regency_id": "1403",
    "label": "Bathin Solapan",
    "value": "Bathin Solapan"
  },
  { "id": "140401", "regency_id": "1404", "label": "Reteh", "value": "Reteh" },
  { "id": "140402", "regency_id": "1404", "label": "Enok", "value": "Enok" },
  {
    "id": "140403",
    "regency_id": "1404",
    "label": "Kuala Indragiri",
    "value": "Kuala Indragiri"
  },
  {
    "id": "140404",
    "regency_id": "1404",
    "label": "Tembilahan",
    "value": "Tembilahan"
  },
  {
    "id": "140405",
    "regency_id": "1404",
    "label": "Tempuling",
    "value": "Tempuling"
  },
  {
    "id": "140406",
    "regency_id": "1404",
    "label": "Gaung Anak Serka",
    "value": "Gaung Anak Serka"
  },
  {
    "id": "140407",
    "regency_id": "1404",
    "label": "Mandah",
    "value": "Mandah"
  },
  {
    "id": "140408",
    "regency_id": "1404",
    "label": "Kateman",
    "value": "Kateman"
  },
  {
    "id": "140409",
    "regency_id": "1404",
    "label": "Keritang",
    "value": "Keritang"
  },
  {
    "id": "140410",
    "regency_id": "1404",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "140411",
    "regency_id": "1404",
    "label": "Batang Tuaka",
    "value": "Batang Tuaka"
  },
  { "id": "140412", "regency_id": "1404", "label": "Gaung", "value": "Gaung" },
  {
    "id": "140413",
    "regency_id": "1404",
    "label": "Tembilahan Hulu",
    "value": "Tembilahan Hulu"
  },
  {
    "id": "140414",
    "regency_id": "1404",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "140415",
    "regency_id": "1404",
    "label": "Pelangiran",
    "value": "Pelangiran"
  },
  {
    "id": "140416",
    "regency_id": "1404",
    "label": "Teluk Belengkong",
    "value": "Teluk Belengkong"
  },
  {
    "id": "140417",
    "regency_id": "1404",
    "label": "Pulau Burung",
    "value": "Pulau Burung"
  },
  {
    "id": "140418",
    "regency_id": "1404",
    "label": "Concong",
    "value": "Concong"
  },
  {
    "id": "140419",
    "regency_id": "1404",
    "label": "Kempas",
    "value": "Kempas"
  },
  {
    "id": "140420",
    "regency_id": "1404",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  { "id": "140501", "regency_id": "1405", "label": "Ukui", "value": "Ukui" },
  {
    "id": "140502",
    "regency_id": "1405",
    "label": "Pangkalan Kerinci",
    "value": "Pangkalan Kerinci"
  },
  {
    "id": "140503",
    "regency_id": "1405",
    "label": "Pangkalan Kuras",
    "value": "Pangkalan Kuras"
  },
  {
    "id": "140504",
    "regency_id": "1405",
    "label": "Pangkalan Lesung",
    "value": "Pangkalan Lesung"
  },
  {
    "id": "140505",
    "regency_id": "1405",
    "label": "Langgam",
    "value": "Langgam"
  },
  {
    "id": "140506",
    "regency_id": "1405",
    "label": "Pelalawan",
    "value": "Pelalawan"
  },
  {
    "id": "140507",
    "regency_id": "1405",
    "label": "Kerumutan",
    "value": "Kerumutan"
  },
  { "id": "140508", "regency_id": "1405", "label": "Bunut", "value": "Bunut" },
  {
    "id": "140509",
    "regency_id": "1405",
    "label": "Teluk Meranti",
    "value": "Teluk Meranti"
  },
  {
    "id": "140510",
    "regency_id": "1405",
    "label": "Kuala Kampar",
    "value": "Kuala Kampar"
  },
  {
    "id": "140511",
    "regency_id": "1405",
    "label": "Bandar Sei Kijang",
    "value": "Bandar Sei Kijang"
  },
  {
    "id": "140512",
    "regency_id": "1405",
    "label": "Bandar Petalangan",
    "value": "Bandar Petalangan"
  },
  {
    "id": "140601",
    "regency_id": "1406",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "140602",
    "regency_id": "1406",
    "label": "Rokan IV Koto",
    "value": "Rokan IV Koto"
  },
  {
    "id": "140603",
    "regency_id": "1406",
    "label": "Rambah",
    "value": "Rambah"
  },
  {
    "id": "140604",
    "regency_id": "1406",
    "label": "Tambusai",
    "value": "Tambusai"
  },
  {
    "id": "140605",
    "regency_id": "1406",
    "label": "Kepenuhan",
    "value": "Kepenuhan"
  },
  {
    "id": "140606",
    "regency_id": "1406",
    "label": "Kunto Darussalam",
    "value": "Kunto Darussalam"
  },
  {
    "id": "140607",
    "regency_id": "1406",
    "label": "Rambah Samo",
    "value": "Rambah Samo"
  },
  {
    "id": "140608",
    "regency_id": "1406",
    "label": "Rambah Hilir",
    "value": "Rambah Hilir"
  },
  {
    "id": "140609",
    "regency_id": "1406",
    "label": "Tambusai Utara",
    "value": "Tambusai Utara"
  },
  {
    "id": "140610",
    "regency_id": "1406",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "140611",
    "regency_id": "1406",
    "label": "Tandun",
    "value": "Tandun"
  },
  { "id": "140612", "regency_id": "1406", "label": "Kabun", "value": "Kabun" },
  {
    "id": "140613",
    "regency_id": "1406",
    "label": "Bonai Darussalam",
    "value": "Bonai Darussalam"
  },
  {
    "id": "140614",
    "regency_id": "1406",
    "label": "Pagaran Tapah Darussalam",
    "value": "Pagaran Tapah Darussalam"
  },
  {
    "id": "140615",
    "regency_id": "1406",
    "label": "Kepenuhan Hulu",
    "value": "Kepenuhan Hulu"
  },
  {
    "id": "140616",
    "regency_id": "1406",
    "label": "Pendalian IV Koto",
    "value": "Pendalian IV Koto"
  },
  { "id": "140701", "regency_id": "1407", "label": "Kubu", "value": "Kubu" },
  {
    "id": "140702",
    "regency_id": "1407",
    "label": "Bangko",
    "value": "Bangko"
  },
  {
    "id": "140703",
    "regency_id": "1407",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "140704",
    "regency_id": "1407",
    "label": "Rimba Melintang",
    "value": "Rimba Melintang"
  },
  {
    "id": "140705",
    "regency_id": "1407",
    "label": "Bagansinembah",
    "value": "Bagansinembah"
  },
  {
    "id": "140706",
    "regency_id": "1407",
    "label": "Pasir Limau Kapas",
    "value": "Pasir Limau Kapas"
  },
  {
    "id": "140707",
    "regency_id": "1407",
    "label": "Sinaboi",
    "value": "Sinaboi"
  },
  { "id": "140708", "regency_id": "1407", "label": "Pujud", "value": "Pujud" },
  {
    "id": "140709",
    "regency_id": "1407",
    "label": "Tanah Putih Tanjung Melawa",
    "value": "Tanah Putih Tanjung Melawa"
  },
  {
    "id": "140710",
    "regency_id": "1407",
    "label": "Bangko Pusako",
    "value": "Bangko Pusako"
  },
  {
    "id": "140711",
    "regency_id": "1407",
    "label": "Simpang Kanan",
    "value": "Simpang Kanan"
  },
  {
    "id": "140712",
    "regency_id": "1407",
    "label": "Batu Hampar",
    "value": "Batu Hampar"
  },
  {
    "id": "140713",
    "regency_id": "1407",
    "label": "Rantau Kopar",
    "value": "Rantau Kopar"
  },
  {
    "id": "140714",
    "regency_id": "1407",
    "label": "Pekaitan",
    "value": "Pekaitan"
  },
  {
    "id": "140715",
    "regency_id": "1407",
    "label": "Kubu Babussalam",
    "value": "Kubu Babussalam"
  },
  {
    "id": "140716",
    "regency_id": "1407",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "140717",
    "regency_id": "1407",
    "label": "Bagan Sinembah Raya",
    "value": "Bagan Sinembah Raya"
  },
  {
    "id": "140718",
    "regency_id": "1407",
    "label": "Balai Jaya",
    "value": "Balai Jaya"
  },
  { "id": "140801", "regency_id": "1408", "label": "Siak", "value": "Siak" },
  {
    "id": "140802",
    "regency_id": "1408",
    "label": "Sungai Apit",
    "value": "Sungai Apit"
  },
  { "id": "140803", "regency_id": "1408", "label": "Minas", "value": "Minas" },
  {
    "id": "140804",
    "regency_id": "1408",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "140805",
    "regency_id": "1408",
    "label": "Sungai Mandau",
    "value": "Sungai Mandau"
  },
  { "id": "140806", "regency_id": "1408", "label": "Dayun", "value": "Dayun" },
  {
    "id": "140807",
    "regency_id": "1408",
    "label": "Kerinci Kanan",
    "value": "Kerinci Kanan"
  },
  {
    "id": "140808",
    "regency_id": "1408",
    "label": "Bunga Raya",
    "value": "Bunga Raya"
  },
  {
    "id": "140809",
    "regency_id": "1408",
    "label": "Koto Gasib",
    "value": "Koto Gasib"
  },
  {
    "id": "140810",
    "regency_id": "1408",
    "label": "Kandis",
    "value": "Kandis"
  },
  {
    "id": "140811",
    "regency_id": "1408",
    "label": "Lubuk Dalam",
    "value": "Lubuk Dalam"
  },
  {
    "id": "140812",
    "regency_id": "1408",
    "label": "Sabak Auh",
    "value": "Sabak Auh"
  },
  {
    "id": "140813",
    "regency_id": "1408",
    "label": "Mempura",
    "value": "Mempura"
  },
  {
    "id": "140814",
    "regency_id": "1408",
    "label": "Pusako",
    "value": "Pusako"
  },
  {
    "id": "140901",
    "regency_id": "1409",
    "label": "Kuantan Mudik",
    "value": "Kuantan Mudik"
  },
  {
    "id": "140902",
    "regency_id": "1409",
    "label": "Kuantan Tengah",
    "value": "Kuantan Tengah"
  },
  {
    "id": "140903",
    "regency_id": "1409",
    "label": "Singingi",
    "value": "Singingi"
  },
  {
    "id": "140904",
    "regency_id": "1409",
    "label": "Kuantan Hilir",
    "value": "Kuantan Hilir"
  },
  {
    "id": "140905",
    "regency_id": "1409",
    "label": "Cerenti",
    "value": "Cerenti"
  },
  { "id": "140906", "regency_id": "1409", "label": "Benai", "value": "Benai" },
  {
    "id": "140907",
    "regency_id": "1409",
    "label": "Gunungtoar",
    "value": "Gunungtoar"
  },
  {
    "id": "140908",
    "regency_id": "1409",
    "label": "Singingi Hilir",
    "value": "Singingi Hilir"
  },
  {
    "id": "140909",
    "regency_id": "1409",
    "label": "Pangean",
    "value": "Pangean"
  },
  {
    "id": "140910",
    "regency_id": "1409",
    "label": "Logas Tanah Darat",
    "value": "Logas Tanah Darat"
  },
  {
    "id": "140911",
    "regency_id": "1409",
    "label": "Inuman",
    "value": "Inuman"
  },
  {
    "id": "140912",
    "regency_id": "1409",
    "label": "Hulu Kuantan",
    "value": "Hulu Kuantan"
  },
  {
    "id": "140913",
    "regency_id": "1409",
    "label": "Kuantan Hilir Seberang",
    "value": "Kuantan Hilir Seberang"
  },
  {
    "id": "140914",
    "regency_id": "1409",
    "label": "Sentajo Raya",
    "value": "Sentajo Raya"
  },
  {
    "id": "140915",
    "regency_id": "1409",
    "label": "Pucuk Rantau",
    "value": "Pucuk Rantau"
  },
  {
    "id": "141001",
    "regency_id": "1410",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "141002",
    "regency_id": "1410",
    "label": "Rangsang Barat",
    "value": "Rangsang Barat"
  },
  {
    "id": "141003",
    "regency_id": "1410",
    "label": "Rangsang",
    "value": "Rangsang"
  },
  {
    "id": "141004",
    "regency_id": "1410",
    "label": "Tebing Tinggi Barat",
    "value": "Tebing Tinggi Barat"
  },
  {
    "id": "141005",
    "regency_id": "1410",
    "label": "Merbau",
    "value": "Merbau"
  },
  {
    "id": "141006",
    "regency_id": "1410",
    "label": "Pulaumerbau",
    "value": "Pulaumerbau"
  },
  {
    "id": "141007",
    "regency_id": "1410",
    "label": "Tebing Tinggi Timur",
    "value": "Tebing Tinggi Timur"
  },
  {
    "id": "141008",
    "regency_id": "1410",
    "label": "Tasik Putri Puyu",
    "value": "Tasik Putri Puyu"
  },
  {
    "id": "141009",
    "regency_id": "1410",
    "label": "Rangsang Pesisir",
    "value": "Rangsang Pesisir"
  },
  {
    "id": "147101",
    "regency_id": "1471",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "147102",
    "regency_id": "1471",
    "label": "Pekanbaru Kota",
    "value": "Pekanbaru Kota"
  },
  { "id": "147103", "regency_id": "1471", "label": "Sail", "value": "Sail" },
  {
    "id": "147104",
    "regency_id": "1471",
    "label": "Lima Puluh",
    "value": "Lima Puluh"
  },
  {
    "id": "147105",
    "regency_id": "1471",
    "label": "Senapelan",
    "value": "Senapelan"
  },
  {
    "id": "147106",
    "regency_id": "1471",
    "label": "Rumbai",
    "value": "Rumbai"
  },
  {
    "id": "147107",
    "regency_id": "1471",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "147108",
    "regency_id": "1471",
    "label": "Tampan",
    "value": "Tampan"
  },
  {
    "id": "147109",
    "regency_id": "1471",
    "label": "Marpoyan Damai",
    "value": "Marpoyan Damai"
  },
  {
    "id": "147110",
    "regency_id": "1471",
    "label": "Tenayan Raya",
    "value": "Tenayan Raya"
  },
  {
    "id": "147111",
    "regency_id": "1471",
    "label": "Payung Sekaki",
    "value": "Payung Sekaki"
  },
  {
    "id": "147112",
    "regency_id": "1471",
    "label": "Rumbai Pesisir",
    "value": "Rumbai Pesisir"
  },
  {
    "id": "147201",
    "regency_id": "1472",
    "label": "Dumai Barat",
    "value": "Dumai Barat"
  },
  {
    "id": "147202",
    "regency_id": "1472",
    "label": "Dumai Timur",
    "value": "Dumai Timur"
  },
  {
    "id": "147203",
    "regency_id": "1472",
    "label": "Bukit Kapur",
    "value": "Bukit Kapur"
  },
  {
    "id": "147204",
    "regency_id": "1472",
    "label": "Sungai Sembilan",
    "value": "Sungai Sembilan"
  },
  {
    "id": "147205",
    "regency_id": "1472",
    "label": "Medang Kampai",
    "value": "Medang Kampai"
  },
  {
    "id": "147206",
    "regency_id": "1472",
    "label": "Dumai Kota",
    "value": "Dumai Kota"
  },
  {
    "id": "147207",
    "regency_id": "1472",
    "label": "Dumai Selatan",
    "value": "Dumai Selatan"
  },
  {
    "id": "150101",
    "regency_id": "1501",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "150102",
    "regency_id": "1501",
    "label": "Danau Kerinci",
    "value": "Danau Kerinci"
  },
  {
    "id": "150104",
    "regency_id": "1501",
    "label": "Sitinjau Laut",
    "value": "Sitinjau Laut"
  },
  {
    "id": "150105",
    "regency_id": "1501",
    "label": "Air Hangat",
    "value": "Air Hangat"
  },
  {
    "id": "150106",
    "regency_id": "1501",
    "label": "Gunung Kerinci",
    "value": "Gunung Kerinci"
  },
  {
    "id": "150107",
    "regency_id": "1501",
    "label": "Batang Merangin",
    "value": "Batang Merangin"
  },
  {
    "id": "150108",
    "regency_id": "1501",
    "label": "Keliling Danau",
    "value": "Keliling Danau"
  },
  {
    "id": "150109",
    "regency_id": "1501",
    "label": "Kayu Aro",
    "value": "Kayu Aro"
  },
  {
    "id": "150111",
    "regency_id": "1501",
    "label": "Air Hangat Timur",
    "value": "Air Hangat Timur"
  },
  {
    "id": "150115",
    "regency_id": "1501",
    "label": "Gunung Tujuh",
    "value": "Gunung Tujuh"
  },
  {
    "id": "150116",
    "regency_id": "1501",
    "label": "Siulak",
    "value": "Siulak"
  },
  {
    "id": "150117",
    "regency_id": "1501",
    "label": "Depati Tujuh",
    "value": "Depati Tujuh"
  },
  {
    "id": "150118",
    "regency_id": "1501",
    "label": "Siulak Mukai",
    "value": "Siulak Mukai"
  },
  {
    "id": "150119",
    "regency_id": "1501",
    "label": "Kayu Aro Barat",
    "value": "Kayu Aro Barat"
  },
  {
    "id": "150120",
    "regency_id": "1501",
    "label": "Bukitkerman",
    "value": "Bukitkerman"
  },
  {
    "id": "150121",
    "regency_id": "1501",
    "label": "Air Hangat Barat",
    "value": "Air Hangat Barat"
  },
  {
    "id": "150201",
    "regency_id": "1502",
    "label": "Jangkat",
    "value": "Jangkat"
  },
  {
    "id": "150202",
    "regency_id": "1502",
    "label": "Bangko",
    "value": "Bangko"
  },
  {
    "id": "150203",
    "regency_id": "1502",
    "label": "Muara Siau",
    "value": "Muara Siau"
  },
  {
    "id": "150204",
    "regency_id": "1502",
    "label": "Sungai Manau",
    "value": "Sungai Manau"
  },
  { "id": "150205", "regency_id": "1502", "label": "Tabir", "value": "Tabir" },
  {
    "id": "150206",
    "regency_id": "1502",
    "label": "Pamenang",
    "value": "Pamenang"
  },
  {
    "id": "150207",
    "regency_id": "1502",
    "label": "Tabir Ulu",
    "value": "Tabir Ulu"
  },
  {
    "id": "150208",
    "regency_id": "1502",
    "label": "Tabir Selatan",
    "value": "Tabir Selatan"
  },
  {
    "id": "150209",
    "regency_id": "1502",
    "label": "Lembah Masurai",
    "value": "Lembah Masurai"
  },
  {
    "id": "150210",
    "regency_id": "1502",
    "label": "Bangko Barat",
    "value": "Bangko Barat"
  },
  {
    "id": "150211",
    "regency_id": "1502",
    "label": "Nalo Tatan",
    "value": "Nalo Tatan"
  },
  {
    "id": "150212",
    "regency_id": "1502",
    "label": "Batang Masumai",
    "value": "Batang Masumai"
  },
  {
    "id": "150213",
    "regency_id": "1502",
    "label": "Pamenang Barat",
    "value": "Pamenang Barat"
  },
  {
    "id": "150214",
    "regency_id": "1502",
    "label": "Tabir Ilir",
    "value": "Tabir Ilir"
  },
  {
    "id": "150215",
    "regency_id": "1502",
    "label": "Tabir Timur",
    "value": "Tabir Timur"
  },
  {
    "id": "150216",
    "regency_id": "1502",
    "label": "Renah Pembarap",
    "value": "Renah Pembarap"
  },
  {
    "id": "150217",
    "regency_id": "1502",
    "label": "Pangkalan Jambu",
    "value": "Pangkalan Jambu"
  },
  {
    "id": "150218",
    "regency_id": "1502",
    "label": "Jangkat Timur",
    "value": "Jangkat Timur"
  },
  {
    "id": "150219",
    "regency_id": "1502",
    "label": "Renah Pamenang",
    "value": "Renah Pamenang"
  },
  {
    "id": "150220",
    "regency_id": "1502",
    "label": "Pamenang Selatan",
    "value": "Pamenang Selatan"
  },
  {
    "id": "150221",
    "regency_id": "1502",
    "label": "Margo Tabir",
    "value": "Margo Tabir"
  },
  {
    "id": "150222",
    "regency_id": "1502",
    "label": "Tabir Lintas",
    "value": "Tabir Lintas"
  },
  {
    "id": "150223",
    "regency_id": "1502",
    "label": "Tabir Barat",
    "value": "Tabir Barat"
  },
  {
    "id": "150224",
    "regency_id": "1502",
    "label": "Tiang Pumpung",
    "value": "Tiang Pumpung"
  },
  {
    "id": "150301",
    "regency_id": "1503",
    "label": "Batang Asai",
    "value": "Batang Asai"
  },
  { "id": "150302", "regency_id": "1503", "label": "Limun", "value": "Limun" },
  {
    "id": "150303",
    "regency_id": "1503",
    "label": "Sarolangun",
    "value": "Sarolangun"
  },
  { "id": "150304", "regency_id": "1503", "label": "Pauh", "value": "Pauh" },
  {
    "id": "150305",
    "regency_id": "1503",
    "label": "Pelawan",
    "value": "Pelawan"
  },
  {
    "id": "150306",
    "regency_id": "1503",
    "label": "Mandiangin",
    "value": "Mandiangin"
  },
  {
    "id": "150307",
    "regency_id": "1503",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "150308",
    "regency_id": "1503",
    "label": "Bathin VIII",
    "value": "Bathin VIII"
  },
  {
    "id": "150309",
    "regency_id": "1503",
    "label": "Singkut",
    "value": "Singkut"
  },
  {
    "id": "150310",
    "regency_id": "1503",
    "label": "Cermin Nan Gedang",
    "value": "Cermin Nan Gedang"
  },
  {
    "id": "150401",
    "regency_id": "1504",
    "label": "Mersam",
    "value": "Mersam"
  },
  {
    "id": "150402",
    "regency_id": "1504",
    "label": "Muara Tembesi",
    "value": "Muara Tembesi"
  },
  {
    "id": "150403",
    "regency_id": "1504",
    "label": "Muara Bulian",
    "value": "Muara Bulian"
  },
  {
    "id": "150404",
    "regency_id": "1504",
    "label": "Batin XXIV",
    "value": "Batin XXIV"
  },
  {
    "id": "150405",
    "regency_id": "1504",
    "label": "Pemayung",
    "value": "Pemayung"
  },
  {
    "id": "150406",
    "regency_id": "1504",
    "label": "Maro Sebo Ulu",
    "value": "Maro Sebo Ulu"
  },
  {
    "id": "150407",
    "regency_id": "1504",
    "label": "Bajubang",
    "value": "Bajubang"
  },
  {
    "id": "150408",
    "regency_id": "1504",
    "label": "Maro Sebo Ilir",
    "value": "Maro Sebo Ilir"
  },
  {
    "id": "150501",
    "regency_id": "1505",
    "label": "Jambi Luar Kota",
    "value": "Jambi Luar Kota"
  },
  {
    "id": "150502",
    "regency_id": "1505",
    "label": "Sekernan",
    "value": "Sekernan"
  },
  {
    "id": "150503",
    "regency_id": "1505",
    "label": "Kumpeh",
    "value": "Kumpeh"
  },
  {
    "id": "150504",
    "regency_id": "1505",
    "label": "Maro Sebo",
    "value": "Maro Sebo"
  },
  {
    "id": "150505",
    "regency_id": "1505",
    "label": "Mestong",
    "value": "Mestong"
  },
  {
    "id": "150506",
    "regency_id": "1505",
    "label": "Kumpeh Ulu",
    "value": "Kumpeh Ulu"
  },
  {
    "id": "150507",
    "regency_id": "1505",
    "label": "Sungai Bahar",
    "value": "Sungai Bahar"
  },
  {
    "id": "150508",
    "regency_id": "1505",
    "label": "Sungai Gelam",
    "value": "Sungai Gelam"
  },
  {
    "id": "150509",
    "regency_id": "1505",
    "label": "Bahar Utara",
    "value": "Bahar Utara"
  },
  {
    "id": "150510",
    "regency_id": "1505",
    "label": "Bahar Selatan",
    "value": "Bahar Selatan"
  },
  {
    "id": "150511",
    "regency_id": "1505",
    "label": "Taman Rajo",
    "value": "Taman Rajo"
  },
  {
    "id": "150601",
    "regency_id": "1506",
    "label": "Tungkal Ulu",
    "value": "Tungkal Ulu"
  },
  {
    "id": "150602",
    "regency_id": "1506",
    "label": "Tungkal Ilir",
    "value": "Tungkal Ilir"
  },
  {
    "id": "150603",
    "regency_id": "1506",
    "label": "Pengabuan",
    "value": "Pengabuan"
  },
  {
    "id": "150604",
    "regency_id": "1506",
    "label": "Betara",
    "value": "Betara"
  },
  {
    "id": "150605",
    "regency_id": "1506",
    "label": "Merlung",
    "value": "Merlung"
  },
  {
    "id": "150606",
    "regency_id": "1506",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "150607",
    "regency_id": "1506",
    "label": "Batang Asam",
    "value": "Batang Asam"
  },
  {
    "id": "150608",
    "regency_id": "1506",
    "label": "Renah Mendaluh",
    "value": "Renah Mendaluh"
  },
  {
    "id": "150609",
    "regency_id": "1506",
    "label": "Muara Papalik",
    "value": "Muara Papalik"
  },
  {
    "id": "150610",
    "regency_id": "1506",
    "label": "Seberang Kota",
    "value": "Seberang Kota"
  },
  {
    "id": "150611",
    "regency_id": "1506",
    "label": "Bram Itam",
    "value": "Bram Itam"
  },
  {
    "id": "150612",
    "regency_id": "1506",
    "label": "Kuala Betara",
    "value": "Kuala Betara"
  },
  {
    "id": "150613",
    "regency_id": "1506",
    "label": "Senyerang",
    "value": "Senyerang"
  },
  {
    "id": "150701",
    "regency_id": "1507",
    "label": "Muara Sabak Timur",
    "value": "Muara Sabak Timur"
  },
  {
    "id": "150702",
    "regency_id": "1507",
    "label": "Nipah Panjang",
    "value": "Nipah Panjang"
  },
  {
    "id": "150703",
    "regency_id": "1507",
    "label": "Mendahara",
    "value": "Mendahara"
  },
  {
    "id": "150704",
    "regency_id": "1507",
    "label": "Rantau Rasau",
    "value": "Rantau Rasau"
  },
  { "id": "150705", "regency_id": "1507", "label": "Sadu", "value": "Sadu" },
  {
    "id": "150706",
    "regency_id": "1507",
    "label": "Dendang",
    "value": "Dendang"
  },
  {
    "id": "150707",
    "regency_id": "1507",
    "label": "Muara Sabak Barat",
    "value": "Muara Sabak Barat"
  },
  {
    "id": "150708",
    "regency_id": "1507",
    "label": "Kuala Jambi",
    "value": "Kuala Jambi"
  },
  {
    "id": "150709",
    "regency_id": "1507",
    "label": "Mendahara Ulu",
    "value": "Mendahara Ulu"
  },
  {
    "id": "150710",
    "regency_id": "1507",
    "label": "Geragai",
    "value": "Geragai"
  },
  {
    "id": "150711",
    "regency_id": "1507",
    "label": "Berbak",
    "value": "Berbak"
  },
  {
    "id": "150801",
    "regency_id": "1508",
    "label": "Tanah Tumbuh",
    "value": "Tanah Tumbuh"
  },
  {
    "id": "150802",
    "regency_id": "1508",
    "label": "Rantau Pandan",
    "value": "Rantau Pandan"
  },
  {
    "id": "150803",
    "regency_id": "1508",
    "label": "Pasar Muaro Bungo",
    "value": "Pasar Muaro Bungo"
  },
  {
    "id": "150804",
    "regency_id": "1508",
    "label": "Jujuhan",
    "value": "Jujuhan"
  },
  {
    "id": "150805",
    "regency_id": "1508",
    "label": "Tanah Sepenggal",
    "value": "Tanah Sepenggal"
  },
  {
    "id": "150806",
    "regency_id": "1508",
    "label": "Pelepat",
    "value": "Pelepat"
  },
  {
    "id": "150807",
    "regency_id": "1508",
    "label": "Limbur Lubuk Mengkuang",
    "value": "Limbur Lubuk Mengkuang"
  },
  {
    "id": "150808",
    "regency_id": "1508",
    "label": "Muko-muko Bathin VII",
    "value": "Muko-muko Bathin VII"
  },
  {
    "id": "150809",
    "regency_id": "1508",
    "label": "Pelepat Ilir",
    "value": "Pelepat Ilir"
  },
  {
    "id": "150810",
    "regency_id": "1508",
    "label": "Batin II Babeko",
    "value": "Batin II Babeko"
  },
  {
    "id": "150811",
    "regency_id": "1508",
    "label": "Bathin III",
    "value": "Bathin III"
  },
  {
    "id": "150812",
    "regency_id": "1508",
    "label": "Bungo Dani",
    "value": "Bungo Dani"
  },
  {
    "id": "150813",
    "regency_id": "1508",
    "label": "Rimbo Tengah",
    "value": "Rimbo Tengah"
  },
  {
    "id": "150814",
    "regency_id": "1508",
    "label": "Bathin III Ulu",
    "value": "Bathin III Ulu"
  },
  {
    "id": "150815",
    "regency_id": "1508",
    "label": "Bathin II Pelayang",
    "value": "Bathin II Pelayang"
  },
  {
    "id": "150816",
    "regency_id": "1508",
    "label": "Jujuhan Ilir",
    "value": "Jujuhan Ilir"
  },
  {
    "id": "150817",
    "regency_id": "1508",
    "label": "Tanah Sepenggal Lintas",
    "value": "Tanah Sepenggal Lintas"
  },
  {
    "id": "150901",
    "regency_id": "1509",
    "label": "Tebo Tengah",
    "value": "Tebo Tengah"
  },
  {
    "id": "150902",
    "regency_id": "1509",
    "label": "Tebo Ilir",
    "value": "Tebo Ilir"
  },
  {
    "id": "150903",
    "regency_id": "1509",
    "label": "Tebo Ulu",
    "value": "Tebo Ulu"
  },
  {
    "id": "150904",
    "regency_id": "1509",
    "label": "Rimbo Bujang",
    "value": "Rimbo Bujang"
  },
  { "id": "150905", "regency_id": "1509", "label": "Sumay", "value": "Sumay" },
  {
    "id": "150906",
    "regency_id": "1509",
    "label": "VII Koto",
    "value": "VII Koto"
  },
  {
    "id": "150907",
    "regency_id": "1509",
    "label": "Rimbo Ulu",
    "value": "Rimbo Ulu"
  },
  {
    "id": "150908",
    "regency_id": "1509",
    "label": "Rimbo Ilir",
    "value": "Rimbo Ilir"
  },
  {
    "id": "150909",
    "regency_id": "1509",
    "label": "Tengah Ilir",
    "value": "Tengah Ilir"
  },
  {
    "id": "150910",
    "regency_id": "1509",
    "label": "Serai Serumpun",
    "value": "Serai Serumpun"
  },
  {
    "id": "150911",
    "regency_id": "1509",
    "label": "VII Koto Ilir",
    "value": "VII Koto Ilir"
  },
  {
    "id": "150912",
    "regency_id": "1509",
    "label": "Muara Tabir",
    "value": "Muara Tabir"
  },
  {
    "id": "157101",
    "regency_id": "1571",
    "label": "Telanaipura",
    "value": "Telanaipura"
  },
  {
    "id": "157102",
    "regency_id": "1571",
    "label": "Jambi Selatan",
    "value": "Jambi Selatan"
  },
  {
    "id": "157103",
    "regency_id": "1571",
    "label": "Jambi Timur",
    "value": "Jambi Timur"
  },
  {
    "id": "157104",
    "regency_id": "1571",
    "label": "Pasar Jambi",
    "value": "Pasar Jambi"
  },
  {
    "id": "157105",
    "regency_id": "1571",
    "label": "Pelayangan",
    "value": "Pelayangan"
  },
  {
    "id": "157106",
    "regency_id": "1571",
    "label": "Danau Teluk",
    "value": "Danau Teluk"
  },
  {
    "id": "157107",
    "regency_id": "1571",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "157108",
    "regency_id": "1571",
    "label": "Jelutung",
    "value": "Jelutung"
  },
  {
    "id": "157109",
    "regency_id": "1571",
    "label": "Alam Barajo",
    "value": "Alam Barajo"
  },
  {
    "id": "157110",
    "regency_id": "1571",
    "label": "Danau Sipin",
    "value": "Danau Sipin"
  },
  {
    "id": "157111",
    "regency_id": "1571",
    "label": "Paal Merah",
    "value": "Paal Merah"
  },
  {
    "id": "157201",
    "regency_id": "1572",
    "label": "Sungai Penuh",
    "value": "Sungai Penuh"
  },
  {
    "id": "157202",
    "regency_id": "1572",
    "label": "Pesisir Bukit",
    "value": "Pesisir Bukit"
  },
  {
    "id": "157203",
    "regency_id": "1572",
    "label": "Hamparan Rawang",
    "value": "Hamparan Rawang"
  },
  {
    "id": "157204",
    "regency_id": "1572",
    "label": "Tanah Kampung",
    "value": "Tanah Kampung"
  },
  {
    "id": "157205",
    "regency_id": "1572",
    "label": "Kumun Debai",
    "value": "Kumun Debai"
  },
  {
    "id": "157206",
    "regency_id": "1572",
    "label": "Pondok Tinggi",
    "value": "Pondok Tinggi"
  },
  {
    "id": "157207",
    "regency_id": "1572",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "157208",
    "regency_id": "1572",
    "label": "Sungai Bungkal",
    "value": "Sungai Bungkal"
  },
  {
    "id": "160107",
    "regency_id": "1601",
    "label": "Sosoh Buay Rayap",
    "value": "Sosoh Buay Rayap"
  },
  {
    "id": "160108",
    "regency_id": "1601",
    "label": "Pengandonan",
    "value": "Pengandonan"
  },
  {
    "id": "160109",
    "regency_id": "1601",
    "label": "Peninjauan",
    "value": "Peninjauan"
  },
  {
    "id": "160113",
    "regency_id": "1601",
    "label": "Baturaja Barat",
    "value": "Baturaja Barat"
  },
  {
    "id": "160114",
    "regency_id": "1601",
    "label": "Baturaja Timur",
    "value": "Baturaja Timur"
  },
  {
    "id": "160120",
    "regency_id": "1601",
    "label": "Ulu Ogan",
    "value": "Ulu Ogan"
  },
  {
    "id": "160121",
    "regency_id": "1601",
    "label": "Semidang Aji",
    "value": "Semidang Aji"
  },
  {
    "id": "160122",
    "regency_id": "1601",
    "label": "Lubuk Batang",
    "value": "Lubuk Batang"
  },
  {
    "id": "160128",
    "regency_id": "1601",
    "label": "Lengkiti",
    "value": "Lengkiti"
  },
  {
    "id": "160129",
    "regency_id": "1601",
    "label": "Sinar Peninjauan",
    "value": "Sinar Peninjauan"
  },
  {
    "id": "160130",
    "regency_id": "1601",
    "label": "Lubuk Raja",
    "value": "Lubuk Raja"
  },
  {
    "id": "160131",
    "regency_id": "1601",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "160132",
    "regency_id": "1601",
    "label": "Kedaton Peninjauan Raya",
    "value": "Kedaton Peninjauan Raya"
  },
  {
    "id": "160202",
    "regency_id": "1602",
    "label": "Tanjung Lubuk",
    "value": "Tanjung Lubuk"
  },
  {
    "id": "160203",
    "regency_id": "1602",
    "label": "Pedamaran",
    "value": "Pedamaran"
  },
  {
    "id": "160204",
    "regency_id": "1602",
    "label": "Mesuji",
    "value": "Mesuji"
  },
  {
    "id": "160205",
    "regency_id": "1602",
    "label": "Kayu Agung",
    "value": "Kayu Agung"
  },
  {
    "id": "160208",
    "regency_id": "1602",
    "label": "Sirah Pulau Padang",
    "value": "Sirah Pulau Padang"
  },
  {
    "id": "160211",
    "regency_id": "1602",
    "label": "Tulung Selapan",
    "value": "Tulung Selapan"
  },
  {
    "id": "160212",
    "regency_id": "1602",
    "label": "Pampangan",
    "value": "Pampangan"
  },
  {
    "id": "160213",
    "regency_id": "1602",
    "label": "Lempuing",
    "value": "Lempuing"
  },
  {
    "id": "160214",
    "regency_id": "1602",
    "label": "Air Sugihan",
    "value": "Air Sugihan"
  },
  {
    "id": "160215",
    "regency_id": "1602",
    "label": "Sungai Menang",
    "value": "Sungai Menang"
  },
  {
    "id": "160217",
    "regency_id": "1602",
    "label": "Jejawi",
    "value": "Jejawi"
  },
  {
    "id": "160218",
    "regency_id": "1602",
    "label": "Cengal",
    "value": "Cengal"
  },
  {
    "id": "160219",
    "regency_id": "1602",
    "label": "Pangkalan Lampam",
    "value": "Pangkalan Lampam"
  },
  {
    "id": "160220",
    "regency_id": "1602",
    "label": "Mesuji Makmur",
    "value": "Mesuji Makmur"
  },
  {
    "id": "160221",
    "regency_id": "1602",
    "label": "Mesuji Raya",
    "value": "Mesuji Raya"
  },
  {
    "id": "160222",
    "regency_id": "1602",
    "label": "Lempuing Jaya",
    "value": "Lempuing Jaya"
  },
  {
    "id": "160223",
    "regency_id": "1602",
    "label": "Teluk Gelam",
    "value": "Teluk Gelam"
  },
  {
    "id": "160224",
    "regency_id": "1602",
    "label": "Pedamaran Timur",
    "value": "Pedamaran Timur"
  },
  {
    "id": "160301",
    "regency_id": "1603",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "160302",
    "regency_id": "1603",
    "label": "Muara Enim",
    "value": "Muara Enim"
  },
  {
    "id": "160303",
    "regency_id": "1603",
    "label": "Rambang Niru",
    "value": "Rambang Niru"
  },
  {
    "id": "160304",
    "regency_id": "1603",
    "label": "Gunung Megang",
    "value": "Gunung Megang"
  },
  {
    "id": "160306",
    "regency_id": "1603",
    "label": "Gelumbang",
    "value": "Gelumbang"
  },
  {
    "id": "160307",
    "regency_id": "1603",
    "label": "Lawang Kidul",
    "value": "Lawang Kidul"
  },
  {
    "id": "160308",
    "regency_id": "1603",
    "label": "Semende Darat Laut",
    "value": "Semende Darat Laut"
  },
  {
    "id": "160309",
    "regency_id": "1603",
    "label": "Semende Darat Tengah",
    "value": "Semende Darat Tengah"
  },
  {
    "id": "160310",
    "regency_id": "1603",
    "label": "Semende Darat Ulu",
    "value": "Semende Darat Ulu"
  },
  {
    "id": "160311",
    "regency_id": "1603",
    "label": "Ujan Mas",
    "value": "Ujan Mas"
  },
  { "id": "160314", "regency_id": "1603", "label": "Lubai", "value": "Lubai" },
  {
    "id": "160315",
    "regency_id": "1603",
    "label": "Rambang",
    "value": "Rambang"
  },
  {
    "id": "160316",
    "regency_id": "1603",
    "label": "Sungai Rotan",
    "value": "Sungai Rotan"
  },
  {
    "id": "160317",
    "regency_id": "1603",
    "label": "Lembak",
    "value": "Lembak"
  },
  {
    "id": "160319",
    "regency_id": "1603",
    "label": "Benakat",
    "value": "Benakat"
  },
  {
    "id": "160321",
    "regency_id": "1603",
    "label": "Kelekar",
    "value": "Kelekar"
  },
  {
    "id": "160322",
    "regency_id": "1603",
    "label": "Muara Belida",
    "value": "Muara Belida"
  },
  {
    "id": "160323",
    "regency_id": "1603",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "160324",
    "regency_id": "1603",
    "label": "Belida Darat",
    "value": "Belida Darat"
  },
  {
    "id": "160325",
    "regency_id": "1603",
    "label": "Lubai Ulu",
    "value": "Lubai Ulu"
  },
  {
    "id": "160326",
    "regency_id": "1603",
    "label": "Empat Petulai Dangku",
    "value": "Empat Petulai Dangku"
  },
  {
    "id": "160327",
    "regency_id": "1603",
    "label": "Panang Enim",
    "value": "Panang Enim"
  },
  {
    "id": "160401",
    "regency_id": "1604",
    "label": "Tanjungsakti Pumu",
    "value": "Tanjungsakti Pumu"
  },
  { "id": "160406", "regency_id": "1604", "label": "Jarai", "value": "Jarai" },
  {
    "id": "160407",
    "regency_id": "1604",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "160408",
    "regency_id": "1604",
    "label": "Pulaupinang",
    "value": "Pulaupinang"
  },
  {
    "id": "160409",
    "regency_id": "1604",
    "label": "Merapi Barat",
    "value": "Merapi Barat"
  },
  { "id": "160410", "regency_id": "1604", "label": "Lahat", "value": "Lahat" },
  {
    "id": "160412",
    "regency_id": "1604",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "160415",
    "regency_id": "1604",
    "label": "Mulak Ulu",
    "value": "Mulak Ulu"
  },
  {
    "id": "160416",
    "regency_id": "1604",
    "label": "Kikim Selatan",
    "value": "Kikim Selatan"
  },
  {
    "id": "160417",
    "regency_id": "1604",
    "label": "Kikim Timur",
    "value": "Kikim Timur"
  },
  {
    "id": "160418",
    "regency_id": "1604",
    "label": "Kikim Tengah",
    "value": "Kikim Tengah"
  },
  {
    "id": "160419",
    "regency_id": "1604",
    "label": "Kikim Barat",
    "value": "Kikim Barat"
  },
  {
    "id": "160420",
    "regency_id": "1604",
    "label": "Pseksu",
    "value": "Pseksu"
  },
  {
    "id": "160421",
    "regency_id": "1604",
    "label": "Gumay Talang",
    "value": "Gumay Talang"
  },
  {
    "id": "160422",
    "regency_id": "1604",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "160423",
    "regency_id": "1604",
    "label": "Merapi Timur",
    "value": "Merapi Timur"
  },
  {
    "id": "160424",
    "regency_id": "1604",
    "label": "Tanjung Sakti Pumi",
    "value": "Tanjung Sakti Pumi"
  },
  {
    "id": "160425",
    "regency_id": "1604",
    "label": "Gumay Ulu",
    "value": "Gumay Ulu"
  },
  {
    "id": "160426",
    "regency_id": "1604",
    "label": "Merapi Selatan",
    "value": "Merapi Selatan"
  },
  {
    "id": "160427",
    "regency_id": "1604",
    "label": "Tanjungtebat",
    "value": "Tanjungtebat"
  },
  {
    "id": "160428",
    "regency_id": "1604",
    "label": "Muarapayang",
    "value": "Muarapayang"
  },
  {
    "id": "160429",
    "regency_id": "1604",
    "label": "Sukamerindu",
    "value": "Sukamerindu"
  },
  {
    "id": "160430",
    "regency_id": "1604",
    "label": "Mulak Sebingkai",
    "value": "Mulak Sebingkai"
  },
  {
    "id": "160431",
    "regency_id": "1604",
    "label": "Lahat Selatan",
    "value": "Lahat Selatan"
  },
  {
    "id": "160501",
    "regency_id": "1605",
    "label": "Tugumulyo",
    "value": "Tugumulyo"
  },
  {
    "id": "160502",
    "regency_id": "1605",
    "label": "Muara Lakitan",
    "value": "Muara Lakitan"
  },
  {
    "id": "160503",
    "regency_id": "1605",
    "label": "Muara Kelingi",
    "value": "Muara Kelingi"
  },
  {
    "id": "160508",
    "regency_id": "1605",
    "label": "Jayaloka",
    "value": "Jayaloka"
  },
  {
    "id": "160509",
    "regency_id": "1605",
    "label": "Muara Beliti",
    "value": "Muara Beliti"
  },
  {
    "id": "160510",
    "regency_id": "1605",
    "label": "STL Ulu Terawas",
    "value": "STL Ulu Terawas"
  },
  {
    "id": "160511",
    "regency_id": "1605",
    "label": "Selangit",
    "value": "Selangit"
  },
  {
    "id": "160512",
    "regency_id": "1605",
    "label": "Megang Sakti",
    "value": "Megang Sakti"
  },
  {
    "id": "160513",
    "regency_id": "1605",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "160514",
    "regency_id": "1605",
    "label": "BTS. Ulu",
    "value": "BTS. Ulu"
  },
  {
    "id": "160518",
    "regency_id": "1605",
    "label": "Tiang Pumpung Kepungut",
    "value": "Tiang Pumpung Kepungut"
  },
  {
    "id": "160519",
    "regency_id": "1605",
    "label": "Sumber Harta",
    "value": "Sumber Harta"
  },
  {
    "id": "160520",
    "regency_id": "1605",
    "label": "Tuah Negeri",
    "value": "Tuah Negeri"
  },
  {
    "id": "160521",
    "regency_id": "1605",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "160601",
    "regency_id": "1606",
    "label": "Sekayu",
    "value": "Sekayu"
  },
  { "id": "160602", "regency_id": "1606", "label": "Lais", "value": "Lais" },
  {
    "id": "160603",
    "regency_id": "1606",
    "label": "Sungai Keruh",
    "value": "Sungai Keruh"
  },
  {
    "id": "160604",
    "regency_id": "1606",
    "label": "Batang Hari Leko",
    "value": "Batang Hari Leko"
  },
  {
    "id": "160605",
    "regency_id": "1606",
    "label": "Sanga Desa",
    "value": "Sanga Desa"
  },
  {
    "id": "160606",
    "regency_id": "1606",
    "label": "Babat Toman",
    "value": "Babat Toman"
  },
  {
    "id": "160607",
    "regency_id": "1606",
    "label": "Sungai Lilin",
    "value": "Sungai Lilin"
  },
  {
    "id": "160608",
    "regency_id": "1606",
    "label": "Keluang",
    "value": "Keluang"
  },
  {
    "id": "160609",
    "regency_id": "1606",
    "label": "Bayung Lencir",
    "value": "Bayung Lencir"
  },
  {
    "id": "160610",
    "regency_id": "1606",
    "label": "Plakat Tinggi",
    "value": "Plakat Tinggi"
  },
  { "id": "160611", "regency_id": "1606", "label": "Lalan", "value": "Lalan" },
  {
    "id": "160612",
    "regency_id": "1606",
    "label": "Tungkal Jaya",
    "value": "Tungkal Jaya"
  },
  {
    "id": "160613",
    "regency_id": "1606",
    "label": "Lawang Wetan",
    "value": "Lawang Wetan"
  },
  {
    "id": "160614",
    "regency_id": "1606",
    "label": "Babat Supat",
    "value": "Babat Supat"
  },
  {
    "id": "160615",
    "regency_id": "1606",
    "label": "Jirak Jaya",
    "value": "Jirak Jaya"
  },
  {
    "id": "160701",
    "regency_id": "1607",
    "label": "Banyuasin I",
    "value": "Banyuasin I"
  },
  {
    "id": "160702",
    "regency_id": "1607",
    "label": "Banyuasin II",
    "value": "Banyuasin II"
  },
  {
    "id": "160703",
    "regency_id": "1607",
    "label": "Banyuasin III",
    "value": "Banyuasin III"
  },
  {
    "id": "160704",
    "regency_id": "1607",
    "label": "Pulau Rimau",
    "value": "Pulau Rimau"
  },
  {
    "id": "160705",
    "regency_id": "1607",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "160706",
    "regency_id": "1607",
    "label": "Rambutan",
    "value": "Rambutan"
  },
  {
    "id": "160707",
    "regency_id": "1607",
    "label": "Muara Padang",
    "value": "Muara Padang"
  },
  {
    "id": "160708",
    "regency_id": "1607",
    "label": "Muara Telang",
    "value": "Muara Telang"
  },
  {
    "id": "160709",
    "regency_id": "1607",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "160710",
    "regency_id": "1607",
    "label": "Talang Kelapa",
    "value": "Talang Kelapa"
  },
  {
    "id": "160711",
    "regency_id": "1607",
    "label": "Rantau Bayur",
    "value": "Rantau Bayur"
  },
  {
    "id": "160712",
    "regency_id": "1607",
    "label": "Tanjung Lago",
    "value": "Tanjung Lago"
  },
  {
    "id": "160713",
    "regency_id": "1607",
    "label": "Muara Sugihan",
    "value": "Muara Sugihan"
  },
  {
    "id": "160714",
    "regency_id": "1607",
    "label": "Air Salek",
    "value": "Air Salek"
  },
  {
    "id": "160715",
    "regency_id": "1607",
    "label": "Tungkal Ilir",
    "value": "Tungkal Ilir"
  },
  {
    "id": "160716",
    "regency_id": "1607",
    "label": "Suak Tapeh",
    "value": "Suak Tapeh"
  },
  {
    "id": "160717",
    "regency_id": "1607",
    "label": "Sembawa",
    "value": "Sembawa"
  },
  {
    "id": "160718",
    "regency_id": "1607",
    "label": "Sumber Marga Telang",
    "value": "Sumber Marga Telang"
  },
  {
    "id": "160719",
    "regency_id": "1607",
    "label": "Air Kumbang",
    "value": "Air Kumbang"
  },
  {
    "id": "160720",
    "regency_id": "1607",
    "label": "Karang Agung Ilir",
    "value": "Karang Agung Ilir"
  },
  {
    "id": "160721",
    "regency_id": "1607",
    "label": "Selat Penuguan",
    "value": "Selat Penuguan"
  },
  {
    "id": "160801",
    "regency_id": "1608",
    "label": "Martapura",
    "value": "Martapura"
  },
  {
    "id": "160802",
    "regency_id": "1608",
    "label": "Buay Madang",
    "value": "Buay Madang"
  },
  {
    "id": "160803",
    "regency_id": "1608",
    "label": "Belitang",
    "value": "Belitang"
  },
  {
    "id": "160804",
    "regency_id": "1608",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "160805",
    "regency_id": "1608",
    "label": "Buay Pemuka Peliung",
    "value": "Buay Pemuka Peliung"
  },
  {
    "id": "160806",
    "regency_id": "1608",
    "label": "Madang Suku II",
    "value": "Madang Suku II"
  },
  {
    "id": "160807",
    "regency_id": "1608",
    "label": "Madang Suku I",
    "value": "Madang Suku I"
  },
  {
    "id": "160808",
    "regency_id": "1608",
    "label": "Semendawai Suku III",
    "value": "Semendawai Suku III"
  },
  {
    "id": "160809",
    "regency_id": "1608",
    "label": "Belitang II",
    "value": "Belitang II"
  },
  {
    "id": "160810",
    "regency_id": "1608",
    "label": "Belitang III",
    "value": "Belitang III"
  },
  {
    "id": "160811",
    "regency_id": "1608",
    "label": "Bunga Mayang",
    "value": "Bunga Mayang"
  },
  {
    "id": "160812",
    "regency_id": "1608",
    "label": "Buay Madang Timur",
    "value": "Buay Madang Timur"
  },
  {
    "id": "160813",
    "regency_id": "1608",
    "label": "Madang Suku III",
    "value": "Madang Suku III"
  },
  {
    "id": "160814",
    "regency_id": "1608",
    "label": "Semendawai Barat",
    "value": "Semendawai Barat"
  },
  {
    "id": "160815",
    "regency_id": "1608",
    "label": "Semendawai Timur",
    "value": "Semendawai Timur"
  },
  {
    "id": "160816",
    "regency_id": "1608",
    "label": "Jayapura",
    "value": "Jayapura"
  },
  {
    "id": "160817",
    "regency_id": "1608",
    "label": "Belitang Jaya",
    "value": "Belitang Jaya"
  },
  {
    "id": "160818",
    "regency_id": "1608",
    "label": "Belitang Madang Raya",
    "value": "Belitang Madang Raya"
  },
  {
    "id": "160819",
    "regency_id": "1608",
    "label": "Belitang Mulya",
    "value": "Belitang Mulya"
  },
  {
    "id": "160820",
    "regency_id": "1608",
    "label": "Buay Pemuka Bangsa Raja",
    "value": "Buay Pemuka Bangsa Raja"
  },
  {
    "id": "160901",
    "regency_id": "1609",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "160902",
    "regency_id": "1609",
    "label": "Pulau Beringin",
    "value": "Pulau Beringin"
  },
  {
    "id": "160903",
    "regency_id": "1609",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "160904",
    "regency_id": "1609",
    "label": "Muara Dua Kisam",
    "value": "Muara Dua Kisam"
  },
  {
    "id": "160905",
    "regency_id": "1609",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "160906",
    "regency_id": "1609",
    "label": "Buay Sandang Aji",
    "value": "Buay Sandang Aji"
  },
  {
    "id": "160907",
    "regency_id": "1609",
    "label": "Buay Runjung",
    "value": "Buay Runjung"
  },
  {
    "id": "160908",
    "regency_id": "1609",
    "label": "Mekakau Ilir",
    "value": "Mekakau Ilir"
  },
  {
    "id": "160909",
    "regency_id": "1609",
    "label": "Buay Pemaca",
    "value": "Buay Pemaca"
  },
  {
    "id": "160910",
    "regency_id": "1609",
    "label": "Kisam Tinggi",
    "value": "Kisam Tinggi"
  },
  {
    "id": "160911",
    "regency_id": "1609",
    "label": "Kisam Ilir",
    "value": "Kisam Ilir"
  },
  {
    "id": "160912",
    "regency_id": "1609",
    "label": "Buay Pematang Ribu Ranau T",
    "value": "Buay Pematang Ribu Ranau T"
  },
  {
    "id": "160913",
    "regency_id": "1609",
    "label": "Warkuk Ranau Selatan",
    "value": "Warkuk Ranau Selatan"
  },
  {
    "id": "160914",
    "regency_id": "1609",
    "label": "Runjung Agung",
    "value": "Runjung Agung"
  },
  {
    "id": "160915",
    "regency_id": "1609",
    "label": "Sungai Are",
    "value": "Sungai Are"
  },
  {
    "id": "160916",
    "regency_id": "1609",
    "label": "Sindang Danau",
    "value": "Sindang Danau"
  },
  {
    "id": "160917",
    "regency_id": "1609",
    "label": "Buana Pemaca",
    "value": "Buana Pemaca"
  },
  {
    "id": "160918",
    "regency_id": "1609",
    "label": "Tiga Dihaji",
    "value": "Tiga Dihaji"
  },
  {
    "id": "160919",
    "regency_id": "1609",
    "label": "Buay Rawan",
    "value": "Buay Rawan"
  },
  {
    "id": "161001",
    "regency_id": "1610",
    "label": "Muara Kuang",
    "value": "Muara Kuang"
  },
  {
    "id": "161002",
    "regency_id": "1610",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "161003",
    "regency_id": "1610",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "161004",
    "regency_id": "1610",
    "label": "Indralaya",
    "value": "Indralaya"
  },
  {
    "id": "161005",
    "regency_id": "1610",
    "label": "Pemulutan",
    "value": "Pemulutan"
  },
  {
    "id": "161006",
    "regency_id": "1610",
    "label": "Rantau Alai",
    "value": "Rantau Alai"
  },
  {
    "id": "161007",
    "regency_id": "1610",
    "label": "Indralaya Utara",
    "value": "Indralaya Utara"
  },
  {
    "id": "161008",
    "regency_id": "1610",
    "label": "Indralaya Selatan",
    "value": "Indralaya Selatan"
  },
  {
    "id": "161009",
    "regency_id": "1610",
    "label": "Pemulutan Selatan",
    "value": "Pemulutan Selatan"
  },
  {
    "id": "161010",
    "regency_id": "1610",
    "label": "Pemulutan Barat",
    "value": "Pemulutan Barat"
  },
  {
    "id": "161011",
    "regency_id": "1610",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "161012",
    "regency_id": "1610",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "161013",
    "regency_id": "1610",
    "label": "Kandis",
    "value": "Kandis"
  },
  {
    "id": "161014",
    "regency_id": "1610",
    "label": "Rambang Kuang",
    "value": "Rambang Kuang"
  },
  {
    "id": "161015",
    "regency_id": "1610",
    "label": "Lubuk Keliat",
    "value": "Lubuk Keliat"
  },
  {
    "id": "161016",
    "regency_id": "1610",
    "label": "Payaraman",
    "value": "Payaraman"
  },
  {
    "id": "161101",
    "regency_id": "1611",
    "label": "Muara Pinang",
    "value": "Muara Pinang"
  },
  {
    "id": "161102",
    "regency_id": "1611",
    "label": "Pendopo",
    "value": "Pendopo"
  },
  {
    "id": "161103",
    "regency_id": "1611",
    "label": "Ulu Musi",
    "value": "Ulu Musi"
  },
  {
    "id": "161104",
    "regency_id": "1611",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "161105",
    "regency_id": "1611",
    "label": "Lintang Kanan",
    "value": "Lintang Kanan"
  },
  {
    "id": "161106",
    "regency_id": "1611",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "161107",
    "regency_id": "1611",
    "label": "Pasemah Air Keruh",
    "value": "Pasemah Air Keruh"
  },
  {
    "id": "161108",
    "regency_id": "1611",
    "label": "Sikap Dalam",
    "value": "Sikap Dalam"
  },
  {
    "id": "161109",
    "regency_id": "1611",
    "label": "Saling",
    "value": "Saling"
  },
  {
    "id": "161110",
    "regency_id": "1611",
    "label": "Pendopo Barat",
    "value": "Pendopo Barat"
  },
  {
    "id": "161201",
    "regency_id": "1612",
    "label": "Talang Ubi",
    "value": "Talang Ubi"
  },
  {
    "id": "161202",
    "regency_id": "1612",
    "label": "Penukal Utara",
    "value": "Penukal Utara"
  },
  {
    "id": "161203",
    "regency_id": "1612",
    "label": "Penukal",
    "value": "Penukal"
  },
  { "id": "161204", "regency_id": "1612", "label": "Abab", "value": "Abab" },
  {
    "id": "161205",
    "regency_id": "1612",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  { "id": "161301", "regency_id": "1613", "label": "Rupit", "value": "Rupit" },
  {
    "id": "161302",
    "regency_id": "1613",
    "label": "Rawas Ulu",
    "value": "Rawas Ulu"
  },
  {
    "id": "161303",
    "regency_id": "1613",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "161304",
    "regency_id": "1613",
    "label": "Rawas Ilir",
    "value": "Rawas Ilir"
  },
  {
    "id": "161305",
    "regency_id": "1613",
    "label": "Karang Dapo",
    "value": "Karang Dapo"
  },
  {
    "id": "161306",
    "regency_id": "1613",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "161307",
    "regency_id": "1613",
    "label": "Ulu Rawas",
    "value": "Ulu Rawas"
  },
  {
    "id": "167101",
    "regency_id": "1671",
    "label": "Ilir Barat II",
    "value": "Ilir Barat II"
  },
  {
    "id": "167102",
    "regency_id": "1671",
    "label": "Seberang Ulu I",
    "value": "Seberang Ulu I"
  },
  {
    "id": "167103",
    "regency_id": "1671",
    "label": "Seberang Ulu II",
    "value": "Seberang Ulu II"
  },
  {
    "id": "167104",
    "regency_id": "1671",
    "label": "Ilir Barat I",
    "value": "Ilir Barat I"
  },
  {
    "id": "167105",
    "regency_id": "1671",
    "label": "Ilir Timur I",
    "value": "Ilir Timur I"
  },
  {
    "id": "167106",
    "regency_id": "1671",
    "label": "Ilir Timur II",
    "value": "Ilir Timur II"
  },
  {
    "id": "167107",
    "regency_id": "1671",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  { "id": "167108", "regency_id": "1671", "label": "Sako", "value": "Sako" },
  {
    "id": "167109",
    "regency_id": "1671",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "167110",
    "regency_id": "1671",
    "label": "Kalidoni",
    "value": "Kalidoni"
  },
  {
    "id": "167111",
    "regency_id": "1671",
    "label": "Bukit Kecil",
    "value": "Bukit Kecil"
  },
  {
    "id": "167112",
    "regency_id": "1671",
    "label": "Gandus",
    "value": "Gandus"
  },
  {
    "id": "167113",
    "regency_id": "1671",
    "label": "Kertapati",
    "value": "Kertapati"
  },
  { "id": "167114", "regency_id": "1671", "label": "Plaju", "value": "Plaju" },
  {
    "id": "167115",
    "regency_id": "1671",
    "label": "Alang-alang Lebar",
    "value": "Alang-alang Lebar"
  },
  {
    "id": "167116",
    "regency_id": "1671",
    "label": "Sematang Borang",
    "value": "Sematang Borang"
  },
  {
    "id": "167117",
    "regency_id": "1671",
    "label": "Jakabaring",
    "value": "Jakabaring"
  },
  {
    "id": "167118",
    "regency_id": "1671",
    "label": "Ilir Timur Tiga",
    "value": "Ilir Timur Tiga"
  },
  {
    "id": "167201",
    "regency_id": "1672",
    "label": "Pagar Alam Utara",
    "value": "Pagar Alam Utara"
  },
  {
    "id": "167202",
    "regency_id": "1672",
    "label": "Pagar Alam Selatan",
    "value": "Pagar Alam Selatan"
  },
  {
    "id": "167203",
    "regency_id": "1672",
    "label": "Dempo Utara",
    "value": "Dempo Utara"
  },
  {
    "id": "167204",
    "regency_id": "1672",
    "label": "Dempo Selatan",
    "value": "Dempo Selatan"
  },
  {
    "id": "167205",
    "regency_id": "1672",
    "label": "Dempo Tengah",
    "value": "Dempo Tengah"
  },
  {
    "id": "167301",
    "regency_id": "1673",
    "label": "Lubuk Linggau Timur I",
    "value": "Lubuk Linggau Timur I"
  },
  {
    "id": "167302",
    "regency_id": "1673",
    "label": "Lubuk Linggau Barat I",
    "value": "Lubuk Linggau Barat I"
  },
  {
    "id": "167303",
    "regency_id": "1673",
    "label": "Lubuk Linggau Selatan I",
    "value": "Lubuk Linggau Selatan I"
  },
  {
    "id": "167304",
    "regency_id": "1673",
    "label": "Lubuk Linggau Utara I",
    "value": "Lubuk Linggau Utara I"
  },
  {
    "id": "167305",
    "regency_id": "1673",
    "label": "Lubuk Linggau Timur II",
    "value": "Lubuk Linggau Timur II"
  },
  {
    "id": "167306",
    "regency_id": "1673",
    "label": "Lubuk Linggau Barat II",
    "value": "Lubuk Linggau Barat II"
  },
  {
    "id": "167307",
    "regency_id": "1673",
    "label": "Lubuk Linggau Selatan II",
    "value": "Lubuk Linggau Selatan II"
  },
  {
    "id": "167308",
    "regency_id": "1673",
    "label": "Lubuk Linggau Utara II",
    "value": "Lubuk Linggau Utara II"
  },
  {
    "id": "167401",
    "regency_id": "1674",
    "label": "Prabumulih Barat",
    "value": "Prabumulih Barat"
  },
  {
    "id": "167402",
    "regency_id": "1674",
    "label": "Prabumulih Timur",
    "value": "Prabumulih Timur"
  },
  {
    "id": "167403",
    "regency_id": "1674",
    "label": "Cambai",
    "value": "Cambai"
  },
  {
    "id": "167404",
    "regency_id": "1674",
    "label": "Rambang Kpk Tengah",
    "value": "Rambang Kpk Tengah"
  },
  {
    "id": "167405",
    "regency_id": "1674",
    "label": "Prabumulih Utara",
    "value": "Prabumulih Utara"
  },
  {
    "id": "167406",
    "regency_id": "1674",
    "label": "Prabumulih Selatan",
    "value": "Prabumulih Selatan"
  },
  {
    "id": "170101",
    "regency_id": "1701",
    "label": "Kedurang",
    "value": "Kedurang"
  },
  {
    "id": "170102",
    "regency_id": "1701",
    "label": "Seginim",
    "value": "Seginim"
  },
  { "id": "170103", "regency_id": "1701", "label": "Pino", "value": "Pino" },
  { "id": "170104", "regency_id": "1701", "label": "Manna", "value": "Manna" },
  {
    "id": "170105",
    "regency_id": "1701",
    "label": "Kota Manna",
    "value": "Kota Manna"
  },
  {
    "id": "170106",
    "regency_id": "1701",
    "label": "Pino Raya",
    "value": "Pino Raya"
  },
  {
    "id": "170107",
    "regency_id": "1701",
    "label": "Kedurang Ilir",
    "value": "Kedurang Ilir"
  },
  {
    "id": "170108",
    "regency_id": "1701",
    "label": "Air Nipis",
    "value": "Air Nipis"
  },
  {
    "id": "170109",
    "regency_id": "1701",
    "label": "Ulu Manna",
    "value": "Ulu Manna"
  },
  {
    "id": "170110",
    "regency_id": "1701",
    "label": "Bunga Mas",
    "value": "Bunga Mas"
  },
  {
    "id": "170111",
    "regency_id": "1701",
    "label": "Pasar Manna",
    "value": "Pasar Manna"
  },
  {
    "id": "170206",
    "regency_id": "1702",
    "label": "Kota Padang",
    "value": "Kota Padang"
  },
  {
    "id": "170207",
    "regency_id": "1702",
    "label": "Padang Ulak Tanding",
    "value": "Padang Ulak Tanding"
  },
  {
    "id": "170208",
    "regency_id": "1702",
    "label": "Sindang Kelingi",
    "value": "Sindang Kelingi"
  },
  { "id": "170209", "regency_id": "1702", "label": "Curup", "value": "Curup" },
  {
    "id": "170210",
    "regency_id": "1702",
    "label": "Bermani Ulu",
    "value": "Bermani Ulu"
  },
  {
    "id": "170211",
    "regency_id": "1702",
    "label": "Selupu Rejang",
    "value": "Selupu Rejang"
  },
  {
    "id": "170216",
    "regency_id": "1702",
    "label": "Curup Utara",
    "value": "Curup Utara"
  },
  {
    "id": "170217",
    "regency_id": "1702",
    "label": "Curup Timur",
    "value": "Curup Timur"
  },
  {
    "id": "170218",
    "regency_id": "1702",
    "label": "Curup Selatan",
    "value": "Curup Selatan"
  },
  {
    "id": "170219",
    "regency_id": "1702",
    "label": "Curup Tengah",
    "value": "Curup Tengah"
  },
  {
    "id": "170220",
    "regency_id": "1702",
    "label": "Binduriang",
    "value": "Binduriang"
  },
  {
    "id": "170221",
    "regency_id": "1702",
    "label": "Sindang Beliti Ulu",
    "value": "Sindang Beliti Ulu"
  },
  {
    "id": "170222",
    "regency_id": "1702",
    "label": "Sindang Dataran",
    "value": "Sindang Dataran"
  },
  {
    "id": "170223",
    "regency_id": "1702",
    "label": "Sindang Beliti Ilir",
    "value": "Sindang Beliti Ilir"
  },
  {
    "id": "170224",
    "regency_id": "1702",
    "label": "Bermani Ulu Raya",
    "value": "Bermani Ulu Raya"
  },
  {
    "id": "170301",
    "regency_id": "1703",
    "label": "Enggano",
    "value": "Enggano"
  },
  {
    "id": "170306",
    "regency_id": "1703",
    "label": "Kerkap",
    "value": "Kerkap"
  },
  {
    "id": "170307",
    "regency_id": "1703",
    "label": "Kota Arga Makmur",
    "value": "Kota Arga Makmur"
  },
  {
    "id": "170308",
    "regency_id": "1703",
    "label": "Giri Mulya",
    "value": "Giri Mulya"
  },
  {
    "id": "170309",
    "regency_id": "1703",
    "label": "Padang Jaya",
    "value": "Padang Jaya"
  },
  { "id": "170310", "regency_id": "1703", "label": "Lais", "value": "Lais" },
  {
    "id": "170311",
    "regency_id": "1703",
    "label": "Batik Nau",
    "value": "Batik Nau"
  },
  {
    "id": "170312",
    "regency_id": "1703",
    "label": "Ketahun",
    "value": "Ketahun"
  },
  {
    "id": "170313",
    "regency_id": "1703",
    "label": "Napal Putih",
    "value": "Napal Putih"
  },
  {
    "id": "170314",
    "regency_id": "1703",
    "label": "Putri Hijau",
    "value": "Putri Hijau"
  },
  {
    "id": "170315",
    "regency_id": "1703",
    "label": "Air Besi",
    "value": "Air Besi"
  },
  {
    "id": "170316",
    "regency_id": "1703",
    "label": "Air Napal",
    "value": "Air Napal"
  },
  {
    "id": "170319",
    "regency_id": "1703",
    "label": "Hulu Palik",
    "value": "Hulu Palik"
  },
  {
    "id": "170320",
    "regency_id": "1703",
    "label": "Air Padang",
    "value": "Air Padang"
  },
  {
    "id": "170321",
    "regency_id": "1703",
    "label": "Arma Jaya",
    "value": "Arma Jaya"
  },
  {
    "id": "170322",
    "regency_id": "1703",
    "label": "Tanjung Agung Palik",
    "value": "Tanjung Agung Palik"
  },
  {
    "id": "170323",
    "regency_id": "1703",
    "label": "Ulok Kupai",
    "value": "Ulok Kupai"
  },
  {
    "id": "170324",
    "regency_id": "1703",
    "label": "Pinang Raya",
    "value": "Pinang Raya"
  },
  {
    "id": "170325",
    "regency_id": "1703",
    "label": "Marga Sakti Sebelat",
    "value": "Marga Sakti Sebelat"
  },
  { "id": "170401", "regency_id": "1704", "label": "Kinal", "value": "Kinal" },
  {
    "id": "170402",
    "regency_id": "1704",
    "label": "Tanjung Kemuning",
    "value": "Tanjung Kemuning"
  },
  {
    "id": "170403",
    "regency_id": "1704",
    "label": "Kaur Utara",
    "value": "Kaur Utara"
  },
  {
    "id": "170404",
    "regency_id": "1704",
    "label": "Kaur Tengah",
    "value": "Kaur Tengah"
  },
  {
    "id": "170405",
    "regency_id": "1704",
    "label": "Kaur Selatan",
    "value": "Kaur Selatan"
  },
  { "id": "170406", "regency_id": "1704", "label": "Maje", "value": "Maje" },
  { "id": "170407", "regency_id": "1704", "label": "Nasal", "value": "Nasal" },
  {
    "id": "170408",
    "regency_id": "1704",
    "label": "Semidang Gumay",
    "value": "Semidang Gumay"
  },
  {
    "id": "170409",
    "regency_id": "1704",
    "label": "Kelam Tengah",
    "value": "Kelam Tengah"
  },
  { "id": "170410", "regency_id": "1704", "label": "Luas", "value": "Luas" },
  {
    "id": "170411",
    "regency_id": "1704",
    "label": "Muara Sahung",
    "value": "Muara Sahung"
  },
  { "id": "170412", "regency_id": "1704", "label": "Tetap", "value": "Tetap" },
  {
    "id": "170413",
    "regency_id": "1704",
    "label": "Lungkang Kule",
    "value": "Lungkang Kule"
  },
  {
    "id": "170414",
    "regency_id": "1704",
    "label": "Padang Guci Hilir",
    "value": "Padang Guci Hilir"
  },
  {
    "id": "170415",
    "regency_id": "1704",
    "label": "Padang Guci Hulu",
    "value": "Padang Guci Hulu"
  },
  {
    "id": "170501",
    "regency_id": "1705",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "170502",
    "regency_id": "1705",
    "label": "Seluma",
    "value": "Seluma"
  },
  { "id": "170503", "regency_id": "1705", "label": "Talo", "value": "Talo" },
  {
    "id": "170504",
    "regency_id": "1705",
    "label": "Semidang Alas",
    "value": "Semidang Alas"
  },
  {
    "id": "170505",
    "regency_id": "1705",
    "label": "Semidang Alas Maras",
    "value": "Semidang Alas Maras"
  },
  {
    "id": "170506",
    "regency_id": "1705",
    "label": "Air Periukan",
    "value": "Air Periukan"
  },
  {
    "id": "170507",
    "regency_id": "1705",
    "label": "Lubuk Sandi",
    "value": "Lubuk Sandi"
  },
  {
    "id": "170508",
    "regency_id": "1705",
    "label": "Seluma Barat",
    "value": "Seluma Barat"
  },
  {
    "id": "170509",
    "regency_id": "1705",
    "label": "Seluma Timur",
    "value": "Seluma Timur"
  },
  {
    "id": "170510",
    "regency_id": "1705",
    "label": "Seluma Utara",
    "value": "Seluma Utara"
  },
  {
    "id": "170511",
    "regency_id": "1705",
    "label": "Seluma Selatan",
    "value": "Seluma Selatan"
  },
  {
    "id": "170512",
    "regency_id": "1705",
    "label": "Talo Kecil",
    "value": "Talo Kecil"
  },
  {
    "id": "170513",
    "regency_id": "1705",
    "label": "Ulu Talo",
    "value": "Ulu Talo"
  },
  {
    "id": "170514",
    "regency_id": "1705",
    "label": "Ilir Talo",
    "value": "Ilir Talo"
  },
  {
    "id": "170601",
    "regency_id": "1706",
    "label": "Lubuk Pinang",
    "value": "Lubuk Pinang"
  },
  {
    "id": "170602",
    "regency_id": "1706",
    "label": "Kota Mukomuko",
    "value": "Kota Mukomuko"
  },
  {
    "id": "170603",
    "regency_id": "1706",
    "label": "Teras Terunjam",
    "value": "Teras Terunjam"
  },
  {
    "id": "170604",
    "regency_id": "1706",
    "label": "Pondok Suguh",
    "value": "Pondok Suguh"
  },
  { "id": "170605", "regency_id": "1706", "label": "Ipuh", "value": "Ipuh" },
  {
    "id": "170606",
    "regency_id": "1706",
    "label": "Malin Deman",
    "value": "Malin Deman"
  },
  {
    "id": "170607",
    "regency_id": "1706",
    "label": "Air Rami",
    "value": "Air Rami"
  },
  {
    "id": "170608",
    "regency_id": "1706",
    "label": "Teramang Jaya",
    "value": "Teramang Jaya"
  },
  {
    "id": "170609",
    "regency_id": "1706",
    "label": "Selagan Raya",
    "value": "Selagan Raya"
  },
  {
    "id": "170610",
    "regency_id": "1706",
    "label": "Penarik",
    "value": "Penarik"
  },
  {
    "id": "170611",
    "regency_id": "1706",
    "label": "XIV Koto",
    "value": "XIV Koto"
  },
  {
    "id": "170612",
    "regency_id": "1706",
    "label": "V Koto",
    "value": "V Koto"
  },
  {
    "id": "170613",
    "regency_id": "1706",
    "label": "Air Majunto",
    "value": "Air Majunto"
  },
  {
    "id": "170614",
    "regency_id": "1706",
    "label": "Air Dikit",
    "value": "Air Dikit"
  },
  {
    "id": "170615",
    "regency_id": "1706",
    "label": "Sungai Rumbai",
    "value": "Sungai Rumbai"
  },
  {
    "id": "170701",
    "regency_id": "1707",
    "label": "Lebong Utara",
    "value": "Lebong Utara"
  },
  {
    "id": "170702",
    "regency_id": "1707",
    "label": "Lebong Atas",
    "value": "Lebong Atas"
  },
  {
    "id": "170703",
    "regency_id": "1707",
    "label": "Lebong Tengah",
    "value": "Lebong Tengah"
  },
  {
    "id": "170704",
    "regency_id": "1707",
    "label": "Lebong Selatan",
    "value": "Lebong Selatan"
  },
  {
    "id": "170705",
    "regency_id": "1707",
    "label": "Rimbo Pengadang",
    "value": "Rimbo Pengadang"
  },
  { "id": "170706", "regency_id": "1707", "label": "Topos", "value": "Topos" },
  {
    "id": "170707",
    "regency_id": "1707",
    "label": "Bingin Kuning",
    "value": "Bingin Kuning"
  },
  {
    "id": "170708",
    "regency_id": "1707",
    "label": "Lebong Sakti",
    "value": "Lebong Sakti"
  },
  {
    "id": "170709",
    "regency_id": "1707",
    "label": "Pelabai",
    "value": "Pelabai"
  },
  { "id": "170710", "regency_id": "1707", "label": "Amen", "value": "Amen" },
  {
    "id": "170711",
    "regency_id": "1707",
    "label": "Uram Jaya",
    "value": "Uram Jaya"
  },
  {
    "id": "170712",
    "regency_id": "1707",
    "label": "Pinang Belapis",
    "value": "Pinang Belapis"
  },
  {
    "id": "170801",
    "regency_id": "1708",
    "label": "Bermani Ilir",
    "value": "Bermani Ilir"
  },
  {
    "id": "170802",
    "regency_id": "1708",
    "label": "Ujan Mas",
    "value": "Ujan Mas"
  },
  {
    "id": "170803",
    "regency_id": "1708",
    "label": "Tebat Karai",
    "value": "Tebat Karai"
  },
  {
    "id": "170804",
    "regency_id": "1708",
    "label": "Kepahiang",
    "value": "Kepahiang"
  },
  {
    "id": "170805",
    "regency_id": "1708",
    "label": "Merigi",
    "value": "Merigi"
  },
  {
    "id": "170806",
    "regency_id": "1708",
    "label": "Kebawetan",
    "value": "Kebawetan"
  },
  {
    "id": "170807",
    "regency_id": "1708",
    "label": "Seberang Musi",
    "value": "Seberang Musi"
  },
  {
    "id": "170808",
    "regency_id": "1708",
    "label": "Muara Kemumu",
    "value": "Muara Kemumu"
  },
  {
    "id": "170901",
    "regency_id": "1709",
    "label": "Karang Tinggi",
    "value": "Karang Tinggi"
  },
  {
    "id": "170902",
    "regency_id": "1709",
    "label": "Talang Empat",
    "value": "Talang Empat"
  },
  {
    "id": "170903",
    "regency_id": "1709",
    "label": "Pondok Kelapa",
    "value": "Pondok Kelapa"
  },
  {
    "id": "170904",
    "regency_id": "1709",
    "label": "Pematang Tiga",
    "value": "Pematang Tiga"
  },
  {
    "id": "170905",
    "regency_id": "1709",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "170906",
    "regency_id": "1709",
    "label": "Taba Penanjung",
    "value": "Taba Penanjung"
  },
  {
    "id": "170907",
    "regency_id": "1709",
    "label": "Merigi Kelindang",
    "value": "Merigi Kelindang"
  },
  {
    "id": "170908",
    "regency_id": "1709",
    "label": "Merigi Sakti",
    "value": "Merigi Sakti"
  },
  {
    "id": "170909",
    "regency_id": "1709",
    "label": "Pondok Kubang",
    "value": "Pondok Kubang"
  },
  {
    "id": "170910",
    "regency_id": "1709",
    "label": "Bang Haji",
    "value": "Bang Haji"
  },
  {
    "id": "170911",
    "regency_id": "1709",
    "label": "Semidang Lagan",
    "value": "Semidang Lagan"
  },
  {
    "id": "177101",
    "regency_id": "1771",
    "label": "Selebar",
    "value": "Selebar"
  },
  {
    "id": "177102",
    "regency_id": "1771",
    "label": "Gading Cempaka",
    "value": "Gading Cempaka"
  },
  {
    "id": "177103",
    "regency_id": "1771",
    "label": "Teluk Segara",
    "value": "Teluk Segara"
  },
  {
    "id": "177104",
    "regency_id": "1771",
    "label": "Muara Bangka Hulu",
    "value": "Muara Bangka Hulu"
  },
  {
    "id": "177105",
    "regency_id": "1771",
    "label": "Kampung Melayu",
    "value": "Kampung Melayu"
  },
  {
    "id": "177106",
    "regency_id": "1771",
    "label": "Ratu Agung",
    "value": "Ratu Agung"
  },
  {
    "id": "177107",
    "regency_id": "1771",
    "label": "Ratu Samban",
    "value": "Ratu Samban"
  },
  {
    "id": "177108",
    "regency_id": "1771",
    "label": "Sungai Serut",
    "value": "Sungai Serut"
  },
  {
    "id": "177109",
    "regency_id": "1771",
    "label": "Singaran Pati",
    "value": "Singaran Pati"
  },
  { "id": "180104", "regency_id": "1801", "label": "Natar", "value": "Natar" },
  {
    "id": "180105",
    "regency_id": "1801",
    "label": "Tanjung Bintang",
    "value": "Tanjung Bintang"
  },
  {
    "id": "180106",
    "regency_id": "1801",
    "label": "Kalianda",
    "value": "Kalianda"
  },
  {
    "id": "180107",
    "regency_id": "1801",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "180108",
    "regency_id": "1801",
    "label": "Katibung",
    "value": "Katibung"
  },
  {
    "id": "180109",
    "regency_id": "1801",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  { "id": "180110", "regency_id": "1801", "label": "Palas", "value": "Palas" },
  {
    "id": "180113",
    "regency_id": "1801",
    "label": "Jati Agung",
    "value": "Jati Agung"
  },
  {
    "id": "180114",
    "regency_id": "1801",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  { "id": "180115", "regency_id": "1801", "label": "Sragi", "value": "Sragi" },
  {
    "id": "180116",
    "regency_id": "1801",
    "label": "Raja Basa",
    "value": "Raja Basa"
  },
  {
    "id": "180117",
    "regency_id": "1801",
    "label": "Candipuro",
    "value": "Candipuro"
  },
  {
    "id": "180118",
    "regency_id": "1801",
    "label": "Merbau Mataram",
    "value": "Merbau Mataram"
  },
  {
    "id": "180121",
    "regency_id": "1801",
    "label": "Bakauheni",
    "value": "Bakauheni"
  },
  {
    "id": "180122",
    "regency_id": "1801",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "180123",
    "regency_id": "1801",
    "label": "Way Sulan",
    "value": "Way Sulan"
  },
  {
    "id": "180124",
    "regency_id": "1801",
    "label": "Way Panji",
    "value": "Way Panji"
  },
  {
    "id": "180201",
    "regency_id": "1802",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "180202",
    "regency_id": "1802",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "180203",
    "regency_id": "1802",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "180204",
    "regency_id": "1802",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "180205",
    "regency_id": "1802",
    "label": "Trimurjo",
    "value": "Trimurjo"
  },
  {
    "id": "180206",
    "regency_id": "1802",
    "label": "Punggur",
    "value": "Punggur"
  },
  {
    "id": "180207",
    "regency_id": "1802",
    "label": "Terbanggi Besar",
    "value": "Terbanggi Besar"
  },
  {
    "id": "180208",
    "regency_id": "1802",
    "label": "Seputih Raman",
    "value": "Seputih Raman"
  },
  {
    "id": "180209",
    "regency_id": "1802",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "180210",
    "regency_id": "1802",
    "label": "Seputih Banyak",
    "value": "Seputih Banyak"
  },
  {
    "id": "180211",
    "regency_id": "1802",
    "label": "Seputih Mataram",
    "value": "Seputih Mataram"
  },
  {
    "id": "180212",
    "regency_id": "1802",
    "label": "Seputih Surabaya",
    "value": "Seputih Surabaya"
  },
  {
    "id": "180213",
    "regency_id": "1802",
    "label": "Terusan Nunyai",
    "value": "Terusan Nunyai"
  },
  {
    "id": "180214",
    "regency_id": "1802",
    "label": "Bumi Ratu Nuban",
    "value": "Bumi Ratu Nuban"
  },
  { "id": "180215", "regency_id": "1802", "label": "Bekri", "value": "Bekri" },
  {
    "id": "180216",
    "regency_id": "1802",
    "label": "Seputih Agung",
    "value": "Seputih Agung"
  },
  {
    "id": "180217",
    "regency_id": "1802",
    "label": "Way Pangubuan",
    "value": "Way Pangubuan"
  },
  {
    "id": "180218",
    "regency_id": "1802",
    "label": "Bandar Mataram",
    "value": "Bandar Mataram"
  },
  {
    "id": "180219",
    "regency_id": "1802",
    "label": "Pubian",
    "value": "Pubian"
  },
  {
    "id": "180220",
    "regency_id": "1802",
    "label": "Selagai Lingga",
    "value": "Selagai Lingga"
  },
  {
    "id": "180221",
    "regency_id": "1802",
    "label": "Anak Tuha",
    "value": "Anak Tuha"
  },
  {
    "id": "180222",
    "regency_id": "1802",
    "label": "Sendang Agung",
    "value": "Sendang Agung"
  },
  {
    "id": "180223",
    "regency_id": "1802",
    "label": "Kota Gajah",
    "value": "Kota Gajah"
  },
  {
    "id": "180224",
    "regency_id": "1802",
    "label": "Bumi Nabung",
    "value": "Bumi Nabung"
  },
  {
    "id": "180225",
    "regency_id": "1802",
    "label": "Way Seputih",
    "value": "Way Seputih"
  },
  {
    "id": "180226",
    "regency_id": "1802",
    "label": "Bandar Surabaya",
    "value": "Bandar Surabaya"
  },
  {
    "id": "180227",
    "regency_id": "1802",
    "label": "Anak Ratu Aji",
    "value": "Anak Ratu Aji"
  },
  {
    "id": "180228",
    "regency_id": "1802",
    "label": "Putra Rumbia",
    "value": "Putra Rumbia"
  },
  {
    "id": "180301",
    "regency_id": "1803",
    "label": "Bukit Kemuning",
    "value": "Bukit Kemuning"
  },
  {
    "id": "180302",
    "regency_id": "1803",
    "label": "Kotabumi",
    "value": "Kotabumi"
  },
  {
    "id": "180303",
    "regency_id": "1803",
    "label": "Sungkai Selatan",
    "value": "Sungkai Selatan"
  },
  {
    "id": "180304",
    "regency_id": "1803",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "180305",
    "regency_id": "1803",
    "label": "Abung Timur",
    "value": "Abung Timur"
  },
  {
    "id": "180306",
    "regency_id": "1803",
    "label": "Abung Barat",
    "value": "Abung Barat"
  },
  {
    "id": "180307",
    "regency_id": "1803",
    "label": "Abung Selatan",
    "value": "Abung Selatan"
  },
  {
    "id": "180308",
    "regency_id": "1803",
    "label": "Sungkai Utara",
    "value": "Sungkai Utara"
  },
  {
    "id": "180309",
    "regency_id": "1803",
    "label": "Kotabumi Utara",
    "value": "Kotabumi Utara"
  },
  {
    "id": "180310",
    "regency_id": "1803",
    "label": "Kotabumi Selatan",
    "value": "Kotabumi Selatan"
  },
  {
    "id": "180311",
    "regency_id": "1803",
    "label": "Abung Tengah",
    "value": "Abung Tengah"
  },
  {
    "id": "180312",
    "regency_id": "1803",
    "label": "Abung Tinggi",
    "value": "Abung Tinggi"
  },
  {
    "id": "180313",
    "regency_id": "1803",
    "label": "Abung Semuli",
    "value": "Abung Semuli"
  },
  {
    "id": "180314",
    "regency_id": "1803",
    "label": "Abung Surakarta",
    "value": "Abung Surakarta"
  },
  {
    "id": "180315",
    "regency_id": "1803",
    "label": "Muara Sungkai",
    "value": "Muara Sungkai"
  },
  {
    "id": "180316",
    "regency_id": "1803",
    "label": "Bunga Mayang",
    "value": "Bunga Mayang"
  },
  {
    "id": "180317",
    "regency_id": "1803",
    "label": "Hulu Sungkai",
    "value": "Hulu Sungkai"
  },
  {
    "id": "180318",
    "regency_id": "1803",
    "label": "Sungkai Tengah",
    "value": "Sungkai Tengah"
  },
  {
    "id": "180319",
    "regency_id": "1803",
    "label": "Abung Pekurun",
    "value": "Abung Pekurun"
  },
  {
    "id": "180320",
    "regency_id": "1803",
    "label": "Sungkai Jaya",
    "value": "Sungkai Jaya"
  },
  {
    "id": "180321",
    "regency_id": "1803",
    "label": "Sungkai Barat",
    "value": "Sungkai Barat"
  },
  {
    "id": "180322",
    "regency_id": "1803",
    "label": "Abung Kunang",
    "value": "Abung Kunang"
  },
  {
    "id": "180323",
    "regency_id": "1803",
    "label": "Blambangan Pagar",
    "value": "Blambangan Pagar"
  },
  {
    "id": "180404",
    "regency_id": "1804",
    "label": "Balik Bukit",
    "value": "Balik Bukit"
  },
  {
    "id": "180405",
    "regency_id": "1804",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "180406",
    "regency_id": "1804",
    "label": "Belalau",
    "value": "Belalau"
  },
  {
    "id": "180407",
    "regency_id": "1804",
    "label": "Way Tenong",
    "value": "Way Tenong"
  },
  {
    "id": "180408",
    "regency_id": "1804",
    "label": "Sekincau",
    "value": "Sekincau"
  },
  { "id": "180409", "regency_id": "1804", "label": "Suoh", "value": "Suoh" },
  {
    "id": "180410",
    "regency_id": "1804",
    "label": "Batu Brak",
    "value": "Batu Brak"
  },
  { "id": "180411", "regency_id": "1804", "label": "Sukau", "value": "Sukau" },
  {
    "id": "180415",
    "regency_id": "1804",
    "label": "Gedung Surian",
    "value": "Gedung Surian"
  },
  {
    "id": "180418",
    "regency_id": "1804",
    "label": "Kebun Tebu",
    "value": "Kebun Tebu"
  },
  {
    "id": "180419",
    "regency_id": "1804",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "180420",
    "regency_id": "1804",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "180421",
    "regency_id": "1804",
    "label": "Batu Ketulis",
    "value": "Batu Ketulis"
  },
  {
    "id": "180422",
    "regency_id": "1804",
    "label": "Lumbok Seminung",
    "value": "Lumbok Seminung"
  },
  {
    "id": "180423",
    "regency_id": "1804",
    "label": "Bandar Negeri Suoh",
    "value": "Bandar Negeri Suoh"
  },
  {
    "id": "180502",
    "regency_id": "1805",
    "label": "Menggala",
    "value": "Menggala"
  },
  {
    "id": "180506",
    "regency_id": "1805",
    "label": "Gedung Aji",
    "value": "Gedung Aji"
  },
  {
    "id": "180508",
    "regency_id": "1805",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "180511",
    "regency_id": "1805",
    "label": "Gedung Meneng",
    "value": "Gedung Meneng"
  },
  {
    "id": "180512",
    "regency_id": "1805",
    "label": "Rawa Jitu Selatan",
    "value": "Rawa Jitu Selatan"
  },
  {
    "id": "180513",
    "regency_id": "1805",
    "label": "Penawar Tama",
    "value": "Penawar Tama"
  },
  {
    "id": "180518",
    "regency_id": "1805",
    "label": "Rawa Jitu Timur",
    "value": "Rawa Jitu Timur"
  },
  {
    "id": "180520",
    "regency_id": "1805",
    "label": "Banjar Margo",
    "value": "Banjar Margo"
  },
  {
    "id": "180522",
    "regency_id": "1805",
    "label": "Rawa Pitu",
    "value": "Rawa Pitu"
  },
  {
    "id": "180523",
    "regency_id": "1805",
    "label": "Penawar Aji",
    "value": "Penawar Aji"
  },
  {
    "id": "180525",
    "regency_id": "1805",
    "label": "Dente Teladas",
    "value": "Dente Teladas"
  },
  {
    "id": "180526",
    "regency_id": "1805",
    "label": "Meraksa Aji",
    "value": "Meraksa Aji"
  },
  {
    "id": "180527",
    "regency_id": "1805",
    "label": "Gedung Aji Baru",
    "value": "Gedung Aji Baru"
  },
  {
    "id": "180529",
    "regency_id": "1805",
    "label": "Banjar Baru",
    "value": "Banjar Baru"
  },
  {
    "id": "180530",
    "regency_id": "1805",
    "label": "Menggala Timur",
    "value": "Menggala Timur"
  },
  {
    "id": "180601",
    "regency_id": "1806",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "180602",
    "regency_id": "1806",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "180603",
    "regency_id": "1806",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "180604",
    "regency_id": "1806",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "180609",
    "regency_id": "1806",
    "label": "Cukuh Balak",
    "value": "Cukuh Balak"
  },
  {
    "id": "180611",
    "regency_id": "1806",
    "label": "Pugung",
    "value": "Pugung"
  },
  {
    "id": "180612",
    "regency_id": "1806",
    "label": "Semaka",
    "value": "Semaka"
  },
  {
    "id": "180613",
    "regency_id": "1806",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "180615",
    "regency_id": "1806",
    "label": "Ulu Belu",
    "value": "Ulu Belu"
  },
  {
    "id": "180616",
    "regency_id": "1806",
    "label": "Pematang Sawa",
    "value": "Pematang Sawa"
  },
  {
    "id": "180617",
    "regency_id": "1806",
    "label": "Klumbayan",
    "value": "Klumbayan"
  },
  {
    "id": "180618",
    "regency_id": "1806",
    "label": "Kota Agung Barat",
    "value": "Kota Agung Barat"
  },
  {
    "id": "180619",
    "regency_id": "1806",
    "label": "Kota Agung Timur",
    "value": "Kota Agung Timur"
  },
  {
    "id": "180620",
    "regency_id": "1806",
    "label": "Gisting",
    "value": "Gisting"
  },
  {
    "id": "180621",
    "regency_id": "1806",
    "label": "Gunung Alip",
    "value": "Gunung Alip"
  },
  { "id": "180624", "regency_id": "1806", "label": "Limau", "value": "Limau" },
  {
    "id": "180625",
    "regency_id": "1806",
    "label": "Bandar Negeri Semuong",
    "value": "Bandar Negeri Semuong"
  },
  {
    "id": "180626",
    "regency_id": "1806",
    "label": "Air Naningan",
    "value": "Air Naningan"
  },
  { "id": "180627", "regency_id": "1806", "label": "Bulok", "value": "Bulok" },
  {
    "id": "180628",
    "regency_id": "1806",
    "label": "Klumbayan Barat",
    "value": "Klumbayan Barat"
  },
  {
    "id": "180701",
    "regency_id": "1807",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "180702",
    "regency_id": "1807",
    "label": "Labuhan Maringgai",
    "value": "Labuhan Maringgai"
  },
  {
    "id": "180703",
    "regency_id": "1807",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "180704",
    "regency_id": "1807",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "180705",
    "regency_id": "1807",
    "label": "Sekampung",
    "value": "Sekampung"
  },
  {
    "id": "180706",
    "regency_id": "1807",
    "label": "Batanghari",
    "value": "Batanghari"
  },
  {
    "id": "180707",
    "regency_id": "1807",
    "label": "Way Jepara",
    "value": "Way Jepara"
  },
  {
    "id": "180708",
    "regency_id": "1807",
    "label": "Purbolinggo",
    "value": "Purbolinggo"
  },
  {
    "id": "180709",
    "regency_id": "1807",
    "label": "Raman Utara",
    "value": "Raman Utara"
  },
  {
    "id": "180710",
    "regency_id": "1807",
    "label": "Metro Kibang",
    "value": "Metro Kibang"
  },
  {
    "id": "180711",
    "regency_id": "1807",
    "label": "Marga Tiga",
    "value": "Marga Tiga"
  },
  {
    "id": "180712",
    "regency_id": "1807",
    "label": "Sekampung Udik",
    "value": "Sekampung Udik"
  },
  {
    "id": "180713",
    "regency_id": "1807",
    "label": "Batanghari Nuban",
    "value": "Batanghari Nuban"
  },
  {
    "id": "180714",
    "regency_id": "1807",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "180715",
    "regency_id": "1807",
    "label": "Bandar Sribhawono",
    "value": "Bandar Sribhawono"
  },
  {
    "id": "180716",
    "regency_id": "1807",
    "label": "Mataram Baru",
    "value": "Mataram Baru"
  },
  {
    "id": "180717",
    "regency_id": "1807",
    "label": "Melinting",
    "value": "Melinting"
  },
  {
    "id": "180718",
    "regency_id": "1807",
    "label": "Gunung Pelindung",
    "value": "Gunung Pelindung"
  },
  {
    "id": "180719",
    "regency_id": "1807",
    "label": "Pasir Sakti",
    "value": "Pasir Sakti"
  },
  {
    "id": "180720",
    "regency_id": "1807",
    "label": "Waway Karya",
    "value": "Waway Karya"
  },
  {
    "id": "180721",
    "regency_id": "1807",
    "label": "Labuhan Ratu",
    "value": "Labuhan Ratu"
  },
  {
    "id": "180722",
    "regency_id": "1807",
    "label": "Braja Selebah",
    "value": "Braja Selebah"
  },
  {
    "id": "180723",
    "regency_id": "1807",
    "label": "Way Bungur",
    "value": "Way Bungur"
  },
  {
    "id": "180724",
    "regency_id": "1807",
    "label": "Marga Sekampung",
    "value": "Marga Sekampung"
  },
  {
    "id": "180801",
    "regency_id": "1808",
    "label": "Blambangan Umpu",
    "value": "Blambangan Umpu"
  },
  { "id": "180802", "regency_id": "1808", "label": "Kasui", "value": "Kasui" },
  {
    "id": "180803",
    "regency_id": "1808",
    "label": "Banjit",
    "value": "Banjit"
  },
  {
    "id": "180804",
    "regency_id": "1808",
    "label": "Baradatu",
    "value": "Baradatu"
  },
  {
    "id": "180805",
    "regency_id": "1808",
    "label": "Bahuga",
    "value": "Bahuga"
  },
  {
    "id": "180806",
    "regency_id": "1808",
    "label": "Pakuan Ratu",
    "value": "Pakuan Ratu"
  },
  {
    "id": "180807",
    "regency_id": "1808",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "180808",
    "regency_id": "1808",
    "label": "Way Tuba",
    "value": "Way Tuba"
  },
  {
    "id": "180809",
    "regency_id": "1808",
    "label": "Rebang Tangkas",
    "value": "Rebang Tangkas"
  },
  {
    "id": "180810",
    "regency_id": "1808",
    "label": "Gunung Labuhan",
    "value": "Gunung Labuhan"
  },
  {
    "id": "180811",
    "regency_id": "1808",
    "label": "Negara Batin",
    "value": "Negara Batin"
  },
  {
    "id": "180812",
    "regency_id": "1808",
    "label": "Negeri Besar",
    "value": "Negeri Besar"
  },
  {
    "id": "180813",
    "regency_id": "1808",
    "label": "Buay Bahuga",
    "value": "Buay Bahuga"
  },
  {
    "id": "180814",
    "regency_id": "1808",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "180901",
    "regency_id": "1809",
    "label": "Gedong Tataan",
    "value": "Gedong Tataan"
  },
  {
    "id": "180902",
    "regency_id": "1809",
    "label": "Negeri Katon",
    "value": "Negeri Katon"
  },
  {
    "id": "180903",
    "regency_id": "1809",
    "label": "Tegineneng",
    "value": "Tegineneng"
  },
  {
    "id": "180904",
    "regency_id": "1809",
    "label": "Way Lima",
    "value": "Way Lima"
  },
  {
    "id": "180905",
    "regency_id": "1809",
    "label": "Padang Cermin",
    "value": "Padang Cermin"
  },
  {
    "id": "180906",
    "regency_id": "1809",
    "label": "Punduh Pidada",
    "value": "Punduh Pidada"
  },
  {
    "id": "180907",
    "regency_id": "1809",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "180908",
    "regency_id": "1809",
    "label": "Marga Punduh",
    "value": "Marga Punduh"
  },
  {
    "id": "180909",
    "regency_id": "1809",
    "label": "Way Khilau",
    "value": "Way Khilau"
  },
  {
    "id": "180910",
    "regency_id": "1809",
    "label": "Teluk Pandan",
    "value": "Teluk Pandan"
  },
  {
    "id": "180911",
    "regency_id": "1809",
    "label": "Way Ratai",
    "value": "Way Ratai"
  },
  {
    "id": "181001",
    "regency_id": "1810",
    "label": "Pringsewu",
    "value": "Pringsewu"
  },
  {
    "id": "181002",
    "regency_id": "1810",
    "label": "Gading Rejo",
    "value": "Gading Rejo"
  },
  {
    "id": "181003",
    "regency_id": "1810",
    "label": "Ambarawa",
    "value": "Ambarawa"
  },
  {
    "id": "181004",
    "regency_id": "1810",
    "label": "Pardasuka",
    "value": "Pardasuka"
  },
  {
    "id": "181005",
    "regency_id": "1810",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "181006",
    "regency_id": "1810",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "181007",
    "regency_id": "1810",
    "label": "Adiluwih",
    "value": "Adiluwih"
  },
  {
    "id": "181008",
    "regency_id": "1810",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "181009",
    "regency_id": "1810",
    "label": "Pagelaran Utara",
    "value": "Pagelaran Utara"
  },
  {
    "id": "181101",
    "regency_id": "1811",
    "label": "Mesuji",
    "value": "Mesuji"
  },
  {
    "id": "181102",
    "regency_id": "1811",
    "label": "Mesuji Timur",
    "value": "Mesuji Timur"
  },
  {
    "id": "181103",
    "regency_id": "1811",
    "label": "Rawa Jitu Utara",
    "value": "Rawa Jitu Utara"
  },
  {
    "id": "181104",
    "regency_id": "1811",
    "label": "Way Serdang",
    "value": "Way Serdang"
  },
  {
    "id": "181105",
    "regency_id": "1811",
    "label": "Simpang Pematang",
    "value": "Simpang Pematang"
  },
  {
    "id": "181106",
    "regency_id": "1811",
    "label": "Panca Jaya",
    "value": "Panca Jaya"
  },
  {
    "id": "181107",
    "regency_id": "1811",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "181201",
    "regency_id": "1812",
    "label": "Tulang Bawang Tengah",
    "value": "Tulang Bawang Tengah"
  },
  {
    "id": "181202",
    "regency_id": "1812",
    "label": "Tumijajar",
    "value": "Tumijajar"
  },
  {
    "id": "181203",
    "regency_id": "1812",
    "label": "Tulang Bawang Udik",
    "value": "Tulang Bawang Udik"
  },
  {
    "id": "181204",
    "regency_id": "1812",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "181205",
    "regency_id": "1812",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "181206",
    "regency_id": "1812",
    "label": "Way Kenanga",
    "value": "Way Kenanga"
  },
  {
    "id": "181207",
    "regency_id": "1812",
    "label": "Lambu Kibang",
    "value": "Lambu Kibang"
  },
  {
    "id": "181208",
    "regency_id": "1812",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "181209",
    "regency_id": "1812",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "181301",
    "regency_id": "1813",
    "label": "Pesisir Tengah",
    "value": "Pesisir Tengah"
  },
  {
    "id": "181302",
    "regency_id": "1813",
    "label": "Pesisir Selatan",
    "value": "Pesisir Selatan"
  },
  {
    "id": "181303",
    "regency_id": "1813",
    "label": "Lemong",
    "value": "Lemong"
  },
  {
    "id": "181304",
    "regency_id": "1813",
    "label": "Pesisir Utara",
    "value": "Pesisir Utara"
  },
  {
    "id": "181305",
    "regency_id": "1813",
    "label": "Karya Penggawa",
    "value": "Karya Penggawa"
  },
  {
    "id": "181306",
    "regency_id": "1813",
    "label": "Pulaupisang",
    "value": "Pulaupisang"
  },
  {
    "id": "181307",
    "regency_id": "1813",
    "label": "Way Krui",
    "value": "Way Krui"
  },
  {
    "id": "181308",
    "regency_id": "1813",
    "label": "Krui Selatan",
    "value": "Krui Selatan"
  },
  {
    "id": "181309",
    "regency_id": "1813",
    "label": "Ngambur",
    "value": "Ngambur"
  },
  {
    "id": "181310",
    "regency_id": "1813",
    "label": "Ngaras",
    "value": "Ngaras"
  },
  {
    "id": "181311",
    "regency_id": "1813",
    "label": "Bangkunat",
    "value": "Bangkunat"
  },
  {
    "id": "187101",
    "regency_id": "1871",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "187102",
    "regency_id": "1871",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "187103",
    "regency_id": "1871",
    "label": "Tanjungkarang Barat",
    "value": "Tanjungkarang Barat"
  },
  {
    "id": "187104",
    "regency_id": "1871",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "187105",
    "regency_id": "1871",
    "label": "Tanjungkarang Timur",
    "value": "Tanjungkarang Timur"
  },
  {
    "id": "187106",
    "regency_id": "1871",
    "label": "Tanjungkarang Pusat",
    "value": "Tanjungkarang Pusat"
  },
  {
    "id": "187107",
    "regency_id": "1871",
    "label": "Telukbetung Selatan",
    "value": "Telukbetung Selatan"
  },
  {
    "id": "187108",
    "regency_id": "1871",
    "label": "Telukbetung Barat",
    "value": "Telukbetung Barat"
  },
  {
    "id": "187109",
    "regency_id": "1871",
    "label": "Telukbetung Utara",
    "value": "Telukbetung Utara"
  },
  {
    "id": "187110",
    "regency_id": "1871",
    "label": "Rajabasa",
    "value": "Rajabasa"
  },
  {
    "id": "187111",
    "regency_id": "1871",
    "label": "Tanjung Senang",
    "value": "Tanjung Senang"
  },
  {
    "id": "187112",
    "regency_id": "1871",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "187113",
    "regency_id": "1871",
    "label": "Kemiling",
    "value": "Kemiling"
  },
  {
    "id": "187114",
    "regency_id": "1871",
    "label": "Labuhan Ratu",
    "value": "Labuhan Ratu"
  },
  {
    "id": "187115",
    "regency_id": "1871",
    "label": "Way Halim",
    "value": "Way Halim"
  },
  {
    "id": "187116",
    "regency_id": "1871",
    "label": "Langkapura",
    "value": "Langkapura"
  },
  {
    "id": "187117",
    "regency_id": "1871",
    "label": "Enggal",
    "value": "Enggal"
  },
  {
    "id": "187118",
    "regency_id": "1871",
    "label": "Kedamaian",
    "value": "Kedamaian"
  },
  {
    "id": "187119",
    "regency_id": "1871",
    "label": "Telukbetung Timur",
    "value": "Telukbetung Timur"
  },
  {
    "id": "187120",
    "regency_id": "1871",
    "label": "Bumi Waras",
    "value": "Bumi Waras"
  },
  {
    "id": "187201",
    "regency_id": "1872",
    "label": "Metro Pusat",
    "value": "Metro Pusat"
  },
  {
    "id": "187202",
    "regency_id": "1872",
    "label": "Metro Utara",
    "value": "Metro Utara"
  },
  {
    "id": "187203",
    "regency_id": "1872",
    "label": "Metro Barat",
    "value": "Metro Barat"
  },
  {
    "id": "187204",
    "regency_id": "1872",
    "label": "Metro Timur",
    "value": "Metro Timur"
  },
  {
    "id": "187205",
    "regency_id": "1872",
    "label": "Metro Selatan",
    "value": "Metro Selatan"
  },
  {
    "id": "190101",
    "regency_id": "1901",
    "label": "Sungailiat",
    "value": "Sungailiat"
  },
  {
    "id": "190102",
    "regency_id": "1901",
    "label": "Belinyu",
    "value": "Belinyu"
  },
  {
    "id": "190103",
    "regency_id": "1901",
    "label": "Merawang",
    "value": "Merawang"
  },
  {
    "id": "190104",
    "regency_id": "1901",
    "label": "Mendo Barat",
    "value": "Mendo Barat"
  },
  {
    "id": "190105",
    "regency_id": "1901",
    "label": "Pemali",
    "value": "Pemali"
  },
  { "id": "190106", "regency_id": "1901", "label": "Bakam", "value": "Bakam" },
  {
    "id": "190107",
    "regency_id": "1901",
    "label": "Riau Silip",
    "value": "Riau Silip"
  },
  {
    "id": "190108",
    "regency_id": "1901",
    "label": "Puding Besar",
    "value": "Puding Besar"
  },
  {
    "id": "190201",
    "regency_id": "1902",
    "label": "Tanjung Pandan",
    "value": "Tanjung Pandan"
  },
  {
    "id": "190202",
    "regency_id": "1902",
    "label": "Membalong",
    "value": "Membalong"
  },
  {
    "id": "190203",
    "regency_id": "1902",
    "label": "Selat Nasik",
    "value": "Selat Nasik"
  },
  { "id": "190204", "regency_id": "1902", "label": "Sijuk", "value": "Sijuk" },
  { "id": "190205", "regency_id": "1902", "label": "Badau", "value": "Badau" },
  {
    "id": "190301",
    "regency_id": "1903",
    "label": "Toboali",
    "value": "Toboali"
  },
  {
    "id": "190302",
    "regency_id": "1903",
    "label": "Lepar Pongok",
    "value": "Lepar Pongok"
  },
  {
    "id": "190303",
    "regency_id": "1903",
    "label": "Air Gegas",
    "value": "Air Gegas"
  },
  {
    "id": "190304",
    "regency_id": "1903",
    "label": "Simpang Rimba",
    "value": "Simpang Rimba"
  },
  {
    "id": "190305",
    "regency_id": "1903",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "190306",
    "regency_id": "1903",
    "label": "Tukak Sadai",
    "value": "Tukak Sadai"
  },
  {
    "id": "190307",
    "regency_id": "1903",
    "label": "Pulaubesar",
    "value": "Pulaubesar"
  },
  {
    "id": "190308",
    "regency_id": "1903",
    "label": "Kepulauan Pongok",
    "value": "Kepulauan Pongok"
  },
  { "id": "190401", "regency_id": "1904", "label": "Koba", "value": "Koba" },
  {
    "id": "190402",
    "regency_id": "1904",
    "label": "Pangkalan Baru",
    "value": "Pangkalan Baru"
  },
  {
    "id": "190403",
    "regency_id": "1904",
    "label": "Sungai Selan",
    "value": "Sungai Selan"
  },
  {
    "id": "190404",
    "regency_id": "1904",
    "label": "Simpang Katis",
    "value": "Simpang Katis"
  },
  {
    "id": "190405",
    "regency_id": "1904",
    "label": "Namang",
    "value": "Namang"
  },
  {
    "id": "190406",
    "regency_id": "1904",
    "label": "Lubuk Besar",
    "value": "Lubuk Besar"
  },
  {
    "id": "190501",
    "regency_id": "1905",
    "label": "Muntok",
    "value": "Muntok"
  },
  {
    "id": "190502",
    "regency_id": "1905",
    "label": "Simpang Teritip",
    "value": "Simpang Teritip"
  },
  { "id": "190503", "regency_id": "1905", "label": "Jebus", "value": "Jebus" },
  {
    "id": "190504",
    "regency_id": "1905",
    "label": "Kelapa",
    "value": "Kelapa"
  },
  {
    "id": "190505",
    "regency_id": "1905",
    "label": "Tempilang",
    "value": "Tempilang"
  },
  {
    "id": "190506",
    "regency_id": "1905",
    "label": "Parittiga",
    "value": "Parittiga"
  },
  {
    "id": "190601",
    "regency_id": "1906",
    "label": "Manggar",
    "value": "Manggar"
  },
  {
    "id": "190602",
    "regency_id": "1906",
    "label": "Gantung",
    "value": "Gantung"
  },
  {
    "id": "190603",
    "regency_id": "1906",
    "label": "Dendang",
    "value": "Dendang"
  },
  {
    "id": "190604",
    "regency_id": "1906",
    "label": "Kelapa Kampit",
    "value": "Kelapa Kampit"
  },
  { "id": "190605", "regency_id": "1906", "label": "Damar", "value": "Damar" },
  {
    "id": "190606",
    "regency_id": "1906",
    "label": "Simpang Renggiang",
    "value": "Simpang Renggiang"
  },
  {
    "id": "190607",
    "regency_id": "1906",
    "label": "Simpang Pesak",
    "value": "Simpang Pesak"
  },
  {
    "id": "197101",
    "regency_id": "1971",
    "label": "Bukitintan",
    "value": "Bukitintan"
  },
  {
    "id": "197102",
    "regency_id": "1971",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "197103",
    "regency_id": "1971",
    "label": "Pangkal Balam",
    "value": "Pangkal Balam"
  },
  {
    "id": "197104",
    "regency_id": "1971",
    "label": "Rangkui",
    "value": "Rangkui"
  },
  {
    "id": "197105",
    "regency_id": "1971",
    "label": "Gerunggang",
    "value": "Gerunggang"
  },
  { "id": "197106", "regency_id": "1971", "label": "Gabek", "value": "Gabek" },
  {
    "id": "197107",
    "regency_id": "1971",
    "label": "Girimaya",
    "value": "Girimaya"
  },
  {
    "id": "210104",
    "regency_id": "2101",
    "label": "Gunung Kijang",
    "value": "Gunung Kijang"
  },
  {
    "id": "210106",
    "regency_id": "2101",
    "label": "Bintan Timur",
    "value": "Bintan Timur"
  },
  {
    "id": "210107",
    "regency_id": "2101",
    "label": "Bintan Utara",
    "value": "Bintan Utara"
  },
  {
    "id": "210108",
    "regency_id": "2101",
    "label": "Teluk Bintan",
    "value": "Teluk Bintan"
  },
  {
    "id": "210109",
    "regency_id": "2101",
    "label": "Tambelan",
    "value": "Tambelan"
  },
  {
    "id": "210110",
    "regency_id": "2101",
    "label": "Telok Sebong",
    "value": "Telok Sebong"
  },
  {
    "id": "210112",
    "regency_id": "2101",
    "label": "Toapaya",
    "value": "Toapaya"
  },
  {
    "id": "210113",
    "regency_id": "2101",
    "label": "Mantang",
    "value": "Mantang"
  },
  {
    "id": "210114",
    "regency_id": "2101",
    "label": "Bintan Pesisir",
    "value": "Bintan Pesisir"
  },
  {
    "id": "210115",
    "regency_id": "2101",
    "label": "Seri Kuala Lobam",
    "value": "Seri Kuala Lobam"
  },
  { "id": "210201", "regency_id": "2102", "label": "Moro", "value": "Moro" },
  {
    "id": "210202",
    "regency_id": "2102",
    "label": "Kundur",
    "value": "Kundur"
  },
  {
    "id": "210203",
    "regency_id": "2102",
    "label": "Karimun",
    "value": "Karimun"
  },
  { "id": "210204", "regency_id": "2102", "label": "Meral", "value": "Meral" },
  {
    "id": "210205",
    "regency_id": "2102",
    "label": "Tebing",
    "value": "Tebing"
  },
  { "id": "210206", "regency_id": "2102", "label": "Buru", "value": "Buru" },
  {
    "id": "210207",
    "regency_id": "2102",
    "label": "Kundur Utara",
    "value": "Kundur Utara"
  },
  {
    "id": "210208",
    "regency_id": "2102",
    "label": "Kundur Barat",
    "value": "Kundur Barat"
  },
  { "id": "210209", "regency_id": "2102", "label": "Durai", "value": "Durai" },
  {
    "id": "210210",
    "regency_id": "2102",
    "label": "Meral Barat",
    "value": "Meral Barat"
  },
  { "id": "210211", "regency_id": "2102", "label": "Ungar", "value": "Ungar" },
  { "id": "210212", "regency_id": "2102", "label": "Belat", "value": "Belat" },
  { "id": "210304", "regency_id": "2103", "label": "Midai", "value": "Midai" },
  {
    "id": "210305",
    "regency_id": "2103",
    "label": "Bunguran Barat",
    "value": "Bunguran Barat"
  },
  {
    "id": "210306",
    "regency_id": "2103",
    "label": "Serasan",
    "value": "Serasan"
  },
  {
    "id": "210307",
    "regency_id": "2103",
    "label": "Bunguran Timur",
    "value": "Bunguran Timur"
  },
  {
    "id": "210308",
    "regency_id": "2103",
    "label": "Bunguran Utara",
    "value": "Bunguran Utara"
  },
  { "id": "210309", "regency_id": "2103", "label": "Subi", "value": "Subi" },
  {
    "id": "210310",
    "regency_id": "2103",
    "label": "Pulau Laut",
    "value": "Pulau Laut"
  },
  {
    "id": "210311",
    "regency_id": "2103",
    "label": "Pulau Tiga",
    "value": "Pulau Tiga"
  },
  {
    "id": "210315",
    "regency_id": "2103",
    "label": "Bunguran Timur Laut",
    "value": "Bunguran Timur Laut"
  },
  {
    "id": "210316",
    "regency_id": "2103",
    "label": "Bunguran Tengah",
    "value": "Bunguran Tengah"
  },
  {
    "id": "210318",
    "regency_id": "2103",
    "label": "Bunguran Selatan",
    "value": "Bunguran Selatan"
  },
  {
    "id": "210319",
    "regency_id": "2103",
    "label": "Serasan Timur",
    "value": "Serasan Timur"
  },
  {
    "id": "210320",
    "regency_id": "2103",
    "label": "Bunguran Batubi",
    "value": "Bunguran Batubi"
  },
  {
    "id": "210321",
    "regency_id": "2103",
    "label": "Pulau Tiga Barat",
    "value": "Pulau Tiga Barat"
  },
  {
    "id": "210322",
    "regency_id": "2103",
    "label": "Suak Midai",
    "value": "Suak Midai"
  },
  {
    "id": "210401",
    "regency_id": "2104",
    "label": "Singkep",
    "value": "Singkep"
  },
  {
    "id": "210402",
    "regency_id": "2104",
    "label": "Lingga",
    "value": "Lingga"
  },
  {
    "id": "210403",
    "regency_id": "2104",
    "label": "Senayang",
    "value": "Senayang"
  },
  {
    "id": "210404",
    "regency_id": "2104",
    "label": "Singkep Barat",
    "value": "Singkep Barat"
  },
  {
    "id": "210405",
    "regency_id": "2104",
    "label": "Lingga Utara",
    "value": "Lingga Utara"
  },
  {
    "id": "210406",
    "regency_id": "2104",
    "label": "Singkep Pesisir",
    "value": "Singkep Pesisir"
  },
  {
    "id": "210407",
    "regency_id": "2104",
    "label": "Lingga Timur",
    "value": "Lingga Timur"
  },
  {
    "id": "210408",
    "regency_id": "2104",
    "label": "Selayar",
    "value": "Selayar"
  },
  {
    "id": "210409",
    "regency_id": "2104",
    "label": "Singkep Selatan",
    "value": "Singkep Selatan"
  },
  {
    "id": "210410",
    "regency_id": "2104",
    "label": "Kepulauan Posek",
    "value": "Kepulauan Posek"
  },
  {
    "id": "210411",
    "regency_id": "2104",
    "label": "Katang Bidare",
    "value": "Katang Bidare"
  },
  {
    "id": "210412",
    "regency_id": "2104",
    "label": "Temiang Pesisir",
    "value": "Temiang Pesisir"
  },
  {
    "id": "210413",
    "regency_id": "2104",
    "label": "Bakung Serumpun",
    "value": "Bakung Serumpun"
  },
  {
    "id": "210501",
    "regency_id": "2105",
    "label": "Siantan",
    "value": "Siantan"
  },
  {
    "id": "210502",
    "regency_id": "2105",
    "label": "Palmatak",
    "value": "Palmatak"
  },
  {
    "id": "210503",
    "regency_id": "2105",
    "label": "Siantan Timur",
    "value": "Siantan Timur"
  },
  {
    "id": "210504",
    "regency_id": "2105",
    "label": "Siantan Selatan",
    "value": "Siantan Selatan"
  },
  {
    "id": "210505",
    "regency_id": "2105",
    "label": "Jemaja Timur",
    "value": "Jemaja Timur"
  },
  {
    "id": "210506",
    "regency_id": "2105",
    "label": "Jemaja",
    "value": "Jemaja"
  },
  {
    "id": "210507",
    "regency_id": "2105",
    "label": "Siantan Tengah",
    "value": "Siantan Tengah"
  },
  {
    "id": "210508",
    "regency_id": "2105",
    "label": "Siantan Utara",
    "value": "Siantan Utara"
  },
  {
    "id": "210509",
    "regency_id": "2105",
    "label": "Jemaja Barat",
    "value": "Jemaja Barat"
  },
  {
    "id": "217101",
    "regency_id": "2171",
    "label": "Belakang Padang",
    "value": "Belakang Padang"
  },
  {
    "id": "217102",
    "regency_id": "2171",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "217103",
    "regency_id": "2171",
    "label": "Sekupang",
    "value": "Sekupang"
  },
  {
    "id": "217104",
    "regency_id": "2171",
    "label": "Nongsa",
    "value": "Nongsa"
  },
  {
    "id": "217105",
    "regency_id": "2171",
    "label": "Bulang",
    "value": "Bulang"
  },
  {
    "id": "217106",
    "regency_id": "2171",
    "label": "Lubuk Baja",
    "value": "Lubuk Baja"
  },
  {
    "id": "217107",
    "regency_id": "2171",
    "label": "Sei Beduk",
    "value": "Sei Beduk"
  },
  {
    "id": "217108",
    "regency_id": "2171",
    "label": "Galang",
    "value": "Galang"
  },
  {
    "id": "217109",
    "regency_id": "2171",
    "label": "Bengkong",
    "value": "Bengkong"
  },
  {
    "id": "217110",
    "regency_id": "2171",
    "label": "Batam Kota",
    "value": "Batam Kota"
  },
  {
    "id": "217111",
    "regency_id": "2171",
    "label": "Sagulung",
    "value": "Sagulung"
  },
  {
    "id": "217112",
    "regency_id": "2171",
    "label": "Batu Aji",
    "value": "Batu Aji"
  },
  {
    "id": "217201",
    "regency_id": "2172",
    "label": "Tanjung Pinang Barat",
    "value": "Tanjung Pinang Barat"
  },
  {
    "id": "217202",
    "regency_id": "2172",
    "label": "Tanjung Pinang Timur",
    "value": "Tanjung Pinang Timur"
  },
  {
    "id": "217203",
    "regency_id": "2172",
    "label": "Tanjung Pinang Kota",
    "value": "Tanjung Pinang Kota"
  },
  {
    "id": "217204",
    "regency_id": "2172",
    "label": "Bukit Bestari",
    "value": "Bukit Bestari"
  },
  {
    "id": "310101",
    "regency_id": "3101",
    "label": "Kepulauan Seribu Utara",
    "value": "Kepulauan Seribu Utara"
  },
  {
    "id": "310102",
    "regency_id": "3101",
    "label": "Kepulauan Seribu Selatan.",
    "value": "Kepulauan Seribu Selatan."
  },
  {
    "id": "317101",
    "regency_id": "3171",
    "label": "Gambir",
    "value": "Gambir"
  },
  {
    "id": "317102",
    "regency_id": "3171",
    "label": "Sawah Besar",
    "value": "Sawah Besar"
  },
  {
    "id": "317103",
    "regency_id": "3171",
    "label": "Kemayoran",
    "value": "Kemayoran"
  },
  { "id": "317104", "regency_id": "3171", "label": "Senen", "value": "Senen" },
  {
    "id": "317105",
    "regency_id": "3171",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "317106",
    "regency_id": "3171",
    "label": "Menteng",
    "value": "Menteng"
  },
  {
    "id": "317107",
    "regency_id": "3171",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "317108",
    "regency_id": "3171",
    "label": "Johar Baru",
    "value": "Johar Baru"
  },
  {
    "id": "317201",
    "regency_id": "3172",
    "label": "Penjaringan",
    "value": "Penjaringan"
  },
  {
    "id": "317202",
    "regency_id": "3172",
    "label": "Tanjung Priok",
    "value": "Tanjung Priok"
  },
  { "id": "317203", "regency_id": "3172", "label": "Koja", "value": "Koja" },
  {
    "id": "317204",
    "regency_id": "3172",
    "label": "Cilincing",
    "value": "Cilincing"
  },
  {
    "id": "317205",
    "regency_id": "3172",
    "label": "Pademangan",
    "value": "Pademangan"
  },
  {
    "id": "317206",
    "regency_id": "3172",
    "label": "Kelapa Gading",
    "value": "Kelapa Gading"
  },
  {
    "id": "317301",
    "regency_id": "3173",
    "label": "Cengkareng",
    "value": "Cengkareng"
  },
  {
    "id": "317302",
    "regency_id": "3173",
    "label": "Grogol Petamburan",
    "value": "Grogol Petamburan"
  },
  {
    "id": "317303",
    "regency_id": "3173",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "317304",
    "regency_id": "3173",
    "label": "Tambora",
    "value": "Tambora"
  },
  {
    "id": "317305",
    "regency_id": "3173",
    "label": "Kebon Jeruk",
    "value": "Kebon Jeruk"
  },
  {
    "id": "317306",
    "regency_id": "3173",
    "label": "Kalideres",
    "value": "Kalideres"
  },
  {
    "id": "317307",
    "regency_id": "3173",
    "label": "Pal Merah",
    "value": "Pal Merah"
  },
  {
    "id": "317308",
    "regency_id": "3173",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  { "id": "317401", "regency_id": "3174", "label": "Tebet", "value": "Tebet" },
  {
    "id": "317402",
    "regency_id": "3174",
    "label": "Setiabudi",
    "value": "Setiabudi"
  },
  {
    "id": "317403",
    "regency_id": "3174",
    "label": "Mampang Prapatan",
    "value": "Mampang Prapatan"
  },
  {
    "id": "317404",
    "regency_id": "3174",
    "label": "Pasar Minggu",
    "value": "Pasar Minggu"
  },
  {
    "id": "317405",
    "regency_id": "3174",
    "label": "Kebayoran Lama",
    "value": "Kebayoran Lama"
  },
  {
    "id": "317406",
    "regency_id": "3174",
    "label": "Cilandak",
    "value": "Cilandak"
  },
  {
    "id": "317407",
    "regency_id": "3174",
    "label": "Kebayoran Baru",
    "value": "Kebayoran Baru"
  },
  {
    "id": "317408",
    "regency_id": "3174",
    "label": "Pancoran",
    "value": "Pancoran"
  },
  {
    "id": "317409",
    "regency_id": "3174",
    "label": "Jagakarsa",
    "value": "Jagakarsa"
  },
  {
    "id": "317410",
    "regency_id": "3174",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "317501",
    "regency_id": "3175",
    "label": "Matraman",
    "value": "Matraman"
  },
  {
    "id": "317502",
    "regency_id": "3175",
    "label": "Pulogadung",
    "value": "Pulogadung"
  },
  {
    "id": "317503",
    "regency_id": "3175",
    "label": "Jatinegara",
    "value": "Jatinegara"
  },
  {
    "id": "317504",
    "regency_id": "3175",
    "label": "Kramatjati",
    "value": "Kramatjati"
  },
  {
    "id": "317505",
    "regency_id": "3175",
    "label": "Pasar Rebo",
    "value": "Pasar Rebo"
  },
  {
    "id": "317506",
    "regency_id": "3175",
    "label": "Cakung",
    "value": "Cakung"
  },
  {
    "id": "317507",
    "regency_id": "3175",
    "label": "Duren Sawit",
    "value": "Duren Sawit"
  },
  {
    "id": "317508",
    "regency_id": "3175",
    "label": "Makasar",
    "value": "Makasar"
  },
  {
    "id": "317509",
    "regency_id": "3175",
    "label": "Ciracas",
    "value": "Ciracas"
  },
  {
    "id": "317510",
    "regency_id": "3175",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "320101",
    "regency_id": "3201",
    "label": "Cibinong",
    "value": "Cibinong"
  },
  {
    "id": "320102",
    "regency_id": "3201",
    "label": "Gunung Putri",
    "value": "Gunung Putri"
  },
  {
    "id": "320103",
    "regency_id": "3201",
    "label": "Citeureup",
    "value": "Citeureup"
  },
  {
    "id": "320104",
    "regency_id": "3201",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "320105",
    "regency_id": "3201",
    "label": "Babakan Madang",
    "value": "Babakan Madang"
  },
  {
    "id": "320106",
    "regency_id": "3201",
    "label": "Jonggol",
    "value": "Jonggol"
  },
  {
    "id": "320107",
    "regency_id": "3201",
    "label": "Cileungsi",
    "value": "Cileungsi"
  },
  { "id": "320108", "regency_id": "3201", "label": "Cariu", "value": "Cariu" },
  {
    "id": "320109",
    "regency_id": "3201",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "320110",
    "regency_id": "3201",
    "label": "Parung",
    "value": "Parung"
  },
  {
    "id": "320111",
    "regency_id": "3201",
    "label": "Gunung Sindur",
    "value": "Gunung Sindur"
  },
  {
    "id": "320112",
    "regency_id": "3201",
    "label": "Kemang",
    "value": "Kemang"
  },
  {
    "id": "320113",
    "regency_id": "3201",
    "label": "Bojong Gede",
    "value": "Bojong Gede"
  },
  {
    "id": "320114",
    "regency_id": "3201",
    "label": "Leuwiliang",
    "value": "Leuwiliang"
  },
  {
    "id": "320115",
    "regency_id": "3201",
    "label": "Ciampea",
    "value": "Ciampea"
  },
  {
    "id": "320116",
    "regency_id": "3201",
    "label": "Cibungbulang",
    "value": "Cibungbulang"
  },
  {
    "id": "320117",
    "regency_id": "3201",
    "label": "Pamijahan",
    "value": "Pamijahan"
  },
  {
    "id": "320118",
    "regency_id": "3201",
    "label": "Rumpin",
    "value": "Rumpin"
  },
  {
    "id": "320119",
    "regency_id": "3201",
    "label": "Jasinga",
    "value": "Jasinga"
  },
  {
    "id": "320120",
    "regency_id": "3201",
    "label": "Parung Panjang",
    "value": "Parung Panjang"
  },
  {
    "id": "320121",
    "regency_id": "3201",
    "label": "Nanggung",
    "value": "Nanggung"
  },
  {
    "id": "320122",
    "regency_id": "3201",
    "label": "Cigudeg",
    "value": "Cigudeg"
  },
  { "id": "320123", "regency_id": "3201", "label": "Tenjo", "value": "Tenjo" },
  { "id": "320124", "regency_id": "3201", "label": "Ciawi", "value": "Ciawi" },
  {
    "id": "320125",
    "regency_id": "3201",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "320126",
    "regency_id": "3201",
    "label": "Megamendung",
    "value": "Megamendung"
  },
  {
    "id": "320127",
    "regency_id": "3201",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "320128",
    "regency_id": "3201",
    "label": "Cijeruk",
    "value": "Cijeruk"
  },
  {
    "id": "320129",
    "regency_id": "3201",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "320130",
    "regency_id": "3201",
    "label": "Dramaga",
    "value": "Dramaga"
  },
  {
    "id": "320131",
    "regency_id": "3201",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "320132",
    "regency_id": "3201",
    "label": "Klapanunggal",
    "value": "Klapanunggal"
  },
  {
    "id": "320133",
    "regency_id": "3201",
    "label": "Ciseeng",
    "value": "Ciseeng"
  },
  {
    "id": "320134",
    "regency_id": "3201",
    "label": "Ranca Bungur",
    "value": "Ranca Bungur"
  },
  {
    "id": "320135",
    "regency_id": "3201",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "320136",
    "regency_id": "3201",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "320137",
    "regency_id": "3201",
    "label": "Tajurhalang",
    "value": "Tajurhalang"
  },
  {
    "id": "320138",
    "regency_id": "3201",
    "label": "Cigombong",
    "value": "Cigombong"
  },
  {
    "id": "320139",
    "regency_id": "3201",
    "label": "Leuwisadeng",
    "value": "Leuwisadeng"
  },
  {
    "id": "320140",
    "regency_id": "3201",
    "label": "Tenjolaya",
    "value": "Tenjolaya"
  },
  {
    "id": "320201",
    "regency_id": "3202",
    "label": "Palabuhanratu",
    "value": "Palabuhanratu"
  },
  {
    "id": "320202",
    "regency_id": "3202",
    "label": "Simpenan",
    "value": "Simpenan"
  },
  {
    "id": "320203",
    "regency_id": "3202",
    "label": "Cikakak",
    "value": "Cikakak"
  },
  {
    "id": "320204",
    "regency_id": "3202",
    "label": "Bantargadung",
    "value": "Bantargadung"
  },
  {
    "id": "320205",
    "regency_id": "3202",
    "label": "Cisolok",
    "value": "Cisolok"
  },
  {
    "id": "320206",
    "regency_id": "3202",
    "label": "Cikidang",
    "value": "Cikidang"
  },
  {
    "id": "320207",
    "regency_id": "3202",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "320208",
    "regency_id": "3202",
    "label": "Jampangtengah",
    "value": "Jampangtengah"
  },
  {
    "id": "320209",
    "regency_id": "3202",
    "label": "Warungkiara",
    "value": "Warungkiara"
  },
  {
    "id": "320210",
    "regency_id": "3202",
    "label": "Cikembar",
    "value": "Cikembar"
  },
  {
    "id": "320211",
    "regency_id": "3202",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "320212",
    "regency_id": "3202",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "320213",
    "regency_id": "3202",
    "label": "Parungkuda",
    "value": "Parungkuda"
  },
  {
    "id": "320214",
    "regency_id": "3202",
    "label": "Bojonggenteng",
    "value": "Bojonggenteng"
  },
  {
    "id": "320215",
    "regency_id": "3202",
    "label": "Parakansalak",
    "value": "Parakansalak"
  },
  {
    "id": "320216",
    "regency_id": "3202",
    "label": "Cicurug",
    "value": "Cicurug"
  },
  {
    "id": "320217",
    "regency_id": "3202",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "320218",
    "regency_id": "3202",
    "label": "Kalapanunggal",
    "value": "Kalapanunggal"
  },
  {
    "id": "320219",
    "regency_id": "3202",
    "label": "Kabandungan",
    "value": "Kabandungan"
  },
  {
    "id": "320220",
    "regency_id": "3202",
    "label": "Waluran",
    "value": "Waluran"
  },
  {
    "id": "320221",
    "regency_id": "3202",
    "label": "Jampangkulon",
    "value": "Jampangkulon"
  },
  {
    "id": "320222",
    "regency_id": "3202",
    "label": "Ciemas",
    "value": "Ciemas"
  },
  {
    "id": "320223",
    "regency_id": "3202",
    "label": "Kalibunder",
    "value": "Kalibunder"
  },
  {
    "id": "320224",
    "regency_id": "3202",
    "label": "Surade",
    "value": "Surade"
  },
  {
    "id": "320225",
    "regency_id": "3202",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "320226",
    "regency_id": "3202",
    "label": "Ciracap",
    "value": "Ciracap"
  },
  {
    "id": "320227",
    "regency_id": "3202",
    "label": "Gunungguruh",
    "value": "Gunungguruh"
  },
  {
    "id": "320228",
    "regency_id": "3202",
    "label": "Cicantayan",
    "value": "Cicantayan"
  },
  {
    "id": "320229",
    "regency_id": "3202",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "320230",
    "regency_id": "3202",
    "label": "Kadudampit",
    "value": "Kadudampit"
  },
  {
    "id": "320231",
    "regency_id": "3202",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "320232",
    "regency_id": "3202",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "320233",
    "regency_id": "3202",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "320234",
    "regency_id": "3202",
    "label": "Kebonpedes",
    "value": "Kebonpedes"
  },
  {
    "id": "320235",
    "regency_id": "3202",
    "label": "Cireunghas",
    "value": "Cireunghas"
  },
  {
    "id": "320236",
    "regency_id": "3202",
    "label": "Sukalarang",
    "value": "Sukalarang"
  },
  {
    "id": "320237",
    "regency_id": "3202",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "320238",
    "regency_id": "3202",
    "label": "Purabaya",
    "value": "Purabaya"
  },
  {
    "id": "320239",
    "regency_id": "3202",
    "label": "Nyalindung",
    "value": "Nyalindung"
  },
  {
    "id": "320240",
    "regency_id": "3202",
    "label": "Gegerbitung",
    "value": "Gegerbitung"
  },
  {
    "id": "320241",
    "regency_id": "3202",
    "label": "Sagaranten",
    "value": "Sagaranten"
  },
  {
    "id": "320242",
    "regency_id": "3202",
    "label": "Curugkembar",
    "value": "Curugkembar"
  },
  {
    "id": "320243",
    "regency_id": "3202",
    "label": "Cidolog",
    "value": "Cidolog"
  },
  {
    "id": "320244",
    "regency_id": "3202",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "320245",
    "regency_id": "3202",
    "label": "Tegalbuleud",
    "value": "Tegalbuleud"
  },
  {
    "id": "320246",
    "regency_id": "3202",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "320247",
    "regency_id": "3202",
    "label": "Ciambar",
    "value": "Ciambar"
  },
  {
    "id": "320301",
    "regency_id": "3203",
    "label": "Cianjur",
    "value": "Cianjur"
  },
  {
    "id": "320302",
    "regency_id": "3203",
    "label": "Warungkondang",
    "value": "Warungkondang"
  },
  {
    "id": "320303",
    "regency_id": "3203",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "320304",
    "regency_id": "3203",
    "label": "Cilaku",
    "value": "Cilaku"
  },
  {
    "id": "320305",
    "regency_id": "3203",
    "label": "Ciranjang",
    "value": "Ciranjang"
  },
  {
    "id": "320306",
    "regency_id": "3203",
    "label": "Bojongpicung",
    "value": "Bojongpicung"
  },
  {
    "id": "320307",
    "regency_id": "3203",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  { "id": "320308", "regency_id": "3203", "label": "Mande", "value": "Mande" },
  {
    "id": "320309",
    "regency_id": "3203",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  { "id": "320310", "regency_id": "3203", "label": "Pacet", "value": "Pacet" },
  {
    "id": "320311",
    "regency_id": "3203",
    "label": "Cugenang",
    "value": "Cugenang"
  },
  {
    "id": "320312",
    "regency_id": "3203",
    "label": "Cikalongkulon",
    "value": "Cikalongkulon"
  },
  {
    "id": "320313",
    "regency_id": "3203",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "320314",
    "regency_id": "3203",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "320315",
    "regency_id": "3203",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "320316",
    "regency_id": "3203",
    "label": "Takokak",
    "value": "Takokak"
  },
  {
    "id": "320317",
    "regency_id": "3203",
    "label": "Kadupandak",
    "value": "Kadupandak"
  },
  {
    "id": "320318",
    "regency_id": "3203",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "320319",
    "regency_id": "3203",
    "label": "Tanggeung",
    "value": "Tanggeung"
  },
  {
    "id": "320320",
    "regency_id": "3203",
    "label": "Cibinong",
    "value": "Cibinong"
  },
  {
    "id": "320321",
    "regency_id": "3203",
    "label": "Sindangbarang",
    "value": "Sindangbarang"
  },
  {
    "id": "320322",
    "regency_id": "3203",
    "label": "Agrabinta",
    "value": "Agrabinta"
  },
  {
    "id": "320323",
    "regency_id": "3203",
    "label": "Cidaun",
    "value": "Cidaun"
  },
  {
    "id": "320324",
    "regency_id": "3203",
    "label": "Naringgul",
    "value": "Naringgul"
  },
  {
    "id": "320325",
    "regency_id": "3203",
    "label": "Campakamulya",
    "value": "Campakamulya"
  },
  {
    "id": "320326",
    "regency_id": "3203",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "320327",
    "regency_id": "3203",
    "label": "Gekbrong",
    "value": "Gekbrong"
  },
  {
    "id": "320328",
    "regency_id": "3203",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "320329",
    "regency_id": "3203",
    "label": "Cijati",
    "value": "Cijati"
  },
  { "id": "320330", "regency_id": "3203", "label": "Leles", "value": "Leles" },
  {
    "id": "320331",
    "regency_id": "3203",
    "label": "Haurwangi",
    "value": "Haurwangi"
  },
  {
    "id": "320332",
    "regency_id": "3203",
    "label": "Pasirkuda",
    "value": "Pasirkuda"
  },
  {
    "id": "320405",
    "regency_id": "3204",
    "label": "Cileunyi",
    "value": "Cileunyi"
  },
  {
    "id": "320406",
    "regency_id": "3204",
    "label": "Cimenyan",
    "value": "Cimenyan"
  },
  {
    "id": "320407",
    "regency_id": "3204",
    "label": "Cilengkrang",
    "value": "Cilengkrang"
  },
  {
    "id": "320408",
    "regency_id": "3204",
    "label": "Bojongsoang",
    "value": "Bojongsoang"
  },
  {
    "id": "320409",
    "regency_id": "3204",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "320410",
    "regency_id": "3204",
    "label": "Margaasih",
    "value": "Margaasih"
  },
  {
    "id": "320411",
    "regency_id": "3204",
    "label": "Katapang",
    "value": "Katapang"
  },
  {
    "id": "320412",
    "regency_id": "3204",
    "label": "Dayeuhkolot",
    "value": "Dayeuhkolot"
  },
  {
    "id": "320413",
    "regency_id": "3204",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "320414",
    "regency_id": "3204",
    "label": "Pameungpeuk",
    "value": "Pameungpeuk"
  },
  {
    "id": "320415",
    "regency_id": "3204",
    "label": "Pangalengan",
    "value": "Pangalengan"
  },
  {
    "id": "320416",
    "regency_id": "3204",
    "label": "Arjasari",
    "value": "Arjasari"
  },
  {
    "id": "320417",
    "regency_id": "3204",
    "label": "Cimaung",
    "value": "Cimaung"
  },
  {
    "id": "320425",
    "regency_id": "3204",
    "label": "Cicalengka",
    "value": "Cicalengka"
  },
  {
    "id": "320426",
    "regency_id": "3204",
    "label": "Nagreg",
    "value": "Nagreg"
  },
  {
    "id": "320427",
    "regency_id": "3204",
    "label": "Cikancung",
    "value": "Cikancung"
  },
  {
    "id": "320428",
    "regency_id": "3204",
    "label": "Rancaekek",
    "value": "Rancaekek"
  },
  {
    "id": "320429",
    "regency_id": "3204",
    "label": "Ciparay",
    "value": "Ciparay"
  },
  { "id": "320430", "regency_id": "3204", "label": "Pacet", "value": "Pacet" },
  {
    "id": "320431",
    "regency_id": "3204",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "320432",
    "regency_id": "3204",
    "label": "Baleendah",
    "value": "Baleendah"
  },
  {
    "id": "320433",
    "regency_id": "3204",
    "label": "Majalaya",
    "value": "Majalaya"
  },
  {
    "id": "320434",
    "regency_id": "3204",
    "label": "Solokanjeruk",
    "value": "Solokanjeruk"
  },
  { "id": "320435", "regency_id": "3204", "label": "Paseh", "value": "Paseh" },
  { "id": "320436", "regency_id": "3204", "label": "Ibun", "value": "Ibun" },
  {
    "id": "320437",
    "regency_id": "3204",
    "label": "Soreang",
    "value": "Soreang"
  },
  {
    "id": "320438",
    "regency_id": "3204",
    "label": "Pasirjambu",
    "value": "Pasirjambu"
  },
  {
    "id": "320439",
    "regency_id": "3204",
    "label": "Ciwidey",
    "value": "Ciwidey"
  },
  {
    "id": "320440",
    "regency_id": "3204",
    "label": "Rancabali",
    "value": "Rancabali"
  },
  {
    "id": "320444",
    "regency_id": "3204",
    "label": "Cangkuang",
    "value": "Cangkuang"
  },
  {
    "id": "320446",
    "regency_id": "3204",
    "label": "Kutawaringin",
    "value": "Kutawaringin"
  },
  {
    "id": "320501",
    "regency_id": "3205",
    "label": "Garut Kota",
    "value": "Garut Kota"
  },
  {
    "id": "320502",
    "regency_id": "3205",
    "label": "Karangpawitan",
    "value": "Karangpawitan"
  },
  {
    "id": "320503",
    "regency_id": "3205",
    "label": "Wanaraja",
    "value": "Wanaraja"
  },
  {
    "id": "320504",
    "regency_id": "3205",
    "label": "Tarogong Kaler",
    "value": "Tarogong Kaler"
  },
  {
    "id": "320505",
    "regency_id": "3205",
    "label": "Tarogong Kidul",
    "value": "Tarogong Kidul"
  },
  {
    "id": "320506",
    "regency_id": "3205",
    "label": "Banyuresmi",
    "value": "Banyuresmi"
  },
  {
    "id": "320507",
    "regency_id": "3205",
    "label": "Samarang",
    "value": "Samarang"
  },
  {
    "id": "320508",
    "regency_id": "3205",
    "label": "Pasirwangi",
    "value": "Pasirwangi"
  },
  { "id": "320509", "regency_id": "3205", "label": "Leles", "value": "Leles" },
  {
    "id": "320510",
    "regency_id": "3205",
    "label": "Kadungora",
    "value": "Kadungora"
  },
  {
    "id": "320511",
    "regency_id": "3205",
    "label": "Leuwigoong",
    "value": "Leuwigoong"
  },
  {
    "id": "320512",
    "regency_id": "3205",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "320513",
    "regency_id": "3205",
    "label": "Kersamanah",
    "value": "Kersamanah"
  },
  {
    "id": "320514",
    "regency_id": "3205",
    "label": "Malangbong",
    "value": "Malangbong"
  },
  {
    "id": "320515",
    "regency_id": "3205",
    "label": "Sukawening",
    "value": "Sukawening"
  },
  {
    "id": "320516",
    "regency_id": "3205",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "320517",
    "regency_id": "3205",
    "label": "Bayongbong",
    "value": "Bayongbong"
  },
  {
    "id": "320518",
    "regency_id": "3205",
    "label": "Cigedug",
    "value": "Cigedug"
  },
  {
    "id": "320519",
    "regency_id": "3205",
    "label": "Cilawu",
    "value": "Cilawu"
  },
  {
    "id": "320520",
    "regency_id": "3205",
    "label": "Cisurupan",
    "value": "Cisurupan"
  },
  {
    "id": "320521",
    "regency_id": "3205",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "320522",
    "regency_id": "3205",
    "label": "Cikajang",
    "value": "Cikajang"
  },
  {
    "id": "320523",
    "regency_id": "3205",
    "label": "Banjarwangi",
    "value": "Banjarwangi"
  },
  {
    "id": "320524",
    "regency_id": "3205",
    "label": "Singajaya",
    "value": "Singajaya"
  },
  {
    "id": "320525",
    "regency_id": "3205",
    "label": "Cihurip",
    "value": "Cihurip"
  },
  {
    "id": "320526",
    "regency_id": "3205",
    "label": "Peundeuy",
    "value": "Peundeuy"
  },
  {
    "id": "320527",
    "regency_id": "3205",
    "label": "Pameungpeuk",
    "value": "Pameungpeuk"
  },
  {
    "id": "320528",
    "regency_id": "3205",
    "label": "Cisompet",
    "value": "Cisompet"
  },
  {
    "id": "320529",
    "regency_id": "3205",
    "label": "Cibalong",
    "value": "Cibalong"
  },
  {
    "id": "320530",
    "regency_id": "3205",
    "label": "Cikelet",
    "value": "Cikelet"
  },
  {
    "id": "320531",
    "regency_id": "3205",
    "label": "Bungbulang",
    "value": "Bungbulang"
  },
  {
    "id": "320532",
    "regency_id": "3205",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "320533",
    "regency_id": "3205",
    "label": "Pakenjeng",
    "value": "Pakenjeng"
  },
  {
    "id": "320534",
    "regency_id": "3205",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "320535",
    "regency_id": "3205",
    "label": "Cisewu",
    "value": "Cisewu"
  },
  {
    "id": "320536",
    "regency_id": "3205",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "320537",
    "regency_id": "3205",
    "label": "Talegong",
    "value": "Talegong"
  },
  {
    "id": "320538",
    "regency_id": "3205",
    "label": "Bl. Limbangan",
    "value": "Bl. Limbangan"
  },
  {
    "id": "320539",
    "regency_id": "3205",
    "label": "Selaawi",
    "value": "Selaawi"
  },
  {
    "id": "320540",
    "regency_id": "3205",
    "label": "Cibiuk",
    "value": "Cibiuk"
  },
  {
    "id": "320541",
    "regency_id": "3205",
    "label": "Pangatikan",
    "value": "Pangatikan"
  },
  {
    "id": "320542",
    "regency_id": "3205",
    "label": "Sucinaraja",
    "value": "Sucinaraja"
  },
  {
    "id": "320601",
    "regency_id": "3206",
    "label": "Cipatujah",
    "value": "Cipatujah"
  },
  {
    "id": "320602",
    "regency_id": "3206",
    "label": "Karangnunggal",
    "value": "Karangnunggal"
  },
  {
    "id": "320603",
    "regency_id": "3206",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "320604",
    "regency_id": "3206",
    "label": "Pancatengah",
    "value": "Pancatengah"
  },
  {
    "id": "320605",
    "regency_id": "3206",
    "label": "Cikatomas",
    "value": "Cikatomas"
  },
  {
    "id": "320606",
    "regency_id": "3206",
    "label": "Cibalong",
    "value": "Cibalong"
  },
  {
    "id": "320607",
    "regency_id": "3206",
    "label": "Parungponteng",
    "value": "Parungponteng"
  },
  {
    "id": "320608",
    "regency_id": "3206",
    "label": "Bantarkalong",
    "value": "Bantarkalong"
  },
  {
    "id": "320609",
    "regency_id": "3206",
    "label": "Bojongasih",
    "value": "Bojongasih"
  },
  {
    "id": "320610",
    "regency_id": "3206",
    "label": "Culamega",
    "value": "Culamega"
  },
  {
    "id": "320611",
    "regency_id": "3206",
    "label": "Bojonggambir",
    "value": "Bojonggambir"
  },
  {
    "id": "320612",
    "regency_id": "3206",
    "label": "Sodonghilir",
    "value": "Sodonghilir"
  },
  {
    "id": "320613",
    "regency_id": "3206",
    "label": "Taraju",
    "value": "Taraju"
  },
  {
    "id": "320614",
    "regency_id": "3206",
    "label": "Salawu",
    "value": "Salawu"
  },
  {
    "id": "320615",
    "regency_id": "3206",
    "label": "Puspahiang",
    "value": "Puspahiang"
  },
  {
    "id": "320616",
    "regency_id": "3206",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "320617",
    "regency_id": "3206",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "320618",
    "regency_id": "3206",
    "label": "Salopa",
    "value": "Salopa"
  },
  {
    "id": "320619",
    "regency_id": "3206",
    "label": "Jatiwaras",
    "value": "Jatiwaras"
  },
  {
    "id": "320620",
    "regency_id": "3206",
    "label": "Cineam",
    "value": "Cineam"
  },
  {
    "id": "320621",
    "regency_id": "3206",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "320622",
    "regency_id": "3206",
    "label": "Manonjaya",
    "value": "Manonjaya"
  },
  {
    "id": "320623",
    "regency_id": "3206",
    "label": "Gunung Tanjung",
    "value": "Gunung Tanjung"
  },
  {
    "id": "320624",
    "regency_id": "3206",
    "label": "Singaparna",
    "value": "Singaparna"
  },
  {
    "id": "320625",
    "regency_id": "3206",
    "label": "Mangunreja",
    "value": "Mangunreja"
  },
  {
    "id": "320626",
    "regency_id": "3206",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "320627",
    "regency_id": "3206",
    "label": "Cigalontang",
    "value": "Cigalontang"
  },
  {
    "id": "320628",
    "regency_id": "3206",
    "label": "Leuwisari",
    "value": "Leuwisari"
  },
  {
    "id": "320629",
    "regency_id": "3206",
    "label": "Padakembang",
    "value": "Padakembang"
  },
  {
    "id": "320630",
    "regency_id": "3206",
    "label": "Sariwangi",
    "value": "Sariwangi"
  },
  {
    "id": "320631",
    "regency_id": "3206",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "320632",
    "regency_id": "3206",
    "label": "Cisayong",
    "value": "Cisayong"
  },
  {
    "id": "320633",
    "regency_id": "3206",
    "label": "Sukahening",
    "value": "Sukahening"
  },
  {
    "id": "320634",
    "regency_id": "3206",
    "label": "Rajapolah",
    "value": "Rajapolah"
  },
  {
    "id": "320635",
    "regency_id": "3206",
    "label": "Jamanis",
    "value": "Jamanis"
  },
  { "id": "320636", "regency_id": "3206", "label": "Ciawi", "value": "Ciawi" },
  {
    "id": "320637",
    "regency_id": "3206",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "320638",
    "regency_id": "3206",
    "label": "Pagerageung",
    "value": "Pagerageung"
  },
  {
    "id": "320639",
    "regency_id": "3206",
    "label": "Sukaresik",
    "value": "Sukaresik"
  },
  {
    "id": "320701",
    "regency_id": "3207",
    "label": "Ciamis",
    "value": "Ciamis"
  },
  {
    "id": "320702",
    "regency_id": "3207",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "320703",
    "regency_id": "3207",
    "label": "Cijeungjing",
    "value": "Cijeungjing"
  },
  {
    "id": "320704",
    "regency_id": "3207",
    "label": "Sadananya",
    "value": "Sadananya"
  },
  {
    "id": "320705",
    "regency_id": "3207",
    "label": "Cidolog",
    "value": "Cidolog"
  },
  {
    "id": "320706",
    "regency_id": "3207",
    "label": "Cihaurbeuti",
    "value": "Cihaurbeuti"
  },
  {
    "id": "320707",
    "regency_id": "3207",
    "label": "Panumbangan",
    "value": "Panumbangan"
  },
  {
    "id": "320708",
    "regency_id": "3207",
    "label": "Panjalu",
    "value": "Panjalu"
  },
  {
    "id": "320709",
    "regency_id": "3207",
    "label": "Kawali",
    "value": "Kawali"
  },
  {
    "id": "320710",
    "regency_id": "3207",
    "label": "Panawangan",
    "value": "Panawangan"
  },
  {
    "id": "320711",
    "regency_id": "3207",
    "label": "Cipaku",
    "value": "Cipaku"
  },
  {
    "id": "320712",
    "regency_id": "3207",
    "label": "Jatinagara",
    "value": "Jatinagara"
  },
  {
    "id": "320713",
    "regency_id": "3207",
    "label": "Rajadesa",
    "value": "Rajadesa"
  },
  {
    "id": "320714",
    "regency_id": "3207",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "320715",
    "regency_id": "3207",
    "label": "Rancah",
    "value": "Rancah"
  },
  {
    "id": "320716",
    "regency_id": "3207",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "320717",
    "regency_id": "3207",
    "label": "Lakbok",
    "value": "Lakbok"
  },
  {
    "id": "320718",
    "regency_id": "3207",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "320719",
    "regency_id": "3207",
    "label": "Pamarican",
    "value": "Pamarican"
  },
  {
    "id": "320729",
    "regency_id": "3207",
    "label": "Cimaragas",
    "value": "Cimaragas"
  },
  {
    "id": "320730",
    "regency_id": "3207",
    "label": "Cisaga",
    "value": "Cisaga"
  },
  {
    "id": "320731",
    "regency_id": "3207",
    "label": "Sindangkasih",
    "value": "Sindangkasih"
  },
  {
    "id": "320732",
    "regency_id": "3207",
    "label": "Baregbeg",
    "value": "Baregbeg"
  },
  {
    "id": "320733",
    "regency_id": "3207",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "320734",
    "regency_id": "3207",
    "label": "Lumbung",
    "value": "Lumbung"
  },
  {
    "id": "320735",
    "regency_id": "3207",
    "label": "Purwadadi",
    "value": "Purwadadi"
  },
  {
    "id": "320737",
    "regency_id": "3207",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "320801",
    "regency_id": "3208",
    "label": "Kadugede",
    "value": "Kadugede"
  },
  {
    "id": "320802",
    "regency_id": "3208",
    "label": "Ciniru",
    "value": "Ciniru"
  },
  {
    "id": "320803",
    "regency_id": "3208",
    "label": "Subang",
    "value": "Subang"
  },
  {
    "id": "320804",
    "regency_id": "3208",
    "label": "Ciwaru",
    "value": "Ciwaru"
  },
  {
    "id": "320805",
    "regency_id": "3208",
    "label": "Cibingbin",
    "value": "Cibingbin"
  },
  {
    "id": "320806",
    "regency_id": "3208",
    "label": "Luragung",
    "value": "Luragung"
  },
  {
    "id": "320807",
    "regency_id": "3208",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "320808",
    "regency_id": "3208",
    "label": "Garawangi",
    "value": "Garawangi"
  },
  {
    "id": "320809",
    "regency_id": "3208",
    "label": "Kuningan",
    "value": "Kuningan"
  },
  {
    "id": "320810",
    "regency_id": "3208",
    "label": "Ciawigebang",
    "value": "Ciawigebang"
  },
  {
    "id": "320811",
    "regency_id": "3208",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "320812",
    "regency_id": "3208",
    "label": "Jalaksana",
    "value": "Jalaksana"
  },
  {
    "id": "320813",
    "regency_id": "3208",
    "label": "Cilimus",
    "value": "Cilimus"
  },
  {
    "id": "320814",
    "regency_id": "3208",
    "label": "Mandirancan",
    "value": "Mandirancan"
  },
  {
    "id": "320815",
    "regency_id": "3208",
    "label": "Selajambe",
    "value": "Selajambe"
  },
  {
    "id": "320816",
    "regency_id": "3208",
    "label": "Kramatmulya",
    "value": "Kramatmulya"
  },
  { "id": "320817", "regency_id": "3208", "label": "Darma", "value": "Darma" },
  {
    "id": "320818",
    "regency_id": "3208",
    "label": "Cigugur",
    "value": "Cigugur"
  },
  {
    "id": "320819",
    "regency_id": "3208",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "320820",
    "regency_id": "3208",
    "label": "Nusaherang",
    "value": "Nusaherang"
  },
  {
    "id": "320821",
    "regency_id": "3208",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "320822",
    "regency_id": "3208",
    "label": "Pancalang",
    "value": "Pancalang"
  },
  {
    "id": "320823",
    "regency_id": "3208",
    "label": "Japara",
    "value": "Japara"
  },
  {
    "id": "320824",
    "regency_id": "3208",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "320825",
    "regency_id": "3208",
    "label": "Cilebak",
    "value": "Cilebak"
  },
  {
    "id": "320826",
    "regency_id": "3208",
    "label": "Hantara",
    "value": "Hantara"
  },
  {
    "id": "320827",
    "regency_id": "3208",
    "label": "Kalimanggis",
    "value": "Kalimanggis"
  },
  {
    "id": "320828",
    "regency_id": "3208",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "320829",
    "regency_id": "3208",
    "label": "Karang Kancana",
    "value": "Karang Kancana"
  },
  {
    "id": "320830",
    "regency_id": "3208",
    "label": "Maleber",
    "value": "Maleber"
  },
  {
    "id": "320831",
    "regency_id": "3208",
    "label": "Sindangagung",
    "value": "Sindangagung"
  },
  {
    "id": "320832",
    "regency_id": "3208",
    "label": "Cigandamekar",
    "value": "Cigandamekar"
  },
  { "id": "320901", "regency_id": "3209", "label": "Waled", "value": "Waled" },
  {
    "id": "320902",
    "regency_id": "3209",
    "label": "Ciledug",
    "value": "Ciledug"
  },
  {
    "id": "320903",
    "regency_id": "3209",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "320904",
    "regency_id": "3209",
    "label": "Pabedilan",
    "value": "Pabedilan"
  },
  {
    "id": "320905",
    "regency_id": "3209",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "320906",
    "regency_id": "3209",
    "label": "Karangsembung",
    "value": "Karangsembung"
  },
  {
    "id": "320907",
    "regency_id": "3209",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "320908",
    "regency_id": "3209",
    "label": "Susukan Lebak",
    "value": "Susukan Lebak"
  },
  {
    "id": "320909",
    "regency_id": "3209",
    "label": "Sedong",
    "value": "Sedong"
  },
  {
    "id": "320910",
    "regency_id": "3209",
    "label": "Astanajapura",
    "value": "Astanajapura"
  },
  {
    "id": "320911",
    "regency_id": "3209",
    "label": "Pangenan",
    "value": "Pangenan"
  },
  { "id": "320912", "regency_id": "3209", "label": "Mundu", "value": "Mundu" },
  { "id": "320913", "regency_id": "3209", "label": "Beber", "value": "Beber" },
  { "id": "320914", "regency_id": "3209", "label": "Talun", "value": "Talun" },
  {
    "id": "320915",
    "regency_id": "3209",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "320916",
    "regency_id": "3209",
    "label": "Dukupuntang",
    "value": "Dukupuntang"
  },
  {
    "id": "320917",
    "regency_id": "3209",
    "label": "Palimanan",
    "value": "Palimanan"
  },
  {
    "id": "320918",
    "regency_id": "3209",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  { "id": "320919", "regency_id": "3209", "label": "Weru", "value": "Weru" },
  {
    "id": "320920",
    "regency_id": "3209",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "320921",
    "regency_id": "3209",
    "label": "Gunung Jati",
    "value": "Gunung Jati"
  },
  {
    "id": "320922",
    "regency_id": "3209",
    "label": "Kapetakan",
    "value": "Kapetakan"
  },
  {
    "id": "320923",
    "regency_id": "3209",
    "label": "Klangenan",
    "value": "Klangenan"
  },
  {
    "id": "320924",
    "regency_id": "3209",
    "label": "Arjawinangun",
    "value": "Arjawinangun"
  },
  {
    "id": "320925",
    "regency_id": "3209",
    "label": "Panguragan",
    "value": "Panguragan"
  },
  {
    "id": "320926",
    "regency_id": "3209",
    "label": "Ciwaringin",
    "value": "Ciwaringin"
  },
  {
    "id": "320927",
    "regency_id": "3209",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "320928",
    "regency_id": "3209",
    "label": "Gegesik",
    "value": "Gegesik"
  },
  {
    "id": "320929",
    "regency_id": "3209",
    "label": "Kaliwedi",
    "value": "Kaliwedi"
  },
  {
    "id": "320930",
    "regency_id": "3209",
    "label": "Gebang",
    "value": "Gebang"
  },
  { "id": "320931", "regency_id": "3209", "label": "Depok", "value": "Depok" },
  {
    "id": "320932",
    "regency_id": "3209",
    "label": "Pasaleman",
    "value": "Pasaleman"
  },
  {
    "id": "320933",
    "regency_id": "3209",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "320934",
    "regency_id": "3209",
    "label": "Karangwareng",
    "value": "Karangwareng"
  },
  {
    "id": "320935",
    "regency_id": "3209",
    "label": "Tengah Tani",
    "value": "Tengah Tani"
  },
  {
    "id": "320936",
    "regency_id": "3209",
    "label": "Plered",
    "value": "Plered"
  },
  {
    "id": "320937",
    "regency_id": "3209",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "320938",
    "regency_id": "3209",
    "label": "Greged",
    "value": "Greged"
  },
  {
    "id": "320939",
    "regency_id": "3209",
    "label": "Suranenggala",
    "value": "Suranenggala"
  },
  {
    "id": "320940",
    "regency_id": "3209",
    "label": "Jamblang",
    "value": "Jamblang"
  },
  {
    "id": "321001",
    "regency_id": "3210",
    "label": "Lemahsugih",
    "value": "Lemahsugih"
  },
  {
    "id": "321002",
    "regency_id": "3210",
    "label": "Bantarujeg",
    "value": "Bantarujeg"
  },
  {
    "id": "321003",
    "regency_id": "3210",
    "label": "Cikijing",
    "value": "Cikijing"
  },
  {
    "id": "321004",
    "regency_id": "3210",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "321005",
    "regency_id": "3210",
    "label": "Argapura",
    "value": "Argapura"
  },
  { "id": "321006", "regency_id": "3210", "label": "Maja", "value": "Maja" },
  {
    "id": "321007",
    "regency_id": "3210",
    "label": "Majalengka",
    "value": "Majalengka"
  },
  {
    "id": "321008",
    "regency_id": "3210",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "321009",
    "regency_id": "3210",
    "label": "Rajagaluh",
    "value": "Rajagaluh"
  },
  {
    "id": "321010",
    "regency_id": "3210",
    "label": "Leuwimunding",
    "value": "Leuwimunding"
  },
  {
    "id": "321011",
    "regency_id": "3210",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "321012",
    "regency_id": "3210",
    "label": "Dawuan",
    "value": "Dawuan"
  },
  {
    "id": "321013",
    "regency_id": "3210",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "321014",
    "regency_id": "3210",
    "label": "Kertajati",
    "value": "Kertajati"
  },
  {
    "id": "321015",
    "regency_id": "3210",
    "label": "Jatitujuh",
    "value": "Jatitujuh"
  },
  {
    "id": "321016",
    "regency_id": "3210",
    "label": "Ligung",
    "value": "Ligung"
  },
  {
    "id": "321017",
    "regency_id": "3210",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "321018",
    "regency_id": "3210",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "321019",
    "regency_id": "3210",
    "label": "Palasah",
    "value": "Palasah"
  },
  {
    "id": "321020",
    "regency_id": "3210",
    "label": "Cigasong",
    "value": "Cigasong"
  },
  {
    "id": "321021",
    "regency_id": "3210",
    "label": "Sindangwangi",
    "value": "Sindangwangi"
  },
  {
    "id": "321022",
    "regency_id": "3210",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "321023",
    "regency_id": "3210",
    "label": "Cingambul",
    "value": "Cingambul"
  },
  {
    "id": "321024",
    "regency_id": "3210",
    "label": "Kasokandel",
    "value": "Kasokandel"
  },
  {
    "id": "321025",
    "regency_id": "3210",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "321026",
    "regency_id": "3210",
    "label": "Malausma",
    "value": "Malausma"
  },
  { "id": "321101", "regency_id": "3211", "label": "Wado", "value": "Wado" },
  {
    "id": "321102",
    "regency_id": "3211",
    "label": "Jatinunggal",
    "value": "Jatinunggal"
  },
  {
    "id": "321103",
    "regency_id": "3211",
    "label": "Darmaraja",
    "value": "Darmaraja"
  },
  {
    "id": "321104",
    "regency_id": "3211",
    "label": "Cibugel",
    "value": "Cibugel"
  },
  {
    "id": "321105",
    "regency_id": "3211",
    "label": "Cisitu",
    "value": "Cisitu"
  },
  {
    "id": "321106",
    "regency_id": "3211",
    "label": "Situraja",
    "value": "Situraja"
  },
  {
    "id": "321107",
    "regency_id": "3211",
    "label": "Conggeang",
    "value": "Conggeang"
  },
  { "id": "321108", "regency_id": "3211", "label": "Paseh", "value": "Paseh" },
  {
    "id": "321109",
    "regency_id": "3211",
    "label": "Surian",
    "value": "Surian"
  },
  {
    "id": "321110",
    "regency_id": "3211",
    "label": "Buahdua",
    "value": "Buahdua"
  },
  {
    "id": "321111",
    "regency_id": "3211",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "321112",
    "regency_id": "3211",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "321113",
    "regency_id": "3211",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "321114",
    "regency_id": "3211",
    "label": "Cimanggung",
    "value": "Cimanggung"
  },
  {
    "id": "321115",
    "regency_id": "3211",
    "label": "Jatinangor",
    "value": "Jatinangor"
  },
  {
    "id": "321116",
    "regency_id": "3211",
    "label": "Rancakalong",
    "value": "Rancakalong"
  },
  {
    "id": "321117",
    "regency_id": "3211",
    "label": "Sumedang Selatan",
    "value": "Sumedang Selatan"
  },
  {
    "id": "321118",
    "regency_id": "3211",
    "label": "Sumedang Utara",
    "value": "Sumedang Utara"
  },
  {
    "id": "321119",
    "regency_id": "3211",
    "label": "Ganeas",
    "value": "Ganeas"
  },
  {
    "id": "321120",
    "regency_id": "3211",
    "label": "Tanjungkerta",
    "value": "Tanjungkerta"
  },
  {
    "id": "321121",
    "regency_id": "3211",
    "label": "Tanjungmedar",
    "value": "Tanjungmedar"
  },
  {
    "id": "321122",
    "regency_id": "3211",
    "label": "Cimalaka",
    "value": "Cimalaka"
  },
  {
    "id": "321123",
    "regency_id": "3211",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  { "id": "321124", "regency_id": "3211", "label": "Tomo", "value": "Tomo" },
  {
    "id": "321125",
    "regency_id": "3211",
    "label": "Ujungjaya",
    "value": "Ujungjaya"
  },
  {
    "id": "321126",
    "regency_id": "3211",
    "label": "Jatigede",
    "value": "Jatigede"
  },
  {
    "id": "321201",
    "regency_id": "3212",
    "label": "Haurgeulis",
    "value": "Haurgeulis"
  },
  { "id": "321202", "regency_id": "3212", "label": "Kroya", "value": "Kroya" },
  {
    "id": "321203",
    "regency_id": "3212",
    "label": "Gabuswetan",
    "value": "Gabuswetan"
  },
  {
    "id": "321204",
    "regency_id": "3212",
    "label": "Cikedung",
    "value": "Cikedung"
  },
  { "id": "321205", "regency_id": "3212", "label": "Lelea", "value": "Lelea" },
  {
    "id": "321206",
    "regency_id": "3212",
    "label": "Bangodua",
    "value": "Bangodua"
  },
  {
    "id": "321207",
    "regency_id": "3212",
    "label": "Widasari",
    "value": "Widasari"
  },
  {
    "id": "321208",
    "regency_id": "3212",
    "label": "Kertasemaya",
    "value": "Kertasemaya"
  },
  {
    "id": "321209",
    "regency_id": "3212",
    "label": "Krangkeng",
    "value": "Krangkeng"
  },
  {
    "id": "321210",
    "regency_id": "3212",
    "label": "Karangampel",
    "value": "Karangampel"
  },
  {
    "id": "321211",
    "regency_id": "3212",
    "label": "Juntinyuat",
    "value": "Juntinyuat"
  },
  {
    "id": "321212",
    "regency_id": "3212",
    "label": "Sliyeg",
    "value": "Sliyeg"
  },
  {
    "id": "321213",
    "regency_id": "3212",
    "label": "Jatibarang",
    "value": "Jatibarang"
  },
  {
    "id": "321214",
    "regency_id": "3212",
    "label": "Balongan",
    "value": "Balongan"
  },
  {
    "id": "321215",
    "regency_id": "3212",
    "label": "Indramayu",
    "value": "Indramayu"
  },
  {
    "id": "321216",
    "regency_id": "3212",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "321217",
    "regency_id": "3212",
    "label": "Cantigi",
    "value": "Cantigi"
  },
  {
    "id": "321218",
    "regency_id": "3212",
    "label": "Lohbener",
    "value": "Lohbener"
  },
  {
    "id": "321219",
    "regency_id": "3212",
    "label": "Arahan",
    "value": "Arahan"
  },
  {
    "id": "321220",
    "regency_id": "3212",
    "label": "Losarang",
    "value": "Losarang"
  },
  {
    "id": "321221",
    "regency_id": "3212",
    "label": "Kandanghaur",
    "value": "Kandanghaur"
  },
  {
    "id": "321222",
    "regency_id": "3212",
    "label": "Bongas",
    "value": "Bongas"
  },
  {
    "id": "321223",
    "regency_id": "3212",
    "label": "Anjatan",
    "value": "Anjatan"
  },
  { "id": "321224", "regency_id": "3212", "label": "Sukra", "value": "Sukra" },
  {
    "id": "321225",
    "regency_id": "3212",
    "label": "Gantar",
    "value": "Gantar"
  },
  { "id": "321226", "regency_id": "3212", "label": "Trisi", "value": "Trisi" },
  {
    "id": "321227",
    "regency_id": "3212",
    "label": "Sukagumiwang",
    "value": "Sukagumiwang"
  },
  {
    "id": "321228",
    "regency_id": "3212",
    "label": "Kedokan Bunder",
    "value": "Kedokan Bunder"
  },
  {
    "id": "321229",
    "regency_id": "3212",
    "label": "Pasekan",
    "value": "Pasekan"
  },
  {
    "id": "321230",
    "regency_id": "3212",
    "label": "Tukdana",
    "value": "Tukdana"
  },
  {
    "id": "321231",
    "regency_id": "3212",
    "label": "Patrol",
    "value": "Patrol"
  },
  {
    "id": "321301",
    "regency_id": "3213",
    "label": "Sagalaherang",
    "value": "Sagalaherang"
  },
  {
    "id": "321302",
    "regency_id": "3213",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "321303",
    "regency_id": "3213",
    "label": "Subang",
    "value": "Subang"
  },
  {
    "id": "321304",
    "regency_id": "3213",
    "label": "Kalijati",
    "value": "Kalijati"
  },
  {
    "id": "321305",
    "regency_id": "3213",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "321306",
    "regency_id": "3213",
    "label": "Purwadadi",
    "value": "Purwadadi"
  },
  {
    "id": "321307",
    "regency_id": "3213",
    "label": "Pagaden",
    "value": "Pagaden"
  },
  {
    "id": "321308",
    "regency_id": "3213",
    "label": "Binong",
    "value": "Binong"
  },
  {
    "id": "321309",
    "regency_id": "3213",
    "label": "Ciasem",
    "value": "Ciasem"
  },
  {
    "id": "321310",
    "regency_id": "3213",
    "label": "Pusakanagara",
    "value": "Pusakanagara"
  },
  {
    "id": "321311",
    "regency_id": "3213",
    "label": "Pamanukan",
    "value": "Pamanukan"
  },
  {
    "id": "321312",
    "regency_id": "3213",
    "label": "Jalancagak",
    "value": "Jalancagak"
  },
  {
    "id": "321313",
    "regency_id": "3213",
    "label": "Blanakan",
    "value": "Blanakan"
  },
  {
    "id": "321314",
    "regency_id": "3213",
    "label": "Tanjungsiang",
    "value": "Tanjungsiang"
  },
  {
    "id": "321315",
    "regency_id": "3213",
    "label": "Compreng",
    "value": "Compreng"
  },
  {
    "id": "321316",
    "regency_id": "3213",
    "label": "Patokbeusi",
    "value": "Patokbeusi"
  },
  {
    "id": "321317",
    "regency_id": "3213",
    "label": "Cibogo",
    "value": "Cibogo"
  },
  {
    "id": "321318",
    "regency_id": "3213",
    "label": "Cipunagara",
    "value": "Cipunagara"
  },
  {
    "id": "321319",
    "regency_id": "3213",
    "label": "Cijambe",
    "value": "Cijambe"
  },
  {
    "id": "321320",
    "regency_id": "3213",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "321321",
    "regency_id": "3213",
    "label": "Legonkulon",
    "value": "Legonkulon"
  },
  {
    "id": "321322",
    "regency_id": "3213",
    "label": "Cikaum",
    "value": "Cikaum"
  },
  {
    "id": "321323",
    "regency_id": "3213",
    "label": "Serangpanjang",
    "value": "Serangpanjang"
  },
  {
    "id": "321324",
    "regency_id": "3213",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "321325",
    "regency_id": "3213",
    "label": "Tambakdahan",
    "value": "Tambakdahan"
  },
  {
    "id": "321326",
    "regency_id": "3213",
    "label": "Kasomalang",
    "value": "Kasomalang"
  },
  {
    "id": "321327",
    "regency_id": "3213",
    "label": "Dawuan",
    "value": "Dawuan"
  },
  {
    "id": "321328",
    "regency_id": "3213",
    "label": "Pagaden Barat",
    "value": "Pagaden Barat"
  },
  {
    "id": "321329",
    "regency_id": "3213",
    "label": "Ciater",
    "value": "Ciater"
  },
  {
    "id": "321330",
    "regency_id": "3213",
    "label": "Pusakajaya",
    "value": "Pusakajaya"
  },
  {
    "id": "321401",
    "regency_id": "3214",
    "label": "Purwakarta",
    "value": "Purwakarta"
  },
  {
    "id": "321402",
    "regency_id": "3214",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "321403",
    "regency_id": "3214",
    "label": "Jatiluhur",
    "value": "Jatiluhur"
  },
  {
    "id": "321404",
    "regency_id": "3214",
    "label": "Plered",
    "value": "Plered"
  },
  {
    "id": "321405",
    "regency_id": "3214",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "321406",
    "regency_id": "3214",
    "label": "Darangdan",
    "value": "Darangdan"
  },
  {
    "id": "321407",
    "regency_id": "3214",
    "label": "Maniis",
    "value": "Maniis"
  },
  {
    "id": "321408",
    "regency_id": "3214",
    "label": "Tegalwaru",
    "value": "Tegalwaru"
  },
  {
    "id": "321409",
    "regency_id": "3214",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "321410",
    "regency_id": "3214",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "321411",
    "regency_id": "3214",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "321412",
    "regency_id": "3214",
    "label": "Babakancikao",
    "value": "Babakancikao"
  },
  {
    "id": "321413",
    "regency_id": "3214",
    "label": "Bungursari",
    "value": "Bungursari"
  },
  {
    "id": "321414",
    "regency_id": "3214",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "321415",
    "regency_id": "3214",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "321416",
    "regency_id": "3214",
    "label": "Pondoksalam",
    "value": "Pondoksalam"
  },
  {
    "id": "321417",
    "regency_id": "3214",
    "label": "Kiarapedes",
    "value": "Kiarapedes"
  },
  {
    "id": "321501",
    "regency_id": "3215",
    "label": "Karawang Barat",
    "value": "Karawang Barat"
  },
  {
    "id": "321502",
    "regency_id": "3215",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "321503",
    "regency_id": "3215",
    "label": "Telukjambe Timur",
    "value": "Telukjambe Timur"
  },
  {
    "id": "321504",
    "regency_id": "3215",
    "label": "Ciampel",
    "value": "Ciampel"
  },
  { "id": "321505", "regency_id": "3215", "label": "Klari", "value": "Klari" },
  {
    "id": "321506",
    "regency_id": "3215",
    "label": "Rengasdengklok",
    "value": "Rengasdengklok"
  },
  {
    "id": "321507",
    "regency_id": "3215",
    "label": "Kutawaluya",
    "value": "Kutawaluya"
  },
  {
    "id": "321508",
    "regency_id": "3215",
    "label": "Batujaya",
    "value": "Batujaya"
  },
  {
    "id": "321509",
    "regency_id": "3215",
    "label": "Tirtajaya",
    "value": "Tirtajaya"
  },
  { "id": "321510", "regency_id": "3215", "label": "Pedes", "value": "Pedes" },
  {
    "id": "321511",
    "regency_id": "3215",
    "label": "Cibuaya",
    "value": "Cibuaya"
  },
  {
    "id": "321512",
    "regency_id": "3215",
    "label": "Pakisjaya",
    "value": "Pakisjaya"
  },
  {
    "id": "321513",
    "regency_id": "3215",
    "label": "Cikampek",
    "value": "Cikampek"
  },
  {
    "id": "321514",
    "regency_id": "3215",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "321515",
    "regency_id": "3215",
    "label": "Cilamaya Wetan",
    "value": "Cilamaya Wetan"
  },
  {
    "id": "321516",
    "regency_id": "3215",
    "label": "Tirtamulya",
    "value": "Tirtamulya"
  },
  {
    "id": "321517",
    "regency_id": "3215",
    "label": "Telagasari",
    "value": "Telagasari"
  },
  {
    "id": "321518",
    "regency_id": "3215",
    "label": "Rawamerta",
    "value": "Rawamerta"
  },
  {
    "id": "321519",
    "regency_id": "3215",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "321520",
    "regency_id": "3215",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "321521",
    "regency_id": "3215",
    "label": "Majalaya",
    "value": "Majalaya"
  },
  {
    "id": "321522",
    "regency_id": "3215",
    "label": "Jayakerta",
    "value": "Jayakerta"
  },
  {
    "id": "321523",
    "regency_id": "3215",
    "label": "Cilamaya Kulon",
    "value": "Cilamaya Kulon"
  },
  {
    "id": "321524",
    "regency_id": "3215",
    "label": "Banyusari",
    "value": "Banyusari"
  },
  {
    "id": "321525",
    "regency_id": "3215",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "321526",
    "regency_id": "3215",
    "label": "Karawang Timur",
    "value": "Karawang Timur"
  },
  {
    "id": "321527",
    "regency_id": "3215",
    "label": "Telukjambe Barat",
    "value": "Telukjambe Barat"
  },
  {
    "id": "321528",
    "regency_id": "3215",
    "label": "Tegalwaru",
    "value": "Tegalwaru"
  },
  {
    "id": "321529",
    "regency_id": "3215",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "321530",
    "regency_id": "3215",
    "label": "Cilebar",
    "value": "Cilebar"
  },
  {
    "id": "321601",
    "regency_id": "3216",
    "label": "Tarumajaya",
    "value": "Tarumajaya"
  },
  {
    "id": "321602",
    "regency_id": "3216",
    "label": "Babelan",
    "value": "Babelan"
  },
  {
    "id": "321603",
    "regency_id": "3216",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "321604",
    "regency_id": "3216",
    "label": "Tambelang",
    "value": "Tambelang"
  },
  {
    "id": "321605",
    "regency_id": "3216",
    "label": "Tambun Utara",
    "value": "Tambun Utara"
  },
  {
    "id": "321606",
    "regency_id": "3216",
    "label": "Tambun Selatan",
    "value": "Tambun Selatan"
  },
  {
    "id": "321607",
    "regency_id": "3216",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "321608",
    "regency_id": "3216",
    "label": "Cikarang Barat",
    "value": "Cikarang Barat"
  },
  {
    "id": "321609",
    "regency_id": "3216",
    "label": "Cikarang Utara",
    "value": "Cikarang Utara"
  },
  {
    "id": "321610",
    "regency_id": "3216",
    "label": "Karang Bahagia",
    "value": "Karang Bahagia"
  },
  {
    "id": "321611",
    "regency_id": "3216",
    "label": "Cikarang Timur",
    "value": "Cikarang Timur"
  },
  {
    "id": "321612",
    "regency_id": "3216",
    "label": "Kedung Waringin",
    "value": "Kedung Waringin"
  },
  {
    "id": "321613",
    "regency_id": "3216",
    "label": "Pebayuran",
    "value": "Pebayuran"
  },
  {
    "id": "321614",
    "regency_id": "3216",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "321615",
    "regency_id": "3216",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "321616",
    "regency_id": "3216",
    "label": "Cabangbungin",
    "value": "Cabangbungin"
  },
  {
    "id": "321617",
    "regency_id": "3216",
    "label": "Muaragembong",
    "value": "Muaragembong"
  },
  { "id": "321618", "regency_id": "3216", "label": "Setu", "value": "Setu" },
  {
    "id": "321619",
    "regency_id": "3216",
    "label": "Cikarang Selatan",
    "value": "Cikarang Selatan"
  },
  {
    "id": "321620",
    "regency_id": "3216",
    "label": "Cikarang Pusat",
    "value": "Cikarang Pusat"
  },
  {
    "id": "321621",
    "regency_id": "3216",
    "label": "Serang Baru",
    "value": "Serang Baru"
  },
  {
    "id": "321622",
    "regency_id": "3216",
    "label": "Cibarusah",
    "value": "Cibarusah"
  },
  {
    "id": "321623",
    "regency_id": "3216",
    "label": "Bojongmangu",
    "value": "Bojongmangu"
  },
  {
    "id": "321701",
    "regency_id": "3217",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "321702",
    "regency_id": "3217",
    "label": "Parongpong",
    "value": "Parongpong"
  },
  {
    "id": "321703",
    "regency_id": "3217",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "321704",
    "regency_id": "3217",
    "label": "Cikalongwetan",
    "value": "Cikalongwetan"
  },
  {
    "id": "321705",
    "regency_id": "3217",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "321706",
    "regency_id": "3217",
    "label": "Ngamprah",
    "value": "Ngamprah"
  },
  {
    "id": "321707",
    "regency_id": "3217",
    "label": "Cipatat",
    "value": "Cipatat"
  },
  {
    "id": "321708",
    "regency_id": "3217",
    "label": "Padalarang",
    "value": "Padalarang"
  },
  {
    "id": "321709",
    "regency_id": "3217",
    "label": "Batujajar",
    "value": "Batujajar"
  },
  {
    "id": "321710",
    "regency_id": "3217",
    "label": "Cihampelas",
    "value": "Cihampelas"
  },
  {
    "id": "321711",
    "regency_id": "3217",
    "label": "Cililin",
    "value": "Cililin"
  },
  {
    "id": "321712",
    "regency_id": "3217",
    "label": "Cipongkor",
    "value": "Cipongkor"
  },
  {
    "id": "321713",
    "regency_id": "3217",
    "label": "Rongga",
    "value": "Rongga"
  },
  {
    "id": "321714",
    "regency_id": "3217",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "321715",
    "regency_id": "3217",
    "label": "Gununghalu",
    "value": "Gununghalu"
  },
  {
    "id": "321716",
    "regency_id": "3217",
    "label": "Saguling",
    "value": "Saguling"
  },
  {
    "id": "321801",
    "regency_id": "3218",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "321802",
    "regency_id": "3218",
    "label": "Cijulang",
    "value": "Cijulang"
  },
  {
    "id": "321803",
    "regency_id": "3218",
    "label": "Cimerak",
    "value": "Cimerak"
  },
  {
    "id": "321804",
    "regency_id": "3218",
    "label": "Cigugur",
    "value": "Cigugur"
  },
  {
    "id": "321805",
    "regency_id": "3218",
    "label": "Langkaplancar",
    "value": "Langkaplancar"
  },
  {
    "id": "321806",
    "regency_id": "3218",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "321807",
    "regency_id": "3218",
    "label": "Padaherang",
    "value": "Padaherang"
  },
  {
    "id": "321808",
    "regency_id": "3218",
    "label": "Kalipucang",
    "value": "Kalipucang"
  },
  {
    "id": "321809",
    "regency_id": "3218",
    "label": "Pangandaran",
    "value": "Pangandaran"
  },
  {
    "id": "321810",
    "regency_id": "3218",
    "label": "Sidamulih",
    "value": "Sidamulih"
  },
  {
    "id": "327101",
    "regency_id": "3271",
    "label": "Bogor Selatan",
    "value": "Bogor Selatan"
  },
  {
    "id": "327102",
    "regency_id": "3271",
    "label": "Bogor Timur",
    "value": "Bogor Timur"
  },
  {
    "id": "327103",
    "regency_id": "3271",
    "label": "Bogor Tengah",
    "value": "Bogor Tengah"
  },
  {
    "id": "327104",
    "regency_id": "3271",
    "label": "Bogor Barat",
    "value": "Bogor Barat"
  },
  {
    "id": "327105",
    "regency_id": "3271",
    "label": "Bogor Utara",
    "value": "Bogor Utara"
  },
  {
    "id": "327106",
    "regency_id": "3271",
    "label": "Tanah Sareal",
    "value": "Tanah Sareal"
  },
  {
    "id": "327201",
    "regency_id": "3272",
    "label": "Gunungpuyuh",
    "value": "Gunungpuyuh"
  },
  {
    "id": "327202",
    "regency_id": "3272",
    "label": "Cikole",
    "value": "Cikole"
  },
  {
    "id": "327203",
    "regency_id": "3272",
    "label": "Citamiang",
    "value": "Citamiang"
  },
  {
    "id": "327204",
    "regency_id": "3272",
    "label": "Warudoyong",
    "value": "Warudoyong"
  },
  { "id": "327205", "regency_id": "3272", "label": "Baros", "value": "Baros" },
  {
    "id": "327206",
    "regency_id": "3272",
    "label": "Lembursitu",
    "value": "Lembursitu"
  },
  {
    "id": "327207",
    "regency_id": "3272",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "327301",
    "regency_id": "3273",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "327302",
    "regency_id": "3273",
    "label": "Coblong",
    "value": "Coblong"
  },
  {
    "id": "327303",
    "regency_id": "3273",
    "label": "Babakan Ciparay",
    "value": "Babakan Ciparay"
  },
  {
    "id": "327304",
    "regency_id": "3273",
    "label": "Bojongloa Kaler",
    "value": "Bojongloa Kaler"
  },
  { "id": "327305", "regency_id": "3273", "label": "Andir", "value": "Andir" },
  {
    "id": "327306",
    "regency_id": "3273",
    "label": "Cicendo",
    "value": "Cicendo"
  },
  {
    "id": "327307",
    "regency_id": "3273",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "327308",
    "regency_id": "3273",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "327309",
    "regency_id": "3273",
    "label": "Bandung Wetan",
    "value": "Bandung Wetan"
  },
  {
    "id": "327310",
    "regency_id": "3273",
    "label": "Astana Anyar",
    "value": "Astana Anyar"
  },
  { "id": "327311", "regency_id": "3273", "label": "Regol", "value": "Regol" },
  {
    "id": "327312",
    "regency_id": "3273",
    "label": "Batununggal",
    "value": "Batununggal"
  },
  {
    "id": "327313",
    "regency_id": "3273",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "327314",
    "regency_id": "3273",
    "label": "Cibeunying Kidul",
    "value": "Cibeunying Kidul"
  },
  {
    "id": "327315",
    "regency_id": "3273",
    "label": "Bandung Kulon",
    "value": "Bandung Kulon"
  },
  {
    "id": "327316",
    "regency_id": "3273",
    "label": "Kiaracondong",
    "value": "Kiaracondong"
  },
  {
    "id": "327317",
    "regency_id": "3273",
    "label": "Bojongloa Kidul",
    "value": "Bojongloa Kidul"
  },
  {
    "id": "327318",
    "regency_id": "3273",
    "label": "Cibeunying Kaler",
    "value": "Cibeunying Kaler"
  },
  {
    "id": "327319",
    "regency_id": "3273",
    "label": "Sumur Bandung",
    "value": "Sumur Bandung"
  },
  {
    "id": "327320",
    "regency_id": "3273",
    "label": "Antapani",
    "value": "Antapani"
  },
  {
    "id": "327321",
    "regency_id": "3273",
    "label": "Bandung Kidul",
    "value": "Bandung Kidul"
  },
  {
    "id": "327322",
    "regency_id": "3273",
    "label": "Buahbatu",
    "value": "Buahbatu"
  },
  {
    "id": "327323",
    "regency_id": "3273",
    "label": "Rancasari",
    "value": "Rancasari"
  },
  {
    "id": "327324",
    "regency_id": "3273",
    "label": "Arcamanik",
    "value": "Arcamanik"
  },
  {
    "id": "327325",
    "regency_id": "3273",
    "label": "Cibiru",
    "value": "Cibiru"
  },
  {
    "id": "327326",
    "regency_id": "3273",
    "label": "Ujungberung",
    "value": "Ujungberung"
  },
  {
    "id": "327327",
    "regency_id": "3273",
    "label": "Gedebage",
    "value": "Gedebage"
  },
  {
    "id": "327328",
    "regency_id": "3273",
    "label": "Panyileukan",
    "value": "Panyileukan"
  },
  {
    "id": "327329",
    "regency_id": "3273",
    "label": "Cinambo",
    "value": "Cinambo"
  },
  {
    "id": "327330",
    "regency_id": "3273",
    "label": "Mandalajati",
    "value": "Mandalajati"
  },
  {
    "id": "327401",
    "regency_id": "3274",
    "label": "Kejaksan",
    "value": "Kejaksan"
  },
  {
    "id": "327402",
    "regency_id": "3274",
    "label": "Lemah Wungkuk",
    "value": "Lemah Wungkuk"
  },
  {
    "id": "327403",
    "regency_id": "3274",
    "label": "Harjamukti",
    "value": "Harjamukti"
  },
  {
    "id": "327404",
    "regency_id": "3274",
    "label": "Pekalipan",
    "value": "Pekalipan"
  },
  {
    "id": "327405",
    "regency_id": "3274",
    "label": "Kesambi",
    "value": "Kesambi"
  },
  {
    "id": "327501",
    "regency_id": "3275",
    "label": "Bekasi Timur",
    "value": "Bekasi Timur"
  },
  {
    "id": "327502",
    "regency_id": "3275",
    "label": "Bekasi Barat",
    "value": "Bekasi Barat"
  },
  {
    "id": "327503",
    "regency_id": "3275",
    "label": "Bekasi Utara",
    "value": "Bekasi Utara"
  },
  {
    "id": "327504",
    "regency_id": "3275",
    "label": "Bekasi Selatan",
    "value": "Bekasi Selatan"
  },
  {
    "id": "327505",
    "regency_id": "3275",
    "label": "Rawalumbu",
    "value": "Rawalumbu"
  },
  {
    "id": "327506",
    "regency_id": "3275",
    "label": "Medansatria",
    "value": "Medansatria"
  },
  {
    "id": "327507",
    "regency_id": "3275",
    "label": "Bantargebang",
    "value": "Bantargebang"
  },
  {
    "id": "327508",
    "regency_id": "3275",
    "label": "Pondokgede",
    "value": "Pondokgede"
  },
  {
    "id": "327509",
    "regency_id": "3275",
    "label": "Jatiasih",
    "value": "Jatiasih"
  },
  {
    "id": "327510",
    "regency_id": "3275",
    "label": "Jatisampurna",
    "value": "Jatisampurna"
  },
  {
    "id": "327511",
    "regency_id": "3275",
    "label": "Mustikajaya",
    "value": "Mustikajaya"
  },
  {
    "id": "327512",
    "regency_id": "3275",
    "label": "Pondokmelati",
    "value": "Pondokmelati"
  },
  {
    "id": "327601",
    "regency_id": "3276",
    "label": "Pancoran Mas",
    "value": "Pancoran Mas"
  },
  {
    "id": "327602",
    "regency_id": "3276",
    "label": "Cimanggis",
    "value": "Cimanggis"
  },
  {
    "id": "327603",
    "regency_id": "3276",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  { "id": "327604", "regency_id": "3276", "label": "Limo", "value": "Limo" },
  {
    "id": "327605",
    "regency_id": "3276",
    "label": "Sukmajaya",
    "value": "Sukmajaya"
  },
  { "id": "327606", "regency_id": "3276", "label": "Beji", "value": "Beji" },
  {
    "id": "327607",
    "regency_id": "3276",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "327608",
    "regency_id": "3276",
    "label": "Cilodong",
    "value": "Cilodong"
  },
  {
    "id": "327609",
    "regency_id": "3276",
    "label": "Cinere",
    "value": "Cinere"
  },
  { "id": "327610", "regency_id": "3276", "label": "Tapos", "value": "Tapos" },
  {
    "id": "327611",
    "regency_id": "3276",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "327701",
    "regency_id": "3277",
    "label": "Cimahi Selatan",
    "value": "Cimahi Selatan"
  },
  {
    "id": "327702",
    "regency_id": "3277",
    "label": "Cimahi Tengah",
    "value": "Cimahi Tengah"
  },
  {
    "id": "327703",
    "regency_id": "3277",
    "label": "Cimahi Utara",
    "value": "Cimahi Utara"
  },
  {
    "id": "327801",
    "regency_id": "3278",
    "label": "Cihideung",
    "value": "Cihideung"
  },
  {
    "id": "327802",
    "regency_id": "3278",
    "label": "Cipedes",
    "value": "Cipedes"
  },
  {
    "id": "327803",
    "regency_id": "3278",
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "id": "327804",
    "regency_id": "3278",
    "label": "Indihiang",
    "value": "Indihiang"
  },
  {
    "id": "327805",
    "regency_id": "3278",
    "label": "Kawalu",
    "value": "Kawalu"
  },
  {
    "id": "327806",
    "regency_id": "3278",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "327807",
    "regency_id": "3278",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "327808",
    "regency_id": "3278",
    "label": "Mangkubumi",
    "value": "Mangkubumi"
  },
  {
    "id": "327809",
    "regency_id": "3278",
    "label": "Bungursari",
    "value": "Bungursari"
  },
  {
    "id": "327810",
    "regency_id": "3278",
    "label": "Purbaratu",
    "value": "Purbaratu"
  },
  {
    "id": "327901",
    "regency_id": "3279",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "327902",
    "regency_id": "3279",
    "label": "Pataruman",
    "value": "Pataruman"
  },
  {
    "id": "327903",
    "regency_id": "3279",
    "label": "Purwaharja",
    "value": "Purwaharja"
  },
  {
    "id": "327904",
    "regency_id": "3279",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "330101",
    "regency_id": "3301",
    "label": "Kedungreja",
    "value": "Kedungreja"
  },
  {
    "id": "330102",
    "regency_id": "3301",
    "label": "Kesugihan",
    "value": "Kesugihan"
  },
  {
    "id": "330103",
    "regency_id": "3301",
    "label": "Adipala",
    "value": "Adipala"
  },
  {
    "id": "330104",
    "regency_id": "3301",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "330105",
    "regency_id": "3301",
    "label": "Nusawungu",
    "value": "Nusawungu"
  },
  { "id": "330106", "regency_id": "3301", "label": "Kroya", "value": "Kroya" },
  { "id": "330107", "regency_id": "3301", "label": "Maos", "value": "Maos" },
  {
    "id": "330108",
    "regency_id": "3301",
    "label": "Jeruklegi",
    "value": "Jeruklegi"
  },
  {
    "id": "330109",
    "regency_id": "3301",
    "label": "Kawunganten",
    "value": "Kawunganten"
  },
  {
    "id": "330110",
    "regency_id": "3301",
    "label": "Gandrungmangu",
    "value": "Gandrungmangu"
  },
  {
    "id": "330111",
    "regency_id": "3301",
    "label": "Sidareja",
    "value": "Sidareja"
  },
  {
    "id": "330112",
    "regency_id": "3301",
    "label": "Karangpucung",
    "value": "Karangpucung"
  },
  {
    "id": "330113",
    "regency_id": "3301",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "330114",
    "regency_id": "3301",
    "label": "Majenang",
    "value": "Majenang"
  },
  {
    "id": "330115",
    "regency_id": "3301",
    "label": "Wanareja",
    "value": "Wanareja"
  },
  {
    "id": "330116",
    "regency_id": "3301",
    "label": "Dayeuhluhur",
    "value": "Dayeuhluhur"
  },
  {
    "id": "330117",
    "regency_id": "3301",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "330118",
    "regency_id": "3301",
    "label": "Cipari",
    "value": "Cipari"
  },
  {
    "id": "330119",
    "regency_id": "3301",
    "label": "Patimuan",
    "value": "Patimuan"
  },
  {
    "id": "330120",
    "regency_id": "3301",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "330121",
    "regency_id": "3301",
    "label": "Cilacap Selatan",
    "value": "Cilacap Selatan"
  },
  {
    "id": "330122",
    "regency_id": "3301",
    "label": "Cilacap Tengah",
    "value": "Cilacap Tengah"
  },
  {
    "id": "330123",
    "regency_id": "3301",
    "label": "Cilacap Utara",
    "value": "Cilacap Utara"
  },
  {
    "id": "330124",
    "regency_id": "3301",
    "label": "Kampung Laut",
    "value": "Kampung Laut"
  },
  {
    "id": "330201",
    "regency_id": "3302",
    "label": "Lumbir",
    "value": "Lumbir"
  },
  {
    "id": "330202",
    "regency_id": "3302",
    "label": "Wangon",
    "value": "Wangon"
  },
  {
    "id": "330203",
    "regency_id": "3302",
    "label": "Jatilawang",
    "value": "Jatilawang"
  },
  {
    "id": "330204",
    "regency_id": "3302",
    "label": "Rawalo",
    "value": "Rawalo"
  },
  {
    "id": "330205",
    "regency_id": "3302",
    "label": "Kebasen",
    "value": "Kebasen"
  },
  {
    "id": "330206",
    "regency_id": "3302",
    "label": "Kemranjen",
    "value": "Kemranjen"
  },
  {
    "id": "330207",
    "regency_id": "3302",
    "label": "Sumpiuh",
    "value": "Sumpiuh"
  },
  {
    "id": "330208",
    "regency_id": "3302",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "330209",
    "regency_id": "3302",
    "label": "Somagede",
    "value": "Somagede"
  },
  {
    "id": "330210",
    "regency_id": "3302",
    "label": "Kalibagor",
    "value": "Kalibagor"
  },
  {
    "id": "330211",
    "regency_id": "3302",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "330212",
    "regency_id": "3302",
    "label": "Patikraja",
    "value": "Patikraja"
  },
  {
    "id": "330213",
    "regency_id": "3302",
    "label": "Purwojati",
    "value": "Purwojati"
  },
  {
    "id": "330214",
    "regency_id": "3302",
    "label": "Ajibarang",
    "value": "Ajibarang"
  },
  {
    "id": "330215",
    "regency_id": "3302",
    "label": "Gumelar",
    "value": "Gumelar"
  },
  {
    "id": "330216",
    "regency_id": "3302",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "330217",
    "regency_id": "3302",
    "label": "Cilongok",
    "value": "Cilongok"
  },
  {
    "id": "330218",
    "regency_id": "3302",
    "label": "Karanglewas",
    "value": "Karanglewas"
  },
  {
    "id": "330219",
    "regency_id": "3302",
    "label": "Sokaraja",
    "value": "Sokaraja"
  },
  {
    "id": "330220",
    "regency_id": "3302",
    "label": "Kembaran",
    "value": "Kembaran"
  },
  {
    "id": "330221",
    "regency_id": "3302",
    "label": "Sumbang",
    "value": "Sumbang"
  },
  {
    "id": "330222",
    "regency_id": "3302",
    "label": "Baturraden",
    "value": "Baturraden"
  },
  {
    "id": "330223",
    "regency_id": "3302",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "330224",
    "regency_id": "3302",
    "label": "Purwokerto Selatan",
    "value": "Purwokerto Selatan"
  },
  {
    "id": "330225",
    "regency_id": "3302",
    "label": "Purwokerto Barat",
    "value": "Purwokerto Barat"
  },
  {
    "id": "330226",
    "regency_id": "3302",
    "label": "Purwokerto Timur",
    "value": "Purwokerto Timur"
  },
  {
    "id": "330227",
    "regency_id": "3302",
    "label": "Purwokerto Utara",
    "value": "Purwokerto Utara"
  },
  {
    "id": "330301",
    "regency_id": "3303",
    "label": "Kemangkon",
    "value": "Kemangkon"
  },
  {
    "id": "330302",
    "regency_id": "3303",
    "label": "Bukateja",
    "value": "Bukateja"
  },
  {
    "id": "330303",
    "regency_id": "3303",
    "label": "Kejobong",
    "value": "Kejobong"
  },
  {
    "id": "330304",
    "regency_id": "3303",
    "label": "Kaligondang",
    "value": "Kaligondang"
  },
  {
    "id": "330305",
    "regency_id": "3303",
    "label": "Purbalingga",
    "value": "Purbalingga"
  },
  {
    "id": "330306",
    "regency_id": "3303",
    "label": "Kalimanah",
    "value": "Kalimanah"
  },
  {
    "id": "330307",
    "regency_id": "3303",
    "label": "Kutasari",
    "value": "Kutasari"
  },
  {
    "id": "330308",
    "regency_id": "3303",
    "label": "Mrebet",
    "value": "Mrebet"
  },
  {
    "id": "330309",
    "regency_id": "3303",
    "label": "Bobotsari",
    "value": "Bobotsari"
  },
  {
    "id": "330310",
    "regency_id": "3303",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "330311",
    "regency_id": "3303",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "330312",
    "regency_id": "3303",
    "label": "Karangmoncol",
    "value": "Karangmoncol"
  },
  {
    "id": "330313",
    "regency_id": "3303",
    "label": "Rembang",
    "value": "Rembang"
  },
  {
    "id": "330314",
    "regency_id": "3303",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "330315",
    "regency_id": "3303",
    "label": "Padamara",
    "value": "Padamara"
  },
  {
    "id": "330316",
    "regency_id": "3303",
    "label": "Pengadegan",
    "value": "Pengadegan"
  },
  {
    "id": "330317",
    "regency_id": "3303",
    "label": "Karangjambu",
    "value": "Karangjambu"
  },
  {
    "id": "330318",
    "regency_id": "3303",
    "label": "Kertanegara",
    "value": "Kertanegara"
  },
  {
    "id": "330401",
    "regency_id": "3304",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "330402",
    "regency_id": "3304",
    "label": "Purworeja Klampok",
    "value": "Purworeja Klampok"
  },
  {
    "id": "330403",
    "regency_id": "3304",
    "label": "Mandiraja",
    "value": "Mandiraja"
  },
  {
    "id": "330404",
    "regency_id": "3304",
    "label": "Purwanegara",
    "value": "Purwanegara"
  },
  {
    "id": "330405",
    "regency_id": "3304",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "330406",
    "regency_id": "3304",
    "label": "Banjarnegara",
    "value": "Banjarnegara"
  },
  {
    "id": "330407",
    "regency_id": "3304",
    "label": "Sigaluh",
    "value": "Sigaluh"
  },
  {
    "id": "330408",
    "regency_id": "3304",
    "label": "Madukara",
    "value": "Madukara"
  },
  {
    "id": "330409",
    "regency_id": "3304",
    "label": "Banjarmangu",
    "value": "Banjarmangu"
  },
  {
    "id": "330410",
    "regency_id": "3304",
    "label": "Wanadadi",
    "value": "Wanadadi"
  },
  { "id": "330411", "regency_id": "3304", "label": "Rakit", "value": "Rakit" },
  {
    "id": "330412",
    "regency_id": "3304",
    "label": "Punggelan",
    "value": "Punggelan"
  },
  {
    "id": "330413",
    "regency_id": "3304",
    "label": "Karangkobar",
    "value": "Karangkobar"
  },
  {
    "id": "330414",
    "regency_id": "3304",
    "label": "Pagentan",
    "value": "Pagentan"
  },
  {
    "id": "330415",
    "regency_id": "3304",
    "label": "Pejawaran",
    "value": "Pejawaran"
  },
  { "id": "330416", "regency_id": "3304", "label": "Batur", "value": "Batur" },
  {
    "id": "330417",
    "regency_id": "3304",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "330418",
    "regency_id": "3304",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "330419",
    "regency_id": "3304",
    "label": "Pandanarum",
    "value": "Pandanarum"
  },
  {
    "id": "330420",
    "regency_id": "3304",
    "label": "Pagedongan",
    "value": "Pagedongan"
  },
  { "id": "330501", "regency_id": "3305", "label": "Ayah", "value": "Ayah" },
  {
    "id": "330502",
    "regency_id": "3305",
    "label": "Buayan",
    "value": "Buayan"
  },
  {
    "id": "330503",
    "regency_id": "3305",
    "label": "Puring",
    "value": "Puring"
  },
  {
    "id": "330504",
    "regency_id": "3305",
    "label": "Petanahan",
    "value": "Petanahan"
  },
  {
    "id": "330505",
    "regency_id": "3305",
    "label": "Klirong",
    "value": "Klirong"
  },
  {
    "id": "330506",
    "regency_id": "3305",
    "label": "Buluspesantren",
    "value": "Buluspesantren"
  },
  { "id": "330507", "regency_id": "3305", "label": "Ambal", "value": "Ambal" },
  { "id": "330508", "regency_id": "3305", "label": "Mirit", "value": "Mirit" },
  {
    "id": "330509",
    "regency_id": "3305",
    "label": "Prembun",
    "value": "Prembun"
  },
  {
    "id": "330510",
    "regency_id": "3305",
    "label": "Kutowinangun",
    "value": "Kutowinangun"
  },
  { "id": "330511", "regency_id": "3305", "label": "Alian", "value": "Alian" },
  {
    "id": "330512",
    "regency_id": "3305",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "330513",
    "regency_id": "3305",
    "label": "Pejagoan",
    "value": "Pejagoan"
  },
  {
    "id": "330514",
    "regency_id": "3305",
    "label": "Sruweng",
    "value": "Sruweng"
  },
  {
    "id": "330515",
    "regency_id": "3305",
    "label": "Adimulyo",
    "value": "Adimulyo"
  },
  {
    "id": "330516",
    "regency_id": "3305",
    "label": "Kuwarasan",
    "value": "Kuwarasan"
  },
  {
    "id": "330517",
    "regency_id": "3305",
    "label": "Rowokele",
    "value": "Rowokele"
  },
  {
    "id": "330518",
    "regency_id": "3305",
    "label": "Sempor",
    "value": "Sempor"
  },
  {
    "id": "330519",
    "regency_id": "3305",
    "label": "Gombong",
    "value": "Gombong"
  },
  {
    "id": "330520",
    "regency_id": "3305",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "330521",
    "regency_id": "3305",
    "label": "Karanggayam",
    "value": "Karanggayam"
  },
  {
    "id": "330522",
    "regency_id": "3305",
    "label": "Sadang",
    "value": "Sadang"
  },
  {
    "id": "330523",
    "regency_id": "3305",
    "label": "Bonorowo",
    "value": "Bonorowo"
  },
  {
    "id": "330524",
    "regency_id": "3305",
    "label": "Padureso",
    "value": "Padureso"
  },
  {
    "id": "330525",
    "regency_id": "3305",
    "label": "Poncowarno",
    "value": "Poncowarno"
  },
  {
    "id": "330526",
    "regency_id": "3305",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "330601",
    "regency_id": "3306",
    "label": "Grabag",
    "value": "Grabag"
  },
  {
    "id": "330602",
    "regency_id": "3306",
    "label": "Ngombol",
    "value": "Ngombol"
  },
  {
    "id": "330603",
    "regency_id": "3306",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "330604",
    "regency_id": "3306",
    "label": "Bagelen",
    "value": "Bagelen"
  },
  {
    "id": "330605",
    "regency_id": "3306",
    "label": "Kaligesing",
    "value": "Kaligesing"
  },
  {
    "id": "330606",
    "regency_id": "3306",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "330607",
    "regency_id": "3306",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  { "id": "330608", "regency_id": "3306", "label": "Bayan", "value": "Bayan" },
  {
    "id": "330609",
    "regency_id": "3306",
    "label": "Kutoarjo",
    "value": "Kutoarjo"
  },
  { "id": "330610", "regency_id": "3306", "label": "Butuh", "value": "Butuh" },
  {
    "id": "330611",
    "regency_id": "3306",
    "label": "Pituruh",
    "value": "Pituruh"
  },
  {
    "id": "330612",
    "regency_id": "3306",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  { "id": "330613", "regency_id": "3306", "label": "Bruno", "value": "Bruno" },
  {
    "id": "330614",
    "regency_id": "3306",
    "label": "Gebang",
    "value": "Gebang"
  },
  { "id": "330615", "regency_id": "3306", "label": "Loano", "value": "Loano" },
  { "id": "330616", "regency_id": "3306", "label": "Bener", "value": "Bener" },
  {
    "id": "330701",
    "regency_id": "3307",
    "label": "Wadaslintang",
    "value": "Wadaslintang"
  },
  { "id": "330702", "regency_id": "3307", "label": "Kepil", "value": "Kepil" },
  {
    "id": "330703",
    "regency_id": "3307",
    "label": "Sapuran",
    "value": "Sapuran"
  },
  {
    "id": "330704",
    "regency_id": "3307",
    "label": "Kaliwiro",
    "value": "Kaliwiro"
  },
  {
    "id": "330705",
    "regency_id": "3307",
    "label": "Leksono",
    "value": "Leksono"
  },
  {
    "id": "330706",
    "regency_id": "3307",
    "label": "Selomerto",
    "value": "Selomerto"
  },
  {
    "id": "330707",
    "regency_id": "3307",
    "label": "Kalikajar",
    "value": "Kalikajar"
  },
  {
    "id": "330708",
    "regency_id": "3307",
    "label": "Kertek",
    "value": "Kertek"
  },
  {
    "id": "330709",
    "regency_id": "3307",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "330710",
    "regency_id": "3307",
    "label": "Watumalang",
    "value": "Watumalang"
  },
  {
    "id": "330711",
    "regency_id": "3307",
    "label": "Mojotengah",
    "value": "Mojotengah"
  },
  {
    "id": "330712",
    "regency_id": "3307",
    "label": "Garung",
    "value": "Garung"
  },
  {
    "id": "330713",
    "regency_id": "3307",
    "label": "Kejajar",
    "value": "Kejajar"
  },
  {
    "id": "330714",
    "regency_id": "3307",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "330715",
    "regency_id": "3307",
    "label": "Kalibawang",
    "value": "Kalibawang"
  },
  {
    "id": "330801",
    "regency_id": "3308",
    "label": "Salaman",
    "value": "Salaman"
  },
  {
    "id": "330802",
    "regency_id": "3308",
    "label": "Borobudur",
    "value": "Borobudur"
  },
  {
    "id": "330803",
    "regency_id": "3308",
    "label": "Ngluwar",
    "value": "Ngluwar"
  },
  { "id": "330804", "regency_id": "3308", "label": "Salam", "value": "Salam" },
  {
    "id": "330805",
    "regency_id": "3308",
    "label": "Srumbung",
    "value": "Srumbung"
  },
  { "id": "330806", "regency_id": "3308", "label": "Dukun", "value": "Dukun" },
  {
    "id": "330807",
    "regency_id": "3308",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "330808",
    "regency_id": "3308",
    "label": "Muntilan",
    "value": "Muntilan"
  },
  {
    "id": "330809",
    "regency_id": "3308",
    "label": "Mungkid",
    "value": "Mungkid"
  },
  {
    "id": "330810",
    "regency_id": "3308",
    "label": "Mertoyudan",
    "value": "Mertoyudan"
  },
  {
    "id": "330811",
    "regency_id": "3308",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "330812",
    "regency_id": "3308",
    "label": "Kajoran",
    "value": "Kajoran"
  },
  {
    "id": "330813",
    "regency_id": "3308",
    "label": "Kaliangkrik",
    "value": "Kaliangkrik"
  },
  {
    "id": "330814",
    "regency_id": "3308",
    "label": "Bandongan",
    "value": "Bandongan"
  },
  {
    "id": "330815",
    "regency_id": "3308",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  { "id": "330816", "regency_id": "3308", "label": "Pakis", "value": "Pakis" },
  {
    "id": "330817",
    "regency_id": "3308",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "330818",
    "regency_id": "3308",
    "label": "Grabag",
    "value": "Grabag"
  },
  {
    "id": "330819",
    "regency_id": "3308",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "330820",
    "regency_id": "3308",
    "label": "Secang",
    "value": "Secang"
  },
  {
    "id": "330821",
    "regency_id": "3308",
    "label": "Windusari",
    "value": "Windusari"
  },
  { "id": "330901", "regency_id": "3309", "label": "Selo", "value": "Selo" },
  { "id": "330902", "regency_id": "3309", "label": "Ampel", "value": "Ampel" },
  {
    "id": "330903",
    "regency_id": "3309",
    "label": "Cepogo",
    "value": "Cepogo"
  },
  { "id": "330904", "regency_id": "3309", "label": "Musuk", "value": "Musuk" },
  {
    "id": "330905",
    "regency_id": "3309",
    "label": "Boyolali",
    "value": "Boyolali"
  },
  {
    "id": "330906",
    "regency_id": "3309",
    "label": "Mojosongo",
    "value": "Mojosongo"
  },
  { "id": "330907", "regency_id": "3309", "label": "Teras", "value": "Teras" },
  { "id": "330908", "regency_id": "3309", "label": "Sawit", "value": "Sawit" },
  {
    "id": "330909",
    "regency_id": "3309",
    "label": "Banyudono",
    "value": "Banyudono"
  },
  { "id": "330910", "regency_id": "3309", "label": "Sambi", "value": "Sambi" },
  {
    "id": "330911",
    "regency_id": "3309",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "330912",
    "regency_id": "3309",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  { "id": "330913", "regency_id": "3309", "label": "Simo", "value": "Simo" },
  {
    "id": "330914",
    "regency_id": "3309",
    "label": "Karanggede",
    "value": "Karanggede"
  },
  { "id": "330915", "regency_id": "3309", "label": "Klego", "value": "Klego" },
  {
    "id": "330916",
    "regency_id": "3309",
    "label": "Andong",
    "value": "Andong"
  },
  {
    "id": "330917",
    "regency_id": "3309",
    "label": "Kemusu",
    "value": "Kemusu"
  },
  {
    "id": "330918",
    "regency_id": "3309",
    "label": "Wonosegoro",
    "value": "Wonosegoro"
  },
  {
    "id": "330919",
    "regency_id": "3309",
    "label": "Juwangi",
    "value": "Juwangi"
  },
  {
    "id": "330920",
    "regency_id": "3309",
    "label": "Gladagsari",
    "value": "Gladagsari"
  },
  {
    "id": "330921",
    "regency_id": "3309",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "330922",
    "regency_id": "3309",
    "label": "Wonosamodro",
    "value": "Wonosamodro"
  },
  {
    "id": "331001",
    "regency_id": "3310",
    "label": "Prambanan",
    "value": "Prambanan"
  },
  {
    "id": "331002",
    "regency_id": "3310",
    "label": "Gantiwarno",
    "value": "Gantiwarno"
  },
  { "id": "331003", "regency_id": "3310", "label": "Wedi", "value": "Wedi" },
  { "id": "331004", "regency_id": "3310", "label": "Bayat", "value": "Bayat" },
  { "id": "331005", "regency_id": "3310", "label": "Cawas", "value": "Cawas" },
  {
    "id": "331006",
    "regency_id": "3310",
    "label": "Trucuk",
    "value": "Trucuk"
  },
  {
    "id": "331007",
    "regency_id": "3310",
    "label": "Kebonarum",
    "value": "Kebonarum"
  },
  {
    "id": "331008",
    "regency_id": "3310",
    "label": "Jogonalan",
    "value": "Jogonalan"
  },
  {
    "id": "331009",
    "regency_id": "3310",
    "label": "Manisrenggo",
    "value": "Manisrenggo"
  },
  {
    "id": "331010",
    "regency_id": "3310",
    "label": "Karangnongko",
    "value": "Karangnongko"
  },
  { "id": "331011", "regency_id": "3310", "label": "Ceper", "value": "Ceper" },
  { "id": "331012", "regency_id": "3310", "label": "Pedan", "value": "Pedan" },
  {
    "id": "331013",
    "regency_id": "3310",
    "label": "Karangdowo",
    "value": "Karangdowo"
  },
  {
    "id": "331014",
    "regency_id": "3310",
    "label": "Juwiring",
    "value": "Juwiring"
  },
  {
    "id": "331015",
    "regency_id": "3310",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "331016",
    "regency_id": "3310",
    "label": "Delanggu",
    "value": "Delanggu"
  },
  {
    "id": "331017",
    "regency_id": "3310",
    "label": "Polanharjo",
    "value": "Polanharjo"
  },
  {
    "id": "331018",
    "regency_id": "3310",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "331019",
    "regency_id": "3310",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "331020",
    "regency_id": "3310",
    "label": "Jatinom",
    "value": "Jatinom"
  },
  {
    "id": "331021",
    "regency_id": "3310",
    "label": "Kemalang",
    "value": "Kemalang"
  },
  {
    "id": "331022",
    "regency_id": "3310",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "331023",
    "regency_id": "3310",
    "label": "Kalikotes",
    "value": "Kalikotes"
  },
  {
    "id": "331024",
    "regency_id": "3310",
    "label": "Klaten Utara",
    "value": "Klaten Utara"
  },
  {
    "id": "331025",
    "regency_id": "3310",
    "label": "Klaten Tengah",
    "value": "Klaten Tengah"
  },
  {
    "id": "331026",
    "regency_id": "3310",
    "label": "Klaten Selatan",
    "value": "Klaten Selatan"
  },
  { "id": "331101", "regency_id": "3311", "label": "Weru", "value": "Weru" },
  { "id": "331102", "regency_id": "3311", "label": "Bulu", "value": "Bulu" },
  {
    "id": "331103",
    "regency_id": "3311",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "331104",
    "regency_id": "3311",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "331105",
    "regency_id": "3311",
    "label": "Nguter",
    "value": "Nguter"
  },
  {
    "id": "331106",
    "regency_id": "3311",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "331107",
    "regency_id": "3311",
    "label": "Polokarto",
    "value": "Polokarto"
  },
  {
    "id": "331108",
    "regency_id": "3311",
    "label": "Mojolaban",
    "value": "Mojolaban"
  },
  {
    "id": "331109",
    "regency_id": "3311",
    "label": "Grogol",
    "value": "Grogol"
  },
  { "id": "331110", "regency_id": "3311", "label": "Baki", "value": "Baki" },
  { "id": "331111", "regency_id": "3311", "label": "Gatak", "value": "Gatak" },
  {
    "id": "331112",
    "regency_id": "3311",
    "label": "Kartasura",
    "value": "Kartasura"
  },
  {
    "id": "331201",
    "regency_id": "3312",
    "label": "Pracimantoro",
    "value": "Pracimantoro"
  },
  {
    "id": "331202",
    "regency_id": "3312",
    "label": "Giritontro",
    "value": "Giritontro"
  },
  {
    "id": "331203",
    "regency_id": "3312",
    "label": "Giriwoyo",
    "value": "Giriwoyo"
  },
  {
    "id": "331204",
    "regency_id": "3312",
    "label": "Batuwarno",
    "value": "Batuwarno"
  },
  {
    "id": "331205",
    "regency_id": "3312",
    "label": "Tirtomoyo",
    "value": "Tirtomoyo"
  },
  {
    "id": "331206",
    "regency_id": "3312",
    "label": "Nguntoronadi",
    "value": "Nguntoronadi"
  },
  {
    "id": "331207",
    "regency_id": "3312",
    "label": "Baturetno",
    "value": "Baturetno"
  },
  {
    "id": "331208",
    "regency_id": "3312",
    "label": "Eromoko",
    "value": "Eromoko"
  },
  {
    "id": "331209",
    "regency_id": "3312",
    "label": "Wuryantoro",
    "value": "Wuryantoro"
  },
  {
    "id": "331210",
    "regency_id": "3312",
    "label": "Manyaran",
    "value": "Manyaran"
  },
  {
    "id": "331211",
    "regency_id": "3312",
    "label": "Selogiri",
    "value": "Selogiri"
  },
  {
    "id": "331212",
    "regency_id": "3312",
    "label": "Wonogiri",
    "value": "Wonogiri"
  },
  {
    "id": "331213",
    "regency_id": "3312",
    "label": "Ngadirojo",
    "value": "Ngadirojo"
  },
  {
    "id": "331214",
    "regency_id": "3312",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "331215",
    "regency_id": "3312",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "331216",
    "regency_id": "3312",
    "label": "Kismantoro",
    "value": "Kismantoro"
  },
  {
    "id": "331217",
    "regency_id": "3312",
    "label": "Purwantoro",
    "value": "Purwantoro"
  },
  {
    "id": "331218",
    "regency_id": "3312",
    "label": "Bulukerto",
    "value": "Bulukerto"
  },
  {
    "id": "331219",
    "regency_id": "3312",
    "label": "Slogohimo",
    "value": "Slogohimo"
  },
  {
    "id": "331220",
    "regency_id": "3312",
    "label": "Jatisrono",
    "value": "Jatisrono"
  },
  {
    "id": "331221",
    "regency_id": "3312",
    "label": "Jatipurno",
    "value": "Jatipurno"
  },
  {
    "id": "331222",
    "regency_id": "3312",
    "label": "Girimarto",
    "value": "Girimarto"
  },
  {
    "id": "331223",
    "regency_id": "3312",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "331224",
    "regency_id": "3312",
    "label": "Paranggupito",
    "value": "Paranggupito"
  },
  {
    "id": "331225",
    "regency_id": "3312",
    "label": "Puhpelem",
    "value": "Puhpelem"
  },
  {
    "id": "331301",
    "regency_id": "3313",
    "label": "Jatipuro",
    "value": "Jatipuro"
  },
  {
    "id": "331302",
    "regency_id": "3313",
    "label": "Jatiyoso",
    "value": "Jatiyoso"
  },
  {
    "id": "331303",
    "regency_id": "3313",
    "label": "Jumapolo",
    "value": "Jumapolo"
  },
  {
    "id": "331304",
    "regency_id": "3313",
    "label": "Jumantono",
    "value": "Jumantono"
  },
  {
    "id": "331305",
    "regency_id": "3313",
    "label": "Matesih",
    "value": "Matesih"
  },
  {
    "id": "331306",
    "regency_id": "3313",
    "label": "Tawangmangu",
    "value": "Tawangmangu"
  },
  {
    "id": "331307",
    "regency_id": "3313",
    "label": "Ngargoyoso",
    "value": "Ngargoyoso"
  },
  {
    "id": "331308",
    "regency_id": "3313",
    "label": "Karangpandan",
    "value": "Karangpandan"
  },
  {
    "id": "331309",
    "regency_id": "3313",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "331310",
    "regency_id": "3313",
    "label": "Tasikmadu",
    "value": "Tasikmadu"
  },
  { "id": "331311", "regency_id": "3313", "label": "Jaten", "value": "Jaten" },
  {
    "id": "331312",
    "regency_id": "3313",
    "label": "Colomadu",
    "value": "Colomadu"
  },
  {
    "id": "331313",
    "regency_id": "3313",
    "label": "Gondangrejo",
    "value": "Gondangrejo"
  },
  {
    "id": "331314",
    "regency_id": "3313",
    "label": "Kebakkramat",
    "value": "Kebakkramat"
  },
  {
    "id": "331315",
    "regency_id": "3313",
    "label": "Mojogedang",
    "value": "Mojogedang"
  },
  { "id": "331316", "regency_id": "3313", "label": "Kerjo", "value": "Kerjo" },
  {
    "id": "331317",
    "regency_id": "3313",
    "label": "Jenawi",
    "value": "Jenawi"
  },
  {
    "id": "331401",
    "regency_id": "3314",
    "label": "Kalijambe",
    "value": "Kalijambe"
  },
  {
    "id": "331402",
    "regency_id": "3314",
    "label": "Plupuh",
    "value": "Plupuh"
  },
  {
    "id": "331403",
    "regency_id": "3314",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "331404",
    "regency_id": "3314",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "331405",
    "regency_id": "3314",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "331406",
    "regency_id": "3314",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "331407",
    "regency_id": "3314",
    "label": "Sambungmacan",
    "value": "Sambungmacan"
  },
  {
    "id": "331408",
    "regency_id": "3314",
    "label": "Ngrampal",
    "value": "Ngrampal"
  },
  {
    "id": "331409",
    "regency_id": "3314",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "331410",
    "regency_id": "3314",
    "label": "Sragen",
    "value": "Sragen"
  },
  {
    "id": "331411",
    "regency_id": "3314",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  { "id": "331412", "regency_id": "3314", "label": "Tanon", "value": "Tanon" },
  {
    "id": "331413",
    "regency_id": "3314",
    "label": "Gemolong",
    "value": "Gemolong"
  },
  { "id": "331414", "regency_id": "3314", "label": "Miri", "value": "Miri" },
  {
    "id": "331415",
    "regency_id": "3314",
    "label": "Sumberlawang",
    "value": "Sumberlawang"
  },
  {
    "id": "331416",
    "regency_id": "3314",
    "label": "Mondokan",
    "value": "Mondokan"
  },
  {
    "id": "331417",
    "regency_id": "3314",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  { "id": "331418", "regency_id": "3314", "label": "Gesi", "value": "Gesi" },
  {
    "id": "331419",
    "regency_id": "3314",
    "label": "Tangen",
    "value": "Tangen"
  },
  { "id": "331420", "regency_id": "3314", "label": "Jenar", "value": "Jenar" },
  {
    "id": "331501",
    "regency_id": "3315",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "331502",
    "regency_id": "3315",
    "label": "Karangrayung",
    "value": "Karangrayung"
  },
  {
    "id": "331503",
    "regency_id": "3315",
    "label": "Penawangan",
    "value": "Penawangan"
  },
  { "id": "331504", "regency_id": "3315", "label": "Toroh", "value": "Toroh" },
  { "id": "331505", "regency_id": "3315", "label": "Geyer", "value": "Geyer" },
  {
    "id": "331506",
    "regency_id": "3315",
    "label": "Pulokulon",
    "value": "Pulokulon"
  },
  {
    "id": "331507",
    "regency_id": "3315",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  { "id": "331508", "regency_id": "3315", "label": "Gabus", "value": "Gabus" },
  {
    "id": "331509",
    "regency_id": "3315",
    "label": "Ngaringan",
    "value": "Ngaringan"
  },
  {
    "id": "331510",
    "regency_id": "3315",
    "label": "Wirosari",
    "value": "Wirosari"
  },
  {
    "id": "331511",
    "regency_id": "3315",
    "label": "Tawangharjo",
    "value": "Tawangharjo"
  },
  {
    "id": "331512",
    "regency_id": "3315",
    "label": "Grobogan",
    "value": "Grobogan"
  },
  {
    "id": "331513",
    "regency_id": "3315",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  { "id": "331514", "regency_id": "3315", "label": "Brati", "value": "Brati" },
  {
    "id": "331515",
    "regency_id": "3315",
    "label": "Klambu",
    "value": "Klambu"
  },
  {
    "id": "331516",
    "regency_id": "3315",
    "label": "Godong",
    "value": "Godong"
  },
  { "id": "331517", "regency_id": "3315", "label": "Gubug", "value": "Gubug" },
  {
    "id": "331518",
    "regency_id": "3315",
    "label": "Tegowanu",
    "value": "Tegowanu"
  },
  {
    "id": "331519",
    "regency_id": "3315",
    "label": "Tanggungharjo",
    "value": "Tanggungharjo"
  },
  { "id": "331601", "regency_id": "3316", "label": "Jati", "value": "Jati" },
  {
    "id": "331602",
    "regency_id": "3316",
    "label": "Randublatung",
    "value": "Randublatung"
  },
  {
    "id": "331603",
    "regency_id": "3316",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "331604",
    "regency_id": "3316",
    "label": "Kedungtuban",
    "value": "Kedungtuban"
  },
  { "id": "331605", "regency_id": "3316", "label": "Cepu", "value": "Cepu" },
  {
    "id": "331606",
    "regency_id": "3316",
    "label": "Sambong",
    "value": "Sambong"
  },
  { "id": "331607", "regency_id": "3316", "label": "Jiken", "value": "Jiken" },
  { "id": "331608", "regency_id": "3316", "label": "Jepon", "value": "Jepon" },
  { "id": "331609", "regency_id": "3316", "label": "Blora", "value": "Blora" },
  {
    "id": "331610",
    "regency_id": "3316",
    "label": "Tunjungan",
    "value": "Tunjungan"
  },
  {
    "id": "331611",
    "regency_id": "3316",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "331612",
    "regency_id": "3316",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "331613",
    "regency_id": "3316",
    "label": "Kunduran",
    "value": "Kunduran"
  },
  {
    "id": "331614",
    "regency_id": "3316",
    "label": "Todanan",
    "value": "Todanan"
  },
  {
    "id": "331615",
    "regency_id": "3316",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  { "id": "331616", "regency_id": "3316", "label": "Japah", "value": "Japah" },
  {
    "id": "331701",
    "regency_id": "3317",
    "label": "Sumber",
    "value": "Sumber"
  },
  { "id": "331702", "regency_id": "3317", "label": "Bulu", "value": "Bulu" },
  { "id": "331703", "regency_id": "3317", "label": "Gunem", "value": "Gunem" },
  { "id": "331704", "regency_id": "3317", "label": "Sale", "value": "Sale" },
  {
    "id": "331705",
    "regency_id": "3317",
    "label": "Sarang",
    "value": "Sarang"
  },
  { "id": "331706", "regency_id": "3317", "label": "Sedan", "value": "Sedan" },
  {
    "id": "331707",
    "regency_id": "3317",
    "label": "Pamotan",
    "value": "Pamotan"
  },
  {
    "id": "331708",
    "regency_id": "3317",
    "label": "Sulang",
    "value": "Sulang"
  },
  {
    "id": "331709",
    "regency_id": "3317",
    "label": "Kaliori",
    "value": "Kaliori"
  },
  {
    "id": "331710",
    "regency_id": "3317",
    "label": "Rembang",
    "value": "Rembang"
  },
  {
    "id": "331711",
    "regency_id": "3317",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "331712",
    "regency_id": "3317",
    "label": "Kragan",
    "value": "Kragan"
  },
  { "id": "331713", "regency_id": "3317", "label": "Sluke", "value": "Sluke" },
  { "id": "331714", "regency_id": "3317", "label": "Lasem", "value": "Lasem" },
  {
    "id": "331801",
    "regency_id": "3318",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  { "id": "331802", "regency_id": "3318", "label": "Kayen", "value": "Kayen" },
  {
    "id": "331803",
    "regency_id": "3318",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "331804",
    "regency_id": "3318",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "331805",
    "regency_id": "3318",
    "label": "Pucakwangi",
    "value": "Pucakwangi"
  },
  { "id": "331806", "regency_id": "3318", "label": "Jaken", "value": "Jaken" },
  {
    "id": "331807",
    "regency_id": "3318",
    "label": "Batangan",
    "value": "Batangan"
  },
  {
    "id": "331808",
    "regency_id": "3318",
    "label": "Juwana",
    "value": "Juwana"
  },
  {
    "id": "331809",
    "regency_id": "3318",
    "label": "Jakenan",
    "value": "Jakenan"
  },
  { "id": "331810", "regency_id": "3318", "label": "Pati", "value": "Pati" },
  { "id": "331811", "regency_id": "3318", "label": "Gabus", "value": "Gabus" },
  {
    "id": "331812",
    "regency_id": "3318",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "331813",
    "regency_id": "3318",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "331814",
    "regency_id": "3318",
    "label": "Tlogowungu",
    "value": "Tlogowungu"
  },
  {
    "id": "331815",
    "regency_id": "3318",
    "label": "Wedarijaksa",
    "value": "Wedarijaksa"
  },
  {
    "id": "331816",
    "regency_id": "3318",
    "label": "Margoyoso",
    "value": "Margoyoso"
  },
  {
    "id": "331817",
    "regency_id": "3318",
    "label": "Gunungwungkal",
    "value": "Gunungwungkal"
  },
  {
    "id": "331818",
    "regency_id": "3318",
    "label": "Cluwak",
    "value": "Cluwak"
  },
  { "id": "331819", "regency_id": "3318", "label": "Tayu", "value": "Tayu" },
  {
    "id": "331820",
    "regency_id": "3318",
    "label": "Dukuhseti",
    "value": "Dukuhseti"
  },
  {
    "id": "331821",
    "regency_id": "3318",
    "label": "Trangkil",
    "value": "Trangkil"
  },
  {
    "id": "331901",
    "regency_id": "3319",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "331902",
    "regency_id": "3319",
    "label": "Kota Kudus",
    "value": "Kota Kudus"
  },
  { "id": "331903", "regency_id": "3319", "label": "Jati", "value": "Jati" },
  {
    "id": "331904",
    "regency_id": "3319",
    "label": "Undaan",
    "value": "Undaan"
  },
  {
    "id": "331905",
    "regency_id": "3319",
    "label": "Mejobo",
    "value": "Mejobo"
  },
  {
    "id": "331906",
    "regency_id": "3319",
    "label": "Jekulo",
    "value": "Jekulo"
  },
  { "id": "331907", "regency_id": "3319", "label": "Bae", "value": "Bae" },
  { "id": "331908", "regency_id": "3319", "label": "Gebog", "value": "Gebog" },
  { "id": "331909", "regency_id": "3319", "label": "Dawe", "value": "Dawe" },
  {
    "id": "332001",
    "regency_id": "3320",
    "label": "Kedung",
    "value": "Kedung"
  },
  {
    "id": "332002",
    "regency_id": "3320",
    "label": "Pecangaan",
    "value": "Pecangaan"
  },
  {
    "id": "332003",
    "regency_id": "3320",
    "label": "Welahan",
    "value": "Welahan"
  },
  {
    "id": "332004",
    "regency_id": "3320",
    "label": "Mayong",
    "value": "Mayong"
  },
  {
    "id": "332005",
    "regency_id": "3320",
    "label": "Batealit",
    "value": "Batealit"
  },
  {
    "id": "332006",
    "regency_id": "3320",
    "label": "Jepara",
    "value": "Jepara"
  },
  {
    "id": "332007",
    "regency_id": "3320",
    "label": "Mlonggo",
    "value": "Mlonggo"
  },
  {
    "id": "332008",
    "regency_id": "3320",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "332009",
    "regency_id": "3320",
    "label": "Keling",
    "value": "Keling"
  },
  {
    "id": "332010",
    "regency_id": "3320",
    "label": "Karimun Jawa",
    "value": "Karimun Jawa"
  },
  {
    "id": "332011",
    "regency_id": "3320",
    "label": "Tahunan",
    "value": "Tahunan"
  },
  {
    "id": "332012",
    "regency_id": "3320",
    "label": "Nalumsari",
    "value": "Nalumsari"
  },
  {
    "id": "332013",
    "regency_id": "3320",
    "label": "Kalinyamatan",
    "value": "Kalinyamatan"
  },
  {
    "id": "332014",
    "regency_id": "3320",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "332015",
    "regency_id": "3320",
    "label": "Pakis Aji",
    "value": "Pakis Aji"
  },
  {
    "id": "332016",
    "regency_id": "3320",
    "label": "Donorojo",
    "value": "Donorojo"
  },
  {
    "id": "332101",
    "regency_id": "3321",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "332102",
    "regency_id": "3321",
    "label": "Karangawen",
    "value": "Karangawen"
  },
  {
    "id": "332103",
    "regency_id": "3321",
    "label": "Guntur",
    "value": "Guntur"
  },
  {
    "id": "332104",
    "regency_id": "3321",
    "label": "Sayung",
    "value": "Sayung"
  },
  {
    "id": "332105",
    "regency_id": "3321",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "332106",
    "regency_id": "3321",
    "label": "Wonosalam",
    "value": "Wonosalam"
  },
  {
    "id": "332107",
    "regency_id": "3321",
    "label": "Dempet",
    "value": "Dempet"
  },
  { "id": "332108", "regency_id": "3321", "label": "Gajah", "value": "Gajah" },
  {
    "id": "332109",
    "regency_id": "3321",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  { "id": "332110", "regency_id": "3321", "label": "Mijen", "value": "Mijen" },
  { "id": "332111", "regency_id": "3321", "label": "Demak", "value": "Demak" },
  {
    "id": "332112",
    "regency_id": "3321",
    "label": "Bonang",
    "value": "Bonang"
  },
  {
    "id": "332113",
    "regency_id": "3321",
    "label": "Wedung",
    "value": "Wedung"
  },
  {
    "id": "332114",
    "regency_id": "3321",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "332201",
    "regency_id": "3322",
    "label": "Getasan",
    "value": "Getasan"
  },
  {
    "id": "332202",
    "regency_id": "3322",
    "label": "Tengaran",
    "value": "Tengaran"
  },
  {
    "id": "332203",
    "regency_id": "3322",
    "label": "Susukan",
    "value": "Susukan"
  },
  { "id": "332204", "regency_id": "3322", "label": "Suruh", "value": "Suruh" },
  {
    "id": "332205",
    "regency_id": "3322",
    "label": "Pabelan",
    "value": "Pabelan"
  },
  {
    "id": "332206",
    "regency_id": "3322",
    "label": "Tuntang",
    "value": "Tuntang"
  },
  {
    "id": "332207",
    "regency_id": "3322",
    "label": "Banyubiru",
    "value": "Banyubiru"
  },
  { "id": "332208", "regency_id": "3322", "label": "Jambu", "value": "Jambu" },
  {
    "id": "332209",
    "regency_id": "3322",
    "label": "Sumowono",
    "value": "Sumowono"
  },
  {
    "id": "332210",
    "regency_id": "3322",
    "label": "Ambarawa",
    "value": "Ambarawa"
  },
  { "id": "332211", "regency_id": "3322", "label": "Bawen", "value": "Bawen" },
  {
    "id": "332212",
    "regency_id": "3322",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "332213",
    "regency_id": "3322",
    "label": "Bergas",
    "value": "Bergas"
  },
  {
    "id": "332215",
    "regency_id": "3322",
    "label": "Pringapus",
    "value": "Pringapus"
  },
  {
    "id": "332216",
    "regency_id": "3322",
    "label": "Bancak",
    "value": "Bancak"
  },
  {
    "id": "332217",
    "regency_id": "3322",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "332218",
    "regency_id": "3322",
    "label": "Ungaran Barat",
    "value": "Ungaran Barat"
  },
  {
    "id": "332219",
    "regency_id": "3322",
    "label": "Ungaran Timur",
    "value": "Ungaran Timur"
  },
  {
    "id": "332220",
    "regency_id": "3322",
    "label": "Bandungan",
    "value": "Bandungan"
  },
  { "id": "332301", "regency_id": "3323", "label": "Bulu", "value": "Bulu" },
  {
    "id": "332302",
    "regency_id": "3323",
    "label": "Tembarak",
    "value": "Tembarak"
  },
  {
    "id": "332303",
    "regency_id": "3323",
    "label": "Temanggung",
    "value": "Temanggung"
  },
  {
    "id": "332304",
    "regency_id": "3323",
    "label": "Pringsurat",
    "value": "Pringsurat"
  },
  {
    "id": "332305",
    "regency_id": "3323",
    "label": "Kaloran",
    "value": "Kaloran"
  },
  {
    "id": "332306",
    "regency_id": "3323",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  { "id": "332307", "regency_id": "3323", "label": "Kedu", "value": "Kedu" },
  {
    "id": "332308",
    "regency_id": "3323",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "332309",
    "regency_id": "3323",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  { "id": "332310", "regency_id": "3323", "label": "Jumo", "value": "Jumo" },
  {
    "id": "332311",
    "regency_id": "3323",
    "label": "Tretep",
    "value": "Tretep"
  },
  {
    "id": "332312",
    "regency_id": "3323",
    "label": "Candiroto",
    "value": "Candiroto"
  },
  {
    "id": "332313",
    "regency_id": "3323",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "332314",
    "regency_id": "3323",
    "label": "Tlogomulyo",
    "value": "Tlogomulyo"
  },
  {
    "id": "332315",
    "regency_id": "3323",
    "label": "Selopampang",
    "value": "Selopampang"
  },
  {
    "id": "332316",
    "regency_id": "3323",
    "label": "Bansari",
    "value": "Bansari"
  },
  {
    "id": "332317",
    "regency_id": "3323",
    "label": "Kledung",
    "value": "Kledung"
  },
  { "id": "332318", "regency_id": "3323", "label": "Bejen", "value": "Bejen" },
  {
    "id": "332319",
    "regency_id": "3323",
    "label": "Wonoboyo",
    "value": "Wonoboyo"
  },
  {
    "id": "332320",
    "regency_id": "3323",
    "label": "Gemawang",
    "value": "Gemawang"
  },
  {
    "id": "332401",
    "regency_id": "3324",
    "label": "Plantungan",
    "value": "Plantungan"
  },
  {
    "id": "332402",
    "regency_id": "3324",
    "label": "Pageruyung",
    "value": "Pageruyung"
  },
  {
    "id": "332403",
    "regency_id": "3324",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "332404",
    "regency_id": "3324",
    "label": "Patean",
    "value": "Patean"
  },
  {
    "id": "332405",
    "regency_id": "3324",
    "label": "Singorojo",
    "value": "Singorojo"
  },
  {
    "id": "332406",
    "regency_id": "3324",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  { "id": "332407", "regency_id": "3324", "label": "Boja", "value": "Boja" },
  {
    "id": "332408",
    "regency_id": "3324",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "332409",
    "regency_id": "3324",
    "label": "Brangsong",
    "value": "Brangsong"
  },
  {
    "id": "332410",
    "regency_id": "3324",
    "label": "Pegandon",
    "value": "Pegandon"
  },
  { "id": "332411", "regency_id": "3324", "label": "Gemuh", "value": "Gemuh" },
  {
    "id": "332412",
    "regency_id": "3324",
    "label": "Weleri",
    "value": "Weleri"
  },
  {
    "id": "332413",
    "regency_id": "3324",
    "label": "Cepiring",
    "value": "Cepiring"
  },
  {
    "id": "332414",
    "regency_id": "3324",
    "label": "Patebon",
    "value": "Patebon"
  },
  {
    "id": "332415",
    "regency_id": "3324",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "332416",
    "regency_id": "3324",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "332417",
    "regency_id": "3324",
    "label": "Kangkung",
    "value": "Kangkung"
  },
  {
    "id": "332418",
    "regency_id": "3324",
    "label": "Ringinarum",
    "value": "Ringinarum"
  },
  {
    "id": "332419",
    "regency_id": "3324",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "332420",
    "regency_id": "3324",
    "label": "Kaliwungu Selatan",
    "value": "Kaliwungu Selatan"
  },
  {
    "id": "332501",
    "regency_id": "3325",
    "label": "Wonotunggal",
    "value": "Wonotunggal"
  },
  {
    "id": "332502",
    "regency_id": "3325",
    "label": "Bandar",
    "value": "Bandar"
  },
  { "id": "332503", "regency_id": "3325", "label": "Blado", "value": "Blado" },
  { "id": "332504", "regency_id": "3325", "label": "Reban", "value": "Reban" },
  {
    "id": "332505",
    "regency_id": "3325",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "332506",
    "regency_id": "3325",
    "label": "Tersono",
    "value": "Tersono"
  },
  {
    "id": "332507",
    "regency_id": "3325",
    "label": "Gringsing",
    "value": "Gringsing"
  },
  {
    "id": "332508",
    "regency_id": "3325",
    "label": "Limpung",
    "value": "Limpung"
  },
  { "id": "332509", "regency_id": "3325", "label": "Subah", "value": "Subah" },
  { "id": "332510", "regency_id": "3325", "label": "Tulis", "value": "Tulis" },
  {
    "id": "332511",
    "regency_id": "3325",
    "label": "Batang",
    "value": "Batang"
  },
  {
    "id": "332512",
    "regency_id": "3325",
    "label": "Warungasem",
    "value": "Warungasem"
  },
  {
    "id": "332513",
    "regency_id": "3325",
    "label": "Kandeman",
    "value": "Kandeman"
  },
  {
    "id": "332514",
    "regency_id": "3325",
    "label": "Pecalungan",
    "value": "Pecalungan"
  },
  {
    "id": "332515",
    "regency_id": "3325",
    "label": "Banyuputih",
    "value": "Banyuputih"
  },
  {
    "id": "332601",
    "regency_id": "3326",
    "label": "Kandangserang",
    "value": "Kandangserang"
  },
  {
    "id": "332602",
    "regency_id": "3326",
    "label": "Paninggaran",
    "value": "Paninggaran"
  },
  {
    "id": "332603",
    "regency_id": "3326",
    "label": "Lebakbarang",
    "value": "Lebakbarang"
  },
  {
    "id": "332604",
    "regency_id": "3326",
    "label": "Petungkriyono",
    "value": "Petungkriyono"
  },
  { "id": "332605", "regency_id": "3326", "label": "Talun", "value": "Talun" },
  { "id": "332606", "regency_id": "3326", "label": "Doro", "value": "Doro" },
  {
    "id": "332607",
    "regency_id": "3326",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  { "id": "332608", "regency_id": "3326", "label": "Kajen", "value": "Kajen" },
  {
    "id": "332609",
    "regency_id": "3326",
    "label": "Kesesi",
    "value": "Kesesi"
  },
  { "id": "332610", "regency_id": "3326", "label": "Sragi", "value": "Sragi" },
  {
    "id": "332611",
    "regency_id": "3326",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "332612",
    "regency_id": "3326",
    "label": "Wonopringgo",
    "value": "Wonopringgo"
  },
  {
    "id": "332613",
    "regency_id": "3326",
    "label": "Kedungwuni",
    "value": "Kedungwuni"
  },
  {
    "id": "332614",
    "regency_id": "3326",
    "label": "Buaran",
    "value": "Buaran"
  },
  { "id": "332615", "regency_id": "3326", "label": "Tirto", "value": "Tirto" },
  {
    "id": "332616",
    "regency_id": "3326",
    "label": "Wiradesa",
    "value": "Wiradesa"
  },
  {
    "id": "332617",
    "regency_id": "3326",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "332618",
    "regency_id": "3326",
    "label": "Karangdadap",
    "value": "Karangdadap"
  },
  {
    "id": "332619",
    "regency_id": "3326",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  { "id": "332701", "regency_id": "3327", "label": "Moga", "value": "Moga" },
  {
    "id": "332702",
    "regency_id": "3327",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  { "id": "332703", "regency_id": "3327", "label": "Belik", "value": "Belik" },
  {
    "id": "332704",
    "regency_id": "3327",
    "label": "Watukumpul",
    "value": "Watukumpul"
  },
  { "id": "332705", "regency_id": "3327", "label": "Bodeh", "value": "Bodeh" },
  {
    "id": "332706",
    "regency_id": "3327",
    "label": "Bantarbolang",
    "value": "Bantarbolang"
  },
  {
    "id": "332707",
    "regency_id": "3327",
    "label": "Randudongkal",
    "value": "Randudongkal"
  },
  {
    "id": "332708",
    "regency_id": "3327",
    "label": "Pemalang",
    "value": "Pemalang"
  },
  { "id": "332709", "regency_id": "3327", "label": "Taman", "value": "Taman" },
  {
    "id": "332710",
    "regency_id": "3327",
    "label": "Petarukan",
    "value": "Petarukan"
  },
  {
    "id": "332711",
    "regency_id": "3327",
    "label": "Ampelgading",
    "value": "Ampelgading"
  },
  { "id": "332712", "regency_id": "3327", "label": "Comal", "value": "Comal" },
  {
    "id": "332713",
    "regency_id": "3327",
    "label": "Ulujami",
    "value": "Ulujami"
  },
  {
    "id": "332714",
    "regency_id": "3327",
    "label": "Warungpring",
    "value": "Warungpring"
  },
  {
    "id": "332801",
    "regency_id": "3328",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "332802",
    "regency_id": "3328",
    "label": "Bumijawa",
    "value": "Bumijawa"
  },
  {
    "id": "332803",
    "regency_id": "3328",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "332804",
    "regency_id": "3328",
    "label": "Balapulang",
    "value": "Balapulang"
  },
  {
    "id": "332805",
    "regency_id": "3328",
    "label": "Pagerbarang",
    "value": "Pagerbarang"
  },
  {
    "id": "332806",
    "regency_id": "3328",
    "label": "Lebaksiu",
    "value": "Lebaksiu"
  },
  {
    "id": "332807",
    "regency_id": "3328",
    "label": "Jatinegara",
    "value": "Jatinegara"
  },
  {
    "id": "332808",
    "regency_id": "3328",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "332809",
    "regency_id": "3328",
    "label": "Pangkah",
    "value": "Pangkah"
  },
  { "id": "332810", "regency_id": "3328", "label": "Slawi", "value": "Slawi" },
  {
    "id": "332811",
    "regency_id": "3328",
    "label": "Adiwerna",
    "value": "Adiwerna"
  },
  {
    "id": "332812",
    "regency_id": "3328",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "332813",
    "regency_id": "3328",
    "label": "Dukuhturi",
    "value": "Dukuhturi"
  },
  { "id": "332814", "regency_id": "3328", "label": "Tarub", "value": "Tarub" },
  {
    "id": "332815",
    "regency_id": "3328",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "332816",
    "regency_id": "3328",
    "label": "Suradadi",
    "value": "Suradadi"
  },
  {
    "id": "332817",
    "regency_id": "3328",
    "label": "Warureja",
    "value": "Warureja"
  },
  {
    "id": "332818",
    "regency_id": "3328",
    "label": "Dukuhwaru",
    "value": "Dukuhwaru"
  },
  { "id": "332901", "regency_id": "3329", "label": "Salem", "value": "Salem" },
  {
    "id": "332902",
    "regency_id": "3329",
    "label": "Bantarkawung",
    "value": "Bantarkawung"
  },
  {
    "id": "332903",
    "regency_id": "3329",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "332904",
    "regency_id": "3329",
    "label": "Paguyangan",
    "value": "Paguyangan"
  },
  {
    "id": "332905",
    "regency_id": "3329",
    "label": "Sirampog",
    "value": "Sirampog"
  },
  {
    "id": "332906",
    "regency_id": "3329",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "332907",
    "regency_id": "3329",
    "label": "Jatibarang",
    "value": "Jatibarang"
  },
  {
    "id": "332908",
    "regency_id": "3329",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "332909",
    "regency_id": "3329",
    "label": "Brebes",
    "value": "Brebes"
  },
  {
    "id": "332910",
    "regency_id": "3329",
    "label": "Songgom",
    "value": "Songgom"
  },
  {
    "id": "332911",
    "regency_id": "3329",
    "label": "Kersana",
    "value": "Kersana"
  },
  {
    "id": "332912",
    "regency_id": "3329",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "332913",
    "regency_id": "3329",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "332914",
    "regency_id": "3329",
    "label": "Bulakamba",
    "value": "Bulakamba"
  },
  {
    "id": "332915",
    "regency_id": "3329",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "332916",
    "regency_id": "3329",
    "label": "Ketanggungan",
    "value": "Ketanggungan"
  },
  {
    "id": "332917",
    "regency_id": "3329",
    "label": "Banjarharjo",
    "value": "Banjarharjo"
  },
  {
    "id": "337101",
    "regency_id": "3371",
    "label": "Magelang Selatan",
    "value": "Magelang Selatan"
  },
  {
    "id": "337102",
    "regency_id": "3371",
    "label": "Magelang Utara",
    "value": "Magelang Utara"
  },
  {
    "id": "337103",
    "regency_id": "3371",
    "label": "Magelang Tengah",
    "value": "Magelang Tengah"
  },
  {
    "id": "337201",
    "regency_id": "3372",
    "label": "Laweyan",
    "value": "Laweyan"
  },
  {
    "id": "337202",
    "regency_id": "3372",
    "label": "Serengan",
    "value": "Serengan"
  },
  {
    "id": "337203",
    "regency_id": "3372",
    "label": "Pasar Kliwon",
    "value": "Pasar Kliwon"
  },
  {
    "id": "337204",
    "regency_id": "3372",
    "label": "Jebres",
    "value": "Jebres"
  },
  {
    "id": "337205",
    "regency_id": "3372",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "337301",
    "regency_id": "3373",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "337302",
    "regency_id": "3373",
    "label": "Tingkir",
    "value": "Tingkir"
  },
  {
    "id": "337303",
    "regency_id": "3373",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "337304",
    "regency_id": "3373",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "337401",
    "regency_id": "3374",
    "label": "Semarang Tengah",
    "value": "Semarang Tengah"
  },
  {
    "id": "337402",
    "regency_id": "3374",
    "label": "Semarang Utara",
    "value": "Semarang Utara"
  },
  {
    "id": "337403",
    "regency_id": "3374",
    "label": "Semarang Timur",
    "value": "Semarang Timur"
  },
  {
    "id": "337404",
    "regency_id": "3374",
    "label": "Gayamsari",
    "value": "Gayamsari"
  },
  { "id": "337405", "regency_id": "3374", "label": "Genuk", "value": "Genuk" },
  {
    "id": "337406",
    "regency_id": "3374",
    "label": "Pedurungan",
    "value": "Pedurungan"
  },
  {
    "id": "337407",
    "regency_id": "3374",
    "label": "Semarang Selatan",
    "value": "Semarang Selatan"
  },
  {
    "id": "337408",
    "regency_id": "3374",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "337409",
    "regency_id": "3374",
    "label": "Gajahmungkur",
    "value": "Gajahmungkur"
  },
  {
    "id": "337410",
    "regency_id": "3374",
    "label": "Tembalang",
    "value": "Tembalang"
  },
  {
    "id": "337411",
    "regency_id": "3374",
    "label": "Banyumanik",
    "value": "Banyumanik"
  },
  {
    "id": "337412",
    "regency_id": "3374",
    "label": "Gunungpati",
    "value": "Gunungpati"
  },
  {
    "id": "337413",
    "regency_id": "3374",
    "label": "Semarang Barat",
    "value": "Semarang Barat"
  },
  { "id": "337414", "regency_id": "3374", "label": "Mijen", "value": "Mijen" },
  {
    "id": "337415",
    "regency_id": "3374",
    "label": "Ngaliyan",
    "value": "Ngaliyan"
  },
  { "id": "337416", "regency_id": "3374", "label": "Tugu", "value": "Tugu" },
  {
    "id": "337501",
    "regency_id": "3375",
    "label": "Pekalongan Barat",
    "value": "Pekalongan Barat"
  },
  {
    "id": "337502",
    "regency_id": "3375",
    "label": "Pekalongan Timur",
    "value": "Pekalongan Timur"
  },
  {
    "id": "337503",
    "regency_id": "3375",
    "label": "Pekalongan Utara",
    "value": "Pekalongan Utara"
  },
  {
    "id": "337504",
    "regency_id": "3375",
    "label": "Pekalongan Selatan",
    "value": "Pekalongan Selatan"
  },
  {
    "id": "337601",
    "regency_id": "3376",
    "label": "Tegal Barat",
    "value": "Tegal Barat"
  },
  {
    "id": "337602",
    "regency_id": "3376",
    "label": "Tegal Timur",
    "value": "Tegal Timur"
  },
  {
    "id": "337603",
    "regency_id": "3376",
    "label": "Tegal Selatan",
    "value": "Tegal Selatan"
  },
  {
    "id": "337604",
    "regency_id": "3376",
    "label": "Margadana",
    "value": "Margadana"
  },
  { "id": "340101", "regency_id": "3401", "label": "Temon", "value": "Temon" },
  { "id": "340102", "regency_id": "3401", "label": "Wates", "value": "Wates" },
  {
    "id": "340103",
    "regency_id": "3401",
    "label": "Panjatan",
    "value": "Panjatan"
  },
  { "id": "340104", "regency_id": "3401", "label": "Galur", "value": "Galur" },
  {
    "id": "340105",
    "regency_id": "3401",
    "label": "Lendah",
    "value": "Lendah"
  },
  {
    "id": "340106",
    "regency_id": "3401",
    "label": "Sentolo",
    "value": "Sentolo"
  },
  {
    "id": "340107",
    "regency_id": "3401",
    "label": "Pengasih",
    "value": "Pengasih"
  },
  { "id": "340108", "regency_id": "3401", "label": "Kokap", "value": "Kokap" },
  {
    "id": "340109",
    "regency_id": "3401",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "340110",
    "regency_id": "3401",
    "label": "Nanggulan",
    "value": "Nanggulan"
  },
  {
    "id": "340111",
    "regency_id": "3401",
    "label": "Samigaluh",
    "value": "Samigaluh"
  },
  {
    "id": "340112",
    "regency_id": "3401",
    "label": "Kalibawang",
    "value": "Kalibawang"
  },
  {
    "id": "340201",
    "regency_id": "3402",
    "label": "Srandakan",
    "value": "Srandakan"
  },
  {
    "id": "340202",
    "regency_id": "3402",
    "label": "Sanden",
    "value": "Sanden"
  },
  {
    "id": "340203",
    "regency_id": "3402",
    "label": "Kretek",
    "value": "Kretek"
  },
  {
    "id": "340204",
    "regency_id": "3402",
    "label": "Pundong",
    "value": "Pundong"
  },
  {
    "id": "340205",
    "regency_id": "3402",
    "label": "Bambanglipuro",
    "value": "Bambanglipuro"
  },
  {
    "id": "340206",
    "regency_id": "3402",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "340207",
    "regency_id": "3402",
    "label": "Pajangan",
    "value": "Pajangan"
  },
  {
    "id": "340208",
    "regency_id": "3402",
    "label": "Bantul",
    "value": "Bantul"
  },
  { "id": "340209", "regency_id": "3402", "label": "Jetis", "value": "Jetis" },
  {
    "id": "340210",
    "regency_id": "3402",
    "label": "Imogiri",
    "value": "Imogiri"
  },
  {
    "id": "340211",
    "regency_id": "3402",
    "label": "Dlingo",
    "value": "Dlingo"
  },
  {
    "id": "340212",
    "regency_id": "3402",
    "label": "Banguntapan",
    "value": "Banguntapan"
  },
  {
    "id": "340213",
    "regency_id": "3402",
    "label": "Pleret",
    "value": "Pleret"
  },
  {
    "id": "340214",
    "regency_id": "3402",
    "label": "Piyungan",
    "value": "Piyungan"
  },
  { "id": "340215", "regency_id": "3402", "label": "Sewon", "value": "Sewon" },
  {
    "id": "340216",
    "regency_id": "3402",
    "label": "Kasihan",
    "value": "Kasihan"
  },
  {
    "id": "340217",
    "regency_id": "3402",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "340301",
    "regency_id": "3403",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "340302",
    "regency_id": "3403",
    "label": "Nglipar",
    "value": "Nglipar"
  },
  {
    "id": "340303",
    "regency_id": "3403",
    "label": "Playen",
    "value": "Playen"
  },
  { "id": "340304", "regency_id": "3403", "label": "Patuk", "value": "Patuk" },
  {
    "id": "340305",
    "regency_id": "3403",
    "label": "Paliyan",
    "value": "Paliyan"
  },
  {
    "id": "340306",
    "regency_id": "3403",
    "label": "Panggang",
    "value": "Panggang"
  },
  { "id": "340307", "regency_id": "3403", "label": "Tepus", "value": "Tepus" },
  {
    "id": "340308",
    "regency_id": "3403",
    "label": "Semanu",
    "value": "Semanu"
  },
  {
    "id": "340309",
    "regency_id": "3403",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "340310",
    "regency_id": "3403",
    "label": "Ponjong",
    "value": "Ponjong"
  },
  {
    "id": "340311",
    "regency_id": "3403",
    "label": "Rongkop",
    "value": "Rongkop"
  },
  { "id": "340312", "regency_id": "3403", "label": "Semin", "value": "Semin" },
  {
    "id": "340313",
    "regency_id": "3403",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "340314",
    "regency_id": "3403",
    "label": "Gedangsari",
    "value": "Gedangsari"
  },
  {
    "id": "340315",
    "regency_id": "3403",
    "label": "Saptosari",
    "value": "Saptosari"
  },
  {
    "id": "340316",
    "regency_id": "3403",
    "label": "Girisubo",
    "value": "Girisubo"
  },
  {
    "id": "340317",
    "regency_id": "3403",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "340318",
    "regency_id": "3403",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "340401",
    "regency_id": "3404",
    "label": "Gamping",
    "value": "Gamping"
  },
  {
    "id": "340402",
    "regency_id": "3404",
    "label": "Godean",
    "value": "Godean"
  },
  {
    "id": "340403",
    "regency_id": "3404",
    "label": "Moyudan",
    "value": "Moyudan"
  },
  {
    "id": "340404",
    "regency_id": "3404",
    "label": "Minggir",
    "value": "Minggir"
  },
  {
    "id": "340405",
    "regency_id": "3404",
    "label": "Seyegan",
    "value": "Seyegan"
  },
  { "id": "340406", "regency_id": "3404", "label": "Mlati", "value": "Mlati" },
  { "id": "340407", "regency_id": "3404", "label": "Depok", "value": "Depok" },
  {
    "id": "340408",
    "regency_id": "3404",
    "label": "Berbah",
    "value": "Berbah"
  },
  {
    "id": "340409",
    "regency_id": "3404",
    "label": "Prambanan",
    "value": "Prambanan"
  },
  {
    "id": "340410",
    "regency_id": "3404",
    "label": "Kalasan",
    "value": "Kalasan"
  },
  {
    "id": "340411",
    "regency_id": "3404",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "340412",
    "regency_id": "3404",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "340413",
    "regency_id": "3404",
    "label": "Sleman",
    "value": "Sleman"
  },
  {
    "id": "340414",
    "regency_id": "3404",
    "label": "Tempel",
    "value": "Tempel"
  },
  { "id": "340415", "regency_id": "3404", "label": "Turi", "value": "Turi" },
  { "id": "340416", "regency_id": "3404", "label": "Pakem", "value": "Pakem" },
  {
    "id": "340417",
    "regency_id": "3404",
    "label": "Cangkringan",
    "value": "Cangkringan"
  },
  {
    "id": "347101",
    "regency_id": "3471",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  { "id": "347102", "regency_id": "3471", "label": "Jetis", "value": "Jetis" },
  {
    "id": "347103",
    "regency_id": "3471",
    "label": "Gondokusuman",
    "value": "Gondokusuman"
  },
  {
    "id": "347104",
    "regency_id": "3471",
    "label": "Danurejan",
    "value": "Danurejan"
  },
  {
    "id": "347105",
    "regency_id": "3471",
    "label": "Gedongtengen",
    "value": "Gedongtengen"
  },
  {
    "id": "347106",
    "regency_id": "3471",
    "label": "Ngampilan",
    "value": "Ngampilan"
  },
  {
    "id": "347107",
    "regency_id": "3471",
    "label": "Wirobrajan",
    "value": "Wirobrajan"
  },
  {
    "id": "347108",
    "regency_id": "3471",
    "label": "Mantrijeron",
    "value": "Mantrijeron"
  },
  {
    "id": "347109",
    "regency_id": "3471",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "347110",
    "regency_id": "3471",
    "label": "Gondomanan",
    "value": "Gondomanan"
  },
  {
    "id": "347111",
    "regency_id": "3471",
    "label": "Pakualaman",
    "value": "Pakualaman"
  },
  {
    "id": "347112",
    "regency_id": "3471",
    "label": "Mergangsan",
    "value": "Mergangsan"
  },
  {
    "id": "347113",
    "regency_id": "3471",
    "label": "Umbulharjo",
    "value": "Umbulharjo"
  },
  {
    "id": "347114",
    "regency_id": "3471",
    "label": "Kotagede",
    "value": "Kotagede"
  },
  {
    "id": "350101",
    "regency_id": "3501",
    "label": "Donorojo",
    "value": "Donorojo"
  },
  {
    "id": "350102",
    "regency_id": "3501",
    "label": "Pringkuku",
    "value": "Pringkuku"
  },
  {
    "id": "350103",
    "regency_id": "3501",
    "label": "Punung",
    "value": "Punung"
  },
  {
    "id": "350104",
    "regency_id": "3501",
    "label": "Pacitan",
    "value": "Pacitan"
  },
  {
    "id": "350105",
    "regency_id": "3501",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "350106",
    "regency_id": "3501",
    "label": "Arjosari",
    "value": "Arjosari"
  },
  {
    "id": "350107",
    "regency_id": "3501",
    "label": "Nawangan",
    "value": "Nawangan"
  },
  {
    "id": "350108",
    "regency_id": "3501",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "350109",
    "regency_id": "3501",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "350110",
    "regency_id": "3501",
    "label": "Tulakan",
    "value": "Tulakan"
  },
  {
    "id": "350111",
    "regency_id": "3501",
    "label": "Ngadirojo",
    "value": "Ngadirojo"
  },
  {
    "id": "350112",
    "regency_id": "3501",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "350201",
    "regency_id": "3502",
    "label": "Slahung",
    "value": "Slahung"
  },
  {
    "id": "350202",
    "regency_id": "3502",
    "label": "Ngrayun",
    "value": "Ngrayun"
  },
  {
    "id": "350203",
    "regency_id": "3502",
    "label": "Bungkal",
    "value": "Bungkal"
  },
  {
    "id": "350204",
    "regency_id": "3502",
    "label": "Sambit",
    "value": "Sambit"
  },
  { "id": "350205", "regency_id": "3502", "label": "Sawoo", "value": "Sawoo" },
  { "id": "350206", "regency_id": "3502", "label": "Sooko", "value": "Sooko" },
  {
    "id": "350207",
    "regency_id": "3502",
    "label": "Pulung",
    "value": "Pulung"
  },
  {
    "id": "350208",
    "regency_id": "3502",
    "label": "Mlarak",
    "value": "Mlarak"
  },
  { "id": "350209", "regency_id": "3502", "label": "Jetis", "value": "Jetis" },
  { "id": "350210", "regency_id": "3502", "label": "Siman", "value": "Siman" },
  {
    "id": "350211",
    "regency_id": "3502",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "350212",
    "regency_id": "3502",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "350213",
    "regency_id": "3502",
    "label": "Badegan",
    "value": "Badegan"
  },
  {
    "id": "350214",
    "regency_id": "3502",
    "label": "Sampung",
    "value": "Sampung"
  },
  {
    "id": "350215",
    "regency_id": "3502",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "350216",
    "regency_id": "3502",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "350217",
    "regency_id": "3502",
    "label": "Ponorogo",
    "value": "Ponorogo"
  },
  {
    "id": "350218",
    "regency_id": "3502",
    "label": "Jenangan",
    "value": "Jenangan"
  },
  {
    "id": "350219",
    "regency_id": "3502",
    "label": "Ngebel",
    "value": "Ngebel"
  },
  {
    "id": "350220",
    "regency_id": "3502",
    "label": "Jambon",
    "value": "Jambon"
  },
  { "id": "350221", "regency_id": "3502", "label": "Pudak", "value": "Pudak" },
  {
    "id": "350301",
    "regency_id": "3503",
    "label": "Panggul",
    "value": "Panggul"
  },
  {
    "id": "350302",
    "regency_id": "3503",
    "label": "Munjungan",
    "value": "Munjungan"
  },
  { "id": "350303", "regency_id": "3503", "label": "Pule", "value": "Pule" },
  {
    "id": "350304",
    "regency_id": "3503",
    "label": "Dongko",
    "value": "Dongko"
  },
  { "id": "350305", "regency_id": "3503", "label": "Tugu", "value": "Tugu" },
  {
    "id": "350306",
    "regency_id": "3503",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "350307",
    "regency_id": "3503",
    "label": "Kampak",
    "value": "Kampak"
  },
  {
    "id": "350308",
    "regency_id": "3503",
    "label": "Watulimo",
    "value": "Watulimo"
  },
  {
    "id": "350309",
    "regency_id": "3503",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "350310",
    "regency_id": "3503",
    "label": "Gandusari",
    "value": "Gandusari"
  },
  {
    "id": "350311",
    "regency_id": "3503",
    "label": "Trenggalek",
    "value": "Trenggalek"
  },
  {
    "id": "350312",
    "regency_id": "3503",
    "label": "Pogalan",
    "value": "Pogalan"
  },
  {
    "id": "350313",
    "regency_id": "3503",
    "label": "Durenan",
    "value": "Durenan"
  },
  { "id": "350314", "regency_id": "3503", "label": "Suruh", "value": "Suruh" },
  {
    "id": "350401",
    "regency_id": "3504",
    "label": "Tulungagung",
    "value": "Tulungagung"
  },
  {
    "id": "350402",
    "regency_id": "3504",
    "label": "Boyolangu",
    "value": "Boyolangu"
  },
  {
    "id": "350403",
    "regency_id": "3504",
    "label": "Kedungwaru",
    "value": "Kedungwaru"
  },
  {
    "id": "350404",
    "regency_id": "3504",
    "label": "Ngantru",
    "value": "Ngantru"
  },
  {
    "id": "350405",
    "regency_id": "3504",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "350406",
    "regency_id": "3504",
    "label": "Pagerwojo",
    "value": "Pagerwojo"
  },
  {
    "id": "350407",
    "regency_id": "3504",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "350408",
    "regency_id": "3504",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "350409",
    "regency_id": "3504",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "350410",
    "regency_id": "3504",
    "label": "Sumbergempol",
    "value": "Sumbergempol"
  },
  {
    "id": "350411",
    "regency_id": "3504",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "350412",
    "regency_id": "3504",
    "label": "Pucanglaban",
    "value": "Pucanglaban"
  },
  {
    "id": "350413",
    "regency_id": "3504",
    "label": "Rejotangan",
    "value": "Rejotangan"
  },
  {
    "id": "350414",
    "regency_id": "3504",
    "label": "Kalidawir",
    "value": "Kalidawir"
  },
  {
    "id": "350415",
    "regency_id": "3504",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "350416",
    "regency_id": "3504",
    "label": "Campurdarat",
    "value": "Campurdarat"
  },
  {
    "id": "350417",
    "regency_id": "3504",
    "label": "Bandung",
    "value": "Bandung"
  },
  { "id": "350418", "regency_id": "3504", "label": "Pakel", "value": "Pakel" },
  {
    "id": "350419",
    "regency_id": "3504",
    "label": "Tanggunggunung",
    "value": "Tanggunggunung"
  },
  {
    "id": "350501",
    "regency_id": "3505",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "350502",
    "regency_id": "3505",
    "label": "Udanawu",
    "value": "Udanawu"
  },
  {
    "id": "350503",
    "regency_id": "3505",
    "label": "Srengat",
    "value": "Srengat"
  },
  {
    "id": "350504",
    "regency_id": "3505",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "350505",
    "regency_id": "3505",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "350506",
    "regency_id": "3505",
    "label": "Ponggok",
    "value": "Ponggok"
  },
  {
    "id": "350507",
    "regency_id": "3505",
    "label": "Sanankulon",
    "value": "Sanankulon"
  },
  {
    "id": "350508",
    "regency_id": "3505",
    "label": "Wonotirto",
    "value": "Wonotirto"
  },
  {
    "id": "350509",
    "regency_id": "3505",
    "label": "Nglegok",
    "value": "Nglegok"
  },
  {
    "id": "350510",
    "regency_id": "3505",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  { "id": "350511", "regency_id": "3505", "label": "Garum", "value": "Garum" },
  {
    "id": "350512",
    "regency_id": "3505",
    "label": "Sutojayan",
    "value": "Sutojayan"
  },
  {
    "id": "350513",
    "regency_id": "3505",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  { "id": "350514", "regency_id": "3505", "label": "Talun", "value": "Talun" },
  {
    "id": "350515",
    "regency_id": "3505",
    "label": "Gandusari",
    "value": "Gandusari"
  },
  {
    "id": "350516",
    "regency_id": "3505",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "350517",
    "regency_id": "3505",
    "label": "Wlingi",
    "value": "Wlingi"
  },
  { "id": "350518", "regency_id": "3505", "label": "Doko", "value": "Doko" },
  {
    "id": "350519",
    "regency_id": "3505",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  { "id": "350520", "regency_id": "3505", "label": "Wates", "value": "Wates" },
  {
    "id": "350521",
    "regency_id": "3505",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "350522",
    "regency_id": "3505",
    "label": "Selopuro",
    "value": "Selopuro"
  },
  { "id": "350601", "regency_id": "3506", "label": "Semen", "value": "Semen" },
  { "id": "350602", "regency_id": "3506", "label": "Mojo", "value": "Mojo" },
  { "id": "350603", "regency_id": "3506", "label": "Kras", "value": "Kras" },
  {
    "id": "350604",
    "regency_id": "3506",
    "label": "Ngadiluwih",
    "value": "Ngadiluwih"
  },
  {
    "id": "350605",
    "regency_id": "3506",
    "label": "Kandat",
    "value": "Kandat"
  },
  { "id": "350606", "regency_id": "3506", "label": "Wates", "value": "Wates" },
  {
    "id": "350607",
    "regency_id": "3506",
    "label": "Ngancar",
    "value": "Ngancar"
  },
  { "id": "350608", "regency_id": "3506", "label": "Puncu", "value": "Puncu" },
  {
    "id": "350609",
    "regency_id": "3506",
    "label": "Plosoklaten",
    "value": "Plosoklaten"
  },
  { "id": "350610", "regency_id": "3506", "label": "Gurah", "value": "Gurah" },
  { "id": "350611", "regency_id": "3506", "label": "Pagu", "value": "Pagu" },
  {
    "id": "350612",
    "regency_id": "3506",
    "label": "Gampengrejo",
    "value": "Gampengrejo"
  },
  {
    "id": "350613",
    "regency_id": "3506",
    "label": "Grogol",
    "value": "Grogol"
  },
  { "id": "350614", "regency_id": "3506", "label": "Papar", "value": "Papar" },
  {
    "id": "350615",
    "regency_id": "3506",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "350616",
    "regency_id": "3506",
    "label": "Plemahan",
    "value": "Plemahan"
  },
  { "id": "350617", "regency_id": "3506", "label": "Pare", "value": "Pare" },
  {
    "id": "350618",
    "regency_id": "3506",
    "label": "Kepung",
    "value": "Kepung"
  },
  {
    "id": "350619",
    "regency_id": "3506",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "350620",
    "regency_id": "3506",
    "label": "Tarokan",
    "value": "Tarokan"
  },
  {
    "id": "350621",
    "regency_id": "3506",
    "label": "Kunjang",
    "value": "Kunjang"
  },
  {
    "id": "350622",
    "regency_id": "3506",
    "label": "Banyakan",
    "value": "Banyakan"
  },
  {
    "id": "350623",
    "regency_id": "3506",
    "label": "Ringinrejo",
    "value": "Ringinrejo"
  },
  {
    "id": "350624",
    "regency_id": "3506",
    "label": "Kayen Kidul",
    "value": "Kayen Kidul"
  },
  {
    "id": "350625",
    "regency_id": "3506",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  { "id": "350626", "regency_id": "3506", "label": "Badas", "value": "Badas" },
  {
    "id": "350701",
    "regency_id": "3507",
    "label": "Donomulyo",
    "value": "Donomulyo"
  },
  { "id": "350702", "regency_id": "3507", "label": "Pagak", "value": "Pagak" },
  {
    "id": "350703",
    "regency_id": "3507",
    "label": "Bantur",
    "value": "Bantur"
  },
  {
    "id": "350704",
    "regency_id": "3507",
    "label": "Sumbermanjing Wetan",
    "value": "Sumbermanjing Wetan"
  },
  {
    "id": "350705",
    "regency_id": "3507",
    "label": "Dampit",
    "value": "Dampit"
  },
  {
    "id": "350706",
    "regency_id": "3507",
    "label": "Ampelgading",
    "value": "Ampelgading"
  },
  {
    "id": "350707",
    "regency_id": "3507",
    "label": "Poncokusumo",
    "value": "Poncokusumo"
  },
  { "id": "350708", "regency_id": "3507", "label": "Wajak", "value": "Wajak" },
  { "id": "350709", "regency_id": "3507", "label": "Turen", "value": "Turen" },
  {
    "id": "350710",
    "regency_id": "3507",
    "label": "Gondanglegi",
    "value": "Gondanglegi"
  },
  {
    "id": "350711",
    "regency_id": "3507",
    "label": "Kalipare",
    "value": "Kalipare"
  },
  {
    "id": "350712",
    "regency_id": "3507",
    "label": "Sumberpucung",
    "value": "Sumberpucung"
  },
  {
    "id": "350713",
    "regency_id": "3507",
    "label": "Kepanjen",
    "value": "Kepanjen"
  },
  {
    "id": "350714",
    "regency_id": "3507",
    "label": "Bululawang",
    "value": "Bululawang"
  },
  {
    "id": "350715",
    "regency_id": "3507",
    "label": "Tajinan",
    "value": "Tajinan"
  },
  {
    "id": "350716",
    "regency_id": "3507",
    "label": "Tumpang",
    "value": "Tumpang"
  },
  {
    "id": "350717",
    "regency_id": "3507",
    "label": "Jabung",
    "value": "Jabung"
  },
  { "id": "350718", "regency_id": "3507", "label": "Pakis", "value": "Pakis" },
  {
    "id": "350719",
    "regency_id": "3507",
    "label": "Pakisaji",
    "value": "Pakisaji"
  },
  {
    "id": "350720",
    "regency_id": "3507",
    "label": "Ngajum",
    "value": "Ngajum"
  },
  { "id": "350721", "regency_id": "3507", "label": "Wagir", "value": "Wagir" },
  { "id": "350722", "regency_id": "3507", "label": "Dau", "value": "Dau" },
  {
    "id": "350723",
    "regency_id": "3507",
    "label": "Karang Ploso",
    "value": "Karang Ploso"
  },
  {
    "id": "350724",
    "regency_id": "3507",
    "label": "Singosari",
    "value": "Singosari"
  },
  {
    "id": "350725",
    "regency_id": "3507",
    "label": "Lawang",
    "value": "Lawang"
  },
  { "id": "350726", "regency_id": "3507", "label": "Pujon", "value": "Pujon" },
  {
    "id": "350727",
    "regency_id": "3507",
    "label": "Ngantang",
    "value": "Ngantang"
  },
  {
    "id": "350728",
    "regency_id": "3507",
    "label": "Kasembon",
    "value": "Kasembon"
  },
  {
    "id": "350729",
    "regency_id": "3507",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "350730",
    "regency_id": "3507",
    "label": "Tirtoyudo",
    "value": "Tirtoyudo"
  },
  {
    "id": "350731",
    "regency_id": "3507",
    "label": "Kromengan",
    "value": "Kromengan"
  },
  {
    "id": "350732",
    "regency_id": "3507",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "350733",
    "regency_id": "3507",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "350801",
    "regency_id": "3508",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "350802",
    "regency_id": "3508",
    "label": "Pronojiwo",
    "value": "Pronojiwo"
  },
  {
    "id": "350803",
    "regency_id": "3508",
    "label": "Candipuro",
    "value": "Candipuro"
  },
  {
    "id": "350804",
    "regency_id": "3508",
    "label": "Pasirian",
    "value": "Pasirian"
  },
  {
    "id": "350805",
    "regency_id": "3508",
    "label": "Tempeh",
    "value": "Tempeh"
  },
  { "id": "350806", "regency_id": "3508", "label": "Kunir", "value": "Kunir" },
  {
    "id": "350807",
    "regency_id": "3508",
    "label": "Yosowilangun",
    "value": "Yosowilangun"
  },
  {
    "id": "350808",
    "regency_id": "3508",
    "label": "Rowokangkung",
    "value": "Rowokangkung"
  },
  {
    "id": "350809",
    "regency_id": "3508",
    "label": "Tekung",
    "value": "Tekung"
  },
  {
    "id": "350810",
    "regency_id": "3508",
    "label": "Lumajang",
    "value": "Lumajang"
  },
  {
    "id": "350811",
    "regency_id": "3508",
    "label": "Pasrujambe",
    "value": "Pasrujambe"
  },
  {
    "id": "350812",
    "regency_id": "3508",
    "label": "Senduro",
    "value": "Senduro"
  },
  {
    "id": "350813",
    "regency_id": "3508",
    "label": "Gucialit",
    "value": "Gucialit"
  },
  {
    "id": "350814",
    "regency_id": "3508",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "350815",
    "regency_id": "3508",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "350816",
    "regency_id": "3508",
    "label": "Kedungjajang",
    "value": "Kedungjajang"
  },
  {
    "id": "350817",
    "regency_id": "3508",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "350818",
    "regency_id": "3508",
    "label": "Randuagung",
    "value": "Randuagung"
  },
  {
    "id": "350819",
    "regency_id": "3508",
    "label": "Klakah",
    "value": "Klakah"
  },
  {
    "id": "350820",
    "regency_id": "3508",
    "label": "Ranuyoso",
    "value": "Ranuyoso"
  },
  {
    "id": "350821",
    "regency_id": "3508",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "350901",
    "regency_id": "3509",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "350902",
    "regency_id": "3509",
    "label": "Kencong",
    "value": "Kencong"
  },
  {
    "id": "350903",
    "regency_id": "3509",
    "label": "Sumberbaru",
    "value": "Sumberbaru"
  },
  {
    "id": "350904",
    "regency_id": "3509",
    "label": "Gumukmas",
    "value": "Gumukmas"
  },
  {
    "id": "350905",
    "regency_id": "3509",
    "label": "Umbulsari",
    "value": "Umbulsari"
  },
  {
    "id": "350906",
    "regency_id": "3509",
    "label": "Tanggul",
    "value": "Tanggul"
  },
  {
    "id": "350907",
    "regency_id": "3509",
    "label": "Semboro",
    "value": "Semboro"
  },
  { "id": "350908", "regency_id": "3509", "label": "Puger", "value": "Puger" },
  {
    "id": "350909",
    "regency_id": "3509",
    "label": "Bangsalsari",
    "value": "Bangsalsari"
  },
  {
    "id": "350910",
    "regency_id": "3509",
    "label": "Balung",
    "value": "Balung"
  },
  {
    "id": "350911",
    "regency_id": "3509",
    "label": "Wuluhan",
    "value": "Wuluhan"
  },
  {
    "id": "350912",
    "regency_id": "3509",
    "label": "Ambulu",
    "value": "Ambulu"
  },
  {
    "id": "350913",
    "regency_id": "3509",
    "label": "Rambipuji",
    "value": "Rambipuji"
  },
  { "id": "350914", "regency_id": "3509", "label": "Panti", "value": "Panti" },
  {
    "id": "350915",
    "regency_id": "3509",
    "label": "Sukorambi",
    "value": "Sukorambi"
  },
  {
    "id": "350916",
    "regency_id": "3509",
    "label": "Jenggawah",
    "value": "Jenggawah"
  },
  { "id": "350917", "regency_id": "3509", "label": "Ajung", "value": "Ajung" },
  {
    "id": "350918",
    "regency_id": "3509",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "350919",
    "regency_id": "3509",
    "label": "Kaliwates",
    "value": "Kaliwates"
  },
  {
    "id": "350920",
    "regency_id": "3509",
    "label": "Patrang",
    "value": "Patrang"
  },
  {
    "id": "350921",
    "regency_id": "3509",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "350922",
    "regency_id": "3509",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "350923",
    "regency_id": "3509",
    "label": "Mumbulsari",
    "value": "Mumbulsari"
  },
  {
    "id": "350924",
    "regency_id": "3509",
    "label": "Pakusari",
    "value": "Pakusari"
  },
  {
    "id": "350925",
    "regency_id": "3509",
    "label": "Jelbuk",
    "value": "Jelbuk"
  },
  {
    "id": "350926",
    "regency_id": "3509",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "350927",
    "regency_id": "3509",
    "label": "Kalisat",
    "value": "Kalisat"
  },
  {
    "id": "350928",
    "regency_id": "3509",
    "label": "Ledokombo",
    "value": "Ledokombo"
  },
  {
    "id": "350929",
    "regency_id": "3509",
    "label": "Sukowono",
    "value": "Sukowono"
  },
  { "id": "350930", "regency_id": "3509", "label": "Silo", "value": "Silo" },
  {
    "id": "350931",
    "regency_id": "3509",
    "label": "Sumberjambe",
    "value": "Sumberjambe"
  },
  {
    "id": "351001",
    "regency_id": "3510",
    "label": "Pesanggaran",
    "value": "Pesanggaran"
  },
  {
    "id": "351002",
    "regency_id": "3510",
    "label": "Bangorejo",
    "value": "Bangorejo"
  },
  {
    "id": "351003",
    "regency_id": "3510",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "351004",
    "regency_id": "3510",
    "label": "Tegaldlimo",
    "value": "Tegaldlimo"
  },
  {
    "id": "351005",
    "regency_id": "3510",
    "label": "Muncar",
    "value": "Muncar"
  },
  {
    "id": "351006",
    "regency_id": "3510",
    "label": "Cluring",
    "value": "Cluring"
  },
  {
    "id": "351007",
    "regency_id": "3510",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  { "id": "351008", "regency_id": "3510", "label": "Srono", "value": "Srono" },
  {
    "id": "351009",
    "regency_id": "3510",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "351010",
    "regency_id": "3510",
    "label": "Glenmore",
    "value": "Glenmore"
  },
  {
    "id": "351011",
    "regency_id": "3510",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "351012",
    "regency_id": "3510",
    "label": "Singojuruh",
    "value": "Singojuruh"
  },
  {
    "id": "351013",
    "regency_id": "3510",
    "label": "Rogojampi",
    "value": "Rogojampi"
  },
  { "id": "351014", "regency_id": "3510", "label": "Kabat", "value": "Kabat" },
  {
    "id": "351015",
    "regency_id": "3510",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "351016",
    "regency_id": "3510",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  { "id": "351017", "regency_id": "3510", "label": "Giri", "value": "Giri" },
  {
    "id": "351018",
    "regency_id": "3510",
    "label": "Wongsorejo",
    "value": "Wongsorejo"
  },
  {
    "id": "351019",
    "regency_id": "3510",
    "label": "Songgon",
    "value": "Songgon"
  },
  { "id": "351020", "regency_id": "3510", "label": "Sempu", "value": "Sempu" },
  {
    "id": "351021",
    "regency_id": "3510",
    "label": "Kalipuro",
    "value": "Kalipuro"
  },
  {
    "id": "351022",
    "regency_id": "3510",
    "label": "Siliragung",
    "value": "Siliragung"
  },
  {
    "id": "351023",
    "regency_id": "3510",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  { "id": "351024", "regency_id": "3510", "label": "Licin", "value": "Licin" },
  {
    "id": "351025",
    "regency_id": "3510",
    "label": "Blimbingsari",
    "value": "Blimbingsari"
  },
  {
    "id": "351101",
    "regency_id": "3511",
    "label": "Maesan",
    "value": "Maesan"
  },
  {
    "id": "351102",
    "regency_id": "3511",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "351103",
    "regency_id": "3511",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "351104",
    "regency_id": "3511",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  { "id": "351105", "regency_id": "3511", "label": "Pujer", "value": "Pujer" },
  {
    "id": "351106",
    "regency_id": "3511",
    "label": "Grujugan",
    "value": "Grujugan"
  },
  {
    "id": "351107",
    "regency_id": "3511",
    "label": "Curahdami",
    "value": "Curahdami"
  },
  {
    "id": "351108",
    "regency_id": "3511",
    "label": "Tenggarang",
    "value": "Tenggarang"
  },
  {
    "id": "351109",
    "regency_id": "3511",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  { "id": "351110", "regency_id": "3511", "label": "Tapen", "value": "Tapen" },
  {
    "id": "351111",
    "regency_id": "3511",
    "label": "Bondowoso",
    "value": "Bondowoso"
  },
  {
    "id": "351112",
    "regency_id": "3511",
    "label": "Wringin",
    "value": "Wringin"
  },
  {
    "id": "351113",
    "regency_id": "3511",
    "label": "Tegalampel",
    "value": "Tegalampel"
  },
  {
    "id": "351114",
    "regency_id": "3511",
    "label": "Klabang",
    "value": "Klabang"
  },
  {
    "id": "351115",
    "regency_id": "3511",
    "label": "Cermee",
    "value": "Cermee"
  },
  {
    "id": "351116",
    "regency_id": "3511",
    "label": "Prajekan",
    "value": "Prajekan"
  },
  { "id": "351117", "regency_id": "3511", "label": "Pakem", "value": "Pakem" },
  {
    "id": "351118",
    "regency_id": "3511",
    "label": "Sumberwringin",
    "value": "Sumberwringin"
  },
  {
    "id": "351119",
    "regency_id": "3511",
    "label": "Sempol",
    "value": "Sempol"
  },
  {
    "id": "351120",
    "regency_id": "3511",
    "label": "Binakal",
    "value": "Binakal"
  },
  {
    "id": "351121",
    "regency_id": "3511",
    "label": "Taman Krocok",
    "value": "Taman Krocok"
  },
  {
    "id": "351122",
    "regency_id": "3511",
    "label": "Botolinggo",
    "value": "Botolinggo"
  },
  {
    "id": "351123",
    "regency_id": "3511",
    "label": "Jambesari Darus Sholah",
    "value": "Jambesari Darus Sholah"
  },
  {
    "id": "351201",
    "regency_id": "3512",
    "label": "Jatibanteng",
    "value": "Jatibanteng"
  },
  {
    "id": "351202",
    "regency_id": "3512",
    "label": "Besuki",
    "value": "Besuki"
  },
  { "id": "351203", "regency_id": "3512", "label": "Suboh", "value": "Suboh" },
  {
    "id": "351204",
    "regency_id": "3512",
    "label": "Mlandingan",
    "value": "Mlandingan"
  },
  {
    "id": "351205",
    "regency_id": "3512",
    "label": "Kendit",
    "value": "Kendit"
  },
  {
    "id": "351206",
    "regency_id": "3512",
    "label": "Panarukan",
    "value": "Panarukan"
  },
  {
    "id": "351207",
    "regency_id": "3512",
    "label": "Situbondo",
    "value": "Situbondo"
  },
  { "id": "351208", "regency_id": "3512", "label": "Panji", "value": "Panji" },
  {
    "id": "351209",
    "regency_id": "3512",
    "label": "Mangaran",
    "value": "Mangaran"
  },
  {
    "id": "351210",
    "regency_id": "3512",
    "label": "Kapongan",
    "value": "Kapongan"
  },
  {
    "id": "351211",
    "regency_id": "3512",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "351212",
    "regency_id": "3512",
    "label": "Jangkar",
    "value": "Jangkar"
  },
  {
    "id": "351213",
    "regency_id": "3512",
    "label": "Asembagus",
    "value": "Asembagus"
  },
  {
    "id": "351214",
    "regency_id": "3512",
    "label": "Banyuputih",
    "value": "Banyuputih"
  },
  {
    "id": "351215",
    "regency_id": "3512",
    "label": "Sumbermalang",
    "value": "Sumbermalang"
  },
  {
    "id": "351216",
    "regency_id": "3512",
    "label": "Banyuglugur",
    "value": "Banyuglugur"
  },
  {
    "id": "351217",
    "regency_id": "3512",
    "label": "Bungatan",
    "value": "Bungatan"
  },
  {
    "id": "351301",
    "regency_id": "3513",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "351302",
    "regency_id": "3513",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "351303",
    "regency_id": "3513",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "351304",
    "regency_id": "3513",
    "label": "Bantaran",
    "value": "Bantaran"
  },
  { "id": "351305", "regency_id": "3513", "label": "Leces", "value": "Leces" },
  {
    "id": "351306",
    "regency_id": "3513",
    "label": "Banyuanyar",
    "value": "Banyuanyar"
  },
  { "id": "351307", "regency_id": "3513", "label": "Tiris", "value": "Tiris" },
  {
    "id": "351308",
    "regency_id": "3513",
    "label": "Krucil",
    "value": "Krucil"
  },
  {
    "id": "351309",
    "regency_id": "3513",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "351310",
    "regency_id": "3513",
    "label": "Pakuniran",
    "value": "Pakuniran"
  },
  {
    "id": "351311",
    "regency_id": "3513",
    "label": "Kotaanyar",
    "value": "Kotaanyar"
  },
  {
    "id": "351312",
    "regency_id": "3513",
    "label": "Paiton",
    "value": "Paiton"
  },
  { "id": "351313", "regency_id": "3513", "label": "Besuk", "value": "Besuk" },
  {
    "id": "351314",
    "regency_id": "3513",
    "label": "Kraksaan",
    "value": "Kraksaan"
  },
  {
    "id": "351315",
    "regency_id": "3513",
    "label": "Krejengan",
    "value": "Krejengan"
  },
  {
    "id": "351316",
    "regency_id": "3513",
    "label": "Pejarakan",
    "value": "Pejarakan"
  },
  { "id": "351317", "regency_id": "3513", "label": "Maron", "value": "Maron" },
  {
    "id": "351318",
    "regency_id": "3513",
    "label": "Gending",
    "value": "Gending"
  },
  {
    "id": "351319",
    "regency_id": "3513",
    "label": "Dringu",
    "value": "Dringu"
  },
  {
    "id": "351320",
    "regency_id": "3513",
    "label": "Tegalsiwalan",
    "value": "Tegalsiwalan"
  },
  {
    "id": "351321",
    "regency_id": "3513",
    "label": "Sumberasih",
    "value": "Sumberasih"
  },
  {
    "id": "351322",
    "regency_id": "3513",
    "label": "Wonomerto",
    "value": "Wonomerto"
  },
  {
    "id": "351323",
    "regency_id": "3513",
    "label": "Tongas",
    "value": "Tongas"
  },
  {
    "id": "351324",
    "regency_id": "3513",
    "label": "Lumbang",
    "value": "Lumbang"
  },
  {
    "id": "351401",
    "regency_id": "3514",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  { "id": "351402", "regency_id": "3514", "label": "Tutur", "value": "Tutur" },
  { "id": "351403", "regency_id": "3514", "label": "Puspo", "value": "Puspo" },
  {
    "id": "351404",
    "regency_id": "3514",
    "label": "Lumbang",
    "value": "Lumbang"
  },
  {
    "id": "351405",
    "regency_id": "3514",
    "label": "Pasrepan",
    "value": "Pasrepan"
  },
  {
    "id": "351406",
    "regency_id": "3514",
    "label": "Kejayan",
    "value": "Kejayan"
  },
  {
    "id": "351407",
    "regency_id": "3514",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "351408",
    "regency_id": "3514",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "351409",
    "regency_id": "3514",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "351410",
    "regency_id": "3514",
    "label": "Prigen",
    "value": "Prigen"
  },
  {
    "id": "351411",
    "regency_id": "3514",
    "label": "Pandaan",
    "value": "Pandaan"
  },
  {
    "id": "351412",
    "regency_id": "3514",
    "label": "Gempol",
    "value": "Gempol"
  },
  { "id": "351413", "regency_id": "3514", "label": "Beji", "value": "Beji" },
  {
    "id": "351414",
    "regency_id": "3514",
    "label": "Bangil",
    "value": "Bangil"
  },
  {
    "id": "351415",
    "regency_id": "3514",
    "label": "Rembang",
    "value": "Rembang"
  },
  {
    "id": "351416",
    "regency_id": "3514",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "351417",
    "regency_id": "3514",
    "label": "Pohjentrek",
    "value": "Pohjentrek"
  },
  {
    "id": "351418",
    "regency_id": "3514",
    "label": "Gondangwetan",
    "value": "Gondangwetan"
  },
  {
    "id": "351419",
    "regency_id": "3514",
    "label": "Winongan",
    "value": "Winongan"
  },
  { "id": "351420", "regency_id": "3514", "label": "Grati", "value": "Grati" },
  {
    "id": "351421",
    "regency_id": "3514",
    "label": "Nguling",
    "value": "Nguling"
  },
  { "id": "351422", "regency_id": "3514", "label": "Lekok", "value": "Lekok" },
  {
    "id": "351423",
    "regency_id": "3514",
    "label": "Rejoso",
    "value": "Rejoso"
  },
  {
    "id": "351424",
    "regency_id": "3514",
    "label": "Tosari",
    "value": "Tosari"
  },
  { "id": "351501", "regency_id": "3515", "label": "Tarik", "value": "Tarik" },
  {
    "id": "351502",
    "regency_id": "3515",
    "label": "Prambon",
    "value": "Prambon"
  },
  {
    "id": "351503",
    "regency_id": "3515",
    "label": "Krembung",
    "value": "Krembung"
  },
  {
    "id": "351504",
    "regency_id": "3515",
    "label": "Porong",
    "value": "Porong"
  },
  { "id": "351505", "regency_id": "3515", "label": "Jabon", "value": "Jabon" },
  {
    "id": "351506",
    "regency_id": "3515",
    "label": "Tanggulangin",
    "value": "Tanggulangin"
  },
  { "id": "351507", "regency_id": "3515", "label": "Candi", "value": "Candi" },
  {
    "id": "351508",
    "regency_id": "3515",
    "label": "Sidoarjo",
    "value": "Sidoarjo"
  },
  {
    "id": "351509",
    "regency_id": "3515",
    "label": "Tulangan",
    "value": "Tulangan"
  },
  {
    "id": "351510",
    "regency_id": "3515",
    "label": "Wonoayu",
    "value": "Wonoayu"
  },
  { "id": "351511", "regency_id": "3515", "label": "Krian", "value": "Krian" },
  {
    "id": "351512",
    "regency_id": "3515",
    "label": "Balongbendo",
    "value": "Balongbendo"
  },
  { "id": "351513", "regency_id": "3515", "label": "Taman", "value": "Taman" },
  {
    "id": "351514",
    "regency_id": "3515",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "351515",
    "regency_id": "3515",
    "label": "Buduran",
    "value": "Buduran"
  },
  {
    "id": "351516",
    "regency_id": "3515",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "351517",
    "regency_id": "3515",
    "label": "Sedati",
    "value": "Sedati"
  },
  { "id": "351518", "regency_id": "3515", "label": "Waru", "value": "Waru" },
  {
    "id": "351601",
    "regency_id": "3516",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "351602",
    "regency_id": "3516",
    "label": "Gondang",
    "value": "Gondang"
  },
  { "id": "351603", "regency_id": "3516", "label": "Pacet", "value": "Pacet" },
  {
    "id": "351604",
    "regency_id": "3516",
    "label": "Trawas",
    "value": "Trawas"
  },
  { "id": "351605", "regency_id": "3516", "label": "Ngoro", "value": "Ngoro" },
  {
    "id": "351606",
    "regency_id": "3516",
    "label": "Pungging",
    "value": "Pungging"
  },
  {
    "id": "351607",
    "regency_id": "3516",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "351608",
    "regency_id": "3516",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "351609",
    "regency_id": "3516",
    "label": "Dlanggu",
    "value": "Dlanggu"
  },
  {
    "id": "351610",
    "regency_id": "3516",
    "label": "Bangsal",
    "value": "Bangsal"
  },
  { "id": "351611", "regency_id": "3516", "label": "Puri", "value": "Puri" },
  {
    "id": "351612",
    "regency_id": "3516",
    "label": "Trowulan",
    "value": "Trowulan"
  },
  { "id": "351613", "regency_id": "3516", "label": "Sooko", "value": "Sooko" },
  { "id": "351614", "regency_id": "3516", "label": "Gedeg", "value": "Gedeg" },
  {
    "id": "351615",
    "regency_id": "3516",
    "label": "Kemlagi",
    "value": "Kemlagi"
  },
  { "id": "351616", "regency_id": "3516", "label": "Jetis", "value": "Jetis" },
  {
    "id": "351617",
    "regency_id": "3516",
    "label": "Dawarblandong",
    "value": "Dawarblandong"
  },
  {
    "id": "351618",
    "regency_id": "3516",
    "label": "Mojoanyar",
    "value": "Mojoanyar"
  },
  { "id": "351701", "regency_id": "3517", "label": "Perak", "value": "Perak" },
  { "id": "351702", "regency_id": "3517", "label": "Gudo", "value": "Gudo" },
  { "id": "351703", "regency_id": "3517", "label": "Ngoro", "value": "Ngoro" },
  {
    "id": "351704",
    "regency_id": "3517",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "351705",
    "regency_id": "3517",
    "label": "Wonosalam",
    "value": "Wonosalam"
  },
  {
    "id": "351706",
    "regency_id": "3517",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "351707",
    "regency_id": "3517",
    "label": "Mojowarno",
    "value": "Mojowarno"
  },
  { "id": "351708", "regency_id": "3517", "label": "Diwek", "value": "Diwek" },
  {
    "id": "351709",
    "regency_id": "3517",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "351710",
    "regency_id": "3517",
    "label": "Peterongan",
    "value": "Peterongan"
  },
  {
    "id": "351711",
    "regency_id": "3517",
    "label": "Sumobito",
    "value": "Sumobito"
  },
  {
    "id": "351712",
    "regency_id": "3517",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  {
    "id": "351713",
    "regency_id": "3517",
    "label": "Tembelang",
    "value": "Tembelang"
  },
  { "id": "351714", "regency_id": "3517", "label": "Ploso", "value": "Ploso" },
  {
    "id": "351715",
    "regency_id": "3517",
    "label": "Plandaan",
    "value": "Plandaan"
  },
  { "id": "351716", "regency_id": "3517", "label": "Kabuh", "value": "Kabuh" },
  { "id": "351717", "regency_id": "3517", "label": "Kudu", "value": "Kudu" },
  {
    "id": "351718",
    "regency_id": "3517",
    "label": "Bandarkedungmulyo",
    "value": "Bandarkedungmulyo"
  },
  {
    "id": "351719",
    "regency_id": "3517",
    "label": "Jogoroto",
    "value": "Jogoroto"
  },
  {
    "id": "351720",
    "regency_id": "3517",
    "label": "Megaluh",
    "value": "Megaluh"
  },
  {
    "id": "351721",
    "regency_id": "3517",
    "label": "Ngusikan",
    "value": "Ngusikan"
  },
  {
    "id": "351801",
    "regency_id": "3518",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "351802",
    "regency_id": "3518",
    "label": "Ngetos",
    "value": "Ngetos"
  },
  {
    "id": "351803",
    "regency_id": "3518",
    "label": "Berbek",
    "value": "Berbek"
  },
  {
    "id": "351804",
    "regency_id": "3518",
    "label": "Loceret",
    "value": "Loceret"
  },
  { "id": "351805", "regency_id": "3518", "label": "Pace", "value": "Pace" },
  {
    "id": "351806",
    "regency_id": "3518",
    "label": "Prambon",
    "value": "Prambon"
  },
  {
    "id": "351807",
    "regency_id": "3518",
    "label": "Ngronggot",
    "value": "Ngronggot"
  },
  {
    "id": "351808",
    "regency_id": "3518",
    "label": "Kertosono",
    "value": "Kertosono"
  },
  {
    "id": "351809",
    "regency_id": "3518",
    "label": "Patianrowo",
    "value": "Patianrowo"
  },
  { "id": "351810", "regency_id": "3518", "label": "Baron", "value": "Baron" },
  {
    "id": "351811",
    "regency_id": "3518",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "351812",
    "regency_id": "3518",
    "label": "Sukomoro",
    "value": "Sukomoro"
  },
  {
    "id": "351813",
    "regency_id": "3518",
    "label": "Nganjuk",
    "value": "Nganjuk"
  },
  { "id": "351814", "regency_id": "3518", "label": "Bagor", "value": "Bagor" },
  {
    "id": "351815",
    "regency_id": "3518",
    "label": "Wilangan",
    "value": "Wilangan"
  },
  {
    "id": "351816",
    "regency_id": "3518",
    "label": "Rejoso",
    "value": "Rejoso"
  },
  {
    "id": "351817",
    "regency_id": "3518",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "351818",
    "regency_id": "3518",
    "label": "Ngluyu",
    "value": "Ngluyu"
  },
  {
    "id": "351819",
    "regency_id": "3518",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "351820",
    "regency_id": "3518",
    "label": "Jatikalen",
    "value": "Jatikalen"
  },
  {
    "id": "351901",
    "regency_id": "3519",
    "label": "Kebon Sari",
    "value": "Kebon Sari"
  },
  {
    "id": "351902",
    "regency_id": "3519",
    "label": "Dolopo",
    "value": "Dolopo"
  },
  { "id": "351903", "regency_id": "3519", "label": "Geger", "value": "Geger" },
  {
    "id": "351904",
    "regency_id": "3519",
    "label": "Dagangan",
    "value": "Dagangan"
  },
  { "id": "351905", "regency_id": "3519", "label": "Kare", "value": "Kare" },
  {
    "id": "351906",
    "regency_id": "3519",
    "label": "Gemarang",
    "value": "Gemarang"
  },
  { "id": "351907", "regency_id": "3519", "label": "Wungu", "value": "Wungu" },
  {
    "id": "351908",
    "regency_id": "3519",
    "label": "Madiun",
    "value": "Madiun"
  },
  { "id": "351909", "regency_id": "3519", "label": "Jiwan", "value": "Jiwan" },
  {
    "id": "351910",
    "regency_id": "3519",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "351911",
    "regency_id": "3519",
    "label": "Mejayan",
    "value": "Mejayan"
  },
  {
    "id": "351912",
    "regency_id": "3519",
    "label": "Saradan",
    "value": "Saradan"
  },
  {
    "id": "351913",
    "regency_id": "3519",
    "label": "Pilangkenceng",
    "value": "Pilangkenceng"
  },
  {
    "id": "351914",
    "regency_id": "3519",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "351915",
    "regency_id": "3519",
    "label": "Wonoasri",
    "value": "Wonoasri"
  },
  {
    "id": "352001",
    "regency_id": "3520",
    "label": "Poncol",
    "value": "Poncol"
  },
  {
    "id": "352002",
    "regency_id": "3520",
    "label": "Parang",
    "value": "Parang"
  },
  {
    "id": "352003",
    "regency_id": "3520",
    "label": "Lembeyan",
    "value": "Lembeyan"
  },
  {
    "id": "352004",
    "regency_id": "3520",
    "label": "Takeran",
    "value": "Takeran"
  },
  {
    "id": "352005",
    "regency_id": "3520",
    "label": "Kawedanan",
    "value": "Kawedanan"
  },
  {
    "id": "352006",
    "regency_id": "3520",
    "label": "Magetan",
    "value": "Magetan"
  },
  {
    "id": "352007",
    "regency_id": "3520",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "352008",
    "regency_id": "3520",
    "label": "Panekan",
    "value": "Panekan"
  },
  {
    "id": "352009",
    "regency_id": "3520",
    "label": "Sukomoro",
    "value": "Sukomoro"
  },
  { "id": "352010", "regency_id": "3520", "label": "Bendo", "value": "Bendo" },
  {
    "id": "352011",
    "regency_id": "3520",
    "label": "Maospati",
    "value": "Maospati"
  },
  { "id": "352012", "regency_id": "3520", "label": "Barat", "value": "Barat" },
  {
    "id": "352013",
    "regency_id": "3520",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  { "id": "352014", "regency_id": "3520", "label": "Karas", "value": "Karas" },
  {
    "id": "352015",
    "regency_id": "3520",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "352016",
    "regency_id": "3520",
    "label": "Ngariboyo",
    "value": "Ngariboyo"
  },
  {
    "id": "352017",
    "regency_id": "3520",
    "label": "Nguntoronadi",
    "value": "Nguntoronadi"
  },
  {
    "id": "352018",
    "regency_id": "3520",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  { "id": "352101", "regency_id": "3521", "label": "Sine", "value": "Sine" },
  {
    "id": "352102",
    "regency_id": "3521",
    "label": "Ngrambe",
    "value": "Ngrambe"
  },
  {
    "id": "352103",
    "regency_id": "3521",
    "label": "Jogorogo",
    "value": "Jogorogo"
  },
  {
    "id": "352104",
    "regency_id": "3521",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "352105",
    "regency_id": "3521",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "352106",
    "regency_id": "3521",
    "label": "Kwadungan",
    "value": "Kwadungan"
  },
  {
    "id": "352107",
    "regency_id": "3521",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  { "id": "352108", "regency_id": "3521", "label": "Padas", "value": "Padas" },
  { "id": "352109", "regency_id": "3521", "label": "Ngawi", "value": "Ngawi" },
  { "id": "352110", "regency_id": "3521", "label": "Paron", "value": "Paron" },
  {
    "id": "352111",
    "regency_id": "3521",
    "label": "Kedunggalar",
    "value": "Kedunggalar"
  },
  {
    "id": "352112",
    "regency_id": "3521",
    "label": "Widodaren",
    "value": "Widodaren"
  },
  {
    "id": "352113",
    "regency_id": "3521",
    "label": "Mantingan",
    "value": "Mantingan"
  },
  {
    "id": "352114",
    "regency_id": "3521",
    "label": "Pangkur",
    "value": "Pangkur"
  },
  {
    "id": "352115",
    "regency_id": "3521",
    "label": "Bringin",
    "value": "Bringin"
  },
  { "id": "352116", "regency_id": "3521", "label": "Pitu", "value": "Pitu" },
  {
    "id": "352117",
    "regency_id": "3521",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  { "id": "352118", "regency_id": "3521", "label": "Gerih", "value": "Gerih" },
  {
    "id": "352119",
    "regency_id": "3521",
    "label": "Kasreman",
    "value": "Kasreman"
  },
  {
    "id": "352201",
    "regency_id": "3522",
    "label": "Ngraho",
    "value": "Ngraho"
  },
  {
    "id": "352202",
    "regency_id": "3522",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "352203",
    "regency_id": "3522",
    "label": "Ngambon",
    "value": "Ngambon"
  },
  {
    "id": "352204",
    "regency_id": "3522",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "352205",
    "regency_id": "3522",
    "label": "Bubulan",
    "value": "Bubulan"
  },
  {
    "id": "352206",
    "regency_id": "3522",
    "label": "Dander",
    "value": "Dander"
  },
  {
    "id": "352207",
    "regency_id": "3522",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "352208",
    "regency_id": "3522",
    "label": "Kedungadem",
    "value": "Kedungadem"
  },
  {
    "id": "352209",
    "regency_id": "3522",
    "label": "Kepohbaru",
    "value": "Kepohbaru"
  },
  {
    "id": "352210",
    "regency_id": "3522",
    "label": "Baureno",
    "value": "Baureno"
  },
  { "id": "352211", "regency_id": "3522", "label": "Kanor", "value": "Kanor" },
  {
    "id": "352212",
    "regency_id": "3522",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  { "id": "352213", "regency_id": "3522", "label": "Balen", "value": "Balen" },
  { "id": "352214", "regency_id": "3522", "label": "Kapas", "value": "Kapas" },
  {
    "id": "352215",
    "regency_id": "3522",
    "label": "Bojonegoro",
    "value": "Bojonegoro"
  },
  {
    "id": "352216",
    "regency_id": "3522",
    "label": "Kalitidu",
    "value": "Kalitidu"
  },
  { "id": "352217", "regency_id": "3522", "label": "Malo", "value": "Malo" },
  {
    "id": "352218",
    "regency_id": "3522",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "352219",
    "regency_id": "3522",
    "label": "Padangan",
    "value": "Padangan"
  },
  {
    "id": "352220",
    "regency_id": "3522",
    "label": "Kasiman",
    "value": "Kasiman"
  },
  {
    "id": "352221",
    "regency_id": "3522",
    "label": "Temayang",
    "value": "Temayang"
  },
  {
    "id": "352222",
    "regency_id": "3522",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "352223",
    "regency_id": "3522",
    "label": "Trucuk",
    "value": "Trucuk"
  },
  {
    "id": "352224",
    "regency_id": "3522",
    "label": "Sukosewu",
    "value": "Sukosewu"
  },
  {
    "id": "352225",
    "regency_id": "3522",
    "label": "Kedewan",
    "value": "Kedewan"
  },
  {
    "id": "352226",
    "regency_id": "3522",
    "label": "Gondang",
    "value": "Gondang"
  },
  { "id": "352227", "regency_id": "3522", "label": "Sekar", "value": "Sekar" },
  { "id": "352228", "regency_id": "3522", "label": "Gayam", "value": "Gayam" },
  {
    "id": "352301",
    "regency_id": "3523",
    "label": "Kenduruan",
    "value": "Kenduruan"
  },
  {
    "id": "352302",
    "regency_id": "3523",
    "label": "Jatirogo",
    "value": "Jatirogo"
  },
  {
    "id": "352303",
    "regency_id": "3523",
    "label": "Bangilan",
    "value": "Bangilan"
  },
  {
    "id": "352304",
    "regency_id": "3523",
    "label": "Bancar",
    "value": "Bancar"
  },
  {
    "id": "352305",
    "regency_id": "3523",
    "label": "Senori",
    "value": "Senori"
  },
  {
    "id": "352306",
    "regency_id": "3523",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "352307",
    "regency_id": "3523",
    "label": "Singgahan",
    "value": "Singgahan"
  },
  { "id": "352308", "regency_id": "3523", "label": "Kerek", "value": "Kerek" },
  {
    "id": "352309",
    "regency_id": "3523",
    "label": "Parengan",
    "value": "Parengan"
  },
  {
    "id": "352310",
    "regency_id": "3523",
    "label": "Montong",
    "value": "Montong"
  },
  { "id": "352311", "regency_id": "3523", "label": "Soko", "value": "Soko" },
  { "id": "352312", "regency_id": "3523", "label": "Jenu", "value": "Jenu" },
  {
    "id": "352313",
    "regency_id": "3523",
    "label": "Merakurak",
    "value": "Merakurak"
  },
  {
    "id": "352314",
    "regency_id": "3523",
    "label": "Rengel",
    "value": "Rengel"
  },
  {
    "id": "352315",
    "regency_id": "3523",
    "label": "Semanding",
    "value": "Semanding"
  },
  { "id": "352316", "regency_id": "3523", "label": "Tuban", "value": "Tuban" },
  {
    "id": "352317",
    "regency_id": "3523",
    "label": "Plumpang",
    "value": "Plumpang"
  },
  {
    "id": "352318",
    "regency_id": "3523",
    "label": "Palang",
    "value": "Palang"
  },
  {
    "id": "352319",
    "regency_id": "3523",
    "label": "Widang",
    "value": "Widang"
  },
  {
    "id": "352320",
    "regency_id": "3523",
    "label": "Grabagan",
    "value": "Grabagan"
  },
  {
    "id": "352401",
    "regency_id": "3524",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "352402",
    "regency_id": "3524",
    "label": "Bluluk",
    "value": "Bluluk"
  },
  { "id": "352403", "regency_id": "3524", "label": "Modo", "value": "Modo" },
  {
    "id": "352404",
    "regency_id": "3524",
    "label": "Ngimbang",
    "value": "Ngimbang"
  },
  { "id": "352405", "regency_id": "3524", "label": "Babat", "value": "Babat" },
  {
    "id": "352406",
    "regency_id": "3524",
    "label": "Kedungpring",
    "value": "Kedungpring"
  },
  {
    "id": "352407",
    "regency_id": "3524",
    "label": "Brondong",
    "value": "Brondong"
  },
  { "id": "352408", "regency_id": "3524", "label": "Laren", "value": "Laren" },
  {
    "id": "352409",
    "regency_id": "3524",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "352410",
    "regency_id": "3524",
    "label": "Maduran",
    "value": "Maduran"
  },
  {
    "id": "352411",
    "regency_id": "3524",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  { "id": "352412", "regency_id": "3524", "label": "Sugio", "value": "Sugio" },
  { "id": "352413", "regency_id": "3524", "label": "Pucuk", "value": "Pucuk" },
  {
    "id": "352414",
    "regency_id": "3524",
    "label": "Paciran",
    "value": "Paciran"
  },
  {
    "id": "352415",
    "regency_id": "3524",
    "label": "Solokuro",
    "value": "Solokuro"
  },
  {
    "id": "352416",
    "regency_id": "3524",
    "label": "Mantup",
    "value": "Mantup"
  },
  {
    "id": "352417",
    "regency_id": "3524",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "352418",
    "regency_id": "3524",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "352419",
    "regency_id": "3524",
    "label": "Kembangbahu",
    "value": "Kembangbahu"
  },
  {
    "id": "352420",
    "regency_id": "3524",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  { "id": "352421", "regency_id": "3524", "label": "Turi", "value": "Turi" },
  {
    "id": "352422",
    "regency_id": "3524",
    "label": "Lamongan",
    "value": "Lamongan"
  },
  {
    "id": "352423",
    "regency_id": "3524",
    "label": "Tikung",
    "value": "Tikung"
  },
  {
    "id": "352424",
    "regency_id": "3524",
    "label": "Karangbinangun",
    "value": "Karangbinangun"
  },
  { "id": "352425", "regency_id": "3524", "label": "Deket", "value": "Deket" },
  {
    "id": "352426",
    "regency_id": "3524",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "352427",
    "regency_id": "3524",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  { "id": "352501", "regency_id": "3525", "label": "Dukun", "value": "Dukun" },
  {
    "id": "352502",
    "regency_id": "3525",
    "label": "Balongpanggang",
    "value": "Balongpanggang"
  },
  {
    "id": "352503",
    "regency_id": "3525",
    "label": "Panceng",
    "value": "Panceng"
  },
  {
    "id": "352504",
    "regency_id": "3525",
    "label": "Benjeng",
    "value": "Benjeng"
  },
  {
    "id": "352505",
    "regency_id": "3525",
    "label": "Duduksampeyan",
    "value": "Duduksampeyan"
  },
  {
    "id": "352506",
    "regency_id": "3525",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "352507",
    "regency_id": "3525",
    "label": "Ujungpangkah",
    "value": "Ujungpangkah"
  },
  {
    "id": "352508",
    "regency_id": "3525",
    "label": "Kedamean",
    "value": "Kedamean"
  },
  {
    "id": "352509",
    "regency_id": "3525",
    "label": "Sidayu",
    "value": "Sidayu"
  },
  {
    "id": "352510",
    "regency_id": "3525",
    "label": "Manyar",
    "value": "Manyar"
  },
  { "id": "352511", "regency_id": "3525", "label": "Cerme", "value": "Cerme" },
  {
    "id": "352512",
    "regency_id": "3525",
    "label": "Bungah",
    "value": "Bungah"
  },
  {
    "id": "352513",
    "regency_id": "3525",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "352514",
    "regency_id": "3525",
    "label": "Kebomas",
    "value": "Kebomas"
  },
  {
    "id": "352515",
    "regency_id": "3525",
    "label": "Driyorejo",
    "value": "Driyorejo"
  },
  {
    "id": "352516",
    "regency_id": "3525",
    "label": "Gresik",
    "value": "Gresik"
  },
  {
    "id": "352517",
    "regency_id": "3525",
    "label": "Sangkapura",
    "value": "Sangkapura"
  },
  {
    "id": "352518",
    "regency_id": "3525",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "352601",
    "regency_id": "3526",
    "label": "Bangkalan",
    "value": "Bangkalan"
  },
  { "id": "352602", "regency_id": "3526", "label": "Socah", "value": "Socah" },
  {
    "id": "352603",
    "regency_id": "3526",
    "label": "Burneh",
    "value": "Burneh"
  },
  { "id": "352604", "regency_id": "3526", "label": "Kamal", "value": "Kamal" },
  {
    "id": "352605",
    "regency_id": "3526",
    "label": "Arosbaya",
    "value": "Arosbaya"
  },
  { "id": "352606", "regency_id": "3526", "label": "Geger", "value": "Geger" },
  {
    "id": "352607",
    "regency_id": "3526",
    "label": "Klampis",
    "value": "Klampis"
  },
  {
    "id": "352608",
    "regency_id": "3526",
    "label": "Sepulu",
    "value": "Sepulu"
  },
  {
    "id": "352609",
    "regency_id": "3526",
    "label": "Tanjung Bumi",
    "value": "Tanjung Bumi"
  },
  { "id": "352610", "regency_id": "3526", "label": "Kokop", "value": "Kokop" },
  {
    "id": "352611",
    "regency_id": "3526",
    "label": "Kwanyar",
    "value": "Kwanyar"
  },
  {
    "id": "352612",
    "regency_id": "3526",
    "label": "Labang",
    "value": "Labang"
  },
  {
    "id": "352613",
    "regency_id": "3526",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "352614",
    "regency_id": "3526",
    "label": "Tragah",
    "value": "Tragah"
  },
  { "id": "352615", "regency_id": "3526", "label": "Blega", "value": "Blega" },
  {
    "id": "352616",
    "regency_id": "3526",
    "label": "Modung",
    "value": "Modung"
  },
  {
    "id": "352617",
    "regency_id": "3526",
    "label": "Konang",
    "value": "Konang"
  },
  { "id": "352618", "regency_id": "3526", "label": "Galis", "value": "Galis" },
  {
    "id": "352701",
    "regency_id": "3527",
    "label": "Sreseh",
    "value": "Sreseh"
  },
  {
    "id": "352702",
    "regency_id": "3527",
    "label": "Torjun",
    "value": "Torjun"
  },
  {
    "id": "352703",
    "regency_id": "3527",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "352704",
    "regency_id": "3527",
    "label": "Camplong",
    "value": "Camplong"
  },
  { "id": "352705", "regency_id": "3527", "label": "Omben", "value": "Omben" },
  {
    "id": "352706",
    "regency_id": "3527",
    "label": "Kedungdung",
    "value": "Kedungdung"
  },
  {
    "id": "352707",
    "regency_id": "3527",
    "label": "Jrengik",
    "value": "Jrengik"
  },
  {
    "id": "352708",
    "regency_id": "3527",
    "label": "Tambelangan",
    "value": "Tambelangan"
  },
  {
    "id": "352709",
    "regency_id": "3527",
    "label": "Banyuates",
    "value": "Banyuates"
  },
  {
    "id": "352710",
    "regency_id": "3527",
    "label": "Robatal",
    "value": "Robatal"
  },
  {
    "id": "352711",
    "regency_id": "3527",
    "label": "Sokobanah",
    "value": "Sokobanah"
  },
  {
    "id": "352712",
    "regency_id": "3527",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "352713",
    "regency_id": "3527",
    "label": "Pangarengan",
    "value": "Pangarengan"
  },
  {
    "id": "352714",
    "regency_id": "3527",
    "label": "Karangpenang",
    "value": "Karangpenang"
  },
  {
    "id": "352801",
    "regency_id": "3528",
    "label": "Tlanakan",
    "value": "Tlanakan"
  },
  {
    "id": "352802",
    "regency_id": "3528",
    "label": "Pademawu",
    "value": "Pademawu"
  },
  { "id": "352803", "regency_id": "3528", "label": "Galis", "value": "Galis" },
  {
    "id": "352804",
    "regency_id": "3528",
    "label": "Pamekasan",
    "value": "Pamekasan"
  },
  {
    "id": "352805",
    "regency_id": "3528",
    "label": "Proppo",
    "value": "Proppo"
  },
  {
    "id": "352806",
    "regency_id": "3528",
    "label": "Palenggaan",
    "value": "Palenggaan"
  },
  {
    "id": "352807",
    "regency_id": "3528",
    "label": "Pegantenan",
    "value": "Pegantenan"
  },
  {
    "id": "352808",
    "regency_id": "3528",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "352809",
    "regency_id": "3528",
    "label": "Pakong",
    "value": "Pakong"
  },
  { "id": "352810", "regency_id": "3528", "label": "Waru", "value": "Waru" },
  {
    "id": "352811",
    "regency_id": "3528",
    "label": "Batumarmar",
    "value": "Batumarmar"
  },
  { "id": "352812", "regency_id": "3528", "label": "Kadur", "value": "Kadur" },
  {
    "id": "352813",
    "regency_id": "3528",
    "label": "Pasean",
    "value": "Pasean"
  },
  {
    "id": "352901",
    "regency_id": "3529",
    "label": "Kota Sumenep",
    "value": "Kota Sumenep"
  },
  {
    "id": "352902",
    "regency_id": "3529",
    "label": "Kalianget",
    "value": "Kalianget"
  },
  {
    "id": "352903",
    "regency_id": "3529",
    "label": "Manding",
    "value": "Manding"
  },
  {
    "id": "352904",
    "regency_id": "3529",
    "label": "Talango",
    "value": "Talango"
  },
  { "id": "352905", "regency_id": "3529", "label": "Bluto", "value": "Bluto" },
  {
    "id": "352906",
    "regency_id": "3529",
    "label": "Saronggi",
    "value": "Saronggi"
  },
  {
    "id": "352907",
    "regency_id": "3529",
    "label": "Lenteng",
    "value": "Lenteng"
  },
  {
    "id": "352908",
    "regency_id": "3529",
    "label": "Giliginting",
    "value": "Giliginting"
  },
  {
    "id": "352909",
    "regency_id": "3529",
    "label": "Guluk-Guluk",
    "value": "Guluk-Guluk"
  },
  {
    "id": "352910",
    "regency_id": "3529",
    "label": "Ganding",
    "value": "Ganding"
  },
  {
    "id": "352911",
    "regency_id": "3529",
    "label": "Pragaan",
    "value": "Pragaan"
  },
  {
    "id": "352912",
    "regency_id": "3529",
    "label": "Ambunten",
    "value": "Ambunten"
  },
  {
    "id": "352913",
    "regency_id": "3529",
    "label": "Pasongsongan",
    "value": "Pasongsongan"
  },
  { "id": "352914", "regency_id": "3529", "label": "Dasuk", "value": "Dasuk" },
  {
    "id": "352915",
    "regency_id": "3529",
    "label": "Rubaru",
    "value": "Rubaru"
  },
  {
    "id": "352916",
    "regency_id": "3529",
    "label": "Batang Batang",
    "value": "Batang Batang"
  },
  {
    "id": "352917",
    "regency_id": "3529",
    "label": "Batuputih",
    "value": "Batuputih"
  },
  {
    "id": "352918",
    "regency_id": "3529",
    "label": "Dungkek",
    "value": "Dungkek"
  },
  {
    "id": "352919",
    "regency_id": "3529",
    "label": "Gapura",
    "value": "Gapura"
  },
  { "id": "352920", "regency_id": "3529", "label": "Gayam", "value": "Gayam" },
  {
    "id": "352921",
    "regency_id": "3529",
    "label": "Nonggunong",
    "value": "Nonggunong"
  },
  { "id": "352922", "regency_id": "3529", "label": "Ra'as", "value": "Ra'as" },
  {
    "id": "352923",
    "regency_id": "3529",
    "label": "Masalembu",
    "value": "Masalembu"
  },
  {
    "id": "352924",
    "regency_id": "3529",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "352925",
    "regency_id": "3529",
    "label": "Sapeken",
    "value": "Sapeken"
  },
  {
    "id": "352926",
    "regency_id": "3529",
    "label": "Batuan",
    "value": "Batuan"
  },
  {
    "id": "352927",
    "regency_id": "3529",
    "label": "Kangayan",
    "value": "Kangayan"
  },
  {
    "id": "357101",
    "regency_id": "3571",
    "label": "Mojoroto",
    "value": "Mojoroto"
  },
  { "id": "357102", "regency_id": "3571", "label": "Kota", "value": "Kota" },
  {
    "id": "357103",
    "regency_id": "3571",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "357201",
    "regency_id": "3572",
    "label": "Kepanjenkidul",
    "value": "Kepanjenkidul"
  },
  {
    "id": "357202",
    "regency_id": "3572",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "357203",
    "regency_id": "3572",
    "label": "Sananwetan",
    "value": "Sananwetan"
  },
  {
    "id": "357301",
    "regency_id": "3573",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "357302",
    "regency_id": "3573",
    "label": "Klojen",
    "value": "Klojen"
  },
  {
    "id": "357303",
    "regency_id": "3573",
    "label": "Kedungkandang",
    "value": "Kedungkandang"
  },
  { "id": "357304", "regency_id": "3573", "label": "Sukun", "value": "Sukun" },
  {
    "id": "357305",
    "regency_id": "3573",
    "label": "Lowokwaru",
    "value": "Lowokwaru"
  },
  {
    "id": "357401",
    "regency_id": "3574",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "357402",
    "regency_id": "3574",
    "label": "Wonoasih",
    "value": "Wonoasih"
  },
  {
    "id": "357403",
    "regency_id": "3574",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "357404",
    "regency_id": "3574",
    "label": "Kanigaran",
    "value": "Kanigaran"
  },
  {
    "id": "357405",
    "regency_id": "3574",
    "label": "Kedopok",
    "value": "Kedopok"
  },
  {
    "id": "357501",
    "regency_id": "3575",
    "label": "Gadingrejo",
    "value": "Gadingrejo"
  },
  {
    "id": "357502",
    "regency_id": "3575",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "357503",
    "regency_id": "3575",
    "label": "Bugul Kidul",
    "value": "Bugul Kidul"
  },
  {
    "id": "357504",
    "regency_id": "3575",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "357601",
    "regency_id": "3576",
    "label": "Prajuritkulon",
    "value": "Prajuritkulon"
  },
  {
    "id": "357602",
    "regency_id": "3576",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "357603",
    "regency_id": "3576",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "357701",
    "regency_id": "3577",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "357702",
    "regency_id": "3577",
    "label": "Manguharjo",
    "value": "Manguharjo"
  },
  { "id": "357703", "regency_id": "3577", "label": "Taman", "value": "Taman" },
  {
    "id": "357801",
    "regency_id": "3578",
    "label": "Karang Pilang",
    "value": "Karang Pilang"
  },
  {
    "id": "357802",
    "regency_id": "3578",
    "label": "Wonocolo",
    "value": "Wonocolo"
  },
  {
    "id": "357803",
    "regency_id": "3578",
    "label": "Rungkut",
    "value": "Rungkut"
  },
  {
    "id": "357804",
    "regency_id": "3578",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "357805",
    "regency_id": "3578",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "357806",
    "regency_id": "3578",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "357807",
    "regency_id": "3578",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "357808",
    "regency_id": "3578",
    "label": "Gubeng",
    "value": "Gubeng"
  },
  {
    "id": "357809",
    "regency_id": "3578",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "357810",
    "regency_id": "3578",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "357811",
    "regency_id": "3578",
    "label": "Simokerto",
    "value": "Simokerto"
  },
  {
    "id": "357812",
    "regency_id": "3578",
    "label": "Pabean Cantian",
    "value": "Pabean Cantian"
  },
  {
    "id": "357813",
    "regency_id": "3578",
    "label": "Bubutan",
    "value": "Bubutan"
  },
  {
    "id": "357814",
    "regency_id": "3578",
    "label": "Tandes",
    "value": "Tandes"
  },
  {
    "id": "357815",
    "regency_id": "3578",
    "label": "Krembangan",
    "value": "Krembangan"
  },
  {
    "id": "357816",
    "regency_id": "3578",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "357817",
    "regency_id": "3578",
    "label": "Kenjeran",
    "value": "Kenjeran"
  },
  {
    "id": "357818",
    "regency_id": "3578",
    "label": "Lakarsantri",
    "value": "Lakarsantri"
  },
  {
    "id": "357819",
    "regency_id": "3578",
    "label": "Benowo",
    "value": "Benowo"
  },
  {
    "id": "357820",
    "regency_id": "3578",
    "label": "Wiyung",
    "value": "Wiyung"
  },
  {
    "id": "357821",
    "regency_id": "3578",
    "label": "Dukuh Pakis",
    "value": "Dukuh Pakis"
  },
  {
    "id": "357822",
    "regency_id": "3578",
    "label": "Gayungan",
    "value": "Gayungan"
  },
  {
    "id": "357823",
    "regency_id": "3578",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "357824",
    "regency_id": "3578",
    "label": "Tenggilis Mejoyo",
    "value": "Tenggilis Mejoyo"
  },
  {
    "id": "357825",
    "regency_id": "3578",
    "label": "Gunung Anyar",
    "value": "Gunung Anyar"
  },
  {
    "id": "357826",
    "regency_id": "3578",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "357827",
    "regency_id": "3578",
    "label": "Sukomanunggal",
    "value": "Sukomanunggal"
  },
  {
    "id": "357828",
    "regency_id": "3578",
    "label": "Asem Rowo",
    "value": "Asem Rowo"
  },
  { "id": "357829", "regency_id": "3578", "label": "Bulak", "value": "Bulak" },
  { "id": "357830", "regency_id": "3578", "label": "Pakal", "value": "Pakal" },
  {
    "id": "357831",
    "regency_id": "3578",
    "label": "Sambikerep",
    "value": "Sambikerep"
  },
  { "id": "357901", "regency_id": "3579", "label": "Batu", "value": "Batu" },
  {
    "id": "357902",
    "regency_id": "3579",
    "label": "Bumiaji",
    "value": "Bumiaji"
  },
  {
    "id": "357903",
    "regency_id": "3579",
    "label": "Junrejo",
    "value": "Junrejo"
  },
  { "id": "360101", "regency_id": "3601", "label": "Sumur", "value": "Sumur" },
  {
    "id": "360102",
    "regency_id": "3601",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "360103",
    "regency_id": "3601",
    "label": "Cibaliung",
    "value": "Cibaliung"
  },
  {
    "id": "360104",
    "regency_id": "3601",
    "label": "Cikeusik",
    "value": "Cikeusik"
  },
  {
    "id": "360105",
    "regency_id": "3601",
    "label": "Cigeulis",
    "value": "Cigeulis"
  },
  {
    "id": "360106",
    "regency_id": "3601",
    "label": "Panimbang",
    "value": "Panimbang"
  },
  {
    "id": "360107",
    "regency_id": "3601",
    "label": "Angsana",
    "value": "Angsana"
  },
  {
    "id": "360108",
    "regency_id": "3601",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "360109",
    "regency_id": "3601",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "360110",
    "regency_id": "3601",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "360111",
    "regency_id": "3601",
    "label": "Picung",
    "value": "Picung"
  },
  {
    "id": "360112",
    "regency_id": "3601",
    "label": "Labuan",
    "value": "Labuan"
  },
  { "id": "360113", "regency_id": "3601", "label": "Menes", "value": "Menes" },
  {
    "id": "360114",
    "regency_id": "3601",
    "label": "Saketi",
    "value": "Saketi"
  },
  {
    "id": "360115",
    "regency_id": "3601",
    "label": "Cipeucang",
    "value": "Cipeucang"
  },
  { "id": "360116", "regency_id": "3601", "label": "Jiput", "value": "Jiput" },
  {
    "id": "360117",
    "regency_id": "3601",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "360118",
    "regency_id": "3601",
    "label": "Cimanuk",
    "value": "Cimanuk"
  },
  {
    "id": "360119",
    "regency_id": "3601",
    "label": "Kaduhejo",
    "value": "Kaduhejo"
  },
  {
    "id": "360120",
    "regency_id": "3601",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "360121",
    "regency_id": "3601",
    "label": "Pandeglang",
    "value": "Pandeglang"
  },
  {
    "id": "360122",
    "regency_id": "3601",
    "label": "Cadasari",
    "value": "Cadasari"
  },
  {
    "id": "360123",
    "regency_id": "3601",
    "label": "Cisata",
    "value": "Cisata"
  },
  { "id": "360124", "regency_id": "3601", "label": "Patia", "value": "Patia" },
  {
    "id": "360125",
    "regency_id": "3601",
    "label": "Karang Tanjung",
    "value": "Karang Tanjung"
  },
  {
    "id": "360126",
    "regency_id": "3601",
    "label": "Cikedal",
    "value": "Cikedal"
  },
  {
    "id": "360127",
    "regency_id": "3601",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "360128",
    "regency_id": "3601",
    "label": "Carita",
    "value": "Carita"
  },
  {
    "id": "360129",
    "regency_id": "3601",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "360130",
    "regency_id": "3601",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "360131",
    "regency_id": "3601",
    "label": "Sindangresmi",
    "value": "Sindangresmi"
  },
  {
    "id": "360132",
    "regency_id": "3601",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "360133",
    "regency_id": "3601",
    "label": "Koroncong",
    "value": "Koroncong"
  },
  {
    "id": "360134",
    "regency_id": "3601",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "360135",
    "regency_id": "3601",
    "label": "Sobang",
    "value": "Sobang"
  },
  {
    "id": "360201",
    "regency_id": "3602",
    "label": "Malingping",
    "value": "Malingping"
  },
  {
    "id": "360202",
    "regency_id": "3602",
    "label": "Panggarangan",
    "value": "Panggarangan"
  },
  { "id": "360203", "regency_id": "3602", "label": "Bayah", "value": "Bayah" },
  {
    "id": "360204",
    "regency_id": "3602",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "360205",
    "regency_id": "3602",
    "label": "Muncang",
    "value": "Muncang"
  },
  {
    "id": "360206",
    "regency_id": "3602",
    "label": "Leuwidamar",
    "value": "Leuwidamar"
  },
  {
    "id": "360207",
    "regency_id": "3602",
    "label": "Bojongmanik",
    "value": "Bojongmanik"
  },
  {
    "id": "360208",
    "regency_id": "3602",
    "label": "Gunungkencana",
    "value": "Gunungkencana"
  },
  {
    "id": "360209",
    "regency_id": "3602",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "360210",
    "regency_id": "3602",
    "label": "Cileles",
    "value": "Cileles"
  },
  {
    "id": "360211",
    "regency_id": "3602",
    "label": "Cimarga",
    "value": "Cimarga"
  },
  {
    "id": "360212",
    "regency_id": "3602",
    "label": "Sajira",
    "value": "Sajira"
  },
  { "id": "360213", "regency_id": "3602", "label": "Maja", "value": "Maja" },
  {
    "id": "360214",
    "regency_id": "3602",
    "label": "Rangkasbitung",
    "value": "Rangkasbitung"
  },
  {
    "id": "360215",
    "regency_id": "3602",
    "label": "Warunggunung",
    "value": "Warunggunung"
  },
  {
    "id": "360216",
    "regency_id": "3602",
    "label": "Cijaku",
    "value": "Cijaku"
  },
  {
    "id": "360217",
    "regency_id": "3602",
    "label": "Cikulur",
    "value": "Cikulur"
  },
  {
    "id": "360218",
    "regency_id": "3602",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "360219",
    "regency_id": "3602",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "360220",
    "regency_id": "3602",
    "label": "Cilograng",
    "value": "Cilograng"
  },
  {
    "id": "360221",
    "regency_id": "3602",
    "label": "Wanasalam",
    "value": "Wanasalam"
  },
  {
    "id": "360222",
    "regency_id": "3602",
    "label": "Sobang",
    "value": "Sobang"
  },
  {
    "id": "360223",
    "regency_id": "3602",
    "label": "Curug bitung",
    "value": "Curug bitung"
  },
  {
    "id": "360224",
    "regency_id": "3602",
    "label": "Kalanganyar",
    "value": "Kalanganyar"
  },
  {
    "id": "360225",
    "regency_id": "3602",
    "label": "Lebakgedong",
    "value": "Lebakgedong"
  },
  {
    "id": "360226",
    "regency_id": "3602",
    "label": "Cihara",
    "value": "Cihara"
  },
  {
    "id": "360227",
    "regency_id": "3602",
    "label": "Cirinten",
    "value": "Cirinten"
  },
  {
    "id": "360228",
    "regency_id": "3602",
    "label": "Cigemlong",
    "value": "Cigemlong"
  },
  {
    "id": "360301",
    "regency_id": "3603",
    "label": "Balaraja",
    "value": "Balaraja"
  },
  {
    "id": "360302",
    "regency_id": "3603",
    "label": "Jayanti",
    "value": "Jayanti"
  },
  {
    "id": "360303",
    "regency_id": "3603",
    "label": "Tigaraksa",
    "value": "Tigaraksa"
  },
  { "id": "360304", "regency_id": "3603", "label": "Jambe", "value": "Jambe" },
  {
    "id": "360305",
    "regency_id": "3603",
    "label": "Cisoka",
    "value": "Cisoka"
  },
  {
    "id": "360306",
    "regency_id": "3603",
    "label": "Kresek",
    "value": "Kresek"
  },
  {
    "id": "360307",
    "regency_id": "3603",
    "label": "Kronjo",
    "value": "Kronjo"
  },
  { "id": "360308", "regency_id": "3603", "label": "Mauk", "value": "Mauk" },
  {
    "id": "360309",
    "regency_id": "3603",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "360310",
    "regency_id": "3603",
    "label": "Sukadiri",
    "value": "Sukadiri"
  },
  { "id": "360311", "regency_id": "3603", "label": "Rajeg", "value": "Rajeg" },
  {
    "id": "360312",
    "regency_id": "3603",
    "label": "Pasar Kemis",
    "value": "Pasar Kemis"
  },
  {
    "id": "360313",
    "regency_id": "3603",
    "label": "Teluknaga",
    "value": "Teluknaga"
  },
  {
    "id": "360314",
    "regency_id": "3603",
    "label": "Kosambi",
    "value": "Kosambi"
  },
  {
    "id": "360315",
    "regency_id": "3603",
    "label": "Pakuhaji",
    "value": "Pakuhaji"
  },
  {
    "id": "360316",
    "regency_id": "3603",
    "label": "Sepatan",
    "value": "Sepatan"
  },
  { "id": "360317", "regency_id": "3603", "label": "Curug", "value": "Curug" },
  {
    "id": "360318",
    "regency_id": "3603",
    "label": "Cikupa",
    "value": "Cikupa"
  },
  {
    "id": "360319",
    "regency_id": "3603",
    "label": "Panongan",
    "value": "Panongan"
  },
  { "id": "360320", "regency_id": "3603", "label": "Legok", "value": "Legok" },
  {
    "id": "360322",
    "regency_id": "3603",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "360323",
    "regency_id": "3603",
    "label": "Cisauk",
    "value": "Cisauk"
  },
  {
    "id": "360327",
    "regency_id": "3603",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "360328",
    "regency_id": "3603",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "360329",
    "regency_id": "3603",
    "label": "Sindang Jaya",
    "value": "Sindang Jaya"
  },
  {
    "id": "360330",
    "regency_id": "3603",
    "label": "Sepatan Timur",
    "value": "Sepatan Timur"
  },
  {
    "id": "360331",
    "regency_id": "3603",
    "label": "Solear",
    "value": "Solear"
  },
  {
    "id": "360332",
    "regency_id": "3603",
    "label": "Gunung Kaler",
    "value": "Gunung Kaler"
  },
  {
    "id": "360333",
    "regency_id": "3603",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "360405",
    "regency_id": "3604",
    "label": "Kramatwatu",
    "value": "Kramatwatu"
  },
  {
    "id": "360406",
    "regency_id": "3604",
    "label": "Waringinkurung",
    "value": "Waringinkurung"
  },
  {
    "id": "360407",
    "regency_id": "3604",
    "label": "Bojonegara",
    "value": "Bojonegara"
  },
  {
    "id": "360408",
    "regency_id": "3604",
    "label": "Pulo Ampel",
    "value": "Pulo Ampel"
  },
  {
    "id": "360409",
    "regency_id": "3604",
    "label": "Ciruas",
    "value": "Ciruas"
  },
  {
    "id": "360411",
    "regency_id": "3604",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "360412",
    "regency_id": "3604",
    "label": "Pontang",
    "value": "Pontang"
  },
  {
    "id": "360413",
    "regency_id": "3604",
    "label": "Tirtayasa",
    "value": "Tirtayasa"
  },
  {
    "id": "360414",
    "regency_id": "3604",
    "label": "Tanara",
    "value": "Tanara"
  },
  {
    "id": "360415",
    "regency_id": "3604",
    "label": "Cikande",
    "value": "Cikande"
  },
  { "id": "360416", "regency_id": "3604", "label": "Kibin", "value": "Kibin" },
  {
    "id": "360417",
    "regency_id": "3604",
    "label": "Carenang",
    "value": "Carenang"
  },
  {
    "id": "360418",
    "regency_id": "3604",
    "label": "Binuang",
    "value": "Binuang"
  },
  { "id": "360419", "regency_id": "3604", "label": "Petir", "value": "Petir" },
  {
    "id": "360420",
    "regency_id": "3604",
    "label": "Tunjung Teja",
    "value": "Tunjung Teja"
  },
  { "id": "360422", "regency_id": "3604", "label": "Baros", "value": "Baros" },
  {
    "id": "360423",
    "regency_id": "3604",
    "label": "Cikeusal",
    "value": "Cikeusal"
  },
  {
    "id": "360424",
    "regency_id": "3604",
    "label": "Pamarayan",
    "value": "Pamarayan"
  },
  { "id": "360425", "regency_id": "3604", "label": "Kopo", "value": "Kopo" },
  {
    "id": "360426",
    "regency_id": "3604",
    "label": "Jawilan",
    "value": "Jawilan"
  },
  {
    "id": "360427",
    "regency_id": "3604",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "360428",
    "regency_id": "3604",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "360429",
    "regency_id": "3604",
    "label": "Padarincang",
    "value": "Padarincang"
  },
  { "id": "360430", "regency_id": "3604", "label": "Anyar", "value": "Anyar" },
  {
    "id": "360431",
    "regency_id": "3604",
    "label": "Cinangka",
    "value": "Cinangka"
  },
  {
    "id": "360432",
    "regency_id": "3604",
    "label": "Mancak",
    "value": "Mancak"
  },
  {
    "id": "360433",
    "regency_id": "3604",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "360434",
    "regency_id": "3604",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "360435",
    "regency_id": "3604",
    "label": "Lebak Wangi",
    "value": "Lebak Wangi"
  },
  {
    "id": "367101",
    "regency_id": "3671",
    "label": "Tangerang",
    "value": "Tangerang"
  },
  {
    "id": "367102",
    "regency_id": "3671",
    "label": "Jatiuwung",
    "value": "Jatiuwung"
  },
  {
    "id": "367103",
    "regency_id": "3671",
    "label": "Batuceper",
    "value": "Batuceper"
  },
  { "id": "367104", "regency_id": "3671", "label": "Benda", "value": "Benda" },
  {
    "id": "367105",
    "regency_id": "3671",
    "label": "Cipondoh",
    "value": "Cipondoh"
  },
  {
    "id": "367106",
    "regency_id": "3671",
    "label": "Ciledug",
    "value": "Ciledug"
  },
  {
    "id": "367107",
    "regency_id": "3671",
    "label": "Karawaci",
    "value": "Karawaci"
  },
  {
    "id": "367108",
    "regency_id": "3671",
    "label": "Periuk",
    "value": "Periuk"
  },
  {
    "id": "367109",
    "regency_id": "3671",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "367110",
    "regency_id": "3671",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "367111",
    "regency_id": "3671",
    "label": "Pinang",
    "value": "Pinang"
  },
  {
    "id": "367112",
    "regency_id": "3671",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "367113",
    "regency_id": "3671",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "367201",
    "regency_id": "3672",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "367202",
    "regency_id": "3672",
    "label": "Cilegon",
    "value": "Cilegon"
  },
  {
    "id": "367203",
    "regency_id": "3672",
    "label": "Pulomerak",
    "value": "Pulomerak"
  },
  {
    "id": "367204",
    "regency_id": "3672",
    "label": "Ciwandan",
    "value": "Ciwandan"
  },
  {
    "id": "367205",
    "regency_id": "3672",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "367206",
    "regency_id": "3672",
    "label": "Gerogol",
    "value": "Gerogol"
  },
  {
    "id": "367207",
    "regency_id": "3672",
    "label": "Purwakarta",
    "value": "Purwakarta"
  },
  {
    "id": "367208",
    "regency_id": "3672",
    "label": "Citangkil",
    "value": "Citangkil"
  },
  {
    "id": "367301",
    "regency_id": "3673",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "367302",
    "regency_id": "3673",
    "label": "Kasemen",
    "value": "Kasemen"
  },
  {
    "id": "367303",
    "regency_id": "3673",
    "label": "Walantaka",
    "value": "Walantaka"
  },
  { "id": "367304", "regency_id": "3673", "label": "Curug", "value": "Curug" },
  {
    "id": "367305",
    "regency_id": "3673",
    "label": "Cipocok Jaya",
    "value": "Cipocok Jaya"
  },
  {
    "id": "367306",
    "regency_id": "3673",
    "label": "Taktakan",
    "value": "Taktakan"
  },
  {
    "id": "367401",
    "regency_id": "3674",
    "label": "Serpong",
    "value": "Serpong"
  },
  {
    "id": "367402",
    "regency_id": "3674",
    "label": "Serpong Utara",
    "value": "Serpong Utara"
  },
  {
    "id": "367403",
    "regency_id": "3674",
    "label": "Pondok Aren",
    "value": "Pondok Aren"
  },
  {
    "id": "367404",
    "regency_id": "3674",
    "label": "Ciputat",
    "value": "Ciputat"
  },
  {
    "id": "367405",
    "regency_id": "3674",
    "label": "Ciputat Timur",
    "value": "Ciputat Timur"
  },
  {
    "id": "367406",
    "regency_id": "3674",
    "label": "Pamulang",
    "value": "Pamulang"
  },
  { "id": "367407", "regency_id": "3674", "label": "Setu", "value": "Setu" },
  {
    "id": "510101",
    "regency_id": "5101",
    "label": "Negara",
    "value": "Negara"
  },
  {
    "id": "510102",
    "regency_id": "5101",
    "label": "Mendoyo",
    "value": "Mendoyo"
  },
  {
    "id": "510103",
    "regency_id": "5101",
    "label": "Pekutatan",
    "value": "Pekutatan"
  },
  {
    "id": "510104",
    "regency_id": "5101",
    "label": "Melaya",
    "value": "Melaya"
  },
  {
    "id": "510105",
    "regency_id": "5101",
    "label": "Jembrana",
    "value": "Jembrana"
  },
  {
    "id": "510201",
    "regency_id": "5102",
    "label": "Selemadeg",
    "value": "Selemadeg"
  },
  {
    "id": "510202",
    "regency_id": "5102",
    "label": "Salamadeg Timur",
    "value": "Salamadeg Timur"
  },
  {
    "id": "510203",
    "regency_id": "5102",
    "label": "Salemadeg Barat",
    "value": "Salemadeg Barat"
  },
  {
    "id": "510204",
    "regency_id": "5102",
    "label": "Kerambitan",
    "value": "Kerambitan"
  },
  {
    "id": "510205",
    "regency_id": "5102",
    "label": "Tabanan",
    "value": "Tabanan"
  },
  {
    "id": "510206",
    "regency_id": "5102",
    "label": "Kediri",
    "value": "Kediri"
  },
  { "id": "510207", "regency_id": "5102", "label": "Marga", "value": "Marga" },
  {
    "id": "510208",
    "regency_id": "5102",
    "label": "Penebel",
    "value": "Penebel"
  },
  {
    "id": "510209",
    "regency_id": "5102",
    "label": "Baturiti",
    "value": "Baturiti"
  },
  {
    "id": "510210",
    "regency_id": "5102",
    "label": "Pupuan",
    "value": "Pupuan"
  },
  { "id": "510301", "regency_id": "5103", "label": "Kuta", "value": "Kuta" },
  {
    "id": "510302",
    "regency_id": "5103",
    "label": "Mengwi",
    "value": "Mengwi"
  },
  {
    "id": "510303",
    "regency_id": "5103",
    "label": "Abiansemal",
    "value": "Abiansemal"
  },
  {
    "id": "510304",
    "regency_id": "5103",
    "label": "Petang",
    "value": "Petang"
  },
  {
    "id": "510305",
    "regency_id": "5103",
    "label": "Kuta Selatan",
    "value": "Kuta Selatan"
  },
  {
    "id": "510306",
    "regency_id": "5103",
    "label": "Kuta Utara",
    "value": "Kuta Utara"
  },
  {
    "id": "510401",
    "regency_id": "5104",
    "label": "Sukawati",
    "value": "Sukawati"
  },
  {
    "id": "510402",
    "regency_id": "5104",
    "label": "Blahbatuh",
    "value": "Blahbatuh"
  },
  {
    "id": "510403",
    "regency_id": "5104",
    "label": "Gianyar",
    "value": "Gianyar"
  },
  {
    "id": "510404",
    "regency_id": "5104",
    "label": "Tampaksiring",
    "value": "Tampaksiring"
  },
  { "id": "510405", "regency_id": "5104", "label": "Ubud", "value": "Ubud" },
  {
    "id": "510406",
    "regency_id": "5104",
    "label": "Tegallalang",
    "value": "Tegallalang"
  },
  {
    "id": "510407",
    "regency_id": "5104",
    "label": "Payangan",
    "value": "Payangan"
  },
  {
    "id": "510501",
    "regency_id": "5105",
    "label": "Nusa Penida",
    "value": "Nusa Penida"
  },
  {
    "id": "510502",
    "regency_id": "5105",
    "label": "Banjarangkan",
    "value": "Banjarangkan"
  },
  {
    "id": "510503",
    "regency_id": "5105",
    "label": "Klungkung",
    "value": "Klungkung"
  },
  { "id": "510504", "regency_id": "5105", "label": "Dawan", "value": "Dawan" },
  { "id": "510601", "regency_id": "5106", "label": "Susut", "value": "Susut" },
  {
    "id": "510602",
    "regency_id": "5106",
    "label": "Bangli",
    "value": "Bangli"
  },
  {
    "id": "510603",
    "regency_id": "5106",
    "label": "Tembuku",
    "value": "Tembuku"
  },
  {
    "id": "510604",
    "regency_id": "5106",
    "label": "Kintamani",
    "value": "Kintamani"
  },
  {
    "id": "510701",
    "regency_id": "5107",
    "label": "Rendang",
    "value": "Rendang"
  },
  {
    "id": "510702",
    "regency_id": "5107",
    "label": "Sidemen",
    "value": "Sidemen"
  },
  {
    "id": "510703",
    "regency_id": "5107",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "510704",
    "regency_id": "5107",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  { "id": "510705", "regency_id": "5107", "label": "Abang", "value": "Abang" },
  {
    "id": "510706",
    "regency_id": "5107",
    "label": "Bebandem",
    "value": "Bebandem"
  },
  { "id": "510707", "regency_id": "5107", "label": "Selat", "value": "Selat" },
  { "id": "510708", "regency_id": "5107", "label": "Kubu", "value": "Kubu" },
  {
    "id": "510801",
    "regency_id": "5108",
    "label": "Gerokgak",
    "value": "Gerokgak"
  },
  {
    "id": "510802",
    "regency_id": "5108",
    "label": "Seririt",
    "value": "Seririt"
  },
  {
    "id": "510803",
    "regency_id": "5108",
    "label": "Busungbiu",
    "value": "Busungbiu"
  },
  {
    "id": "510804",
    "regency_id": "5108",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "510805",
    "regency_id": "5108",
    "label": "Sukasada",
    "value": "Sukasada"
  },
  {
    "id": "510806",
    "regency_id": "5108",
    "label": "Buleleng",
    "value": "Buleleng"
  },
  { "id": "510807", "regency_id": "5108", "label": "Sawan", "value": "Sawan" },
  {
    "id": "510808",
    "regency_id": "5108",
    "label": "Kubutambahan",
    "value": "Kubutambahan"
  },
  {
    "id": "510809",
    "regency_id": "5108",
    "label": "Tejakula",
    "value": "Tejakula"
  },
  {
    "id": "517101",
    "regency_id": "5171",
    "label": "Denpasar Selatan",
    "value": "Denpasar Selatan"
  },
  {
    "id": "517102",
    "regency_id": "5171",
    "label": "Denpasar Timur",
    "value": "Denpasar Timur"
  },
  {
    "id": "517103",
    "regency_id": "5171",
    "label": "Denpasar Barat",
    "value": "Denpasar Barat"
  },
  {
    "id": "517104",
    "regency_id": "5171",
    "label": "Denpasar Utara",
    "value": "Denpasar Utara"
  },
  {
    "id": "520101",
    "regency_id": "5201",
    "label": "Gerung",
    "value": "Gerung"
  },
  {
    "id": "520102",
    "regency_id": "5201",
    "label": "Kediri",
    "value": "Kediri"
  },
  {
    "id": "520103",
    "regency_id": "5201",
    "label": "Narmada",
    "value": "Narmada"
  },
  {
    "id": "520107",
    "regency_id": "5201",
    "label": "Sekotong",
    "value": "Sekotong"
  },
  {
    "id": "520108",
    "regency_id": "5201",
    "label": "Labuapi",
    "value": "Labuapi"
  },
  {
    "id": "520109",
    "regency_id": "5201",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "520112",
    "regency_id": "5201",
    "label": "Lingsar",
    "value": "Lingsar"
  },
  {
    "id": "520113",
    "regency_id": "5201",
    "label": "Lembar",
    "value": "Lembar"
  },
  {
    "id": "520114",
    "regency_id": "5201",
    "label": "Batu Layar",
    "value": "Batu Layar"
  },
  {
    "id": "520115",
    "regency_id": "5201",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  { "id": "520201", "regency_id": "5202", "label": "Praya", "value": "Praya" },
  {
    "id": "520202",
    "regency_id": "5202",
    "label": "Jonggat",
    "value": "Jonggat"
  },
  {
    "id": "520203",
    "regency_id": "5202",
    "label": "Batukliang",
    "value": "Batukliang"
  },
  { "id": "520204", "regency_id": "5202", "label": "Pujut", "value": "Pujut" },
  {
    "id": "520205",
    "regency_id": "5202",
    "label": "Praya Barat",
    "value": "Praya Barat"
  },
  {
    "id": "520206",
    "regency_id": "5202",
    "label": "Praya Timur",
    "value": "Praya Timur"
  },
  {
    "id": "520207",
    "regency_id": "5202",
    "label": "Janapria",
    "value": "Janapria"
  },
  {
    "id": "520208",
    "regency_id": "5202",
    "label": "Pringgarata",
    "value": "Pringgarata"
  },
  {
    "id": "520209",
    "regency_id": "5202",
    "label": "Kopang",
    "value": "Kopang"
  },
  {
    "id": "520210",
    "regency_id": "5202",
    "label": "Praya Tengah",
    "value": "Praya Tengah"
  },
  {
    "id": "520211",
    "regency_id": "5202",
    "label": "Praya Barat Daya",
    "value": "Praya Barat Daya"
  },
  {
    "id": "520212",
    "regency_id": "5202",
    "label": "Batukliang Utara",
    "value": "Batukliang Utara"
  },
  {
    "id": "520301",
    "regency_id": "5203",
    "label": "Keruak",
    "value": "Keruak"
  },
  { "id": "520302", "regency_id": "5203", "label": "Sakra", "value": "Sakra" },
  {
    "id": "520303",
    "regency_id": "5203",
    "label": "Terara",
    "value": "Terara"
  },
  { "id": "520304", "regency_id": "5203", "label": "Sikur", "value": "Sikur" },
  {
    "id": "520305",
    "regency_id": "5203",
    "label": "Masbagik",
    "value": "Masbagik"
  },
  {
    "id": "520306",
    "regency_id": "5203",
    "label": "Sukamulia",
    "value": "Sukamulia"
  },
  {
    "id": "520307",
    "regency_id": "5203",
    "label": "Selong",
    "value": "Selong"
  },
  {
    "id": "520308",
    "regency_id": "5203",
    "label": "Pringgabaya",
    "value": "Pringgabaya"
  },
  {
    "id": "520309",
    "regency_id": "5203",
    "label": "Aikmel",
    "value": "Aikmel"
  },
  {
    "id": "520310",
    "regency_id": "5203",
    "label": "Sambelia",
    "value": "Sambelia"
  },
  {
    "id": "520311",
    "regency_id": "5203",
    "label": "Montong Gading",
    "value": "Montong Gading"
  },
  {
    "id": "520312",
    "regency_id": "5203",
    "label": "Pringgasela",
    "value": "Pringgasela"
  },
  {
    "id": "520313",
    "regency_id": "5203",
    "label": "Suralaga",
    "value": "Suralaga"
  },
  {
    "id": "520314",
    "regency_id": "5203",
    "label": "Wanasaba",
    "value": "Wanasaba"
  },
  {
    "id": "520315",
    "regency_id": "5203",
    "label": "Sembalun",
    "value": "Sembalun"
  },
  {
    "id": "520316",
    "regency_id": "5203",
    "label": "Suwela",
    "value": "Suwela"
  },
  {
    "id": "520317",
    "regency_id": "5203",
    "label": "Labuhan Haji",
    "value": "Labuhan Haji"
  },
  {
    "id": "520318",
    "regency_id": "5203",
    "label": "Sakra Timur",
    "value": "Sakra Timur"
  },
  {
    "id": "520319",
    "regency_id": "5203",
    "label": "Sakra Barat",
    "value": "Sakra Barat"
  },
  {
    "id": "520320",
    "regency_id": "5203",
    "label": "Jerowaru",
    "value": "Jerowaru"
  },
  { "id": "520321", "regency_id": "5203", "label": "Lenek", "value": "Lenek" },
  {
    "id": "520402",
    "regency_id": "5204",
    "label": "Lunyuk",
    "value": "Lunyuk"
  },
  { "id": "520405", "regency_id": "5204", "label": "Alas", "value": "Alas" },
  { "id": "520406", "regency_id": "5204", "label": "Utan", "value": "Utan" },
  {
    "id": "520407",
    "regency_id": "5204",
    "label": "Batu Lanteh",
    "value": "Batu Lanteh"
  },
  {
    "id": "520408",
    "regency_id": "5204",
    "label": "Sumbawa",
    "value": "Sumbawa"
  },
  {
    "id": "520409",
    "regency_id": "5204",
    "label": "Moyo Hilir",
    "value": "Moyo Hilir"
  },
  {
    "id": "520410",
    "regency_id": "5204",
    "label": "Moyo Hulu",
    "value": "Moyo Hulu"
  },
  {
    "id": "520411",
    "regency_id": "5204",
    "label": "Ropang",
    "value": "Ropang"
  },
  { "id": "520412", "regency_id": "5204", "label": "Lape", "value": "Lape" },
  {
    "id": "520413",
    "regency_id": "5204",
    "label": "Plampang",
    "value": "Plampang"
  },
  {
    "id": "520414",
    "regency_id": "5204",
    "label": "Empang",
    "value": "Empang"
  },
  {
    "id": "520417",
    "regency_id": "5204",
    "label": "Alas Barat",
    "value": "Alas Barat"
  },
  {
    "id": "520418",
    "regency_id": "5204",
    "label": "Labuhan Badas",
    "value": "Labuhan Badas"
  },
  {
    "id": "520419",
    "regency_id": "5204",
    "label": "Labangka",
    "value": "Labangka"
  },
  { "id": "520420", "regency_id": "5204", "label": "Buer", "value": "Buer" },
  { "id": "520421", "regency_id": "5204", "label": "Rhee", "value": "Rhee" },
  {
    "id": "520422",
    "regency_id": "5204",
    "label": "Unter Iwes",
    "value": "Unter Iwes"
  },
  {
    "id": "520423",
    "regency_id": "5204",
    "label": "Moyo Utara",
    "value": "Moyo Utara"
  },
  {
    "id": "520424",
    "regency_id": "5204",
    "label": "Maronge",
    "value": "Maronge"
  },
  {
    "id": "520425",
    "regency_id": "5204",
    "label": "Tarano",
    "value": "Tarano"
  },
  { "id": "520426", "regency_id": "5204", "label": "Lopok", "value": "Lopok" },
  {
    "id": "520427",
    "regency_id": "5204",
    "label": "Lenangguar",
    "value": "Lenangguar"
  },
  {
    "id": "520428",
    "regency_id": "5204",
    "label": "Orong Telu",
    "value": "Orong Telu"
  },
  {
    "id": "520429",
    "regency_id": "5204",
    "label": "Lantung",
    "value": "Lantung"
  },
  { "id": "520501", "regency_id": "5205", "label": "Dompu", "value": "Dompu" },
  { "id": "520502", "regency_id": "5205", "label": "Kempo", "value": "Kempo" },
  { "id": "520503", "regency_id": "5205", "label": "Hu'u", "value": "Hu'u" },
  { "id": "520504", "regency_id": "5205", "label": "Kilo", "value": "Kilo" },
  { "id": "520505", "regency_id": "5205", "label": "Woja", "value": "Woja" },
  { "id": "520506", "regency_id": "5205", "label": "Pekat", "value": "Pekat" },
  {
    "id": "520507",
    "regency_id": "5205",
    "label": "Manggalewa",
    "value": "Manggalewa"
  },
  { "id": "520508", "regency_id": "5205", "label": "Pajo", "value": "Pajo" },
  { "id": "520601", "regency_id": "5206", "label": "Monta", "value": "Monta" },
  { "id": "520602", "regency_id": "5206", "label": "Bolo", "value": "Bolo" },
  { "id": "520603", "regency_id": "5206", "label": "Woha", "value": "Woha" },
  { "id": "520604", "regency_id": "5206", "label": "Belo", "value": "Belo" },
  { "id": "520605", "regency_id": "5206", "label": "Wawo", "value": "Wawo" },
  { "id": "520606", "regency_id": "5206", "label": "Sape", "value": "Sape" },
  { "id": "520607", "regency_id": "5206", "label": "Wera", "value": "Wera" },
  {
    "id": "520608",
    "regency_id": "5206",
    "label": "Donggo",
    "value": "Donggo"
  },
  {
    "id": "520609",
    "regency_id": "5206",
    "label": "Sanggar",
    "value": "Sanggar"
  },
  {
    "id": "520610",
    "regency_id": "5206",
    "label": "Ambalawi",
    "value": "Ambalawi"
  },
  {
    "id": "520611",
    "regency_id": "5206",
    "label": "Langgudu",
    "value": "Langgudu"
  },
  { "id": "520612", "regency_id": "5206", "label": "Lambu", "value": "Lambu" },
  {
    "id": "520613",
    "regency_id": "5206",
    "label": "Madapangga",
    "value": "Madapangga"
  },
  {
    "id": "520614",
    "regency_id": "5206",
    "label": "Tambora",
    "value": "Tambora"
  },
  {
    "id": "520615",
    "regency_id": "5206",
    "label": "Soromandi",
    "value": "Soromandi"
  },
  {
    "id": "520616",
    "regency_id": "5206",
    "label": "Parado",
    "value": "Parado"
  },
  {
    "id": "520617",
    "regency_id": "5206",
    "label": "Lambitu",
    "value": "Lambitu"
  },
  {
    "id": "520618",
    "regency_id": "5206",
    "label": "Palibelo",
    "value": "Palibelo"
  },
  {
    "id": "520701",
    "regency_id": "5207",
    "label": "Jereweh",
    "value": "Jereweh"
  },
  {
    "id": "520702",
    "regency_id": "5207",
    "label": "Taliwang",
    "value": "Taliwang"
  },
  {
    "id": "520703",
    "regency_id": "5207",
    "label": "Seteluk",
    "value": "Seteluk"
  },
  {
    "id": "520704",
    "regency_id": "5207",
    "label": "Sekongkang",
    "value": "Sekongkang"
  },
  {
    "id": "520705",
    "regency_id": "5207",
    "label": "Brang Rea",
    "value": "Brang Rea"
  },
  {
    "id": "520706",
    "regency_id": "5207",
    "label": "Poto Tano",
    "value": "Poto Tano"
  },
  {
    "id": "520707",
    "regency_id": "5207",
    "label": "Brang Ene",
    "value": "Brang Ene"
  },
  { "id": "520708", "regency_id": "5207", "label": "Maluk", "value": "Maluk" },
  {
    "id": "520801",
    "regency_id": "5208",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "520802",
    "regency_id": "5208",
    "label": "Gangga",
    "value": "Gangga"
  },
  {
    "id": "520803",
    "regency_id": "5208",
    "label": "Kayangan",
    "value": "Kayangan"
  },
  { "id": "520804", "regency_id": "5208", "label": "Bayan", "value": "Bayan" },
  {
    "id": "520805",
    "regency_id": "5208",
    "label": "Pemenang",
    "value": "Pemenang"
  },
  {
    "id": "527101",
    "regency_id": "5271",
    "label": "Ampenan",
    "value": "Ampenan"
  },
  {
    "id": "527102",
    "regency_id": "5271",
    "label": "Mataram",
    "value": "Mataram"
  },
  {
    "id": "527103",
    "regency_id": "5271",
    "label": "Cakranegara",
    "value": "Cakranegara"
  },
  {
    "id": "527104",
    "regency_id": "5271",
    "label": "Sekarbela",
    "value": "Sekarbela"
  },
  {
    "id": "527105",
    "regency_id": "5271",
    "label": "Selaprang",
    "value": "Selaprang"
  },
  {
    "id": "527106",
    "regency_id": "5271",
    "label": "Sandubaya",
    "value": "Sandubaya"
  },
  {
    "id": "527201",
    "regency_id": "5272",
    "label": "Rasanae Barat",
    "value": "Rasanae Barat"
  },
  {
    "id": "527202",
    "regency_id": "5272",
    "label": "Rasanae Timur",
    "value": "Rasanae Timur"
  },
  {
    "id": "527203",
    "regency_id": "5272",
    "label": "Asakota",
    "value": "Asakota"
  },
  { "id": "527204", "regency_id": "5272", "label": "Raba", "value": "Raba" },
  {
    "id": "527205",
    "regency_id": "5272",
    "label": "Mpunda",
    "value": "Mpunda"
  },
  { "id": "530104", "regency_id": "5301", "label": "Semau", "value": "Semau" },
  {
    "id": "530105",
    "regency_id": "5301",
    "label": "Kupang Barat",
    "value": "Kupang Barat"
  },
  {
    "id": "530106",
    "regency_id": "5301",
    "label": "Kupang Timur",
    "value": "Kupang Timur"
  },
  {
    "id": "530107",
    "regency_id": "5301",
    "label": "Sulamu",
    "value": "Sulamu"
  },
  {
    "id": "530108",
    "regency_id": "5301",
    "label": "Kupang Tengah",
    "value": "Kupang Tengah"
  },
  {
    "id": "530109",
    "regency_id": "5301",
    "label": "Amarasi",
    "value": "Amarasi"
  },
  {
    "id": "530110",
    "regency_id": "5301",
    "label": "Fatuleu",
    "value": "Fatuleu"
  },
  {
    "id": "530111",
    "regency_id": "5301",
    "label": "Takari",
    "value": "Takari"
  },
  {
    "id": "530112",
    "regency_id": "5301",
    "label": "Amfoang Selatan",
    "value": "Amfoang Selatan"
  },
  {
    "id": "530113",
    "regency_id": "5301",
    "label": "Amfoang Utara",
    "value": "Amfoang Utara"
  },
  {
    "id": "530116",
    "regency_id": "5301",
    "label": "Nekamese",
    "value": "Nekamese"
  },
  {
    "id": "530117",
    "regency_id": "5301",
    "label": "Amarasi Barat",
    "value": "Amarasi Barat"
  },
  {
    "id": "530118",
    "regency_id": "5301",
    "label": "Amarasi Selatan",
    "value": "Amarasi Selatan"
  },
  {
    "id": "530119",
    "regency_id": "5301",
    "label": "Amarasi Timur",
    "value": "Amarasi Timur"
  },
  {
    "id": "530120",
    "regency_id": "5301",
    "label": "Amabi Oefeto Timur",
    "value": "Amabi Oefeto Timur"
  },
  {
    "id": "530121",
    "regency_id": "5301",
    "label": "Amfoang Barat Daya",
    "value": "Amfoang Barat Daya"
  },
  {
    "id": "530122",
    "regency_id": "5301",
    "label": "Amfoang Barat Laut",
    "value": "Amfoang Barat Laut"
  },
  {
    "id": "530123",
    "regency_id": "5301",
    "label": "Semau Selatan",
    "value": "Semau Selatan"
  },
  {
    "id": "530124",
    "regency_id": "5301",
    "label": "Taebenu",
    "value": "Taebenu"
  },
  {
    "id": "530125",
    "regency_id": "5301",
    "label": "Amabi Oefeto",
    "value": "Amabi Oefeto"
  },
  {
    "id": "530126",
    "regency_id": "5301",
    "label": "Amfoang Timur",
    "value": "Amfoang Timur"
  },
  {
    "id": "530127",
    "regency_id": "5301",
    "label": "Fatuleu Barat",
    "value": "Fatuleu Barat"
  },
  {
    "id": "530128",
    "regency_id": "5301",
    "label": "Fatuleu Tengah",
    "value": "Fatuleu Tengah"
  },
  {
    "id": "530130",
    "regency_id": "5301",
    "label": "Amfoang Tengah",
    "value": "Amfoang Tengah"
  },
  {
    "id": "530201",
    "regency_id": "5302",
    "label": "Kota Soe",
    "value": "Kota Soe"
  },
  {
    "id": "530202",
    "regency_id": "5302",
    "label": "Mollo Selatan",
    "value": "Mollo Selatan"
  },
  {
    "id": "530203",
    "regency_id": "5302",
    "label": "Mollo Utara",
    "value": "Mollo Utara"
  },
  {
    "id": "530204",
    "regency_id": "5302",
    "label": "Amanuban Timur",
    "value": "Amanuban Timur"
  },
  {
    "id": "530205",
    "regency_id": "5302",
    "label": "Amanuban Tengah",
    "value": "Amanuban Tengah"
  },
  {
    "id": "530206",
    "regency_id": "5302",
    "label": "Amanuban Selatan",
    "value": "Amanuban Selatan"
  },
  {
    "id": "530207",
    "regency_id": "5302",
    "label": "Amanuban Barat",
    "value": "Amanuban Barat"
  },
  {
    "id": "530208",
    "regency_id": "5302",
    "label": "Amanatun Selatan",
    "value": "Amanatun Selatan"
  },
  {
    "id": "530209",
    "regency_id": "5302",
    "label": "Amanatun Utara",
    "value": "Amanatun Utara"
  },
  { "id": "530210", "regency_id": "5302", "label": "KI'E", "value": "KI'E" },
  {
    "id": "530211",
    "regency_id": "5302",
    "label": "Kuanfatu",
    "value": "Kuanfatu"
  },
  {
    "id": "530212",
    "regency_id": "5302",
    "label": "Fatumnasi",
    "value": "Fatumnasi"
  },
  { "id": "530213", "regency_id": "5302", "label": "Polen", "value": "Polen" },
  {
    "id": "530214",
    "regency_id": "5302",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "530215",
    "regency_id": "5302",
    "label": "Boking",
    "value": "Boking"
  },
  {
    "id": "530216",
    "regency_id": "5302",
    "label": "Toianas",
    "value": "Toianas"
  },
  {
    "id": "530217",
    "regency_id": "5302",
    "label": "Nunkolo",
    "value": "Nunkolo"
  },
  {
    "id": "530218",
    "regency_id": "5302",
    "label": "Oenino",
    "value": "Oenino"
  },
  {
    "id": "530219",
    "regency_id": "5302",
    "label": "Kolbano",
    "value": "Kolbano"
  },
  {
    "id": "530220",
    "regency_id": "5302",
    "label": "Kot olin",
    "value": "Kot olin"
  },
  {
    "id": "530221",
    "regency_id": "5302",
    "label": "Kualin",
    "value": "Kualin"
  },
  {
    "id": "530222",
    "regency_id": "5302",
    "label": "Mollo Barat",
    "value": "Mollo Barat"
  },
  {
    "id": "530223",
    "regency_id": "5302",
    "label": "Kok Baun",
    "value": "Kok Baun"
  },
  {
    "id": "530224",
    "regency_id": "5302",
    "label": "Noebana",
    "value": "Noebana"
  },
  {
    "id": "530225",
    "regency_id": "5302",
    "label": "Santian",
    "value": "Santian"
  },
  {
    "id": "530226",
    "regency_id": "5302",
    "label": "Noebeba",
    "value": "Noebeba"
  },
  {
    "id": "530227",
    "regency_id": "5302",
    "label": "Kuatnana",
    "value": "Kuatnana"
  },
  {
    "id": "530228",
    "regency_id": "5302",
    "label": "Fautmolo",
    "value": "Fautmolo"
  },
  {
    "id": "530229",
    "regency_id": "5302",
    "label": "Fatukopa",
    "value": "Fatukopa"
  },
  {
    "id": "530230",
    "regency_id": "5302",
    "label": "Mollo Tengah",
    "value": "Mollo Tengah"
  },
  { "id": "530231", "regency_id": "5302", "label": "Tobu", "value": "Tobu" },
  {
    "id": "530232",
    "regency_id": "5302",
    "label": "Nunbena",
    "value": "Nunbena"
  },
  {
    "id": "530301",
    "regency_id": "5303",
    "label": "Miomafo Timur",
    "value": "Miomafo Timur"
  },
  {
    "id": "530302",
    "regency_id": "5303",
    "label": "Miomafo Barat",
    "value": "Miomafo Barat"
  },
  {
    "id": "530303",
    "regency_id": "5303",
    "label": "Biboki Selatan",
    "value": "Biboki Selatan"
  },
  {
    "id": "530304",
    "regency_id": "5303",
    "label": "Noemuti",
    "value": "Noemuti"
  },
  {
    "id": "530305",
    "regency_id": "5303",
    "label": "Kota Kefamenanu",
    "value": "Kota Kefamenanu"
  },
  {
    "id": "530306",
    "regency_id": "5303",
    "label": "Biboki Utara",
    "value": "Biboki Utara"
  },
  {
    "id": "530307",
    "regency_id": "5303",
    "label": "Biboki Anleu",
    "value": "Biboki Anleu"
  },
  {
    "id": "530308",
    "regency_id": "5303",
    "label": "Insana",
    "value": "Insana"
  },
  {
    "id": "530309",
    "regency_id": "5303",
    "label": "Insana Utara",
    "value": "Insana Utara"
  },
  {
    "id": "530310",
    "regency_id": "5303",
    "label": "Noemuti Timur",
    "value": "Noemuti Timur"
  },
  {
    "id": "530311",
    "regency_id": "5303",
    "label": "Miomaffo Tengah",
    "value": "Miomaffo Tengah"
  },
  { "id": "530312", "regency_id": "5303", "label": "Musi", "value": "Musi" },
  { "id": "530313", "regency_id": "5303", "label": "Mutis", "value": "Mutis" },
  {
    "id": "530314",
    "regency_id": "5303",
    "label": "Bikomi Selatan",
    "value": "Bikomi Selatan"
  },
  {
    "id": "530315",
    "regency_id": "5303",
    "label": "Bikomi Tengah",
    "value": "Bikomi Tengah"
  },
  {
    "id": "530316",
    "regency_id": "5303",
    "label": "Bikomi Nilulat",
    "value": "Bikomi Nilulat"
  },
  {
    "id": "530317",
    "regency_id": "5303",
    "label": "Bikomi Utara",
    "value": "Bikomi Utara"
  },
  {
    "id": "530318",
    "regency_id": "5303",
    "label": "Naibenu",
    "value": "Naibenu"
  },
  {
    "id": "530319",
    "regency_id": "5303",
    "label": "Insana Fafinesu",
    "value": "Insana Fafinesu"
  },
  {
    "id": "530320",
    "regency_id": "5303",
    "label": "Insana Barat",
    "value": "Insana Barat"
  },
  {
    "id": "530321",
    "regency_id": "5303",
    "label": "Insana Tengah",
    "value": "Insana Tengah"
  },
  {
    "id": "530322",
    "regency_id": "5303",
    "label": "Biboki Tan Pah",
    "value": "Biboki Tan Pah"
  },
  {
    "id": "530323",
    "regency_id": "5303",
    "label": "Biboki Moenleu",
    "value": "Biboki Moenleu"
  },
  {
    "id": "530324",
    "regency_id": "5303",
    "label": "Biboki Feotleu",
    "value": "Biboki Feotleu"
  },
  {
    "id": "530401",
    "regency_id": "5304",
    "label": "Lamaknen",
    "value": "Lamaknen"
  },
  {
    "id": "530402",
    "regency_id": "5304",
    "label": "TasifetoTimur",
    "value": "TasifetoTimur"
  },
  {
    "id": "530403",
    "regency_id": "5304",
    "label": "Raihat",
    "value": "Raihat"
  },
  {
    "id": "530404",
    "regency_id": "5304",
    "label": "Tasifeto Barat",
    "value": "Tasifeto Barat"
  },
  {
    "id": "530405",
    "regency_id": "5304",
    "label": "Kakuluk Mesak",
    "value": "Kakuluk Mesak"
  },
  {
    "id": "530412",
    "regency_id": "5304",
    "label": "Kota Atambua",
    "value": "Kota Atambua"
  },
  {
    "id": "530413",
    "regency_id": "5304",
    "label": "Raimanuk",
    "value": "Raimanuk"
  },
  {
    "id": "530417",
    "regency_id": "5304",
    "label": "Lasiolat",
    "value": "Lasiolat"
  },
  {
    "id": "530418",
    "regency_id": "5304",
    "label": "Lamaknen Selatan",
    "value": "Lamaknen Selatan"
  },
  {
    "id": "530421",
    "regency_id": "5304",
    "label": "Atambua Barat",
    "value": "Atambua Barat"
  },
  {
    "id": "530422",
    "regency_id": "5304",
    "label": "Atambua Selatan",
    "value": "Atambua Selatan"
  },
  {
    "id": "530423",
    "regency_id": "5304",
    "label": "Nanaet Duabesi",
    "value": "Nanaet Duabesi"
  },
  {
    "id": "530501",
    "regency_id": "5305",
    "label": "Teluk Mutiara",
    "value": "Teluk Mutiara"
  },
  {
    "id": "530502",
    "regency_id": "5305",
    "label": "Alor Barat Laut",
    "value": "Alor Barat Laut"
  },
  {
    "id": "530503",
    "regency_id": "5305",
    "label": "Alor Barat Daya",
    "value": "Alor Barat Daya"
  },
  {
    "id": "530504",
    "regency_id": "5305",
    "label": "Alor Selatan",
    "value": "Alor Selatan"
  },
  {
    "id": "530505",
    "regency_id": "5305",
    "label": "Alor Timur",
    "value": "Alor Timur"
  },
  {
    "id": "530506",
    "regency_id": "5305",
    "label": "Pantar",
    "value": "Pantar"
  },
  {
    "id": "530507",
    "regency_id": "5305",
    "label": "Alor Tengah Utara",
    "value": "Alor Tengah Utara"
  },
  {
    "id": "530508",
    "regency_id": "5305",
    "label": "Alor Timur Laut",
    "value": "Alor Timur Laut"
  },
  {
    "id": "530509",
    "regency_id": "5305",
    "label": "Pantar Barat",
    "value": "Pantar Barat"
  },
  {
    "id": "530510",
    "regency_id": "5305",
    "label": "Kabola",
    "value": "Kabola"
  },
  {
    "id": "530511",
    "regency_id": "5305",
    "label": "Pulau Pura",
    "value": "Pulau Pura"
  },
  {
    "id": "530512",
    "regency_id": "5305",
    "label": "Mataru",
    "value": "Mataru"
  },
  {
    "id": "530513",
    "regency_id": "5305",
    "label": "Pureman",
    "value": "Pureman"
  },
  {
    "id": "530514",
    "regency_id": "5305",
    "label": "Pantar Timur",
    "value": "Pantar Timur"
  },
  {
    "id": "530515",
    "regency_id": "5305",
    "label": "Lembur",
    "value": "Lembur"
  },
  {
    "id": "530516",
    "regency_id": "5305",
    "label": "Pantar Tengah",
    "value": "Pantar Tengah"
  },
  {
    "id": "530517",
    "regency_id": "5305",
    "label": "Pantar Baru Laut",
    "value": "Pantar Baru Laut"
  },
  {
    "id": "530601",
    "regency_id": "5306",
    "label": "Wulanggitang",
    "value": "Wulanggitang"
  },
  {
    "id": "530602",
    "regency_id": "5306",
    "label": "Titehena",
    "value": "Titehena"
  },
  {
    "id": "530603",
    "regency_id": "5306",
    "label": "Larantuka",
    "value": "Larantuka"
  },
  {
    "id": "530604",
    "regency_id": "5306",
    "label": "Ile Mandiri",
    "value": "Ile Mandiri"
  },
  {
    "id": "530605",
    "regency_id": "5306",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "530606",
    "regency_id": "5306",
    "label": "Solor Barat",
    "value": "Solor Barat"
  },
  {
    "id": "530607",
    "regency_id": "5306",
    "label": "Solor Timur",
    "value": "Solor Timur"
  },
  {
    "id": "530608",
    "regency_id": "5306",
    "label": "Adonara Barat",
    "value": "Adonara Barat"
  },
  {
    "id": "530609",
    "regency_id": "5306",
    "label": "Wotan Ulumando",
    "value": "Wotan Ulumando"
  },
  {
    "id": "530610",
    "regency_id": "5306",
    "label": "Adonara Timur",
    "value": "Adonara Timur"
  },
  {
    "id": "530611",
    "regency_id": "5306",
    "label": "Kelubagolit",
    "value": "Kelubagolit"
  },
  {
    "id": "530612",
    "regency_id": "5306",
    "label": "Witihama",
    "value": "Witihama"
  },
  {
    "id": "530613",
    "regency_id": "5306",
    "label": "Ile Boleng",
    "value": "Ile Boleng"
  },
  {
    "id": "530614",
    "regency_id": "5306",
    "label": "Demon Pagong",
    "value": "Demon Pagong"
  },
  {
    "id": "530615",
    "regency_id": "5306",
    "label": "Lewolema",
    "value": "Lewolema"
  },
  {
    "id": "530616",
    "regency_id": "5306",
    "label": "Ile Bura",
    "value": "Ile Bura"
  },
  {
    "id": "530617",
    "regency_id": "5306",
    "label": "Adonara",
    "value": "Adonara"
  },
  {
    "id": "530618",
    "regency_id": "5306",
    "label": "Adonara Tengah",
    "value": "Adonara Tengah"
  },
  {
    "id": "530619",
    "regency_id": "5306",
    "label": "Solor Selatan",
    "value": "Solor Selatan"
  },
  { "id": "530701", "regency_id": "5307", "label": "Paga", "value": "Paga" },
  { "id": "530702", "regency_id": "5307", "label": "Mego", "value": "Mego" },
  { "id": "530703", "regency_id": "5307", "label": "Lela", "value": "Lela" },
  { "id": "530704", "regency_id": "5307", "label": "Nita", "value": "Nita" },
  { "id": "530705", "regency_id": "5307", "label": "Alok", "value": "Alok" },
  { "id": "530706", "regency_id": "5307", "label": "Palue", "value": "Palue" },
  { "id": "530707", "regency_id": "5307", "label": "Nelle", "value": "Nelle" },
  {
    "id": "530708",
    "regency_id": "5307",
    "label": "Talibura",
    "value": "Talibura"
  },
  {
    "id": "530709",
    "regency_id": "5307",
    "label": "Waigete",
    "value": "Waigete"
  },
  {
    "id": "530710",
    "regency_id": "5307",
    "label": "Kewapante",
    "value": "Kewapante"
  },
  { "id": "530711", "regency_id": "5307", "label": "Bola", "value": "Bola" },
  {
    "id": "530712",
    "regency_id": "5307",
    "label": "Magepanda",
    "value": "Magepanda"
  },
  {
    "id": "530713",
    "regency_id": "5307",
    "label": "Waiblama",
    "value": "Waiblama"
  },
  {
    "id": "530714",
    "regency_id": "5307",
    "label": "Alok Barat",
    "value": "Alok Barat"
  },
  {
    "id": "530715",
    "regency_id": "5307",
    "label": "Alok Timur",
    "value": "Alok Timur"
  },
  {
    "id": "530716",
    "regency_id": "5307",
    "label": "Koting",
    "value": "Koting"
  },
  {
    "id": "530717",
    "regency_id": "5307",
    "label": "Tana Wawo",
    "value": "Tana Wawo"
  },
  {
    "id": "530718",
    "regency_id": "5307",
    "label": "Hewokloang",
    "value": "Hewokloang"
  },
  {
    "id": "530719",
    "regency_id": "5307",
    "label": "Kangae",
    "value": "Kangae"
  },
  {
    "id": "530720",
    "regency_id": "5307",
    "label": "Doreng",
    "value": "Doreng"
  },
  {
    "id": "530721",
    "regency_id": "5307",
    "label": "Mapitara",
    "value": "Mapitara"
  },
  {
    "id": "530801",
    "regency_id": "5308",
    "label": "Nangapanda",
    "value": "Nangapanda"
  },
  {
    "id": "530802",
    "regency_id": "5308",
    "label": "Pulau Ende",
    "value": "Pulau Ende"
  },
  { "id": "530803", "regency_id": "5308", "label": "Ende", "value": "Ende" },
  {
    "id": "530804",
    "regency_id": "5308",
    "label": "Ende Selatan",
    "value": "Ende Selatan"
  },
  { "id": "530805", "regency_id": "5308", "label": "Ndona", "value": "Ndona" },
  {
    "id": "530806",
    "regency_id": "5308",
    "label": "Detusoko",
    "value": "Detusoko"
  },
  {
    "id": "530807",
    "regency_id": "5308",
    "label": "Wewaria",
    "value": "Wewaria"
  },
  {
    "id": "530808",
    "regency_id": "5308",
    "label": "Wolowaru",
    "value": "Wolowaru"
  },
  {
    "id": "530809",
    "regency_id": "5308",
    "label": "Wolojita",
    "value": "Wolojita"
  },
  {
    "id": "530810",
    "regency_id": "5308",
    "label": "Maurole",
    "value": "Maurole"
  },
  {
    "id": "530811",
    "regency_id": "5308",
    "label": "Maukaro",
    "value": "Maukaro"
  },
  {
    "id": "530812",
    "regency_id": "5308",
    "label": "Lio Timur",
    "value": "Lio Timur"
  },
  {
    "id": "530813",
    "regency_id": "5308",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "530814",
    "regency_id": "5308",
    "label": "Kelimutu",
    "value": "Kelimutu"
  },
  {
    "id": "530815",
    "regency_id": "5308",
    "label": "Detukeli",
    "value": "Detukeli"
  },
  {
    "id": "530816",
    "regency_id": "5308",
    "label": "Ndona Timur",
    "value": "Ndona Timur"
  },
  { "id": "530817", "regency_id": "5308", "label": "Ndori", "value": "Ndori" },
  {
    "id": "530818",
    "regency_id": "5308",
    "label": "Ende Utara",
    "value": "Ende Utara"
  },
  {
    "id": "530819",
    "regency_id": "5308",
    "label": "Ende Tengah",
    "value": "Ende Tengah"
  },
  {
    "id": "530820",
    "regency_id": "5308",
    "label": "Ende Timur",
    "value": "Ende Timur"
  },
  {
    "id": "530821",
    "regency_id": "5308",
    "label": "Lepembusu Kelisoke",
    "value": "Lepembusu Kelisoke"
  },
  {
    "id": "530901",
    "regency_id": "5309",
    "label": "Aimere",
    "value": "Aimere"
  },
  {
    "id": "530902",
    "regency_id": "5309",
    "label": "Golewa",
    "value": "Golewa"
  },
  {
    "id": "530906",
    "regency_id": "5309",
    "label": "Bajawa",
    "value": "Bajawa"
  },
  { "id": "530907", "regency_id": "5309", "label": "Soa", "value": "Soa" },
  { "id": "530909", "regency_id": "5309", "label": "Riung", "value": "Riung" },
  {
    "id": "530912",
    "regency_id": "5309",
    "label": "Jerebuu",
    "value": "Jerebuu"
  },
  {
    "id": "530914",
    "regency_id": "5309",
    "label": "Riung Barat",
    "value": "Riung Barat"
  },
  {
    "id": "530915",
    "regency_id": "5309",
    "label": "Bajawa Utara",
    "value": "Bajawa Utara"
  },
  {
    "id": "530916",
    "regency_id": "5309",
    "label": "Wolomeze",
    "value": "Wolomeze"
  },
  {
    "id": "530918",
    "regency_id": "5309",
    "label": "Golewa Selatan",
    "value": "Golewa Selatan"
  },
  {
    "id": "530919",
    "regency_id": "5309",
    "label": "Golewa Barat",
    "value": "Golewa Barat"
  },
  {
    "id": "530920",
    "regency_id": "5309",
    "label": "Inerie",
    "value": "Inerie"
  },
  {
    "id": "531001",
    "regency_id": "5310",
    "label": "Wae Rii",
    "value": "Wae Rii"
  },
  {
    "id": "531003",
    "regency_id": "5310",
    "label": "Ruteng",
    "value": "Ruteng"
  },
  {
    "id": "531005",
    "regency_id": "5310",
    "label": "Satar Mese",
    "value": "Satar Mese"
  },
  { "id": "531006", "regency_id": "5310", "label": "Cibal", "value": "Cibal" },
  { "id": "531011", "regency_id": "5310", "label": "Reok", "value": "Reok" },
  {
    "id": "531012",
    "regency_id": "5310",
    "label": "Langke Rembong",
    "value": "Langke Rembong"
  },
  {
    "id": "531013",
    "regency_id": "5310",
    "label": "Satar Mese Barat",
    "value": "Satar Mese Barat"
  },
  {
    "id": "531014",
    "regency_id": "5310",
    "label": "Rahong Utara",
    "value": "Rahong Utara"
  },
  { "id": "531015", "regency_id": "5310", "label": "Lelak", "value": "Lelak" },
  {
    "id": "531016",
    "regency_id": "5310",
    "label": "Reok Barat",
    "value": "Reok Barat"
  },
  {
    "id": "531017",
    "regency_id": "5310",
    "label": "Cibal barat",
    "value": "Cibal barat"
  },
  {
    "id": "531018",
    "regency_id": "5310",
    "label": "Satar Mese Utara",
    "value": "Satar Mese Utara"
  },
  {
    "id": "531101",
    "regency_id": "5311",
    "label": "Kota Waingapu",
    "value": "Kota Waingapu"
  },
  {
    "id": "531102",
    "regency_id": "5311",
    "label": "Haharu",
    "value": "Haharu"
  },
  { "id": "531103", "regency_id": "5311", "label": "Lewa", "value": "Lewa" },
  {
    "id": "531104",
    "regency_id": "5311",
    "label": "Nggaha Ori Angu",
    "value": "Nggaha Ori Angu"
  },
  {
    "id": "531105",
    "regency_id": "5311",
    "label": "Tabundung",
    "value": "Tabundung"
  },
  {
    "id": "531106",
    "regency_id": "5311",
    "label": "Pinu Pahar",
    "value": "Pinu Pahar"
  },
  {
    "id": "531107",
    "regency_id": "5311",
    "label": "Pandawai",
    "value": "Pandawai"
  },
  {
    "id": "531108",
    "regency_id": "5311",
    "label": "Umalulu",
    "value": "Umalulu"
  },
  { "id": "531109", "regency_id": "5311", "label": "Rindi", "value": "Rindi" },
  {
    "id": "531110",
    "regency_id": "5311",
    "label": "Pahunga Lodu",
    "value": "Pahunga Lodu"
  },
  {
    "id": "531111",
    "regency_id": "5311",
    "label": "Wulla Waijelu",
    "value": "Wulla Waijelu"
  },
  {
    "id": "531112",
    "regency_id": "5311",
    "label": "Paberiwai",
    "value": "Paberiwai"
  },
  {
    "id": "531113",
    "regency_id": "5311",
    "label": "Karera",
    "value": "Karera"
  },
  {
    "id": "531114",
    "regency_id": "5311",
    "label": "Kahaungu Eti",
    "value": "Kahaungu Eti"
  },
  {
    "id": "531115",
    "regency_id": "5311",
    "label": "Matawai La Pawu",
    "value": "Matawai La Pawu"
  },
  {
    "id": "531116",
    "regency_id": "5311",
    "label": "Kambera",
    "value": "Kambera"
  },
  {
    "id": "531117",
    "regency_id": "5311",
    "label": "Kambata Mapambuhang",
    "value": "Kambata Mapambuhang"
  },
  {
    "id": "531118",
    "regency_id": "5311",
    "label": "Lewa Tidahu",
    "value": "Lewa Tidahu"
  },
  {
    "id": "531119",
    "regency_id": "5311",
    "label": "Katala Hamu Lingu",
    "value": "Katala Hamu Lingu"
  },
  {
    "id": "531120",
    "regency_id": "5311",
    "label": "Kanatang",
    "value": "Kanatang"
  },
  {
    "id": "531121",
    "regency_id": "5311",
    "label": "Ngadu Ngala",
    "value": "Ngadu Ngala"
  },
  { "id": "531122", "regency_id": "5311", "label": "Mahu", "value": "Mahu" },
  {
    "id": "531204",
    "regency_id": "5312",
    "label": "Tana Righu",
    "value": "Tana Righu"
  },
  { "id": "531210", "regency_id": "5312", "label": "Loli", "value": "Loli" },
  {
    "id": "531211",
    "regency_id": "5312",
    "label": "Wanokaka",
    "value": "Wanokaka"
  },
  {
    "id": "531212",
    "regency_id": "5312",
    "label": "Lamboya",
    "value": "Lamboya"
  },
  {
    "id": "531215",
    "regency_id": "5312",
    "label": "Kota Waikabubak",
    "value": "Kota Waikabubak"
  },
  {
    "id": "531218",
    "regency_id": "5312",
    "label": "Laboya Barat",
    "value": "Laboya Barat"
  },
  {
    "id": "531301",
    "regency_id": "5313",
    "label": "Naga Wutung",
    "value": "Naga Wutung"
  },
  {
    "id": "531302",
    "regency_id": "5313",
    "label": "Atadei",
    "value": "Atadei"
  },
  {
    "id": "531303",
    "regency_id": "5313",
    "label": "Ile Ape",
    "value": "Ile Ape"
  },
  {
    "id": "531304",
    "regency_id": "5313",
    "label": "Lebatukan",
    "value": "Lebatukan"
  },
  {
    "id": "531305",
    "regency_id": "5313",
    "label": "Nubatukan",
    "value": "Nubatukan"
  },
  {
    "id": "531306",
    "regency_id": "5313",
    "label": "Omesuri",
    "value": "Omesuri"
  },
  {
    "id": "531307",
    "regency_id": "5313",
    "label": "Buyasuri",
    "value": "Buyasuri"
  },
  {
    "id": "531308",
    "regency_id": "5313",
    "label": "Wulandoni",
    "value": "Wulandoni"
  },
  {
    "id": "531309",
    "regency_id": "5313",
    "label": "Ile Ape Timur",
    "value": "Ile Ape Timur"
  },
  {
    "id": "531401",
    "regency_id": "5314",
    "label": "Rote Barat Daya",
    "value": "Rote Barat Daya"
  },
  {
    "id": "531402",
    "regency_id": "5314",
    "label": "Rote Barat Laut",
    "value": "Rote Barat Laut"
  },
  {
    "id": "531403",
    "regency_id": "5314",
    "label": "Lobalain",
    "value": "Lobalain"
  },
  {
    "id": "531404",
    "regency_id": "5314",
    "label": "Rote Tengah",
    "value": "Rote Tengah"
  },
  {
    "id": "531405",
    "regency_id": "5314",
    "label": "Pantai Baru",
    "value": "Pantai Baru"
  },
  {
    "id": "531406",
    "regency_id": "5314",
    "label": "Rote Timur",
    "value": "Rote Timur"
  },
  {
    "id": "531407",
    "regency_id": "5314",
    "label": "Rote Barat",
    "value": "Rote Barat"
  },
  {
    "id": "531408",
    "regency_id": "5314",
    "label": "Rote Selatan",
    "value": "Rote Selatan"
  },
  {
    "id": "531409",
    "regency_id": "5314",
    "label": "Ndao Nuse",
    "value": "Ndao Nuse"
  },
  {
    "id": "531410",
    "regency_id": "5314",
    "label": "Landu Leko",
    "value": "Landu Leko"
  },
  {
    "id": "531501",
    "regency_id": "5315",
    "label": "Macang Pacar",
    "value": "Macang Pacar"
  },
  { "id": "531502", "regency_id": "5315", "label": "Kuwus", "value": "Kuwus" },
  {
    "id": "531503",
    "regency_id": "5315",
    "label": "Lembor",
    "value": "Lembor"
  },
  {
    "id": "531504",
    "regency_id": "5315",
    "label": "Sano Nggoang",
    "value": "Sano Nggoang"
  },
  {
    "id": "531505",
    "regency_id": "5315",
    "label": "Komodo",
    "value": "Komodo"
  },
  {
    "id": "531506",
    "regency_id": "5315",
    "label": "Boleng",
    "value": "Boleng"
  },
  { "id": "531507", "regency_id": "5315", "label": "Welak", "value": "Welak" },
  { "id": "531508", "regency_id": "5315", "label": "Ndoso", "value": "Ndoso" },
  {
    "id": "531509",
    "regency_id": "5315",
    "label": "Lembor Selatan",
    "value": "Lembor Selatan"
  },
  {
    "id": "531510",
    "regency_id": "5315",
    "label": "Mbeliling",
    "value": "Mbeliling"
  },
  { "id": "531511", "regency_id": "5315", "label": "Pacar", "value": "Pacar" },
  {
    "id": "531512",
    "regency_id": "5315",
    "label": "Kuwus Barat",
    "value": "Kuwus Barat"
  },
  {
    "id": "531601",
    "regency_id": "5316",
    "label": "Aesesa",
    "value": "Aesesa"
  },
  {
    "id": "531602",
    "regency_id": "5316",
    "label": "Nangaroro",
    "value": "Nangaroro"
  },
  {
    "id": "531603",
    "regency_id": "5316",
    "label": "Boawae",
    "value": "Boawae"
  },
  {
    "id": "531604",
    "regency_id": "5316",
    "label": "Mauponggo",
    "value": "Mauponggo"
  },
  {
    "id": "531605",
    "regency_id": "5316",
    "label": "Wolowae",
    "value": "Wolowae"
  },
  {
    "id": "531606",
    "regency_id": "5316",
    "label": "Keo Tengah",
    "value": "Keo Tengah"
  },
  {
    "id": "531607",
    "regency_id": "5316",
    "label": "Aesesa Selatan",
    "value": "Aesesa Selatan"
  },
  {
    "id": "531701",
    "regency_id": "5317",
    "label": "Katiku Tana",
    "value": "Katiku Tana"
  },
  {
    "id": "531702",
    "regency_id": "5317",
    "label": "Umbu Ratu Nggay Barat",
    "value": "Umbu Ratu Nggay Barat"
  },
  {
    "id": "531703",
    "regency_id": "5317",
    "label": "Mamboro",
    "value": "Mamboro"
  },
  {
    "id": "531704",
    "regency_id": "5317",
    "label": "Umbu Ratu Nggay",
    "value": "Umbu Ratu Nggay"
  },
  {
    "id": "531705",
    "regency_id": "5317",
    "label": "Katiku Tana Selatan",
    "value": "Katiku Tana Selatan"
  },
  { "id": "531801", "regency_id": "5318", "label": "Loura", "value": "Loura" },
  {
    "id": "531802",
    "regency_id": "5318",
    "label": "Wewewa Utara",
    "value": "Wewewa Utara"
  },
  {
    "id": "531803",
    "regency_id": "5318",
    "label": "Wewewa Timur",
    "value": "Wewewa Timur"
  },
  {
    "id": "531804",
    "regency_id": "5318",
    "label": "Wewewa Barat",
    "value": "Wewewa Barat"
  },
  {
    "id": "531805",
    "regency_id": "5318",
    "label": "Wewewa Selatan",
    "value": "Wewewa Selatan"
  },
  {
    "id": "531806",
    "regency_id": "5318",
    "label": "Kodi Bangedo",
    "value": "Kodi Bangedo"
  },
  { "id": "531807", "regency_id": "5318", "label": "Kodi", "value": "Kodi" },
  {
    "id": "531808",
    "regency_id": "5318",
    "label": "Kodi Utara",
    "value": "Kodi Utara"
  },
  {
    "id": "531809",
    "regency_id": "5318",
    "label": "Kota Tambolaka",
    "value": "Kota Tambolaka"
  },
  {
    "id": "531810",
    "regency_id": "5318",
    "label": "Wewewa Tengah",
    "value": "Wewewa Tengah"
  },
  {
    "id": "531811",
    "regency_id": "5318",
    "label": "Kodi Balaghar",
    "value": "Kodi Balaghar"
  },
  {
    "id": "531901",
    "regency_id": "5319",
    "label": "Borong",
    "value": "Borong"
  },
  {
    "id": "531902",
    "regency_id": "5319",
    "label": "Poco Ranaka",
    "value": "Poco Ranaka"
  },
  {
    "id": "531903",
    "regency_id": "5319",
    "label": "Lamba Leda",
    "value": "Lamba Leda"
  },
  {
    "id": "531904",
    "regency_id": "5319",
    "label": "Sambi Rampas",
    "value": "Sambi Rampas"
  },
  { "id": "531905", "regency_id": "5319", "label": "Elar", "value": "Elar" },
  {
    "id": "531906",
    "regency_id": "5319",
    "label": "Kota Komba",
    "value": "Kota Komba"
  },
  {
    "id": "531907",
    "regency_id": "5319",
    "label": "Rana Mese",
    "value": "Rana Mese"
  },
  {
    "id": "531908",
    "regency_id": "5319",
    "label": "Poco Ranaka Timur",
    "value": "Poco Ranaka Timur"
  },
  {
    "id": "531909",
    "regency_id": "5319",
    "label": "Elar Selatan",
    "value": "Elar Selatan"
  },
  {
    "id": "532001",
    "regency_id": "5320",
    "label": "Sabu Barat",
    "value": "Sabu Barat"
  },
  {
    "id": "532002",
    "regency_id": "5320",
    "label": "Sabu Tengah",
    "value": "Sabu Tengah"
  },
  {
    "id": "532003",
    "regency_id": "5320",
    "label": "Sabu Timur",
    "value": "Sabu Timur"
  },
  {
    "id": "532004",
    "regency_id": "5320",
    "label": "Sabu Liae",
    "value": "Sabu Liae"
  },
  {
    "id": "532005",
    "regency_id": "5320",
    "label": "Hawu Mehara",
    "value": "Hawu Mehara"
  },
  {
    "id": "532006",
    "regency_id": "5320",
    "label": "Raijua",
    "value": "Raijua"
  },
  {
    "id": "532101",
    "regency_id": "5321",
    "label": "Malaka Tengah",
    "value": "Malaka Tengah"
  },
  {
    "id": "532102",
    "regency_id": "5321",
    "label": "Malaka Barat",
    "value": "Malaka Barat"
  },
  {
    "id": "532103",
    "regency_id": "5321",
    "label": "Wewiku",
    "value": "Wewiku"
  },
  {
    "id": "532104",
    "regency_id": "5321",
    "label": "Weliman",
    "value": "Weliman"
  },
  {
    "id": "532105",
    "regency_id": "5321",
    "label": "Rinhat",
    "value": "Rinhat"
  },
  {
    "id": "532106",
    "regency_id": "5321",
    "label": "Io Kufeu",
    "value": "Io Kufeu"
  },
  {
    "id": "532107",
    "regency_id": "5321",
    "label": "Sasitamean",
    "value": "Sasitamean"
  },
  {
    "id": "532108",
    "regency_id": "5321",
    "label": "Laenmanen",
    "value": "Laenmanen"
  },
  {
    "id": "532109",
    "regency_id": "5321",
    "label": "Malaka Timur",
    "value": "Malaka Timur"
  },
  {
    "id": "532110",
    "regency_id": "5321",
    "label": "Kobalima Timur",
    "value": "Kobalima Timur"
  },
  {
    "id": "532111",
    "regency_id": "5321",
    "label": "Kobalima",
    "value": "Kobalima"
  },
  {
    "id": "532112",
    "regency_id": "5321",
    "label": "Botin Leobele",
    "value": "Botin Leobele"
  },
  { "id": "537101", "regency_id": "5371", "label": "Alak", "value": "Alak" },
  {
    "id": "537102",
    "regency_id": "5371",
    "label": "Maulafa",
    "value": "Maulafa"
  },
  {
    "id": "537103",
    "regency_id": "5371",
    "label": "Kelapa Lima",
    "value": "Kelapa Lima"
  },
  {
    "id": "537104",
    "regency_id": "5371",
    "label": "Oebobo",
    "value": "Oebobo"
  },
  {
    "id": "537105",
    "regency_id": "5371",
    "label": "Kota Raja",
    "value": "Kota Raja"
  },
  {
    "id": "537106",
    "regency_id": "5371",
    "label": "Kota Lama",
    "value": "Kota Lama"
  },
  {
    "id": "610101",
    "regency_id": "6101",
    "label": "Sambas",
    "value": "Sambas"
  },
  {
    "id": "610102",
    "regency_id": "6101",
    "label": "Teluk Keramat",
    "value": "Teluk Keramat"
  },
  { "id": "610103", "regency_id": "6101", "label": "Jawai", "value": "Jawai" },
  { "id": "610104", "regency_id": "6101", "label": "Tebas", "value": "Tebas" },
  {
    "id": "610105",
    "regency_id": "6101",
    "label": "Pemangkat",
    "value": "Pemangkat"
  },
  {
    "id": "610106",
    "regency_id": "6101",
    "label": "Sejangkung",
    "value": "Sejangkung"
  },
  {
    "id": "610107",
    "regency_id": "6101",
    "label": "Selakau",
    "value": "Selakau"
  },
  { "id": "610108", "regency_id": "6101", "label": "Paloh", "value": "Paloh" },
  {
    "id": "610109",
    "regency_id": "6101",
    "label": "Sajingan Besar",
    "value": "Sajingan Besar"
  },
  { "id": "610110", "regency_id": "6101", "label": "Subah", "value": "Subah" },
  {
    "id": "610111",
    "regency_id": "6101",
    "label": "Galing",
    "value": "Galing"
  },
  {
    "id": "610112",
    "regency_id": "6101",
    "label": "Tekarang",
    "value": "Tekarang"
  },
  {
    "id": "610113",
    "regency_id": "6101",
    "label": "Semparuk",
    "value": "Semparuk"
  },
  { "id": "610114", "regency_id": "6101", "label": "Sajad", "value": "Sajad" },
  {
    "id": "610115",
    "regency_id": "6101",
    "label": "Sebawi",
    "value": "Sebawi"
  },
  {
    "id": "610116",
    "regency_id": "6101",
    "label": "Jawai Selatan",
    "value": "Jawai Selatan"
  },
  {
    "id": "610117",
    "regency_id": "6101",
    "label": "Tangaran",
    "value": "Tangaran"
  },
  {
    "id": "610118",
    "regency_id": "6101",
    "label": "Salatiga",
    "value": "Salatiga"
  },
  {
    "id": "610119",
    "regency_id": "6101",
    "label": "Selakau Timur",
    "value": "Selakau Timur"
  },
  {
    "id": "610201",
    "regency_id": "6102",
    "label": "Mempawah Hilir",
    "value": "Mempawah Hilir"
  },
  { "id": "610206", "regency_id": "6102", "label": "Toho", "value": "Toho" },
  {
    "id": "610207",
    "regency_id": "6102",
    "label": "Sungai Pinyuh",
    "value": "Sungai Pinyuh"
  },
  {
    "id": "610208",
    "regency_id": "6102",
    "label": "Siantan",
    "value": "Siantan"
  },
  {
    "id": "610212",
    "regency_id": "6102",
    "label": "Sungai Kunyit",
    "value": "Sungai Kunyit"
  },
  {
    "id": "610215",
    "regency_id": "6102",
    "label": "Segedong",
    "value": "Segedong"
  },
  {
    "id": "610216",
    "regency_id": "6102",
    "label": "Anjongan",
    "value": "Anjongan"
  },
  {
    "id": "610217",
    "regency_id": "6102",
    "label": "Sadaniang",
    "value": "Sadaniang"
  },
  {
    "id": "610218",
    "regency_id": "6102",
    "label": "Mempawah Timur",
    "value": "Mempawah Timur"
  },
  {
    "id": "610301",
    "regency_id": "6103",
    "label": "Kapuas",
    "value": "Kapuas"
  },
  { "id": "610302", "regency_id": "6103", "label": "Mukok", "value": "Mukok" },
  { "id": "610303", "regency_id": "6103", "label": "Noyan", "value": "Noyan" },
  {
    "id": "610304",
    "regency_id": "6103",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  { "id": "610305", "regency_id": "6103", "label": "Bonti", "value": "Bonti" },
  {
    "id": "610306",
    "regency_id": "6103",
    "label": "Beduai",
    "value": "Beduai"
  },
  {
    "id": "610307",
    "regency_id": "6103",
    "label": "Sekayam",
    "value": "Sekayam"
  },
  {
    "id": "610308",
    "regency_id": "6103",
    "label": "Kembayan",
    "value": "Kembayan"
  },
  {
    "id": "610309",
    "regency_id": "6103",
    "label": "Parindu",
    "value": "Parindu"
  },
  {
    "id": "610310",
    "regency_id": "6103",
    "label": "Tayan Hulu",
    "value": "Tayan Hulu"
  },
  {
    "id": "610311",
    "regency_id": "6103",
    "label": "Tayan Hilir",
    "value": "Tayan Hilir"
  },
  { "id": "610312", "regency_id": "6103", "label": "Balai", "value": "Balai" },
  { "id": "610313", "regency_id": "6103", "label": "Toba", "value": "Toba" },
  {
    "id": "610320",
    "regency_id": "6103",
    "label": "Meliau",
    "value": "Meliau"
  },
  {
    "id": "610321",
    "regency_id": "6103",
    "label": "Entikong",
    "value": "Entikong"
  },
  {
    "id": "610401",
    "regency_id": "6104",
    "label": "Matan Hilir Utara",
    "value": "Matan Hilir Utara"
  },
  { "id": "610402", "regency_id": "6104", "label": "Marau", "value": "Marau" },
  {
    "id": "610403",
    "regency_id": "6104",
    "label": "Manis Mata",
    "value": "Manis Mata"
  },
  {
    "id": "610404",
    "regency_id": "6104",
    "label": "Kendawangan",
    "value": "Kendawangan"
  },
  {
    "id": "610405",
    "regency_id": "6104",
    "label": "Sandai",
    "value": "Sandai"
  },
  {
    "id": "610407",
    "regency_id": "6104",
    "label": "Sungai Laur",
    "value": "Sungai Laur"
  },
  {
    "id": "610408",
    "regency_id": "6104",
    "label": "Simpang Hulu",
    "value": "Simpang Hulu"
  },
  {
    "id": "610411",
    "regency_id": "6104",
    "label": "Nanga Tayap",
    "value": "Nanga Tayap"
  },
  {
    "id": "610412",
    "regency_id": "6104",
    "label": "Matan Hilir Selatan",
    "value": "Matan Hilir Selatan"
  },
  {
    "id": "610413",
    "regency_id": "6104",
    "label": "Tumbang Titi",
    "value": "Tumbang Titi"
  },
  {
    "id": "610414",
    "regency_id": "6104",
    "label": "Jelai Hulu",
    "value": "Jelai Hulu"
  },
  {
    "id": "610416",
    "regency_id": "6104",
    "label": "Delta Pawan",
    "value": "Delta Pawan"
  },
  {
    "id": "610417",
    "regency_id": "6104",
    "label": "Muara Pawan",
    "value": "Muara Pawan"
  },
  {
    "id": "610418",
    "regency_id": "6104",
    "label": "Benua Kayong",
    "value": "Benua Kayong"
  },
  {
    "id": "610419",
    "regency_id": "6104",
    "label": "Hulu Sungai",
    "value": "Hulu Sungai"
  },
  {
    "id": "610420",
    "regency_id": "6104",
    "label": "Simpang Dua",
    "value": "Simpang Dua"
  },
  {
    "id": "610421",
    "regency_id": "6104",
    "label": "Air Upas",
    "value": "Air Upas"
  },
  {
    "id": "610422",
    "regency_id": "6104",
    "label": "Singkup",
    "value": "Singkup"
  },
  {
    "id": "610424",
    "regency_id": "6104",
    "label": "Pemahan",
    "value": "Pemahan"
  },
  {
    "id": "610425",
    "regency_id": "6104",
    "label": "Sungai Melayu Rayak",
    "value": "Sungai Melayu Rayak"
  },
  {
    "id": "610501",
    "regency_id": "6105",
    "label": "Sintang",
    "value": "Sintang"
  },
  {
    "id": "610502",
    "regency_id": "6105",
    "label": "Tempunak",
    "value": "Tempunak"
  },
  {
    "id": "610503",
    "regency_id": "6105",
    "label": "Sepauk",
    "value": "Sepauk"
  },
  {
    "id": "610504",
    "regency_id": "6105",
    "label": "Ketungau Hilir",
    "value": "Ketungau Hilir"
  },
  {
    "id": "610505",
    "regency_id": "6105",
    "label": "Ketungau Tengah",
    "value": "Ketungau Tengah"
  },
  {
    "id": "610506",
    "regency_id": "6105",
    "label": "Ketungau Hulu",
    "value": "Ketungau Hulu"
  },
  { "id": "610507", "regency_id": "6105", "label": "Dedai", "value": "Dedai" },
  {
    "id": "610508",
    "regency_id": "6105",
    "label": "Kayan Hilir",
    "value": "Kayan Hilir"
  },
  {
    "id": "610509",
    "regency_id": "6105",
    "label": "Kayan Hulu",
    "value": "Kayan Hulu"
  },
  {
    "id": "610514",
    "regency_id": "6105",
    "label": "Serawai",
    "value": "Serawai"
  },
  {
    "id": "610515",
    "regency_id": "6105",
    "label": "Ambalau",
    "value": "Ambalau"
  },
  {
    "id": "610519",
    "regency_id": "6105",
    "label": "Kelam Permai",
    "value": "Kelam Permai"
  },
  {
    "id": "610520",
    "regency_id": "6105",
    "label": "Sungai Tebelian",
    "value": "Sungai Tebelian"
  },
  {
    "id": "610521",
    "regency_id": "6105",
    "label": "Binjai Hulu",
    "value": "Binjai Hulu"
  },
  {
    "id": "610601",
    "regency_id": "6106",
    "label": "Putussibau Utara",
    "value": "Putussibau Utara"
  },
  { "id": "610602", "regency_id": "6106", "label": "Bika", "value": "Bika" },
  {
    "id": "610603",
    "regency_id": "6106",
    "label": "Embaloh Hilir",
    "value": "Embaloh Hilir"
  },
  {
    "id": "610604",
    "regency_id": "6106",
    "label": "Embaloh Hulu",
    "value": "Embaloh Hulu"
  },
  {
    "id": "610605",
    "regency_id": "6106",
    "label": "Bunut Hilir",
    "value": "Bunut Hilir"
  },
  {
    "id": "610606",
    "regency_id": "6106",
    "label": "Bunut Hulu",
    "value": "Bunut Hulu"
  },
  {
    "id": "610607",
    "regency_id": "6106",
    "label": "Jongkong",
    "value": "Jongkong"
  },
  {
    "id": "610608",
    "regency_id": "6106",
    "label": "Hulu Gurung",
    "value": "Hulu Gurung"
  },
  {
    "id": "610609",
    "regency_id": "6106",
    "label": "Selimbau",
    "value": "Selimbau"
  },
  {
    "id": "610610",
    "regency_id": "6106",
    "label": "Semitau",
    "value": "Semitau"
  },
  {
    "id": "610611",
    "regency_id": "6106",
    "label": "Seberuang",
    "value": "Seberuang"
  },
  {
    "id": "610612",
    "regency_id": "6106",
    "label": "Batang Lupar",
    "value": "Batang Lupar"
  },
  {
    "id": "610613",
    "regency_id": "6106",
    "label": "Empanang",
    "value": "Empanang"
  },
  { "id": "610614", "regency_id": "6106", "label": "Badau", "value": "Badau" },
  {
    "id": "610615",
    "regency_id": "6106",
    "label": "Silat Hilir",
    "value": "Silat Hilir"
  },
  {
    "id": "610616",
    "regency_id": "6106",
    "label": "Silat Hulu",
    "value": "Silat Hulu"
  },
  {
    "id": "610617",
    "regency_id": "6106",
    "label": "Putussibau Selatan",
    "value": "Putussibau Selatan"
  },
  { "id": "610618", "regency_id": "6106", "label": "Kalis", "value": "Kalis" },
  {
    "id": "610619",
    "regency_id": "6106",
    "label": "Boyan Tanjung",
    "value": "Boyan Tanjung"
  },
  {
    "id": "610620",
    "regency_id": "6106",
    "label": "Mentebah",
    "value": "Mentebah"
  },
  {
    "id": "610621",
    "regency_id": "6106",
    "label": "Pengkadan",
    "value": "Pengkadan"
  },
  {
    "id": "610622",
    "regency_id": "6106",
    "label": "Suhaid",
    "value": "Suhaid"
  },
  {
    "id": "610623",
    "regency_id": "6106",
    "label": "Puring Kencana",
    "value": "Puring Kencana"
  },
  {
    "id": "610701",
    "regency_id": "6107",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "610702",
    "regency_id": "6107",
    "label": "Samalantan",
    "value": "Samalantan"
  },
  { "id": "610703", "regency_id": "6107", "label": "Ledo", "value": "Ledo" },
  {
    "id": "610704",
    "regency_id": "6107",
    "label": "Bengkayang",
    "value": "Bengkayang"
  },
  {
    "id": "610705",
    "regency_id": "6107",
    "label": "Seluas",
    "value": "Seluas"
  },
  {
    "id": "610706",
    "regency_id": "6107",
    "label": "Sanggau Ledo",
    "value": "Sanggau Ledo"
  },
  {
    "id": "610707",
    "regency_id": "6107",
    "label": "Jagoi Babang",
    "value": "Jagoi Babang"
  },
  {
    "id": "610708",
    "regency_id": "6107",
    "label": "Monterado",
    "value": "Monterado"
  },
  {
    "id": "610709",
    "regency_id": "6107",
    "label": "Teriak",
    "value": "Teriak"
  },
  {
    "id": "610710",
    "regency_id": "6107",
    "label": "Suti Semarang",
    "value": "Suti Semarang"
  },
  {
    "id": "610711",
    "regency_id": "6107",
    "label": "Capkala",
    "value": "Capkala"
  },
  {
    "id": "610712",
    "regency_id": "6107",
    "label": "Siding",
    "value": "Siding"
  },
  { "id": "610713", "regency_id": "6107", "label": "Lumar", "value": "Lumar" },
  {
    "id": "610714",
    "regency_id": "6107",
    "label": "Sungai Betung",
    "value": "Sungai Betung"
  },
  {
    "id": "610715",
    "regency_id": "6107",
    "label": "Sungai Raya Kepulauan",
    "value": "Sungai Raya Kepulauan"
  },
  {
    "id": "610716",
    "regency_id": "6107",
    "label": "Lembah Bawang",
    "value": "Lembah Bawang"
  },
  {
    "id": "610717",
    "regency_id": "6107",
    "label": "Tujuh Belas",
    "value": "Tujuh Belas"
  },
  {
    "id": "610801",
    "regency_id": "6108",
    "label": "Ngabang",
    "value": "Ngabang"
  },
  {
    "id": "610802",
    "regency_id": "6108",
    "label": "Mempawah Hulu",
    "value": "Mempawah Hulu"
  },
  {
    "id": "610803",
    "regency_id": "6108",
    "label": "Menjalin",
    "value": "Menjalin"
  },
  {
    "id": "610804",
    "regency_id": "6108",
    "label": "Mandor",
    "value": "Mandor"
  },
  {
    "id": "610805",
    "regency_id": "6108",
    "label": "Air Besar",
    "value": "Air Besar"
  },
  {
    "id": "610806",
    "regency_id": "6108",
    "label": "Menyuke",
    "value": "Menyuke"
  },
  {
    "id": "610807",
    "regency_id": "6108",
    "label": "Sengah Temila",
    "value": "Sengah Temila"
  },
  {
    "id": "610808",
    "regency_id": "6108",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "610809",
    "regency_id": "6108",
    "label": "Kuala Behe",
    "value": "Kuala Behe"
  },
  {
    "id": "610810",
    "regency_id": "6108",
    "label": "Sebangki",
    "value": "Sebangki"
  },
  {
    "id": "610811",
    "regency_id": "6108",
    "label": "Jelimpo",
    "value": "Jelimpo"
  },
  {
    "id": "610812",
    "regency_id": "6108",
    "label": "Banyuke Hulu",
    "value": "Banyuke Hulu"
  },
  {
    "id": "610813",
    "regency_id": "6108",
    "label": "Sompak",
    "value": "Sompak"
  },
  {
    "id": "610901",
    "regency_id": "6109",
    "label": "Sekadau Hilir",
    "value": "Sekadau Hilir"
  },
  {
    "id": "610902",
    "regency_id": "6109",
    "label": "Sekadau Hulu",
    "value": "Sekadau Hulu"
  },
  {
    "id": "610903",
    "regency_id": "6109",
    "label": "Nanga Taman",
    "value": "Nanga Taman"
  },
  {
    "id": "610904",
    "regency_id": "6109",
    "label": "Nanga Mahap",
    "value": "Nanga Mahap"
  },
  {
    "id": "610905",
    "regency_id": "6109",
    "label": "Belitang Hilir",
    "value": "Belitang Hilir"
  },
  {
    "id": "610906",
    "regency_id": "6109",
    "label": "Belitang Hulu",
    "value": "Belitang Hulu"
  },
  {
    "id": "610907",
    "regency_id": "6109",
    "label": "Belitang",
    "value": "Belitang"
  },
  {
    "id": "611001",
    "regency_id": "6110",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "611002",
    "regency_id": "6110",
    "label": "Nanga Pinoh",
    "value": "Nanga Pinoh"
  },
  {
    "id": "611003",
    "regency_id": "6110",
    "label": "Ella Hilir",
    "value": "Ella Hilir"
  },
  {
    "id": "611004",
    "regency_id": "6110",
    "label": "Menukung",
    "value": "Menukung"
  },
  { "id": "611005", "regency_id": "6110", "label": "Sayan", "value": "Sayan" },
  {
    "id": "611006",
    "regency_id": "6110",
    "label": "Tanah Pinoh",
    "value": "Tanah Pinoh"
  },
  { "id": "611007", "regency_id": "6110", "label": "Sokan", "value": "Sokan" },
  {
    "id": "611008",
    "regency_id": "6110",
    "label": "Pinoh Utara",
    "value": "Pinoh Utara"
  },
  {
    "id": "611009",
    "regency_id": "6110",
    "label": "Pinoh Selatan",
    "value": "Pinoh Selatan"
  },
  {
    "id": "611010",
    "regency_id": "6110",
    "label": "Belimbing Hulu",
    "value": "Belimbing Hulu"
  },
  {
    "id": "611011",
    "regency_id": "6110",
    "label": "Tanah Pinoh Barat",
    "value": "Tanah Pinoh Barat"
  },
  {
    "id": "611101",
    "regency_id": "6111",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "611102",
    "regency_id": "6111",
    "label": "Simpang Hilir",
    "value": "Simpang Hilir"
  },
  {
    "id": "611103",
    "regency_id": "6111",
    "label": "Teluk Batang",
    "value": "Teluk Batang"
  },
  {
    "id": "611104",
    "regency_id": "6111",
    "label": "Pulau Maya",
    "value": "Pulau Maya"
  },
  {
    "id": "611105",
    "regency_id": "6111",
    "label": "Seponti",
    "value": "Seponti"
  },
  {
    "id": "611106",
    "regency_id": "6111",
    "label": "Kepulauan Karimata",
    "value": "Kepulauan Karimata"
  },
  {
    "id": "611201",
    "regency_id": "6112",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "611202",
    "regency_id": "6112",
    "label": "Kuala Mandor B",
    "value": "Kuala Mandor B"
  },
  {
    "id": "611203",
    "regency_id": "6112",
    "label": "Sungai Ambawang",
    "value": "Sungai Ambawang"
  },
  {
    "id": "611204",
    "regency_id": "6112",
    "label": "Terentang",
    "value": "Terentang"
  },
  {
    "id": "611205",
    "regency_id": "6112",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  { "id": "611206", "regency_id": "6112", "label": "Kubu", "value": "Kubu" },
  {
    "id": "611207",
    "regency_id": "6112",
    "label": "Rasau Jaya",
    "value": "Rasau Jaya"
  },
  {
    "id": "611208",
    "regency_id": "6112",
    "label": "Teluk Pakedai",
    "value": "Teluk Pakedai"
  },
  {
    "id": "611209",
    "regency_id": "6112",
    "label": "Sungai Kakap",
    "value": "Sungai Kakap"
  },
  {
    "id": "617101",
    "regency_id": "6171",
    "label": "Pontianak Selatan",
    "value": "Pontianak Selatan"
  },
  {
    "id": "617102",
    "regency_id": "6171",
    "label": "Pontianak Timur",
    "value": "Pontianak Timur"
  },
  {
    "id": "617103",
    "regency_id": "6171",
    "label": "Pontianak Barat",
    "value": "Pontianak Barat"
  },
  {
    "id": "617104",
    "regency_id": "6171",
    "label": "Pontianak Utara",
    "value": "Pontianak Utara"
  },
  {
    "id": "617105",
    "regency_id": "6171",
    "label": "Pontianak Kota",
    "value": "Pontianak Kota"
  },
  {
    "id": "617106",
    "regency_id": "6171",
    "label": "Pontianak Tenggara",
    "value": "Pontianak Tenggara"
  },
  {
    "id": "617201",
    "regency_id": "6172",
    "label": "Singkawang Tengah",
    "value": "Singkawang Tengah"
  },
  {
    "id": "617202",
    "regency_id": "6172",
    "label": "Singkawang Barat",
    "value": "Singkawang Barat"
  },
  {
    "id": "617203",
    "regency_id": "6172",
    "label": "Singkawang Timur",
    "value": "Singkawang Timur"
  },
  {
    "id": "617204",
    "regency_id": "6172",
    "label": "Singkawang Utara",
    "value": "Singkawang Utara"
  },
  {
    "id": "617205",
    "regency_id": "6172",
    "label": "Singkawang Selatan",
    "value": "Singkawang Selatan"
  },
  { "id": "620101", "regency_id": "6201", "label": "Kumai", "value": "Kumai" },
  {
    "id": "620102",
    "regency_id": "6201",
    "label": "Arut Selatan",
    "value": "Arut Selatan"
  },
  {
    "id": "620103",
    "regency_id": "6201",
    "label": "Kotawaringin Lama",
    "value": "Kotawaringin Lama"
  },
  {
    "id": "620104",
    "regency_id": "6201",
    "label": "Arut Utara",
    "value": "Arut Utara"
  },
  {
    "id": "620105",
    "regency_id": "6201",
    "label": "Pangkalan Lada",
    "value": "Pangkalan Lada"
  },
  {
    "id": "620106",
    "regency_id": "6201",
    "label": "Pangkalan Banteng",
    "value": "Pangkalan Banteng"
  },
  {
    "id": "620201",
    "regency_id": "6202",
    "label": "Kota Besi",
    "value": "Kota Besi"
  },
  {
    "id": "620202",
    "regency_id": "6202",
    "label": "Cempaga",
    "value": "Cempaga"
  },
  {
    "id": "620203",
    "regency_id": "6202",
    "label": "Mentaya Hulu",
    "value": "Mentaya Hulu"
  },
  {
    "id": "620204",
    "regency_id": "6202",
    "label": "Parenggean",
    "value": "Parenggean"
  },
  {
    "id": "620205",
    "regency_id": "6202",
    "label": "Baamang",
    "value": "Baamang"
  },
  {
    "id": "620206",
    "regency_id": "6202",
    "label": "Mentawa Baru Ketapang",
    "value": "Mentawa Baru Ketapang"
  },
  {
    "id": "620207",
    "regency_id": "6202",
    "label": "Mentaya Hilir Utara",
    "value": "Mentaya Hilir Utara"
  },
  {
    "id": "620208",
    "regency_id": "6202",
    "label": "Mentaya Hilir Selatan",
    "value": "Mentaya Hilir Selatan"
  },
  {
    "id": "620209",
    "regency_id": "6202",
    "label": "Pulau Hanaut",
    "value": "Pulau Hanaut"
  },
  {
    "id": "620210",
    "regency_id": "6202",
    "label": "Antang Kalang",
    "value": "Antang Kalang"
  },
  {
    "id": "620211",
    "regency_id": "6202",
    "label": "Teluk Sampit",
    "value": "Teluk Sampit"
  },
  {
    "id": "620212",
    "regency_id": "6202",
    "label": "Seranau",
    "value": "Seranau"
  },
  {
    "id": "620213",
    "regency_id": "6202",
    "label": "Cempaga Hulu",
    "value": "Cempaga Hulu"
  },
  {
    "id": "620214",
    "regency_id": "6202",
    "label": "Telawang",
    "value": "Telawang"
  },
  {
    "id": "620215",
    "regency_id": "6202",
    "label": "Bukit Santuai",
    "value": "Bukit Santuai"
  },
  {
    "id": "620216",
    "regency_id": "6202",
    "label": "Tualan Hulu",
    "value": "Tualan Hulu"
  },
  {
    "id": "620217",
    "regency_id": "6202",
    "label": "Telaga Antang",
    "value": "Telaga Antang"
  },
  { "id": "620301", "regency_id": "6203", "label": "Selat", "value": "Selat" },
  {
    "id": "620302",
    "regency_id": "6203",
    "label": "Kapuas Hilir",
    "value": "Kapuas Hilir"
  },
  {
    "id": "620303",
    "regency_id": "6203",
    "label": "Kapuas Timur",
    "value": "Kapuas Timur"
  },
  {
    "id": "620304",
    "regency_id": "6203",
    "label": "Kapuas Kuala",
    "value": "Kapuas Kuala"
  },
  {
    "id": "620305",
    "regency_id": "6203",
    "label": "Kapuas Barat",
    "value": "Kapuas Barat"
  },
  {
    "id": "620306",
    "regency_id": "6203",
    "label": "Pulau Petak",
    "value": "Pulau Petak"
  },
  {
    "id": "620307",
    "regency_id": "6203",
    "label": "Kapuas Murung",
    "value": "Kapuas Murung"
  },
  {
    "id": "620308",
    "regency_id": "6203",
    "label": "Basarang",
    "value": "Basarang"
  },
  {
    "id": "620309",
    "regency_id": "6203",
    "label": "Mantangai",
    "value": "Mantangai"
  },
  {
    "id": "620310",
    "regency_id": "6203",
    "label": "Timpah",
    "value": "Timpah"
  },
  {
    "id": "620311",
    "regency_id": "6203",
    "label": "Kapuas Tengah",
    "value": "Kapuas Tengah"
  },
  {
    "id": "620312",
    "regency_id": "6203",
    "label": "Kapuas Hulu",
    "value": "Kapuas Hulu"
  },
  {
    "id": "620313",
    "regency_id": "6203",
    "label": "Tamban Catur",
    "value": "Tamban Catur"
  },
  {
    "id": "620314",
    "regency_id": "6203",
    "label": "Pasak Talawang",
    "value": "Pasak Talawang"
  },
  {
    "id": "620315",
    "regency_id": "6203",
    "label": "Mandau Talawang",
    "value": "Mandau Talawang"
  },
  {
    "id": "620316",
    "regency_id": "6203",
    "label": "Dadahup",
    "value": "Dadahup"
  },
  {
    "id": "620317",
    "regency_id": "6203",
    "label": "Bataguh",
    "value": "Bataguh"
  },
  {
    "id": "620401",
    "regency_id": "6204",
    "label": "Jenamas",
    "value": "Jenamas"
  },
  {
    "id": "620402",
    "regency_id": "6204",
    "label": "Dusun Hilir",
    "value": "Dusun Hilir"
  },
  {
    "id": "620403",
    "regency_id": "6204",
    "label": "Karau Kuala",
    "value": "Karau Kuala"
  },
  {
    "id": "620404",
    "regency_id": "6204",
    "label": "Dusun Utara",
    "value": "Dusun Utara"
  },
  {
    "id": "620405",
    "regency_id": "6204",
    "label": "Gn. Bintang Awai",
    "value": "Gn. Bintang Awai"
  },
  {
    "id": "620406",
    "regency_id": "6204",
    "label": "Dusun Selatan",
    "value": "Dusun Selatan"
  },
  {
    "id": "620501",
    "regency_id": "6205",
    "label": "Montallat",
    "value": "Montallat"
  },
  {
    "id": "620502",
    "regency_id": "6205",
    "label": "Gunung Timang",
    "value": "Gunung Timang"
  },
  {
    "id": "620503",
    "regency_id": "6205",
    "label": "Gunung Purei",
    "value": "Gunung Purei"
  },
  {
    "id": "620504",
    "regency_id": "6205",
    "label": "Teweh Timur",
    "value": "Teweh Timur"
  },
  {
    "id": "620505",
    "regency_id": "6205",
    "label": "Teweh Tengah",
    "value": "Teweh Tengah"
  },
  { "id": "620506", "regency_id": "6205", "label": "Lahei", "value": "Lahei" },
  {
    "id": "620507",
    "regency_id": "6205",
    "label": "Teweh Baru",
    "value": "Teweh Baru"
  },
  {
    "id": "620508",
    "regency_id": "6205",
    "label": "Teweh Selatan",
    "value": "Teweh Selatan"
  },
  {
    "id": "620509",
    "regency_id": "6205",
    "label": "Lahei Barat",
    "value": "Lahei Barat"
  },
  {
    "id": "620601",
    "regency_id": "6206",
    "label": "Kamipang",
    "value": "Kamipang"
  },
  {
    "id": "620602",
    "regency_id": "6206",
    "label": "Katingan Hilir",
    "value": "Katingan Hilir"
  },
  {
    "id": "620603",
    "regency_id": "6206",
    "label": "Tewang Sangalang Garing",
    "value": "Tewang Sangalang Garing"
  },
  {
    "id": "620604",
    "regency_id": "6206",
    "label": "Pulau Malan",
    "value": "Pulau Malan"
  },
  {
    "id": "620605",
    "regency_id": "6206",
    "label": "Katingan Tengah",
    "value": "Katingan Tengah"
  },
  {
    "id": "620606",
    "regency_id": "6206",
    "label": "Sanaman Mantikei",
    "value": "Sanaman Mantikei"
  },
  {
    "id": "620607",
    "regency_id": "6206",
    "label": "Marikit",
    "value": "Marikit"
  },
  {
    "id": "620608",
    "regency_id": "6206",
    "label": "Katingan Hulu",
    "value": "Katingan Hulu"
  },
  {
    "id": "620609",
    "regency_id": "6206",
    "label": "Mendawai",
    "value": "Mendawai"
  },
  {
    "id": "620610",
    "regency_id": "6206",
    "label": "Katingan Kuala",
    "value": "Katingan Kuala"
  },
  {
    "id": "620611",
    "regency_id": "6206",
    "label": "Tasik Payawan",
    "value": "Tasik Payawan"
  },
  {
    "id": "620612",
    "regency_id": "6206",
    "label": "Petak Malai",
    "value": "Petak Malai"
  },
  {
    "id": "620613",
    "regency_id": "6206",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "620701",
    "regency_id": "6207",
    "label": "Seruyan Hilir",
    "value": "Seruyan Hilir"
  },
  {
    "id": "620702",
    "regency_id": "6207",
    "label": "Seruyan Tengah",
    "value": "Seruyan Tengah"
  },
  {
    "id": "620703",
    "regency_id": "6207",
    "label": "Danau Sembuluh",
    "value": "Danau Sembuluh"
  },
  { "id": "620704", "regency_id": "6207", "label": "Hanau", "value": "Hanau" },
  {
    "id": "620705",
    "regency_id": "6207",
    "label": "Seruyan Hulu",
    "value": "Seruyan Hulu"
  },
  {
    "id": "620706",
    "regency_id": "6207",
    "label": "Seruyan Hilir Timur",
    "value": "Seruyan Hilir Timur"
  },
  {
    "id": "620707",
    "regency_id": "6207",
    "label": "Seruyan Raya",
    "value": "Seruyan Raya"
  },
  {
    "id": "620708",
    "regency_id": "6207",
    "label": "Danau Seluluk",
    "value": "Danau Seluluk"
  },
  {
    "id": "620709",
    "regency_id": "6207",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "620710",
    "regency_id": "6207",
    "label": "Suling Tambun",
    "value": "Suling Tambun"
  },
  {
    "id": "620801",
    "regency_id": "6208",
    "label": "Sukamara",
    "value": "Sukamara"
  },
  { "id": "620802", "regency_id": "6208", "label": "Jelai", "value": "Jelai" },
  {
    "id": "620803",
    "regency_id": "6208",
    "label": "Balai Riam",
    "value": "Balai Riam"
  },
  {
    "id": "620804",
    "regency_id": "6208",
    "label": "Pantai Lunci",
    "value": "Pantai Lunci"
  },
  {
    "id": "620805",
    "regency_id": "6208",
    "label": "Permata Kecubung",
    "value": "Permata Kecubung"
  },
  {
    "id": "620901",
    "regency_id": "6209",
    "label": "Lamandau",
    "value": "Lamandau"
  },
  {
    "id": "620902",
    "regency_id": "6209",
    "label": "Delang",
    "value": "Delang"
  },
  { "id": "620903", "regency_id": "6209", "label": "Bulik", "value": "Bulik" },
  {
    "id": "620904",
    "regency_id": "6209",
    "label": "Bulik Timur",
    "value": "Bulik Timur"
  },
  {
    "id": "620905",
    "regency_id": "6209",
    "label": "Menthobi Raya",
    "value": "Menthobi Raya"
  },
  {
    "id": "620906",
    "regency_id": "6209",
    "label": "Sematu Jaya",
    "value": "Sematu Jaya"
  },
  {
    "id": "620907",
    "regency_id": "6209",
    "label": "Belantikan Raya",
    "value": "Belantikan Raya"
  },
  {
    "id": "620908",
    "regency_id": "6209",
    "label": "Batang Kawa",
    "value": "Batang Kawa"
  },
  {
    "id": "621001",
    "regency_id": "6210",
    "label": "Sepang",
    "value": "Sepang"
  },
  { "id": "621002", "regency_id": "6210", "label": "Kurun", "value": "Kurun" },
  { "id": "621003", "regency_id": "6210", "label": "Tewah", "value": "Tewah" },
  {
    "id": "621004",
    "regency_id": "6210",
    "label": "Kahayan Hulu Utara",
    "value": "Kahayan Hulu Utara"
  },
  {
    "id": "621005",
    "regency_id": "6210",
    "label": "Rungan",
    "value": "Rungan"
  },
  {
    "id": "621006",
    "regency_id": "6210",
    "label": "Manuhing",
    "value": "Manuhing"
  },
  {
    "id": "621007",
    "regency_id": "6210",
    "label": "Mihing Raya",
    "value": "Mihing Raya"
  },
  {
    "id": "621008",
    "regency_id": "6210",
    "label": "Damang Batu",
    "value": "Damang Batu"
  },
  {
    "id": "621009",
    "regency_id": "6210",
    "label": "Miri Manasa",
    "value": "Miri Manasa"
  },
  {
    "id": "621010",
    "regency_id": "6210",
    "label": "Rungan Hulu",
    "value": "Rungan Hulu"
  },
  {
    "id": "621011",
    "regency_id": "6210",
    "label": "Manuhing Raya",
    "value": "Manuhing Raya"
  },
  {
    "id": "621012",
    "regency_id": "6210",
    "label": "Rungan Barat",
    "value": "Rungan Barat"
  },
  {
    "id": "621101",
    "regency_id": "6211",
    "label": "Pandih Batu",
    "value": "Pandih Batu"
  },
  {
    "id": "621102",
    "regency_id": "6211",
    "label": "Kahayan Kuala",
    "value": "Kahayan Kuala"
  },
  {
    "id": "621103",
    "regency_id": "6211",
    "label": "Kahayan Tengah",
    "value": "Kahayan Tengah"
  },
  {
    "id": "621104",
    "regency_id": "6211",
    "label": "Banama Tingang",
    "value": "Banama Tingang"
  },
  {
    "id": "621105",
    "regency_id": "6211",
    "label": "Kahayan Hilir",
    "value": "Kahayan Hilir"
  },
  {
    "id": "621106",
    "regency_id": "6211",
    "label": "Maliku",
    "value": "Maliku"
  },
  {
    "id": "621107",
    "regency_id": "6211",
    "label": "Jabiren",
    "value": "Jabiren"
  },
  {
    "id": "621108",
    "regency_id": "6211",
    "label": "Sebangau Kuala",
    "value": "Sebangau Kuala"
  },
  {
    "id": "621201",
    "regency_id": "6212",
    "label": "Murung",
    "value": "Murung"
  },
  {
    "id": "621202",
    "regency_id": "6212",
    "label": "Tanah Siang",
    "value": "Tanah Siang"
  },
  {
    "id": "621203",
    "regency_id": "6212",
    "label": "Laung Tuhup",
    "value": "Laung Tuhup"
  },
  {
    "id": "621204",
    "regency_id": "6212",
    "label": "Permata Intan",
    "value": "Permata Intan"
  },
  {
    "id": "621205",
    "regency_id": "6212",
    "label": "Sumber Barito",
    "value": "Sumber Barito"
  },
  {
    "id": "621206",
    "regency_id": "6212",
    "label": "Barito Tuhup Raya",
    "value": "Barito Tuhup Raya"
  },
  {
    "id": "621207",
    "regency_id": "6212",
    "label": "Tanah Siang Selatan",
    "value": "Tanah Siang Selatan"
  },
  {
    "id": "621208",
    "regency_id": "6212",
    "label": "Sungai Babuat",
    "value": "Sungai Babuat"
  },
  {
    "id": "621209",
    "regency_id": "6212",
    "label": "Seribu Riam",
    "value": "Seribu Riam"
  },
  {
    "id": "621210",
    "regency_id": "6212",
    "label": "Uut Murung",
    "value": "Uut Murung"
  },
  {
    "id": "621301",
    "regency_id": "6213",
    "label": "Dusun Timur",
    "value": "Dusun Timur"
  },
  {
    "id": "621302",
    "regency_id": "6213",
    "label": "Banua Lima",
    "value": "Banua Lima"
  },
  {
    "id": "621303",
    "regency_id": "6213",
    "label": "Patangkep Tutui",
    "value": "Patangkep Tutui"
  },
  { "id": "621304", "regency_id": "6213", "label": "Awang", "value": "Awang" },
  {
    "id": "621305",
    "regency_id": "6213",
    "label": "Dusun Tengah",
    "value": "Dusun Tengah"
  },
  {
    "id": "621306",
    "regency_id": "6213",
    "label": "Pematang Karau",
    "value": "Pematang Karau"
  },
  {
    "id": "621307",
    "regency_id": "6213",
    "label": "Paju Epat",
    "value": "Paju Epat"
  },
  {
    "id": "621308",
    "regency_id": "6213",
    "label": "Raren Batuah",
    "value": "Raren Batuah"
  },
  { "id": "621309", "regency_id": "6213", "label": "Paku", "value": "Paku" },
  {
    "id": "621310",
    "regency_id": "6213",
    "label": "Karusen Janang",
    "value": "Karusen Janang"
  },
  {
    "id": "627101",
    "regency_id": "6271",
    "label": "Pahandut",
    "value": "Pahandut"
  },
  {
    "id": "627102",
    "regency_id": "6271",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "627103",
    "regency_id": "6271",
    "label": "Jekan Raya",
    "value": "Jekan Raya"
  },
  {
    "id": "627104",
    "regency_id": "6271",
    "label": "Sabangau",
    "value": "Sabangau"
  },
  {
    "id": "627105",
    "regency_id": "6271",
    "label": "Rakumpit",
    "value": "Rakumpit"
  },
  {
    "id": "630101",
    "regency_id": "6301",
    "label": "Takisung",
    "value": "Takisung"
  },
  {
    "id": "630102",
    "regency_id": "6301",
    "label": "Jorong",
    "value": "Jorong"
  },
  {
    "id": "630103",
    "regency_id": "6301",
    "label": "Pelaihari",
    "value": "Pelaihari"
  },
  { "id": "630104", "regency_id": "6301", "label": "Kurau", "value": "Kurau" },
  {
    "id": "630105",
    "regency_id": "6301",
    "label": "Bati Bati",
    "value": "Bati Bati"
  },
  {
    "id": "630106",
    "regency_id": "6301",
    "label": "Panyipatan",
    "value": "Panyipatan"
  },
  {
    "id": "630107",
    "regency_id": "6301",
    "label": "Kintap",
    "value": "Kintap"
  },
  {
    "id": "630108",
    "regency_id": "6301",
    "label": "Tambang Ulang",
    "value": "Tambang Ulang"
  },
  {
    "id": "630109",
    "regency_id": "6301",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "630110",
    "regency_id": "6301",
    "label": "Bajuin",
    "value": "Bajuin"
  },
  {
    "id": "630111",
    "regency_id": "6301",
    "label": "Bumi Makmur",
    "value": "Bumi Makmur"
  },
  {
    "id": "630201",
    "regency_id": "6302",
    "label": "Pulau Sembilan",
    "value": "Pulau Sembilan"
  },
  {
    "id": "630202",
    "regency_id": "6302",
    "label": "Pulau Laut Barat",
    "value": "Pulau Laut Barat"
  },
  {
    "id": "630203",
    "regency_id": "6302",
    "label": "Pulau Laut Selatan",
    "value": "Pulau Laut Selatan"
  },
  {
    "id": "630204",
    "regency_id": "6302",
    "label": "Pulau Laut Timur",
    "value": "Pulau Laut Timur"
  },
  {
    "id": "630205",
    "regency_id": "6302",
    "label": "Pulau Sebuku",
    "value": "Pulau Sebuku"
  },
  {
    "id": "630206",
    "regency_id": "6302",
    "label": "Pulau Laut Utara",
    "value": "Pulau Laut Utara"
  },
  {
    "id": "630207",
    "regency_id": "6302",
    "label": "Kelumpang Selatan",
    "value": "Kelumpang Selatan"
  },
  {
    "id": "630208",
    "regency_id": "6302",
    "label": "Kelumpang Hulu",
    "value": "Kelumpang Hulu"
  },
  {
    "id": "630209",
    "regency_id": "6302",
    "label": "Kelumpang Tengah",
    "value": "Kelumpang Tengah"
  },
  {
    "id": "630210",
    "regency_id": "6302",
    "label": "Kelumpang Utara",
    "value": "Kelumpang Utara"
  },
  {
    "id": "630211",
    "regency_id": "6302",
    "label": "Pamukan Selatan",
    "value": "Pamukan Selatan"
  },
  {
    "id": "630212",
    "regency_id": "6302",
    "label": "Sampanahan",
    "value": "Sampanahan"
  },
  {
    "id": "630213",
    "regency_id": "6302",
    "label": "Pamukan Utara",
    "value": "Pamukan Utara"
  },
  {
    "id": "630214",
    "regency_id": "6302",
    "label": "Hampang",
    "value": "Hampang"
  },
  {
    "id": "630215",
    "regency_id": "6302",
    "label": "Sungai Durian",
    "value": "Sungai Durian"
  },
  {
    "id": "630216",
    "regency_id": "6302",
    "label": "Pulau Laut Tengah",
    "value": "Pulau Laut Tengah"
  },
  {
    "id": "630217",
    "regency_id": "6302",
    "label": "Kelumpang Hilir",
    "value": "Kelumpang Hilir"
  },
  {
    "id": "630218",
    "regency_id": "6302",
    "label": "Kelumpang Barat",
    "value": "Kelumpang Barat"
  },
  {
    "id": "630219",
    "regency_id": "6302",
    "label": "Pamukan Barat",
    "value": "Pamukan Barat"
  },
  {
    "id": "630220",
    "regency_id": "6302",
    "label": "Pulau Laut Kepulauan",
    "value": "Pulau Laut Kepulauan"
  },
  {
    "id": "630221",
    "regency_id": "6302",
    "label": "Pulau Laut Tanjung Selayar",
    "value": "Pulau Laut Tanjung Selayar"
  },
  {
    "id": "630301",
    "regency_id": "6303",
    "label": "Aluh Aluh",
    "value": "Aluh Aluh"
  },
  {
    "id": "630302",
    "regency_id": "6303",
    "label": "Kertak Hanyar",
    "value": "Kertak Hanyar"
  },
  {
    "id": "630303",
    "regency_id": "6303",
    "label": "Gambut",
    "value": "Gambut"
  },
  {
    "id": "630304",
    "regency_id": "6303",
    "label": "Sungai Tabuk",
    "value": "Sungai Tabuk"
  },
  {
    "id": "630305",
    "regency_id": "6303",
    "label": "Martapura",
    "value": "Martapura"
  },
  {
    "id": "630306",
    "regency_id": "6303",
    "label": "Karang Intan",
    "value": "Karang Intan"
  },
  {
    "id": "630307",
    "regency_id": "6303",
    "label": "Astambul",
    "value": "Astambul"
  },
  {
    "id": "630308",
    "regency_id": "6303",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "630309",
    "regency_id": "6303",
    "label": "Pengarom",
    "value": "Pengarom"
  },
  {
    "id": "630310",
    "regency_id": "6303",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "630311",
    "regency_id": "6303",
    "label": "Aranio",
    "value": "Aranio"
  },
  {
    "id": "630312",
    "regency_id": "6303",
    "label": "Mataraman",
    "value": "Mataraman"
  },
  {
    "id": "630313",
    "regency_id": "6303",
    "label": "Beruntung Baru",
    "value": "Beruntung Baru"
  },
  {
    "id": "630314",
    "regency_id": "6303",
    "label": "Martapura Barat",
    "value": "Martapura Barat"
  },
  {
    "id": "630315",
    "regency_id": "6303",
    "label": "Martapura Timur",
    "value": "Martapura Timur"
  },
  {
    "id": "630316",
    "regency_id": "6303",
    "label": "Sambung Makmur",
    "value": "Sambung Makmur"
  },
  {
    "id": "630317",
    "regency_id": "6303",
    "label": "Paramasan",
    "value": "Paramasan"
  },
  {
    "id": "630318",
    "regency_id": "6303",
    "label": "Telaga Bauntung",
    "value": "Telaga Bauntung"
  },
  {
    "id": "630319",
    "regency_id": "6303",
    "label": "Tatah Makmur",
    "value": "Tatah Makmur"
  },
  {
    "id": "630320",
    "regency_id": "6303",
    "label": "Cintapuri Darussalam",
    "value": "Cintapuri Darussalam"
  },
  {
    "id": "630401",
    "regency_id": "6304",
    "label": "Tabunganen",
    "value": "Tabunganen"
  },
  {
    "id": "630402",
    "regency_id": "6304",
    "label": "Tamban",
    "value": "Tamban"
  },
  {
    "id": "630403",
    "regency_id": "6304",
    "label": "Anjir Pasar",
    "value": "Anjir Pasar"
  },
  {
    "id": "630404",
    "regency_id": "6304",
    "label": "Anjir Muara",
    "value": "Anjir Muara"
  },
  {
    "id": "630405",
    "regency_id": "6304",
    "label": "Alalak",
    "value": "Alalak"
  },
  {
    "id": "630406",
    "regency_id": "6304",
    "label": "Mandastana",
    "value": "Mandastana"
  },
  {
    "id": "630407",
    "regency_id": "6304",
    "label": "Rantau Badauh",
    "value": "Rantau Badauh"
  },
  {
    "id": "630408",
    "regency_id": "6304",
    "label": "Belawang",
    "value": "Belawang"
  },
  {
    "id": "630409",
    "regency_id": "6304",
    "label": "Cerbon",
    "value": "Cerbon"
  },
  {
    "id": "630410",
    "regency_id": "6304",
    "label": "Bakumpai",
    "value": "Bakumpai"
  },
  {
    "id": "630411",
    "regency_id": "6304",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "630412",
    "regency_id": "6304",
    "label": "Tabukan",
    "value": "Tabukan"
  },
  {
    "id": "630413",
    "regency_id": "6304",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "630414",
    "regency_id": "6304",
    "label": "Barambai",
    "value": "Barambai"
  },
  {
    "id": "630415",
    "regency_id": "6304",
    "label": "Marabahan",
    "value": "Marabahan"
  },
  {
    "id": "630416",
    "regency_id": "6304",
    "label": "Wanaraya",
    "value": "Wanaraya"
  },
  {
    "id": "630417",
    "regency_id": "6304",
    "label": "Jejangkit",
    "value": "Jejangkit"
  },
  {
    "id": "630501",
    "regency_id": "6305",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "630502",
    "regency_id": "6305",
    "label": "Tapin Selatan",
    "value": "Tapin Selatan"
  },
  {
    "id": "630503",
    "regency_id": "6305",
    "label": "Tapin Tengah",
    "value": "Tapin Tengah"
  },
  {
    "id": "630504",
    "regency_id": "6305",
    "label": "Tapin Utara",
    "value": "Tapin Utara"
  },
  {
    "id": "630505",
    "regency_id": "6305",
    "label": "Candi Laras Selatan",
    "value": "Candi Laras Selatan"
  },
  {
    "id": "630506",
    "regency_id": "6305",
    "label": "Candi Laras Utara",
    "value": "Candi Laras Utara"
  },
  {
    "id": "630507",
    "regency_id": "6305",
    "label": "Bakarangan",
    "value": "Bakarangan"
  },
  { "id": "630508", "regency_id": "6305", "label": "Piani", "value": "Piani" },
  {
    "id": "630509",
    "regency_id": "6305",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "630510",
    "regency_id": "6305",
    "label": "Lokpaikat",
    "value": "Lokpaikat"
  },
  {
    "id": "630511",
    "regency_id": "6305",
    "label": "Salam Babaris",
    "value": "Salam Babaris"
  },
  {
    "id": "630512",
    "regency_id": "6305",
    "label": "Hatungun",
    "value": "Hatungun"
  },
  {
    "id": "630601",
    "regency_id": "6306",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "630602",
    "regency_id": "6306",
    "label": "Padang Batung",
    "value": "Padang Batung"
  },
  {
    "id": "630603",
    "regency_id": "6306",
    "label": "Telaga Langsat",
    "value": "Telaga Langsat"
  },
  {
    "id": "630604",
    "regency_id": "6306",
    "label": "Angkinang",
    "value": "Angkinang"
  },
  {
    "id": "630605",
    "regency_id": "6306",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "630606",
    "regency_id": "6306",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "630607",
    "regency_id": "6306",
    "label": "Daha Selatan",
    "value": "Daha Selatan"
  },
  {
    "id": "630608",
    "regency_id": "6306",
    "label": "Daha Utara",
    "value": "Daha Utara"
  },
  {
    "id": "630609",
    "regency_id": "6306",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "630610",
    "regency_id": "6306",
    "label": "Loksado",
    "value": "Loksado"
  },
  {
    "id": "630611",
    "regency_id": "6306",
    "label": "Daha Barat",
    "value": "Daha Barat"
  },
  {
    "id": "630701",
    "regency_id": "6307",
    "label": "Haruyan",
    "value": "Haruyan"
  },
  {
    "id": "630702",
    "regency_id": "6307",
    "label": "Batu Benawa",
    "value": "Batu Benawa"
  },
  {
    "id": "630703",
    "regency_id": "6307",
    "label": "Labuan Amas Selatan",
    "value": "Labuan Amas Selatan"
  },
  {
    "id": "630704",
    "regency_id": "6307",
    "label": "Labuan Amas Utara",
    "value": "Labuan Amas Utara"
  },
  {
    "id": "630705",
    "regency_id": "6307",
    "label": "Pandawan",
    "value": "Pandawan"
  },
  {
    "id": "630706",
    "regency_id": "6307",
    "label": "Barabai",
    "value": "Barabai"
  },
  {
    "id": "630707",
    "regency_id": "6307",
    "label": "Batang Alai Selatan",
    "value": "Batang Alai Selatan"
  },
  {
    "id": "630708",
    "regency_id": "6307",
    "label": "Batang Alai Utara",
    "value": "Batang Alai Utara"
  },
  {
    "id": "630709",
    "regency_id": "6307",
    "label": "Hantakan",
    "value": "Hantakan"
  },
  {
    "id": "630710",
    "regency_id": "6307",
    "label": "Batang Alai Timur",
    "value": "Batang Alai Timur"
  },
  {
    "id": "630711",
    "regency_id": "6307",
    "label": "Limpasu",
    "value": "Limpasu"
  },
  {
    "id": "630801",
    "regency_id": "6308",
    "label": "Danau Panggang",
    "value": "Danau Panggang"
  },
  {
    "id": "630802",
    "regency_id": "6308",
    "label": "Babirik",
    "value": "Babirik"
  },
  {
    "id": "630803",
    "regency_id": "6308",
    "label": "Sungai Pandan",
    "value": "Sungai Pandan"
  },
  {
    "id": "630804",
    "regency_id": "6308",
    "label": "Amuntai Selatan",
    "value": "Amuntai Selatan"
  },
  {
    "id": "630805",
    "regency_id": "6308",
    "label": "Amuntai Tengah",
    "value": "Amuntai Tengah"
  },
  {
    "id": "630806",
    "regency_id": "6308",
    "label": "Amuntai Utara",
    "value": "Amuntai Utara"
  },
  {
    "id": "630807",
    "regency_id": "6308",
    "label": "Banjang",
    "value": "Banjang"
  },
  {
    "id": "630808",
    "regency_id": "6308",
    "label": "Haur Gading",
    "value": "Haur Gading"
  },
  {
    "id": "630809",
    "regency_id": "6308",
    "label": "Paminggir",
    "value": "Paminggir"
  },
  {
    "id": "630810",
    "regency_id": "6308",
    "label": "Sungai Tabukan",
    "value": "Sungai Tabukan"
  },
  {
    "id": "630901",
    "regency_id": "6309",
    "label": "Banua Lawas",
    "value": "Banua Lawas"
  },
  { "id": "630902", "regency_id": "6309", "label": "Kelua", "value": "Kelua" },
  { "id": "630903", "regency_id": "6309", "label": "Tanta", "value": "Tanta" },
  {
    "id": "630904",
    "regency_id": "6309",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "630905",
    "regency_id": "6309",
    "label": "Haruai",
    "value": "Haruai"
  },
  {
    "id": "630906",
    "regency_id": "6309",
    "label": "Murung Pudak",
    "value": "Murung Pudak"
  },
  {
    "id": "630907",
    "regency_id": "6309",
    "label": "Muara Uya",
    "value": "Muara Uya"
  },
  {
    "id": "630908",
    "regency_id": "6309",
    "label": "Muara Harus",
    "value": "Muara Harus"
  },
  {
    "id": "630909",
    "regency_id": "6309",
    "label": "Pugaan",
    "value": "Pugaan"
  },
  { "id": "630910", "regency_id": "6309", "label": "Upau", "value": "Upau" },
  { "id": "630911", "regency_id": "6309", "label": "Jaro", "value": "Jaro" },
  {
    "id": "630912",
    "regency_id": "6309",
    "label": "Bintang Ara",
    "value": "Bintang Ara"
  },
  {
    "id": "631001",
    "regency_id": "6310",
    "label": "Batu Licin",
    "value": "Batu Licin"
  },
  {
    "id": "631002",
    "regency_id": "6310",
    "label": "Kusan Hilir",
    "value": "Kusan Hilir"
  },
  {
    "id": "631003",
    "regency_id": "6310",
    "label": "Sungai Loban",
    "value": "Sungai Loban"
  },
  { "id": "631004", "regency_id": "6310", "label": "Satui", "value": "Satui" },
  {
    "id": "631005",
    "regency_id": "6310",
    "label": "Kusan Hulu",
    "value": "Kusan Hulu"
  },
  {
    "id": "631006",
    "regency_id": "6310",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "631007",
    "regency_id": "6310",
    "label": "Karang Bintang",
    "value": "Karang Bintang"
  },
  {
    "id": "631008",
    "regency_id": "6310",
    "label": "Mantewe",
    "value": "Mantewe"
  },
  {
    "id": "631009",
    "regency_id": "6310",
    "label": "Angsana",
    "value": "Angsana"
  },
  {
    "id": "631010",
    "regency_id": "6310",
    "label": "Kuranji",
    "value": "Kuranji"
  },
  { "id": "631101", "regency_id": "6311", "label": "Juai", "value": "Juai" },
  {
    "id": "631102",
    "regency_id": "6311",
    "label": "Halong",
    "value": "Halong"
  },
  {
    "id": "631103",
    "regency_id": "6311",
    "label": "Awayan",
    "value": "Awayan"
  },
  {
    "id": "631104",
    "regency_id": "6311",
    "label": "Batu Mandi",
    "value": "Batu Mandi"
  },
  {
    "id": "631105",
    "regency_id": "6311",
    "label": "Lampihong",
    "value": "Lampihong"
  },
  {
    "id": "631106",
    "regency_id": "6311",
    "label": "Paringin",
    "value": "Paringin"
  },
  {
    "id": "631107",
    "regency_id": "6311",
    "label": "Paringin Selatan",
    "value": "Paringin Selatan"
  },
  {
    "id": "631108",
    "regency_id": "6311",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "637101",
    "regency_id": "6371",
    "label": "Banjarmasin Selatan",
    "value": "Banjarmasin Selatan"
  },
  {
    "id": "637102",
    "regency_id": "6371",
    "label": "Banjarmasin Timur",
    "value": "Banjarmasin Timur"
  },
  {
    "id": "637103",
    "regency_id": "6371",
    "label": "Banjarmasin Barat",
    "value": "Banjarmasin Barat"
  },
  {
    "id": "637104",
    "regency_id": "6371",
    "label": "Banjarmasin Utara",
    "value": "Banjarmasin Utara"
  },
  {
    "id": "637105",
    "regency_id": "6371",
    "label": "Banjarmasin Tengah",
    "value": "Banjarmasin Tengah"
  },
  {
    "id": "637202",
    "regency_id": "6372",
    "label": "Landasan Ulin",
    "value": "Landasan Ulin"
  },
  {
    "id": "637203",
    "regency_id": "6372",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "637204",
    "regency_id": "6372",
    "label": "Banjarbaru Utara",
    "value": "Banjarbaru Utara"
  },
  {
    "id": "637205",
    "regency_id": "6372",
    "label": "Banjarbaru Selatan",
    "value": "Banjarbaru Selatan"
  },
  {
    "id": "637206",
    "regency_id": "6372",
    "label": "Liang Anggang",
    "value": "Liang Anggang"
  },
  {
    "id": "640101",
    "regency_id": "6401",
    "label": "Batu Sopang",
    "value": "Batu Sopang"
  },
  {
    "id": "640102",
    "regency_id": "6401",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "640103",
    "regency_id": "6401",
    "label": "Paser Belengkong",
    "value": "Paser Belengkong"
  },
  {
    "id": "640104",
    "regency_id": "6401",
    "label": "Tanah Grogot",
    "value": "Tanah Grogot"
  },
  { "id": "640105", "regency_id": "6401", "label": "Kuaro", "value": "Kuaro" },
  {
    "id": "640106",
    "regency_id": "6401",
    "label": "Long Ikis",
    "value": "Long Ikis"
  },
  {
    "id": "640107",
    "regency_id": "6401",
    "label": "Muara Komam",
    "value": "Muara Komam"
  },
  {
    "id": "640108",
    "regency_id": "6401",
    "label": "Long Kali",
    "value": "Long Kali"
  },
  {
    "id": "640109",
    "regency_id": "6401",
    "label": "Batu Engau",
    "value": "Batu Engau"
  },
  {
    "id": "640110",
    "regency_id": "6401",
    "label": "Muara Samu",
    "value": "Muara Samu"
  },
  {
    "id": "640201",
    "regency_id": "6402",
    "label": "Muara Muntai",
    "value": "Muara Muntai"
  },
  {
    "id": "640202",
    "regency_id": "6402",
    "label": "Loa Kulu",
    "value": "Loa Kulu"
  },
  {
    "id": "640203",
    "regency_id": "6402",
    "label": "Loa Janan",
    "value": "Loa Janan"
  },
  {
    "id": "640204",
    "regency_id": "6402",
    "label": "Anggana",
    "value": "Anggana"
  },
  {
    "id": "640205",
    "regency_id": "6402",
    "label": "Muara Badak",
    "value": "Muara Badak"
  },
  {
    "id": "640206",
    "regency_id": "6402",
    "label": "Tenggarong",
    "value": "Tenggarong"
  },
  {
    "id": "640207",
    "regency_id": "6402",
    "label": "Sebulu",
    "value": "Sebulu"
  },
  {
    "id": "640208",
    "regency_id": "6402",
    "label": "Kota Bangun",
    "value": "Kota Bangun"
  },
  {
    "id": "640209",
    "regency_id": "6402",
    "label": "Kenohan",
    "value": "Kenohan"
  },
  {
    "id": "640210",
    "regency_id": "6402",
    "label": "Kembang Janggut",
    "value": "Kembang Janggut"
  },
  {
    "id": "640211",
    "regency_id": "6402",
    "label": "Muara Kaman",
    "value": "Muara Kaman"
  },
  {
    "id": "640212",
    "regency_id": "6402",
    "label": "Tabang",
    "value": "Tabang"
  },
  {
    "id": "640213",
    "regency_id": "6402",
    "label": "Samboja",
    "value": "Samboja"
  },
  {
    "id": "640214",
    "regency_id": "6402",
    "label": "Muara Jawa",
    "value": "Muara Jawa"
  },
  {
    "id": "640215",
    "regency_id": "6402",
    "label": "Sanga Sanga",
    "value": "Sanga Sanga"
  },
  {
    "id": "640216",
    "regency_id": "6402",
    "label": "Tenggarong Seberang",
    "value": "Tenggarong Seberang"
  },
  {
    "id": "640217",
    "regency_id": "6402",
    "label": "Marang Kayu",
    "value": "Marang Kayu"
  },
  {
    "id": "640218",
    "regency_id": "6402",
    "label": "Muara Wis",
    "value": "Muara Wis"
  },
  { "id": "640301", "regency_id": "6403", "label": "Kelay", "value": "Kelay" },
  {
    "id": "640302",
    "regency_id": "6403",
    "label": "Talisayan",
    "value": "Talisayan"
  },
  {
    "id": "640303",
    "regency_id": "6403",
    "label": "Sambaliung",
    "value": "Sambaliung"
  },
  { "id": "640304", "regency_id": "6403", "label": "Segah", "value": "Segah" },
  {
    "id": "640305",
    "regency_id": "6403",
    "label": "Tanjung Redeb",
    "value": "Tanjung Redeb"
  },
  {
    "id": "640306",
    "regency_id": "6403",
    "label": "Gunung Tabur",
    "value": "Gunung Tabur"
  },
  {
    "id": "640307",
    "regency_id": "6403",
    "label": "Pulau Derawan",
    "value": "Pulau Derawan"
  },
  {
    "id": "640308",
    "regency_id": "6403",
    "label": "Biduk-Biduk",
    "value": "Biduk-Biduk"
  },
  {
    "id": "640309",
    "regency_id": "6403",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "640310",
    "regency_id": "6403",
    "label": "Tabalar",
    "value": "Tabalar"
  },
  {
    "id": "640311",
    "regency_id": "6403",
    "label": "Maratua",
    "value": "Maratua"
  },
  {
    "id": "640312",
    "regency_id": "6403",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "640313",
    "regency_id": "6403",
    "label": "Biatan",
    "value": "Biatan"
  },
  {
    "id": "640705",
    "regency_id": "6407",
    "label": "Long Iram",
    "value": "Long Iram"
  },
  { "id": "640706", "regency_id": "6407", "label": "Melak", "value": "Melak" },
  {
    "id": "640707",
    "regency_id": "6407",
    "label": "Barong Tongkok",
    "value": "Barong Tongkok"
  },
  { "id": "640708", "regency_id": "6407", "label": "Damai", "value": "Damai" },
  {
    "id": "640709",
    "regency_id": "6407",
    "label": "Muara Lawa",
    "value": "Muara Lawa"
  },
  {
    "id": "640710",
    "regency_id": "6407",
    "label": "Muara Pahu",
    "value": "Muara Pahu"
  },
  {
    "id": "640711",
    "regency_id": "6407",
    "label": "Jempang",
    "value": "Jempang"
  },
  {
    "id": "640712",
    "regency_id": "6407",
    "label": "Bongan",
    "value": "Bongan"
  },
  {
    "id": "640713",
    "regency_id": "6407",
    "label": "Penyinggahan",
    "value": "Penyinggahan"
  },
  {
    "id": "640714",
    "regency_id": "6407",
    "label": "Bentian Besar",
    "value": "Bentian Besar"
  },
  {
    "id": "640715",
    "regency_id": "6407",
    "label": "Linggang Bigung",
    "value": "Linggang Bigung"
  },
  {
    "id": "640716",
    "regency_id": "6407",
    "label": "Nyuatan",
    "value": "Nyuatan"
  },
  {
    "id": "640717",
    "regency_id": "6407",
    "label": "Siluq Ngurai",
    "value": "Siluq Ngurai"
  },
  {
    "id": "640718",
    "regency_id": "6407",
    "label": "Mook Manaar Bulatn",
    "value": "Mook Manaar Bulatn"
  },
  {
    "id": "640719",
    "regency_id": "6407",
    "label": "Tering",
    "value": "Tering"
  },
  {
    "id": "640720",
    "regency_id": "6407",
    "label": "Sekolaq Darat",
    "value": "Sekolaq Darat"
  },
  {
    "id": "640801",
    "regency_id": "6408",
    "label": "Muara Ancalong",
    "value": "Muara Ancalong"
  },
  {
    "id": "640802",
    "regency_id": "6408",
    "label": "Muara Wahau",
    "value": "Muara Wahau"
  },
  {
    "id": "640803",
    "regency_id": "6408",
    "label": "Muara Bengkal",
    "value": "Muara Bengkal"
  },
  {
    "id": "640804",
    "regency_id": "6408",
    "label": "Sangatta Utara",
    "value": "Sangatta Utara"
  },
  {
    "id": "640805",
    "regency_id": "6408",
    "label": "Sangkulirang",
    "value": "Sangkulirang"
  },
  {
    "id": "640806",
    "regency_id": "6408",
    "label": "Busang",
    "value": "Busang"
  },
  { "id": "640807", "regency_id": "6408", "label": "Telen", "value": "Telen" },
  {
    "id": "640808",
    "regency_id": "6408",
    "label": "Kombeng",
    "value": "Kombeng"
  },
  {
    "id": "640809",
    "regency_id": "6408",
    "label": "Bengalon",
    "value": "Bengalon"
  },
  {
    "id": "640810",
    "regency_id": "6408",
    "label": "Kaliorang",
    "value": "Kaliorang"
  },
  {
    "id": "640811",
    "regency_id": "6408",
    "label": "Sandaran",
    "value": "Sandaran"
  },
  {
    "id": "640812",
    "regency_id": "6408",
    "label": "Sangatta Selatan",
    "value": "Sangatta Selatan"
  },
  {
    "id": "640813",
    "regency_id": "6408",
    "label": "Teluk Pandan",
    "value": "Teluk Pandan"
  },
  {
    "id": "640814",
    "regency_id": "6408",
    "label": "Rantau Pulung",
    "value": "Rantau Pulung"
  },
  {
    "id": "640815",
    "regency_id": "6408",
    "label": "Kaubun",
    "value": "Kaubun"
  },
  {
    "id": "640816",
    "regency_id": "6408",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "640817",
    "regency_id": "6408",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "640818",
    "regency_id": "6408",
    "label": "Long Mesangat",
    "value": "Long Mesangat"
  },
  {
    "id": "640901",
    "regency_id": "6409",
    "label": "Penajam",
    "value": "Penajam"
  },
  { "id": "640902", "regency_id": "6409", "label": "Waru", "value": "Waru" },
  {
    "id": "640903",
    "regency_id": "6409",
    "label": "Babulu",
    "value": "Babulu"
  },
  {
    "id": "640904",
    "regency_id": "6409",
    "label": "Sepaku",
    "value": "Sepaku"
  },
  {
    "id": "641101",
    "regency_id": "6411",
    "label": "Long Bagun",
    "value": "Long Bagun"
  },
  {
    "id": "641102",
    "regency_id": "6411",
    "label": "Long Hubung",
    "value": "Long Hubung"
  },
  { "id": "641103", "regency_id": "6411", "label": "Laham", "value": "Laham" },
  {
    "id": "641104",
    "regency_id": "6411",
    "label": "Long Apari",
    "value": "Long Apari"
  },
  {
    "id": "641105",
    "regency_id": "6411",
    "label": "Long Pahangai",
    "value": "Long Pahangai"
  },
  {
    "id": "647101",
    "regency_id": "6471",
    "label": "Balikpapan Timur",
    "value": "Balikpapan Timur"
  },
  {
    "id": "647102",
    "regency_id": "6471",
    "label": "Balikpapan Barat",
    "value": "Balikpapan Barat"
  },
  {
    "id": "647103",
    "regency_id": "6471",
    "label": "Balikpapan Utara",
    "value": "Balikpapan Utara"
  },
  {
    "id": "647104",
    "regency_id": "6471",
    "label": "Balikpapan Tengah",
    "value": "Balikpapan Tengah"
  },
  {
    "id": "647105",
    "regency_id": "6471",
    "label": "Balikpapan Selatan",
    "value": "Balikpapan Selatan"
  },
  {
    "id": "647106",
    "regency_id": "6471",
    "label": "Balikpapan Kota",
    "value": "Balikpapan Kota"
  },
  {
    "id": "647201",
    "regency_id": "6472",
    "label": "Palaran",
    "value": "Palaran"
  },
  {
    "id": "647202",
    "regency_id": "6472",
    "label": "Samarinda Seberang",
    "value": "Samarinda Seberang"
  },
  {
    "id": "647203",
    "regency_id": "6472",
    "label": "Samarinda Ulu",
    "value": "Samarinda Ulu"
  },
  {
    "id": "647204",
    "regency_id": "6472",
    "label": "Samarinda Ilir",
    "value": "Samarinda Ilir"
  },
  {
    "id": "647205",
    "regency_id": "6472",
    "label": "Samarinda Utara",
    "value": "Samarinda Utara"
  },
  {
    "id": "647206",
    "regency_id": "6472",
    "label": "Sungai Kunjang",
    "value": "Sungai Kunjang"
  },
  {
    "id": "647207",
    "regency_id": "6472",
    "label": "Sambutan",
    "value": "Sambutan"
  },
  {
    "id": "647208",
    "regency_id": "6472",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "647209",
    "regency_id": "6472",
    "label": "Samarinda Kota",
    "value": "Samarinda Kota"
  },
  {
    "id": "647210",
    "regency_id": "6472",
    "label": "Loa Janan Ilir",
    "value": "Loa Janan Ilir"
  },
  {
    "id": "647401",
    "regency_id": "6474",
    "label": "Bontang Utara",
    "value": "Bontang Utara"
  },
  {
    "id": "647402",
    "regency_id": "6474",
    "label": "Bontang Selatan",
    "value": "Bontang Selatan"
  },
  {
    "id": "647403",
    "regency_id": "6474",
    "label": "Bontang Barat",
    "value": "Bontang Barat"
  },
  {
    "id": "650101",
    "regency_id": "6501",
    "label": "Tanjung Palas",
    "value": "Tanjung Palas"
  },
  {
    "id": "650102",
    "regency_id": "6501",
    "label": "Tanjung Palas Barat",
    "value": "Tanjung Palas Barat"
  },
  {
    "id": "650103",
    "regency_id": "6501",
    "label": "Tanjung Palas Utara",
    "value": "Tanjung Palas Utara"
  },
  {
    "id": "650104",
    "regency_id": "6501",
    "label": "Tanjung Palas Timur",
    "value": "Tanjung Palas Timur"
  },
  {
    "id": "650105",
    "regency_id": "6501",
    "label": "Tanjung Selor",
    "value": "Tanjung Selor"
  },
  {
    "id": "650106",
    "regency_id": "6501",
    "label": "Tanjung Palas Tengah",
    "value": "Tanjung Palas Tengah"
  },
  { "id": "650107", "regency_id": "6501", "label": "Peso", "value": "Peso" },
  {
    "id": "650108",
    "regency_id": "6501",
    "label": "Peso Hilir",
    "value": "Peso Hilir"
  },
  {
    "id": "650109",
    "regency_id": "6501",
    "label": "Sekatak",
    "value": "Sekatak"
  },
  { "id": "650110", "regency_id": "6501", "label": "Bunyu", "value": "Bunyu" },
  {
    "id": "650201",
    "regency_id": "6502",
    "label": "Mentarang",
    "value": "Mentarang"
  },
  {
    "id": "650202",
    "regency_id": "6502",
    "label": "Malinau Kota",
    "value": "Malinau Kota"
  },
  {
    "id": "650203",
    "regency_id": "6502",
    "label": "Pujungan",
    "value": "Pujungan"
  },
  {
    "id": "650204",
    "regency_id": "6502",
    "label": "Kayan Hilir",
    "value": "Kayan Hilir"
  },
  {
    "id": "650205",
    "regency_id": "6502",
    "label": "Kayan Hulu",
    "value": "Kayan Hulu"
  },
  {
    "id": "650206",
    "regency_id": "6502",
    "label": "Malinau Selatan",
    "value": "Malinau Selatan"
  },
  {
    "id": "650207",
    "regency_id": "6502",
    "label": "Malinau Utara",
    "value": "Malinau Utara"
  },
  {
    "id": "650208",
    "regency_id": "6502",
    "label": "Malinau Barat",
    "value": "Malinau Barat"
  },
  {
    "id": "650209",
    "regency_id": "6502",
    "label": "Sungai Boh",
    "value": "Sungai Boh"
  },
  {
    "id": "650210",
    "regency_id": "6502",
    "label": "Kayan Selatan",
    "value": "Kayan Selatan"
  },
  {
    "id": "650211",
    "regency_id": "6502",
    "label": "Bahau Hulu",
    "value": "Bahau Hulu"
  },
  {
    "id": "650212",
    "regency_id": "6502",
    "label": "Mentarang Hulu",
    "value": "Mentarang Hulu"
  },
  {
    "id": "650213",
    "regency_id": "6502",
    "label": "Malinau Selatan Hilir",
    "value": "Malinau Selatan Hilir"
  },
  {
    "id": "650214",
    "regency_id": "6502",
    "label": "Malinau Selatan Hulu",
    "value": "Malinau Selatan Hulu"
  },
  {
    "id": "650215",
    "regency_id": "6502",
    "label": "Sungai Tubu",
    "value": "Sungai Tubu"
  },
  {
    "id": "650301",
    "regency_id": "6503",
    "label": "Sebatik",
    "value": "Sebatik"
  },
  {
    "id": "650302",
    "regency_id": "6503",
    "label": "Nunukan",
    "value": "Nunukan"
  },
  {
    "id": "650303",
    "regency_id": "6503",
    "label": "Sembakung",
    "value": "Sembakung"
  },
  {
    "id": "650304",
    "regency_id": "6503",
    "label": "Lumbis",
    "value": "Lumbis"
  },
  {
    "id": "650305",
    "regency_id": "6503",
    "label": "Krayan",
    "value": "Krayan"
  },
  {
    "id": "650306",
    "regency_id": "6503",
    "label": "Sebuku",
    "value": "Sebuku"
  },
  {
    "id": "650307",
    "regency_id": "6503",
    "label": "Krayan Selatan",
    "value": "Krayan Selatan"
  },
  {
    "id": "650308",
    "regency_id": "6503",
    "label": "Sebatik Barat",
    "value": "Sebatik Barat"
  },
  {
    "id": "650309",
    "regency_id": "6503",
    "label": "Nunukan Selatan",
    "value": "Nunukan Selatan"
  },
  {
    "id": "650310",
    "regency_id": "6503",
    "label": "Sebatik Timur",
    "value": "Sebatik Timur"
  },
  {
    "id": "650311",
    "regency_id": "6503",
    "label": "Sebatik Utara",
    "value": "Sebatik Utara"
  },
  {
    "id": "650312",
    "regency_id": "6503",
    "label": "Sebatik Tengah",
    "value": "Sebatik Tengah"
  },
  {
    "id": "650313",
    "regency_id": "6503",
    "label": "Sei Menggaris",
    "value": "Sei Menggaris"
  },
  {
    "id": "650314",
    "regency_id": "6503",
    "label": "Tulin Onsoi",
    "value": "Tulin Onsoi"
  },
  {
    "id": "650315",
    "regency_id": "6503",
    "label": "Lumbis Ogong",
    "value": "Lumbis Ogong"
  },
  {
    "id": "650316",
    "regency_id": "6503",
    "label": "Sembakung Atulai",
    "value": "Sembakung Atulai"
  },
  {
    "id": "650317",
    "regency_id": "6503",
    "label": "Krayan Tengah",
    "value": "Krayan Tengah"
  },
  {
    "id": "650318",
    "regency_id": "6503",
    "label": "Krayan Timur",
    "value": "Krayan Timur"
  },
  {
    "id": "650319",
    "regency_id": "6503",
    "label": "Krayan Barat",
    "value": "Krayan Barat"
  },
  {
    "id": "650401",
    "regency_id": "6504",
    "label": "Sesayap",
    "value": "Sesayap"
  },
  {
    "id": "650402",
    "regency_id": "6504",
    "label": "Sesayap Hilir",
    "value": "Sesayap Hilir"
  },
  {
    "id": "650403",
    "regency_id": "6504",
    "label": "Tana Lia",
    "value": "Tana Lia"
  },
  {
    "id": "650404",
    "regency_id": "6504",
    "label": "Betayau",
    "value": "Betayau"
  },
  {
    "id": "650405",
    "regency_id": "6504",
    "label": "Muruk Rian",
    "value": "Muruk Rian"
  },
  {
    "id": "657101",
    "regency_id": "6571",
    "label": "Tarakan Barat",
    "value": "Tarakan Barat"
  },
  {
    "id": "657102",
    "regency_id": "6571",
    "label": "Tarakan Tengah",
    "value": "Tarakan Tengah"
  },
  {
    "id": "657103",
    "regency_id": "6571",
    "label": "Tarakan Timur",
    "value": "Tarakan Timur"
  },
  {
    "id": "657104",
    "regency_id": "6571",
    "label": "Tarakan Utara",
    "value": "Tarakan Utara"
  },
  {
    "id": "710105",
    "regency_id": "7101",
    "label": "Sang Tombolang",
    "value": "Sang Tombolang"
  },
  {
    "id": "710109",
    "regency_id": "7101",
    "label": "Dumoga Barat",
    "value": "Dumoga Barat"
  },
  {
    "id": "710110",
    "regency_id": "7101",
    "label": "Dumoga Timur",
    "value": "Dumoga Timur"
  },
  {
    "id": "710111",
    "regency_id": "7101",
    "label": "Dumoga Utara",
    "value": "Dumoga Utara"
  },
  { "id": "710112", "regency_id": "7101", "label": "Lolak", "value": "Lolak" },
  {
    "id": "710113",
    "regency_id": "7101",
    "label": "Bolaang",
    "value": "Bolaang"
  },
  {
    "id": "710114",
    "regency_id": "7101",
    "label": "Lolayan",
    "value": "Lolayan"
  },
  {
    "id": "710119",
    "regency_id": "7101",
    "label": "Passi Barat",
    "value": "Passi Barat"
  },
  {
    "id": "710120",
    "regency_id": "7101",
    "label": "Poigar",
    "value": "Poigar"
  },
  {
    "id": "710122",
    "regency_id": "7101",
    "label": "Passi Timur",
    "value": "Passi Timur"
  },
  {
    "id": "710131",
    "regency_id": "7101",
    "label": "Bolaang Timur",
    "value": "Bolaang Timur"
  },
  {
    "id": "710132",
    "regency_id": "7101",
    "label": "Bilalang",
    "value": "Bilalang"
  },
  {
    "id": "710133",
    "regency_id": "7101",
    "label": "Dumoga",
    "value": "Dumoga"
  },
  {
    "id": "710134",
    "regency_id": "7101",
    "label": "Dumoga Tenggara",
    "value": "Dumoga Tenggara"
  },
  {
    "id": "710135",
    "regency_id": "7101",
    "label": "Dumoga Tengah",
    "value": "Dumoga Tengah"
  },
  {
    "id": "710201",
    "regency_id": "7102",
    "label": "Tondano Barat",
    "value": "Tondano Barat"
  },
  {
    "id": "710202",
    "regency_id": "7102",
    "label": "Tondano Timur",
    "value": "Tondano Timur"
  },
  { "id": "710203", "regency_id": "7102", "label": "Eris", "value": "Eris" },
  { "id": "710204", "regency_id": "7102", "label": "Kombi", "value": "Kombi" },
  {
    "id": "710205",
    "regency_id": "7102",
    "label": "Lembean Timur",
    "value": "Lembean Timur"
  },
  { "id": "710206", "regency_id": "7102", "label": "Kakas", "value": "Kakas" },
  {
    "id": "710207",
    "regency_id": "7102",
    "label": "Tompaso",
    "value": "Tompaso"
  },
  {
    "id": "710208",
    "regency_id": "7102",
    "label": "Remboken",
    "value": "Remboken"
  },
  {
    "id": "710209",
    "regency_id": "7102",
    "label": "Langowan Timur",
    "value": "Langowan Timur"
  },
  {
    "id": "710210",
    "regency_id": "7102",
    "label": "Langowan Barat",
    "value": "Langowan Barat"
  },
  {
    "id": "710211",
    "regency_id": "7102",
    "label": "Sonder",
    "value": "Sonder"
  },
  {
    "id": "710212",
    "regency_id": "7102",
    "label": "Kawangkoan",
    "value": "Kawangkoan"
  },
  {
    "id": "710213",
    "regency_id": "7102",
    "label": "Pineleng",
    "value": "Pineleng"
  },
  {
    "id": "710214",
    "regency_id": "7102",
    "label": "Tombulu",
    "value": "Tombulu"
  },
  {
    "id": "710215",
    "regency_id": "7102",
    "label": "Tombariri",
    "value": "Tombariri"
  },
  {
    "id": "710216",
    "regency_id": "7102",
    "label": "Tondano Utara",
    "value": "Tondano Utara"
  },
  {
    "id": "710217",
    "regency_id": "7102",
    "label": "Langowan Selatan",
    "value": "Langowan Selatan"
  },
  {
    "id": "710218",
    "regency_id": "7102",
    "label": "Tondano Selatan",
    "value": "Tondano Selatan"
  },
  {
    "id": "710219",
    "regency_id": "7102",
    "label": "Langowan Utara",
    "value": "Langowan Utara"
  },
  {
    "id": "710220",
    "regency_id": "7102",
    "label": "Kakas Barat",
    "value": "Kakas Barat"
  },
  {
    "id": "710221",
    "regency_id": "7102",
    "label": "Kawangkoan Utara",
    "value": "Kawangkoan Utara"
  },
  {
    "id": "710222",
    "regency_id": "7102",
    "label": "Kawangkoan Barat",
    "value": "Kawangkoan Barat"
  },
  {
    "id": "710223",
    "regency_id": "7102",
    "label": "Mandolang",
    "value": "Mandolang"
  },
  {
    "id": "710224",
    "regency_id": "7102",
    "label": "Tombariri Timur",
    "value": "Tombariri Timur"
  },
  {
    "id": "710225",
    "regency_id": "7102",
    "label": "Tompaso Barat",
    "value": "Tompaso Barat"
  },
  {
    "id": "710308",
    "regency_id": "7103",
    "label": "Tabukan Utara",
    "value": "Tabukan Utara"
  },
  {
    "id": "710309",
    "regency_id": "7103",
    "label": "Nusa Tabukan",
    "value": "Nusa Tabukan"
  },
  {
    "id": "710310",
    "regency_id": "7103",
    "label": "Manganitu Selatan",
    "value": "Manganitu Selatan"
  },
  {
    "id": "710311",
    "regency_id": "7103",
    "label": "Tatoareng",
    "value": "Tatoareng"
  },
  {
    "id": "710312",
    "regency_id": "7103",
    "label": "Tamako",
    "value": "Tamako"
  },
  {
    "id": "710313",
    "regency_id": "7103",
    "label": "Manganitu",
    "value": "Manganitu"
  },
  {
    "id": "710314",
    "regency_id": "7103",
    "label": "Tabukan Tengah",
    "value": "Tabukan Tengah"
  },
  {
    "id": "710315",
    "regency_id": "7103",
    "label": "Tabukan Selatan",
    "value": "Tabukan Selatan"
  },
  {
    "id": "710316",
    "regency_id": "7103",
    "label": "Kendahe",
    "value": "Kendahe"
  },
  {
    "id": "710317",
    "regency_id": "7103",
    "label": "Tahuna",
    "value": "Tahuna"
  },
  {
    "id": "710319",
    "regency_id": "7103",
    "label": "Tabukan Selatan Tengah",
    "value": "Tabukan Selatan Tengah"
  },
  {
    "id": "710320",
    "regency_id": "7103",
    "label": "Tabukan Selatan Tenggara",
    "value": "Tabukan Selatan Tenggara"
  },
  {
    "id": "710323",
    "regency_id": "7103",
    "label": "Tahuna Barat",
    "value": "Tahuna Barat"
  },
  {
    "id": "710324",
    "regency_id": "7103",
    "label": "Tahuna Timur",
    "value": "Tahuna Timur"
  },
  {
    "id": "710325",
    "regency_id": "7103",
    "label": "Kepulauan Marore",
    "value": "Kepulauan Marore"
  },
  {
    "id": "710401",
    "regency_id": "7104",
    "label": "Lirung",
    "value": "Lirung"
  },
  { "id": "710402", "regency_id": "7104", "label": "Beo", "value": "Beo" },
  {
    "id": "710403",
    "regency_id": "7104",
    "label": "Rainis",
    "value": "Rainis"
  },
  {
    "id": "710404",
    "regency_id": "7104",
    "label": "Essang",
    "value": "Essang"
  },
  {
    "id": "710405",
    "regency_id": "7104",
    "label": "Nanusa",
    "value": "Nanusa"
  },
  {
    "id": "710406",
    "regency_id": "7104",
    "label": "Kabaruan",
    "value": "Kabaruan"
  },
  {
    "id": "710407",
    "regency_id": "7104",
    "label": "Melonguane",
    "value": "Melonguane"
  },
  { "id": "710408", "regency_id": "7104", "label": "Gemeh", "value": "Gemeh" },
  { "id": "710409", "regency_id": "7104", "label": "Damau", "value": "Damau" },
  {
    "id": "710410",
    "regency_id": "7104",
    "label": "Tampan' Amma",
    "value": "Tampan' Amma"
  },
  {
    "id": "710411",
    "regency_id": "7104",
    "label": "Salibabu",
    "value": "Salibabu"
  },
  {
    "id": "710412",
    "regency_id": "7104",
    "label": "Kalongan",
    "value": "Kalongan"
  },
  {
    "id": "710413",
    "regency_id": "7104",
    "label": "Miangas",
    "value": "Miangas"
  },
  {
    "id": "710414",
    "regency_id": "7104",
    "label": "Beo Utara",
    "value": "Beo Utara"
  },
  {
    "id": "710415",
    "regency_id": "7104",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "710416",
    "regency_id": "7104",
    "label": "Melonguane Timur",
    "value": "Melonguane Timur"
  },
  {
    "id": "710417",
    "regency_id": "7104",
    "label": "Moronge",
    "value": "Moronge"
  },
  {
    "id": "710418",
    "regency_id": "7104",
    "label": "Beo Selatan",
    "value": "Beo Selatan"
  },
  {
    "id": "710419",
    "regency_id": "7104",
    "label": "Essang Selatan",
    "value": "Essang Selatan"
  },
  {
    "id": "710501",
    "regency_id": "7105",
    "label": "Modoinding",
    "value": "Modoinding"
  },
  {
    "id": "710502",
    "regency_id": "7105",
    "label": "Tompaso Baru",
    "value": "Tompaso Baru"
  },
  {
    "id": "710503",
    "regency_id": "7105",
    "label": "Ranoyapo",
    "value": "Ranoyapo"
  },
  {
    "id": "710507",
    "regency_id": "7105",
    "label": "Motoling",
    "value": "Motoling"
  },
  {
    "id": "710508",
    "regency_id": "7105",
    "label": "Sinonsayang",
    "value": "Sinonsayang"
  },
  { "id": "710509", "regency_id": "7105", "label": "Tenga", "value": "Tenga" },
  {
    "id": "710510",
    "regency_id": "7105",
    "label": "Amurang",
    "value": "Amurang"
  },
  {
    "id": "710512",
    "regency_id": "7105",
    "label": "Tumpaan",
    "value": "Tumpaan"
  },
  {
    "id": "710513",
    "regency_id": "7105",
    "label": "Tareran",
    "value": "Tareran"
  },
  {
    "id": "710515",
    "regency_id": "7105",
    "label": "Kumelembuai",
    "value": "Kumelembuai"
  },
  {
    "id": "710516",
    "regency_id": "7105",
    "label": "Maesaan",
    "value": "Maesaan"
  },
  {
    "id": "710517",
    "regency_id": "7105",
    "label": "Amurang Barat",
    "value": "Amurang Barat"
  },
  {
    "id": "710518",
    "regency_id": "7105",
    "label": "Amurang Timur",
    "value": "Amurang Timur"
  },
  {
    "id": "710519",
    "regency_id": "7105",
    "label": "Tatapaan",
    "value": "Tatapaan"
  },
  {
    "id": "710521",
    "regency_id": "7105",
    "label": "Motoling Barat",
    "value": "Motoling Barat"
  },
  {
    "id": "710522",
    "regency_id": "7105",
    "label": "Motoling Timur",
    "value": "Motoling Timur"
  },
  {
    "id": "710523",
    "regency_id": "7105",
    "label": "Suluun Tareran",
    "value": "Suluun Tareran"
  },
  { "id": "710601", "regency_id": "7106", "label": "Kema", "value": "Kema" },
  {
    "id": "710602",
    "regency_id": "7106",
    "label": "Kauditan",
    "value": "Kauditan"
  },
  {
    "id": "710603",
    "regency_id": "7106",
    "label": "Airmadidi",
    "value": "Airmadidi"
  },
  { "id": "710604", "regency_id": "7106", "label": "Wori", "value": "Wori" },
  {
    "id": "710605",
    "regency_id": "7106",
    "label": "Dimembe",
    "value": "Dimembe"
  },
  {
    "id": "710606",
    "regency_id": "7106",
    "label": "Likupang Barat",
    "value": "Likupang Barat"
  },
  {
    "id": "710607",
    "regency_id": "7106",
    "label": "Likupang Timur",
    "value": "Likupang Timur"
  },
  {
    "id": "710608",
    "regency_id": "7106",
    "label": "Kalawat",
    "value": "Kalawat"
  },
  {
    "id": "710609",
    "regency_id": "7106",
    "label": "Talawaan",
    "value": "Talawaan"
  },
  {
    "id": "710610",
    "regency_id": "7106",
    "label": "Likupang Selatan",
    "value": "Likupang Selatan"
  },
  {
    "id": "710701",
    "regency_id": "7107",
    "label": "Ratahan",
    "value": "Ratahan"
  },
  {
    "id": "710702",
    "regency_id": "7107",
    "label": "Pusomaen",
    "value": "Pusomaen"
  },
  {
    "id": "710703",
    "regency_id": "7107",
    "label": "Belang",
    "value": "Belang"
  },
  {
    "id": "710704",
    "regency_id": "7107",
    "label": "Ratatotok",
    "value": "Ratatotok"
  },
  {
    "id": "710705",
    "regency_id": "7107",
    "label": "Tombatu",
    "value": "Tombatu"
  },
  {
    "id": "710706",
    "regency_id": "7107",
    "label": "Touluaan",
    "value": "Touluaan"
  },
  {
    "id": "710707",
    "regency_id": "7107",
    "label": "Touluaan Selatan",
    "value": "Touluaan Selatan"
  },
  {
    "id": "710708",
    "regency_id": "7107",
    "label": "Silian Raya",
    "value": "Silian Raya"
  },
  {
    "id": "710709",
    "regency_id": "7107",
    "label": "Tombatu Timur",
    "value": "Tombatu Timur"
  },
  {
    "id": "710710",
    "regency_id": "7107",
    "label": "Tombatu Utara",
    "value": "Tombatu Utara"
  },
  { "id": "710711", "regency_id": "7107", "label": "Pasan", "value": "Pasan" },
  {
    "id": "710712",
    "regency_id": "7107",
    "label": "Ratahan Timur",
    "value": "Ratahan Timur"
  },
  {
    "id": "710801",
    "regency_id": "7108",
    "label": "Sangkub",
    "value": "Sangkub"
  },
  {
    "id": "710802",
    "regency_id": "7108",
    "label": "Bintauna",
    "value": "Bintauna"
  },
  {
    "id": "710803",
    "regency_id": "7108",
    "label": "Bolangitang Timur",
    "value": "Bolangitang Timur"
  },
  {
    "id": "710804",
    "regency_id": "7108",
    "label": "Bolangitang Barat",
    "value": "Bolangitang Barat"
  },
  {
    "id": "710805",
    "regency_id": "7108",
    "label": "Kaidipang",
    "value": "Kaidipang"
  },
  {
    "id": "710806",
    "regency_id": "7108",
    "label": "Pinogaluman",
    "value": "Pinogaluman"
  },
  {
    "id": "710901",
    "regency_id": "7109",
    "label": "Siau Timur",
    "value": "Siau Timur"
  },
  {
    "id": "710902",
    "regency_id": "7109",
    "label": "Siau Barat",
    "value": "Siau Barat"
  },
  {
    "id": "710903",
    "regency_id": "7109",
    "label": "Tagulandang",
    "value": "Tagulandang"
  },
  {
    "id": "710904",
    "regency_id": "7109",
    "label": "Siau Timur Selatan",
    "value": "Siau Timur Selatan"
  },
  {
    "id": "710905",
    "regency_id": "7109",
    "label": "Siau Barat Selatan",
    "value": "Siau Barat Selatan"
  },
  {
    "id": "710906",
    "regency_id": "7109",
    "label": "Tagulandang Utara",
    "value": "Tagulandang Utara"
  },
  { "id": "710907", "regency_id": "7109", "label": "Biaro", "value": "Biaro" },
  {
    "id": "710908",
    "regency_id": "7109",
    "label": "Siau Barat Utara",
    "value": "Siau Barat Utara"
  },
  {
    "id": "710909",
    "regency_id": "7109",
    "label": "Siau Tengah",
    "value": "Siau Tengah"
  },
  {
    "id": "710910",
    "regency_id": "7109",
    "label": "Tagulandang Selatan",
    "value": "Tagulandang Selatan"
  },
  {
    "id": "711001",
    "regency_id": "7110",
    "label": "Tutuyan",
    "value": "Tutuyan"
  },
  {
    "id": "711002",
    "regency_id": "7110",
    "label": "Kotabunan",
    "value": "Kotabunan"
  },
  {
    "id": "711003",
    "regency_id": "7110",
    "label": "Nuangan",
    "value": "Nuangan"
  },
  {
    "id": "711004",
    "regency_id": "7110",
    "label": "Modayag",
    "value": "Modayag"
  },
  {
    "id": "711005",
    "regency_id": "7110",
    "label": "Modayag Barat",
    "value": "Modayag Barat"
  },
  {
    "id": "711006",
    "regency_id": "7110",
    "label": "Motongkad",
    "value": "Motongkad"
  },
  { "id": "711007", "regency_id": "7110", "label": "Mooat", "value": "Mooat" },
  {
    "id": "711101",
    "regency_id": "7111",
    "label": "Bolaang Uki",
    "value": "Bolaang Uki"
  },
  {
    "id": "711102",
    "regency_id": "7111",
    "label": "Posigadan",
    "value": "Posigadan"
  },
  {
    "id": "711103",
    "regency_id": "7111",
    "label": "Pinolosian",
    "value": "Pinolosian"
  },
  {
    "id": "711104",
    "regency_id": "7111",
    "label": "Pinolosian Tengah",
    "value": "Pinolosian Tengah"
  },
  {
    "id": "711105",
    "regency_id": "7111",
    "label": "Pinolosian Timur",
    "value": "Pinolosian Timur"
  },
  {
    "id": "711106",
    "regency_id": "7111",
    "label": "Helumo",
    "value": "Helumo"
  },
  {
    "id": "711107",
    "regency_id": "7111",
    "label": "Tomini",
    "value": "Tomini"
  },
  {
    "id": "717101",
    "regency_id": "7171",
    "label": "Bunaken",
    "value": "Bunaken"
  },
  {
    "id": "717102",
    "regency_id": "7171",
    "label": "Tuminiting",
    "value": "Tuminiting"
  },
  {
    "id": "717103",
    "regency_id": "7171",
    "label": "Singkil",
    "value": "Singkil"
  },
  {
    "id": "717104",
    "regency_id": "7171",
    "label": "Wenang",
    "value": "Wenang"
  },
  {
    "id": "717105",
    "regency_id": "7171",
    "label": "Tikala",
    "value": "Tikala"
  },
  { "id": "717106", "regency_id": "7171", "label": "Sario", "value": "Sario" },
  { "id": "717107", "regency_id": "7171", "label": "Wanea", "value": "Wanea" },
  {
    "id": "717108",
    "regency_id": "7171",
    "label": "Mapanget",
    "value": "Mapanget"
  },
  {
    "id": "717109",
    "regency_id": "7171",
    "label": "Malalayang",
    "value": "Malalayang"
  },
  {
    "id": "717110",
    "regency_id": "7171",
    "label": "Bunaken Kepulauan",
    "value": "Bunaken Kepulauan"
  },
  {
    "id": "717111",
    "regency_id": "7171",
    "label": "Paal Dua",
    "value": "Paal Dua"
  },
  {
    "id": "717201",
    "regency_id": "7172",
    "label": "Lembeh Selatan",
    "value": "Lembeh Selatan"
  },
  {
    "id": "717202",
    "regency_id": "7172",
    "label": "Madidir",
    "value": "Madidir"
  },
  {
    "id": "717203",
    "regency_id": "7172",
    "label": "Ranowulu",
    "value": "Ranowulu"
  },
  {
    "id": "717204",
    "regency_id": "7172",
    "label": "Aertembaga",
    "value": "Aertembaga"
  },
  {
    "id": "717205",
    "regency_id": "7172",
    "label": "Matuari",
    "value": "Matuari"
  },
  {
    "id": "717206",
    "regency_id": "7172",
    "label": "Girian",
    "value": "Girian"
  },
  { "id": "717207", "regency_id": "7172", "label": "Maesa", "value": "Maesa" },
  {
    "id": "717208",
    "regency_id": "7172",
    "label": "Lembeh Utara",
    "value": "Lembeh Utara"
  },
  {
    "id": "717301",
    "regency_id": "7173",
    "label": "Tomohon Selatan",
    "value": "Tomohon Selatan"
  },
  {
    "id": "717302",
    "regency_id": "7173",
    "label": "Tomohon Tengah",
    "value": "Tomohon Tengah"
  },
  {
    "id": "717303",
    "regency_id": "7173",
    "label": "Tomohon Utara",
    "value": "Tomohon Utara"
  },
  {
    "id": "717304",
    "regency_id": "7173",
    "label": "Tomohon Barat",
    "value": "Tomohon Barat"
  },
  {
    "id": "717305",
    "regency_id": "7173",
    "label": "Tomohon Timur",
    "value": "Tomohon Timur"
  },
  {
    "id": "717401",
    "regency_id": "7174",
    "label": "Kotamobagu Utara",
    "value": "Kotamobagu Utara"
  },
  {
    "id": "717402",
    "regency_id": "7174",
    "label": "Kotamobagu Timur",
    "value": "Kotamobagu Timur"
  },
  {
    "id": "717403",
    "regency_id": "7174",
    "label": "Kotamobagu Selatan",
    "value": "Kotamobagu Selatan"
  },
  {
    "id": "717404",
    "regency_id": "7174",
    "label": "Kotamobagu Barat",
    "value": "Kotamobagu Barat"
  },
  { "id": "720101", "regency_id": "7201", "label": "Batui", "value": "Batui" },
  { "id": "720102", "regency_id": "7201", "label": "Bunta", "value": "Bunta" },
  {
    "id": "720103",
    "regency_id": "7201",
    "label": "Kintom",
    "value": "Kintom"
  },
  { "id": "720104", "regency_id": "7201", "label": "Luwuk", "value": "Luwuk" },
  {
    "id": "720105",
    "regency_id": "7201",
    "label": "Lamala",
    "value": "Lamala"
  },
  {
    "id": "720106",
    "regency_id": "7201",
    "label": "Balantak",
    "value": "Balantak"
  },
  {
    "id": "720107",
    "regency_id": "7201",
    "label": "Pagimana",
    "value": "Pagimana"
  },
  {
    "id": "720108",
    "regency_id": "7201",
    "label": "Bualemo",
    "value": "Bualemo"
  },
  { "id": "720109", "regency_id": "7201", "label": "Toili", "value": "Toili" },
  {
    "id": "720110",
    "regency_id": "7201",
    "label": "Masama",
    "value": "Masama"
  },
  {
    "id": "720111",
    "regency_id": "7201",
    "label": "Luwuk Timur",
    "value": "Luwuk Timur"
  },
  {
    "id": "720112",
    "regency_id": "7201",
    "label": "Toili Barat",
    "value": "Toili Barat"
  },
  { "id": "720113", "regency_id": "7201", "label": "Nuhon", "value": "Nuhon" },
  {
    "id": "720114",
    "regency_id": "7201",
    "label": "Moilong",
    "value": "Moilong"
  },
  {
    "id": "720115",
    "regency_id": "7201",
    "label": "Batui Selatan",
    "value": "Batui Selatan"
  },
  { "id": "720116", "regency_id": "7201", "label": "Lobu", "value": "Lobu" },
  {
    "id": "720117",
    "regency_id": "7201",
    "label": "Simpang Raya",
    "value": "Simpang Raya"
  },
  {
    "id": "720118",
    "regency_id": "7201",
    "label": "Balantak Selatan",
    "value": "Balantak Selatan"
  },
  {
    "id": "720119",
    "regency_id": "7201",
    "label": "Balantak Utara",
    "value": "Balantak Utara"
  },
  {
    "id": "720120",
    "regency_id": "7201",
    "label": "Luwuk Selatan",
    "value": "Luwuk Selatan"
  },
  {
    "id": "720121",
    "regency_id": "7201",
    "label": "Luwuk Utara",
    "value": "Luwuk Utara"
  },
  {
    "id": "720122",
    "regency_id": "7201",
    "label": "Mantoh",
    "value": "Mantoh"
  },
  { "id": "720123", "regency_id": "7201", "label": "Nambo", "value": "Nambo" },
  {
    "id": "720201",
    "regency_id": "7202",
    "label": "Poso Kota",
    "value": "Poso Kota"
  },
  {
    "id": "720202",
    "regency_id": "7202",
    "label": "Poso Pesisir",
    "value": "Poso Pesisir"
  },
  { "id": "720203", "regency_id": "7202", "label": "Lage", "value": "Lage" },
  {
    "id": "720204",
    "regency_id": "7202",
    "label": "Pamona Puselemba",
    "value": "Pamona Puselemba"
  },
  {
    "id": "720205",
    "regency_id": "7202",
    "label": "Pamona Timur",
    "value": "Pamona Timur"
  },
  {
    "id": "720206",
    "regency_id": "7202",
    "label": "Pamona Selatan",
    "value": "Pamona Selatan"
  },
  {
    "id": "720207",
    "regency_id": "7202",
    "label": "Lore Utara",
    "value": "Lore Utara"
  },
  {
    "id": "720208",
    "regency_id": "7202",
    "label": "Lore Tengah",
    "value": "Lore Tengah"
  },
  {
    "id": "720209",
    "regency_id": "7202",
    "label": "Lore Selatan",
    "value": "Lore Selatan"
  },
  {
    "id": "720218",
    "regency_id": "7202",
    "label": "Poso Pesisir Utara",
    "value": "Poso Pesisir Utara"
  },
  {
    "id": "720219",
    "regency_id": "7202",
    "label": "Poso Pesisir Selatan",
    "value": "Poso Pesisir Selatan"
  },
  {
    "id": "720220",
    "regency_id": "7202",
    "label": "Pamona Barat",
    "value": "Pamona Barat"
  },
  {
    "id": "720221",
    "regency_id": "7202",
    "label": "Poso Kota Selatan",
    "value": "Poso Kota Selatan"
  },
  {
    "id": "720222",
    "regency_id": "7202",
    "label": "Poso Kota Utara",
    "value": "Poso Kota Utara"
  },
  {
    "id": "720223",
    "regency_id": "7202",
    "label": "Lore Barat",
    "value": "Lore Barat"
  },
  {
    "id": "720224",
    "regency_id": "7202",
    "label": "Lore Timur",
    "value": "Lore Timur"
  },
  {
    "id": "720225",
    "regency_id": "7202",
    "label": "Lore Piore",
    "value": "Lore Piore"
  },
  {
    "id": "720226",
    "regency_id": "7202",
    "label": "Pamona Tenggara",
    "value": "Pamona Tenggara"
  },
  {
    "id": "720227",
    "regency_id": "7202",
    "label": "Pamona Utara",
    "value": "Pamona Utara"
  },
  {
    "id": "720304",
    "regency_id": "7203",
    "label": "Rio Pakava",
    "value": "Rio Pakava"
  },
  {
    "id": "720306",
    "regency_id": "7203",
    "label": "Dampelas",
    "value": "Dampelas"
  },
  {
    "id": "720308",
    "regency_id": "7203",
    "label": "Banawa",
    "value": "Banawa"
  },
  {
    "id": "720309",
    "regency_id": "7203",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "720310",
    "regency_id": "7203",
    "label": "Sindue",
    "value": "Sindue"
  },
  {
    "id": "720311",
    "regency_id": "7203",
    "label": "Sirenja",
    "value": "Sirenja"
  },
  {
    "id": "720312",
    "regency_id": "7203",
    "label": "Balaesang",
    "value": "Balaesang"
  },
  { "id": "720314", "regency_id": "7203", "label": "Sojol", "value": "Sojol" },
  {
    "id": "720318",
    "regency_id": "7203",
    "label": "Banawa Selatan",
    "value": "Banawa Selatan"
  },
  {
    "id": "720319",
    "regency_id": "7203",
    "label": "Tanantovea",
    "value": "Tanantovea"
  },
  {
    "id": "720321",
    "regency_id": "7203",
    "label": "Pinembani",
    "value": "Pinembani"
  },
  {
    "id": "720324",
    "regency_id": "7203",
    "label": "Sindue Tombusabora",
    "value": "Sindue Tombusabora"
  },
  {
    "id": "720325",
    "regency_id": "7203",
    "label": "Sindue Tobata",
    "value": "Sindue Tobata"
  },
  {
    "id": "720327",
    "regency_id": "7203",
    "label": "Banawa Tengah",
    "value": "Banawa Tengah"
  },
  {
    "id": "720330",
    "regency_id": "7203",
    "label": "Sojol Utara",
    "value": "Sojol Utara"
  },
  {
    "id": "720331",
    "regency_id": "7203",
    "label": "Balaesang Tanjung",
    "value": "Balaesang Tanjung"
  },
  {
    "id": "720401",
    "regency_id": "7204",
    "label": "Dampal Selatan",
    "value": "Dampal Selatan"
  },
  {
    "id": "720402",
    "regency_id": "7204",
    "label": "Dampal Utara",
    "value": "Dampal Utara"
  },
  { "id": "720403", "regency_id": "7204", "label": "Dondo", "value": "Dondo" },
  {
    "id": "720404",
    "regency_id": "7204",
    "label": "Basidondo",
    "value": "Basidondo"
  },
  {
    "id": "720405",
    "regency_id": "7204",
    "label": "Ogodeide",
    "value": "Ogodeide"
  },
  {
    "id": "720406",
    "regency_id": "7204",
    "label": "Lampasio",
    "value": "Lampasio"
  },
  {
    "id": "720407",
    "regency_id": "7204",
    "label": "Baolan",
    "value": "Baolan"
  },
  {
    "id": "720408",
    "regency_id": "7204",
    "label": "Galang",
    "value": "Galang"
  },
  {
    "id": "720409",
    "regency_id": "7204",
    "label": "Toli-Toli Utara",
    "value": "Toli-Toli Utara"
  },
  {
    "id": "720410",
    "regency_id": "7204",
    "label": "Dako Pemean",
    "value": "Dako Pemean"
  },
  {
    "id": "720501",
    "regency_id": "7205",
    "label": "Momunu",
    "value": "Momunu"
  },
  { "id": "720502", "regency_id": "7205", "label": "Lakea", "value": "Lakea" },
  { "id": "720503", "regency_id": "7205", "label": "Bokat", "value": "Bokat" },
  {
    "id": "720504",
    "regency_id": "7205",
    "label": "Bunobogu",
    "value": "Bunobogu"
  },
  {
    "id": "720505",
    "regency_id": "7205",
    "label": "Paleleh",
    "value": "Paleleh"
  },
  { "id": "720506", "regency_id": "7205", "label": "Biau", "value": "Biau" },
  {
    "id": "720507",
    "regency_id": "7205",
    "label": "Tiloan",
    "value": "Tiloan"
  },
  { "id": "720508", "regency_id": "7205", "label": "Bukal", "value": "Bukal" },
  {
    "id": "720509",
    "regency_id": "7205",
    "label": "Gadung",
    "value": "Gadung"
  },
  {
    "id": "720510",
    "regency_id": "7205",
    "label": "Karamat",
    "value": "Karamat"
  },
  {
    "id": "720511",
    "regency_id": "7205",
    "label": "Paleleh Barat",
    "value": "Paleleh Barat"
  },
  {
    "id": "720605",
    "regency_id": "7206",
    "label": "Bungku Tengah",
    "value": "Bungku Tengah"
  },
  {
    "id": "720606",
    "regency_id": "7206",
    "label": "Bungku Selatan",
    "value": "Bungku Selatan"
  },
  {
    "id": "720607",
    "regency_id": "7206",
    "label": "Menui Kepulauan",
    "value": "Menui Kepulauan"
  },
  {
    "id": "720608",
    "regency_id": "7206",
    "label": "Bungku Barat",
    "value": "Bungku Barat"
  },
  {
    "id": "720609",
    "regency_id": "7206",
    "label": "Bumi Raya",
    "value": "Bumi Raya"
  },
  {
    "id": "720610",
    "regency_id": "7206",
    "label": "Bahodopi",
    "value": "Bahodopi"
  },
  {
    "id": "720612",
    "regency_id": "7206",
    "label": "Wita Ponda",
    "value": "Wita Ponda"
  },
  {
    "id": "720615",
    "regency_id": "7206",
    "label": "Bungku Pesisir",
    "value": "Bungku Pesisir"
  },
  {
    "id": "720618",
    "regency_id": "7206",
    "label": "Bungku Timur",
    "value": "Bungku Timur"
  },
  {
    "id": "720703",
    "regency_id": "7207",
    "label": "Totikum",
    "value": "Totikum"
  },
  {
    "id": "720704",
    "regency_id": "7207",
    "label": "Tinangkung",
    "value": "Tinangkung"
  },
  { "id": "720705", "regency_id": "7207", "label": "Liang", "value": "Liang" },
  {
    "id": "720706",
    "regency_id": "7207",
    "label": "Bulagi",
    "value": "Bulagi"
  },
  { "id": "720707", "regency_id": "7207", "label": "Buko", "value": "Buko" },
  {
    "id": "720709",
    "regency_id": "7207",
    "label": "Bulagi Selatan",
    "value": "Bulagi Selatan"
  },
  {
    "id": "720711",
    "regency_id": "7207",
    "label": "Tinangkung Selatan",
    "value": "Tinangkung Selatan"
  },
  {
    "id": "720715",
    "regency_id": "7207",
    "label": "Totikum Selatan",
    "value": "Totikum Selatan"
  },
  {
    "id": "720716",
    "regency_id": "7207",
    "label": "Peling Tengah",
    "value": "Peling Tengah"
  },
  {
    "id": "720717",
    "regency_id": "7207",
    "label": "Bulagi Utara",
    "value": "Bulagi Utara"
  },
  {
    "id": "720718",
    "regency_id": "7207",
    "label": "Buko Selatan",
    "value": "Buko Selatan"
  },
  {
    "id": "720719",
    "regency_id": "7207",
    "label": "Tinangkung Utara",
    "value": "Tinangkung Utara"
  },
  {
    "id": "720801",
    "regency_id": "7208",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "720802",
    "regency_id": "7208",
    "label": "Ampibabo",
    "value": "Ampibabo"
  },
  {
    "id": "720803",
    "regency_id": "7208",
    "label": "Tinombo",
    "value": "Tinombo"
  },
  {
    "id": "720804",
    "regency_id": "7208",
    "label": "Moutong",
    "value": "Moutong"
  },
  {
    "id": "720805",
    "regency_id": "7208",
    "label": "Tomini",
    "value": "Tomini"
  },
  { "id": "720806", "regency_id": "7208", "label": "Sausu", "value": "Sausu" },
  {
    "id": "720807",
    "regency_id": "7208",
    "label": "Bolano Lambunu",
    "value": "Bolano Lambunu"
  },
  {
    "id": "720808",
    "regency_id": "7208",
    "label": "Kasimbar",
    "value": "Kasimbar"
  },
  { "id": "720809", "regency_id": "7208", "label": "Torue", "value": "Torue" },
  {
    "id": "720810",
    "regency_id": "7208",
    "label": "Tinombo Selatan",
    "value": "Tinombo Selatan"
  },
  {
    "id": "720811",
    "regency_id": "7208",
    "label": "Parigi Selatan",
    "value": "Parigi Selatan"
  },
  {
    "id": "720812",
    "regency_id": "7208",
    "label": "Mepanga",
    "value": "Mepanga"
  },
  {
    "id": "720813",
    "regency_id": "7208",
    "label": "Toribulu",
    "value": "Toribulu"
  },
  { "id": "720814", "regency_id": "7208", "label": "Taopa", "value": "Taopa" },
  {
    "id": "720815",
    "regency_id": "7208",
    "label": "Balinggi",
    "value": "Balinggi"
  },
  {
    "id": "720816",
    "regency_id": "7208",
    "label": "Parigi Barat",
    "value": "Parigi Barat"
  },
  { "id": "720817", "regency_id": "7208", "label": "Siniu", "value": "Siniu" },
  {
    "id": "720818",
    "regency_id": "7208",
    "label": "Palasa",
    "value": "Palasa"
  },
  {
    "id": "720819",
    "regency_id": "7208",
    "label": "Parigi Utara",
    "value": "Parigi Utara"
  },
  {
    "id": "720820",
    "regency_id": "7208",
    "label": "Parigi Tengah",
    "value": "Parigi Tengah"
  },
  {
    "id": "720821",
    "regency_id": "7208",
    "label": "Bolano",
    "value": "Bolano"
  },
  {
    "id": "720822",
    "regency_id": "7208",
    "label": "Ongka Malino",
    "value": "Ongka Malino"
  },
  {
    "id": "720823",
    "regency_id": "7208",
    "label": "Sidoan",
    "value": "Sidoan"
  },
  {
    "id": "720901",
    "regency_id": "7209",
    "label": "Una Una",
    "value": "Una Una"
  },
  {
    "id": "720902",
    "regency_id": "7209",
    "label": "Togean",
    "value": "Togean"
  },
  {
    "id": "720903",
    "regency_id": "7209",
    "label": "Walea Kepulauan",
    "value": "Walea Kepulauan"
  },
  {
    "id": "720904",
    "regency_id": "7209",
    "label": "Ampana Tete",
    "value": "Ampana Tete"
  },
  {
    "id": "720905",
    "regency_id": "7209",
    "label": "Ampana Kota",
    "value": "Ampana Kota"
  },
  {
    "id": "720906",
    "regency_id": "7209",
    "label": "Ulubongka",
    "value": "Ulubongka"
  },
  {
    "id": "720907",
    "regency_id": "7209",
    "label": "Tojo Barat",
    "value": "Tojo Barat"
  },
  { "id": "720908", "regency_id": "7209", "label": "Tojo", "value": "Tojo" },
  {
    "id": "720909",
    "regency_id": "7209",
    "label": "Walea Besar",
    "value": "Walea Besar"
  },
  {
    "id": "720910",
    "regency_id": "7209",
    "label": "Ratolindo",
    "value": "Ratolindo"
  },
  {
    "id": "720911",
    "regency_id": "7209",
    "label": "Batudaka",
    "value": "Batudaka"
  },
  {
    "id": "720912",
    "regency_id": "7209",
    "label": "Talatako",
    "value": "Talatako"
  },
  {
    "id": "721001",
    "regency_id": "7210",
    "label": "Sigi Biromaru",
    "value": "Sigi Biromaru"
  },
  {
    "id": "721002",
    "regency_id": "7210",
    "label": "Palolo",
    "value": "Palolo"
  },
  {
    "id": "721003",
    "regency_id": "7210",
    "label": "Nokilalaki",
    "value": "Nokilalaki"
  },
  { "id": "721004", "regency_id": "7210", "label": "Lindu", "value": "Lindu" },
  {
    "id": "721005",
    "regency_id": "7210",
    "label": "Kulawi",
    "value": "Kulawi"
  },
  {
    "id": "721006",
    "regency_id": "7210",
    "label": "Kulawi Selatan",
    "value": "Kulawi Selatan"
  },
  {
    "id": "721007",
    "regency_id": "7210",
    "label": "Pipikoro",
    "value": "Pipikoro"
  },
  {
    "id": "721008",
    "regency_id": "7210",
    "label": "Gumbasa",
    "value": "Gumbasa"
  },
  {
    "id": "721009",
    "regency_id": "7210",
    "label": "Dolo Selatan",
    "value": "Dolo Selatan"
  },
  {
    "id": "721010",
    "regency_id": "7210",
    "label": "Tanambulava",
    "value": "Tanambulava"
  },
  {
    "id": "721011",
    "regency_id": "7210",
    "label": "Dolo Barat",
    "value": "Dolo Barat"
  },
  { "id": "721012", "regency_id": "7210", "label": "Dolo", "value": "Dolo" },
  {
    "id": "721013",
    "regency_id": "7210",
    "label": "Kinovaro",
    "value": "Kinovaro"
  },
  {
    "id": "721014",
    "regency_id": "7210",
    "label": "Marawola",
    "value": "Marawola"
  },
  {
    "id": "721015",
    "regency_id": "7210",
    "label": "Marawola Barat",
    "value": "Marawola Barat"
  },
  {
    "id": "721101",
    "regency_id": "7211",
    "label": "Banggai",
    "value": "Banggai"
  },
  {
    "id": "721102",
    "regency_id": "7211",
    "label": "Banggai Utara",
    "value": "Banggai Utara"
  },
  {
    "id": "721103",
    "regency_id": "7211",
    "label": "Bokan Kepulauan",
    "value": "Bokan Kepulauan"
  },
  {
    "id": "721104",
    "regency_id": "7211",
    "label": "Bangkurung",
    "value": "Bangkurung"
  },
  {
    "id": "721105",
    "regency_id": "7211",
    "label": "Labobo",
    "value": "Labobo"
  },
  {
    "id": "721106",
    "regency_id": "7211",
    "label": "Banggai Selatan",
    "value": "Banggai Selatan"
  },
  {
    "id": "721107",
    "regency_id": "7211",
    "label": "Banggai Tengah",
    "value": "Banggai Tengah"
  },
  {
    "id": "721201",
    "regency_id": "7212",
    "label": "Petasia",
    "value": "Petasia"
  },
  {
    "id": "721202",
    "regency_id": "7212",
    "label": "Petasia Timur",
    "value": "Petasia Timur"
  },
  {
    "id": "721203",
    "regency_id": "7212",
    "label": "Lembo Raya",
    "value": "Lembo Raya"
  },
  { "id": "721204", "regency_id": "7212", "label": "Lembo", "value": "Lembo" },
  {
    "id": "721205",
    "regency_id": "7212",
    "label": "Mori Atas",
    "value": "Mori Atas"
  },
  {
    "id": "721206",
    "regency_id": "7212",
    "label": "Mori Utara",
    "value": "Mori Utara"
  },
  {
    "id": "721207",
    "regency_id": "7212",
    "label": "Soyo Jaya",
    "value": "Soyo Jaya"
  },
  {
    "id": "721208",
    "regency_id": "7212",
    "label": "Bungku Utara",
    "value": "Bungku Utara"
  },
  {
    "id": "721209",
    "regency_id": "7212",
    "label": "Mamosalato",
    "value": "Mamosalato"
  },
  {
    "id": "721210",
    "regency_id": "7212",
    "label": "Petasia Barat",
    "value": "Petasia Barat"
  },
  {
    "id": "727101",
    "regency_id": "7271",
    "label": "Palu Timur",
    "value": "Palu Timur"
  },
  {
    "id": "727102",
    "regency_id": "7271",
    "label": "Palu Barat",
    "value": "Palu Barat"
  },
  {
    "id": "727103",
    "regency_id": "7271",
    "label": "Palu Selatan",
    "value": "Palu Selatan"
  },
  {
    "id": "727104",
    "regency_id": "7271",
    "label": "Palu Utara",
    "value": "Palu Utara"
  },
  {
    "id": "727105",
    "regency_id": "7271",
    "label": "Ulujadi",
    "value": "Ulujadi"
  },
  {
    "id": "727106",
    "regency_id": "7271",
    "label": "Tatanga",
    "value": "Tatanga"
  },
  {
    "id": "727107",
    "regency_id": "7271",
    "label": "Tawaeli",
    "value": "Tawaeli"
  },
  {
    "id": "727108",
    "regency_id": "7271",
    "label": "Mantikulore",
    "value": "Mantikulore"
  },
  {
    "id": "730101",
    "regency_id": "7301",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "730102",
    "regency_id": "7301",
    "label": "Bontoharu",
    "value": "Bontoharu"
  },
  {
    "id": "730103",
    "regency_id": "7301",
    "label": "Bontomatene",
    "value": "Bontomatene"
  },
  {
    "id": "730104",
    "regency_id": "7301",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "730105",
    "regency_id": "7301",
    "label": "Bontosikuyu",
    "value": "Bontosikuyu"
  },
  {
    "id": "730106",
    "regency_id": "7301",
    "label": "Pasimasunggu",
    "value": "Pasimasunggu"
  },
  {
    "id": "730107",
    "regency_id": "7301",
    "label": "Pasimarannu",
    "value": "Pasimarannu"
  },
  {
    "id": "730108",
    "regency_id": "7301",
    "label": "Taka Bonerate",
    "value": "Taka Bonerate"
  },
  {
    "id": "730109",
    "regency_id": "7301",
    "label": "Pasilambena",
    "value": "Pasilambena"
  },
  {
    "id": "730110",
    "regency_id": "7301",
    "label": "Pasimasunggu Timur",
    "value": "Pasimasunggu Timur"
  },
  { "id": "730111", "regency_id": "7301", "label": "Buki", "value": "Buki" },
  {
    "id": "730201",
    "regency_id": "7302",
    "label": "Gantorang",
    "value": "Gantorang"
  },
  {
    "id": "730202",
    "regency_id": "7302",
    "label": "Ujung Bulu",
    "value": "Ujung Bulu"
  },
  {
    "id": "730203",
    "regency_id": "7302",
    "label": "Bonto Bahari",
    "value": "Bonto Bahari"
  },
  {
    "id": "730204",
    "regency_id": "7302",
    "label": "Bonto Tiro",
    "value": "Bonto Tiro"
  },
  {
    "id": "730205",
    "regency_id": "7302",
    "label": "Herlang",
    "value": "Herlang"
  },
  {
    "id": "730206",
    "regency_id": "7302",
    "label": "Kajang",
    "value": "Kajang"
  },
  {
    "id": "730207",
    "regency_id": "7302",
    "label": "Bulukumpa",
    "value": "Bulukumpa"
  },
  {
    "id": "730208",
    "regency_id": "7302",
    "label": "Kindang",
    "value": "Kindang"
  },
  {
    "id": "730209",
    "regency_id": "7302",
    "label": "Ujungloe",
    "value": "Ujungloe"
  },
  {
    "id": "730210",
    "regency_id": "7302",
    "label": "Rilauale",
    "value": "Rilauale"
  },
  {
    "id": "730301",
    "regency_id": "7303",
    "label": "Bissappu",
    "value": "Bissappu"
  },
  {
    "id": "730302",
    "regency_id": "7303",
    "label": "Bantaeng",
    "value": "Bantaeng"
  },
  {
    "id": "730303",
    "regency_id": "7303",
    "label": "Eremerasa",
    "value": "Eremerasa"
  },
  {
    "id": "730304",
    "regency_id": "7303",
    "label": "Tompo Bulu",
    "value": "Tompo Bulu"
  },
  {
    "id": "730305",
    "regency_id": "7303",
    "label": "Pajukukang",
    "value": "Pajukukang"
  },
  {
    "id": "730306",
    "regency_id": "7303",
    "label": "Uluere",
    "value": "Uluere"
  },
  {
    "id": "730307",
    "regency_id": "7303",
    "label": "Gantarang Keke",
    "value": "Gantarang Keke"
  },
  { "id": "730308", "regency_id": "7303", "label": "Sinoa", "value": "Sinoa" },
  {
    "id": "730401",
    "regency_id": "7304",
    "label": "Bangkala",
    "value": "Bangkala"
  },
  {
    "id": "730402",
    "regency_id": "7304",
    "label": "Tamalatea",
    "value": "Tamalatea"
  },
  {
    "id": "730403",
    "regency_id": "7304",
    "label": "Binamu",
    "value": "Binamu"
  },
  {
    "id": "730404",
    "regency_id": "7304",
    "label": "Batang",
    "value": "Batang"
  },
  {
    "id": "730405",
    "regency_id": "7304",
    "label": "Kelara",
    "value": "Kelara"
  },
  {
    "id": "730406",
    "regency_id": "7304",
    "label": "Bangkala Barat",
    "value": "Bangkala Barat"
  },
  {
    "id": "730407",
    "regency_id": "7304",
    "label": "Bontoramba",
    "value": "Bontoramba"
  },
  {
    "id": "730408",
    "regency_id": "7304",
    "label": "Turatea",
    "value": "Turatea"
  },
  {
    "id": "730409",
    "regency_id": "7304",
    "label": "Arungkeke",
    "value": "Arungkeke"
  },
  {
    "id": "730410",
    "regency_id": "7304",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "730411",
    "regency_id": "7304",
    "label": "Tarowang",
    "value": "Tarowang"
  },
  {
    "id": "730501",
    "regency_id": "7305",
    "label": "Mappakasunggu",
    "value": "Mappakasunggu"
  },
  {
    "id": "730502",
    "regency_id": "7305",
    "label": "Mangarabombang",
    "value": "Mangarabombang"
  },
  {
    "id": "730503",
    "regency_id": "7305",
    "label": "Polombangkeng Selatan",
    "value": "Polombangkeng Selatan"
  },
  {
    "id": "730504",
    "regency_id": "7305",
    "label": "Polombangkeng Utara",
    "value": "Polombangkeng Utara"
  },
  {
    "id": "730505",
    "regency_id": "7305",
    "label": "Galesong Selatan",
    "value": "Galesong Selatan"
  },
  {
    "id": "730506",
    "regency_id": "7305",
    "label": "Galesong Utara",
    "value": "Galesong Utara"
  },
  {
    "id": "730507",
    "regency_id": "7305",
    "label": "Pattallassang",
    "value": "Pattallassang"
  },
  {
    "id": "730508",
    "regency_id": "7305",
    "label": "Sanrobone",
    "value": "Sanrobone"
  },
  {
    "id": "730509",
    "regency_id": "7305",
    "label": "Galesong",
    "value": "Galesong"
  },
  {
    "id": "730510",
    "regency_id": "7305",
    "label": "Kepulauan Tanakeke",
    "value": "Kepulauan Tanakeke"
  },
  {
    "id": "730601",
    "regency_id": "7306",
    "label": "Bontonompo",
    "value": "Bontonompo"
  },
  {
    "id": "730602",
    "regency_id": "7306",
    "label": "Bajeng",
    "value": "Bajeng"
  },
  {
    "id": "730603",
    "regency_id": "7306",
    "label": "Tompobullu",
    "value": "Tompobullu"
  },
  {
    "id": "730604",
    "regency_id": "7306",
    "label": "Tinggimoncong",
    "value": "Tinggimoncong"
  },
  {
    "id": "730605",
    "regency_id": "7306",
    "label": "Parangloe",
    "value": "Parangloe"
  },
  {
    "id": "730606",
    "regency_id": "7306",
    "label": "Bontomarannu",
    "value": "Bontomarannu"
  },
  {
    "id": "730607",
    "regency_id": "7306",
    "label": "Palangga",
    "value": "Palangga"
  },
  {
    "id": "730608",
    "regency_id": "7306",
    "label": "Somba Upu",
    "value": "Somba Upu"
  },
  {
    "id": "730609",
    "regency_id": "7306",
    "label": "Bungaya",
    "value": "Bungaya"
  },
  {
    "id": "730610",
    "regency_id": "7306",
    "label": "Tombolopao",
    "value": "Tombolopao"
  },
  {
    "id": "730611",
    "regency_id": "7306",
    "label": "Biringbulu",
    "value": "Biringbulu"
  },
  {
    "id": "730612",
    "regency_id": "7306",
    "label": "Barombong",
    "value": "Barombong"
  },
  {
    "id": "730613",
    "regency_id": "7306",
    "label": "Pattalasang",
    "value": "Pattalasang"
  },
  {
    "id": "730614",
    "regency_id": "7306",
    "label": "Manuju",
    "value": "Manuju"
  },
  {
    "id": "730615",
    "regency_id": "7306",
    "label": "Bontolempangang",
    "value": "Bontolempangang"
  },
  {
    "id": "730616",
    "regency_id": "7306",
    "label": "Bontonompo Selatan",
    "value": "Bontonompo Selatan"
  },
  {
    "id": "730617",
    "regency_id": "7306",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "730618",
    "regency_id": "7306",
    "label": "Bajeng Barat",
    "value": "Bajeng Barat"
  },
  {
    "id": "730701",
    "regency_id": "7307",
    "label": "Sinjai Barat",
    "value": "Sinjai Barat"
  },
  {
    "id": "730702",
    "regency_id": "7307",
    "label": "Sinjai Selatan",
    "value": "Sinjai Selatan"
  },
  {
    "id": "730703",
    "regency_id": "7307",
    "label": "Sinjai Timur",
    "value": "Sinjai Timur"
  },
  {
    "id": "730704",
    "regency_id": "7307",
    "label": "Sinjai Tengah",
    "value": "Sinjai Tengah"
  },
  {
    "id": "730705",
    "regency_id": "7307",
    "label": "Sinjai Utara",
    "value": "Sinjai Utara"
  },
  {
    "id": "730706",
    "regency_id": "7307",
    "label": "Bulupoddo",
    "value": "Bulupoddo"
  },
  {
    "id": "730707",
    "regency_id": "7307",
    "label": "Sinjai Borong",
    "value": "Sinjai Borong"
  },
  {
    "id": "730708",
    "regency_id": "7307",
    "label": "Tellu Limpoe",
    "value": "Tellu Limpoe"
  },
  {
    "id": "730709",
    "regency_id": "7307",
    "label": "Pulau Sembilan",
    "value": "Pulau Sembilan"
  },
  {
    "id": "730801",
    "regency_id": "7308",
    "label": "Bontocani",
    "value": "Bontocani"
  },
  { "id": "730802", "regency_id": "7308", "label": "Kahu", "value": "Kahu" },
  {
    "id": "730803",
    "regency_id": "7308",
    "label": "Kajuara",
    "value": "Kajuara"
  },
  {
    "id": "730804",
    "regency_id": "7308",
    "label": "Salomekko",
    "value": "Salomekko"
  },
  { "id": "730805", "regency_id": "7308", "label": "Tonra", "value": "Tonra" },
  {
    "id": "730806",
    "regency_id": "7308",
    "label": "Libureng",
    "value": "Libureng"
  },
  { "id": "730807", "regency_id": "7308", "label": "Mare", "value": "Mare" },
  {
    "id": "730808",
    "regency_id": "7308",
    "label": "Sibulue",
    "value": "Sibulue"
  },
  {
    "id": "730809",
    "regency_id": "7308",
    "label": "Barebbo",
    "value": "Barebbo"
  },
  { "id": "730810", "regency_id": "7308", "label": "Cina", "value": "Cina" },
  { "id": "730811", "regency_id": "7308", "label": "Ponre", "value": "Ponre" },
  {
    "id": "730812",
    "regency_id": "7308",
    "label": "Lappariaja",
    "value": "Lappariaja"
  },
  {
    "id": "730813",
    "regency_id": "7308",
    "label": "Lamuru",
    "value": "Lamuru"
  },
  {
    "id": "730814",
    "regency_id": "7308",
    "label": "Ulaweng",
    "value": "Ulaweng"
  },
  {
    "id": "730815",
    "regency_id": "7308",
    "label": "Palakka",
    "value": "Palakka"
  },
  {
    "id": "730816",
    "regency_id": "7308",
    "label": "Awangpone",
    "value": "Awangpone"
  },
  {
    "id": "730817",
    "regency_id": "7308",
    "label": "Tellu Siattinge",
    "value": "Tellu Siattinge"
  },
  {
    "id": "730818",
    "regency_id": "7308",
    "label": "Ajangale",
    "value": "Ajangale"
  },
  {
    "id": "730819",
    "regency_id": "7308",
    "label": "Dua Boccoe",
    "value": "Dua Boccoe"
  },
  {
    "id": "730820",
    "regency_id": "7308",
    "label": "Cenrana",
    "value": "Cenrana"
  },
  {
    "id": "730821",
    "regency_id": "7308",
    "label": "Tanete Riattang",
    "value": "Tanete Riattang"
  },
  {
    "id": "730822",
    "regency_id": "7308",
    "label": "Tanete Riattang Barat",
    "value": "Tanete Riattang Barat"
  },
  {
    "id": "730823",
    "regency_id": "7308",
    "label": "Tanete Riattang Timur",
    "value": "Tanete Riattang Timur"
  },
  { "id": "730824", "regency_id": "7308", "label": "Amali", "value": "Amali" },
  {
    "id": "730825",
    "regency_id": "7308",
    "label": "Tellulimpoe",
    "value": "Tellulimpoe"
  },
  { "id": "730826", "regency_id": "7308", "label": "Bengo", "value": "Bengo" },
  {
    "id": "730827",
    "regency_id": "7308",
    "label": "Patimpeng",
    "value": "Patimpeng"
  },
  {
    "id": "730901",
    "regency_id": "7309",
    "label": "Mandai",
    "value": "Mandai"
  },
  { "id": "730902", "regency_id": "7309", "label": "Camba", "value": "Camba" },
  {
    "id": "730903",
    "regency_id": "7309",
    "label": "Bantimurung",
    "value": "Bantimurung"
  },
  {
    "id": "730904",
    "regency_id": "7309",
    "label": "Maros Baru",
    "value": "Maros Baru"
  },
  {
    "id": "730905",
    "regency_id": "7309",
    "label": "Bontoa",
    "value": "Bontoa"
  },
  {
    "id": "730906",
    "regency_id": "7309",
    "label": "Malllawa",
    "value": "Malllawa"
  },
  {
    "id": "730907",
    "regency_id": "7309",
    "label": "Tanralili",
    "value": "Tanralili"
  },
  {
    "id": "730908",
    "regency_id": "7309",
    "label": "Marusu",
    "value": "Marusu"
  },
  {
    "id": "730909",
    "regency_id": "7309",
    "label": "Simbang",
    "value": "Simbang"
  },
  {
    "id": "730910",
    "regency_id": "7309",
    "label": "Cenrana",
    "value": "Cenrana"
  },
  {
    "id": "730911",
    "regency_id": "7309",
    "label": "Tompobulu",
    "value": "Tompobulu"
  },
  { "id": "730912", "regency_id": "7309", "label": "Lau", "value": "Lau" },
  {
    "id": "730913",
    "regency_id": "7309",
    "label": "Moncong Loe",
    "value": "Moncong Loe"
  },
  {
    "id": "730914",
    "regency_id": "7309",
    "label": "Turikale",
    "value": "Turikale"
  },
  {
    "id": "731001",
    "regency_id": "7310",
    "label": "Liukang Tangaya",
    "value": "Liukang Tangaya"
  },
  {
    "id": "731002",
    "regency_id": "7310",
    "label": "Liukang Kalmas",
    "value": "Liukang Kalmas"
  },
  {
    "id": "731003",
    "regency_id": "7310",
    "label": "Liukang Tupabbiring",
    "value": "Liukang Tupabbiring"
  },
  {
    "id": "731004",
    "regency_id": "7310",
    "label": "Pangkajene",
    "value": "Pangkajene"
  },
  {
    "id": "731005",
    "regency_id": "7310",
    "label": "Balocci",
    "value": "Balocci"
  },
  {
    "id": "731006",
    "regency_id": "7310",
    "label": "Bungoro",
    "value": "Bungoro"
  },
  {
    "id": "731007",
    "regency_id": "7310",
    "label": "Labakkang",
    "value": "Labakkang"
  },
  {
    "id": "731008",
    "regency_id": "7310",
    "label": "Marang",
    "value": "Marang"
  },
  {
    "id": "731009",
    "regency_id": "7310",
    "label": "Segeri",
    "value": "Segeri"
  },
  {
    "id": "731010",
    "regency_id": "7310",
    "label": "Minasa Tene",
    "value": "Minasa Tene"
  },
  {
    "id": "731011",
    "regency_id": "7310",
    "label": "Mandalle",
    "value": "Mandalle"
  },
  {
    "id": "731012",
    "regency_id": "7310",
    "label": "Tondong Tallasa",
    "value": "Tondong Tallasa"
  },
  {
    "id": "731013",
    "regency_id": "7310",
    "label": "Liukang Tupabbiring Utara",
    "value": "Liukang Tupabbiring Utara"
  },
  {
    "id": "731101",
    "regency_id": "7311",
    "label": "Tanete Riaja",
    "value": "Tanete Riaja"
  },
  {
    "id": "731102",
    "regency_id": "7311",
    "label": "Tanete Rilau",
    "value": "Tanete Rilau"
  },
  { "id": "731103", "regency_id": "7311", "label": "Barru", "value": "Barru" },
  {
    "id": "731104",
    "regency_id": "7311",
    "label": "Soppeng Riaja",
    "value": "Soppeng Riaja"
  },
  {
    "id": "731105",
    "regency_id": "7311",
    "label": "Mallusetasi",
    "value": "Mallusetasi"
  },
  {
    "id": "731106",
    "regency_id": "7311",
    "label": "Pujananting",
    "value": "Pujananting"
  },
  {
    "id": "731107",
    "regency_id": "7311",
    "label": "Balusu",
    "value": "Balusu"
  },
  {
    "id": "731201",
    "regency_id": "7312",
    "label": "Marioriwawo",
    "value": "Marioriwawo"
  },
  {
    "id": "731202",
    "regency_id": "7312",
    "label": "Liliraja",
    "value": "Liliraja"
  },
  {
    "id": "731203",
    "regency_id": "7312",
    "label": "Lilirilau",
    "value": "Lilirilau"
  },
  {
    "id": "731204",
    "regency_id": "7312",
    "label": "Lalabata",
    "value": "Lalabata"
  },
  {
    "id": "731205",
    "regency_id": "7312",
    "label": "Marioriawa",
    "value": "Marioriawa"
  },
  {
    "id": "731206",
    "regency_id": "7312",
    "label": "Donri Donri",
    "value": "Donri Donri"
  },
  { "id": "731207", "regency_id": "7312", "label": "Ganra", "value": "Ganra" },
  { "id": "731208", "regency_id": "7312", "label": "Citta", "value": "Citta" },
  {
    "id": "731301",
    "regency_id": "7313",
    "label": "Sabangparu",
    "value": "Sabangparu"
  },
  {
    "id": "731302",
    "regency_id": "7313",
    "label": "Pammana",
    "value": "Pammana"
  },
  {
    "id": "731303",
    "regency_id": "7313",
    "label": "Takkalalla",
    "value": "Takkalalla"
  },
  {
    "id": "731304",
    "regency_id": "7313",
    "label": "Sajoanging",
    "value": "Sajoanging"
  },
  {
    "id": "731305",
    "regency_id": "7313",
    "label": "Majauleng",
    "value": "Majauleng"
  },
  { "id": "731306", "regency_id": "7313", "label": "Tempe", "value": "Tempe" },
  {
    "id": "731307",
    "regency_id": "7313",
    "label": "Belawa",
    "value": "Belawa"
  },
  {
    "id": "731308",
    "regency_id": "7313",
    "label": "Tanasitolo",
    "value": "Tanasitolo"
  },
  {
    "id": "731309",
    "regency_id": "7313",
    "label": "Maniangpajo",
    "value": "Maniangpajo"
  },
  {
    "id": "731310",
    "regency_id": "7313",
    "label": "Pitumpanua",
    "value": "Pitumpanua"
  },
  { "id": "731311", "regency_id": "7313", "label": "Bola", "value": "Bola" },
  {
    "id": "731312",
    "regency_id": "7313",
    "label": "Penrang",
    "value": "Penrang"
  },
  {
    "id": "731313",
    "regency_id": "7313",
    "label": "Gilireng",
    "value": "Gilireng"
  },
  { "id": "731314", "regency_id": "7313", "label": "Keera", "value": "Keera" },
  {
    "id": "731401",
    "regency_id": "7314",
    "label": "Panca Lautan",
    "value": "Panca Lautan"
  },
  {
    "id": "731402",
    "regency_id": "7314",
    "label": "Tellu Limpoe",
    "value": "Tellu Limpoe"
  },
  {
    "id": "731403",
    "regency_id": "7314",
    "label": "Watang Pulu",
    "value": "Watang Pulu"
  },
  {
    "id": "731404",
    "regency_id": "7314",
    "label": "Baranti",
    "value": "Baranti"
  },
  {
    "id": "731405",
    "regency_id": "7314",
    "label": "Panca Rijang",
    "value": "Panca Rijang"
  },
  { "id": "731406", "regency_id": "7314", "label": "Kulo", "value": "Kulo" },
  {
    "id": "731407",
    "regency_id": "7314",
    "label": "Maritengngae",
    "value": "Maritengngae"
  },
  {
    "id": "731408",
    "regency_id": "7314",
    "label": "WT. Sidenreng",
    "value": "WT. Sidenreng"
  },
  {
    "id": "731409",
    "regency_id": "7314",
    "label": "Dua Pitue",
    "value": "Dua Pitue"
  },
  {
    "id": "731410",
    "regency_id": "7314",
    "label": "Pitu Riawa",
    "value": "Pitu Riawa"
  },
  {
    "id": "731411",
    "regency_id": "7314",
    "label": "Pitu Raise",
    "value": "Pitu Raise"
  },
  {
    "id": "731501",
    "regency_id": "7315",
    "label": "Matirro Sompe",
    "value": "Matirro Sompe"
  },
  { "id": "731502", "regency_id": "7315", "label": "Suppa", "value": "Suppa" },
  {
    "id": "731503",
    "regency_id": "7315",
    "label": "Mattiro Bulu",
    "value": "Mattiro Bulu"
  },
  {
    "id": "731504",
    "regency_id": "7315",
    "label": "Watang Sawito",
    "value": "Watang Sawito"
  },
  {
    "id": "731505",
    "regency_id": "7315",
    "label": "Patampanua",
    "value": "Patampanua"
  },
  {
    "id": "731506",
    "regency_id": "7315",
    "label": "Duampanua",
    "value": "Duampanua"
  },
  {
    "id": "731507",
    "regency_id": "7315",
    "label": "Lembang",
    "value": "Lembang"
  },
  { "id": "731508", "regency_id": "7315", "label": "Cempa", "value": "Cempa" },
  {
    "id": "731509",
    "regency_id": "7315",
    "label": "Tiroang",
    "value": "Tiroang"
  },
  {
    "id": "731510",
    "regency_id": "7315",
    "label": "Lansirang",
    "value": "Lansirang"
  },
  {
    "id": "731511",
    "regency_id": "7315",
    "label": "Paleteang",
    "value": "Paleteang"
  },
  {
    "id": "731512",
    "regency_id": "7315",
    "label": "Batu Lappa",
    "value": "Batu Lappa"
  },
  { "id": "731601", "regency_id": "7316", "label": "Maiwa", "value": "Maiwa" },
  {
    "id": "731602",
    "regency_id": "7316",
    "label": "Enrekang",
    "value": "Enrekang"
  },
  {
    "id": "731603",
    "regency_id": "7316",
    "label": "Baraka",
    "value": "Baraka"
  },
  {
    "id": "731604",
    "regency_id": "7316",
    "label": "Anggeraja",
    "value": "Anggeraja"
  },
  { "id": "731605", "regency_id": "7316", "label": "Alla", "value": "Alla" },
  {
    "id": "731606",
    "regency_id": "7316",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "731607",
    "regency_id": "7316",
    "label": "Cendana",
    "value": "Cendana"
  },
  { "id": "731608", "regency_id": "7316", "label": "Curio", "value": "Curio" },
  { "id": "731609", "regency_id": "7316", "label": "Malua", "value": "Malua" },
  {
    "id": "731610",
    "regency_id": "7316",
    "label": "Buntu Batu",
    "value": "Buntu Batu"
  },
  {
    "id": "731611",
    "regency_id": "7316",
    "label": "Masalle",
    "value": "Masalle"
  },
  {
    "id": "731612",
    "regency_id": "7316",
    "label": "Baroko",
    "value": "Baroko"
  },
  {
    "id": "731701",
    "regency_id": "7317",
    "label": "Basse Sangtempe",
    "value": "Basse Sangtempe"
  },
  {
    "id": "731702",
    "regency_id": "7317",
    "label": "Larompong",
    "value": "Larompong"
  },
  { "id": "731703", "regency_id": "7317", "label": "Suli", "value": "Suli" },
  { "id": "731704", "regency_id": "7317", "label": "Bajo", "value": "Bajo" },
  {
    "id": "731705",
    "regency_id": "7317",
    "label": "Bua Ponrang",
    "value": "Bua Ponrang"
  },
  {
    "id": "731706",
    "regency_id": "7317",
    "label": "Walenrang",
    "value": "Walenrang"
  },
  {
    "id": "731707",
    "regency_id": "7317",
    "label": "Belopa",
    "value": "Belopa"
  },
  { "id": "731708", "regency_id": "7317", "label": "Bua", "value": "Bua" },
  {
    "id": "731709",
    "regency_id": "7317",
    "label": "Lamasi",
    "value": "Lamasi"
  },
  {
    "id": "731710",
    "regency_id": "7317",
    "label": "Larompong Selatan",
    "value": "Larompong Selatan"
  },
  {
    "id": "731711",
    "regency_id": "7317",
    "label": "Ponrang",
    "value": "Ponrang"
  },
  {
    "id": "731712",
    "regency_id": "7317",
    "label": "Latimojong",
    "value": "Latimojong"
  },
  {
    "id": "731713",
    "regency_id": "7317",
    "label": "Kamanre",
    "value": "Kamanre"
  },
  {
    "id": "731714",
    "regency_id": "7317",
    "label": "Belopa Utara",
    "value": "Belopa Utara"
  },
  {
    "id": "731715",
    "regency_id": "7317",
    "label": "Walenrang Barat",
    "value": "Walenrang Barat"
  },
  {
    "id": "731716",
    "regency_id": "7317",
    "label": "Walenrang Utara",
    "value": "Walenrang Utara"
  },
  {
    "id": "731717",
    "regency_id": "7317",
    "label": "Walenrang Timur",
    "value": "Walenrang Timur"
  },
  {
    "id": "731718",
    "regency_id": "7317",
    "label": "Lamasi Timur",
    "value": "Lamasi Timur"
  },
  {
    "id": "731719",
    "regency_id": "7317",
    "label": "Suli Barat",
    "value": "Suli Barat"
  },
  {
    "id": "731720",
    "regency_id": "7317",
    "label": "Bajo Barat",
    "value": "Bajo Barat"
  },
  {
    "id": "731721",
    "regency_id": "7317",
    "label": "Ponrang Selatan",
    "value": "Ponrang Selatan"
  },
  {
    "id": "731722",
    "regency_id": "7317",
    "label": "Basse Sangtempe Utara",
    "value": "Basse Sangtempe Utara"
  },
  {
    "id": "731801",
    "regency_id": "7318",
    "label": "Saluputi",
    "value": "Saluputi"
  },
  {
    "id": "731802",
    "regency_id": "7318",
    "label": "Bittuang",
    "value": "Bittuang"
  },
  {
    "id": "731803",
    "regency_id": "7318",
    "label": "Bonggakaradeng",
    "value": "Bonggakaradeng"
  },
  {
    "id": "731805",
    "regency_id": "7318",
    "label": "Makale",
    "value": "Makale"
  },
  {
    "id": "731809",
    "regency_id": "7318",
    "label": "Simbuang",
    "value": "Simbuang"
  },
  {
    "id": "731811",
    "regency_id": "7318",
    "label": "Rantetayo",
    "value": "Rantetayo"
  },
  {
    "id": "731812",
    "regency_id": "7318",
    "label": "Mengkendek",
    "value": "Mengkendek"
  },
  {
    "id": "731813",
    "regency_id": "7318",
    "label": "Sangalla",
    "value": "Sangalla"
  },
  {
    "id": "731819",
    "regency_id": "7318",
    "label": "Gandangbatu Sillanan",
    "value": "Gandangbatu Sillanan"
  },
  {
    "id": "731820",
    "regency_id": "7318",
    "label": "Rembon",
    "value": "Rembon"
  },
  {
    "id": "731827",
    "regency_id": "7318",
    "label": "Makale Utara",
    "value": "Makale Utara"
  },
  {
    "id": "731828",
    "regency_id": "7318",
    "label": "Mappak",
    "value": "Mappak"
  },
  {
    "id": "731829",
    "regency_id": "7318",
    "label": "Makale Selatan",
    "value": "Makale Selatan"
  },
  {
    "id": "731831",
    "regency_id": "7318",
    "label": "Masanda",
    "value": "Masanda"
  },
  {
    "id": "731833",
    "regency_id": "7318",
    "label": "Sangalla Selatan",
    "value": "Sangalla Selatan"
  },
  {
    "id": "731834",
    "regency_id": "7318",
    "label": "Sangalla Utara",
    "value": "Sangalla Utara"
  },
  {
    "id": "731835",
    "regency_id": "7318",
    "label": "Malimbong Balepe",
    "value": "Malimbong Balepe"
  },
  { "id": "731837", "regency_id": "7318", "label": "Rano", "value": "Rano" },
  { "id": "731838", "regency_id": "7318", "label": "Kurra", "value": "Kurra" },
  {
    "id": "732201",
    "regency_id": "7322",
    "label": "Malangke",
    "value": "Malangke"
  },
  {
    "id": "732202",
    "regency_id": "7322",
    "label": "Bone Bone",
    "value": "Bone Bone"
  },
  {
    "id": "732203",
    "regency_id": "7322",
    "label": "Masamba",
    "value": "Masamba"
  },
  {
    "id": "732204",
    "regency_id": "7322",
    "label": "Sabbang",
    "value": "Sabbang"
  },
  {
    "id": "732205",
    "regency_id": "7322",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "732206",
    "regency_id": "7322",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  { "id": "732207", "regency_id": "7322", "label": "Seko", "value": "Seko" },
  {
    "id": "732208",
    "regency_id": "7322",
    "label": "Malangke Barat",
    "value": "Malangke Barat"
  },
  { "id": "732209", "regency_id": "7322", "label": "Rampi", "value": "Rampi" },
  {
    "id": "732210",
    "regency_id": "7322",
    "label": "Mappedeceng",
    "value": "Mappedeceng"
  },
  {
    "id": "732211",
    "regency_id": "7322",
    "label": "Baebunta",
    "value": "Baebunta"
  },
  {
    "id": "732212",
    "regency_id": "7322",
    "label": "Tana Lili",
    "value": "Tana Lili"
  },
  {
    "id": "732213",
    "regency_id": "7322",
    "label": "Sukamaju Selatan",
    "value": "Sukamaju Selatan"
  },
  {
    "id": "732214",
    "regency_id": "7322",
    "label": "Baebunta Selatan",
    "value": "Baebunta Selatan"
  },
  {
    "id": "732215",
    "regency_id": "7322",
    "label": "Sabbang Selatan",
    "value": "Sabbang Selatan"
  },
  {
    "id": "732401",
    "regency_id": "7324",
    "label": "Mangkutana",
    "value": "Mangkutana"
  },
  { "id": "732402", "regency_id": "7324", "label": "Nuha", "value": "Nuha" },
  {
    "id": "732403",
    "regency_id": "7324",
    "label": "Towuti",
    "value": "Towuti"
  },
  {
    "id": "732404",
    "regency_id": "7324",
    "label": "Malili",
    "value": "Malili"
  },
  {
    "id": "732405",
    "regency_id": "7324",
    "label": "Angkona",
    "value": "Angkona"
  },
  { "id": "732406", "regency_id": "7324", "label": "Wotu", "value": "Wotu" },
  { "id": "732407", "regency_id": "7324", "label": "Burau", "value": "Burau" },
  {
    "id": "732408",
    "regency_id": "7324",
    "label": "Tomoni",
    "value": "Tomoni"
  },
  {
    "id": "732409",
    "regency_id": "7324",
    "label": "Tomoni Timur",
    "value": "Tomoni Timur"
  },
  {
    "id": "732410",
    "regency_id": "7324",
    "label": "Kalaena",
    "value": "Kalaena"
  },
  {
    "id": "732411",
    "regency_id": "7324",
    "label": "Wasuponda",
    "value": "Wasuponda"
  },
  {
    "id": "732601",
    "regency_id": "7326",
    "label": "Rantepao",
    "value": "Rantepao"
  },
  {
    "id": "732602",
    "regency_id": "7326",
    "label": "Sesean",
    "value": "Sesean"
  },
  {
    "id": "732603",
    "regency_id": "7326",
    "label": "Nanggala",
    "value": "Nanggala"
  },
  {
    "id": "732604",
    "regency_id": "7326",
    "label": "Rindingallo",
    "value": "Rindingallo"
  },
  {
    "id": "732605",
    "regency_id": "7326",
    "label": "Buntao",
    "value": "Buntao"
  },
  {
    "id": "732606",
    "regency_id": "7326",
    "label": "Sa'dan",
    "value": "Sa'dan"
  },
  {
    "id": "732607",
    "regency_id": "7326",
    "label": "Sanggalangi",
    "value": "Sanggalangi"
  },
  { "id": "732608", "regency_id": "7326", "label": "Sopai", "value": "Sopai" },
  {
    "id": "732609",
    "regency_id": "7326",
    "label": "Tikala",
    "value": "Tikala"
  },
  {
    "id": "732610",
    "regency_id": "7326",
    "label": "Balusu",
    "value": "Balusu"
  },
  {
    "id": "732611",
    "regency_id": "7326",
    "label": "Tallunglipu",
    "value": "Tallunglipu"
  },
  {
    "id": "732612",
    "regency_id": "7326",
    "label": "Dende' Piongan Napo",
    "value": "Dende' Piongan Napo"
  },
  {
    "id": "732613",
    "regency_id": "7326",
    "label": "Buntu Pepasan",
    "value": "Buntu Pepasan"
  },
  {
    "id": "732614",
    "regency_id": "7326",
    "label": "Baruppu",
    "value": "Baruppu"
  },
  { "id": "732615", "regency_id": "7326", "label": "Kesu", "value": "Kesu" },
  {
    "id": "732616",
    "regency_id": "7326",
    "label": "Tondon",
    "value": "Tondon"
  },
  {
    "id": "732617",
    "regency_id": "7326",
    "label": "Bangkelekila",
    "value": "Bangkelekila"
  },
  {
    "id": "732618",
    "regency_id": "7326",
    "label": "Rantebua",
    "value": "Rantebua"
  },
  {
    "id": "732619",
    "regency_id": "7326",
    "label": "Sesean Suloara",
    "value": "Sesean Suloara"
  },
  {
    "id": "732620",
    "regency_id": "7326",
    "label": "Kapala Pitu",
    "value": "Kapala Pitu"
  },
  {
    "id": "732621",
    "regency_id": "7326",
    "label": "Awan Rante Karua",
    "value": "Awan Rante Karua"
  },
  {
    "id": "737101",
    "regency_id": "7371",
    "label": "Mariso",
    "value": "Mariso"
  },
  {
    "id": "737102",
    "regency_id": "7371",
    "label": "Mamajang",
    "value": "Mamajang"
  },
  {
    "id": "737103",
    "regency_id": "7371",
    "label": "Makassar",
    "value": "Makassar"
  },
  {
    "id": "737104",
    "regency_id": "7371",
    "label": "Ujung Pandang",
    "value": "Ujung Pandang"
  },
  { "id": "737105", "regency_id": "7371", "label": "Wajo", "value": "Wajo" },
  {
    "id": "737106",
    "regency_id": "7371",
    "label": "Bontoala",
    "value": "Bontoala"
  },
  { "id": "737107", "regency_id": "7371", "label": "Tallo", "value": "Tallo" },
  {
    "id": "737108",
    "regency_id": "7371",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "737109",
    "regency_id": "7371",
    "label": "Panakkukang",
    "value": "Panakkukang"
  },
  {
    "id": "737110",
    "regency_id": "7371",
    "label": "Tamalate",
    "value": "Tamalate"
  },
  {
    "id": "737111",
    "regency_id": "7371",
    "label": "Biringkanaya",
    "value": "Biringkanaya"
  },
  {
    "id": "737112",
    "regency_id": "7371",
    "label": "Manggala",
    "value": "Manggala"
  },
  {
    "id": "737113",
    "regency_id": "7371",
    "label": "Rappocini",
    "value": "Rappocini"
  },
  {
    "id": "737114",
    "regency_id": "7371",
    "label": "Tamalanrea",
    "value": "Tamalanrea"
  },
  {
    "id": "737115",
    "regency_id": "7371",
    "label": "Kepulauan Sangkarrang",
    "value": "Kepulauan Sangkarrang"
  },
  {
    "id": "737201",
    "regency_id": "7372",
    "label": "Bacukiki",
    "value": "Bacukiki"
  },
  { "id": "737202", "regency_id": "7372", "label": "Ujung", "value": "Ujung" },
  {
    "id": "737203",
    "regency_id": "7372",
    "label": "Soreang",
    "value": "Soreang"
  },
  {
    "id": "737204",
    "regency_id": "7372",
    "label": "Bacukiki Barat",
    "value": "Bacukiki Barat"
  },
  { "id": "737301", "regency_id": "7373", "label": "Wara", "value": "Wara" },
  {
    "id": "737302",
    "regency_id": "7373",
    "label": "Wara Utara",
    "value": "Wara Utara"
  },
  {
    "id": "737303",
    "regency_id": "7373",
    "label": "Wara Selatan",
    "value": "Wara Selatan"
  },
  {
    "id": "737304",
    "regency_id": "7373",
    "label": "Telluwanua",
    "value": "Telluwanua"
  },
  {
    "id": "737305",
    "regency_id": "7373",
    "label": "Wara Timur",
    "value": "Wara Timur"
  },
  {
    "id": "737306",
    "regency_id": "7373",
    "label": "Wara Barat",
    "value": "Wara Barat"
  },
  {
    "id": "737307",
    "regency_id": "7373",
    "label": "Sendana",
    "value": "Sendana"
  },
  {
    "id": "737308",
    "regency_id": "7373",
    "label": "Mungkajang",
    "value": "Mungkajang"
  },
  { "id": "737309", "regency_id": "7373", "label": "Bara", "value": "Bara" },
  {
    "id": "740101",
    "regency_id": "7401",
    "label": "Wundulako",
    "value": "Wundulako"
  },
  {
    "id": "740104",
    "regency_id": "7401",
    "label": "Kolaka",
    "value": "Kolaka"
  },
  {
    "id": "740107",
    "regency_id": "7401",
    "label": "Pomalaa",
    "value": "Pomalaa"
  },
  {
    "id": "740108",
    "regency_id": "7401",
    "label": "Watubangga",
    "value": "Watubangga"
  },
  { "id": "740110", "regency_id": "7401", "label": "Wolo", "value": "Wolo" },
  { "id": "740112", "regency_id": "7401", "label": "Baula", "value": "Baula" },
  {
    "id": "740114",
    "regency_id": "7401",
    "label": "Latambaga",
    "value": "Latambaga"
  },
  {
    "id": "740118",
    "regency_id": "7401",
    "label": "Tanggetada",
    "value": "Tanggetada"
  },
  {
    "id": "740120",
    "regency_id": "7401",
    "label": "Samaturu",
    "value": "Samaturu"
  },
  { "id": "740124", "regency_id": "7401", "label": "Toari", "value": "Toari" },
  {
    "id": "740125",
    "regency_id": "7401",
    "label": "Polinggona",
    "value": "Polinggona"
  },
  {
    "id": "740127",
    "regency_id": "7401",
    "label": "Iwoimendaa",
    "value": "Iwoimendaa"
  },
  {
    "id": "740201",
    "regency_id": "7402",
    "label": "Lambuya",
    "value": "Lambuya"
  },
  {
    "id": "740202",
    "regency_id": "7402",
    "label": "Unaaha",
    "value": "Unaaha"
  },
  {
    "id": "740203",
    "regency_id": "7402",
    "label": "Wawotobi",
    "value": "Wawotobi"
  },
  {
    "id": "740204",
    "regency_id": "7402",
    "label": "Pondidaha",
    "value": "Pondidaha"
  },
  {
    "id": "740205",
    "regency_id": "7402",
    "label": "Sampara",
    "value": "Sampara"
  },
  { "id": "740210", "regency_id": "7402", "label": "Abuki", "value": "Abuki" },
  {
    "id": "740211",
    "regency_id": "7402",
    "label": "Soropia",
    "value": "Soropia"
  },
  {
    "id": "740215",
    "regency_id": "7402",
    "label": "Tongauna",
    "value": "Tongauna"
  },
  {
    "id": "740216",
    "regency_id": "7402",
    "label": "Latoma",
    "value": "Latoma"
  },
  {
    "id": "740217",
    "regency_id": "7402",
    "label": "Puriala",
    "value": "Puriala"
  },
  { "id": "740218", "regency_id": "7402", "label": "Uepai", "value": "Uepai" },
  {
    "id": "740219",
    "regency_id": "7402",
    "label": "Wonggeduku",
    "value": "Wonggeduku"
  },
  {
    "id": "740220",
    "regency_id": "7402",
    "label": "Besulutu",
    "value": "Besulutu"
  },
  {
    "id": "740221",
    "regency_id": "7402",
    "label": "Bondoala",
    "value": "Bondoala"
  },
  { "id": "740223", "regency_id": "7402", "label": "Routa", "value": "Routa" },
  {
    "id": "740224",
    "regency_id": "7402",
    "label": "Anggaberi",
    "value": "Anggaberi"
  },
  {
    "id": "740225",
    "regency_id": "7402",
    "label": "Meluhu",
    "value": "Meluhu"
  },
  {
    "id": "740228",
    "regency_id": "7402",
    "label": "Amonggedo",
    "value": "Amonggedo"
  },
  {
    "id": "740231",
    "regency_id": "7402",
    "label": "Asinua",
    "value": "Asinua"
  },
  {
    "id": "740232",
    "regency_id": "7402",
    "label": "Konawe",
    "value": "Konawe"
  },
  {
    "id": "740233",
    "regency_id": "7402",
    "label": "Kapoiala",
    "value": "Kapoiala"
  },
  {
    "id": "740236",
    "regency_id": "7402",
    "label": "Lalonggasumeeto",
    "value": "Lalonggasumeeto"
  },
  {
    "id": "740237",
    "regency_id": "7402",
    "label": "Onembute",
    "value": "Onembute"
  },
  {
    "id": "740238",
    "regency_id": "7402",
    "label": "Padangguni",
    "value": "Padangguni"
  },
  {
    "id": "740239",
    "regency_id": "7402",
    "label": "Morosi",
    "value": "Morosi"
  },
  {
    "id": "740240",
    "regency_id": "7402",
    "label": "Anggalomoare",
    "value": "Anggalomoare"
  },
  {
    "id": "740241",
    "regency_id": "7402",
    "label": "Wonggeduku Barat",
    "value": "Wonggeduku Barat"
  },
  {
    "id": "740306",
    "regency_id": "7403",
    "label": "Napabalano",
    "value": "Napabalano"
  },
  {
    "id": "740307",
    "regency_id": "7403",
    "label": "Maligano",
    "value": "Maligano"
  },
  {
    "id": "740313",
    "regency_id": "7403",
    "label": "Wakorumba Selatan",
    "value": "Wakorumba Selatan"
  },
  {
    "id": "740314",
    "regency_id": "7403",
    "label": "Lasalepa",
    "value": "Lasalepa"
  },
  {
    "id": "740315",
    "regency_id": "7403",
    "label": "Batalaiwaru",
    "value": "Batalaiwaru"
  },
  {
    "id": "740316",
    "regency_id": "7403",
    "label": "Katobu",
    "value": "Katobu"
  },
  {
    "id": "740317",
    "regency_id": "7403",
    "label": "Duruka",
    "value": "Duruka"
  },
  { "id": "740318", "regency_id": "7403", "label": "Lohia", "value": "Lohia" },
  {
    "id": "740319",
    "regency_id": "7403",
    "label": "Watopute",
    "value": "Watopute"
  },
  {
    "id": "740320",
    "regency_id": "7403",
    "label": "Kontunaga",
    "value": "Kontunaga"
  },
  {
    "id": "740323",
    "regency_id": "7403",
    "label": "Kabangka",
    "value": "Kabangka"
  },
  {
    "id": "740324",
    "regency_id": "7403",
    "label": "Kabawo",
    "value": "Kabawo"
  },
  {
    "id": "740325",
    "regency_id": "7403",
    "label": "Parigi",
    "value": "Parigi"
  },
  { "id": "740326", "regency_id": "7403", "label": "Bone", "value": "Bone" },
  {
    "id": "740327",
    "regency_id": "7403",
    "label": "Tongkuno",
    "value": "Tongkuno"
  },
  {
    "id": "740328",
    "regency_id": "7403",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "740330",
    "regency_id": "7403",
    "label": "Kontu Kowuna",
    "value": "Kontu Kowuna"
  },
  {
    "id": "740331",
    "regency_id": "7403",
    "label": "Marobo",
    "value": "Marobo"
  },
  {
    "id": "740332",
    "regency_id": "7403",
    "label": "Tongkuno Selatan",
    "value": "Tongkuno Selatan"
  },
  {
    "id": "740333",
    "regency_id": "7403",
    "label": "Pasi Kolaga",
    "value": "Pasi Kolaga"
  },
  {
    "id": "740334",
    "regency_id": "7403",
    "label": "Batukara",
    "value": "Batukara"
  },
  { "id": "740337", "regency_id": "7403", "label": "Towea", "value": "Towea" },
  {
    "id": "740411",
    "regency_id": "7404",
    "label": "Pasarwajo",
    "value": "Pasarwajo"
  },
  {
    "id": "740422",
    "regency_id": "7404",
    "label": "Kapontori",
    "value": "Kapontori"
  },
  {
    "id": "740423",
    "regency_id": "7404",
    "label": "Lasalimu",
    "value": "Lasalimu"
  },
  {
    "id": "740424",
    "regency_id": "7404",
    "label": "Lasalimu Selatan",
    "value": "Lasalimu Selatan"
  },
  {
    "id": "740427",
    "regency_id": "7404",
    "label": "Siotapina",
    "value": "Siotapina"
  },
  {
    "id": "740428",
    "regency_id": "7404",
    "label": "Wolowa",
    "value": "Wolowa"
  },
  {
    "id": "740429",
    "regency_id": "7404",
    "label": "Wabula",
    "value": "Wabula"
  },
  {
    "id": "740501",
    "regency_id": "7405",
    "label": "Tinanggea",
    "value": "Tinanggea"
  },
  {
    "id": "740502",
    "regency_id": "7405",
    "label": "Angata",
    "value": "Angata"
  },
  {
    "id": "740503",
    "regency_id": "7405",
    "label": "Andoolo",
    "value": "Andoolo"
  },
  {
    "id": "740504",
    "regency_id": "7405",
    "label": "Palangga",
    "value": "Palangga"
  },
  {
    "id": "740505",
    "regency_id": "7405",
    "label": "Landono",
    "value": "Landono"
  },
  {
    "id": "740506",
    "regency_id": "7405",
    "label": "Lainea",
    "value": "Lainea"
  },
  { "id": "740507", "regency_id": "7405", "label": "Konda", "value": "Konda" },
  {
    "id": "740508",
    "regency_id": "7405",
    "label": "Ranomeeto",
    "value": "Ranomeeto"
  },
  {
    "id": "740509",
    "regency_id": "7405",
    "label": "Kolono",
    "value": "Kolono"
  },
  {
    "id": "740510",
    "regency_id": "7405",
    "label": "Moramo",
    "value": "Moramo"
  },
  {
    "id": "740511",
    "regency_id": "7405",
    "label": "Laonti",
    "value": "Laonti"
  },
  {
    "id": "740512",
    "regency_id": "7405",
    "label": "Lalembuu",
    "value": "Lalembuu"
  },
  { "id": "740513", "regency_id": "7405", "label": "Benua", "value": "Benua" },
  {
    "id": "740514",
    "regency_id": "7405",
    "label": "Palangga Selatan",
    "value": "Palangga Selatan"
  },
  {
    "id": "740515",
    "regency_id": "7405",
    "label": "Mowila",
    "value": "Mowila"
  },
  {
    "id": "740516",
    "regency_id": "7405",
    "label": "Moramo Utara",
    "value": "Moramo Utara"
  },
  { "id": "740517", "regency_id": "7405", "label": "Buke", "value": "Buke" },
  {
    "id": "740518",
    "regency_id": "7405",
    "label": "Wolasi",
    "value": "Wolasi"
  },
  { "id": "740519", "regency_id": "7405", "label": "Laeya", "value": "Laeya" },
  { "id": "740520", "regency_id": "7405", "label": "Baito", "value": "Baito" },
  {
    "id": "740521",
    "regency_id": "7405",
    "label": "Basala",
    "value": "Basala"
  },
  {
    "id": "740522",
    "regency_id": "7405",
    "label": "Ranomeeto Barat",
    "value": "Ranomeeto Barat"
  },
  {
    "id": "740523",
    "regency_id": "7405",
    "label": "Kolono Timur",
    "value": "Kolono Timur"
  },
  {
    "id": "740524",
    "regency_id": "7405",
    "label": "Sabulakoa",
    "value": "Sabulakoa"
  },
  {
    "id": "740525",
    "regency_id": "7405",
    "label": "Andoolo Barat",
    "value": "Andoolo Barat"
  },
  {
    "id": "740601",
    "regency_id": "7406",
    "label": "Poleang",
    "value": "Poleang"
  },
  {
    "id": "740602",
    "regency_id": "7406",
    "label": "Poleang Timur",
    "value": "Poleang Timur"
  },
  {
    "id": "740603",
    "regency_id": "7406",
    "label": "Rarowatu",
    "value": "Rarowatu"
  },
  {
    "id": "740604",
    "regency_id": "7406",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "740605",
    "regency_id": "7406",
    "label": "Kabaena",
    "value": "Kabaena"
  },
  {
    "id": "740606",
    "regency_id": "7406",
    "label": "Kabaena Timur",
    "value": "Kabaena Timur"
  },
  {
    "id": "740607",
    "regency_id": "7406",
    "label": "Poleang Barat",
    "value": "Poleang Barat"
  },
  {
    "id": "740608",
    "regency_id": "7406",
    "label": "Mata Oleo",
    "value": "Mata Oleo"
  },
  {
    "id": "740609",
    "regency_id": "7406",
    "label": "Rarowatu Utara",
    "value": "Rarowatu Utara"
  },
  {
    "id": "740610",
    "regency_id": "7406",
    "label": "Poleang Utara",
    "value": "Poleang Utara"
  },
  {
    "id": "740611",
    "regency_id": "7406",
    "label": "Poleang Selatan",
    "value": "Poleang Selatan"
  },
  {
    "id": "740612",
    "regency_id": "7406",
    "label": "Poleang Tenggara",
    "value": "Poleang Tenggara"
  },
  {
    "id": "740613",
    "regency_id": "7406",
    "label": "Kabaena Selatan",
    "value": "Kabaena Selatan"
  },
  {
    "id": "740614",
    "regency_id": "7406",
    "label": "Kabaena Barat",
    "value": "Kabaena Barat"
  },
  {
    "id": "740615",
    "regency_id": "7406",
    "label": "Kabaena Utara",
    "value": "Kabaena Utara"
  },
  {
    "id": "740616",
    "regency_id": "7406",
    "label": "Kabaena Tengah",
    "value": "Kabaena Tengah"
  },
  {
    "id": "740617",
    "regency_id": "7406",
    "label": "Kep. Masaloka Raya",
    "value": "Kep. Masaloka Raya"
  },
  {
    "id": "740618",
    "regency_id": "7406",
    "label": "Rumbia Tengah",
    "value": "Rumbia Tengah"
  },
  {
    "id": "740619",
    "regency_id": "7406",
    "label": "Poleang Tengah",
    "value": "Poleang Tengah"
  },
  {
    "id": "740620",
    "regency_id": "7406",
    "label": "Tontonunu",
    "value": "Tontonunu"
  },
  {
    "id": "740621",
    "regency_id": "7406",
    "label": "Lantari Jaya",
    "value": "Lantari Jaya"
  },
  {
    "id": "740622",
    "regency_id": "7406",
    "label": "Mata Usu",
    "value": "Mata Usu"
  },
  {
    "id": "740701",
    "regency_id": "7407",
    "label": "Wangi-Wangi",
    "value": "Wangi-Wangi"
  },
  {
    "id": "740702",
    "regency_id": "7407",
    "label": "Kaledupa",
    "value": "Kaledupa"
  },
  { "id": "740703", "regency_id": "7407", "label": "Tomia", "value": "Tomia" },
  {
    "id": "740704",
    "regency_id": "7407",
    "label": "Binongko",
    "value": "Binongko"
  },
  {
    "id": "740705",
    "regency_id": "7407",
    "label": "Wangi Wangi Selatan",
    "value": "Wangi Wangi Selatan"
  },
  {
    "id": "740706",
    "regency_id": "7407",
    "label": "Kaledupa Selatan",
    "value": "Kaledupa Selatan"
  },
  {
    "id": "740707",
    "regency_id": "7407",
    "label": "Tomia Timur",
    "value": "Tomia Timur"
  },
  {
    "id": "740708",
    "regency_id": "7407",
    "label": "Togo Binongko",
    "value": "Togo Binongko"
  },
  {
    "id": "740801",
    "regency_id": "7408",
    "label": "Lasusua",
    "value": "Lasusua"
  },
  { "id": "740802", "regency_id": "7408", "label": "Pakue", "value": "Pakue" },
  {
    "id": "740803",
    "regency_id": "7408",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "740804",
    "regency_id": "7408",
    "label": "Rante Angin",
    "value": "Rante Angin"
  },
  {
    "id": "740805",
    "regency_id": "7408",
    "label": "Kodeoha",
    "value": "Kodeoha"
  },
  { "id": "740806", "regency_id": "7408", "label": "Ngapa", "value": "Ngapa" },
  { "id": "740807", "regency_id": "7408", "label": "Wawo", "value": "Wawo" },
  {
    "id": "740808",
    "regency_id": "7408",
    "label": "Lambai",
    "value": "Lambai"
  },
  {
    "id": "740809",
    "regency_id": "7408",
    "label": "Watunohu",
    "value": "Watunohu"
  },
  {
    "id": "740810",
    "regency_id": "7408",
    "label": "Pakue Tengah",
    "value": "Pakue Tengah"
  },
  {
    "id": "740811",
    "regency_id": "7408",
    "label": "Pakue Utara",
    "value": "Pakue Utara"
  },
  {
    "id": "740812",
    "regency_id": "7408",
    "label": "Porehu",
    "value": "Porehu"
  },
  { "id": "740813", "regency_id": "7408", "label": "Katoi", "value": "Katoi" },
  { "id": "740814", "regency_id": "7408", "label": "Tiwu", "value": "Tiwu" },
  {
    "id": "740815",
    "regency_id": "7408",
    "label": "Tolala",
    "value": "Tolala"
  },
  { "id": "740901", "regency_id": "7409", "label": "Asera", "value": "Asera" },
  {
    "id": "740902",
    "regency_id": "7409",
    "label": "Wiwirano",
    "value": "Wiwirano"
  },
  {
    "id": "740903",
    "regency_id": "7409",
    "label": "Langgikima",
    "value": "Langgikima"
  },
  {
    "id": "740904",
    "regency_id": "7409",
    "label": "Molawe",
    "value": "Molawe"
  },
  {
    "id": "740905",
    "regency_id": "7409",
    "label": "Lasolo",
    "value": "Lasolo"
  },
  { "id": "740906", "regency_id": "7409", "label": "Lembo", "value": "Lembo" },
  { "id": "740907", "regency_id": "7409", "label": "Sawa", "value": "Sawa" },
  { "id": "740908", "regency_id": "7409", "label": "Oheo", "value": "Oheo" },
  {
    "id": "740909",
    "regency_id": "7409",
    "label": "Andowia",
    "value": "Andowia"
  },
  { "id": "740910", "regency_id": "7409", "label": "Motui", "value": "Motui" },
  {
    "id": "740911",
    "regency_id": "7409",
    "label": "Wawolesea",
    "value": "Wawolesea"
  },
  {
    "id": "740912",
    "regency_id": "7409",
    "label": "Lasolo Kepulauan",
    "value": "Lasolo Kepulauan"
  },
  {
    "id": "740913",
    "regency_id": "7409",
    "label": "Landawe",
    "value": "Landawe"
  },
  {
    "id": "741001",
    "regency_id": "7410",
    "label": "Kulisusu",
    "value": "Kulisusu"
  },
  {
    "id": "741002",
    "regency_id": "7410",
    "label": "Kambowa",
    "value": "Kambowa"
  },
  {
    "id": "741003",
    "regency_id": "7410",
    "label": "Bonegunu",
    "value": "Bonegunu"
  },
  {
    "id": "741004",
    "regency_id": "7410",
    "label": "Kulisusu Barat",
    "value": "Kulisusu Barat"
  },
  {
    "id": "741005",
    "regency_id": "7410",
    "label": "Kulisusu Utara",
    "value": "Kulisusu Utara"
  },
  {
    "id": "741006",
    "regency_id": "7410",
    "label": "Wakorumba Utara",
    "value": "Wakorumba Utara"
  },
  {
    "id": "741101",
    "regency_id": "7411",
    "label": "Tirawuta",
    "value": "Tirawuta"
  },
  { "id": "741102", "regency_id": "7411", "label": "Loea", "value": "Loea" },
  {
    "id": "741103",
    "regency_id": "7411",
    "label": "Ladongi",
    "value": "Ladongi"
  },
  {
    "id": "741104",
    "regency_id": "7411",
    "label": "Poli Polia",
    "value": "Poli Polia"
  },
  {
    "id": "741105",
    "regency_id": "7411",
    "label": "Lambandia",
    "value": "Lambandia"
  },
  {
    "id": "741106",
    "regency_id": "7411",
    "label": "Lalolae",
    "value": "Lalolae"
  },
  {
    "id": "741107",
    "regency_id": "7411",
    "label": "Mowewe",
    "value": "Mowewe"
  },
  {
    "id": "741108",
    "regency_id": "7411",
    "label": "Uluiwoi",
    "value": "Uluiwoi"
  },
  {
    "id": "741109",
    "regency_id": "7411",
    "label": "Tinondo",
    "value": "Tinondo"
  },
  { "id": "741110", "regency_id": "7411", "label": "Aere", "value": "Aere" },
  { "id": "741111", "regency_id": "7411", "label": "Ueesi", "value": "Ueesi" },
  {
    "id": "741112",
    "regency_id": "7411",
    "label": "Dangia",
    "value": "Dangia"
  },
  {
    "id": "741201",
    "regency_id": "7412",
    "label": "Wawonii Barat",
    "value": "Wawonii Barat"
  },
  {
    "id": "741202",
    "regency_id": "7412",
    "label": "Wawonii Utara",
    "value": "Wawonii Utara"
  },
  {
    "id": "741203",
    "regency_id": "7412",
    "label": "Wawonii Timur Laut",
    "value": "Wawonii Timur Laut"
  },
  {
    "id": "741204",
    "regency_id": "7412",
    "label": "Wawonii Timur",
    "value": "Wawonii Timur"
  },
  {
    "id": "741205",
    "regency_id": "7412",
    "label": "Wawonii Tenggara",
    "value": "Wawonii Tenggara"
  },
  {
    "id": "741206",
    "regency_id": "7412",
    "label": "Wawonii Selatan",
    "value": "Wawonii Selatan"
  },
  {
    "id": "741207",
    "regency_id": "7412",
    "label": "Wawonii Tengah",
    "value": "Wawonii Tengah"
  },
  {
    "id": "741301",
    "regency_id": "7413",
    "label": "Sawerigadi",
    "value": "Sawerigadi"
  },
  {
    "id": "741302",
    "regency_id": "7413",
    "label": "Barangka",
    "value": "Barangka"
  },
  { "id": "741303", "regency_id": "7413", "label": "Lawa", "value": "Lawa" },
  {
    "id": "741304",
    "regency_id": "7413",
    "label": "Wadaga",
    "value": "Wadaga"
  },
  {
    "id": "741305",
    "regency_id": "7413",
    "label": "Tiworo Selatan",
    "value": "Tiworo Selatan"
  },
  {
    "id": "741306",
    "regency_id": "7413",
    "label": "Maginti",
    "value": "Maginti"
  },
  {
    "id": "741307",
    "regency_id": "7413",
    "label": "Tiworo Tengah",
    "value": "Tiworo Tengah"
  },
  {
    "id": "741308",
    "regency_id": "7413",
    "label": "Tiworo Utara",
    "value": "Tiworo Utara"
  },
  {
    "id": "741309",
    "regency_id": "7413",
    "label": "Tiworo Kepulauan",
    "value": "Tiworo Kepulauan"
  },
  {
    "id": "741310",
    "regency_id": "7413",
    "label": "Kusambi",
    "value": "Kusambi"
  },
  {
    "id": "741311",
    "regency_id": "7413",
    "label": "Napano Kusambi",
    "value": "Napano Kusambi"
  },
  {
    "id": "741401",
    "regency_id": "7414",
    "label": "Lakudo",
    "value": "Lakudo"
  },
  {
    "id": "741402",
    "regency_id": "7414",
    "label": "Mawasangka Timur",
    "value": "Mawasangka Timur"
  },
  {
    "id": "741403",
    "regency_id": "7414",
    "label": "Mawasangka Tengah",
    "value": "Mawasangka Tengah"
  },
  {
    "id": "741404",
    "regency_id": "7414",
    "label": "Mawasangka",
    "value": "Mawasangka"
  },
  {
    "id": "741405",
    "regency_id": "7414",
    "label": "Talaga Raya",
    "value": "Talaga Raya"
  },
  { "id": "741406", "regency_id": "7414", "label": "Gu", "value": "Gu" },
  {
    "id": "741407",
    "regency_id": "7414",
    "label": "Sangia Wambulu",
    "value": "Sangia Wambulu"
  },
  {
    "id": "741501",
    "regency_id": "7415",
    "label": "Batauga",
    "value": "Batauga"
  },
  {
    "id": "741502",
    "regency_id": "7415",
    "label": "Sampolawa",
    "value": "Sampolawa"
  },
  {
    "id": "741503",
    "regency_id": "7415",
    "label": "Lapandewa",
    "value": "Lapandewa"
  },
  {
    "id": "741504",
    "regency_id": "7415",
    "label": "Batu Atas",
    "value": "Batu Atas"
  },
  {
    "id": "741505",
    "regency_id": "7415",
    "label": "Siompu Barat",
    "value": "Siompu Barat"
  },
  {
    "id": "741506",
    "regency_id": "7415",
    "label": "Siompu",
    "value": "Siompu"
  },
  {
    "id": "741507",
    "regency_id": "7415",
    "label": "Kadatua",
    "value": "Kadatua"
  },
  {
    "id": "747101",
    "regency_id": "7471",
    "label": "Mandonga",
    "value": "Mandonga"
  },
  {
    "id": "747102",
    "regency_id": "7471",
    "label": "Kendari",
    "value": "Kendari"
  },
  {
    "id": "747103",
    "regency_id": "7471",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "747104",
    "regency_id": "7471",
    "label": "Poasia",
    "value": "Poasia"
  },
  {
    "id": "747105",
    "regency_id": "7471",
    "label": "Kendari Barat",
    "value": "Kendari Barat"
  },
  { "id": "747106", "regency_id": "7471", "label": "Abeli", "value": "Abeli" },
  {
    "id": "747107",
    "regency_id": "7471",
    "label": "Wua-Wua",
    "value": "Wua-Wua"
  },
  { "id": "747108", "regency_id": "7471", "label": "Kadia", "value": "Kadia" },
  {
    "id": "747109",
    "regency_id": "7471",
    "label": "Puuwatu",
    "value": "Puuwatu"
  },
  { "id": "747110", "regency_id": "7471", "label": "Kambu", "value": "Kambu" },
  {
    "id": "747201",
    "regency_id": "7472",
    "label": "Betoambari",
    "value": "Betoambari"
  },
  { "id": "747202", "regency_id": "7472", "label": "Wolio", "value": "Wolio" },
  {
    "id": "747203",
    "regency_id": "7472",
    "label": "Sorawolio",
    "value": "Sorawolio"
  },
  { "id": "747204", "regency_id": "7472", "label": "Bungi", "value": "Bungi" },
  {
    "id": "747205",
    "regency_id": "7472",
    "label": "Kokalukuna",
    "value": "Kokalukuna"
  },
  {
    "id": "747206",
    "regency_id": "7472",
    "label": "Murhum",
    "value": "Murhum"
  },
  {
    "id": "747207",
    "regency_id": "7472",
    "label": "Lea-Lea",
    "value": "Lea-Lea"
  },
  {
    "id": "747208",
    "regency_id": "7472",
    "label": "Batupoaro",
    "value": "Batupoaro"
  },
  {
    "id": "750101",
    "regency_id": "7501",
    "label": "Limboto",
    "value": "Limboto"
  },
  {
    "id": "750102",
    "regency_id": "7501",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "750103",
    "regency_id": "7501",
    "label": "Batudaa",
    "value": "Batudaa"
  },
  {
    "id": "750104",
    "regency_id": "7501",
    "label": "Tibawa",
    "value": "Tibawa"
  },
  {
    "id": "750105",
    "regency_id": "7501",
    "label": "Batudaa Pantai",
    "value": "Batudaa Pantai"
  },
  {
    "id": "750109",
    "regency_id": "7501",
    "label": "Boliyohuto",
    "value": "Boliyohuto"
  },
  {
    "id": "750110",
    "regency_id": "7501",
    "label": "Telaga Biru",
    "value": "Telaga Biru"
  },
  {
    "id": "750111",
    "regency_id": "7501",
    "label": "Bongomeme",
    "value": "Bongomeme"
  },
  {
    "id": "750113",
    "regency_id": "7501",
    "label": "Tolangohula",
    "value": "Tolangohula"
  },
  {
    "id": "750114",
    "regency_id": "7501",
    "label": "Mootilango",
    "value": "Mootilango"
  },
  {
    "id": "750116",
    "regency_id": "7501",
    "label": "Pulubala",
    "value": "Pulubala"
  },
  {
    "id": "750117",
    "regency_id": "7501",
    "label": "Limboto Barat",
    "value": "Limboto Barat"
  },
  {
    "id": "750118",
    "regency_id": "7501",
    "label": "Tilango",
    "value": "Tilango"
  },
  {
    "id": "750119",
    "regency_id": "7501",
    "label": "Tabongo",
    "value": "Tabongo"
  },
  {
    "id": "750120",
    "regency_id": "7501",
    "label": "Biluhu",
    "value": "Biluhu"
  },
  {
    "id": "750121",
    "regency_id": "7501",
    "label": "Asparaga",
    "value": "Asparaga"
  },
  {
    "id": "750122",
    "regency_id": "7501",
    "label": "Talaga Jaya",
    "value": "Talaga Jaya"
  },
  {
    "id": "750123",
    "regency_id": "7501",
    "label": "Bilato",
    "value": "Bilato"
  },
  {
    "id": "750124",
    "regency_id": "7501",
    "label": "Dungaliyo",
    "value": "Dungaliyo"
  },
  {
    "id": "750201",
    "regency_id": "7502",
    "label": "Paguyaman",
    "value": "Paguyaman"
  },
  {
    "id": "750202",
    "regency_id": "7502",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "750203",
    "regency_id": "7502",
    "label": "Dulupi",
    "value": "Dulupi"
  },
  {
    "id": "750204",
    "regency_id": "7502",
    "label": "Tilamuta",
    "value": "Tilamuta"
  },
  {
    "id": "750205",
    "regency_id": "7502",
    "label": "Mananggu",
    "value": "Mananggu"
  },
  {
    "id": "750206",
    "regency_id": "7502",
    "label": "Botumoita",
    "value": "Botumoita"
  },
  {
    "id": "750207",
    "regency_id": "7502",
    "label": "Paguyaman Pantai",
    "value": "Paguyaman Pantai"
  },
  { "id": "750301", "regency_id": "7503", "label": "Tapa", "value": "Tapa" },
  {
    "id": "750302",
    "regency_id": "7503",
    "label": "Kabila",
    "value": "Kabila"
  },
  {
    "id": "750303",
    "regency_id": "7503",
    "label": "Suwawa",
    "value": "Suwawa"
  },
  {
    "id": "750304",
    "regency_id": "7503",
    "label": "Bonepantai",
    "value": "Bonepantai"
  },
  {
    "id": "750305",
    "regency_id": "7503",
    "label": "Bulango Utara",
    "value": "Bulango Utara"
  },
  {
    "id": "750306",
    "regency_id": "7503",
    "label": "Tilongkabila",
    "value": "Tilongkabila"
  },
  {
    "id": "750307",
    "regency_id": "7503",
    "label": "Botupingge",
    "value": "Botupingge"
  },
  {
    "id": "750308",
    "regency_id": "7503",
    "label": "Kabila Bone",
    "value": "Kabila Bone"
  },
  { "id": "750309", "regency_id": "7503", "label": "Bone", "value": "Bone" },
  {
    "id": "750310",
    "regency_id": "7503",
    "label": "Bone Raya",
    "value": "Bone Raya"
  },
  {
    "id": "750311",
    "regency_id": "7503",
    "label": "Suwawa Timur",
    "value": "Suwawa Timur"
  },
  {
    "id": "750312",
    "regency_id": "7503",
    "label": "Suwawa Selatan",
    "value": "Suwawa Selatan"
  },
  {
    "id": "750313",
    "regency_id": "7503",
    "label": "Suwawa Tengah",
    "value": "Suwawa Tengah"
  },
  {
    "id": "750314",
    "regency_id": "7503",
    "label": "Bulango Ulu",
    "value": "Bulango Ulu"
  },
  {
    "id": "750315",
    "regency_id": "7503",
    "label": "Bulango Selatan",
    "value": "Bulango Selatan"
  },
  {
    "id": "750316",
    "regency_id": "7503",
    "label": "Bulango Timur",
    "value": "Bulango Timur"
  },
  {
    "id": "750317",
    "regency_id": "7503",
    "label": "Bulawa",
    "value": "Bulawa"
  },
  {
    "id": "750318",
    "regency_id": "7503",
    "label": "Pinogu",
    "value": "Pinogu"
  },
  {
    "id": "750401",
    "regency_id": "7504",
    "label": "Popayato",
    "value": "Popayato"
  },
  {
    "id": "750402",
    "regency_id": "7504",
    "label": "Lemito",
    "value": "Lemito"
  },
  {
    "id": "750403",
    "regency_id": "7504",
    "label": "Randangan",
    "value": "Randangan"
  },
  {
    "id": "750404",
    "regency_id": "7504",
    "label": "Marisa",
    "value": "Marisa"
  },
  {
    "id": "750405",
    "regency_id": "7504",
    "label": "Paguat",
    "value": "Paguat"
  },
  {
    "id": "750406",
    "regency_id": "7504",
    "label": "Patilanggio",
    "value": "Patilanggio"
  },
  {
    "id": "750407",
    "regency_id": "7504",
    "label": "Taluditi",
    "value": "Taluditi"
  },
  {
    "id": "750408",
    "regency_id": "7504",
    "label": "Dengilo",
    "value": "Dengilo"
  },
  {
    "id": "750409",
    "regency_id": "7504",
    "label": "Buntulia",
    "value": "Buntulia"
  },
  {
    "id": "750410",
    "regency_id": "7504",
    "label": "Duhiadaa",
    "value": "Duhiadaa"
  },
  {
    "id": "750411",
    "regency_id": "7504",
    "label": "Wanggarasi",
    "value": "Wanggarasi"
  },
  {
    "id": "750412",
    "regency_id": "7504",
    "label": "Popayato Timur",
    "value": "Popayato Timur"
  },
  {
    "id": "750413",
    "regency_id": "7504",
    "label": "Popayato Barat",
    "value": "Popayato Barat"
  },
  {
    "id": "750501",
    "regency_id": "7505",
    "label": "Atinggola",
    "value": "Atinggola"
  },
  {
    "id": "750502",
    "regency_id": "7505",
    "label": "Kwandang",
    "value": "Kwandang"
  },
  {
    "id": "750503",
    "regency_id": "7505",
    "label": "Anggrek",
    "value": "Anggrek"
  },
  {
    "id": "750504",
    "regency_id": "7505",
    "label": "Sumalata",
    "value": "Sumalata"
  },
  {
    "id": "750505",
    "regency_id": "7505",
    "label": "Tolinggula",
    "value": "Tolinggula"
  },
  {
    "id": "750506",
    "regency_id": "7505",
    "label": "Gentuma Raya",
    "value": "Gentuma Raya"
  },
  {
    "id": "750507",
    "regency_id": "7505",
    "label": "Tomolito",
    "value": "Tomolito"
  },
  {
    "id": "750508",
    "regency_id": "7505",
    "label": "Ponelo Kepulauan",
    "value": "Ponelo Kepulauan"
  },
  {
    "id": "750509",
    "regency_id": "7505",
    "label": "Monano",
    "value": "Monano"
  },
  { "id": "750510", "regency_id": "7505", "label": "Biau", "value": "Biau" },
  {
    "id": "750511",
    "regency_id": "7505",
    "label": "Sumalata Timur",
    "value": "Sumalata Timur"
  },
  {
    "id": "757101",
    "regency_id": "7571",
    "label": "Kota Barat",
    "value": "Kota Barat"
  },
  {
    "id": "757102",
    "regency_id": "7571",
    "label": "Kota Selatan",
    "value": "Kota Selatan"
  },
  {
    "id": "757103",
    "regency_id": "7571",
    "label": "Kota Utara",
    "value": "Kota Utara"
  },
  {
    "id": "757104",
    "regency_id": "7571",
    "label": "Dungingi",
    "value": "Dungingi"
  },
  {
    "id": "757105",
    "regency_id": "7571",
    "label": "Kota Timur",
    "value": "Kota Timur"
  },
  {
    "id": "757106",
    "regency_id": "7571",
    "label": "Kota Tengah",
    "value": "Kota Tengah"
  },
  {
    "id": "757107",
    "regency_id": "7571",
    "label": "Sipatana",
    "value": "Sipatana"
  },
  {
    "id": "757108",
    "regency_id": "7571",
    "label": "Dumbo Raya",
    "value": "Dumbo Raya"
  },
  {
    "id": "757109",
    "regency_id": "7571",
    "label": "Hulonthalangi",
    "value": "Hulonthalangi"
  },
  {
    "id": "760101",
    "regency_id": "7601",
    "label": "Bambalamotu",
    "value": "Bambalamotu"
  },
  {
    "id": "760102",
    "regency_id": "7601",
    "label": "Pasangkayu",
    "value": "Pasangkayu"
  },
  { "id": "760103", "regency_id": "7601", "label": "Baras", "value": "Baras" },
  {
    "id": "760104",
    "regency_id": "7601",
    "label": "Sarudu",
    "value": "Sarudu"
  },
  {
    "id": "760105",
    "regency_id": "7601",
    "label": "Dapurang",
    "value": "Dapurang"
  },
  {
    "id": "760106",
    "regency_id": "7601",
    "label": "Duripoku",
    "value": "Duripoku"
  },
  {
    "id": "760107",
    "regency_id": "7601",
    "label": "Bulu Taba",
    "value": "Bulu Taba"
  },
  {
    "id": "760108",
    "regency_id": "7601",
    "label": "Tikke Raya",
    "value": "Tikke Raya"
  },
  {
    "id": "760109",
    "regency_id": "7601",
    "label": "Pedongga",
    "value": "Pedongga"
  },
  {
    "id": "760110",
    "regency_id": "7601",
    "label": "Bambaira",
    "value": "Bambaira"
  },
  { "id": "760111", "regency_id": "7601", "label": "Sarjo", "value": "Sarjo" },
  {
    "id": "760112",
    "regency_id": "7601",
    "label": "Lariang",
    "value": "Lariang"
  },
  {
    "id": "760201",
    "regency_id": "7602",
    "label": "Mamuju",
    "value": "Mamuju"
  },
  {
    "id": "760202",
    "regency_id": "7602",
    "label": "Tapalang",
    "value": "Tapalang"
  },
  {
    "id": "760203",
    "regency_id": "7602",
    "label": "Kalukku",
    "value": "Kalukku"
  },
  {
    "id": "760204",
    "regency_id": "7602",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "760207",
    "regency_id": "7602",
    "label": "Papalang",
    "value": "Papalang"
  },
  {
    "id": "760208",
    "regency_id": "7602",
    "label": "Sampaga",
    "value": "Sampaga"
  },
  { "id": "760211", "regency_id": "7602", "label": "Tommo", "value": "Tommo" },
  {
    "id": "760212",
    "regency_id": "7602",
    "label": "Simboro dan Kepulauan",
    "value": "Simboro dan Kepulauan"
  },
  {
    "id": "760213",
    "regency_id": "7602",
    "label": "Tapalang Barat",
    "value": "Tapalang Barat"
  },
  {
    "id": "760215",
    "regency_id": "7602",
    "label": "Bonehau",
    "value": "Bonehau"
  },
  {
    "id": "760216",
    "regency_id": "7602",
    "label": "Kep. Bala Balakang",
    "value": "Kep. Bala Balakang"
  },
  { "id": "760301", "regency_id": "7603", "label": "Mambi", "value": "Mambi" },
  {
    "id": "760302",
    "regency_id": "7603",
    "label": "Aralle",
    "value": "Aralle"
  },
  {
    "id": "760303",
    "regency_id": "7603",
    "label": "Mamasa",
    "value": "Mamasa"
  },
  { "id": "760304", "regency_id": "7603", "label": "Pana", "value": "Pana" },
  {
    "id": "760305",
    "regency_id": "7603",
    "label": "Tabulahan",
    "value": "Tabulahan"
  },
  {
    "id": "760306",
    "regency_id": "7603",
    "label": "Sumarorong",
    "value": "Sumarorong"
  },
  {
    "id": "760307",
    "regency_id": "7603",
    "label": "Messawa",
    "value": "Messawa"
  },
  {
    "id": "760308",
    "regency_id": "7603",
    "label": "Sesenapadang",
    "value": "Sesenapadang"
  },
  {
    "id": "760309",
    "regency_id": "7603",
    "label": "Tanduk Kalua",
    "value": "Tanduk Kalua"
  },
  {
    "id": "760310",
    "regency_id": "7603",
    "label": "Tabang",
    "value": "Tabang"
  },
  {
    "id": "760311",
    "regency_id": "7603",
    "label": "Bambang",
    "value": "Bambang"
  },
  { "id": "760312", "regency_id": "7603", "label": "Balla", "value": "Balla" },
  { "id": "760313", "regency_id": "7603", "label": "Nosu", "value": "Nosu" },
  {
    "id": "760314",
    "regency_id": "7603",
    "label": "Tawalian",
    "value": "Tawalian"
  },
  {
    "id": "760315",
    "regency_id": "7603",
    "label": "Rantebulahan Timur",
    "value": "Rantebulahan Timur"
  },
  {
    "id": "760316",
    "regency_id": "7603",
    "label": "Buntumalangka",
    "value": "Buntumalangka"
  },
  {
    "id": "760317",
    "regency_id": "7603",
    "label": "Mehalaan",
    "value": "Mehalaan"
  },
  {
    "id": "760401",
    "regency_id": "7604",
    "label": "Tinambung",
    "value": "Tinambung"
  },
  {
    "id": "760402",
    "regency_id": "7604",
    "label": "Campalagian",
    "value": "Campalagian"
  },
  {
    "id": "760403",
    "regency_id": "7604",
    "label": "Wonomulyo",
    "value": "Wonomulyo"
  },
  {
    "id": "760404",
    "regency_id": "7604",
    "label": "Polewali",
    "value": "Polewali"
  },
  { "id": "760405", "regency_id": "7604", "label": "Tutar", "value": "Tutar" },
  {
    "id": "760406",
    "regency_id": "7604",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "760407",
    "regency_id": "7604",
    "label": "Tapango",
    "value": "Tapango"
  },
  {
    "id": "760408",
    "regency_id": "7604",
    "label": "Mapilli",
    "value": "Mapilli"
  },
  {
    "id": "760409",
    "regency_id": "7604",
    "label": "Matangnga",
    "value": "Matangnga"
  },
  { "id": "760410", "regency_id": "7604", "label": "Luyo", "value": "Luyo" },
  {
    "id": "760411",
    "regency_id": "7604",
    "label": "Limboro",
    "value": "Limboro"
  },
  {
    "id": "760412",
    "regency_id": "7604",
    "label": "Balanipa",
    "value": "Balanipa"
  },
  {
    "id": "760413",
    "regency_id": "7604",
    "label": "Anreapi",
    "value": "Anreapi"
  },
  {
    "id": "760414",
    "regency_id": "7604",
    "label": "Matakali",
    "value": "Matakali"
  },
  { "id": "760415", "regency_id": "7604", "label": "Allu", "value": "Allu" },
  { "id": "760416", "regency_id": "7604", "label": "Bulo", "value": "Bulo" },
  {
    "id": "760501",
    "regency_id": "7605",
    "label": "Banggae",
    "value": "Banggae"
  },
  {
    "id": "760502",
    "regency_id": "7605",
    "label": "Pamboang",
    "value": "Pamboang"
  },
  {
    "id": "760503",
    "regency_id": "7605",
    "label": "Sendana",
    "value": "Sendana"
  },
  {
    "id": "760504",
    "regency_id": "7605",
    "label": "Malunda",
    "value": "Malunda"
  },
  {
    "id": "760505",
    "regency_id": "7605",
    "label": "Ulumanda",
    "value": "Ulumanda"
  },
  {
    "id": "760506",
    "regency_id": "7605",
    "label": "Tammerodo Sendana",
    "value": "Tammerodo Sendana"
  },
  {
    "id": "760507",
    "regency_id": "7605",
    "label": "Tubo Sendana",
    "value": "Tubo Sendana"
  },
  {
    "id": "760508",
    "regency_id": "7605",
    "label": "Banggae Timur",
    "value": "Banggae Timur"
  },
  {
    "id": "760601",
    "regency_id": "7606",
    "label": "Tobadak",
    "value": "Tobadak"
  },
  {
    "id": "760602",
    "regency_id": "7606",
    "label": "Pangale",
    "value": "Pangale"
  },
  {
    "id": "760603",
    "regency_id": "7606",
    "label": "Budong-Budong",
    "value": "Budong-Budong"
  },
  {
    "id": "760604",
    "regency_id": "7606",
    "label": "Topoyo",
    "value": "Topoyo"
  },
  {
    "id": "760605",
    "regency_id": "7606",
    "label": "Karossa",
    "value": "Karossa"
  },
  {
    "id": "810101",
    "regency_id": "8101",
    "label": "Amahai",
    "value": "Amahai"
  },
  {
    "id": "810102",
    "regency_id": "8101",
    "label": "Teon Nila Serua",
    "value": "Teon Nila Serua"
  },
  {
    "id": "810106",
    "regency_id": "8101",
    "label": "Seram Utara",
    "value": "Seram Utara"
  },
  { "id": "810109", "regency_id": "8101", "label": "Banda", "value": "Banda" },
  {
    "id": "810111",
    "regency_id": "8101",
    "label": "Tehoru",
    "value": "Tehoru"
  },
  {
    "id": "810112",
    "regency_id": "8101",
    "label": "Saparua",
    "value": "Saparua"
  },
  {
    "id": "810113",
    "regency_id": "8101",
    "label": "Pulau Haruku",
    "value": "Pulau Haruku"
  },
  {
    "id": "810114",
    "regency_id": "8101",
    "label": "Salahutu",
    "value": "Salahutu"
  },
  {
    "id": "810115",
    "regency_id": "8101",
    "label": "Leihitu",
    "value": "Leihitu"
  },
  {
    "id": "810116",
    "regency_id": "8101",
    "label": "Nusa Laut",
    "value": "Nusa Laut"
  },
  {
    "id": "810117",
    "regency_id": "8101",
    "label": "Kota Masohi",
    "value": "Kota Masohi"
  },
  {
    "id": "810120",
    "regency_id": "8101",
    "label": "Seram Utara Barat",
    "value": "Seram Utara Barat"
  },
  {
    "id": "810121",
    "regency_id": "8101",
    "label": "Teluk Elpaputih",
    "value": "Teluk Elpaputih"
  },
  {
    "id": "810122",
    "regency_id": "8101",
    "label": "Leihitu Barat",
    "value": "Leihitu Barat"
  },
  {
    "id": "810123",
    "regency_id": "8101",
    "label": "Telutih",
    "value": "Telutih"
  },
  {
    "id": "810124",
    "regency_id": "8101",
    "label": "Seram Utara Timur Seti",
    "value": "Seram Utara Timur Seti"
  },
  {
    "id": "810125",
    "regency_id": "8101",
    "label": "Seram Utara Timur Kobi",
    "value": "Seram Utara Timur Kobi"
  },
  {
    "id": "810126",
    "regency_id": "8101",
    "label": "Saparua Timur",
    "value": "Saparua Timur"
  },
  {
    "id": "810201",
    "regency_id": "8102",
    "label": "Kei Kecil",
    "value": "Kei Kecil"
  },
  {
    "id": "810203",
    "regency_id": "8102",
    "label": "Kei Besar",
    "value": "Kei Besar"
  },
  {
    "id": "810204",
    "regency_id": "8102",
    "label": "Kei Besar Selatan",
    "value": "Kei Besar Selatan"
  },
  {
    "id": "810205",
    "regency_id": "8102",
    "label": "Kei Besar Utara Timur",
    "value": "Kei Besar Utara Timur"
  },
  {
    "id": "810213",
    "regency_id": "8102",
    "label": "Kei Kecil Timur",
    "value": "Kei Kecil Timur"
  },
  {
    "id": "810214",
    "regency_id": "8102",
    "label": "Kei Kecil Barat",
    "value": "Kei Kecil Barat"
  },
  {
    "id": "810215",
    "regency_id": "8102",
    "label": "Manyeuw",
    "value": "Manyeuw"
  },
  {
    "id": "810216",
    "regency_id": "8102",
    "label": "Hoat Sorbay",
    "value": "Hoat Sorbay"
  },
  {
    "id": "810217",
    "regency_id": "8102",
    "label": "Kei Besar Utara Barat",
    "value": "Kei Besar Utara Barat"
  },
  {
    "id": "810218",
    "regency_id": "8102",
    "label": "Kei Besar Selatan Barat",
    "value": "Kei Besar Selatan Barat"
  },
  {
    "id": "810219",
    "regency_id": "8102",
    "label": "Kei Kecil Timur Selatan",
    "value": "Kei Kecil Timur Selatan"
  },
  {
    "id": "810301",
    "regency_id": "8103",
    "label": "Tanimbar Selatan",
    "value": "Tanimbar Selatan"
  },
  {
    "id": "810302",
    "regency_id": "8103",
    "label": "Selaru",
    "value": "Selaru"
  },
  {
    "id": "810303",
    "regency_id": "8103",
    "label": "Wer Tamrian",
    "value": "Wer Tamrian"
  },
  {
    "id": "810304",
    "regency_id": "8103",
    "label": "Wer Maktian",
    "value": "Wer Maktian"
  },
  {
    "id": "810305",
    "regency_id": "8103",
    "label": "Tanimbar Utara",
    "value": "Tanimbar Utara"
  },
  { "id": "810306", "regency_id": "8103", "label": "Yaru", "value": "Yaru" },
  {
    "id": "810307",
    "regency_id": "8103",
    "label": "Wuar Labobar",
    "value": "Wuar Labobar"
  },
  {
    "id": "810308",
    "regency_id": "8103",
    "label": "Kormomolin",
    "value": "Kormomolin"
  },
  {
    "id": "810309",
    "regency_id": "8103",
    "label": "Nirunmas",
    "value": "Nirunmas"
  },
  {
    "id": "810318",
    "regency_id": "8103",
    "label": "Molu Maru",
    "value": "Molu Maru"
  },
  {
    "id": "810401",
    "regency_id": "8104",
    "label": "Namlea",
    "value": "Namlea"
  },
  {
    "id": "810402",
    "regency_id": "8104",
    "label": "Air Buaya",
    "value": "Air Buaya"
  },
  {
    "id": "810403",
    "regency_id": "8104",
    "label": "Waeapo",
    "value": "Waeapo"
  },
  {
    "id": "810406",
    "regency_id": "8104",
    "label": "Waplau",
    "value": "Waplau"
  },
  {
    "id": "810410",
    "regency_id": "8104",
    "label": "Batabual",
    "value": "Batabual"
  },
  {
    "id": "810411",
    "regency_id": "8104",
    "label": "Lolong Guba",
    "value": "Lolong Guba"
  },
  {
    "id": "810412",
    "regency_id": "8104",
    "label": "Waelata",
    "value": "Waelata"
  },
  {
    "id": "810413",
    "regency_id": "8104",
    "label": "Fena Leisela",
    "value": "Fena Leisela"
  },
  {
    "id": "810414",
    "regency_id": "8104",
    "label": "Teluk Kaiely",
    "value": "Teluk Kaiely"
  },
  {
    "id": "810415",
    "regency_id": "8104",
    "label": "Lilialy",
    "value": "Lilialy"
  },
  { "id": "810501", "regency_id": "8105", "label": "Bula", "value": "Bula" },
  {
    "id": "810502",
    "regency_id": "8105",
    "label": "Seram Timur",
    "value": "Seram Timur"
  },
  {
    "id": "810503",
    "regency_id": "8105",
    "label": "Werinama",
    "value": "Werinama"
  },
  {
    "id": "810504",
    "regency_id": "8105",
    "label": "Pulau Gorom",
    "value": "Pulau Gorom"
  },
  {
    "id": "810505",
    "regency_id": "8105",
    "label": "Wakate",
    "value": "Wakate"
  },
  {
    "id": "810506",
    "regency_id": "8105",
    "label": "Tutuk Tolu",
    "value": "Tutuk Tolu"
  },
  {
    "id": "810507",
    "regency_id": "8105",
    "label": "Siwalalat",
    "value": "Siwalalat"
  },
  {
    "id": "810508",
    "regency_id": "8105",
    "label": "Kilmury",
    "value": "Kilmury"
  },
  {
    "id": "810509",
    "regency_id": "8105",
    "label": "Pulau Panjang",
    "value": "Pulau Panjang"
  },
  { "id": "810510", "regency_id": "8105", "label": "Teor", "value": "Teor" },
  {
    "id": "810511",
    "regency_id": "8105",
    "label": "Gorom Timur",
    "value": "Gorom Timur"
  },
  {
    "id": "810512",
    "regency_id": "8105",
    "label": "Bula Barat",
    "value": "Bula Barat"
  },
  {
    "id": "810513",
    "regency_id": "8105",
    "label": "Kian Darat",
    "value": "Kian Darat"
  },
  {
    "id": "810514",
    "regency_id": "8105",
    "label": "Siritaun Wida Timur",
    "value": "Siritaun Wida Timur"
  },
  {
    "id": "810515",
    "regency_id": "8105",
    "label": "Teluk Waru",
    "value": "Teluk Waru"
  },
  {
    "id": "810601",
    "regency_id": "8106",
    "label": "Kairatu",
    "value": "Kairatu"
  },
  {
    "id": "810602",
    "regency_id": "8106",
    "label": "Seram Barat",
    "value": "Seram Barat"
  },
  {
    "id": "810603",
    "regency_id": "8106",
    "label": "Taniwel",
    "value": "Taniwel"
  },
  {
    "id": "810604",
    "regency_id": "8106",
    "label": "Huamual Belakang",
    "value": "Huamual Belakang"
  },
  {
    "id": "810605",
    "regency_id": "8106",
    "label": "Amalatu",
    "value": "Amalatu"
  },
  {
    "id": "810606",
    "regency_id": "8106",
    "label": "Inamosol",
    "value": "Inamosol"
  },
  {
    "id": "810607",
    "regency_id": "8106",
    "label": "Kairatu Barat",
    "value": "Kairatu Barat"
  },
  {
    "id": "810608",
    "regency_id": "8106",
    "label": "Huamual",
    "value": "Huamual"
  },
  {
    "id": "810609",
    "regency_id": "8106",
    "label": "Kepulauan Manipa",
    "value": "Kepulauan Manipa"
  },
  {
    "id": "810610",
    "regency_id": "8106",
    "label": "Taniwel Timur",
    "value": "Taniwel Timur"
  },
  {
    "id": "810611",
    "regency_id": "8106",
    "label": "Elpaputih",
    "value": "Elpaputih"
  },
  {
    "id": "810701",
    "regency_id": "8107",
    "label": "Pulau-Pulau Aru",
    "value": "Pulau-Pulau Aru"
  },
  {
    "id": "810702",
    "regency_id": "8107",
    "label": "Aru Selatan",
    "value": "Aru Selatan"
  },
  {
    "id": "810703",
    "regency_id": "8107",
    "label": "Aru Tengah",
    "value": "Aru Tengah"
  },
  {
    "id": "810704",
    "regency_id": "8107",
    "label": "Aru Utara",
    "value": "Aru Utara"
  },
  {
    "id": "810705",
    "regency_id": "8107",
    "label": "Aru Utara Timur Batuley",
    "value": "Aru Utara Timur Batuley"
  },
  {
    "id": "810706",
    "regency_id": "8107",
    "label": "Sir-Sir",
    "value": "Sir-Sir"
  },
  {
    "id": "810707",
    "regency_id": "8107",
    "label": "Aru Tengah Timur",
    "value": "Aru Tengah Timur"
  },
  {
    "id": "810708",
    "regency_id": "8107",
    "label": "Aru Tengah Selatan",
    "value": "Aru Tengah Selatan"
  },
  {
    "id": "810709",
    "regency_id": "8107",
    "label": "Aru Selatan Timur",
    "value": "Aru Selatan Timur"
  },
  {
    "id": "810710",
    "regency_id": "8107",
    "label": "Aru Selatan Utara",
    "value": "Aru Selatan Utara"
  },
  {
    "id": "810801",
    "regency_id": "8108",
    "label": "Moa Lakor",
    "value": "Moa Lakor"
  },
  { "id": "810802", "regency_id": "8108", "label": "Damer", "value": "Damer" },
  {
    "id": "810803",
    "regency_id": "8108",
    "label": "Mndona Hiera",
    "value": "Mndona Hiera"
  },
  {
    "id": "810804",
    "regency_id": "8108",
    "label": "Pulau-Pulau Babar",
    "value": "Pulau-Pulau Babar"
  },
  {
    "id": "810805",
    "regency_id": "8108",
    "label": "Pulau-pulau Babar Timur",
    "value": "Pulau-pulau Babar Timur"
  },
  { "id": "810806", "regency_id": "8108", "label": "Wetar", "value": "Wetar" },
  {
    "id": "810807",
    "regency_id": "8108",
    "label": "Pulau-pulau Terselatan",
    "value": "Pulau-pulau Terselatan"
  },
  {
    "id": "810808",
    "regency_id": "8108",
    "label": "Pulau Leti",
    "value": "Pulau Leti"
  },
  {
    "id": "810809",
    "regency_id": "8108",
    "label": "Pulau Masela",
    "value": "Pulau Masela"
  },
  {
    "id": "810810",
    "regency_id": "8108",
    "label": "Dawelor Dawera",
    "value": "Dawelor Dawera"
  },
  {
    "id": "810811",
    "regency_id": "8108",
    "label": "Pulau Wetang",
    "value": "Pulau Wetang"
  },
  {
    "id": "810812",
    "regency_id": "8108",
    "label": "Pulau Lakor",
    "value": "Pulau Lakor"
  },
  {
    "id": "810813",
    "regency_id": "8108",
    "label": "Wetar Utara",
    "value": "Wetar Utara"
  },
  {
    "id": "810814",
    "regency_id": "8108",
    "label": "Wetar Barat",
    "value": "Wetar Barat"
  },
  {
    "id": "810815",
    "regency_id": "8108",
    "label": "Wetar Timur",
    "value": "Wetar Timur"
  },
  {
    "id": "810816",
    "regency_id": "8108",
    "label": "Kepulauan Romang",
    "value": "Kepulauan Romang"
  },
  {
    "id": "810817",
    "regency_id": "8108",
    "label": "Kisar Utara",
    "value": "Kisar Utara"
  },
  {
    "id": "810901",
    "regency_id": "8109",
    "label": "Namrole",
    "value": "Namrole"
  },
  {
    "id": "810902",
    "regency_id": "8109",
    "label": "Waesama",
    "value": "Waesama"
  },
  {
    "id": "810903",
    "regency_id": "8109",
    "label": "Ambalau",
    "value": "Ambalau"
  },
  {
    "id": "810904",
    "regency_id": "8109",
    "label": "Kepala Madan",
    "value": "Kepala Madan"
  },
  {
    "id": "810905",
    "regency_id": "8109",
    "label": "Leksula",
    "value": "Leksula"
  },
  {
    "id": "810906",
    "regency_id": "8109",
    "label": "Fena Fafan",
    "value": "Fena Fafan"
  },
  {
    "id": "817101",
    "regency_id": "8171",
    "label": "Nusaniwe",
    "value": "Nusaniwe"
  },
  {
    "id": "817102",
    "regency_id": "8171",
    "label": "Sirimau",
    "value": "Sirimau"
  },
  {
    "id": "817103",
    "regency_id": "8171",
    "label": "Baguala",
    "value": "Baguala"
  },
  {
    "id": "817104",
    "regency_id": "8171",
    "label": "Teluk Ambon",
    "value": "Teluk Ambon"
  },
  {
    "id": "817105",
    "regency_id": "8171",
    "label": "Leitimur Selatan",
    "value": "Leitimur Selatan"
  },
  {
    "id": "817201",
    "regency_id": "8172",
    "label": "Pulau Dullah Utara",
    "value": "Pulau Dullah Utara"
  },
  {
    "id": "817202",
    "regency_id": "8172",
    "label": "Pulau Dullah Selatan",
    "value": "Pulau Dullah Selatan"
  },
  {
    "id": "817203",
    "regency_id": "8172",
    "label": "Tayando Tam",
    "value": "Tayando Tam"
  },
  {
    "id": "817204",
    "regency_id": "8172",
    "label": "Pulau-Pulau Kur",
    "value": "Pulau-Pulau Kur"
  },
  {
    "id": "817205",
    "regency_id": "8172",
    "label": "Kur Selatan",
    "value": "Kur Selatan"
  },
  {
    "id": "820101",
    "regency_id": "8201",
    "label": "Jailolo",
    "value": "Jailolo"
  },
  {
    "id": "820102",
    "regency_id": "8201",
    "label": "Loloda",
    "value": "Loloda"
  },
  { "id": "820103", "regency_id": "8201", "label": "Ibu", "value": "Ibu" },
  { "id": "820104", "regency_id": "8201", "label": "Sahu", "value": "Sahu" },
  {
    "id": "820105",
    "regency_id": "8201",
    "label": "Jailolo Selatan",
    "value": "Jailolo Selatan"
  },
  {
    "id": "820107",
    "regency_id": "8201",
    "label": "Ibu Utara",
    "value": "Ibu Utara"
  },
  {
    "id": "820108",
    "regency_id": "8201",
    "label": "Ibu Selatan",
    "value": "Ibu Selatan"
  },
  {
    "id": "820109",
    "regency_id": "8201",
    "label": "Sahu Timur",
    "value": "Sahu Timur"
  },
  { "id": "820201", "regency_id": "8202", "label": "Weda", "value": "Weda" },
  {
    "id": "820202",
    "regency_id": "8202",
    "label": "Patani",
    "value": "Patani"
  },
  {
    "id": "820203",
    "regency_id": "8202",
    "label": "Pulau Gebe",
    "value": "Pulau Gebe"
  },
  {
    "id": "820204",
    "regency_id": "8202",
    "label": "Weda Utara",
    "value": "Weda Utara"
  },
  {
    "id": "820205",
    "regency_id": "8202",
    "label": "Weda Selatan",
    "value": "Weda Selatan"
  },
  {
    "id": "820206",
    "regency_id": "8202",
    "label": "Patani Utara",
    "value": "Patani Utara"
  },
  {
    "id": "820207",
    "regency_id": "8202",
    "label": "Weda Tengah",
    "value": "Weda Tengah"
  },
  {
    "id": "820208",
    "regency_id": "8202",
    "label": "Patani Barat",
    "value": "Patani Barat"
  },
  {
    "id": "820209",
    "regency_id": "8202",
    "label": "Weda Timur",
    "value": "Weda Timur"
  },
  {
    "id": "820210",
    "regency_id": "8202",
    "label": "Patani Timur",
    "value": "Patani Timur"
  },
  {
    "id": "820304",
    "regency_id": "8203",
    "label": "Galela",
    "value": "Galela"
  },
  {
    "id": "820305",
    "regency_id": "8203",
    "label": "Tobelo",
    "value": "Tobelo"
  },
  {
    "id": "820306",
    "regency_id": "8203",
    "label": "Tobelo Selatan",
    "value": "Tobelo Selatan"
  },
  { "id": "820307", "regency_id": "8203", "label": "Kao", "value": "Kao" },
  {
    "id": "820308",
    "regency_id": "8203",
    "label": "Malifut",
    "value": "Malifut"
  },
  {
    "id": "820309",
    "regency_id": "8203",
    "label": "Loloda Utara",
    "value": "Loloda Utara"
  },
  {
    "id": "820310",
    "regency_id": "8203",
    "label": "Tobelo Utara",
    "value": "Tobelo Utara"
  },
  {
    "id": "820311",
    "regency_id": "8203",
    "label": "Tobelo Tengah",
    "value": "Tobelo Tengah"
  },
  {
    "id": "820312",
    "regency_id": "8203",
    "label": "Tobelo Timur",
    "value": "Tobelo Timur"
  },
  {
    "id": "820313",
    "regency_id": "8203",
    "label": "Tobelo Barat",
    "value": "Tobelo Barat"
  },
  {
    "id": "820314",
    "regency_id": "8203",
    "label": "Galela Barat",
    "value": "Galela Barat"
  },
  {
    "id": "820315",
    "regency_id": "8203",
    "label": "Galela Utara",
    "value": "Galela Utara"
  },
  {
    "id": "820316",
    "regency_id": "8203",
    "label": "Galela Selatan",
    "value": "Galela Selatan"
  },
  {
    "id": "820319",
    "regency_id": "8203",
    "label": "Loloda Kepulauan",
    "value": "Loloda Kepulauan"
  },
  {
    "id": "820320",
    "regency_id": "8203",
    "label": "Kao Utara",
    "value": "Kao Utara"
  },
  {
    "id": "820321",
    "regency_id": "8203",
    "label": "Kao Barat",
    "value": "Kao Barat"
  },
  {
    "id": "820322",
    "regency_id": "8203",
    "label": "Kao Teluk",
    "value": "Kao Teluk"
  },
  {
    "id": "820401",
    "regency_id": "8204",
    "label": "Pulau Makian",
    "value": "Pulau Makian"
  },
  { "id": "820402", "regency_id": "8204", "label": "Kayoa", "value": "Kayoa" },
  {
    "id": "820403",
    "regency_id": "8204",
    "label": "Gane Timur",
    "value": "Gane Timur"
  },
  {
    "id": "820404",
    "regency_id": "8204",
    "label": "Gane Barat",
    "value": "Gane Barat"
  },
  {
    "id": "820405",
    "regency_id": "8204",
    "label": "Obi Selatan",
    "value": "Obi Selatan"
  },
  { "id": "820406", "regency_id": "8204", "label": "Obi", "value": "Obi" },
  {
    "id": "820407",
    "regency_id": "8204",
    "label": "Bacan Timur",
    "value": "Bacan Timur"
  },
  { "id": "820408", "regency_id": "8204", "label": "Bacan", "value": "Bacan" },
  {
    "id": "820409",
    "regency_id": "8204",
    "label": "Bacan Barat",
    "value": "Bacan Barat"
  },
  {
    "id": "820410",
    "regency_id": "8204",
    "label": "Makian Barat",
    "value": "Makian Barat"
  },
  {
    "id": "820411",
    "regency_id": "8204",
    "label": "Kayoa Barat",
    "value": "Kayoa Barat"
  },
  {
    "id": "820412",
    "regency_id": "8204",
    "label": "Kayoa Selatan",
    "value": "Kayoa Selatan"
  },
  {
    "id": "820413",
    "regency_id": "8204",
    "label": "Kayoa Utara",
    "value": "Kayoa Utara"
  },
  {
    "id": "820414",
    "regency_id": "8204",
    "label": "Bacan Barat Utara",
    "value": "Bacan Barat Utara"
  },
  {
    "id": "820415",
    "regency_id": "8204",
    "label": "Kasiruta Barat",
    "value": "Kasiruta Barat"
  },
  {
    "id": "820416",
    "regency_id": "8204",
    "label": "Kasiruta Timur",
    "value": "Kasiruta Timur"
  },
  {
    "id": "820417",
    "regency_id": "8204",
    "label": "Bacan Selatan",
    "value": "Bacan Selatan"
  },
  {
    "id": "820418",
    "regency_id": "8204",
    "label": "Kepulauan Botanglomang",
    "value": "Kepulauan Botanglomang"
  },
  {
    "id": "820419",
    "regency_id": "8204",
    "label": "Mandioli Selatan",
    "value": "Mandioli Selatan"
  },
  {
    "id": "820420",
    "regency_id": "8204",
    "label": "Mandioli Utara",
    "value": "Mandioli Utara"
  },
  {
    "id": "820421",
    "regency_id": "8204",
    "label": "Bacan Timur Selatan",
    "value": "Bacan Timur Selatan"
  },
  {
    "id": "820422",
    "regency_id": "8204",
    "label": "Bacan Timur Tengah",
    "value": "Bacan Timur Tengah"
  },
  {
    "id": "820423",
    "regency_id": "8204",
    "label": "Gane Barat Selatan",
    "value": "Gane Barat Selatan"
  },
  {
    "id": "820424",
    "regency_id": "8204",
    "label": "Gane Barat Utara",
    "value": "Gane Barat Utara"
  },
  {
    "id": "820425",
    "regency_id": "8204",
    "label": "Kepulauan Joronga",
    "value": "Kepulauan Joronga"
  },
  {
    "id": "820426",
    "regency_id": "8204",
    "label": "Gane Timur Selatan",
    "value": "Gane Timur Selatan"
  },
  {
    "id": "820427",
    "regency_id": "8204",
    "label": "Gane Timur Tengah",
    "value": "Gane Timur Tengah"
  },
  {
    "id": "820428",
    "regency_id": "8204",
    "label": "Obi Barat",
    "value": "Obi Barat"
  },
  {
    "id": "820429",
    "regency_id": "8204",
    "label": "Obi Timur",
    "value": "Obi Timur"
  },
  {
    "id": "820430",
    "regency_id": "8204",
    "label": "Obi Utara",
    "value": "Obi Utara"
  },
  {
    "id": "820501",
    "regency_id": "8205",
    "label": "Mangoli Timur",
    "value": "Mangoli Timur"
  },
  {
    "id": "820502",
    "regency_id": "8205",
    "label": "Sanana",
    "value": "Sanana"
  },
  {
    "id": "820503",
    "regency_id": "8205",
    "label": "Sulabesi Barat",
    "value": "Sulabesi Barat"
  },
  {
    "id": "820506",
    "regency_id": "8205",
    "label": "Mangoli Barat",
    "value": "Mangoli Barat"
  },
  {
    "id": "820507",
    "regency_id": "8205",
    "label": "Sulabesi Tengah",
    "value": "Sulabesi Tengah"
  },
  {
    "id": "820508",
    "regency_id": "8205",
    "label": "Sulabesi Timur",
    "value": "Sulabesi Timur"
  },
  {
    "id": "820509",
    "regency_id": "8205",
    "label": "Sulabesi Selatan",
    "value": "Sulabesi Selatan"
  },
  {
    "id": "820510",
    "regency_id": "8205",
    "label": "Mangoli Utara Timur",
    "value": "Mangoli Utara Timur"
  },
  {
    "id": "820511",
    "regency_id": "8205",
    "label": "Mangoli Tengah",
    "value": "Mangoli Tengah"
  },
  {
    "id": "820512",
    "regency_id": "8205",
    "label": "Mangoli Selatan",
    "value": "Mangoli Selatan"
  },
  {
    "id": "820513",
    "regency_id": "8205",
    "label": "Mangoli Utara",
    "value": "Mangoli Utara"
  },
  {
    "id": "820518",
    "regency_id": "8205",
    "label": "Sanana Utara",
    "value": "Sanana Utara"
  },
  {
    "id": "820601",
    "regency_id": "8206",
    "label": "Wasile",
    "value": "Wasile"
  },
  { "id": "820602", "regency_id": "8206", "label": "Maba", "value": "Maba" },
  {
    "id": "820603",
    "regency_id": "8206",
    "label": "Maba Selatan",
    "value": "Maba Selatan"
  },
  {
    "id": "820604",
    "regency_id": "8206",
    "label": "Wasile Selatan",
    "value": "Wasile Selatan"
  },
  {
    "id": "820605",
    "regency_id": "8206",
    "label": "Wasile Tengah",
    "value": "Wasile Tengah"
  },
  {
    "id": "820606",
    "regency_id": "8206",
    "label": "Wasile Utara",
    "value": "Wasile Utara"
  },
  {
    "id": "820607",
    "regency_id": "8206",
    "label": "Wasile Timur",
    "value": "Wasile Timur"
  },
  {
    "id": "820608",
    "regency_id": "8206",
    "label": "Maba Tengah",
    "value": "Maba Tengah"
  },
  {
    "id": "820609",
    "regency_id": "8206",
    "label": "Maba Utara",
    "value": "Maba Utara"
  },
  {
    "id": "820610",
    "regency_id": "8206",
    "label": "Kota Maba",
    "value": "Kota Maba"
  },
  {
    "id": "820701",
    "regency_id": "8207",
    "label": "Morotai Selatan",
    "value": "Morotai Selatan"
  },
  {
    "id": "820702",
    "regency_id": "8207",
    "label": "Morotai Selatan Barat",
    "value": "Morotai Selatan Barat"
  },
  {
    "id": "820703",
    "regency_id": "8207",
    "label": "Morotai Jaya",
    "value": "Morotai Jaya"
  },
  {
    "id": "820704",
    "regency_id": "8207",
    "label": "Morotai Utara",
    "value": "Morotai Utara"
  },
  {
    "id": "820705",
    "regency_id": "8207",
    "label": "Morotai Timur",
    "value": "Morotai Timur"
  },
  {
    "id": "820801",
    "regency_id": "8208",
    "label": "Taliabu Barat",
    "value": "Taliabu Barat"
  },
  {
    "id": "820802",
    "regency_id": "8208",
    "label": "Taliabu Barat Laut",
    "value": "Taliabu Barat Laut"
  },
  { "id": "820803", "regency_id": "8208", "label": "Lede", "value": "Lede" },
  {
    "id": "820804",
    "regency_id": "8208",
    "label": "Taliabu Utara",
    "value": "Taliabu Utara"
  },
  {
    "id": "820805",
    "regency_id": "8208",
    "label": "Taliabu Timur",
    "value": "Taliabu Timur"
  },
  {
    "id": "820806",
    "regency_id": "8208",
    "label": "Taliabu Timur Selatan",
    "value": "Taliabu Timur Selatan"
  },
  {
    "id": "820807",
    "regency_id": "8208",
    "label": "Taliabu Selatan",
    "value": "Taliabu Selatan"
  },
  {
    "id": "820808",
    "regency_id": "8208",
    "label": "Tabona",
    "value": "Tabona"
  },
  {
    "id": "827101",
    "regency_id": "8271",
    "label": "Pulau Ternate",
    "value": "Pulau Ternate"
  },
  {
    "id": "827102",
    "regency_id": "8271",
    "label": "Kota Ternate Selatan",
    "value": "Kota Ternate Selatan"
  },
  {
    "id": "827103",
    "regency_id": "8271",
    "label": "Kota Ternate Utara",
    "value": "Kota Ternate Utara"
  },
  { "id": "827104", "regency_id": "8271", "label": "Moti", "value": "Moti" },
  {
    "id": "827105",
    "regency_id": "8271",
    "label": "Pulau Batang Dua",
    "value": "Pulau Batang Dua"
  },
  {
    "id": "827106",
    "regency_id": "8271",
    "label": "Kota Ternate Tengah",
    "value": "Kota Ternate Tengah"
  },
  {
    "id": "827107",
    "regency_id": "8271",
    "label": "Pulau Hiri",
    "value": "Pulau Hiri"
  },
  {
    "id": "827108",
    "regency_id": "8271",
    "label": "Ternate Barat",
    "value": "Ternate Barat"
  },
  {
    "id": "827201",
    "regency_id": "8272",
    "label": "Tidore",
    "value": "Tidore"
  },
  {
    "id": "827202",
    "regency_id": "8272",
    "label": "Oba Utara",
    "value": "Oba Utara"
  },
  { "id": "827203", "regency_id": "8272", "label": "Oba", "value": "Oba" },
  {
    "id": "827204",
    "regency_id": "8272",
    "label": "Tidore Selatan",
    "value": "Tidore Selatan"
  },
  {
    "id": "827205",
    "regency_id": "8272",
    "label": "Tidore Utara",
    "value": "Tidore Utara"
  },
  {
    "id": "827206",
    "regency_id": "8272",
    "label": "Oba Tengah",
    "value": "Oba Tengah"
  },
  {
    "id": "827207",
    "regency_id": "8272",
    "label": "Oba Selatan",
    "value": "Oba Selatan"
  },
  {
    "id": "827208",
    "regency_id": "8272",
    "label": "Tidore Timur",
    "value": "Tidore Timur"
  },
  {
    "id": "910101",
    "regency_id": "9101",
    "label": "Merauke",
    "value": "Merauke"
  },
  {
    "id": "910102",
    "regency_id": "9101",
    "label": "Muting",
    "value": "Muting"
  },
  { "id": "910103", "regency_id": "9101", "label": "Okaba", "value": "Okaba" },
  {
    "id": "910104",
    "regency_id": "9101",
    "label": "Kimaam",
    "value": "Kimaam"
  },
  {
    "id": "910105",
    "regency_id": "9101",
    "label": "Semangga",
    "value": "Semangga"
  },
  {
    "id": "910106",
    "regency_id": "9101",
    "label": "Tanah Miring",
    "value": "Tanah Miring"
  },
  {
    "id": "910107",
    "regency_id": "9101",
    "label": "Jagebob",
    "value": "Jagebob"
  },
  { "id": "910108", "regency_id": "9101", "label": "Sota", "value": "Sota" },
  {
    "id": "910109",
    "regency_id": "9101",
    "label": "Ulilin",
    "value": "Ulilin"
  },
  {
    "id": "910110",
    "regency_id": "9101",
    "label": "Elikobal",
    "value": "Elikobal"
  },
  { "id": "910111", "regency_id": "9101", "label": "Kurik", "value": "Kurik" },
  {
    "id": "910112",
    "regency_id": "9101",
    "label": "Naukenjerai",
    "value": "Naukenjerai"
  },
  {
    "id": "910113",
    "regency_id": "9101",
    "label": "Animha",
    "value": "Animha"
  },
  {
    "id": "910114",
    "regency_id": "9101",
    "label": "Malind",
    "value": "Malind"
  },
  {
    "id": "910115",
    "regency_id": "9101",
    "label": "Tubang",
    "value": "Tubang"
  },
  {
    "id": "910116",
    "regency_id": "9101",
    "label": "Ngguti",
    "value": "Ngguti"
  },
  {
    "id": "910117",
    "regency_id": "9101",
    "label": "Kaptel",
    "value": "Kaptel"
  },
  {
    "id": "910118",
    "regency_id": "9101",
    "label": "Tabonji",
    "value": "Tabonji"
  },
  { "id": "910119", "regency_id": "9101", "label": "Waan", "value": "Waan" },
  {
    "id": "910120",
    "regency_id": "9101",
    "label": "Ilwayab",
    "value": "Ilwayab"
  },
  {
    "id": "910201",
    "regency_id": "9102",
    "label": "Wamena",
    "value": "Wamena"
  },
  {
    "id": "910203",
    "regency_id": "9102",
    "label": "Kurulu",
    "value": "Kurulu"
  },
  {
    "id": "910204",
    "regency_id": "9102",
    "label": "Asologaima",
    "value": "Asologaima"
  },
  {
    "id": "910212",
    "regency_id": "9102",
    "label": "Hubikosi",
    "value": "Hubikosi"
  },
  {
    "id": "910215",
    "regency_id": "9102",
    "label": "Bolakme",
    "value": "Bolakme"
  },
  {
    "id": "910225",
    "regency_id": "9102",
    "label": "Walelagama",
    "value": "Walelagama"
  },
  {
    "id": "910227",
    "regency_id": "9102",
    "label": "Musatfak",
    "value": "Musatfak"
  },
  { "id": "910228", "regency_id": "9102", "label": "Wolo", "value": "Wolo" },
  {
    "id": "910229",
    "regency_id": "9102",
    "label": "Asolokobal",
    "value": "Asolokobal"
  },
  {
    "id": "910234",
    "regency_id": "9102",
    "label": "Pelebaga",
    "value": "Pelebaga"
  },
  {
    "id": "910235",
    "regency_id": "9102",
    "label": "Yalengga",
    "value": "Yalengga"
  },
  {
    "id": "910240",
    "regency_id": "9102",
    "label": "Trikora",
    "value": "Trikora"
  },
  { "id": "910241", "regency_id": "9102", "label": "Napua", "value": "Napua" },
  {
    "id": "910242",
    "regency_id": "9102",
    "label": "Walaik",
    "value": "Walaik"
  },
  { "id": "910243", "regency_id": "9102", "label": "Wouma", "value": "Wouma" },
  {
    "id": "910244",
    "regency_id": "9102",
    "label": "Hubikiak",
    "value": "Hubikiak"
  },
  { "id": "910245", "regency_id": "9102", "label": "Ibele", "value": "Ibele" },
  {
    "id": "910246",
    "regency_id": "9102",
    "label": "Taelarek",
    "value": "Taelarek"
  },
  {
    "id": "910247",
    "regency_id": "9102",
    "label": "Itlay Hisage",
    "value": "Itlay Hisage"
  },
  {
    "id": "910248",
    "regency_id": "9102",
    "label": "Siepkosi",
    "value": "Siepkosi"
  },
  {
    "id": "910249",
    "regency_id": "9102",
    "label": "Usilimo",
    "value": "Usilimo"
  },
  {
    "id": "910250",
    "regency_id": "9102",
    "label": "Wita Waya",
    "value": "Wita Waya"
  },
  {
    "id": "910251",
    "regency_id": "9102",
    "label": "Libarek",
    "value": "Libarek"
  },
  {
    "id": "910252",
    "regency_id": "9102",
    "label": "Wadangku",
    "value": "Wadangku"
  },
  {
    "id": "910253",
    "regency_id": "9102",
    "label": "Pisugi",
    "value": "Pisugi"
  },
  {
    "id": "910254",
    "regency_id": "9102",
    "label": "Koragi",
    "value": "Koragi"
  },
  {
    "id": "910255",
    "regency_id": "9102",
    "label": "Tagime",
    "value": "Tagime"
  },
  {
    "id": "910256",
    "regency_id": "9102",
    "label": "Molagalome",
    "value": "Molagalome"
  },
  {
    "id": "910257",
    "regency_id": "9102",
    "label": "Tagineri",
    "value": "Tagineri"
  },
  {
    "id": "910258",
    "regency_id": "9102",
    "label": "Silo Karno Doga",
    "value": "Silo Karno Doga"
  },
  {
    "id": "910259",
    "regency_id": "9102",
    "label": "Piramid",
    "value": "Piramid"
  },
  {
    "id": "910260",
    "regency_id": "9102",
    "label": "Muliama",
    "value": "Muliama"
  },
  { "id": "910261", "regency_id": "9102", "label": "Bugi", "value": "Bugi" },
  { "id": "910262", "regency_id": "9102", "label": "Bpiri", "value": "Bpiri" },
  {
    "id": "910263",
    "regency_id": "9102",
    "label": "Welesi",
    "value": "Welesi"
  },
  {
    "id": "910264",
    "regency_id": "9102",
    "label": "Asotipo",
    "value": "Asotipo"
  },
  { "id": "910265", "regency_id": "9102", "label": "Maima", "value": "Maima" },
  {
    "id": "910266",
    "regency_id": "9102",
    "label": "Popugoba",
    "value": "Popugoba"
  },
  { "id": "910267", "regency_id": "9102", "label": "Wame", "value": "Wame" },
  {
    "id": "910268",
    "regency_id": "9102",
    "label": "Wesaput",
    "value": "Wesaput"
  },
  {
    "id": "910301",
    "regency_id": "9103",
    "label": "Sentani",
    "value": "Sentani"
  },
  {
    "id": "910302",
    "regency_id": "9103",
    "label": "Sentani Timur",
    "value": "Sentani Timur"
  },
  {
    "id": "910303",
    "regency_id": "9103",
    "label": "Depapre",
    "value": "Depapre"
  },
  {
    "id": "910304",
    "regency_id": "9103",
    "label": "Sentani Barat",
    "value": "Sentani Barat"
  },
  {
    "id": "910305",
    "regency_id": "9103",
    "label": "Kemtuk",
    "value": "Kemtuk"
  },
  {
    "id": "910306",
    "regency_id": "9103",
    "label": "Kemtuk Gresi",
    "value": "Kemtuk Gresi"
  },
  {
    "id": "910307",
    "regency_id": "9103",
    "label": "Nimboran",
    "value": "Nimboran"
  },
  {
    "id": "910308",
    "regency_id": "9103",
    "label": "Nimbokrang",
    "value": "Nimbokrang"
  },
  {
    "id": "910309",
    "regency_id": "9103",
    "label": "Unurum Guay",
    "value": "Unurum Guay"
  },
  { "id": "910310", "regency_id": "9103", "label": "Demta", "value": "Demta" },
  {
    "id": "910311",
    "regency_id": "9103",
    "label": "Kaureh",
    "value": "Kaureh"
  },
  {
    "id": "910312",
    "regency_id": "9103",
    "label": "Ebungfao",
    "value": "Ebungfao"
  },
  { "id": "910313", "regency_id": "9103", "label": "Waibu", "value": "Waibu" },
  {
    "id": "910314",
    "regency_id": "9103",
    "label": "Nambluong",
    "value": "Nambluong"
  },
  { "id": "910315", "regency_id": "9103", "label": "Yapsi", "value": "Yapsi" },
  { "id": "910316", "regency_id": "9103", "label": "Airu", "value": "Airu" },
  {
    "id": "910317",
    "regency_id": "9103",
    "label": "Raveni Rara",
    "value": "Raveni Rara"
  },
  {
    "id": "910318",
    "regency_id": "9103",
    "label": "Gresi Selatan",
    "value": "Gresi Selatan"
  },
  {
    "id": "910319",
    "regency_id": "9103",
    "label": "Yokari",
    "value": "Yokari"
  },
  {
    "id": "910401",
    "regency_id": "9104",
    "label": "Nabire",
    "value": "Nabire"
  },
  { "id": "910402", "regency_id": "9104", "label": "Napan", "value": "Napan" },
  { "id": "910403", "regency_id": "9104", "label": "Yaur", "value": "Yaur" },
  { "id": "910406", "regency_id": "9104", "label": "Uwapa", "value": "Uwapa" },
  {
    "id": "910407",
    "regency_id": "9104",
    "label": "Wanggar",
    "value": "Wanggar"
  },
  {
    "id": "910410",
    "regency_id": "9104",
    "label": "Siriwo",
    "value": "Siriwo"
  },
  {
    "id": "910411",
    "regency_id": "9104",
    "label": "Makimi",
    "value": "Makimi"
  },
  {
    "id": "910412",
    "regency_id": "9104",
    "label": "Teluk Umar",
    "value": "Teluk Umar"
  },
  {
    "id": "910416",
    "regency_id": "9104",
    "label": "Teluk Kimi",
    "value": "Teluk Kimi"
  },
  { "id": "910417", "regency_id": "9104", "label": "Yaro", "value": "Yaro" },
  {
    "id": "910421",
    "regency_id": "9104",
    "label": "Wapoga",
    "value": "Wapoga"
  },
  {
    "id": "910422",
    "regency_id": "9104",
    "label": "Nabire Barat",
    "value": "Nabire Barat"
  },
  { "id": "910423", "regency_id": "9104", "label": "Moora", "value": "Moora" },
  { "id": "910424", "regency_id": "9104", "label": "Dipa", "value": "Dipa" },
  { "id": "910425", "regency_id": "9104", "label": "Menou", "value": "Menou" },
  {
    "id": "910501",
    "regency_id": "9105",
    "label": "Yapen Selatan",
    "value": "Yapen Selatan"
  },
  {
    "id": "910502",
    "regency_id": "9105",
    "label": "Yapen Barat",
    "value": "Yapen Barat"
  },
  {
    "id": "910503",
    "regency_id": "9105",
    "label": "Yapen Timur",
    "value": "Yapen Timur"
  },
  {
    "id": "910504",
    "regency_id": "9105",
    "label": "Angkaisera",
    "value": "Angkaisera"
  },
  { "id": "910505", "regency_id": "9105", "label": "Poom", "value": "Poom" },
  {
    "id": "910506",
    "regency_id": "9105",
    "label": "Kosiwo",
    "value": "Kosiwo"
  },
  {
    "id": "910507",
    "regency_id": "9105",
    "label": "Yapen Utara",
    "value": "Yapen Utara"
  },
  {
    "id": "910508",
    "regency_id": "9105",
    "label": "Raimbawi",
    "value": "Raimbawi"
  },
  {
    "id": "910509",
    "regency_id": "9105",
    "label": "Teluk Ampimoi",
    "value": "Teluk Ampimoi"
  },
  {
    "id": "910510",
    "regency_id": "9105",
    "label": "Kepulauan Ambai",
    "value": "Kepulauan Ambai"
  },
  {
    "id": "910511",
    "regency_id": "9105",
    "label": "Wonawa",
    "value": "Wonawa"
  },
  {
    "id": "910512",
    "regency_id": "9105",
    "label": "Windesi",
    "value": "Windesi"
  },
  {
    "id": "910513",
    "regency_id": "9105",
    "label": "Pulau Kurudu",
    "value": "Pulau Kurudu"
  },
  {
    "id": "910514",
    "regency_id": "9105",
    "label": "Pulau Yerui",
    "value": "Pulau Yerui"
  },
  {
    "id": "910515",
    "regency_id": "9105",
    "label": "Anotaurei",
    "value": "Anotaurei"
  },
  {
    "id": "910516",
    "regency_id": "9105",
    "label": "Yawakukat",
    "value": "Yawakukat"
  },
  {
    "id": "910601",
    "regency_id": "9106",
    "label": "Biak Kota",
    "value": "Biak Kota"
  },
  {
    "id": "910602",
    "regency_id": "9106",
    "label": "Biak Utara",
    "value": "Biak Utara"
  },
  {
    "id": "910603",
    "regency_id": "9106",
    "label": "Biak Timur",
    "value": "Biak Timur"
  },
  {
    "id": "910604",
    "regency_id": "9106",
    "label": "Numfor Barat",
    "value": "Numfor Barat"
  },
  {
    "id": "910605",
    "regency_id": "9106",
    "label": "Numfor Timur",
    "value": "Numfor Timur"
  },
  {
    "id": "910608",
    "regency_id": "9106",
    "label": "Biak Barat",
    "value": "Biak Barat"
  },
  { "id": "910609", "regency_id": "9106", "label": "Warsa", "value": "Warsa" },
  {
    "id": "910610",
    "regency_id": "9106",
    "label": "Padaido",
    "value": "Padaido"
  },
  {
    "id": "910611",
    "regency_id": "9106",
    "label": "Yendidori",
    "value": "Yendidori"
  },
  {
    "id": "910612",
    "regency_id": "9106",
    "label": "Samofa",
    "value": "Samofa"
  },
  {
    "id": "910613",
    "regency_id": "9106",
    "label": "Yawosi",
    "value": "Yawosi"
  },
  { "id": "910614", "regency_id": "9106", "label": "Andey", "value": "Andey" },
  {
    "id": "910615",
    "regency_id": "9106",
    "label": "Swandiwe",
    "value": "Swandiwe"
  },
  {
    "id": "910616",
    "regency_id": "9106",
    "label": "Bruyadori",
    "value": "Bruyadori"
  },
  {
    "id": "910617",
    "regency_id": "9106",
    "label": "Orkeri",
    "value": "Orkeri"
  },
  { "id": "910618", "regency_id": "9106", "label": "Poiru", "value": "Poiru" },
  {
    "id": "910619",
    "regency_id": "9106",
    "label": "Aimando Padaido",
    "value": "Aimando Padaido"
  },
  {
    "id": "910620",
    "regency_id": "9106",
    "label": "Oridek",
    "value": "Oridek"
  },
  {
    "id": "910621",
    "regency_id": "9106",
    "label": "Bondifuar",
    "value": "Bondifuar"
  },
  { "id": "910701", "regency_id": "9107", "label": "Mulia", "value": "Mulia" },
  { "id": "910703", "regency_id": "9107", "label": "Ilu", "value": "Ilu" },
  { "id": "910706", "regency_id": "9107", "label": "Fawi", "value": "Fawi" },
  {
    "id": "910707",
    "regency_id": "9107",
    "label": "Mewoluk",
    "value": "Mewoluk"
  },
  { "id": "910708", "regency_id": "9107", "label": "Yamo", "value": "Yamo" },
  { "id": "910710", "regency_id": "9107", "label": "Nume", "value": "Nume" },
  {
    "id": "910711",
    "regency_id": "9107",
    "label": "Torere",
    "value": "Torere"
  },
  {
    "id": "910712",
    "regency_id": "9107",
    "label": "Tingginambut",
    "value": "Tingginambut"
  },
  {
    "id": "910717",
    "regency_id": "9107",
    "label": "Pagaleme",
    "value": "Pagaleme"
  },
  {
    "id": "910718",
    "regency_id": "9107",
    "label": "Gurage",
    "value": "Gurage"
  },
  {
    "id": "910719",
    "regency_id": "9107",
    "label": "Irimuli",
    "value": "Irimuli"
  },
  { "id": "910720", "regency_id": "9107", "label": "Muara", "value": "Muara" },
  {
    "id": "910721",
    "regency_id": "9107",
    "label": "Ilamburawi",
    "value": "Ilamburawi"
  },
  { "id": "910722", "regency_id": "9107", "label": "Yambi", "value": "Yambi" },
  { "id": "910723", "regency_id": "9107", "label": "Lumo", "value": "Lumo" },
  {
    "id": "910724",
    "regency_id": "9107",
    "label": "Molanikime",
    "value": "Molanikime"
  },
  {
    "id": "910725",
    "regency_id": "9107",
    "label": "Dokome",
    "value": "Dokome"
  },
  {
    "id": "910726",
    "regency_id": "9107",
    "label": "Kalome",
    "value": "Kalome"
  },
  { "id": "910727", "regency_id": "9107", "label": "Wanwi", "value": "Wanwi" },
  {
    "id": "910728",
    "regency_id": "9107",
    "label": "Yamoneri",
    "value": "Yamoneri"
  },
  { "id": "910729", "regency_id": "9107", "label": "Waegi", "value": "Waegi" },
  { "id": "910730", "regency_id": "9107", "label": "Nioga", "value": "Nioga" },
  {
    "id": "910731",
    "regency_id": "9107",
    "label": "Gubume",
    "value": "Gubume"
  },
  {
    "id": "910732",
    "regency_id": "9107",
    "label": "Taganombak",
    "value": "Taganombak"
  },
  { "id": "910733", "regency_id": "9107", "label": "Dagai", "value": "Dagai" },
  {
    "id": "910734",
    "regency_id": "9107",
    "label": "Kiyage",
    "value": "Kiyage"
  },
  {
    "id": "910801",
    "regency_id": "9108",
    "label": "Paniai Timur",
    "value": "Paniai Timur"
  },
  {
    "id": "910802",
    "regency_id": "9108",
    "label": "Paniai Barat",
    "value": "Paniai Barat"
  },
  {
    "id": "910804",
    "regency_id": "9108",
    "label": "Aradide",
    "value": "Aradide"
  },
  {
    "id": "910807",
    "regency_id": "9108",
    "label": "Bogabaida",
    "value": "Bogabaida"
  },
  {
    "id": "910809",
    "regency_id": "9108",
    "label": "Bibida",
    "value": "Bibida"
  },
  {
    "id": "910812",
    "regency_id": "9108",
    "label": "Dumadama",
    "value": "Dumadama"
  },
  {
    "id": "910813",
    "regency_id": "9108",
    "label": "Siriwo",
    "value": "Siriwo"
  },
  { "id": "910819", "regency_id": "9108", "label": "Kebo", "value": "Kebo" },
  {
    "id": "910820",
    "regency_id": "9108",
    "label": "Yatamo",
    "value": "Yatamo"
  },
  {
    "id": "910821",
    "regency_id": "9108",
    "label": "Ekadide",
    "value": "Ekadide"
  },
  {
    "id": "910822",
    "regency_id": "9108",
    "label": "Wegee Muka",
    "value": "Wegee Muka"
  },
  {
    "id": "910823",
    "regency_id": "9108",
    "label": "Wegee Bino",
    "value": "Wegee Bino"
  },
  {
    "id": "910824",
    "regency_id": "9108",
    "label": "Pugo Dagi",
    "value": "Pugo Dagi"
  },
  { "id": "910825", "regency_id": "9108", "label": "Muye", "value": "Muye" },
  {
    "id": "910826",
    "regency_id": "9108",
    "label": "Nakama",
    "value": "Nakama"
  },
  {
    "id": "910827",
    "regency_id": "9108",
    "label": "Teluk Deya",
    "value": "Teluk Deya"
  },
  { "id": "910828", "regency_id": "9108", "label": "Yagai", "value": "Yagai" },
  {
    "id": "910829",
    "regency_id": "9108",
    "label": "Youtadi",
    "value": "Youtadi"
  },
  {
    "id": "910830",
    "regency_id": "9108",
    "label": "Baya Biru",
    "value": "Baya Biru"
  },
  {
    "id": "910831",
    "regency_id": "9108",
    "label": "Deiyai Miyo",
    "value": "Deiyai Miyo"
  },
  {
    "id": "910832",
    "regency_id": "9108",
    "label": "Dogomo",
    "value": "Dogomo"
  },
  {
    "id": "910833",
    "regency_id": "9108",
    "label": "Aweida",
    "value": "Aweida"
  },
  {
    "id": "910834",
    "regency_id": "9108",
    "label": "Topiyai",
    "value": "Topiyai"
  },
  {
    "id": "910901",
    "regency_id": "9109",
    "label": "Mimika Baru",
    "value": "Mimika Baru"
  },
  {
    "id": "910902",
    "regency_id": "9109",
    "label": "Agimuga",
    "value": "Agimuga"
  },
  {
    "id": "910903",
    "regency_id": "9109",
    "label": "Mimika Timur",
    "value": "Mimika Timur"
  },
  {
    "id": "910904",
    "regency_id": "9109",
    "label": "Mimika Barat",
    "value": "Mimika Barat"
  },
  { "id": "910905", "regency_id": "9109", "label": "Jita", "value": "Jita" },
  { "id": "910906", "regency_id": "9109", "label": "Jila", "value": "Jila" },
  {
    "id": "910907",
    "regency_id": "9109",
    "label": "Mimika Timur Jauh",
    "value": "Mimika Timur Jauh"
  },
  {
    "id": "910908",
    "regency_id": "9109",
    "label": "Mimika Tengah",
    "value": "Mimika Tengah"
  },
  {
    "id": "910909",
    "regency_id": "9109",
    "label": "Kuala Kencana",
    "value": "Kuala Kencana"
  },
  {
    "id": "910910",
    "regency_id": "9109",
    "label": "Tembagapura",
    "value": "Tembagapura"
  },
  {
    "id": "910911",
    "regency_id": "9109",
    "label": "Mimika Barat Jauh",
    "value": "Mimika Barat Jauh"
  },
  {
    "id": "910912",
    "regency_id": "9109",
    "label": "Mimika Barat Tengah",
    "value": "Mimika Barat Tengah"
  },
  {
    "id": "910913",
    "regency_id": "9109",
    "label": "Kwamki Narama",
    "value": "Kwamki Narama"
  },
  { "id": "910914", "regency_id": "9109", "label": "Hoya", "value": "Hoya" },
  { "id": "910915", "regency_id": "9109", "label": "Iwaka", "value": "Iwaka" },
  { "id": "910916", "regency_id": "9109", "label": "Wania", "value": "Wania" },
  { "id": "910917", "regency_id": "9109", "label": "Amar", "value": "Amar" },
  { "id": "910918", "regency_id": "9109", "label": "Alama", "value": "Alama" },
  { "id": "911001", "regency_id": "9110", "label": "Sarmi", "value": "Sarmi" },
  {
    "id": "911002",
    "regency_id": "9110",
    "label": "Tor Atas",
    "value": "Tor Atas"
  },
  {
    "id": "911003",
    "regency_id": "9110",
    "label": "Pantai Barat",
    "value": "Pantai Barat"
  },
  {
    "id": "911004",
    "regency_id": "9110",
    "label": "Pantai Timur",
    "value": "Pantai Timur"
  },
  {
    "id": "911005",
    "regency_id": "9110",
    "label": "Bonggo",
    "value": "Bonggo"
  },
  {
    "id": "911009",
    "regency_id": "9110",
    "label": "Apawer Hulu",
    "value": "Apawer Hulu"
  },
  {
    "id": "911012",
    "regency_id": "9110",
    "label": "Sarmi Selatan",
    "value": "Sarmi Selatan"
  },
  {
    "id": "911013",
    "regency_id": "9110",
    "label": "Sarmi Timur",
    "value": "Sarmi Timur"
  },
  {
    "id": "911014",
    "regency_id": "9110",
    "label": "Pantai Timur Bagian Barat",
    "value": "Pantai Timur Bagian Barat"
  },
  {
    "id": "911015",
    "regency_id": "9110",
    "label": "Bonggo Timur",
    "value": "Bonggo Timur"
  },
  { "id": "911101", "regency_id": "9111", "label": "Waris", "value": "Waris" },
  { "id": "911102", "regency_id": "9111", "label": "Arso", "value": "Arso" },
  {
    "id": "911103",
    "regency_id": "9111",
    "label": "Senggi",
    "value": "Senggi"
  },
  { "id": "911104", "regency_id": "9111", "label": "Web", "value": "Web" },
  {
    "id": "911105",
    "regency_id": "9111",
    "label": "Skanto",
    "value": "Skanto"
  },
  {
    "id": "911106",
    "regency_id": "9111",
    "label": "Arso Timur",
    "value": "Arso Timur"
  },
  { "id": "911107", "regency_id": "9111", "label": "Towe", "value": "Towe" },
  {
    "id": "911108",
    "regency_id": "9111",
    "label": "Arso Barat",
    "value": "Arso Barat"
  },
  {
    "id": "911109",
    "regency_id": "9111",
    "label": "Mannem",
    "value": "Mannem"
  },
  { "id": "911110", "regency_id": "9111", "label": "Yaffi", "value": "Yaffi" },
  {
    "id": "911111",
    "regency_id": "9111",
    "label": "Kaisenar",
    "value": "Kaisenar"
  },
  {
    "id": "911201",
    "regency_id": "9112",
    "label": "Oksibil",
    "value": "Oksibil"
  },
  {
    "id": "911202",
    "regency_id": "9112",
    "label": "Kiwirok",
    "value": "Kiwirok"
  },
  {
    "id": "911203",
    "regency_id": "9112",
    "label": "Okbibab",
    "value": "Okbibab"
  },
  { "id": "911204", "regency_id": "9112", "label": "Iwur", "value": "Iwur" },
  { "id": "911205", "regency_id": "9112", "label": "Batom", "value": "Batom" },
  { "id": "911206", "regency_id": "9112", "label": "Borme", "value": "Borme" },
  {
    "id": "911207",
    "regency_id": "9112",
    "label": "Kiwirok Timur",
    "value": "Kiwirok Timur"
  },
  { "id": "911208", "regency_id": "9112", "label": "Aboy", "value": "Aboy" },
  {
    "id": "911209",
    "regency_id": "9112",
    "label": "Pepera",
    "value": "Pepera"
  },
  { "id": "911210", "regency_id": "9112", "label": "Bime", "value": "Bime" },
  {
    "id": "911211",
    "regency_id": "9112",
    "label": "Alemsom",
    "value": "Alemsom"
  },
  {
    "id": "911212",
    "regency_id": "9112",
    "label": "Okbape",
    "value": "Okbape"
  },
  {
    "id": "911213",
    "regency_id": "9112",
    "label": "Kalomdol",
    "value": "Kalomdol"
  },
  { "id": "911214", "regency_id": "9112", "label": "Oksop", "value": "Oksop" },
  {
    "id": "911215",
    "regency_id": "9112",
    "label": "Serambakon",
    "value": "Serambakon"
  },
  {
    "id": "911216",
    "regency_id": "9112",
    "label": "Ok Aom",
    "value": "Ok Aom"
  },
  { "id": "911217", "regency_id": "9112", "label": "Kawor", "value": "Kawor" },
  {
    "id": "911218",
    "regency_id": "9112",
    "label": "Awinbon",
    "value": "Awinbon"
  },
  { "id": "911219", "regency_id": "9112", "label": "Tarup", "value": "Tarup" },
  {
    "id": "911220",
    "regency_id": "9112",
    "label": "Okhika",
    "value": "Okhika"
  },
  {
    "id": "911221",
    "regency_id": "9112",
    "label": "Oksamol",
    "value": "Oksamol"
  },
  { "id": "911222", "regency_id": "9112", "label": "Oklip", "value": "Oklip" },
  {
    "id": "911223",
    "regency_id": "9112",
    "label": "Okbemtau",
    "value": "Okbemtau"
  },
  {
    "id": "911224",
    "regency_id": "9112",
    "label": "Oksebang",
    "value": "Oksebang"
  },
  { "id": "911225", "regency_id": "9112", "label": "Okbab", "value": "Okbab" },
  {
    "id": "911226",
    "regency_id": "9112",
    "label": "Batani",
    "value": "Batani"
  },
  { "id": "911227", "regency_id": "9112", "label": "Weime", "value": "Weime" },
  {
    "id": "911228",
    "regency_id": "9112",
    "label": "Murkim",
    "value": "Murkim"
  },
  {
    "id": "911229",
    "regency_id": "9112",
    "label": "Mofinop",
    "value": "Mofinop"
  },
  { "id": "911230", "regency_id": "9112", "label": "Jetfa", "value": "Jetfa" },
  {
    "id": "911231",
    "regency_id": "9112",
    "label": "Teiraplu",
    "value": "Teiraplu"
  },
  {
    "id": "911232",
    "regency_id": "9112",
    "label": "Eipumek",
    "value": "Eipumek"
  },
  { "id": "911233", "regency_id": "9112", "label": "Pamek", "value": "Pamek" },
  {
    "id": "911234",
    "regency_id": "9112",
    "label": "Nongme",
    "value": "Nongme"
  },
  {
    "id": "911301",
    "regency_id": "9113",
    "label": "Kurima",
    "value": "Kurima"
  },
  {
    "id": "911302",
    "regency_id": "9113",
    "label": "Anggruk",
    "value": "Anggruk"
  },
  { "id": "911303", "regency_id": "9113", "label": "Ninia", "value": "Ninia" },
  {
    "id": "911306",
    "regency_id": "9113",
    "label": "Silimo",
    "value": "Silimo"
  },
  {
    "id": "911307",
    "regency_id": "9113",
    "label": "Samenage",
    "value": "Samenage"
  },
  { "id": "911308", "regency_id": "9113", "label": "Nalca", "value": "Nalca" },
  { "id": "911309", "regency_id": "9113", "label": "Dekai", "value": "Dekai" },
  { "id": "911310", "regency_id": "9113", "label": "Obio", "value": "Obio" },
  {
    "id": "911311",
    "regency_id": "9113",
    "label": "Suru Suru",
    "value": "Suru Suru"
  },
  {
    "id": "911312",
    "regency_id": "9113",
    "label": "Wusama",
    "value": "Wusama"
  },
  { "id": "911313", "regency_id": "9113", "label": "Amuma", "value": "Amuma" },
  {
    "id": "911314",
    "regency_id": "9113",
    "label": "Musaik",
    "value": "Musaik"
  },
  {
    "id": "911315",
    "regency_id": "9113",
    "label": "Pasema",
    "value": "Pasema"
  },
  { "id": "911316", "regency_id": "9113", "label": "Hogio", "value": "Hogio" },
  { "id": "911317", "regency_id": "9113", "label": "Mugi", "value": "Mugi" },
  { "id": "911318", "regency_id": "9113", "label": "Soba", "value": "Soba" },
  {
    "id": "911319",
    "regency_id": "9113",
    "label": "Werima",
    "value": "Werima"
  },
  {
    "id": "911320",
    "regency_id": "9113",
    "label": "Tangma",
    "value": "Tangma"
  },
  { "id": "911321", "regency_id": "9113", "label": "Ukha", "value": "Ukha" },
  {
    "id": "911322",
    "regency_id": "9113",
    "label": "Panggema",
    "value": "Panggema"
  },
  {
    "id": "911323",
    "regency_id": "9113",
    "label": "Kosarek",
    "value": "Kosarek"
  },
  {
    "id": "911324",
    "regency_id": "9113",
    "label": "Nipsan",
    "value": "Nipsan"
  },
  {
    "id": "911325",
    "regency_id": "9113",
    "label": "Ubahak",
    "value": "Ubahak"
  },
  {
    "id": "911326",
    "regency_id": "9113",
    "label": "Pronggoli",
    "value": "Pronggoli"
  },
  { "id": "911327", "regency_id": "9113", "label": "Walma", "value": "Walma" },
  {
    "id": "911328",
    "regency_id": "9113",
    "label": "Yahuliambut",
    "value": "Yahuliambut"
  },
  {
    "id": "911329",
    "regency_id": "9113",
    "label": "Hereapini",
    "value": "Hereapini"
  },
  {
    "id": "911330",
    "regency_id": "9113",
    "label": "Ubalihi",
    "value": "Ubalihi"
  },
  {
    "id": "911331",
    "regency_id": "9113",
    "label": "Talambo",
    "value": "Talambo"
  },
  {
    "id": "911332",
    "regency_id": "9113",
    "label": "Puldama",
    "value": "Puldama"
  },
  {
    "id": "911333",
    "regency_id": "9113",
    "label": "Endomen",
    "value": "Endomen"
  },
  { "id": "911334", "regency_id": "9113", "label": "Kona", "value": "Kona" },
  {
    "id": "911335",
    "regency_id": "9113",
    "label": "Dirwemna",
    "value": "Dirwemna"
  },
  {
    "id": "911336",
    "regency_id": "9113",
    "label": "Holuon",
    "value": "Holuon"
  },
  { "id": "911337", "regency_id": "9113", "label": "Lolat", "value": "Lolat" },
  {
    "id": "911338",
    "regency_id": "9113",
    "label": "Soloikma",
    "value": "Soloikma"
  },
  { "id": "911339", "regency_id": "9113", "label": "Sela", "value": "Sela" },
  {
    "id": "911340",
    "regency_id": "9113",
    "label": "Korupun",
    "value": "Korupun"
  },
  {
    "id": "911341",
    "regency_id": "9113",
    "label": "Langda",
    "value": "Langda"
  },
  {
    "id": "911342",
    "regency_id": "9113",
    "label": "Bomela",
    "value": "Bomela"
  },
  {
    "id": "911343",
    "regency_id": "9113",
    "label": "Suntamon",
    "value": "Suntamon"
  },
  {
    "id": "911344",
    "regency_id": "9113",
    "label": "Seredela",
    "value": "Seredela"
  },
  {
    "id": "911345",
    "regency_id": "9113",
    "label": "Sobaham",
    "value": "Sobaham"
  },
  {
    "id": "911346",
    "regency_id": "9113",
    "label": "Kabianggama",
    "value": "Kabianggama"
  },
  {
    "id": "911347",
    "regency_id": "9113",
    "label": "Kwelemdua",
    "value": "Kwelemdua"
  },
  {
    "id": "911348",
    "regency_id": "9113",
    "label": "Kwikma",
    "value": "Kwikma"
  },
  {
    "id": "911349",
    "regency_id": "9113",
    "label": "Hilipuk",
    "value": "Hilipuk"
  },
  { "id": "911350", "regency_id": "9113", "label": "Duram", "value": "Duram" },
  {
    "id": "911351",
    "regency_id": "9113",
    "label": "Yogosem",
    "value": "Yogosem"
  },
  { "id": "911352", "regency_id": "9113", "label": "Kayo", "value": "Kayo" },
  { "id": "911353", "regency_id": "9113", "label": "Sumo", "value": "Sumo" },
  {
    "id": "911401",
    "regency_id": "9114",
    "label": "Karubaga",
    "value": "Karubaga"
  },
  {
    "id": "911402",
    "regency_id": "9114",
    "label": "Bokondini",
    "value": "Bokondini"
  },
  {
    "id": "911403",
    "regency_id": "9114",
    "label": "Kanggime",
    "value": "Kanggime"
  },
  { "id": "911404", "regency_id": "9114", "label": "Kembu", "value": "Kembu" },
  {
    "id": "911405",
    "regency_id": "9114",
    "label": "Goyage",
    "value": "Goyage"
  },
  { "id": "911406", "regency_id": "9114", "label": "Wunim", "value": "Wunim" },
  { "id": "911407", "regency_id": "9114", "label": "Wina", "value": "Wina" },
  { "id": "911408", "regency_id": "9114", "label": "Umagi", "value": "Umagi" },
  {
    "id": "911409",
    "regency_id": "9114",
    "label": "Panaga",
    "value": "Panaga"
  },
  {
    "id": "911410",
    "regency_id": "9114",
    "label": "Woniki",
    "value": "Woniki"
  },
  { "id": "911411", "regency_id": "9114", "label": "Kubu", "value": "Kubu" },
  {
    "id": "911412",
    "regency_id": "9114",
    "label": "Konda/Kondaga",
    "value": "Konda/Kondaga"
  },
  {
    "id": "911413",
    "regency_id": "9114",
    "label": "Nelawi",
    "value": "Nelawi"
  },
  { "id": "911414", "regency_id": "9114", "label": "Kuari", "value": "Kuari" },
  {
    "id": "911415",
    "regency_id": "9114",
    "label": "Bokoneri",
    "value": "Bokoneri"
  },
  {
    "id": "911416",
    "regency_id": "9114",
    "label": "Bewani",
    "value": "Bewani"
  },
  {
    "id": "911418",
    "regency_id": "9114",
    "label": "Nabunage",
    "value": "Nabunage"
  },
  {
    "id": "911419",
    "regency_id": "9114",
    "label": "Gilubandu",
    "value": "Gilubandu"
  },
  {
    "id": "911420",
    "regency_id": "9114",
    "label": "Nunggawi",
    "value": "Nunggawi"
  },
  {
    "id": "911421",
    "regency_id": "9114",
    "label": "Gundagi",
    "value": "Gundagi"
  },
  { "id": "911422", "regency_id": "9114", "label": "Numba", "value": "Numba" },
  {
    "id": "911423",
    "regency_id": "9114",
    "label": "Timori",
    "value": "Timori"
  },
  { "id": "911424", "regency_id": "9114", "label": "Dundu", "value": "Dundu" },
  { "id": "911425", "regency_id": "9114", "label": "Geya", "value": "Geya" },
  { "id": "911426", "regency_id": "9114", "label": "Egiam", "value": "Egiam" },
  {
    "id": "911427",
    "regency_id": "9114",
    "label": "Poganeri",
    "value": "Poganeri"
  },
  {
    "id": "911428",
    "regency_id": "9114",
    "label": "Kamboneri",
    "value": "Kamboneri"
  },
  {
    "id": "911429",
    "regency_id": "9114",
    "label": "Airgaram",
    "value": "Airgaram"
  },
  {
    "id": "911430",
    "regency_id": "9114",
    "label": "Wari/Taiyeve II",
    "value": "Wari/Taiyeve II"
  },
  { "id": "911431", "regency_id": "9114", "label": "Dow", "value": "Dow" },
  {
    "id": "911432",
    "regency_id": "9114",
    "label": "Tagineri",
    "value": "Tagineri"
  },
  {
    "id": "911433",
    "regency_id": "9114",
    "label": "Yuneri",
    "value": "Yuneri"
  },
  {
    "id": "911434",
    "regency_id": "9114",
    "label": "Wakuwo",
    "value": "Wakuwo"
  },
  { "id": "911435", "regency_id": "9114", "label": "Gika", "value": "Gika" },
  {
    "id": "911436",
    "regency_id": "9114",
    "label": "Telenggeme",
    "value": "Telenggeme"
  },
  { "id": "911437", "regency_id": "9114", "label": "Anawi", "value": "Anawi" },
  { "id": "911438", "regency_id": "9114", "label": "Wenam", "value": "Wenam" },
  { "id": "911439", "regency_id": "9114", "label": "Wugi", "value": "Wugi" },
  {
    "id": "911440",
    "regency_id": "9114",
    "label": "Danime",
    "value": "Danime"
  },
  {
    "id": "911441",
    "regency_id": "9114",
    "label": "Tagime",
    "value": "Tagime"
  },
  { "id": "911442", "regency_id": "9114", "label": "Kai", "value": "Kai" },
  { "id": "911443", "regency_id": "9114", "label": "Aweku", "value": "Aweku" },
  {
    "id": "911444",
    "regency_id": "9114",
    "label": "Bogonuk",
    "value": "Bogonuk"
  },
  {
    "id": "911445",
    "regency_id": "9114",
    "label": "Li Anogomma",
    "value": "Li Anogomma"
  },
  { "id": "911446", "regency_id": "9114", "label": "Biuk", "value": "Biuk" },
  { "id": "911447", "regency_id": "9114", "label": "Yuko", "value": "Yuko" },
  {
    "id": "911501",
    "regency_id": "9115",
    "label": "Waropen Bawah",
    "value": "Waropen Bawah"
  },
  {
    "id": "911503",
    "regency_id": "9115",
    "label": "Masirei",
    "value": "Masirei"
  },
  {
    "id": "911507",
    "regency_id": "9115",
    "label": "Risei Sayati",
    "value": "Risei Sayati"
  },
  {
    "id": "911508",
    "regency_id": "9115",
    "label": "Urei Faisei",
    "value": "Urei Faisei"
  },
  {
    "id": "911509",
    "regency_id": "9115",
    "label": "Inggerus",
    "value": "Inggerus"
  },
  {
    "id": "911510",
    "regency_id": "9115",
    "label": "Kirihi",
    "value": "Kirihi"
  },
  {
    "id": "911511",
    "regency_id": "9115",
    "label": "Oudate",
    "value": "Oudate"
  },
  {
    "id": "911512",
    "regency_id": "9115",
    "label": "Wapoga",
    "value": "Wapoga"
  },
  { "id": "911513", "regency_id": "9115", "label": "Demba", "value": "Demba" },
  { "id": "911514", "regency_id": "9115", "label": "Wonti", "value": "Wonti" },
  {
    "id": "911515",
    "regency_id": "9115",
    "label": "Soyoi Mambai",
    "value": "Soyoi Mambai"
  },
  {
    "id": "911601",
    "regency_id": "9116",
    "label": "Mandobo",
    "value": "Mandobo"
  },
  {
    "id": "911602",
    "regency_id": "9116",
    "label": "Mindiptana",
    "value": "Mindiptana"
  },
  {
    "id": "911603",
    "regency_id": "9116",
    "label": "Waropko",
    "value": "Waropko"
  },
  { "id": "911604", "regency_id": "9116", "label": "Kouh", "value": "Kouh" },
  { "id": "911605", "regency_id": "9116", "label": "Jair", "value": "Jair" },
  {
    "id": "911606",
    "regency_id": "9116",
    "label": "Bomakia",
    "value": "Bomakia"
  },
  {
    "id": "911607",
    "regency_id": "9116",
    "label": "Kombut",
    "value": "Kombut"
  },
  {
    "id": "911608",
    "regency_id": "9116",
    "label": "Iniyandit",
    "value": "Iniyandit"
  },
  {
    "id": "911609",
    "regency_id": "9116",
    "label": "Arimop",
    "value": "Arimop"
  },
  { "id": "911610", "regency_id": "9116", "label": "Fofi", "value": "Fofi" },
  {
    "id": "911611",
    "regency_id": "9116",
    "label": "Ambatkwi",
    "value": "Ambatkwi"
  },
  {
    "id": "911612",
    "regency_id": "9116",
    "label": "Manggelum",
    "value": "Manggelum"
  },
  {
    "id": "911613",
    "regency_id": "9116",
    "label": "Firiwage",
    "value": "Firiwage"
  },
  {
    "id": "911614",
    "regency_id": "9116",
    "label": "Yaniruma",
    "value": "Yaniruma"
  },
  { "id": "911615", "regency_id": "9116", "label": "Subur", "value": "Subur" },
  {
    "id": "911616",
    "regency_id": "9116",
    "label": "Kombay",
    "value": "Kombay"
  },
  {
    "id": "911617",
    "regency_id": "9116",
    "label": "Ninati",
    "value": "Ninati"
  },
  {
    "id": "911618",
    "regency_id": "9116",
    "label": "Sesnuk",
    "value": "Sesnuk"
  },
  { "id": "911619", "regency_id": "9116", "label": "Ki", "value": "Ki" },
  {
    "id": "911620",
    "regency_id": "9116",
    "label": "Kawagit",
    "value": "Kawagit"
  },
  { "id": "911701", "regency_id": "9117", "label": "Obaa", "value": "Obaa" },
  {
    "id": "911702",
    "regency_id": "9117",
    "label": "Mambioman Bapai",
    "value": "Mambioman Bapai"
  },
  {
    "id": "911703",
    "regency_id": "9117",
    "label": "Citak-Mitak",
    "value": "Citak-Mitak"
  },
  { "id": "911704", "regency_id": "9117", "label": "Edera", "value": "Edera" },
  { "id": "911705", "regency_id": "9117", "label": "Haju", "value": "Haju" },
  { "id": "911706", "regency_id": "9117", "label": "Assue", "value": "Assue" },
  {
    "id": "911707",
    "regency_id": "9117",
    "label": "Kaibar",
    "value": "Kaibar"
  },
  {
    "id": "911708",
    "regency_id": "9117",
    "label": "Passue",
    "value": "Passue"
  },
  {
    "id": "911709",
    "regency_id": "9117",
    "label": "Minyamur",
    "value": "Minyamur"
  },
  {
    "id": "911710",
    "regency_id": "9117",
    "label": "Venaha",
    "value": "Venaha"
  },
  {
    "id": "911711",
    "regency_id": "9117",
    "label": "Syahcame",
    "value": "Syahcame"
  },
  {
    "id": "911712",
    "regency_id": "9117",
    "label": "Yakomi",
    "value": "Yakomi"
  },
  { "id": "911713", "regency_id": "9117", "label": "Bamgi", "value": "Bamgi" },
  {
    "id": "911714",
    "regency_id": "9117",
    "label": "Passue Bawah",
    "value": "Passue Bawah"
  },
  {
    "id": "911715",
    "regency_id": "9117",
    "label": "Ti Zain",
    "value": "Ti Zain"
  },
  { "id": "911801", "regency_id": "9118", "label": "Agats", "value": "Agats" },
  { "id": "911802", "regency_id": "9118", "label": "Atsj", "value": "Atsj" },
  {
    "id": "911803",
    "regency_id": "9118",
    "label": "Sawa Erma",
    "value": "Sawa Erma"
  },
  { "id": "911804", "regency_id": "9118", "label": "Akat", "value": "Akat" },
  { "id": "911805", "regency_id": "9118", "label": "Fayit", "value": "Fayit" },
  {
    "id": "911806",
    "regency_id": "9118",
    "label": "Pantai Kasuari",
    "value": "Pantai Kasuari"
  },
  {
    "id": "911807",
    "regency_id": "9118",
    "label": "Suator",
    "value": "Suator"
  },
  {
    "id": "911808",
    "regency_id": "9118",
    "label": "Suru-suru",
    "value": "Suru-suru"
  },
  {
    "id": "911809",
    "regency_id": "9118",
    "label": "Kolf Braza",
    "value": "Kolf Braza"
  },
  {
    "id": "911810",
    "regency_id": "9118",
    "label": "Unir Sirau",
    "value": "Unir Sirau"
  },
  {
    "id": "911811",
    "regency_id": "9118",
    "label": "Joerat",
    "value": "Joerat"
  },
  {
    "id": "911812",
    "regency_id": "9118",
    "label": "Pulau Tiga",
    "value": "Pulau Tiga"
  },
  { "id": "911813", "regency_id": "9118", "label": "Jetsy", "value": "Jetsy" },
  {
    "id": "911814",
    "regency_id": "9118",
    "label": "Der Koumur",
    "value": "Der Koumur"
  },
  { "id": "911815", "regency_id": "9118", "label": "Kopay", "value": "Kopay" },
  { "id": "911816", "regency_id": "9118", "label": "Safan", "value": "Safan" },
  {
    "id": "911817",
    "regency_id": "9118",
    "label": "Sirets",
    "value": "Sirets"
  },
  { "id": "911818", "regency_id": "9118", "label": "Ayip", "value": "Ayip" },
  {
    "id": "911819",
    "regency_id": "9118",
    "label": "Betcbamu",
    "value": "Betcbamu"
  },
  {
    "id": "911901",
    "regency_id": "9119",
    "label": "Supiori Selatan",
    "value": "Supiori Selatan"
  },
  {
    "id": "911902",
    "regency_id": "9119",
    "label": "Supiori Utara",
    "value": "Supiori Utara"
  },
  {
    "id": "911903",
    "regency_id": "9119",
    "label": "Supiori Timur",
    "value": "Supiori Timur"
  },
  {
    "id": "911904",
    "regency_id": "9119",
    "label": "Kepulauan Aruri",
    "value": "Kepulauan Aruri"
  },
  {
    "id": "911905",
    "regency_id": "9119",
    "label": "Supiori Barat",
    "value": "Supiori Barat"
  },
  {
    "id": "912001",
    "regency_id": "9120",
    "label": "Mamberamo Tengah",
    "value": "Mamberamo Tengah"
  },
  {
    "id": "912002",
    "regency_id": "9120",
    "label": "Mamberamo Hulu",
    "value": "Mamberamo Hulu"
  },
  {
    "id": "912003",
    "regency_id": "9120",
    "label": "Rufaer",
    "value": "Rufaer"
  },
  {
    "id": "912004",
    "regency_id": "9120",
    "label": "Mamberamo Tengah Timur",
    "value": "Mamberamo Tengah Timur"
  },
  {
    "id": "912005",
    "regency_id": "9120",
    "label": "Mamberamo Hilir",
    "value": "Mamberamo Hilir"
  },
  {
    "id": "912006",
    "regency_id": "9120",
    "label": "Waropen Atas",
    "value": "Waropen Atas"
  },
  {
    "id": "912007",
    "regency_id": "9120",
    "label": "Benuki",
    "value": "Benuki"
  },
  { "id": "912008", "regency_id": "9120", "label": "Sawai", "value": "Sawai" },
  {
    "id": "912101",
    "regency_id": "9121",
    "label": "Kobagma",
    "value": "Kobagma"
  },
  {
    "id": "912102",
    "regency_id": "9121",
    "label": "Kelila",
    "value": "Kelila"
  },
  {
    "id": "912103",
    "regency_id": "9121",
    "label": "Eragayam",
    "value": "Eragayam"
  },
  {
    "id": "912104",
    "regency_id": "9121",
    "label": "Megambilis",
    "value": "Megambilis"
  },
  {
    "id": "912105",
    "regency_id": "9121",
    "label": "Ilugwa",
    "value": "Ilugwa"
  },
  {
    "id": "912201",
    "regency_id": "9122",
    "label": "Elelim",
    "value": "Elelim"
  },
  {
    "id": "912202",
    "regency_id": "9122",
    "label": "Apalapsili",
    "value": "Apalapsili"
  },
  {
    "id": "912203",
    "regency_id": "9122",
    "label": "Abenaho",
    "value": "Abenaho"
  },
  {
    "id": "912204",
    "regency_id": "9122",
    "label": "Benawa",
    "value": "Benawa"
  },
  {
    "id": "912205",
    "regency_id": "9122",
    "label": "Welarek",
    "value": "Welarek"
  },
  { "id": "912301", "regency_id": "9123", "label": "Tiom", "value": "Tiom" },
  {
    "id": "912302",
    "regency_id": "9123",
    "label": "Pirime",
    "value": "Pirime"
  },
  { "id": "912303", "regency_id": "9123", "label": "Makki", "value": "Makki" },
  {
    "id": "912304",
    "regency_id": "9123",
    "label": "Gamelia",
    "value": "Gamelia"
  },
  { "id": "912305", "regency_id": "9123", "label": "Dimba", "value": "Dimba" },
  {
    "id": "912306",
    "regency_id": "9123",
    "label": "Melagineri",
    "value": "Melagineri"
  },
  {
    "id": "912307",
    "regency_id": "9123",
    "label": "Balingga",
    "value": "Balingga"
  },
  {
    "id": "912308",
    "regency_id": "9123",
    "label": "Tiomneri",
    "value": "Tiomneri"
  },
  {
    "id": "912309",
    "regency_id": "9123",
    "label": "Kuyawage",
    "value": "Kuyawage"
  },
  { "id": "912310", "regency_id": "9123", "label": "Poga", "value": "Poga" },
  {
    "id": "912311",
    "regency_id": "9123",
    "label": "Niname",
    "value": "Niname"
  },
  { "id": "912312", "regency_id": "9123", "label": "Nogi", "value": "Nogi" },
  {
    "id": "912313",
    "regency_id": "9123",
    "label": "Yiginua",
    "value": "Yiginua"
  },
  {
    "id": "912314",
    "regency_id": "9123",
    "label": "Tiom Ollo",
    "value": "Tiom Ollo"
  },
  {
    "id": "912315",
    "regency_id": "9123",
    "label": "Yugungwi",
    "value": "Yugungwi"
  },
  {
    "id": "912316",
    "regency_id": "9123",
    "label": "Mokoni",
    "value": "Mokoni"
  },
  {
    "id": "912317",
    "regency_id": "9123",
    "label": "Wereka",
    "value": "Wereka"
  },
  {
    "id": "912318",
    "regency_id": "9123",
    "label": "Milimbo",
    "value": "Milimbo"
  },
  {
    "id": "912319",
    "regency_id": "9123",
    "label": "Wiringgambut",
    "value": "Wiringgambut"
  },
  { "id": "912320", "regency_id": "9123", "label": "Gollo", "value": "Gollo" },
  { "id": "912321", "regency_id": "9123", "label": "Awina", "value": "Awina" },
  {
    "id": "912322",
    "regency_id": "9123",
    "label": "Ayumnati",
    "value": "Ayumnati"
  },
  {
    "id": "912323",
    "regency_id": "9123",
    "label": "Wano Barat",
    "value": "Wano Barat"
  },
  {
    "id": "912324",
    "regency_id": "9123",
    "label": "Goa Balim",
    "value": "Goa Balim"
  },
  { "id": "912325", "regency_id": "9123", "label": "Bruwa", "value": "Bruwa" },
  {
    "id": "912326",
    "regency_id": "9123",
    "label": "Balingga Barat",
    "value": "Balingga Barat"
  },
  {
    "id": "912327",
    "regency_id": "9123",
    "label": "Gupura",
    "value": "Gupura"
  },
  {
    "id": "912328",
    "regency_id": "9123",
    "label": "Kolawa",
    "value": "Kolawa"
  },
  {
    "id": "912329",
    "regency_id": "9123",
    "label": "Gelok Beam",
    "value": "Gelok Beam"
  },
  {
    "id": "912330",
    "regency_id": "9123",
    "label": "Kuly Lanny",
    "value": "Kuly Lanny"
  },
  {
    "id": "912331",
    "regency_id": "9123",
    "label": "Lannyna",
    "value": "Lannyna"
  },
  { "id": "912332", "regency_id": "9123", "label": "Karu", "value": "Karu" },
  { "id": "912333", "regency_id": "9123", "label": "Yiluk", "value": "Yiluk" },
  { "id": "912334", "regency_id": "9123", "label": "Guna", "value": "Guna" },
  {
    "id": "912335",
    "regency_id": "9123",
    "label": "Kelulome",
    "value": "Kelulome"
  },
  {
    "id": "912336",
    "regency_id": "9123",
    "label": "Nikogwe",
    "value": "Nikogwe"
  },
  { "id": "912337", "regency_id": "9123", "label": "Muara", "value": "Muara" },
  {
    "id": "912338",
    "regency_id": "9123",
    "label": "Buguk Gona",
    "value": "Buguk Gona"
  },
  {
    "id": "912339",
    "regency_id": "9123",
    "label": "Melagi",
    "value": "Melagi"
  },
  {
    "id": "912401",
    "regency_id": "9124",
    "label": "Kenyam",
    "value": "Kenyam"
  },
  {
    "id": "912402",
    "regency_id": "9124",
    "label": "Mapenduma",
    "value": "Mapenduma"
  },
  { "id": "912403", "regency_id": "9124", "label": "Yigi", "value": "Yigi" },
  { "id": "912404", "regency_id": "9124", "label": "Wosak", "value": "Wosak" },
  {
    "id": "912405",
    "regency_id": "9124",
    "label": "Geselma",
    "value": "Geselma"
  },
  { "id": "912406", "regency_id": "9124", "label": "Mugi", "value": "Mugi" },
  { "id": "912407", "regency_id": "9124", "label": "Mbuwa", "value": "Mbuwa" },
  {
    "id": "912408",
    "regency_id": "9124",
    "label": "Gearek",
    "value": "Gearek"
  },
  {
    "id": "912409",
    "regency_id": "9124",
    "label": "Koroptak",
    "value": "Koroptak"
  },
  {
    "id": "912410",
    "regency_id": "9124",
    "label": "Kegayem",
    "value": "Kegayem"
  },
  { "id": "912411", "regency_id": "9124", "label": "Paro", "value": "Paro" },
  {
    "id": "912412",
    "regency_id": "9124",
    "label": "Mebarok",
    "value": "Mebarok"
  },
  {
    "id": "912413",
    "regency_id": "9124",
    "label": "Yenggelo",
    "value": "Yenggelo"
  },
  {
    "id": "912414",
    "regency_id": "9124",
    "label": "Kilmid",
    "value": "Kilmid"
  },
  { "id": "912415", "regency_id": "9124", "label": "Alama", "value": "Alama" },
  { "id": "912416", "regency_id": "9124", "label": "Yal", "value": "Yal" },
  { "id": "912417", "regency_id": "9124", "label": "Mam", "value": "Mam" },
  { "id": "912418", "regency_id": "9124", "label": "Dal", "value": "Dal" },
  {
    "id": "912419",
    "regency_id": "9124",
    "label": "Nirkuri",
    "value": "Nirkuri"
  },
  {
    "id": "912420",
    "regency_id": "9124",
    "label": "Inikgal",
    "value": "Inikgal"
  },
  { "id": "912421", "regency_id": "9124", "label": "Iniye", "value": "Iniye" },
  {
    "id": "912422",
    "regency_id": "9124",
    "label": "Mbulmu Yalma",
    "value": "Mbulmu Yalma"
  },
  {
    "id": "912423",
    "regency_id": "9124",
    "label": "Mbua Tengah",
    "value": "Mbua Tengah"
  },
  {
    "id": "912424",
    "regency_id": "9124",
    "label": "Embetpen",
    "value": "Embetpen"
  },
  { "id": "912425", "regency_id": "9124", "label": "Kora", "value": "Kora" },
  { "id": "912426", "regency_id": "9124", "label": "Wusi", "value": "Wusi" },
  { "id": "912427", "regency_id": "9124", "label": "Pija", "value": "Pija" },
  { "id": "912428", "regency_id": "9124", "label": "Moba", "value": "Moba" },
  {
    "id": "912429",
    "regency_id": "9124",
    "label": "Wutpaga",
    "value": "Wutpaga"
  },
  {
    "id": "912430",
    "regency_id": "9124",
    "label": "Nenggeagin",
    "value": "Nenggeagin"
  },
  {
    "id": "912431",
    "regency_id": "9124",
    "label": "Krepkuri",
    "value": "Krepkuri"
  },
  {
    "id": "912432",
    "regency_id": "9124",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  { "id": "912501", "regency_id": "9125", "label": "Ilaga", "value": "Ilaga" },
  {
    "id": "912502",
    "regency_id": "9125",
    "label": "Wangbe",
    "value": "Wangbe"
  },
  { "id": "912503", "regency_id": "9125", "label": "Beoga", "value": "Beoga" },
  { "id": "912504", "regency_id": "9125", "label": "Doufo", "value": "Doufo" },
  {
    "id": "912505",
    "regency_id": "9125",
    "label": "Pogoma",
    "value": "Pogoma"
  },
  { "id": "912506", "regency_id": "9125", "label": "Sinak", "value": "Sinak" },
  {
    "id": "912507",
    "regency_id": "9125",
    "label": "Agandugume",
    "value": "Agandugume"
  },
  { "id": "912508", "regency_id": "9125", "label": "Gome", "value": "Gome" },
  {
    "id": "912509",
    "regency_id": "9125",
    "label": "Dervos",
    "value": "Dervos"
  },
  {
    "id": "912510",
    "regency_id": "9125",
    "label": "Beoga Barat",
    "value": "Beoga Barat"
  },
  {
    "id": "912511",
    "regency_id": "9125",
    "label": "Beoga Timur",
    "value": "Beoga Timur"
  },
  {
    "id": "912512",
    "regency_id": "9125",
    "label": "Ogamanim",
    "value": "Ogamanim"
  },
  {
    "id": "912513",
    "regency_id": "9125",
    "label": "Kembru",
    "value": "Kembru"
  },
  { "id": "912514", "regency_id": "9125", "label": "Bina", "value": "Bina" },
  {
    "id": "912515",
    "regency_id": "9125",
    "label": "Sinak Barat",
    "value": "Sinak Barat"
  },
  {
    "id": "912516",
    "regency_id": "9125",
    "label": "Mage'abume",
    "value": "Mage'abume"
  },
  {
    "id": "912517",
    "regency_id": "9125",
    "label": "Yugumuak",
    "value": "Yugumuak"
  },
  {
    "id": "912518",
    "regency_id": "9125",
    "label": "Ilaga Utara",
    "value": "Ilaga Utara"
  },
  {
    "id": "912519",
    "regency_id": "9125",
    "label": "Mabugi",
    "value": "Mabugi"
  },
  {
    "id": "912520",
    "regency_id": "9125",
    "label": "Omukia",
    "value": "Omukia"
  },
  {
    "id": "912521",
    "regency_id": "9125",
    "label": "Lambewi",
    "value": "Lambewi"
  },
  { "id": "912522", "regency_id": "9125", "label": "Oneri", "value": "Oneri" },
  {
    "id": "912523",
    "regency_id": "9125",
    "label": "Amungkalpia",
    "value": "Amungkalpia"
  },
  {
    "id": "912524",
    "regency_id": "9125",
    "label": "Gome Utara",
    "value": "Gome Utara"
  },
  {
    "id": "912525",
    "regency_id": "9125",
    "label": "Erelmakawia",
    "value": "Erelmakawia"
  },
  { "id": "912601", "regency_id": "9126", "label": "Kamu", "value": "Kamu" },
  { "id": "912602", "regency_id": "9126", "label": "Mapia", "value": "Mapia" },
  {
    "id": "912603",
    "regency_id": "9126",
    "label": "Piyaiye",
    "value": "Piyaiye"
  },
  {
    "id": "912604",
    "regency_id": "9126",
    "label": "Kamu Utara",
    "value": "Kamu Utara"
  },
  {
    "id": "912605",
    "regency_id": "9126",
    "label": "Sukikai Selatan",
    "value": "Sukikai Selatan"
  },
  {
    "id": "912606",
    "regency_id": "9126",
    "label": "Mapia Barat",
    "value": "Mapia Barat"
  },
  {
    "id": "912607",
    "regency_id": "9126",
    "label": "Kamu Selatan",
    "value": "Kamu Selatan"
  },
  {
    "id": "912608",
    "regency_id": "9126",
    "label": "Kamu Timur",
    "value": "Kamu Timur"
  },
  {
    "id": "912609",
    "regency_id": "9126",
    "label": "Mapia Tengah",
    "value": "Mapia Tengah"
  },
  {
    "id": "912610",
    "regency_id": "9126",
    "label": "Dogiyai",
    "value": "Dogiyai"
  },
  {
    "id": "912701",
    "regency_id": "9127",
    "label": "Sugapa",
    "value": "Sugapa"
  },
  {
    "id": "912702",
    "regency_id": "9127",
    "label": "Homeyo",
    "value": "Homeyo"
  },
  {
    "id": "912703",
    "regency_id": "9127",
    "label": "Wandai",
    "value": "Wandai"
  },
  {
    "id": "912704",
    "regency_id": "9127",
    "label": "Biandoga",
    "value": "Biandoga"
  },
  {
    "id": "912705",
    "regency_id": "9127",
    "label": "Agisiga",
    "value": "Agisiga"
  },
  {
    "id": "912706",
    "regency_id": "9127",
    "label": "Hitadipa",
    "value": "Hitadipa"
  },
  {
    "id": "912707",
    "regency_id": "9127",
    "label": "Ugimba",
    "value": "Ugimba"
  },
  {
    "id": "912708",
    "regency_id": "9127",
    "label": "Tomosiga",
    "value": "Tomosiga"
  },
  { "id": "912801", "regency_id": "9128", "label": "Tigi", "value": "Tigi" },
  {
    "id": "912802",
    "regency_id": "9128",
    "label": "Tigi Timur",
    "value": "Tigi Timur"
  },
  {
    "id": "912803",
    "regency_id": "9128",
    "label": "Bowobado",
    "value": "Bowobado"
  },
  {
    "id": "912804",
    "regency_id": "9128",
    "label": "Tigi Barat",
    "value": "Tigi Barat"
  },
  {
    "id": "912805",
    "regency_id": "9128",
    "label": "Kapiraya",
    "value": "Kapiraya"
  },
  {
    "id": "917101",
    "regency_id": "9171",
    "label": "Jayapura Utara",
    "value": "Jayapura Utara"
  },
  {
    "id": "917102",
    "regency_id": "9171",
    "label": "Jayapura Selatan",
    "value": "Jayapura Selatan"
  },
  {
    "id": "917103",
    "regency_id": "9171",
    "label": "Abepura",
    "value": "Abepura"
  },
  {
    "id": "917104",
    "regency_id": "9171",
    "label": "Muara Tami",
    "value": "Muara Tami"
  },
  { "id": "917105", "regency_id": "9171", "label": "Heram", "value": "Heram" },
  {
    "id": "920101",
    "regency_id": "9201",
    "label": "Makbon",
    "value": "Makbon"
  },
  {
    "id": "920104",
    "regency_id": "9201",
    "label": "Beraur",
    "value": "Beraur"
  },
  {
    "id": "920105",
    "regency_id": "9201",
    "label": "Salawati",
    "value": "Salawati"
  },
  { "id": "920106", "regency_id": "9201", "label": "Seget", "value": "Seget" },
  { "id": "920107", "regency_id": "9201", "label": "Aimas", "value": "Aimas" },
  {
    "id": "920108",
    "regency_id": "9201",
    "label": "Klamono",
    "value": "Klamono"
  },
  {
    "id": "920110",
    "regency_id": "9201",
    "label": "Sayosa",
    "value": "Sayosa"
  },
  { "id": "920112", "regency_id": "9201", "label": "Segun", "value": "Segun" },
  {
    "id": "920113",
    "regency_id": "9201",
    "label": "Mayamuk",
    "value": "Mayamuk"
  },
  {
    "id": "920114",
    "regency_id": "9201",
    "label": "Salawati Selatan",
    "value": "Salawati Selatan"
  },
  {
    "id": "920117",
    "regency_id": "9201",
    "label": "Klabot",
    "value": "Klabot"
  },
  {
    "id": "920118",
    "regency_id": "9201",
    "label": "Klawak",
    "value": "Klawak"
  },
  {
    "id": "920120",
    "regency_id": "9201",
    "label": "Maudus",
    "value": "Maudus"
  },
  {
    "id": "920139",
    "regency_id": "9201",
    "label": "Mariat",
    "value": "Mariat"
  },
  {
    "id": "920140",
    "regency_id": "9201",
    "label": "Klayili",
    "value": "Klayili"
  },
  { "id": "920141", "regency_id": "9201", "label": "Klaso", "value": "Klaso" },
  {
    "id": "920142",
    "regency_id": "9201",
    "label": "Moisegen",
    "value": "Moisegen"
  },
  {
    "id": "920143",
    "regency_id": "9201",
    "label": "Sorong",
    "value": "Sorong"
  },
  { "id": "920144", "regency_id": "9201", "label": "Bagun", "value": "Bagun" },
  { "id": "920145", "regency_id": "9201", "label": "Wemak", "value": "Wemak" },
  {
    "id": "920146",
    "regency_id": "9201",
    "label": "Sunook",
    "value": "Sunook"
  },
  { "id": "920147", "regency_id": "9201", "label": "Buk", "value": "Buk" },
  {
    "id": "920148",
    "regency_id": "9201",
    "label": "Saengkeduk",
    "value": "Saengkeduk"
  },
  {
    "id": "920149",
    "regency_id": "9201",
    "label": "Malabotom",
    "value": "Malabotom"
  },
  {
    "id": "920150",
    "regency_id": "9201",
    "label": "Konhir",
    "value": "Konhir"
  },
  {
    "id": "920151",
    "regency_id": "9201",
    "label": "Klasafet",
    "value": "Klasafet"
  },
  {
    "id": "920152",
    "regency_id": "9201",
    "label": "Hobard",
    "value": "Hobard"
  },
  {
    "id": "920153",
    "regency_id": "9201",
    "label": "Salawati Tengah",
    "value": "Salawati Tengah"
  },
  {
    "id": "920154",
    "regency_id": "9201",
    "label": "Botain",
    "value": "Botain"
  },
  {
    "id": "920155",
    "regency_id": "9201",
    "label": "Sayosa Timur",
    "value": "Sayosa Timur"
  },
  {
    "id": "920203",
    "regency_id": "9202",
    "label": "Warmare",
    "value": "Warmare"
  },
  { "id": "920204", "regency_id": "9202", "label": "Prafi", "value": "Prafi" },
  { "id": "920205", "regency_id": "9202", "label": "Masni", "value": "Masni" },
  {
    "id": "920212",
    "regency_id": "9202",
    "label": "Manokwari Barat",
    "value": "Manokwari Barat"
  },
  {
    "id": "920213",
    "regency_id": "9202",
    "label": "Manokwari Timur",
    "value": "Manokwari Timur"
  },
  {
    "id": "920214",
    "regency_id": "9202",
    "label": "Manokwari Utara",
    "value": "Manokwari Utara"
  },
  {
    "id": "920215",
    "regency_id": "9202",
    "label": "Manokwari Selatan",
    "value": "Manokwari Selatan"
  },
  {
    "id": "920217",
    "regency_id": "9202",
    "label": "Tanah Rubuh",
    "value": "Tanah Rubuh"
  },
  { "id": "920221", "regency_id": "9202", "label": "Sidey", "value": "Sidey" },
  {
    "id": "920301",
    "regency_id": "9203",
    "label": "Fak-Fak",
    "value": "Fak-Fak"
  },
  {
    "id": "920302",
    "regency_id": "9203",
    "label": "Fak-Fak Barat",
    "value": "Fak-Fak Barat"
  },
  {
    "id": "920303",
    "regency_id": "9203",
    "label": "Fak-Fak Timur",
    "value": "Fak-Fak Timur"
  },
  { "id": "920304", "regency_id": "9203", "label": "Kokas", "value": "Kokas" },
  {
    "id": "920305",
    "regency_id": "9203",
    "label": "Fak-Fak Tengah",
    "value": "Fak-Fak Tengah"
  },
  { "id": "920306", "regency_id": "9203", "label": "Karas", "value": "Karas" },
  {
    "id": "920307",
    "regency_id": "9203",
    "label": "Bomberay",
    "value": "Bomberay"
  },
  {
    "id": "920308",
    "regency_id": "9203",
    "label": "Kramongmongga",
    "value": "Kramongmongga"
  },
  {
    "id": "920309",
    "regency_id": "9203",
    "label": "Teluk Patipi",
    "value": "Teluk Patipi"
  },
  {
    "id": "920310",
    "regency_id": "9203",
    "label": "Pariwari",
    "value": "Pariwari"
  },
  {
    "id": "920311",
    "regency_id": "9203",
    "label": "Wartutin",
    "value": "Wartutin"
  },
  {
    "id": "920312",
    "regency_id": "9203",
    "label": "Fakfak Timur Tengah",
    "value": "Fakfak Timur Tengah"
  },
  {
    "id": "920313",
    "regency_id": "9203",
    "label": "Arguni",
    "value": "Arguni"
  },
  {
    "id": "920314",
    "regency_id": "9203",
    "label": "Mbahamdandara",
    "value": "Mbahamdandara"
  },
  {
    "id": "920315",
    "regency_id": "9203",
    "label": "Kayauni",
    "value": "Kayauni"
  },
  {
    "id": "920316",
    "regency_id": "9203",
    "label": "Furwagi",
    "value": "Furwagi"
  },
  {
    "id": "920317",
    "regency_id": "9203",
    "label": "Tomage",
    "value": "Tomage"
  },
  {
    "id": "920401",
    "regency_id": "9204",
    "label": "Teminabuan",
    "value": "Teminabuan"
  },
  {
    "id": "920404",
    "regency_id": "9204",
    "label": "Inanwatan",
    "value": "Inanwatan"
  },
  {
    "id": "920406",
    "regency_id": "9204",
    "label": "Sawiat",
    "value": "Sawiat"
  },
  {
    "id": "920409",
    "regency_id": "9204",
    "label": "Kokoda",
    "value": "Kokoda"
  },
  {
    "id": "920410",
    "regency_id": "9204",
    "label": "Moswaren",
    "value": "Moswaren"
  },
  {
    "id": "920411",
    "regency_id": "9204",
    "label": "Seremuk",
    "value": "Seremuk"
  },
  { "id": "920412", "regency_id": "9204", "label": "Wayer", "value": "Wayer" },
  { "id": "920414", "regency_id": "9204", "label": "Kais", "value": "Kais" },
  { "id": "920415", "regency_id": "9204", "label": "Konda", "value": "Konda" },
  {
    "id": "920420",
    "regency_id": "9204",
    "label": "Matemani",
    "value": "Matemani"
  },
  {
    "id": "920421",
    "regency_id": "9204",
    "label": "Kokoda Utara",
    "value": "Kokoda Utara"
  },
  { "id": "920422", "regency_id": "9204", "label": "Saifi", "value": "Saifi" },
  {
    "id": "920424",
    "regency_id": "9204",
    "label": "Fokour",
    "value": "Fokour"
  },
  {
    "id": "920425",
    "regency_id": "9204",
    "label": "Salkma",
    "value": "Salkma"
  },
  {
    "id": "920426",
    "regency_id": "9204",
    "label": "Kais Darat",
    "value": "Kais Darat"
  },
  {
    "id": "920501",
    "regency_id": "9205",
    "label": "Misool (Misool Utara)",
    "value": "Misool (Misool Utara)"
  },
  {
    "id": "920502",
    "regency_id": "9205",
    "label": "Waigeo Utara",
    "value": "Waigeo Utara"
  },
  {
    "id": "920503",
    "regency_id": "9205",
    "label": "Waigeo Selatan",
    "value": "Waigeo Selatan"
  },
  {
    "id": "920504",
    "regency_id": "9205",
    "label": "Salawati Utara",
    "value": "Salawati Utara"
  },
  {
    "id": "920505",
    "regency_id": "9205",
    "label": "Kepulauan Ayau",
    "value": "Kepulauan Ayau"
  },
  {
    "id": "920506",
    "regency_id": "9205",
    "label": "Misool Timur",
    "value": "Misool Timur"
  },
  {
    "id": "920507",
    "regency_id": "9205",
    "label": "Waigeo Barat",
    "value": "Waigeo Barat"
  },
  {
    "id": "920508",
    "regency_id": "9205",
    "label": "Waigeo Timur",
    "value": "Waigeo Timur"
  },
  {
    "id": "920509",
    "regency_id": "9205",
    "label": "Teluk Mayalibit",
    "value": "Teluk Mayalibit"
  },
  {
    "id": "920510",
    "regency_id": "9205",
    "label": "Kofiau",
    "value": "Kofiau"
  },
  {
    "id": "920511",
    "regency_id": "9205",
    "label": "Meos Mansar",
    "value": "Meos Mansar"
  },
  {
    "id": "920513",
    "regency_id": "9205",
    "label": "Misool Selatan",
    "value": "Misool Selatan"
  },
  {
    "id": "920514",
    "regency_id": "9205",
    "label": "Warwarbomi",
    "value": "Warwarbomi"
  },
  {
    "id": "920515",
    "regency_id": "9205",
    "label": "Waigeo Barat",
    "value": "Waigeo Barat"
  },
  {
    "id": "920516",
    "regency_id": "9205",
    "label": "Misool Barat",
    "value": "Misool Barat"
  },
  {
    "id": "920517",
    "regency_id": "9205",
    "label": "Kepulauan Sembilan",
    "value": "Kepulauan Sembilan"
  },
  {
    "id": "920518",
    "regency_id": "9205",
    "label": "Kota Waisai",
    "value": "Kota Waisai"
  },
  {
    "id": "920519",
    "regency_id": "9205",
    "label": "Tiplol Mayalibit",
    "value": "Tiplol Mayalibit"
  },
  {
    "id": "920520",
    "regency_id": "9205",
    "label": "Batanta Utara",
    "value": "Batanta Utara"
  },
  {
    "id": "920521",
    "regency_id": "9205",
    "label": "Salawati Barat",
    "value": "Salawati Barat"
  },
  {
    "id": "920522",
    "regency_id": "9205",
    "label": "Salawati Tengah",
    "value": "Salawati Tengah"
  },
  {
    "id": "920523",
    "regency_id": "9205",
    "label": "Supnin",
    "value": "Supnin"
  },
  { "id": "920524", "regency_id": "9205", "label": "Ayau", "value": "Ayau" },
  {
    "id": "920525",
    "regency_id": "9205",
    "label": "Batanta Selatan",
    "value": "Batanta Selatan"
  },
  {
    "id": "920601",
    "regency_id": "9206",
    "label": "Bintuni",
    "value": "Bintuni"
  },
  {
    "id": "920602",
    "regency_id": "9206",
    "label": "Merdey",
    "value": "Merdey"
  },
  { "id": "920603", "regency_id": "9206", "label": "Babo", "value": "Babo" },
  {
    "id": "920604",
    "regency_id": "9206",
    "label": "Aranday",
    "value": "Aranday"
  },
  {
    "id": "920605",
    "regency_id": "9206",
    "label": "Moskona Selatan",
    "value": "Moskona Selatan"
  },
  {
    "id": "920606",
    "regency_id": "9206",
    "label": "Moskona Utara",
    "value": "Moskona Utara"
  },
  {
    "id": "920607",
    "regency_id": "9206",
    "label": "Wamesa",
    "value": "Wamesa"
  },
  {
    "id": "920608",
    "regency_id": "9206",
    "label": "Fafurwar",
    "value": "Fafurwar"
  },
  {
    "id": "920609",
    "regency_id": "9206",
    "label": "Tembuni",
    "value": "Tembuni"
  },
  { "id": "920610", "regency_id": "9206", "label": "Kuri", "value": "Kuri" },
  {
    "id": "920611",
    "regency_id": "9206",
    "label": "Manimeri",
    "value": "Manimeri"
  },
  {
    "id": "920612",
    "regency_id": "9206",
    "label": "Tuhiba",
    "value": "Tuhiba"
  },
  {
    "id": "920613",
    "regency_id": "9206",
    "label": "Dataran Beimes",
    "value": "Dataran Beimes"
  },
  {
    "id": "920614",
    "regency_id": "9206",
    "label": "Sumuri",
    "value": "Sumuri"
  },
  {
    "id": "920615",
    "regency_id": "9206",
    "label": "Kaitaro",
    "value": "Kaitaro"
  },
  { "id": "920616", "regency_id": "9206", "label": "Aroba", "value": "Aroba" },
  {
    "id": "920617",
    "regency_id": "9206",
    "label": "Masyeta",
    "value": "Masyeta"
  },
  {
    "id": "920618",
    "regency_id": "9206",
    "label": "Biscoop",
    "value": "Biscoop"
  },
  { "id": "920619", "regency_id": "9206", "label": "Tomu", "value": "Tomu" },
  {
    "id": "920620",
    "regency_id": "9206",
    "label": "Kamundan",
    "value": "Kamundan"
  },
  {
    "id": "920621",
    "regency_id": "9206",
    "label": "Weriagar",
    "value": "Weriagar"
  },
  {
    "id": "920622",
    "regency_id": "9206",
    "label": "Moskona Barat",
    "value": "Moskona Barat"
  },
  {
    "id": "920623",
    "regency_id": "9206",
    "label": "Meyado",
    "value": "Meyado"
  },
  {
    "id": "920624",
    "regency_id": "9206",
    "label": "Moskona Timur",
    "value": "Moskona Timur"
  },
  {
    "id": "920701",
    "regency_id": "9207",
    "label": "Wasior",
    "value": "Wasior"
  },
  {
    "id": "920702",
    "regency_id": "9207",
    "label": "Windesi",
    "value": "Windesi"
  },
  {
    "id": "920703",
    "regency_id": "9207",
    "label": "Teluk Duairi",
    "value": "Teluk Duairi"
  },
  {
    "id": "920704",
    "regency_id": "9207",
    "label": "Wondiboy",
    "value": "Wondiboy"
  },
  {
    "id": "920705",
    "regency_id": "9207",
    "label": "Wamesa",
    "value": "Wamesa"
  },
  {
    "id": "920706",
    "regency_id": "9207",
    "label": "Rumberpon",
    "value": "Rumberpon"
  },
  {
    "id": "920707",
    "regency_id": "9207",
    "label": "Naikere",
    "value": "Naikere"
  },
  {
    "id": "920708",
    "regency_id": "9207",
    "label": "Rasiei",
    "value": "Rasiei"
  },
  {
    "id": "920709",
    "regency_id": "9207",
    "label": "Kuri Wamesa",
    "value": "Kuri Wamesa"
  },
  { "id": "920710", "regency_id": "9207", "label": "Roon", "value": "Roon" },
  {
    "id": "920711",
    "regency_id": "9207",
    "label": "Roswar",
    "value": "Roswar"
  },
  {
    "id": "920712",
    "regency_id": "9207",
    "label": "Nikiwar",
    "value": "Nikiwar"
  },
  {
    "id": "920713",
    "regency_id": "9207",
    "label": "Soug Jaya",
    "value": "Soug Jaya"
  },
  {
    "id": "920801",
    "regency_id": "9208",
    "label": "Kaimana",
    "value": "Kaimana"
  },
  {
    "id": "920802",
    "regency_id": "9208",
    "label": "Buruway",
    "value": "Buruway"
  },
  {
    "id": "920803",
    "regency_id": "9208",
    "label": "Teluk Arguni Atas",
    "value": "Teluk Arguni Atas"
  },
  {
    "id": "920804",
    "regency_id": "9208",
    "label": "Teluk Etna",
    "value": "Teluk Etna"
  },
  {
    "id": "920805",
    "regency_id": "9208",
    "label": "Kambrau",
    "value": "Kambrau"
  },
  {
    "id": "920806",
    "regency_id": "9208",
    "label": "Teluk Arguni Bawah",
    "value": "Teluk Arguni Bawah"
  },
  { "id": "920807", "regency_id": "9208", "label": "Yamor", "value": "Yamor" },
  { "id": "920901", "regency_id": "9209", "label": "Fef", "value": "Fef" },
  { "id": "920902", "regency_id": "9209", "label": "Miyah", "value": "Miyah" },
  {
    "id": "920903",
    "regency_id": "9209",
    "label": "Yembun",
    "value": "Yembun"
  },
  { "id": "920904", "regency_id": "9209", "label": "Kwoor", "value": "Kwoor" },
  {
    "id": "920905",
    "regency_id": "9209",
    "label": "Sausapor",
    "value": "Sausapor"
  },
  { "id": "920906", "regency_id": "9209", "label": "Abun", "value": "Abun" },
  {
    "id": "920907",
    "regency_id": "9209",
    "label": "Syujak",
    "value": "Syujak"
  },
  {
    "id": "920908",
    "regency_id": "9209",
    "label": "Moraid",
    "value": "Moraid"
  },
  { "id": "920909", "regency_id": "9209", "label": "Kebar", "value": "Kebar" },
  {
    "id": "920910",
    "regency_id": "9209",
    "label": "Amberbaken",
    "value": "Amberbaken"
  },
  {
    "id": "920911",
    "regency_id": "9209",
    "label": "Senopi",
    "value": "Senopi"
  },
  {
    "id": "920912",
    "regency_id": "9209",
    "label": "Mubrani",
    "value": "Mubrani"
  },
  { "id": "920913", "regency_id": "9209", "label": "Bikar", "value": "Bikar" },
  {
    "id": "920914",
    "regency_id": "9209",
    "label": "Bamusbama",
    "value": "Bamusbama"
  },
  { "id": "920915", "regency_id": "9209", "label": "Ases", "value": "Ases" },
  {
    "id": "920916",
    "regency_id": "9209",
    "label": "Miyah Selatan",
    "value": "Miyah Selatan"
  },
  {
    "id": "920917",
    "regency_id": "9209",
    "label": "Ireres",
    "value": "Ireres"
  },
  {
    "id": "920918",
    "regency_id": "9209",
    "label": "Tobouw",
    "value": "Tobouw"
  },
  {
    "id": "920919",
    "regency_id": "9209",
    "label": "Wilhem Roumbouts",
    "value": "Wilhem Roumbouts"
  },
  {
    "id": "920920",
    "regency_id": "9209",
    "label": "Tinggouw",
    "value": "Tinggouw"
  },
  {
    "id": "920921",
    "regency_id": "9209",
    "label": "Kwesefo",
    "value": "Kwesefo"
  },
  {
    "id": "920922",
    "regency_id": "9209",
    "label": "Mawabuan",
    "value": "Mawabuan"
  },
  {
    "id": "920923",
    "regency_id": "9209",
    "label": "Kebar Timur",
    "value": "Kebar Timur"
  },
  {
    "id": "920924",
    "regency_id": "9209",
    "label": "Kebar Selatan",
    "value": "Kebar Selatan"
  },
  {
    "id": "920925",
    "regency_id": "9209",
    "label": "Manekar",
    "value": "Manekar"
  },
  { "id": "920926", "regency_id": "9209", "label": "Mpur", "value": "Mpur" },
  {
    "id": "920927",
    "regency_id": "9209",
    "label": "Amberbaken Barat",
    "value": "Amberbaken Barat"
  },
  { "id": "920928", "regency_id": "9209", "label": "Kasi", "value": "Kasi" },
  {
    "id": "920929",
    "regency_id": "9209",
    "label": "Selemkai",
    "value": "Selemkai"
  },
  { "id": "921001", "regency_id": "9210", "label": "Aifat", "value": "Aifat" },
  {
    "id": "921002",
    "regency_id": "9210",
    "label": "Aifat Utara",
    "value": "Aifat Utara"
  },
  {
    "id": "921003",
    "regency_id": "9210",
    "label": "Aifat Timur",
    "value": "Aifat Timur"
  },
  {
    "id": "921004",
    "regency_id": "9210",
    "label": "Aifat Selatan",
    "value": "Aifat Selatan"
  },
  {
    "id": "921005",
    "regency_id": "9210",
    "label": "Aitinyo Barat",
    "value": "Aitinyo Barat"
  },
  {
    "id": "921006",
    "regency_id": "9210",
    "label": "Aitinyo",
    "value": "Aitinyo"
  },
  {
    "id": "921007",
    "regency_id": "9210",
    "label": "Aitinyo Utara",
    "value": "Aitinyo Utara"
  },
  {
    "id": "921008",
    "regency_id": "9210",
    "label": "Ayamaru",
    "value": "Ayamaru"
  },
  {
    "id": "921009",
    "regency_id": "9210",
    "label": "Ayamaru Utara",
    "value": "Ayamaru Utara"
  },
  {
    "id": "921010",
    "regency_id": "9210",
    "label": "Ayamaru Timur",
    "value": "Ayamaru Timur"
  },
  { "id": "921011", "regency_id": "9210", "label": "Mare", "value": "Mare" },
  {
    "id": "921012",
    "regency_id": "9210",
    "label": "Aifat Timur Tengah",
    "value": "Aifat Timur Tengah"
  },
  {
    "id": "921013",
    "regency_id": "9210",
    "label": "Aifat Timur Jauh",
    "value": "Aifat Timur Jauh"
  },
  {
    "id": "921014",
    "regency_id": "9210",
    "label": "Aifat Timur Selatan",
    "value": "Aifat Timur Selatan"
  },
  {
    "id": "921015",
    "regency_id": "9210",
    "label": "Ayamaru Selatan",
    "value": "Ayamaru Selatan"
  },
  {
    "id": "921016",
    "regency_id": "9210",
    "label": "Ayamaru Jaya",
    "value": "Ayamaru Jaya"
  },
  {
    "id": "921017",
    "regency_id": "9210",
    "label": "Ayamaru Selatan Jaya",
    "value": "Ayamaru Selatan Jaya"
  },
  {
    "id": "921018",
    "regency_id": "9210",
    "label": "Ayamaru Timur",
    "value": "Ayamaru Timur"
  },
  {
    "id": "921019",
    "regency_id": "9210",
    "label": "Ayamaru Utara Timur",
    "value": "Ayamaru Utara Timur"
  },
  {
    "id": "921020",
    "regency_id": "9210",
    "label": "Ayamaru Tengah",
    "value": "Ayamaru Tengah"
  },
  {
    "id": "921021",
    "regency_id": "9210",
    "label": "Ayamaru Barat",
    "value": "Ayamaru Barat"
  },
  {
    "id": "921022",
    "regency_id": "9210",
    "label": "Aitinyo Tengah",
    "value": "Aitinyo Tengah"
  },
  {
    "id": "921023",
    "regency_id": "9210",
    "label": "Aitinyo Raya",
    "value": "Aitinyo Raya"
  },
  {
    "id": "921024",
    "regency_id": "9210",
    "label": "Mare Selatan",
    "value": "Mare Selatan"
  },
  {
    "id": "921101",
    "regency_id": "9211",
    "label": "Ransiki",
    "value": "Ransiki"
  },
  {
    "id": "921102",
    "regency_id": "9211",
    "label": "Oransbari",
    "value": "Oransbari"
  },
  { "id": "921103", "regency_id": "9211", "label": "Neney", "value": "Neney" },
  {
    "id": "921104",
    "regency_id": "9211",
    "label": "Dataran Isim",
    "value": "Dataran Isim"
  },
  {
    "id": "921105",
    "regency_id": "9211",
    "label": "Momi Waren",
    "value": "Momi Waren"
  },
  {
    "id": "921106",
    "regency_id": "9211",
    "label": "Tahota",
    "value": "Tahota"
  },
  { "id": "921201", "regency_id": "9212", "label": "Anggi", "value": "Anggi" },
  {
    "id": "921202",
    "regency_id": "9212",
    "label": "Anggi Gida",
    "value": "Anggi Gida"
  },
  {
    "id": "921203",
    "regency_id": "9212",
    "label": "Membey",
    "value": "Membey"
  },
  {
    "id": "921204",
    "regency_id": "9212",
    "label": "Sururey",
    "value": "Sururey"
  },
  {
    "id": "921205",
    "regency_id": "9212",
    "label": "Didohu",
    "value": "Didohu"
  },
  { "id": "921206", "regency_id": "9212", "label": "Taige", "value": "Taige" },
  {
    "id": "921207",
    "regency_id": "9212",
    "label": "Catubouw",
    "value": "Catubouw"
  },
  {
    "id": "921208",
    "regency_id": "9212",
    "label": "Testega",
    "value": "Testega"
  },
  {
    "id": "921209",
    "regency_id": "9212",
    "label": "Minyambaouw",
    "value": "Minyambaouw"
  },
  { "id": "921210", "regency_id": "9212", "label": "Hingk", "value": "Hingk" },
  {
    "id": "927101",
    "regency_id": "9271",
    "label": "Sorong",
    "value": "Sorong"
  },
  {
    "id": "927102",
    "regency_id": "9271",
    "label": "Sorong Timur",
    "value": "Sorong Timur"
  },
  {
    "id": "927103",
    "regency_id": "9271",
    "label": "Sorong Barat",
    "value": "Sorong Barat"
  },
  {
    "id": "927104",
    "regency_id": "9271",
    "label": "Sorong Kepulauan",
    "value": "Sorong Kepulauan"
  },
  {
    "id": "927105",
    "regency_id": "9271",
    "label": "Sorong Utara",
    "value": "Sorong Utara"
  },
  {
    "id": "927106",
    "regency_id": "9271",
    "label": "Sorong Manoi",
    "value": "Sorong Manoi"
  },
  {
    "id": "927107",
    "regency_id": "9271",
    "label": "Sorong Kota",
    "value": "Sorong Kota"
  },
  {
    "id": "927108",
    "regency_id": "9271",
    "label": "Klaurung",
    "value": "Klaurung"
  },
  {
    "id": "927109",
    "regency_id": "9271",
    "label": "Malaimsimsa",
    "value": "Malaimsimsa"
  },
  {
    "id": "927110",
    "regency_id": "9271",
    "label": "Maladum Mes",
    "value": "Maladum Mes"
  }
]

import React from "react";
import * as styles from "./home.module.css";
import { Button } from "antd";
import { mdiMotorbike } from "@mdi/js";
import Icon from "@mdi/react";
import img from "../../assets/images/beat.png";

export const HomePage = () => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.left}`}>
        <div className={`${styles.box}`}>
          <div className={`${styles.h1}`}>Konversikan</div>
          <div className={`${styles.h1}`}>Kendaraan Anda</div>
          <div style={{ marginTop: "20px" }}>
            <Button
              className={`${styles.button}`}
              onClick={() => (location.href = "/daftar")}
              type="primary"
              icon={<Icon path={mdiMotorbike} size={1} />}
              size="large"
            >
              Mendaftar Konversi
            </Button>
          </div>
        </div>
      </div>
      <div className={`${styles.right}`}>
        <img style={{ width: "360px" }} src={img} />
      </div>
    </div>
  );
};

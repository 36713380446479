import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Space,
  Tag,
  Table,
  Popconfirm,
  message,
  Spin,
  Checkbox,
} from "antd";
import * as styles from "./form.module.css";
type RequiredMark = boolean | "optional";
const { TextArea } = Input;
import {
  InfoCircleOutlined,
  UploadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import regencies from "../../../assets/json/regencies.json";
import masterDistricts from "../../../assets/json/districts.json";
import masterVillages from "../../../assets/json/villages.json";
import { IMapPicker } from "../../../component";
import { produce } from "immer";
import { SuccessStep } from "./submit";

export const BengkelBadanUsahaPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const [kode, setKode] = useState("");
  const [listBengkel, setListBengkel] = useState([
    {
      key: 1000 + Math.random() * (10000 - 1000),
    },
  ]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [namePicker, setNamePicker] = useState("");
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onOk = (name, location) => {
    let f = {};
    f[`latitude-${name}`] = location.lat;
    f[`longitude-${name}`] = location.lng;
    form.setFieldsValue(f);
    console.log(name);
    console.log(location);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values: any) => {
    //console.log('values ', values);
    setLoading(true);
    let hasil = {
      alamat: {},
      pengurus: {},
      alamat_pengurus_ktp: {},
      alamat_pengurus_domisili: {},
    };

    for (let key in values) {
      if (key.split("-").length > 1) {
        // do nothing
      } else if (key.split("alamat_").length > 1) {
        let arr = key.split("alamat_");
        hasil.alamat[arr[1]] = values[key];
      } else if (key.split("pengurus_").length > 1) {
        let arr = key.split("pengurus_");
        hasil.pengurus[arr[1]] = values[key];
      } else if (key.split("ktp_").length > 1) {
        let arr = key.split("ktp_");
        hasil.alamat_pengurus_ktp[arr[1]] = values[key];
      } else if (key.split("domisili_").length > 1) {
        let arr = key.split("domisili_");
        hasil.alamat_pengurus_domisili[arr[1]] = values[key];
      } else {
        hasil[key] = values[key];
      }
    }

    let bengkel = [];
    for (let x = 0; x < listBengkel.length; x++) {
      let obj = {};
      for (let key in values) {
        let arrx = key.split(`-${x + 1}`);
        if (arrx.length > 1) {
          obj[arrx[0]] = values[key];
        }
      }
      obj["uuid"] = crypto.randomUUID();
      bengkel.push(obj);
    }

    if (bengkel.length == 0) {
      message.error("bengkel masih kosong harap diisi.");
      return;
    }

    hasil.bengkel = bengkel;

    console.log("hasil ", hasil);

    const formData = new FormData();
    for (let key in hasil) {
      if (hasil[key].file) {
        formData.append(key, hasil[key].file, hasil[key].file.name);
        formData.append(key + "_data", JSON.stringify(hasil[key].fileList[0]));
      } else if (
        key == "bengkel" ||
        key == "pengurus" ||
        key == "alamat" ||
        key == "alamat_pengurus_ktp" ||
        key == "alamat_pengurus_domisili"
      ) {
        formData.append(key, JSON.stringify(hasil[key]));
      } else {
        formData.append(key, hasil[key]);
      }
    }
    //formData.append(`data_teknisi`, JSON.stringify(dataTeknisi));

    fetch("/api/webuser/pengajuan/badanusaha/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((json) => {
        message.success("send successfully.");
        setKode(json.data.code);
        setListBengkel([
          {
            key: 1000 + Math.random() * (10000 - 1000),
          },
        ]);
        form.resetFields();
        setFinish(true);
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFieldsChange = (values: any) => {};

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      //
    },
    beforeUpload: (file) => {
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    //fileList,
  };

  if (finish) {
    return (
      <div className={`${styles.center}`}>
        <SuccessStep code={kode} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={`${styles.center}`}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={`${styles.body}`}>
      <Form
        form={form}
        className={`${styles.sform}`}
        name="bengkel"
        layout="vertical"
        initialValues={{}}
        onFieldsChange={onFieldsChange}
        onValuesChange={onRequiredTypeChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Typography.Title level={2}>
          Form Pendaftaran Bengkel Konversi untuk Badan Usaha
        </Typography.Title>
        <Typography.Title level={4}>
          Silahkan lengkapi form di bawah ini dengan benar
        </Typography.Title>
        <div>
          Silahkan unduh surat permohonan penunjukan bengkel konversi berikut,{" "}
          <a href="">klik disini</a>.
        </div>
        <Divider />

        <div
          style={{ marginBottom: "20px" }}
          className={`${styles.flexJustify}`}
        >
          <div>
            <div style={{ fontSize: "20px", fontWeight: 500 }}>
              Informasi Badan Usaha
            </div>
          </div>
          <div></div>
        </div>

        <div className={`${styles.grid3}`}>
          <Form.Item
            label="Nama Badan Usaha"
            name="name"
            rules={[
              { required: true, message: "Silahkan Masukan Nama Badan Usaha!" },
            ]}
            tooltip="Nama Badan Usaha"
          >
            <Input placeholder="Nama Badan Usaha" />
          </Form.Item>
          <Form.Item
            label="NIB"
            name="nib"
            rules={[{ required: true, message: "Silahkan Masukan NIB!" }]}
            tooltip="NIB"
          >
            <Input placeholder="NIB" />
          </Form.Item>
          <Form.Item
            label="NPWP"
            name="npwp"
            rules={[
              {
                required: true,
                message: "Silahkan Upload NPWP!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload name="nib_file" {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <Typography.Title level={5}>Alamat Badan Usaha</Typography.Title>
        <Divider />
        <div className={`${styles.grid3}`}>
          <Form.Item
            name="alamat_kota"
            rules={[{ required: true, message: "Silahkan Masukan Kota!" }]}
            label="Kota"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kota"
              optionFilterProp="children"
              onChange={(value, option) => {
                let dd = masterDistricts.filter(
                  (d) => d.regency_id == option["id"],
                );
                setDistricts(dd);
                //console.log('value ', dd)
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={regencies}
            />
          </Form.Item>

          <Form.Item
            name="alamat_kecamatan"
            rules={[{ required: true, message: "Silahkan Masukan Kecamatan!" }]}
            label="Kecamatan"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kecamatan"
              optionFilterProp="children"
              onChange={(value, option) => {
                let dd = (masterVillages as Array<any>).filter(
                  (d) => d.district_id == option["id"],
                );
                setVillages(dd);
                //console.log('value ', dd)
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={districts}
            />
          </Form.Item>

          <Form.Item
            name="alamat_kelurahan"
            rules={[{ required: true, message: "Silahkan Masukan Kelurahan!" }]}
            label="Kelurahan"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kelurahan"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={villages}
            />
          </Form.Item>
        </div>

        <div className={`${styles.grid31}`}>
          <Form.Item
            name="alamat_alamat"
            label="Alamat"
            rules={[
              {
                required: true,
                message: "Silahkan Isi Dahulu!",
              },
            ]}
            tooltip="Masukan Nama Lengkap"
          >
            <Input placeholder="Alamat" />
          </Form.Item>

          <Form.Item
            name="alamat_kodepos"
            label="Kodepos"
            rules={[
              {
                required: true,
                message: "Silahkan Isi Dahulu!",
              },
            ]}
            tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
          >
            <Input placeholder="Kode Pos" />
          </Form.Item>
        </div>

        <div className={`${styles.grid3}`}>
          <Form.Item
            rules={[{ required: true, message: "Silahkan Masukan No Telp!" }]}
            name="telp"
            label="No Telp"
            tooltip="Masukan No Telp"
            extra="Masukan nomor WA yang aktif"
          >
            <Input addonBefore="+62" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Silahkan Masukan No Fax!" }]}
            name="fax"
            label="No Fax"
            tooltip="Masukan No Fax"
            extra="Masukan nomor Fax"
          >
            <Input addonBefore="+62" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { type: "email", message: "Silahkan Masukan format Email!" },
              { required: true, message: "Silahkan Masukan Email!" },
            ]}
            tooltip={{ title: "Masukan Email", icon: <InfoCircleOutlined /> }}
            extra="Informasi pendaftaran akan dikirim lewat email"
          >
            <Input />
          </Form.Item>
        </div>

        <div
          style={{ marginBottom: "20px" }}
          className={`${styles.flexJustify}`}
        >
          <div>
            <div style={{ fontSize: "20px", fontWeight: 500 }}>
              Data Pengurus Badan Usaha
            </div>
          </div>
          <div></div>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Nama Lengkap"
            name="pengurus_name"
            rules={[
              { required: true, message: "Silahkan Masukan Nama Lengkap!" },
            ]}
            tooltip="Nama Lengkap"
          >
            <Input placeholder="Nama Lengkap" />
          </Form.Item>
          <Form.Item
            label="NIK Pengurus"
            name="pengurus_nik"
            rules={[
              { required: true, message: "Silahkan Masukan NIK Pengurus!" },
            ]}
            tooltip="NIK Pengurus"
          >
            <Input placeholder="NIK Pengurus" />
          </Form.Item>
        </div>

        <div className={`${styles.grid3}`}>
          <Form.Item
            label="Jabatan"
            name="pengurus_jabatan"
            rules={[{ required: true, message: "Silahkan Masukan Jabatan!" }]}
            tooltip="Jabatan"
          >
            <Input placeholder="Jabatan" />
          </Form.Item>
          <Form.Item
            label="Kartu Tanda Penduduk"
            name="ktpfile_pengurus"
            rules={[
              {
                required: true,
                message: "Silahkan Upload Kartu Tanda Penduduk!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload name="ktpfile_pengurus" {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="NPWP"
            name="npwpfile_pengurus"
            rules={[
              {
                required: true,
                message: "Silahkan Upload NPWP!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload name="npwpfile_pengurus" {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <Typography.Title level={5}>Alamat Sesuai KTP</Typography.Title>
        <Divider />
        <div className={`${styles.grid3}`}>
          <Form.Item
            name="ktp_kota"
            rules={[{ required: true, message: "Silahkan Masukan Kota!" }]}
            label="Kota"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kota"
              optionFilterProp="children"
              onChange={(value, option) => {
                let dd = masterDistricts.filter(
                  (d) => d.regency_id == option["id"],
                );
                setDistricts(dd);
                //console.log('value ', dd)
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={regencies}
            />
          </Form.Item>

          <Form.Item
            name="ktp_kecamatan"
            rules={[{ required: true, message: "Silahkan Masukan Kecamatan!" }]}
            label="Kecamatan"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kecamatan"
              optionFilterProp="children"
              onChange={(value, option) => {
                let dd = (masterVillages as Array<any>).filter(
                  (d) => d.district_id == option["id"],
                );
                setVillages(dd);
                //console.log('value ', dd)
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={districts}
            />
          </Form.Item>

          <Form.Item
            name="ktp_kelurahan"
            rules={[{ required: true, message: "Silahkan Masukan Kelurahan!" }]}
            label="Kelurahan"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kelurahan"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={villages}
            />
          </Form.Item>
        </div>

        <div className={`${styles.grid31}`}>
          <Form.Item
            name="ktp_alamat"
            label="Alamat"
            rules={[
              {
                required: true,
                message: "Silahkan Isi Dahulu!",
              },
            ]}
            tooltip="Masukan Nama Lengkap"
          >
            <Input placeholder="Alamat" />
          </Form.Item>

          <Form.Item
            name="ktp_kodepos"
            label="Kodepos"
            rules={[
              {
                required: true,
                message: "Silahkan Isi Dahulu!",
              },
            ]}
            tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
          >
            <Input placeholder="Kode Pos" />
          </Form.Item>
        </div>

        <Typography.Title level={5}>Alamat Domisili</Typography.Title>
        <Divider />
        <div className={`${styles.grid3}`}>
          <Form.Item
            name="domisili_kota"
            rules={[{ required: true, message: "Silahkan Masukan Kota!" }]}
            label="Kota"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kota"
              optionFilterProp="children"
              onChange={(value, option) => {
                let dd = masterDistricts.filter(
                  (d) => d.regency_id == option["id"],
                );
                setDistricts(dd);
                //console.log('value ', dd)
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={regencies}
            />
          </Form.Item>

          <Form.Item
            name="domisili_kecamatan"
            rules={[{ required: true, message: "Silahkan Masukan Kecamatan!" }]}
            label="Kecamatan"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kecamatan"
              optionFilterProp="children"
              onChange={(value, option) => {
                let dd = (masterVillages as Array<any>).filter(
                  (d) => d.district_id == option["id"],
                );
                setVillages(dd);
                //console.log('value ', dd)
              }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={districts}
            />
          </Form.Item>

          <Form.Item
            name="domisili_Kelurahan"
            rules={[{ required: true, message: "Silahkan Masukan Kelurahan!" }]}
            label="Kelurahan"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kelurahan"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={villages}
            />
          </Form.Item>
        </div>

        <div className={`${styles.grid31}`}>
          <Form.Item
            name="domisili_alamat"
            label="Alamat"
            rules={[
              {
                required: true,
                message: "Silahkan Isi Dahulu!",
              },
            ]}
            tooltip="Masukan Nama Lengkap"
          >
            <Input placeholder="Alamat" />
          </Form.Item>

          <Form.Item
            name="domisili_kodepos"
            label="Kodepos"
            rules={[
              {
                required: true,
                message: "Silahkan Isi Dahulu!",
              },
            ]}
            tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
          >
            <Input placeholder="Kode Pos" />
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            rules={[{ required: true, message: "Silahkan Masukan No Telp!" }]}
            name="pengurus_telp"
            label="No Telp"
            tooltip="Masukan No Telp"
            extra="Masukan nomor WA yang aktif"
          >
            <Input addonBefore="+62" />
          </Form.Item>

          <Form.Item
            name="pengurus_email"
            label="Email"
            rules={[
              { type: "email", message: "Silahkan Masukan format Email!" },
              { required: true, message: "Silahkan Masukan Email!" },
            ]}
            tooltip={{ title: "Masukan Email", icon: <InfoCircleOutlined /> }}
            extra="Informasi pendaftaran akan dikirim lewat email"
          >
            <Input />
          </Form.Item>
        </div>

        <div
          style={{ marginTop: "40px", marginBottom: "20px" }}
          className={`${styles.flexJustify}`}
        >
          <div>
            <div style={{ fontSize: "20px", fontWeight: 500 }}>
              Informasi Bengkel
            </div>
          </div>
          <div></div>
        </div>

        {listBengkel.map((bengkel, idx) => {
          return (
            <React.Fragment key={idx}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Title level={5}>Bengkel {idx + 1}</Typography.Title>
                {idx > 0 && (
                  <Popconfirm
                    title="Delete the bengkel"
                    description="Are you sure to delete this bengkel?"
                    onConfirm={() => confirm(bengkel.key)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="link"
                      icon={<CloseCircleOutlined style={{ color: "red" }} />}
                    />
                  </Popconfirm>
                )}
              </div>
              <Divider />
              <div className={`${styles.grid2}`}>
                <Form.Item
                  label="Nama Bengkel"
                  name={`name-${idx + 1}`}
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukan Nama Bengkel!",
                    },
                  ]}
                  tooltip="Nama Bengkel"
                >
                  <Input placeholder="Nama Bengkel" />
                </Form.Item>
                <Form.Item
                  label="Nomor Telp Bengkel"
                  name={`telp-${idx + 1}`}
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukan Nomor Telp Bengkel!",
                    },
                  ]}
                  tooltip="Nomor Telp Bengkel"
                >
                  <Input placeholder="Nomor Telp Bengkel" />
                </Form.Item>
              </div>

              <div className={`${styles.grid2}`}>
                <Form.Item
                  label="Nama Penanggung Jawab Bengkel"
                  name={`nama_penanggung_jawab-${idx + 1}`}
                  rules={[
                    {
                      required: true,
                      message:
                        "Silahkan Masukan Nama Penanggung Jawab Bengkel!",
                    },
                  ]}
                  tooltip="Nama Penanggung Jawab Bengkel"
                >
                  <Input placeholder="Nama Penanggung Jawab Bengkel" />
                </Form.Item>
                <Form.Item
                  label="Nomor Telp Penanggung Jawab Bengkel"
                  name={`telp_penanggung_jawab-${idx + 1}`}
                  rules={[
                    {
                      required: true,
                      message:
                        "Silahkan Masukan Nomor Telp Penanggung Jawab Bengkel!",
                    },
                  ]}
                  tooltip="Nomor Telp Penanggung Jawab Bengkel"
                >
                  <Input placeholder="Nomor Telp Penanggung Jawab Bengkel" />
                </Form.Item>
              </div>

              <div className={`${styles.grid3}`}>
                <Form.Item
                  name={`kota-${idx + 1}`}
                  rules={[
                    { required: true, message: "Silahkan Masukan Kota!" },
                  ]}
                  label="Kota"
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Pilih Kota"
                    optionFilterProp="children"
                    onChange={(value, option) => {
                      let dd = masterDistricts.filter(
                        (d) => d.regency_id == option["id"],
                      );
                      setDistricts(dd);
                      //console.log('value ', dd)
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={regencies}
                  />
                </Form.Item>

                <Form.Item
                  name={`kecamatan-${idx + 1}`}
                  rules={[
                    { required: true, message: "Silahkan Masukan Kecamatan!" },
                  ]}
                  label="Kecamatan"
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Pilih Kecamatan"
                    optionFilterProp="children"
                    onChange={(value, option) => {
                      let dd = (masterVillages as Array<any>).filter(
                        (d) => d.district_id == option["id"],
                      );
                      setVillages(dd);
                      //console.log('value ', dd)
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={districts}
                  />
                </Form.Item>

                <Form.Item
                  name={`kelurahan-${idx + 1}`}
                  rules={[
                    { required: true, message: "Silahkan Masukan Kelurahan!" },
                  ]}
                  label="Kelurahan"
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Pilih Kelurahan"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={villages}
                  />
                </Form.Item>
              </div>

              <div className={`${styles.grid31}`}>
                <Form.Item
                  name={`alamat-${idx + 1}`}
                  label="Alamat"
                  required
                  tooltip="Masukan Alamat Lengkap"
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Isi Dahulu!",
                    },
                  ]}
                >
                  <Input placeholder="Alamat" />
                </Form.Item>

                <Form.Item
                  name={`kodepos-${idx + 1}`}
                  label="Kodepos"
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Isi Dahulu!",
                    },
                  ]}
                  tooltip={{
                    title: "Masukan Kodepos",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Input placeholder="Kode Pos" />
                </Form.Item>
              </div>

              <Typography.Title level={5}>Koordinat Bengkel</Typography.Title>
              <Divider />
              <div className={`${styles.grid3}`}>
                <Form.Item
                  name={`latitude-${idx + 1}`}
                  label="Latitude"
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukan Latitude Bengkel!",
                    },
                  ]}
                  tooltip="Latitude"
                >
                  <Input placeholder="Latitude" />
                </Form.Item>
                <Form.Item
                  name={`longitude-${idx + 1}`}
                  label="Longitude"
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukan Longitude Bengkel!",
                    },
                  ]}
                  tooltip="Longitude"
                >
                  <Input placeholder="Longitude" />
                </Form.Item>

                <div>
                  <div style={{ marginBottom: "5px" }}>-</div>
                  <Button
                    onClick={() => {
                      setNamePicker(idx + 1 + "");
                      setIsPickerOpen(true);
                    }}
                    type="primary"
                  >
                    Pilih Lokasi
                  </Button>
                </div>
              </div>
            </React.Fragment>
          );
        })}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              const nextState = produce(listBengkel, (draft) => {
                draft.push({ key: 1000 + Math.random() * (10000 - 1000) });
              });
              setListBengkel(nextState);
            }}
            type="default"
          >
            + Bengkel
          </Button>
        </div>

        <div
          style={{ marginTop: "40px", marginBottom: "20px" }}
          className={`${styles.flexJustify}`}
        >
          <div>
            <div style={{ fontSize: "20px", fontWeight: 500 }}>
              Pernyataan Pemohon
            </div>
          </div>
          <div></div>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Silahkan Pilih Dahulu!",
              },
            ]}
            name="seluruh_data_benar"
            valuePropName="checked"
          >
            <Checkbox>
              Saya menyatakan bahwa seluruh data dan dokumen yang saya berikan
              adalah benar dan dapat diverifikasi
            </Checkbox>
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            name="bersedia_menyediakan_peralatan_bengkel"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Silahkan Pilih Dahulu!",
              },
            ]}
          >
            <Checkbox>
              Saya bersedia menyediakan peralatan bengkel sesuai Lampiran I
            </Checkbox>
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            name="menyetujui"
            rules={[
              {
                required: true,
                message: "Silahkan Pilih Dahulu!",
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox>
              Saya telah membaca dan menyetujui Syarat & Ketentuan menjadi
              Nagara Partner dan merupakan satu kesatuan dan bagian yang tidak
              terpisahkan dengan formulir ini
            </Checkbox>
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <IMapPicker
        isModalOpen={isPickerOpen}
        setIsModalOpen={setIsPickerOpen}
        name={namePicker}
        onOk={onOk}
      />
    </div>
  );
};

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.left {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.box {
  text-align: center;
}
.h1 {
  font-size: 30px;
  font-weight: 700;
  color: #1d5d9b;
}

.button span {
  padding-left: 15px;
  font-weight: 600;
  position: relative;
}
.button span svg {
  position: absolute;
  left: 0;
  top: -20px;
}

@media only screen and (max-width: 600px) {
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;
  }
  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;
  }
}

import React, { useState, useEffect } from "react";
import { Divider, Steps } from "antd";
import { BengkelStep } from "./bengkel";
import { FormStep } from "./form";
import { SubmitStep } from "./submit";
import * as styles from "./daftar.module.css";

export const DaftarPage = () => {
  const [current, setCurrent] = useState(0);
  const [code, setCode] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const [disableSelesai, setDisableSelesai] = useState(true);

  useEffect(() => {
    if (code) {
      setDisableSelesai(false);
      setCurrent(2);
    }
  }, code);

  const onChange = (value: number) => {
    console.log("onChange:", value);
    setCurrent(value);
  };

  const onNextBengkel = () => {
    setDisableForm(false);
    setCurrent(1);
  };

  const onNextSelesai = (code) => {
    setCode(code);
  };

  const steps = [
    {
      title: <div className={`${styles.titlestep}`}>Pilih Bengkel</div>,
      description: "Cari bengkel terdekat",
      content: <BengkelStep onNextBengkel={onNextBengkel} />,
    },
    {
      title: <div className={`${styles.titlestep}`}>Formulir Permohonan</div>,
      description: "Lengkapi formulir",
      content: <FormStep onNextSelesai={onNextSelesai} />,
      disabled: disableForm,
    },
    {
      title: <div className={`${styles.titlestep}`}>Selesai</div>,
      description: "Menunggu Status",
      content: <SubmitStep code={code} />,
      disabled: disableSelesai,
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      <Steps current={current} onChange={onChange} items={steps} />
      <Divider />

      <div className={`${styles.body}`}>{steps[current].content}</div>
    </div>
  );
};

import React, { useState } from "react";
import { Button, Form, Modal, Input, Upload, DatePicker } from "antd";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import * as styles from "./form.module.css";
type RequiredMark = boolean | "optional";
import type { UploadProps } from "antd";

export const ModalTeknisi = ({ isModalOpen, setIsModalOpen, setData }) => {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const handleOk = () => {
    form.submit();
    //setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
    values.uuid = crypto.randomUUID();
    setData(values);
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      //
    },
    beforeUpload: (file) => {
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    //fileList,
  };

  return (
    <Modal
      title="Tambah Tenaga Teknis"
      open={isModalOpen}
      onOk={handleOk}
      maskClosable={false}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        className={`${styles.sform}`}
        name="bengkel"
        layout="vertical"
        initialValues={{ remember: true }}
        onValuesChange={onRequiredTypeChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className={`${styles.grid1}`}>
          <Form.Item
            label="Nama Tenaga Teknis"
            name="name"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan Nama Tenaga Teknis!",
              },
            ]}
            tooltip="Nama Tenaga Teknis"
          >
            <Input placeholder="Nama Tenaga Teknis" />
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            label="Nomor Serkom"
            name="nomor_serkom"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan Nomor Serkom!",
              },
            ]}
            tooltip="Nomor Serkom"
          >
            <Input placeholder="Nomor Serkom" />
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            label="File Serkom (Type File PDF/Gambar ex: pdf, jpeg, jpg, dan png max 5MB)"
            name="file_serkom"
            rules={[
              { required: true, message: "Silahkan Upload File Serkom!" },
            ]}
            tooltip="This is a required field"
          >
            <Upload name="file_serkom" {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            label="Tanggal Mulai Berlaku Serkom"
            name="mulai_serkom"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan Tanggal Mulai Berlaku Serkom!",
              },
            ]}
            tooltip="Tanggal Mulai Berlaku Serkom"
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            label="Tanggal Akhir Berlaku Serkom"
            name="akhir_serkom"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan Tanggal Akhir Berlaku Serkom!",
              },
            ]}
            tooltip="Tanggal Akhir Berlaku Serkom"
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Breadcrumb, Divider, Layout, Menu, theme } from "antd";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import type { MenuProps } from "antd";
import "./layout.css";
import { produce } from "immer";

const { Header, Content, Footer } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const findItem = (find, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].key == find) {
      return arr[i];
    }
    if (arr[i].children) {
      let a = findItem(find, arr[i].children);
      if (a) {
        return arr[i];
      }
    }
  }
  return undefined;
};

/*const menuItems: MenuItem[] = [
  {
    key: "/",
    label: "Home",
  },
  {
    key: "/daftar",
    label: "Pendaftaran",
  },
  {
    key: "/check",
    label: "Cek Status Permohonan",
  },
];*/

export const AppLayout: React.FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {
      key: "/",
      label: "Home",
    },
    {
      key: "/perorangan",
      label: "Perorangan",
    },
    {
      key: "/badan-usaha",
      label: "Badan Usaha",
    },
    {
      key: "/check",
      label: "Cek Status Permohonan",
    },
  ]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  const onClickMenu: MenuProps["onClick"] = (e) => {
    //console.log("click ", e.key);
    //console.log('location.pathname ', location.pathname)
    if (e.key[0] == "/") {
      navigate(e.key);
    }
  };

  useEffect(() => {
    //console.log('location.pathname ', location.pathname)
    if (location.pathname.includes("bengkel")) {
      const nextState = produce(menuItems, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (!draft[i]?.key?.includes("bengkel")) {
            draft[i].key = "/bengkel" + draft[i]?.key;
          }
        }
      });
      setMenuItems(nextState);
    } else {
      const nextState = produce(menuItems, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i]?.key?.includes("bengkel")) {
            let arr = draft[i]?.key.split("/bengkel");
            draft[i].key = arr[1];
          }
        }
      });
      setMenuItems(nextState);
    }
  }, [location]);

  return (
    <Layout className="layout">
      <Header style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ color: "white", fontWeight: "bold", marginRight: "30px" }}
        >
          NAGARA
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={onClickMenu}
          defaultOpenKeys={[
            findItem(location.pathname, menuItems)
              ? findItem(location.pathname, menuItems).key
              : "",
          ]}
          defaultSelectedKeys={[location.pathname]}
          items={menuItems}
        />
      </Header>
      <Content className="content" style={{ background: colorBgContainer }}>
        <div
          className="site-layout-content"
          style={{ background: colorBgContainer, height: "82vh" }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Nagara ©2023 Created by Nagara
      </Footer>
    </Layout>
  );
};

import { useCacheToken } from '@ant-design/cssinjs';
import React from 'react';
import version from '../version';
import { DesignTokenContext, defaultTheme } from './context';
import defaultSeedToken from './themes/seed';
import formatToken from './util/alias';
// ================================== Hook ==================================
export default function useToken() {
  const {
    token: rootDesignToken,
    hashed,
    theme,
    components
  } = React.useContext(DesignTokenContext);
  const salt = `${version}-${hashed || ''}`;
  const mergedTheme = theme || defaultTheme;
  const [token, hashId] = useCacheToken(mergedTheme, [defaultSeedToken, rootDesignToken], {
    salt,
    override: Object.assign({
      override: rootDesignToken
    }, components),
    formatToken
  });
  return [mergedTheme, token, hashed ? hashId : ''];
}
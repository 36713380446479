import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Space,
  Tag,
  Table,
  Popconfirm,
  message,
  Spin,
} from "antd";
import * as styles from "./form.module.css";
type RequiredMark = boolean | "optional";
import { useNavigate, useLocation, matchPath } from "react-router-dom";

export const BengkelPilihPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values: any) => {
    console.log(values);
    if (values.jenis_bengkel == "perorangan") {
      navigate("/bengkel/perorangan");
    } else {
      navigate("/bengkel/badan-usaha");
    }
  };

  const onFieldsChange = (values: any) => {};

  return (
    <div className={`${styles.body}`}>
      <Form
        form={form}
        className={`${styles.sform}`}
        name="bengkel"
        layout="vertical"
        initialValues={{ jenis_bengkel: "perorangan" }}
        onFieldsChange={onFieldsChange}
        onValuesChange={onRequiredTypeChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Typography.Title level={2}>
          Form Pendaftaran Bengkel Konversi
        </Typography.Title>

        <Divider />
        <Typography.Title level={4}>Pilih Jenis Bengkel</Typography.Title>

        <div className={`${styles.grid2}`}>
          <Form.Item
            name="jenis_bengkel"
            rules={[
              { required: true, message: "Silahkan Masukan Jenis Bengkel!" },
            ]}
            tooltip="Jenis Bengkel"
            label="Jenis Bengkel"
          >
            <Radio.Group>
              <Radio value="perorangan"> Perorangan </Radio>
              <Radio value="usaha"> Badan Usaha </Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

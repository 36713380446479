import React from "react";
import { Button, Result } from "antd";

export const SubmitStep = ({ code }) => {
  return (
    <div>
      <Result
        status="success"
        title={`Order number: ${code}`}
        subTitle="Harap DIsimpan Order number ini. untuk melakukan check status"
        extra={[
          <Button
            onClick={() => {
              location.href = "/check";
            }}
            type="primary"
            key="console"
          >
            Check Status Permohonan
          </Button>,
          <Button
            onClick={() => {
              location.href = "/daftar";
            }}
            key="buy"
          >
            Daftar Yang Lain
          </Button>,
        ]}
      />
    </div>
  );
};

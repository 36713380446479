import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Radio, Select } from "antd";
import * as styles from "./check.module.css";
type RequiredMark = boolean | "optional";

export const CheckPage = () => {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (values: any) => {
    console.log("Success:", values);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title}`}>Cek Status Permohonan Konversi</div>
      <div>
        Silahkan masukan nomor konversi untuk cek status konversi kendaraan anda
      </div>
      <div className={`${styles.form}`}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ requiredMarkValue: requiredMark }}
          onValuesChange={onRequiredTypeChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="nomor"
            label="NOMOR KONVERSI"
            required
            tooltip="Masukan NOMOR KONVERSI Lengkap"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan NOMOR KONVERSI Lengkap!",
              },
            ]}
            extra="Masukan NOMOR KONVERSI Lengkap"
          >
            <Input placeholder="NOMOR KONVERSI" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cari
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import MapPicker from "react-google-map-picker";
import { Button, Modal } from "antd";

const DefaultLocation = { lat: -6.160240488617722, lng: 106.82399226782944 };
const DefaultZoom = 10;

export const IMapPicker = ({ isModalOpen, setIsModalOpen, name, onOk }) => {
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  useEffect(() => {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setDefaultLocation({ lat: latitude, lng: longitude });
      setLocation({ lat: latitude, lng: longitude });
    };

    function error() {
      console.log("Unable to retrieve your location");
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }, []);

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onOk(name, location);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="Pilih Lokasi"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <MapPicker
        defaultLocation={defaultLocation}
        zoom={zoom}
        mapTypeId="roadmap"
        style={{ height: "70vh" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={handleChangeZoom}
        apiKey="AIzaSyCv7N6y4mLsuyiHAHz3Hw9j-JiDhDhYtHo"
      />
    </Modal>
  );
};

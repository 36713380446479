export const merk_motor = [
  {
    value: "Honda",
    label: "Honda",
  },
  {
    value: "Yamaha",
    label: "Yamaha",
  },
  {
    value: "Suzuki",
    label: "Suzuki",
  },
  {
    value: "SYM",
    label: "SYM",
  },
  {
    value: "Piaggio",
    label: "Piaggio",
  },
  {
    value: "Vespa",
    label: "Vespa",
  },
  {
    value: "TVS",
    label: "TVS",
  },
];

export const type_motor = [
  {
    value: "BEAT 110 KARBU",
    label: "BEAT 110 KARBU",
    group: "Honda",
  },
  {
    value: "BEAT 110 ESP",
    label: "BEAT 110 ESP",
    group: "Honda",
  },
  {
    value: "BEAT 110 FI",
    label: "BEAT 110 FI",
    group: "Honda",
  },
  {
    value: "BEAT 110 STREET FI",
    label: "BEAT 110 STREET FI",
    group: "Honda",
  },
  {
    value: "BEAT 110 STREET ESP",
    label: "BEAT 110 STREET ESP",
    group: "Honda",
  },
  {
    value: "SCOOPY 110 FI",
    label: "SCOOPY 110 FI",
    group: "Honda",
  },
  {
    value: "SCOOPY 110 KARBU",
    label: "SCOOPY 110 KARBU",
    group: "Honda",
  },
  {
    value: "SCOOPY 110 ESP",
    label: "SCOOPY 110 ESP",
    group: "Honda",
  },
  {
    value: "VARIO 110 FI",
    label: "VARIO 110 FI",
    group: "Honda",
  },
  {
    value: "VARIO 110 ESP",
    label: "VARIO 110 ESP",
    group: "Honda",
  },
  {
    value: "VARIO 110 KARBU",
    label: "VARIO 110 KARBU",
    group: "Honda",
  },
  {
    value: "VARIO 125 NEW",
    label: "VARIO 125 NEW",
    group: "Honda",
  },
  {
    value: "VARIO 125 OLD",
    label: "VARIO 125 OLD",
    group: "Honda",
  },
  {
    value: "VARIO ALL NEW",
    label: "VARIO ALL NEW",
    group: "Honda",
  },
  {
    value: "SUPRA 125 KARBU",
    label: "SUPRA 125 KARBU",
    group: "Honda",
  },
  {
    value: "SUPRA 125 FI",
    label: "SUPRA 125 FI",
    group: "Honda",
  },
  {
    value: "SUPRA 125 HELM IN",
    label: "SUPRA 125 HELM IN",
    group: "Honda",
  },
  {
    value: "REVO KARBU",
    label: "REVO KARBU",
    group: "Honda",
  },
  {
    value: "REVO FI",
    label: "REVO FI",
    group: "Honda",
  },
  {
    value: "KARISMA 125 KARBU",
    label: "KARISMA 125 KARBU",
    group: "Honda",
  },
  {
    value: "KIRANA 125 KARBU",
    label: "KIRANA 125 KARBU",
    group: "Honda",
  },
  {
    value: "ASTREA",
    label: "ASTREA",
    group: "Honda",
  },
  {
    value: "WIN 100",
    label: "WIN 100",
    group: "Honda",
  },
  {
    value: "BLADE",
    label: "BLADE",
    group: "Honda",
  },
  {
    value: "SPACY 110 FI",
    label: "SPACY 110 FI",
    group: "Honda",
  },
  {
    value: "SPACY 110 KARBU",
    label: "SPACY 110 KARBU",
    group: "Honda",
  },
  {
    value: "SPACY 110 ESP",
    label: "SPACY 110 ESP",
    group: "Honda",
  },
  {
    value: "Aerox 155 Non-ABS",
    label: "Aerox 155 Non-ABS",
    group: "Honda",
  },
  {
    value: "FZ150i",
    label: "FZ150i",
    group: "Honda",
  },
  {
    value: "Revo 110",
    label: "Revo 110",
    group: "Honda",
  },
  {
    value: "SPACY 110",
    label: "SPACY 110",
    group: "Honda",
  },
  {
    value: "Supra GTR 150",
    label: "Supra GTR 150",
    group: "Honda",
  },
  {
    value: "Verza 150",
    label: "Verza 150",
    group: "Honda",
  },
  {
    value: "CB150R StreetFire",
    label: "CB150R StreetFire",
    group: "Honda",
  },
  {
    value: "CB150 Verza",
    label: "CB150 Verza",
    group: "Honda",
  },
  {
    value: "Sonic 150R",
    label: "Sonic 150R",
    group: "Honda",
  },
  {
    value: "CBR150R",
    label: "CBR150R",
    group: "Honda",
  },
  {
    value: "CRF150L",
    label: "CRF150L",
    group: "Honda",
  },
  {
    value: "Revo X 125 FI",
    label: "Revo X 125 FI",
    group: "Honda",
  },
  {
    value: "Blade 125 FI",
    label: "Blade 125 FI",
    group: "Honda",
  },
  {
    value: "Vario 150",
    label: "Vario 150",
    group: "Honda",
  },
  {
    value: "PCX 150",
    label: "PCX 150",
    group: "Honda",
  },
  {
    value: "ADV 150",
    label: "ADV 150",
    group: "Honda",
  },
  {
    value: "Supra X NF100 D",
    label: "Supra X NF100 D",
    group: "Honda",
  },
  {
    value: "MEGA PRO",
    label: "MEGA PRO",
    group: "Honda",
  },
  {
    value: "GENIO",
    label: "GENIO",
    group: "Honda",
  },
  {
    value: "Mio Soul",
    label: "Mio Soul",
    group: "Yamaha",
  },
  {
    value: "JUPITER 105",
    label: "JUPITER 105",
    group: "Yamaha",
  },
  {
    value: "JUPITER Z110",
    label: "JUPITER Z110",
    group: "Yamaha",
  },
  {
    value: "JUPITER ZI 115",
    label: "JUPITER ZI 115",
    group: "Yamaha",
  },
  {
    value: "JUPITER Z NEW 115",
    label: "JUPITER Z NEW 115",
    group: "Yamaha",
  },
  {
    value: "VEGA R NEW 110",
    label: "VEGA R NEW 110",
    group: "Yamaha",
  },
  {
    value: "VEGA OLD 105",
    label: "VEGA OLD 105",
    group: "Yamaha",
  },
  {
    value: "CRYPTON",
    label: "CRYPTON",
    group: "Yamaha",
  },
  {
    value: "VEGA ZR 115",
    label: "VEGA ZR 115",
    group: "Yamaha",
  },
  {
    value: "FORCE 115",
    label: "FORCE 115",
    group: "Yamaha",
  },
  {
    value: "NMAX 150 NON ABS",
    label: "NMAX 150 NON ABS",
    group: "Yamaha",
  },
  {
    value: "NMAX 150 ABS",
    label: "NMAX 150 ABS",
    group: "Yamaha",
  },
  {
    value: "Mio M3 125 Non-ABS",
    label: "Mio M3 125 Non-ABS",
    group: "Yamaha",
  },
  {
    value: "Mio M3 ABS",
    label: "Mio M3 ABS",
    group: "Yamaha",
  },
  {
    value: "Mio M3 125 AKS S-Stop & Go ABS",
    label: "Mio M3 125 AKS S-Stop & Go ABS",
    group: "Yamaha",
  },
  {
    value: "Mio M3 125 AKS S-Stop & Go Non-ABS",
    label: "Mio M3 125 AKS S-Stop & Go Non-ABS",
    group: "Yamaha",
  },
  {
    value: "Mio Z",
    label: "Mio Z",
    group: "Yamaha",
  },
  {
    value: "Mio S",
    label: "Mio S",
    group: "Yamaha",
  },
  {
    value: "Soul GT AKS S-STop & Go",
    label: "Soul GT AKS S-STop & Go",
    group: "Yamaha",
  },
  {
    value: "Lexi 125 ABS",
    label: "Lexi 125 ABS",
    group: "Yamaha",
  },
  {
    value: "Lexi 125 Non-ABS",
    label: "Lexi 125 Non-ABS",
    group: "Yamaha",
  },
  {
    value: "FreeGo 125 ABS",
    label: "FreeGo 125 ABS",
    group: "Yamaha",
  },
  {
    value: "FreeGo 125 Non-ABS",
    label: "FreeGo 125 Non-ABS",
    group: "Yamaha",
  },
  {
    value: "Aerox 155 ABS",
    label: "Aerox 155 ABS",
    group: "Yamaha",
  },
  {
    value: "Fino 125",
    label: "Fino 125",
    group: "Yamaha",
  },
  {
    value: "Jupiter MX 135",
    label: "Jupiter MX 135",
    group: "Yamaha",
  },
  {
    value: "YZF-R15",
    label: "YZF-R15",
    group: "Yamaha",
  },
  {
    value: "MT-15",
    label: "MT-15",
    group: "Yamaha",
  },
  {
    value: "XSR155",
    label: "XSR155",
    group: "Yamaha",
  },
  {
    value: "NMAX 155 ABS",
    label: "NMAX 155 ABS",
    group: "Yamaha",
  },
  {
    value: "NMAX 155 Non-ABS",
    label: "NMAX 155 Non-ABS",
    group: "Yamaha",
  },
  {
    value: "MIO J",
    label: "MIO J",
    group: "Yamaha",
  },
  {
    value: "V-ixion NVL",
    label: "V-ixion NVL",
    group: "Yamaha",
  },
  {
    value: "Xeon 125",
    label: "Xeon 125",
    group: "Yamaha",
  },
  {
    value: "Fino 113",
    label: "Fino 113",
    group: "Yamaha",
  },
  {
    value: "VEGA RR",
    label: "VEGA RR",
    group: "Yamaha",
  },
  {
    value: "RX KING",
    label: "RX KING",
    group: "Yamaha",
  },
  {
    value: "F1ZR",
    label: "F1ZR",
    group: "Yamaha",
  },
  {
    value: "NOUVO",
    label: "NOUVO",
    group: "Yamaha",
  },
  {
    value: "DT 100",
    label: "DT 100",
    group: "Yamaha",
  },
  {
    value: "X-RIDE 125",
    label: "X-RIDE 125",
    group: "Yamaha",
  },
  {
    value: "Mio Sporty",
    label: "Mio Sporty",
    group: "Yamaha",
  },
  {
    value: "SHOGUN 125",
    label: "SHOGUN 125",
    group: "Suzuki",
  },
  {
    value: "NEX",
    label: "NEX",
    group: "Suzuki",
  },
  {
    value: "Smash 115",
    label: "Smash 115",
    group: "Suzuki",
  },
  {
    value: "SPIN 125",
    label: "SPIN 125",
    group: "Suzuki",
  },
  {
    value: "SKY DRIVE",
    label: "SKY DRIVE",
    group: "Suzuki",
  },
  {
    value: "FR80",
    label: "FR80",
    group: "Suzuki",
  },
  {
    value: "AXELO",
    label: "AXELO",
    group: "Suzuki",
  },
  {
    value: "SATRIA HIU",
    label: "SATRIA HIU",
    group: "Suzuki",
  },
  {
    value: "SMASH 110",
    label: "SMASH 110",
    group: "Suzuki",
  },
  {
    value: "Attila Elizabeth",
    label: "Attila Elizabeth",
    group: "SYM",
  },
  {
    value: "Vespa LX-Matic",
    label: "Vespa LX-Matic",
    group: "Piaggio",
  },
  {
    value: "Super",
    label: "Super",
    group: "Vespa",
  },
  {
    value: "Exclusive",
    label: "Exclusive",
    group: "Vespa",
  },
  {
    value: "Sprint",
    label: "Sprint",
    group: "Vespa",
  },
  {
    value: "Corsa",
    label: "Corsa",
    group: "Vespa",
  },
  {
    value: "LX",
    label: "LX",
    group: "Vespa",
  },
  {
    value: "PX",
    label: "PX",
    group: "Vespa",
  },
  {
    value: "Excel",
    label: "Excel",
    group: "Vespa",
  },
  {
    value: "P 150 S",
    label: "P 150 S",
    group: "Vespa",
  },
  {
    value: "DAZZ",
    label: "DAZZ",
    group: "TVS",
  },
];

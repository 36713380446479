import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDaftarBengkel {
  jenis_bengkel?: string;
  nama_pengurus?: string;
  nik_pengurus?: string;
  alamat_pengurus?: string;
  nama_bengkel?: string;
  nama_perusahaan_bengkel?: string;
  kota_bengkel?: string;
  kodepos_bengkel?: string;
  latitude_bengkel?: string;
  longitude_bengkel?: string;
  alamat_bengkel?: string;
  nib?: string;
  nib_file?: File;
  npwp?: string;
  npwp_file?: File;
  kswp_file?: File;
  sertifikat_bengkel_instalasi_listrik?: string;
  sertifikat_bengkel_instalasi_listrik_file?: File;
  tanggal_berlaku_sertifikat_bengkel_instalasi_listrik?: string;
  file_sut?: File;
  file_srut?: File;
  dokumen_kelengkapan?: File;
  dokumen_sertifikat?: File;
  surat_permohonan?: File;
  nama_bank?: string;
  nomor_rekening?: string;
  nama_rekening?: string;
  file_buku_rekening?: File;
  phone?: string;
  email?: string;
}

const getBengkel = (): IDaftarBengkel | undefined => {
  return {};
};

export const daftarBengkelSlice = createSlice({
  name: "daftarBengkel",
  initialState: {
    data: getBengkel(),
  },
  reducers: {
    setDaftarBengkel: (state, action: PayloadAction<IDaftarBengkel>) => {
      state.data = action.payload;
    },
  },
});

export const { setDaftarBengkel } = daftarBengkelSlice.actions;

export default daftarBengkelSlice.reducer;
